<section class="container">
    <div class="login-form">
      <div class="row pb-4">
        <div class="col-md-6 col-xs-12 offset-md-3 py-5">
          <h4 class="mb-4">Select airline & Campaign</h4>
          <ngb-alert *ngIf="errorMessage" type="danger" (closed)="closeAlert()">{{ 'login_page.invalid' | translate }}</ngb-alert>
          <form name="airlineForm" class="mt-3" [formGroup]="airlineForm" (ngSubmit)="submit()">
            <div class="form-group">
              <label for="campaign" data-error="error">Campaign Name</label>
              <input
                type="text"
                id="campaign"
                class="form-control"
                formControlName="campaign"
                autocapitalize="none"
                required="required"
                placeholder="name-campaign-format">
                <div class='error-message' *ngIf="campaign.errors !== null && (campaign.touched)" [innerHtml]="formErrorsHelper.errorMessage(campaign, 'Utm Campaign')">
                </div>
            </div>
            <airlines-autocomplete-component
              [codeFieldName]="'airline'"
              [control]="airlineForm.get('airline')"
              [form]="airlineForm"
              [airlines]="airlines"
              [isSignIn]="false"
              (valueSelected)="setAirline($event)"
              [action]="'sign-up'"
              >
            </airlines-autocomplete-component>
            <div class="row d-none d-sm-block">
              <div class="col-lg-5 col-md-8">
                <button type="submit" class="btn btn-primary my-2 w-100">{{ 'flights.search.select_airline' | translate }}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
</section>
<floating-container-bottom class="d-xs-block d-sm-none">
  <button type="submit" class="btn btn-primary my-2 w-100">{{ 'flights.search.select_airline' | translate }}</button>
</floating-container-bottom>
