import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';
import { Oauth2Helper } from 'app/shared/helpers/oauth2.helper';
import { SamlHelper } from 'app/shared/helpers/saml.helper';
import * as fromShared from 'app/shared/store/reducers';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';


@Component({
  selector: 'airlines-autocomplete-component',
  templateUrl: './airlines.autocomplete.html'
})
export class AirlinesAutocompleteComponent implements OnChanges, OnDestroy {
  @Input() airlines;
  @Input() displayFieldName;
  @Input() organizationIdFieldName;
  @Input() codeFieldName;
  @Input() form;
  @Input() control;
  @Input() defaultValue = '';
  @Input() isSignIn;
  @Input() placeHolder = '';
  @Input() action = null;
  @Input() formSubmitted: boolean = false;
  @Output() valueSelected: any = new EventEmitter();
  configFlags$ = this.store.pipe(select(fromShared.getConfigFlag));
  airlinesCopy = [];
  configFlags = null;
  ngUnsubscribe = new Subject<void>();

  constructor(
    public formErrorsHelper: FormErrorsHelper,
    private samlHelper: SamlHelper,
    private oauth2Helper: Oauth2Helper,
    private store: Store<fromShared.State>,

  ) { }

  ngOnChanges() {
    if (this.formSubmitted) { return }
    this.configFlags$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(configFlags => this.configFlags = configFlags);
    if (this.airlinesCopy.length === 0 && this.airlines) {
      this.airlinesCopy = this.airlines.map(airline =>
        Object.assign({}, airline, { added: false })
      );
    }
    if (this.defaultValue) {
      this.selectFromDefaultValue();
    }
  }

  selectFromDefaultValue() {
    const regexSearch = new RegExp(`^${this.defaultValue}/?$`, 'i');
    const defaultAirline = this.airlinesCopy.find((airline) => {
      return (regexSearch).test(airline.display_name) || (regexSearch).test(airline.code);
    });

    if (defaultAirline) {
      return this.airlineSelected(defaultAirline);
    }
  }

  search = (searchString$: Observable<string>) =>
    searchString$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) => {
        return term.length < 2 ? [] : this.filterAirlines(term);
      })
    )

  formatter = (airline: { display_name: string }) => {
    return airline.display_name;
  }

  shouldBeDisplayed(airline) {
    if (this.action === 'reset-pwd' && this.isSsoLogin(airline.code, this.configFlags)) {
      return false;
    }

    return this.isSignIn ? airline.sign_in_available : airline.sign_up_available;
  }

  airlineSelected($event) {
    if (this.codeFieldName) {
      this.form.controls[this.codeFieldName].setValue($event.code);
    }

    if (this.organizationIdFieldName) {
      this.form.controls[this.organizationIdFieldName].setValue($event.id);
    }

    if (this.displayFieldName) {
      this.form.controls[this.displayFieldName].setValue($event.display_name);
    }

    this.valueSelected.emit($event);
  }

  onChange($event) {
    const value = $event.target.value;

    if (value[0] === '+') {
      this.clearForm();
      return this.airlineSelected(this.setOpAirline(value));
    }

    const event = this.getAirline(value);
    if (!event || !this.actionIsActive(event)) {
      return this.clearForm();
    }
    return this.airlineSelected(event);

  }

  clearForm() {
    if (this.codeFieldName) {
      this.form.controls[this.codeFieldName].setValue();
      this.form.controls[this.codeFieldName].markAsTouched({ onlySelf: true });
    }

    if (this.organizationIdFieldName) {
      this.form.controls[this.organizationIdFieldName].setValue();
    }

    if (this.displayFieldName) {
      this.form.controls[this.displayFieldName].setValue();
      this.form.controls[this.displayFieldName].markAsTouched({ onlySelf: true });
    }
  }

  getAirline(name) {
    const airlineInfo = this.airlinesCopy
      .filter(airline => airline.display_name.toLowerCase().indexOf(name.toLowerCase()) > -1).slice(0, 10);
    return airlineInfo.length === 1 ? airlineInfo[0] : null;
  }

  filterAirlines(term) {
    const airlines = this.filterAirlinesByName(term);
    const airlineByCode = this.filterAirlineByCode(term);
    if (airlineByCode) {
      airlines.unshift(airlineByCode);
    }

    return airlines;
  }

  filterAirlinesByName(term): any[] {
    return this.airlinesCopy.filter(airline => airline.display_name.toLowerCase().indexOf(term.toLowerCase()) > -1
      && airline.code.toLowerCase() !== term.toLowerCase() && this.shouldBeDisplayed(airline)).slice(0, 10);
  }

  filterAirlineByCode(term): any {
    if (term.length < 2) { return null; }

      return this.airlinesCopy.find((airline) => {
        return airline.code.toLowerCase() === term.toLowerCase() && this.shouldBeDisplayed(airline);
      });
  }

  // For operator users
  private setOpAirline(airlineCode: string) {
    this.codeFieldName = 'airline';
    const airlineCodeNoPlus = airlineCode.toLowerCase().slice(1);
    this.airlinesCopy.filter((airline) => {
      return airline.code.toLowerCase() === airlineCodeNoPlus;
    }).map((airline) => { airline.added = true; });
    const airline = { code: airlineCode, name: airlineCodeNoPlus.toUpperCase(), display_name: airlineCodeNoPlus.toUpperCase() };
    return airline;
  }

  actionIsActive(airlineInfo) {
    if (this.action === 'sign-up' && !airlineInfo.sign_up_available) {
      return false;
    }

    if ((this.action === 'sign-in' || this.action === 'reset-pwd') && !airlineInfo.sign_in_available) {
      return false;
    }

    if (this.action === 'reset-pwd' && this.isSsoLogin(airlineInfo.code, this.configFlags)) {
      return false;
    }

    return true;
  }

  isSsoLogin(airlineCode, appConfig) {
    return this.samlHelper.inSamlLogin(airlineCode, appConfig) || this.oauth2Helper.inOauth2Login(airlineCode, appConfig);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
