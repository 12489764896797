import { Component, Input, OnInit } from '@angular/core';
import { UserCredits } from 'app/profile/models/user-credits.interface';
import * as moment from 'moment';

@Component({
  selector: 'user-credits-card',
  templateUrl: './user-credits-card.html',
  styleUrls: ['./user-credits-card.scss']
})

export class UserCreditsCardComponent implements OnInit {
  @Input() userCredits: UserCredits;
  @Input() showPicture = true;

  allCreditsExpire = true;
  isUsd = true;

  ngOnInit() {
    this.isUsdCurrency();
    this.calcDoesAllCreditsExpire();
  }

  calcDoesAllCreditsExpire() {
    if (this.userCredits['history'] && this.userCredits['history'].length > 0) {
      const latestCreditExpiration = this.userCredits['history'].filter(c => parseFloat(c.amount) > 0).reduce((a, b) => {
        return new Date(a.expiration_date) > new Date(b.expiration_date) ? a : b;
      });
      if (latestCreditExpiration) {
        this.allCreditsExpire = !moment(latestCreditExpiration.expiration_date).isAfter(moment(this.userCredits.expiration));
      }
    }
  }

  isUsdCurrency() {
    if (this.userCredits) {
      this.isUsd = this.userCredits.currency === 'USD';
    }
  }
}
