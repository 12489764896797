import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { SettingsActions } from '../actions';
import { map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ProfileService } from 'app/shared/services/profile/profile.service';

@Injectable()
export class SettingsEffects {

  loadSettings$ = createEffect(()=>
    this.actions$.pipe(
      ofType<SettingsActions.LoadSettings>(SettingsActions.SettingsActionTypes.LoadSettings),
      switchMap(() => {
        return this.profileService.getProfileSettings().pipe(
            map(settings => new SettingsActions.LoadSettingsSuccess({ settings })),
            catchError(error => of(new SettingsActions.LoadSettingsFailure(error)))
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private profileService: ProfileService,
  ) {}
}
