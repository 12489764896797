import { Component, Input, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

@Component({
  selector: 'watcher-status',
  templateUrl: 'watcher-status.html',
  styleUrls: ['watcher-status.scss']
})
export class WatcherStatusComponent implements OnChanges {
  @Input() flightStatus;
  @Input() loading;
  flightRatioText: string = 'N/A';

  constructor(protected translate: TranslateService) {}

  ngOnChanges() {
    if (this.flightStatus !== undefined && this.flightStatus.last_load_check_status !== undefined) {
      const checkStatus = this.flightStatus.last_load_check_status;
      if (checkStatus.face !== undefined) {
        this.obtainFaceStats(checkStatus);
      } else if (checkStatus.value !== undefined) {
        this.obtainValueStats(checkStatus);
      }
    }
  }

  obtainFaceStats(status: any) {
    const faceDescription = `flights.tools.check_loads.faces.${status.face}`;
    this.translate.get(faceDescription).pipe(take(1)).subscribe((text:string) => {
      this.flightRatioText = text;
    });
  }

  obtainValueStats(status: any) {
    if (status.value !== 'N/A') {
      this.flightRatioText = status.value;
    }
  }
}
