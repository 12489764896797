<div [ngClass]="{ 'form-floating': !placeholder }" class="form-group mb-0" [formGroup]="form">
  <input (keyup)="checkRules($event.target.value)" [ngbPopover]="showTooltip ? toolTipPassword: ''" 	placement="right"  type="{{type}}" class="form-control with-background" formControlName="{{control}}" id="{{id}}" [placeholder]="placeholder" autocomplete="{{autocomplete}}" required="required" pattern="{{pattern}}" data-cy="password" >
  <label *ngIf="!placeholder" for="{{id}}">{{label}}</label>
  <a tabindex="-1" class="clickable password-button btn-link gtm-show-pass" *ngIf="type == 'password'" (click)="showPassword()"><fa-icon [icon]="icons.faEye" class="pe-2"></fa-icon>{{ 'mobile.general.password.show' | translate }}</a>
  <a tabindex="-1" class="clickable password-button btn-link gtm-show-pass" *ngIf="type == 'text'" (click)="hidePassword()"><fa-icon [icon]="icons.faEyeSlash" class="pe-2"></fa-icon>{{ 'mobile.general.password.hide' | translate }}</a>
  <a *ngIf="showForgotPassword" tabindex="-1" class="float-end gtm-forgot-pass" [routerLink]='[forgotPasswordLink, forgotPasswordLinkParams]'>{{ 'forgot_password?' | translate }}</a>
</div>
<ng-template #toolTipPassword>
  <div >
    <div class="row d-flex justify-content-center">
      <div class="col-2">
        <fa-icon [ngStyle]="{'color': rules.rules1 ? 'green' : 'red' }"
        [icon]="getIcon(rules.rules1)"></fa-icon>
      </div>
      <div class="col-10 text-start ps-0">
        <div >{{'mobile.general.password.rules1' | translate}}</div>
      </div>
    </div>
    <div class="row d-flex justify-content-center">
      <div class="col-2">
        <fa-icon [ngStyle]="{'color': rules.rules2 ? 'green' : 'red' }" [icon]="getIcon(rules.rules2)"></fa-icon>
      </div>
      <div class="col-10 text-start ps-0">
        <div>
          {{'mobile.general.password.rules2' | translate}}
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-center">
      <div class="col-2">
        <fa-icon [ngStyle]="{'color': rules.rules3 ? 'green' : 'red' }" [icon]="getIcon(rules.rules3)"></fa-icon>
      </div>
      <div class="col-10 text-start ps-0">
        <div>
          {{'mobile.general.password.rules3' | translate}}
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-center">
      <div class="col-2">
        <fa-icon [ngStyle]="{'color': rules.rules4 ? 'green' : 'red' }" [icon]="getIcon(rules.rules4)"></fa-icon>
      </div>
      <div class="col-10 text-start ps-0">
        {{'mobile.general.password.rules4' | translate}}
      </div>
    </div>
    <div class="row d-flex justify-content-center">
      <div class="col-2">
        <fa-icon [ngStyle]="{'color': rules.rules5 ? 'green' : 'red' }" [icon]="getIcon(rules.rules5)"></fa-icon>
      </div>
      <div class="col-10 text-start ps-0">
        {{'mobile.general.password.rules5' | translate}}
      </div>
    </div>
  </div>
</ng-template>
