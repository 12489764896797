<div *ngIf="_visible" class="multiple-guests my-2">
  <form [formGroup]="guestsForm">
    <div formArrayName="rooms" class="{{ isOnPopUp && roomControls.length <= 1 ? 'd-flex justify-content-center' : 'row' }}">
      <div *ngFor="let room of roomControls; let i=index" [formGroupName]="i" class="text-center col-lg-6 mt-3 room-section pb-3 mb-3">
        <div class="room-header">
          {{'lodging.payments.stay_info.rooms' | translate}} {{ i + 1 }}
          <fa-icon [icon]="icons.faTimesCircle" class="float-end me-4 mt-1 gray-medium-color clickable" *ngIf="roomControls.length > 1" (click)="removeRoom(i)"></fa-icon>
        </div>

        <div class='error-message' *ngIf="existErrorForRoom(i)" [innerHtml]=" 'lodging.search_form.errors.missing_children_age' | translate">
        </div>

        <div class="row mt-2 secondary-content">
          <div class="col-6">{{ 'lodging.search_form.adults' | translate }}</div>
          <div class="col-6">{{ 'lodging.search_form.children' | translate }}</div>
        </div>

        <div class="row mt-2">
          <div class="col-6">
            <counter-component
              [value]="roomControls[i].get('adults').value"
              [maxValue]="maxNumberOfAdults[i]"
              [minValue]="1"
              (valueUpdated)="adultsChanged($event, i)">
            </counter-component>
          </div>
          <div class="col-6">
            <counter-component
              [value]="roomControls[i].get('children').value"
              [maxValue]="maxNumberOfChildren[i]"
              [minValue]="0"
              (valueUpdated)="childrenChanged($event, i)">
            </counter-component>
          </div>
        </div>

        <div *ngIf="roomControls[i].get('ages').value.length > 0">
          <div class="py-2 secondary-content">{{ 'lodging.search_form.age_at_checkin' | translate }}</div>
          <div formArrayName="ages" class="row">
            <div class="col-6 pt-1" [formGroupName]="j" *ngFor="let age of roomChildrenAgesControls(i); let j=index;">
              <child-age-selector
                [childNumber]="j + 1"
                [childAge]="getNumberOfChilds(i, j)"
                (ageSelected)="agesChanged($event, i, j)">
              </child-age-selector>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div *ngIf="roomsList.length < 8" class="clickable float-start" (click)="addRoom()" data-cy="add-room" >
    <fa-icon [icon]="icons.faPlusCircle" class="primary-color fas fa-plus-circle me-2"></fa-icon> {{ 'lodging.search_form.add_room' | translate }}
  </div>
</div>
