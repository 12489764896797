<section class="first-time-login">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-5 col-md-6 col-sm-8 col-xs-12 offset-lg-4 offset-md-3 offset-sm-2 pt-5">
        <div class="col-sm-12 text-center">
          <h2>{{'first_login.welcome_message' | translate }}</h2>
        </div>
        <form [formGroup]="firstTimeForm" (ngSubmit)="complete()">
          <div class="form-group form-floating">
            <input type="text" id="email" class="form-control with-background" formControlName="email" required autocomplete="email" [ngbPopover]="emailToolTip" placement="right"/>
            <label for="email" data-error="error">{{'mobile.general.email' | translate}}</label>
            <div class='error-message' *ngIf="email.errors !== null && (email.touched)" [innerHtml]="formErrorsHelper.errorMessage(email, ('membership.create.company_email_label' | translate))">
            </div>
          </div>
          <div class="form-group" *ngIf="countries">
            <phone-input
              labelKey="profile.info.homePhone"
              formGroupName="homePhone"
              [countries]="countries$ | async"
              [formGroup]="homePhone"
              [countryEnabled]="true"
              [numberEnabled]="true"
              [phoneForceError]="phoneError"
              (onKeyUp)="checkHomePhone()"
              [ngbPopover]="phoneToolTip" placement="right"
            >
            </phone-input>
          </div>
          <div class="form-group">
            <password-input  [control]="'newPassword'" [id]="'newPassword'" [form]="firstTimeForm"
              [label]="'mobile.general.new_password' | translate" autocomplete="new-password">
            </password-input>
            <div class='error-message' *ngIf="newPassword.errors !== null && (newPassword.touched)" [innerHtml]="formErrorsHelper.errorMessage(newPassword, ('membership.create.password_label' | translate))">
            </div>
          </div>
          <div class="form-group">
            <password-input  [control]="'newPasswordConfirm'" [id]="'newPasswordConfirm'" [showTooltip]="false" [form]="firstTimeForm"
              [label]="'mobile.general.confirm_new_password' | translate" autocomplete="new-password">
            </password-input>
            <div class='error-message' *ngIf="newPasswordConfirm.errors !== null && (newPasswordConfirm.touched)" [innerHtml]="formErrorsHelper.errorMessage(newPasswordConfirm, ('membership.create.password_label' | translate))">
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-6 text-end">
              <button type="submit" class="btn btn-primary w-100 my-2">
                {{ 'mobile.action.complete_setup' | translate }}
              </button>
            </div>
            <div class="col-md-6 col-sm-6 text-end">
              <button type="button" class="btn btn-gray w-100 my-2" (click)="onCancelClicked()">
                {{ 'flights.search.modals.cancel' | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
<ng-template #emailToolTip>
  <div >
    <div class="row d-flex justify-content-center">
      <div class="col-10 text-start ps-0">
        <div >{{'first_login.email_tooltip' | translate}}</div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #phoneToolTip>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-10 text-nowrap text-start ps-0">
        <div >{{'mobile.general.optional' | translate}}</div>
      </div>
    </div>
  </div>
</ng-template>
