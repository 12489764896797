import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import * as fromAuth from 'app/auth/store';
import { FeatureFlagsService } from 'app/shared/services/featureFlags/featureFlags.service';
import { PassesModalComponent } from 'app/trips/components/passes-modal/passes-modal.component';
import { HotelTrip } from 'app/trips/models/hotel-trip';
import { TripsDetails } from 'app/trips/models/trips-details';
import keysJson from 'configs/pathsKeysConfig.json';
import * as moment from 'moment';
import * as fromRoot from 'reducers';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'trips-container',
  templateUrl: './trips-container.html',
  styleUrls: ['./trips-container.scss']
})
export class TripsContainerComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('hotelTemplate', { static: true }) hotelTemplate;
  @ViewChild('flightTemplate', { static: true }) flightTemplate;
  @ViewChild('carTemplate', { static: true }) carTemplate;
  @ViewChild('insuranceTemplate', { static: true }) insuranceTemplate;

  @Input() availableTools: string[];
  @Input() itemType: string;
  @Input() maxFutureItems = 6;
  @Input() maxPastItems = 6; // loaded items amount
  @Input() maxSharedItems = 6; // loaded flights insurances
  @Input() sharedItems: TripsDetails; // NOTICE: flights insurances uses sharedItems
  @Input() pastSharedItems: TripsDetails;
  @Input() isUserSelectedByOperator: boolean;
  @Input() showFlights: boolean;
  @Input() eligibleTravelersEnabled: boolean;
  @Input() isInvitedTraveler: boolean;
  @Output() onViewTripClicked = new EventEmitter();
  @Output() loadMoreFutureItems = new EventEmitter();
  @Output() loadMorePastItems = new EventEmitter();
  @Output() loadPastItems = new EventEmitter();
  @Output() loadSharedItems = new EventEmitter();
  @Output() loadFlightsByPNR = new EventEmitter();
  @Output() isFlightPNRClean = new EventEmitter();
  @Output() sectionSelected = new EventEmitter();

  _futureItems: TripsDetails;
  _pastItems: TripsDetails;
  toolSelected: string;
  tabSelected = 'hotels';
  hotelType = 'reservation';
  insuranceType: 'hotels' | 'flights' = 'hotels';
  showSharedItineraries = false;
  isSearchFlightByPNR = false;
  pnr: string;
  user$ = this.store.pipe(select(fromAuth.getUser));
  isFraudUser$ = this.store.pipe(select(fromAuth.getFraudStatus));
  isFraudUser = false;
  isCobusAuthorization = false;
  loadingSearch = false;
  scrollLoading = false;
  icons = { faSearch };
  canSearchByPNR = false;

  private readonly infiniteScrollIncrement = 6;

  _showPast: boolean = false;
  futureReservations: TripsDetails['reservations'] = [];
  pastReservations: TripsDetails['reservations'] = [];
  flightsExtras = {};
  flightToolKeys = [
    'ticketing',
    'listing',
    'cobus',
    'cobus_admin',
    'invited_traveler'
  ];
  private ngUnsubscribe = new Subject<void>();
  template;

  constructor(
    private featureFlagService: FeatureFlagsService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private store: Store<fromRoot.State>
  ) {}

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => {
      this.initTabForCobusAuthorization();
      this.initializateByUrl(params);
      this.isFraudUser$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((fraudUser) => {
        this.isFraudUser = !!fraudUser;
      });
    });
    this.setCanSearchByPNR();
  }

  initTabForCobusAuthorization() {
    this.user$.pipe(take(1)).subscribe((user) => {
      if (!!user?.cobus_authorization) {
        this.tabSelected = 'flights';
        this.isCobusAuthorization = true;
        return this.router.navigate(['/trips/flights/company_business'], {queryParams: { key: '03-05'}});
      }
    });
  }

  @Input()
  set futureItems(val) {
    if (this.itemType === 'hotels' && val.reservations.length > 0) {
      let sortItem : any[] = [... val.reservations];
      sortItem  = sortItem.sort((a, b) => moment(a.startDate).diff(moment(b.startDate)));
      this._futureItems = { ... val, reservations: sortItem };
    } else {
      this._futureItems = val;
    }

    if (this._futureItems.reservations && this._futureItems.reservations.length && this.itemType === 'flights') {
      this.initializateToolSelected(this._futureItems.reservations);
    }
  }

  @Input()
  set pastItems(val) {
    this._pastItems = val;

    if (this._pastItems.reservations && this._pastItems.reservations.length && this.itemType === 'flights') {
      this.initializateToolSelected(this._pastItems.reservations);
    }
  }

  get futureItems(): TripsDetails {
    return this._futureItems;
  }

  get pastItems(): TripsDetails {
    return this._pastItems;
  }

  initializateByUrl(params) {
    if (params.tab) {
      this.tabSelected = params.tab;
    }

    if (params.section) {
      this.toolSelected = params.section;
    }
    this.prepareData();
  }

  initializateToolSelected(reservations) {
    this.flightToolKeys.forEach((key) => {
      if (this.toolSelected) {
        return;
      }

      if (this.isInvitedTraveler) {
        this.toolSelected = 'invited_traveler';
        return;
      }
      const futureReservations = reservations.find(reservation => (reservation.type === key));
      if (futureReservations && futureReservations.reservations.length > 0) {
        this.toolSelected = key;
        this.sectionSelected.emit(this.toolSelected);
      }
    });
  }

  ngOnChanges(changes): void {
    this.prepareData();

    if (!this.showPast) {
      if (this.futureItems.lastPage) {
        this.showPast = true;
      }
    }

    if (changes.itemType) {
      switch (this.itemType) {
        case 'hotels':
          this.template = this.hotelTemplate;
          break;
        case 'flights':
          this.template = this.flightTemplate;
          break;
        case 'cars':
          this.template = this.carTemplate;
          break;
        case 'insurance':
          this.template = this.insuranceTemplate;
          break;
      }
    }
  }

  prepareData() {
    this.onFutureItemsChange();
    this.onPastItemsChange();
    this.onSharedItemsChange();
  }

  onFutureItemsChange() {
    if (this.itemType === 'flights') {
      if (!this.futureItems.pending) {
        this.futureReservations = this.getFlightsReservations(this.futureItems);
        if (!this.pastItems.pending && this.loadingSearch) {
          this.loadingSearch = false;
        }
        this.flightsExtras = (this.toolSelected === 'ticketing' && this.futureItems.reservations.length > 0)
          ? this.futureItems.reservations[0]['extra_info'] : {};
      }
    } else {
      this.futureReservations = this.futureItems ? this.futureItems.reservations : [];
    }
    this.maxFutureItems = Math.min(this.maxFutureItems + this.infiniteScrollIncrement, this.futureReservations.length);
  }

  onPastItemsChange() {
    if (this.itemType === 'flights') {
      if (!this.pastItems.pending) {
        this.pastReservations = this.getFlightsReservations(this.pastItems);
        if (!this.futureItems.pending && this.loadingSearch) {
          this.loadingSearch = false;
        }
      }
    } else if (this.itemType === 'hotels' && !this.pastItems.pending && !this.futureItems.pending) {
      if (this.loadingSearch) {
        this.loadingSearch = false;
      }
      this.validateRepeatedPastHotels();
    } else {
      this.pastReservations = this.pastItems ? this.pastItems.reservations : [];
    }
    this.maxPastItems = Math.min(this.maxPastItems + this.infiniteScrollIncrement, this.pastReservations.length);
  }

  onSharedItemsChange() {
    if (this.sharedItems && this.sharedItems.reservations && this.isFlightsInsurances()) {
      this.maxSharedItems = Math.min(this.maxSharedItems + this.infiniteScrollIncrement, this.sharedItems.reservations.length);
    }
  }

  getFlightsReservations(items) {
    const flights = items ? items.reservations : [];
    const includedTools = [this.toolSelected];
    if (this.isInvitedTraveler) {
      if(this.toolSelected == 'ticketing'){
        includedTools.push('invited_traveler');
      }else if(this.toolSelected == 'invited_traveler') {
        this.toolSelected = 'ticketing';
        includedTools.push('ticketing');
      }
    }
    return flights.reduce(
      (accumulatedReservations, trips) => {
        // Return items' reservations by type (listing, ticketing, ...)
        if (includedTools.includes(trips.type) || this.isSearchFlightByPNR || this.isCobusAuthorization) {
          const acuReserv = accumulatedReservations.concat(trips.reservations);
          if (this.isSearchFlightByPNR) {
            // Return items without duplicates.
            // In cobus or cobus admin, there is the possibility of repeating pnrs
            // when the user creates the cobus admin authorization for self.
            return Array.from(new Set(acuReserv.map(a => a.pnr))).map(pnr => acuReserv.find(a => a.pnr === pnr));
          }
          return acuReserv;
        }
        return accumulatedReservations;
      },
      []
    );
  }

  isFlightsInsurances() {
    return this.showSharedItineraries && this.itemType === 'insurance';
  }

  redirectTo(type) {
    if (type === 'flights' && (this.toolSelected === 'ticketing' || this.toolSelected === 'invited_traveler')) {
      return this.router.navigate(['/flights/ticketing/searches/new']);
    }
    if (type === 'flights' && this.toolSelected === 'listing') {
      return this.router.navigate(['/flights/listing/searches/new']);
    }
    if (type === 'flights' && (this.toolSelected === 'company_business' || this.toolSelected === 'cobus_admin')) {
      return this.router.navigate(['/flights/cobus_admin/searches/new']);
    }
    if (this.isFraudUser) {
      return this.router.navigate(['/flights']);
    }
    if (type === 'insurance') {
      return this.router.navigate(['/trip_insurances']);
    }
    return this.router.navigate([`/search/${type}`]);
  }

  groupBy(id) {
    this.sectionSelected.emit(id);
    return this.updateUrl(id);
  }

  updateUrl(page) {
    const routes = keysJson.tripsRoutes;
    const queryParams = routes[page]?.queryParams ?? {};
    return this.router.navigate([`/trips/${this.tabSelected}/${page}`], { relativeTo: this.route, queryParams: queryParams});
  }

  displayHotels(id) {
    // NOTICE: flights insurances uses sharedItems
    this.hotelType = id;
    if (id === 'shared') {
      this.showSharedItineraries = true;
      this.showPast = true;
      if (!this.sharedItems.loaded) {
        this.loadSharedItems.emit();
      }
    } else {
      this.showSharedItineraries = false;
    }
  }

  displayInsurance(id) {
    // NOTICE: flights insurances uses sharedItems
    if (this.insuranceType !== id) {
      this.insuranceType = id;
      const payload = { pageNumber: 1, type: id };
      if (id === 'hotels') {
        payload.pageNumber = this.futureItems.pageLoaded + 1;
        this.showSharedItineraries = false;
        this.loadMoreFutureItems.emit(payload);
      } else {
        payload.pageNumber = this.sharedItems.pageLoaded + 1;
        this.showSharedItineraries = true;
        this.loadSharedItems.emit(payload);
      }
    }
  }

  showAvailablePasses() {
    const modalRef = this.modalService.open(PassesModalComponent, { size: 'lg' });
    modalRef.componentInstance.remainingFtp = this.flightsExtras ? this.flightsExtras['remainFtp'] : null;
    modalRef.componentInstance.remainingRetireePasses = this.flightsExtras['remainRetireeProgram'] ? this.flightsExtras['remainRetireeProgram'][0] : null;
  }

  displayPassesLink() {
    return this.flightsExtras && (this.flightsExtras['remainFtp'] || this.flightsExtras['remainRetireeProgram']);
  }

  set showPast(val) {
    this._showPast = val;
  }

  get showPast() {
    return this.itemType !== 'insurance' && this._showPast;
  }

  get loading() {
    return (this.futureItems.pageLoaded === 0
      || (this.futureItems && this.futureItems.pending && !this.scrollLoading)
      || (this.sharedItems && this.sharedItems.pending && !this.scrollLoading )
      || (this.pastItems && this.pastItems.pending && !this.scrollLoading)
      || (this.pastSharedItems && this.pastSharedItems.pending && !this.scrollLoading)
      || (this.loadingSearch && !this.scrollLoading));
  }

  loadMoreItems() {
    this.scrollLoading = true;
    if (!this.isSearchFlightByPNR) {
      if (!this.futureItems.lastPage) {
        this.addFutureItems();
      } else if (this.showPast) {
        this.addPastItems();
      }
    }
  }

  addFutureItems() {
    if (this.showSharedItineraries) {
      this.addItems(this.sharedItems, this.maxSharedItems, this.sharedItems.reservations, this.loadSharedItems);

    } else {
      this.addItems(this.futureItems, this.maxFutureItems, this.futureReservations, this.loadMoreFutureItems);
    }
  }

  addPastItems() {
    if (this.showSharedItineraries) {
      this.addItems(this.pastSharedItems, this.maxSharedItems, this.pastSharedItems.reservations, this.loadMorePastItems);
    } else {
      this.addItems(this.pastItems, this.maxPastItems, this.pastReservations, this.loadMorePastItems);
    }
  }

  addItems(items, maxItems, reservations, emitter) {
    if (items.lastPage) {
      this.scrollLoading = false;
    }
    if (
      items
      && maxItems + this.infiniteScrollIncrement > reservations.length
      && !items.lastPage && !items.pending
    ) {
      emitter.emit(this.getLoadMorePayload(items));
    }
  }

  getLoadMorePayload(items) {
    const nextPage = items.pageLoaded + 1;
    let payload: number | { pageNumber: number, type: 'hotels' | 'flights' } = nextPage;
    if (this.itemType === 'insurance') {
      payload = { pageNumber: nextPage, type: this.insuranceType };
    }
    return payload;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  parsePNR() {
    this.pnr = (this.pnr) ? this.pnr.toUpperCase().trim() : '';
    if (this.pnr === '' && this.isSearchFlightByPNR) {
      this.isFlightPNRClean.emit(true);
      this.showPast = false;
      this.isSearchFlightByPNR = false;
      this.loadingSearch = false;
    }
  }

  searchByPNR() {
    if (this.pnr) {
      this.loadFlightsByPNR.emit({ pnr: this.pnr, section: this.toolSelected });
      this.isSearchFlightByPNR = true;
      this.loadingSearch = true;
    }
  }

  get noFlightsWhenSearchFlightsByPNR() {
    if (this.isSearchFlightByPNR && !this.loading) {
      return (this.futureReservations.length === 0 && this.pastReservations.length === 0);
    }
    return false;
  }

  validateRepeatedPastHotels() {
    this.pastReservations = (this._pastItems.reservations as HotelTrip[]).filter((pastReservation, index, self) => {
      const existsInFuture = (this._futureItems.reservations as HotelTrip[]).some((futureItem) => futureItem.itineraryId === pastReservation.itineraryId);
      const isUnique = index === self.findIndex((p) => p.itineraryId === pastReservation.itineraryId);
      const isFirstOccurrence = index === 0;
      return !existsInFuture && (isUnique || isFirstOccurrence);
    });
  }

  setCanSearchByPNR() {
    this.canSearchByPNR = this.isUserSelectedByOperator || this.featureFlagService.isFeatureEnabled('search_by_pnr');
  }
}
