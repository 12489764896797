import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AmbassadorReview } from '../../models/ambassador-review.model';
import { take } from 'rxjs/operators';

@Component({
  selector: 'brand-ambassador-review',
  templateUrl: './brand-ambassador-review.html',
  styleUrls: ['./brand-ambassador-review.scss']
})

export class BrandAmbassadorReviewComponent implements OnInit, OnDestroy {
  subscription :any;
  selectedAmbassador :AmbassadorReview;

  constructor(
    protected translateService: TranslateService
  ) {}

  ngOnInit() {
    this.subscription = this.translateService.get('review.ambassador_review').pipe(take(1)).subscribe(
      (ambassadors) => {
        const selectedAmbassadorIndex = this.randomInt(ambassadors.length);
        this.selectedAmbassador = ambassadors[selectedAmbassadorIndex];

        if (!this.selectedAmbassador.avatar) {
          this.selectedAmbassador.avatar = 'angular_assets/images/customer-headshots/ambassador_placeholder.png';
        }
      }
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  randomInt(ceil :number) :number {
    return Math.floor(Math.random() * ceil);
  }

}
