import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, Renderer2, RendererFactory2, ViewChild } from '@angular/core';
import { GamificationService } from 'app/shared/services/gamification/gamification.service';
import { GamificationCampaignConfig } from 'app/shared/models/gamification.model';
import { AnimationBuilder, animate, keyframes, style } from '@angular/animations';

@Component({
  selector: 'animation-campaign',
  templateUrl: './animation-campaign.component.html',
  styleUrls: ['./animation-campaign.component.scss'],
})
export class AnimationCampaignComponent implements AfterViewInit, OnDestroy {
  @Input() campaignConfig: GamificationCampaignConfig ;
  @Input() mode: 'angularAnimate' | "css" = 'angularAnimate';

  imageUrl = null;
  isImageLoaded = false;
  animationState = 'void';
  render: Renderer2;

  @ViewChild('campaignImage', { static: false }) campaignImage: ElementRef;
  @ViewChild('cssContainer', { static: false }) cssContainer: ElementRef;
  @ViewChild('imageContainer', { static: false }) imageContainer: ElementRef;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private gamificationService: GamificationService,
    private rendererFactory: RendererFactory2,
    private animationBuilder: AnimationBuilder
  ) { }

  ngAfterViewInit() {
    this.loadCampaign();
  }

  async loadCampaign() {
    if (this.campaignConfig !== null && this.mode === 'angularAnimate') {
      this.changeDetector.detectChanges();
      this.imageUrl = this.gamificationService.getCampaignImage(this.campaignConfig.image);
      const campaignImage = <HTMLImageElement>this.campaignImage.nativeElement;
      campaignImage.style.width = this.campaignConfig.animateConfig.width || '20rem';
      campaignImage.style.height = this.campaignConfig.animateConfig.height || '20rem';
      campaignImage.onload = () => {
        this.preparePlayer();
      };
    }
    if (this.campaignConfig !== null && this.mode === 'css') {
      this.createRenderEnv();
    }
  }

  createRenderEnv() {
    const render = this.rendererFactory.createRenderer(null, null);
    const container = render.createElement('div');

    if (this.campaignConfig.containerHTML) {
      container.innerHTML = this.campaignConfig.containerHTML;
    }

    const style = render.createElement('style');
    style.innerHTML = this.campaignConfig.cssStyle;
    container.classList.add(`${this.campaignConfig.animation}`);
    render.appendChild(this.cssContainer.nativeElement, container);
    render.appendChild(this.cssContainer.nativeElement, style);
    this.render = render;
    container.addEventListener('animationend', () => {
      container.remove();
    });
  }

  buildAngularAnimate() {
    const animationFrames = [];
    const config = this.campaignConfig.animateConfig;
    if (config.animate.keyframe) {
      for (let index = 0; index < config.animate.keyframe.length; index++) {
        const frame = config.animate.keyframe[index];
        animationFrames.push(style({ ...frame }));

      }
      if (animationFrames.length > 0) {
        return this.animationBuilder.build([
          style({ ...config.style }),
          animate(config.animate.duration, keyframes(animationFrames))
        ]);
      }
    }
    return this.animationBuilder.build([style(config.style), animate(config.animate.duration)]);
  }

  preparePlayer() {
    const animate = this.buildAngularAnimate();
    const player = animate.create(this.imageContainer.nativeElement);
    this.isImageLoaded = true;
    player.init();
    player.onDone(() => {
     this.imageContainer.nativeElement.remove();
    });
    player.play();  }

  ngOnDestroy(): void {
    if (this.render) { this.render.destroy(); }
  }


}
