<div class="cvv-modal">
  <div class="modal-header">
    <h4 class="modal-title">{{'flights.booking.ccvinfo_title' | translate}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()">

    </button>
  </div>
  <div class="modal-body">
    <cvv-information [cardType]="cardType"></cvv-information>
  </div>
</div>
