<div class="breadcrumbs">
  <ng-container *ngIf="goback">
    <a class="gtm-breadcrumbs-back goback" href="javascript:void(0)" (click)="goBack()" data-cy="hotel-back-button">
      <fa-icon [icon]="icons.faArrowLeft" class="me-2"></fa-icon> {{ title | translate }}
    </a>
  </ng-container>
  <ng-container *ngIf="!goback">
    <span *ngFor="let link of links; let i = index">
      <a class="gtm-breadcrumbs" *ngIf="!isLast(i)" [routerLink]="[link.url, link.params]">{{link.label}}</a>
      <span *ngIf="isLast(i)">{{link.label}}</span>
    </span>
  </ng-container>
</div>
