import { AfterViewInit, Component, Input, OnDestroy, Renderer2, ChangeDetectorRef } from '@angular/core';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { GeneralHelper } from 'app/shared/helpers/general.helper';
import { MeiliHelper } from 'app/shared/helpers/meili.helper';
import { getAirportByCode } from 'app/shared/store/reducers';
import * as moment from 'moment';
import { take } from 'rxjs/operators';

@Component({
  selector: 'need-a-car-widget',
  styleUrls: ['./need-a-car-widget.scss'],
  templateUrl: './need-a-car-widget.html'
})

export class NeedACarWidgetComponent implements AfterViewInit, OnDestroy {

  @Input() destination;
  @Input() checkin;
  @Input() checkout;
  @Input() cityName: string;
  @Input() latitude: number;
  @Input() longitude: number;
  @Input() hotelName: string;
  @Input() fromFlightCheckout: boolean = false;
  MEILI_PTID = environment.carsProvider.meili.connect.ptid;
  scriptElement: HTMLScriptElement;
  errorLoading = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private generalHelper: GeneralHelper,
    private meiliHelper: MeiliHelper,
    private renderer: Renderer2,
    private store: Store,
  ) {}

  ngAfterViewInit(): void {
    if (this.fromFlightCheckout) {
      this.formatCheckDates();
    }
    if (!this.cityName) {
      this.store.select(getAirportByCode(this.destination)).pipe(take(1)).subscribe(airport => {
        this.cityName = airport?.city;
      });
    }
    this.cdr.detectChanges();

    this.loadToken().then(() => {
      this.scriptElement = this.renderer.createElement('script');
      this.scriptElement.src = environment.carsProvider.meili.connect.url;
      this.scriptElement.onload = () => {};
      this.scriptElement.onerror = () => {
        this.errorLoading = true;
      }
      this.renderer.appendChild(document.body, this.scriptElement);
    });
  }

  formatCheckDates() {
    // If the flight is one way, add 2 days to the checkout.
    if (moment(this.checkin).format('YYYY-MM-DD') === moment(this.checkout).format('YYYY-MM-DD')) {
      this.checkout = moment(this.checkout).add(2, 'days');
    }
    // Add 1 hour to allow the user time to pick up the car.
    this.checkin = moment(this.checkin).add(1, 'hours');
    this.checkout = moment(this.checkout).add(1, 'hours');
  }

  loadToken(): Promise<void> {
    return new Promise((resolve) => {
      const searchingToEncode = this.meiliHelper.getSearchQuery(this.checkin, this.checkout, this.destination, this.latitude, this.longitude, this.hotelName);
      const jsonString = JSON.stringify(searchingToEncode);
      const mliRootElement = document.getElementById('mli-root');
      if (mliRootElement) {
        this.renderer.setAttribute(mliRootElement, 'data-query', this.generalHelper.utf8ToBase64(jsonString));
      }
      resolve();
    });
  }

  ngOnDestroy() {
    if (this.scriptElement) {
      this.renderer.removeChild(document.head, this.scriptElement);
      this.scriptElement = null;
    }
  }
}
