import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'translateRelationship', pure: false })
export class TranslateRelationshipPipe implements PipeTransform {

  transform(value: string, ...args: any[]): string {
    const val = value.toLocaleLowerCase().split(' ').join('_');
    const translation =  this.translate.instant(`flights.search.select_passenger.relations.${val}`);
    if (translation === `flights.search.select_passenger.relations.${val}`) {
      return value;
    }
    return this.translate.instant(`flights.search.select_passenger.relations.${val}`);
  }

  constructor(
    private translate: TranslateService,
  ) {}
}
