import { environment } from '@env/environment';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {
  private readonly ID_AIRLINE_CODE = 'IDT';

  constructor(
    private http: HttpClient
  ) { }

  request(params) {
    let airlineCode = null;
    if (params?.airlineCode) {
      airlineCode = params.airlineCode.toUpperCase();
    } else {
      if (params.airline === 'ID90 TRAVEL') {
        airlineCode = this.ID_AIRLINE_CODE;
      } else {
        let airline = params.airline.split('(');
        airline = airline[1].split(')');
        airlineCode = airline[0].trim();
      }
    }

    const parsedParams = {
      organization_id: params.organizationId,
      employee_number: params.memberEmail.toString().trim(),
      airline_code: airlineCode,
      new_reset_link: true
    };
    return this.http.post(`${environment.apiUrl}/api/v1/members/reset_password.json`, parsedParams);
  }

  validate(hash) {
    return this.http.get(`${environment.apiUrl}/api/v1/members/reset_passwords/${hash}.json`, {});
  }

  update(params) {
    return this.http.put(`${environment.apiUrl}/api/v1/members/reset_password.json`, params);
  }

  requestCode(params) {
    return this.http.post(`${environment.apiUrl}/api/v1/mms_reset_password/generate_verification_code`, params);
  }

  validateCode(params) {
    return this.http.post(`${environment.apiUrl}/api/v1/mms_reset_password/validate_verification_code`, params);
  }

  verifyOptions(params) {
    let airlineCode = null;
    if (params?.airlineCode) {
      airlineCode = params.airlineCode.toUpperCase();
    } else {
      if (params.airline === 'ID90 TRAVEL') {
        airlineCode = this.ID_AIRLINE_CODE;
      } else {
        let airline = params.airline.split('(');
        airline = airline[1].split(')');
        airlineCode = airline[0].trim();
      }
    }
    const p = {
      employee_number: params.memberEmail.toString().trim(),
      airline_code: airlineCode,
    };
    return this.http.get(`${environment.apiUrl}/api/v1/verify_user`, { params: p });
  }
}
