import { Component, Input } from '@angular/core';

@Component({
  selector: 'insurance-table',
  templateUrl: 'insurance-table.html',
  styleUrls: ['insurance-table.scss']
})
export class InsuranceTableComponent {
  @Input() tldr = false;

  constructor() { }

}
