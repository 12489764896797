import { CreditCard } from 'app/shared/models/credit-card';
import { Address } from 'app/shared/models/address';
import { PaypalInformation } from 'app/shared/models/paypal-information';

export class PaymentInformation {
  billingAddressRequired: boolean;
  address = new Address();
  cardNumber: String;
  cardType: String;
  ccv: String;
  city: String;
  country: String;
  expirationMonth: String;
  expirationYear: String;
  expiration?: String;
  firstName: String;
  lastName: String;
  state: String;
  zipCode: String;
  isWorldpayToken: boolean;
  isPayPalToken: boolean;
  isCash: boolean;
  authorizationId: string;
  isBusiness: boolean;
  paypalInformation = new PaypalInformation();
  isAffirmToken: boolean;
  affirmToken: string = null;

  constructor(config) {
    this.cardNumber = config.cardNumber || '';
    this.cardType = config.cardType || '';
    this.ccv = config.ccv || '';
    this.expirationMonth = config.expirationMonth || '';
    this.expirationYear = config.expirationYear || '';
    this.expiration = '';
    this.firstName = config.firstName || '';
    this.lastName = config.lastName || '';
    this.isWorldpayToken = config.isWorldpayToken || false;
    this.isPayPalToken = config.isPayPalToken || false;
    this.isCash = config.isCash || false;
    this.authorizationId = config.authorizationId || '';
    this.billingAddressRequired = config.billingAddressRequired || false;
    this.address.street = config.address || '';
    this.address.city = config.city || '';
    this.address.country = config.country || '';
    this.address.state = config.state || '';
    this.address.zipCode = config.zipCode || '';
    this.isBusiness = config.isBusiness || false;
    this.paypalInformation.accountName = config.accountName || '';
    this.paypalInformation.userName = config.userName || '';
  }

  isValid() {
    return(
      this.isCash ||
      this.isPayPalToken ||
      (
        this.cardNumber !== '' &&
        this.cardType !== '' &&
        this.expirationMonth !== '' &&
        this.expirationYear !== '' &&
        this.firstName !== '' &&
        this.lastName !== '' &&
        (this.billingAddressRequired ? this.address.isValid() : true)
      )
    );
  }

  getExpirationMonth() {
    return this.expirationMonth;
  }

  getExpirationYear() {
    return this.expirationYear;
  }

  applyCardValues(card: CreditCard) {
    this.cardNumber = card.token;
    this.cardType = card.payment_method_id;
    this.expirationMonth = card.expiration_date.split('-')[1];
    this.expirationYear = card.expiration_date.split('-')[0] && card.expiration_date.split('-')[0].slice(-2);
    this.expiration = `${this.expirationMonth}/${this.expirationYear}`;
    this.firstName = card.first_name;
    this.lastName = card.last_name;
    this.isWorldpayToken = true;
    this.isPayPalToken = false;
    this.isCash = false;
    this.address.street = card.address;
    this.address.city = card.city;
    this.address.country = card.country;
    this.address.zipCode = card.zip;
    this.address.state = card.state ? card.state : this.address.state;
    this.isBusiness = card.is_business === '1';
  }

  formatExpirationDate(date) {
    const splitDate = date.split('/');

    if (splitDate.length === 2) {
      return `20${splitDate[1]}-${splitDate[0]}`;
    }

    return date;
  }

  resetCard() {
    this.cardNumber = '';
    this.expiration = '';
    this.expirationMonth = '';
    this.expirationYear = '';
    this.cardType = '';
    this.ccv = '';
  }

  toJson() {
    return {
      address: this.address.street,
      first_name: this.firstName,
      last_name: this.lastName,
      cc_number: this.cardNumber.replace(/\s/g, ''),
      cc_type: this.cardType,
      security_code: this.ccv,
      city: this.address.city,
      state: this.address.state,
      country: this.address.country,
      zip: this.address.zipCode,
      expiration_month: this.expirationMonth,
      expiration_year: this.expirationYear,
      expiration: this.expiration,
      is_worldpay_token: this.isWorldpayToken,
      is_paypal_token: this.isPayPalToken,
      cash: this.isCash,
      authorization_id: this.authorizationId,
      is_business: this.isBusiness,
      is_affirm_token: this.isAffirmToken,
      affirm_token:this.affirmToken
    };
  }

  toJsonInsurance() {
    return {
      address: this.address.street,
      firstName: this.firstName,
      lastName: this.lastName,
      cardNumber: this.cardNumber,
      cardType: this.cardType,
      ccv: this.ccv,
      city: this.address.city,
      state: this.address.state,
      country: this.address.country,
      zipCode: this.address.zipCode,
      expirationMonth: this.expirationMonth,
      expirationYear: this.expirationYear,
      expiration: this.expiration,
      isWorldpayToken: this.isWorldpayToken,
      isPaypalToken: this.isPayPalToken,
      isCash: this.isCash,
      authorizationId: this.authorizationId
    };
  }
}
