import { Component, OnInit, Input, ChangeDetectionStrategy, ViewChild } from '@angular/core';

@Component({
  selector: 'app-announcement-bar',
  templateUrl: './announcement-bar.component.html',
  styleUrls: ['./announcement-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnouncementBarComponent implements OnInit {
  @ViewChild('announcementContainer', { static: false }) alert;
  internalShow: boolean;
  messageTokenNoLogged: string;

  public canShowMessage: boolean;
  public dontShowAgain: boolean;

  @Input() seeNotShowAgain: boolean = false;
  @Input() code: string;
  @Input() userID: number;

  get showMessage(): boolean {
    return (this.internalShow && this.canShowMessage);
  }

  get messageToken(): string {
    return this.userID ? `${this.code}-${this.userID}` : this.messageTokenNoLogged;
  }

  ngOnInit() {
    this.internalShow = true;
    this.messageTokenNoLogged = `${this.code}-0`;
    this.handleCanShowMessage();
  }

  handleCanShowMessage(): void {
    const userInSession = sessionStorage.getItem(this.messageToken);
    const userInLocal = localStorage.getItem(this.messageToken);
    const userNoLogged = sessionStorage.getItem(this.messageTokenNoLogged);
    this.canShowMessage = userInSession === null && userInLocal === null && !userNoLogged;
    this.handleUserNoLoggedMessage();
  }

  handleUserNoLoggedMessage(): void {
    const userInSession = sessionStorage.getItem(this.messageTokenNoLogged);
    if (userInSession) {
      sessionStorage.setItem(this.messageToken, 'true');
      sessionStorage.removeItem(this.messageTokenNoLogged);
    }
  }

  onClose(): void {
    this.internalShow = false;
    if (!this.dontShowAgain || this.messageTokenNoLogged === this.messageToken) {
      sessionStorage.setItem(this.messageToken, 'true');
    } else {
      localStorage.setItem(this.messageToken, 'true');
    }
  }
}
