import { HotelsRecentViewsActions, HotelsRecentViewsApiActions } from 'app/shared/store/actions';
import { HotelsRecentSearch } from 'app/shared/models/hotels-recent-search';

export interface State {
  destinations: HotelsRecentSearch[] | null;
  pending: boolean;
  error: any | null;
  loaded: boolean;
}

export const initialState: State = {
  destinations: [],
  pending: false,
  error: null,
  loaded: false
};

export function reducer(
  state = initialState,
  action: HotelsRecentViewsActions.HotelsRecentViewsActionsUnion | HotelsRecentViewsApiActions.HotelsRecentViewsApiActionsUnion
) {
  switch (action.type) {
    case HotelsRecentViewsActions.HotelsRecentViewsActionTypes.Load:
      return {
        ...state,
        destinations: [],
        error: null,
        pending: true
      };
    case HotelsRecentViewsApiActions.HotelsRecentViewsApiActionTypes.LoadSuccess:
      return {
        destinations: action.payload.destinations,
        error: null,
        pending: false,
        loaded: true
      };
    case HotelsRecentViewsApiActions.HotelsRecentViewsApiActionTypes.LoadFailure:
      return {
        destinations: [],
        error: action.payload.error,
        pending: false,
        loaded: false
      };
    default:
      return state;
  }
}

export const getDestinations = (state: State) => state.destinations;
export const getPending = (state: State) => state.pending;
export const getLoaded = (state: State) => state.loaded;
