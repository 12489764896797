<ngb-alert *ngIf="success" type="success" (closed)="closeAlert()">{{ success | translate }}</ngb-alert>
<ngb-alert *ngIf="error" type="danger" (closed)="closeAlert()">{{ error }}</ngb-alert>
<div class="d-sm-none back-button">
  <button type="button" class="btn btn-link" (click)="backToTabs()">
    <fa-icon [icon]="icons.faChevronLeft" aria-hidden="true"></fa-icon>
    {{ "back" | translate }}
  </button>
</div>
<div class="profile-title primary-content">
  {{ "profile.info.passport" | translate }}
</div>
<div class="card shadow-none mb-4">
  <div class="card-body passport">
    <div [formGroup]="profileForm">
      <div formGroupName="passport">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group form-floating">
              <input type="text" formControlName="passportFirstName" id="passportFirstName" class="form-control validate with-background"/>
              <label for="passportFirstName">{{ "profile.info.passportFirstName" | translate }}</label>
              <div
                class="error-message"
                *ngIf="passportFirstName.errors !== null && passportFirstName.touched"
                [innerHtml]="formErrorsHelper.errorMessage(passportFirstName, ('profile.info.passportFirstName' | translate))">
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group form-floating">
              <input type="text" formControlName="passportMiddleName" id="passportMiddleName" class="form-control validate with-background"/>
              <label for="passportMiddleName">{{ "profile.info.passportMiddleName" | translate }}</label>
              <div
                class="error-message"
                *ngIf="passportMiddleName.errors !== null && passportMiddleName.touched"
                [innerHtml]="formErrorsHelper.errorMessage(passportMiddleName, ('profile.info.passportMiddleName' | translate))">
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group form-floating">
              <input type="text" formControlName="passportLastName" id="passportLastName" class="form-control validate with-background" />
              <label for="passportLastName">{{ "profile.info.passportLastName" | translate }}</label>
              <div
                class="error-message"
                *ngIf="passportLastName.errors !== null && passportLastName.touched"
                [innerHtml]="formErrorsHelper.errorMessage(passportLastName, ('profile.info.passportLastName' | translate))">
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group form-floating">
              <select class="form-control form-select form-select with-background" id="passportNationality" formControlName="passportNationality" >
                <option *ngFor="let country of countries" [value]="country.code">{{ country.name }}</option>
              </select>
              <label>{{ "profile.info.passportNationality" | translate }}</label>
              <div
                class="error-message"
                *ngIf="passportNationality.errors !== null && passportNationality.touched"
                [innerHtml]="formErrorsHelper.errorMessage(passportNationality, ('profile.info.passportNationality' | translate))">
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group form-floating">
              <input type="text" formControlName="passportNumber" id="passportNumber" class="form-control validate with-background"  />
              <label for="passportNumber">{{ "profile.info.passportNumber" | translate }}</label>
              <div
                class="error-message"
                *ngIf="passportNumber.errors !== null && passportNumber.touched" data-cy="error-passportNumber"
                [innerHtml]="formErrorsHelper.errorMessage(passportNumber, ('profile.info.passportNumber' | translate))">
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6" *ngIf="profileConfig.passportNumberDomestic?.show">
            <div class="form-group form-floating">
              <input type="text" formControlName="passportNumberDomestic" id="passportNumberDomestic" class="form-control validate with-background" />
              <label for="passportNumberDomestic">{{ "profile.info.passportNumberDomestic" | translate }}</label>
              <div
                class="error-message"
                *ngIf="passportNumberDomestic.errors !== null && passportNumberDomestic.touched"
                [innerHtml]="formErrorsHelper.errorMessage(passportNumberDomestic, ('profile.info.passportNumberDomestic' | translate))">
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6" *ngIf="profileConfig.passportIssuanceDate?.show">
            <div class="form-group">
              <div class="position-relative ngbDatepicker form-floating">
                <input
                  class="form-control with-background"
                  name="passportIssuanceDate"
                  [minDate]="minDate"
                  [startDate]="formatStartIssuanceDate()"
                  [value]="startPassportIssuanceDate"
                  [maxDate]="maxDate"
                  ngbDatepicker
                  #d="ngbDatepicker"
                  (click)="d.toggle()"
                  (dateSelect)="onInsurranceDateSelected($event)"
                  data-cy="passportIssuanceDate"
                />
                <label>{{ "profile.info.passportIssuanceDate" | translate }}(yyyy-mm-dd)</label>
                <fa-icon [icon]="icons.faCalendar" class="input-icon" (click)="d.toggle()"></fa-icon>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6" *ngIf="profileConfig.passportExpirationDate?.show">
            <div class="form-group">
              <div class="position-relative ngbDatepicker form-floating">
                <input
                  class="form-control with-background"
                  name="passportExpirationDate"
                  [minDate]="minExpirationDate"
                  [startDate]="formatStartExpirationDate()"
                  [value]="startPassportExpirationDate"
                  [maxDate]="maxDatePassport"
                  ngbDatepicker
                  #d2="ngbDatepicker"
                  (click)="d2.toggle()"
                  (dateSelect)="onExpirationDateSelected($event)"
                  data-cy="passportExpirationDate"
                />
                <label>{{ "profile.info.passportExpirationDate" | translate }}(yyyy-mm-dd)</label>
                <fa-icon [icon]="icons.faCalendar" class="input-icon" (click)="d2.toggle()"></fa-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6" *ngIf="countries">
            <div class="form-group form-floating">
              <select class="form-control form-select form-select with-background" id="passportIssuanceCountry" formControlName="passportIssuanceCountry">
                <option *ngFor="let country of countries" [value]="country.code">{{ country.name }}</option>
              </select>
              <label>{{ "profile.info.passportIssuanceCountry" | translate }}</label>
              <div
                class="error-message"
                *ngIf="passportIssuanceCountry.errors !== null && passportIssuanceCountry.touched"
                [innerHtml]="formErrorsHelper.errorMessage(passportIssuanceCountry, ('profile.info.passportIssuanceCountry' | translate))">
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6" *ngIf="countries">
            <div class="form-group form-floating">
              <select class="form-control form-select form-select with-background" id="passportCountryBirth" formControlName="passportCountryBirth">
                <option *ngFor="let country of countries" [value]="country.code">{{ country.name }}</option>
              </select>
              <label>{{ "profile.info.passportCountryBirth" | translate }}</label>
              <div
                class="error-message"
                *ngIf="passportCountryBirth.errors !== null && passportCountryBirth.touched"
                [innerHtml]="formErrorsHelper.errorMessage(passportCountryBirth, ('profile.info.passportCountryBirth' | translate))">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<p class="text-end">
  <button type="button" class="btn btn-md btn-primary gtm-update-profile" (click)="onEditPassport()" data-cy="update" *ngIf="!loading">
    {{ "profile.info.update" | translate }}
  </button>
  <button type="button" class="btn btn-md btn-primary" *ngIf="loading" disabled>
    <fa-icon [icon]="icons.faCircleNotch"></fa-icon>
    {{ "invite.invite_processing" | translate }}
  </button>
</p>
