import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { PaymentMethodsActions, PaymentMethodsApiActions } from '../actions';
import { exhaustMap, map, catchError, } from 'rxjs/operators';
import {  merge, of } from 'rxjs';
import { PaymentMethodsService } from '../../services/payment-methods/payment-methods.service';
import { PaymentMethod } from 'app/profile/models/payment-method';

@Injectable()
export class PaymentMethodsEffects {

  addCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType<PaymentMethodsActions.Add>(PaymentMethodsActions.PaymentMethodsActionTypes.Add),
      map(action => action.payload),
      exhaustMap((card: PaymentMethod) =>
        this.paymentMethodsService.create(card).pipe(
          map(res => new PaymentMethodsApiActions.AddCardSuccess(res)),
          catchError(error => of(new PaymentMethodsApiActions.AddCardFailure(error)))
        )
      )
    )
  );

  daleteCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType<PaymentMethodsActions.Delete>(PaymentMethodsActions.PaymentMethodsActionTypes.Delete),
      map(action => action.payload),
      exhaustMap((token: any) =>
        this.paymentMethodsService.delete({ token }).pipe(
          map(res => new PaymentMethodsActions.DeleteSuccess(res)),
          catchError(error => of(new PaymentMethodsActions.DeleteFailure(error)))
        )
      )
    )
  );

  getAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType<PaymentMethodsActions.GetAll>(PaymentMethodsActions.PaymentMethodsActionTypes.GetAll),
      map(action => action.payload),
      exhaustMap((includeBusinessCards: any) =>
        this.paymentMethodsService.getAll(includeBusinessCards).pipe(
          map(res => new PaymentMethodsActions.GetAllSucess(res)),
          catchError(error => of(new PaymentMethodsActions.GetAllFailure(error)))
        )
      )
    )
  );

  editCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType<PaymentMethodsActions.Edit>(PaymentMethodsActions.PaymentMethodsActionTypes.Edit),
      map(action => action.payload),
      exhaustMap((payload: any) =>
      this.paymentMethodsService.update(payload.card).pipe(
          map(res => new PaymentMethodsActions.EditSuccess(res)),
          catchError(error => of(new PaymentMethodsActions.EditFailure(error)))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private paymentMethodsService: PaymentMethodsService
  ) { }
}
