import { Action } from '@ngrx/store';
import { AllInclusiveLocation } from 'app/shared/models/all-inclusive-location';

export enum AllInclusiveApiActionTypes {
  LoadSuccess = '[All Inclusive/API] Load Success',
  LoadFailure = '[All Inclusive/API] Load Failure',
}

export class LoadSuccess implements Action {
  readonly type = AllInclusiveApiActionTypes.LoadSuccess;

  constructor(public payload: AllInclusiveLocation[]) {}
}

export class LoadFailure implements Action {
  readonly type = AllInclusiveApiActionTypes.LoadFailure;

  constructor(public payload: { error: any }) {}
}

export type AllInclusiveApiActionsUnion = LoadSuccess
  | LoadFailure;
