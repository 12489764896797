import { Component } from '@angular/core';

@Component({
  selector: 'our-services',
  templateUrl: './our-services.html',
  styleUrls: ['./our-services.scss']

})

export class OurServicesPage {

  constructor() { }
}
