import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { AllInclusiveActions, AllInclusiveApiActions } from '../actions';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { AllInclusiveService } from 'app/shared/services/all-inclusive/all-inclusive.service';

@Injectable()
export class AllInclusiveEffects {

  load$ = createEffect(()=>
    this.actions$.pipe(
      ofType<AllInclusiveActions.Load>(AllInclusiveActions.AllInclusiveActionTypes.Load),
      switchMap(() =>
        this.allInclusiveService.get().pipe(
          map(res => new AllInclusiveApiActions.LoadSuccess(res)),
          catchError(error => of(new AllInclusiveApiActions.LoadFailure(error)))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private allInclusiveService: AllInclusiveService
  ) {}
}
