import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PromoCodeCreditsService {

  constructor(
    private http: HttpClient
  ) { }

  redeemCreditsPromotionCode(params): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/v1/credits_promotion_code`, params);
  }
  validCreditsPromotionCode(params) : Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/valid_signup_credits_promotion_code/${params}`);
  }
}
