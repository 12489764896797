<div class="card payment-methods-card">
	<div class="card-body">
		<div class="row align-items-center">
			<div class="col-md-2 d-none d-md-block" [ngClass]="{'grayscale' : isExpired}">
				<img class="img-fluid" [src]="'angular_assets/images/icons/' + item.payment_method_id + '.png'" />
			</div>
			<div class="col-md-5 col-6 mt-2 mt-md-0">
				<div class="secondary-content d-none d-md-block">{{ 'payment.card_number' | translate }}</div>
				<div class="secondary-content text-uppercase d-block d-md-none">{{ item.payment_method_id }}</div>
				<div class="tertiary-content">{{ 'payment.ending_in' | translate }} {{ item.card_number }}</div>
			</div>
			<div class="col-md-5 col-6 mt-2 mt-md-0 text-end">
				<div class="secondary-content">{{ 'payment.expiration' | translate }}</div>
				<div class="tertiary-content">{{ item.expiration_date | amParse:'YYYY-MM' | amDateFormat:'MM/YY' }}</div>
        		<div *ngIf="isExpired" class="error-message">{{'flights.booking.errors.CCExp' | translate}}</div>
			</div>
		</div>
	</div>
	<hr class="mb-0">
	<div class="row text-center align-items-center">
		<div class="col-md-3 col-xs-12 col-12 offset-md-2 order-2 order-md-1">
			<button type="button" class="btn btn-link text-danger gtm-manage-profile-cards" (click)="onDeleteOpen(item.token)" data-cy="delete-cc">
				{{ 'payment.delete_card' | translate }}
			</button>
		</div>
		<div class="col-md-3 col-xs-12 col-12 order-2 order-md-1">
			<button type="button" class="btn btn-link text-primary gtm-manage-profile-cards" (click)="onEditOpen(item)" data-cy="edit-cc">
				{{ 'payment.edit_card' | translate }}
			</button>
		</div>
		<div class="col-md-4 col-xs-12 col-12 order-1 order-md-2">
			<button type="button" *ngIf="!isPrimary && !isExpired" class="btn btn-outline-primary btn-sm my-2 w-75 gtm-manage-profile-cards" (click)="onPrimaryCliked()" data-cy="primary-cc">{{'payment.make_primary' | translate}} </button>
			<div *ngIf="isPrimary" class="badge rounded-pill text-bg-success"><fa-icon [icon]="icons.faCheckCircle" class="pe-2"></fa-icon>
				{{ 'mobile.profile.credit_cards.primary_card' | translate }}
			</div>
		</div>
	</div>
</div>
