import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class InviteFriendsService {

  constructor(private http: HttpClient) { }

  sendInviteCodes(params) {
    return this.http.post(`${environment.apiUrl}/api/v1/rms_invites/add_invited_member`, params);
  }

  getAllInvites(params?) {
    return this.http.get(`${environment.apiUrl}/api/v1/rms_invites/all_invites`, { params });
  }

  getStatInvites() {
    return this.http.get(`${environment.apiUrl}/api/v1/rms_invites/stats`, {});
  }

  getPromoValues() {
    return this.http.get<any>(`${environment.apiUrl}/api/v1/members/promotions/invite_rules.json`);
  }

  getInviteCode() {
    return this.http.get<any>(`${environment.apiUrl}/api/v1/rms_invites/invite_code`);
  }

  validInviteCode(inviteCode) {
    const params: HttpParams = new HttpParams()
    .set('inviteCode', inviteCode);
    return this.http.get<any>(`${environment.apiUrl}/api/v1/rms_invites/validate_invite_code`, { params });
  }

  validateExpirationInvite(uniqueCode) {
    const params: HttpParams = new HttpParams()
    .set('uniqueCode', uniqueCode);
    return this.http.get<any>(`${environment.apiUrl}/api/v1/rms_invites/validate_expiration_invite`, { params });
  }

}
