import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Directive({
  selector: '[clear-input]'
})
export class ClearInputDirective implements OnInit {
  clearButton: HTMLElement;
  icons = { faTimes };

  constructor(
    private element: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.buildButton();
    this.setupListener();
  }

  buildButton() {
    this.clearButton = document.createElement('button');
    this.renderer.setProperty(this.clearButton, 'innerHTML', '<fa-icon [icon]="icons.faTimes"></fa-icon>');
    this.renderer.addClass(this.clearButton, 'clear-input-button');
    this.renderer.setAttribute(this.clearButton, 'tabindex', '-1');
    this.renderer.setAttribute(this.clearButton, 'type', 'button');
    this.renderer.setStyle(this.element.nativeElement, 'padding-right', '20px');

    const parent = this.renderer.parentNode(this.element.nativeElement);
    this.renderer.appendChild(parent, this.clearButton);
  }

  setupListener() {
    this.renderer.listen(this.clearButton, 'click', () => {
      this.renderer.setProperty(this.element.nativeElement, 'value', '');
      this.element.nativeElement.dispatchEvent(new CustomEvent('keyup'));
    });
  }
}
