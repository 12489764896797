<div>
  <div class="alert alert-primary mt-3 rounded-2" role="alert" *ngIf="eligibleTravelerEmail">
    <p class="email-text">{{'profile.eligible_travelers.email_sent_to' | translate }}: <em>{{eligibleTravelerEmail}}</em></p>
  </div>

  <form [formGroup]="flightAccessForm">
    <ng-container
      *ngIf="eligibleTraveler.invitedTravelerStatus === INVITED_TRAVELER_STATUS.CONFIRMED && !eligibleTravelers.length"
    >
      <div class="row my-3">
        <div
          class="col"
          [innerHTML]="'profile.eligible_travelers.restrict_to_book' | translate : { name: eligibleTravelerDisplayName }"
        ></div>
      </div>
    </ng-container>
    <ng-container *ngIf="eligibleTraveler.invitedTravelerStatus !== INVITED_TRAVELER_STATUS.CONFIRMED">
      <div class="row my-2">
        <div
          class="col"
          [innerHTML]="'profile.eligible_travelers.allow_to_book' | translate : { name: eligibleTravelerDisplayName }"
        ></div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group form-floating">
            <input
              type="text"
              formControlName="email"
              id="flightAccessEmail"
              class="form-control validate with-background"
            />
            <label for="flightAccessEmail">{{ "profile.info.email" | translate }}</label>
            <div
              class="error-message"
              *ngIf="flightAccessEmail.errors !== null && flightAccessEmail.touched"
              [innerHtml]="formErrorsHelper.errorMessage(flightAccessEmail, ('profile.info.email' | translate))"
            ></div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="eligibleTravelers.length" formArrayName="eligibleTravelers">
      <div class="row mb-2">
        <div class="col fw-bold">
          {{ "profile.eligible_travelers.allow_to_book_for" | translate : { name: eligibleTravelerDisplayName } }}
        </div>
      </div>
      <div class="row" *ngFor="let traveler of eligibleTravelers; index as i" [formGroupName]="i">
        <div class="col">
          <div class="form-check form-switch">
            <input
              [attr.id]="'eligible' + traveler.id"
              class="form-check-input"
              type="checkbox"
              formControlName="enabled"
            />
            <label [attr.for]="'eligible' + traveler.id" class="form-check-label fw-normal">
              {{ traveler.firstName }} {{ traveler.lastName }}
            </label>
          </div>
        </div>
      </div>
    </ng-container>
  </form>
</div>
