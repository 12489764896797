import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ConfigFlags } from '../models/configFlags';

@Injectable({
  providedIn: 'root'
})
export class Oauth2Helper {
  loginPath = '/oauth2/login?airline_code=';
  constructor() {}

  inOauth2Login(airline, config?: ConfigFlags) {
    const oauth2Airlines = config?.oauth2_available_airlines?.split(' ') ?? [];
    return oauth2Airlines.includes(airline.toUpperCase());
  }

  login(airlineCode: string) {
    window.location.href = `${environment.apiUrl}${this.loginPath}${airlineCode}`;
  }
}
