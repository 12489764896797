import { Component, Input } from '@angular/core';
import { InsuranceTrip } from 'app/trips/models/insurance-trip';

@Component({
  selector: 'trip-insurance-info',
  templateUrl: './trip-insurance-info.html',
  styleUrls: ['./trip-insurance-info.scss']
})
export class TripInsuranceInfoComponent {
  @Input() trip: InsuranceTrip;
  @Input() showTitle = true;
}
