import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, QueryList, SimpleChanges, TemplateRef, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { StoryBlokNameDirective } from 'app/shared/directives/storyblok-name.directive';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';
import { StoryblokService } from 'app/shared/services/storyblok/storyblok.service';

const dynamicRadioGroup = 'dynamic-radio-group';
const dynamicCheckboxGroup = 'dynamic-checkbox-group';

@Component({
  selector: 'dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit, AfterViewInit {
  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;
  @ViewChildren(StoryBlokNameDirective) templates: QueryList<StoryBlokNameDirective>;
  @Input() formData:any;
  @Output() formSubmitted = new EventEmitter();
  dynamicForm: FormGroup= null;
  pending = true;

  constructor( private sanitizer: DomSanitizer,
    private storyblokService: StoryblokService,
    public formErrorsHelper: FormErrorsHelper,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef ) { }

  async ngOnInit() {
   this.buildFormControls();
  }

  ngAfterViewInit() {
    this.buildFormView();
    this.pending = false;
  }

  generateBootstrapColumn(column) {
    if (!column) return 'col-auto'
    const sizeMap = {
      "xsmall": "xs",
      "small": "sm",
      "medium": "md",
      "large": "lg",
      "xlarge": "xl"
    };
    let classes = [];
    for (const [key, value] of Object.entries(column)) {
      if (sizeMap[key]) {
        classes.push(`col-${sizeMap[key]}-${value}`);
      }
    }
    return classes.join(' ');
  }

  private buildFormView() {
    for (const key in this.formData) {
      if (Object.prototype.hasOwnProperty.call(this.formData, key) && Array.isArray(this.formData[key])) {
        for (let index = 0; index < this.formData[key].length; index++) {
          const template = this.findTemplate(this.formData[key][index].type) || this.findTemplate(this.formData[key][index].component) ;
          if(template) {
              this.renderComponent(template,this.formData[key][index],this.container);
          }
        }
      }
    }
  }

  private findTemplate(key): TemplateRef<any> {
    const templateRef = this.templates.find(component => component.name === key);
    return templateRef?.template || null;
  }

  private renderComponent(storyBlokComponent: TemplateRef<any>, data: any, container: ViewContainerRef) {
    const context = { data, dynamicForm: this.dynamicForm  };
    const view = storyBlokComponent.createEmbeddedView(context);
    container.insert(view);
  }

  private buildFormControls() {
    this.dynamicForm = this.formBuilder.group({});
    for (const formControl of this.formData?.form){
      let controlValidators = null;
      if (formControl?.component === dynamicRadioGroup)
       {
        this.dynamicForm.addControl(formControl.id, new FormControl('', Validators.required));
      }
      else if ( formControl?.component === dynamicCheckboxGroup ){
        this.dynamicForm.addControl(formControl.id, new FormControl('', Validators.requiredTrue));
      }
      else  {
        if ( formControl.validators && formControl.validators.length> 0 ){
          controlValidators = this.mapValidators(formControl.validators);
        }
        this.dynamicForm.addControl(formControl.id, new FormControl('', controlValidators));
      }
    }
  }

  mapValidators(validators: string[]) {
    const validatorsMap = {
      required: Validators.required,
      email: Validators.email,
    };

    return validators
      .map(name => validatorsMap[name])
      .filter(validator => validator !== undefined);
  }

  private markAllAsTouched(): void {
    Object.values(this.dynamicForm.controls).forEach(control => {
      control.markAsTouched();
    });
  }

  parseRichText(richText: any): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.storyblokService.parseTextRich(richText));
  };

  public onSubmit() {
    if (!this.dynamicForm.valid) {
      this.markAllAsTouched();
      return;
    }
    this.formSubmitted.emit(this.dynamicForm.value);
  }

}
