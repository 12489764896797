<div *ngIf="displayBanner" class="banner-container">
  <div class="d-flex align-items-center">
    <img
      src="angular_assets/images/logo.png"
      alt="id90 travel"
      class="logo mx-2"
    />
    <div class="text-container">
      <p class="m-0 title">{{ "branding.id90.site_name" | translate }}</p>
      <label class="m-0 tertiary-content">{{
        "web.nav.smart_app_banner.open_in_app" | translate
      }}</label>
    </div>
  </div>
  <button
    type="submit"
    class="btn btn-primary m-2 text-uppercase shadow-none"
    (click)="openInApp($event)"
  >
    {{ "web.nav.smart_app_banner.open" | translate }}
  </button>
</div>
