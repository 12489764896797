<ngb-alert *ngIf="success" type="success" (closed)="closeAlert()">{{ success | translate }}</ngb-alert>
<ngb-alert *ngIf="error" type="danger" (closed)="closeAlert()">{{ error }}</ngb-alert>
<div class="d-sm-none back-button">
  <button type="button" class="btn btn-link" (click)="backToTabs()">
    <fa-icon [icon]="icons.faChevronLeft" aria-hidden="true"></fa-icon>
    {{ "back" | translate }}
  </button>
</div>
<div class="profile-title primary-content">
  {{ "profile.info.sections.employment" | translate }}
</div>
<div class="card shadow-none mb-4">
  <div class="card-body employment-information">
    <div [formGroup]="profileForm">
      <div formGroupName="employment">
        <div class="row">
          <div class="col-12 col-md-6" *ngIf="profileConfig.employmentType?.show">
            <div class="form-group form-floating">
              <select class="form-control form-select form-select with-background" placeholder=" " id="employmentType" formControlName="employmentType">
                <option *ngFor="let employmentType of employmentTypeOptions" [value]="employmentType.value" >{{ employmentType.label }}</option>
              </select>
              <label>{{ "profile.info.employmentType" | translate }}</label>
              <div
                class="error-message"
                *ngIf="employmentType.errors !== null && employmentType.touched"
                [innerHtml]="formErrorsHelper.errorMessage(employmentType, translateService.get('profile.info.employmentType')['value'])">
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6" *ngIf="profileConfig.employmentStatus?.show">
            <div class="form-group form-floating">
              <select class="form-control form-select form-select with-background" placeholder=" " id="employmentStatus" formControlName="employmentStatus">
                <option *ngFor="let employmentStatus of employmentStatusOptions" [value]="employmentStatus.value">{{ employmentStatus.label }}</option>
              </select>
              <label>{{ "profile.info.employmentStatus" | translate }}</label>
              <div
                class="error-message"
                *ngIf="employmentStatus.errors !== null && employmentStatus.touched"
                [innerHtml]="formErrorsHelper.errorMessage(employmentStatus, translateService.get('profile.info.employmentStatus')['value'])">
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6" *ngIf="profileConfig.travelStatus?.show">
            <div class="form-group form-floating">
              <select class="form-control form-select form-select with-background" placeholder=" " id="travelStatus" formControlName="travelStatus">
                <option *ngFor="let travelStatus of travelStatusOptions" [value]="travelStatus.value" >{{ travelStatus.label }}</option>
              </select>
              <label>{{ "profile.info.travelStatus" | translate }}</label>
              <div
                class="error-message"
                *ngIf="travelStatus.errors !== null && travelStatus.touched"
                [innerHtml]="formErrorsHelper.errorMessage(travelStatus, translateService.get('profile.info.travelStatus')['value'])">
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6" *ngIf="profileConfig.hireDate?.show">
            <div class="form-group">
              <div class="position-relative ngbDatepicker form-floating">
                <input
                  class="form-control with-background"
                  placeholder=" "
                  name="hireDate"
                  [minDate]="minDate"
                  [startDate]="formatStartHireDate()"
                  [value]="startHireDate"
                  [maxDate]="maxDate"
                  [disabled]="!profileConfig.hireDate.enabled"
                  ngbDatepicker
                  #d="ngbDatepicker"
                  (click)="d.toggle()"
                  (dateSelect)="onHireDateSelected($event)"
                  [readonly]="true"
                />
                <label>{{ "profile.info.hireDate" | translate }}(yyyy-mm-dd)</label>
                <fa-icon [icon]="icons.faCalendar" class="input-icon" (click)="d.toggle()"></fa-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6" *ngIf="profileConfig.seniorityDate?.show">
            <div class="form-group">
              <div class="position-relative ngbDatepicker form-floating">
                <input
                  class="form-control with-background"
                  placeholder=" "
                  name="hireDate"
                  [minDate]="minDate"
                  [startDate]="formatStartSeniorityDate()"
                  [value]="startSeniorityDate"
                  [maxDate]="maxDate"
                  [disabled]="!profileConfig.seniorityDate.enabled"
                  ngbDatepicker
                  #d2="ngbDatepicker"
                  (click)="d2.toggle()"
                  (dateSelect)="onSeniorityDateSelected($event)"
                  [readonly]="true"
                />
                <label>{{ "profile.info.seniorityDate" | translate }}(yyyy-mm-dd)</label>
                <fa-icon [icon]="icons.faCalendar" class="input-icon" (click)="d2.toggle()"></fa-icon>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6" *ngIf="profileConfig.departmentName?.show">
            <div class="form-group form-floating">
              <input type="text" formControlName="departmentName" id="departmentName" class="form-control validate with-background" placeholder=" " />
              <label>{{ "profile.info.departmentName" | translate }}</label>
            </div>
          </div>
          <div class="col-12 col-md-6" *ngIf="profileConfig.costCenterCode?.show">
            <div class="form-group form-floating">
              <input type="text" formControlName="costCenterCode" id="costCenterCode" class="form-control validate with-background" placeholder=" " />
              <label>{{ "profile.info.costCenterCode" | translate }}</label>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="profileConfig.boardingPriority?.show">
          <div class="col-12 col-md-6">
            <div class="form-group form-floating">
              <input type="text" formControlName="boardingPriority" id="boardingPriority" class="form-control validate with-background" placeholder=" " />
              <label>{{ "profile.info.boarding_priority" | translate }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<p class="text-end">
  <button
    type="button"
    class="btn btn-md btn-primary gtm-update-profile"
    (click)="onEditEmployment()"
    *ngIf="!loading">
    {{ "profile.info.update" | translate }}
  </button>
  <button type="button" class="btn btn-md btn-primary" *ngIf="loading" disabled>
    <fa-icon [icon]="icons.faCircleNotch"></fa-icon>
    {{ "invite.invite_processing" | translate }}
  </button>
</p>
