import { Platform } from "@angular/cdk/platform";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { environment } from "@env/environment";

@Injectable({
    providedIn: 'root'
})
export class SmartAppService {
    private readonly appUrl = 'id90travelapp://';

    constructor(private platform: Platform) { }

    public isPlatformEnabled(): boolean {
        const userAgent = navigator.userAgent;
        const isIOSAvailable = this.platform.IOS && (userAgent.indexOf('FxiOS') !== -1 || userAgent.indexOf('CriOS') !== -1)
        return this.platform.ANDROID || isIOSAvailable;
    }

    public isFirefoxOnIos(): boolean {
        return this.platform.IOS && navigator.userAgent.indexOf('FxiOS') !== -1
    }

    public getStoreURL(): string {
        if (this.platform.ANDROID) {
            return environment.androidStore;
        }
        return environment.iosStore;
    }

    public getDeepLinkUrl(urlParams: string): string {
        return `${this.appUrl}${urlParams}`;
    }
}
