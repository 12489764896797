import { GeocodingService, GeocoderAddress, GeocoderLocation } from '../geocoding.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import omittedCityNamesJson from './omitted-city-names.json';
import { getDistance, convertDistance } from 'geolib';

@Injectable({
  providedIn: 'root'
})
export class ArcGisGeocodingService implements GeocodingService {

  private readonly BASE_URL = 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer';
  private readonly CATEGORIES = 'Address,Block,City,District,Metro Area,Neighborhood,POI,Sector,Subregion';

  constructor(
    private http: HttpClient
  ) {}

  getLocations(term: string, locationType: string): Observable<GeocoderLocation[]> {
    if (term === '') {
      return of([]);
    }

    if (locationType === 'hotels' || locationType === 'address' || locationType === 'airport') {
      return this.http.get<any>(
        `${this.BASE_URL}/suggest?f=json&text=${encodeURIComponent(term)}&category=${this.CATEGORIES}`,
        {
          headers: new HttpHeaders({ 'No-Auth': 'true', 'no-version': 'true' })
        }
      ).pipe(
        map((resp) => {
          const admittedSuggestions = [];
          resp.suggestions.forEach((place) => {
            const isOmittedCityName = (omittedCityNamesJson || []).some(city => city.name === place.text);
            if (isOmittedCityName) { return; }
            admittedSuggestions.push(place);
          });
          return admittedSuggestions;
        }),
        map((admittedSuggestions) => {
          const places = [];
          admittedSuggestions.forEach((place) => {
            places.push({ ...place, displayName: place.text });
          });
          return places;
        })
      );
    }

    if (locationType === 'cars') {
      return this.http.get<any>(`${environment.apiUrl}/api/v1/car_locations.json`, { params: { query: term } }).pipe(
        map((resp) => {
          const places = [];
          resp.data.forEach((place) => {
            places.push({ ...place, displayName: place.name });
          });
          return places;
        })
      );
    }
  }

  getLocationCoords(place: GeocoderLocation): Observable<any> {
    return Observable.create((observer) => {
      this.http.get<any>(
        `${this.BASE_URL}//findAddressCandidates?singleLine=${encodeURIComponent(place.displayName)}&magicKey=${place.magicKey}&f=json`,
        {
          headers: new HttpHeaders({ 'No-Auth': 'true', 'no-version': 'true' })
        }
      ).subscribe(
        (res) => {
          const results = res.candidates;
          observer.next({
            ...place,
            lat: results[0].location.y,
            lng: results[0].location.x,
            radiusFromExtent: this.getLocationResolution(results[0].location, results[0].extent)
          });
          observer.complete();
        },
        (err) => {
          throwError(err);
          observer.complete();
        }
      );
    });
  }

  getLocationDetails(place: any): Observable<GeocoderAddress> {
    return Observable.create((observer) => {
      this.http.get<any>(
        `${this.BASE_URL}//findAddressCandidates?singleLine=${encodeURIComponent(place.text)}&magicKey=${place.magicKey}&outFields=RegionAbbr,StAddr,City,Country,Postal&f=json`,
        {
          headers: new HttpHeaders({ 'No-Auth': 'true', 'no-version': 'true' })
        }
      ).subscribe(
        (res) => {
          const result = res.candidates[0];
          observer.next({
            street: result.attributes.StAddr,
            city: result.attributes.City,
            state: result.attributes.RegionAbbr,
            country: result.attributes.Country,
            zip: result.attributes.Postal
          });
          observer.complete();
        },
        (err) => {
          throwError(err);
          observer.complete();
        });
    });
  }

  clearSessionToken() {}

  private getLocationResolution(location, extent) {
    // distance between center of bbox to bottom left corner to get approx radius
    let radiusInMeters = getDistance({latitude: location.y, longitude: location.x}, {latitude: extent.ymin, longitude: extent.xmin});
    return convertDistance(radiusInMeters, 'mi');
  }

}
