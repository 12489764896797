import { Action } from '@ngrx/store';
import { AuthApiActionTypes } from 'app/auth/store/actions/auth-api.actions';

export enum LoginPageActionTypes {
  Login = '[Login Page] Login',
  LoginWithJWT = '[Auth/API] Login via JWT',
  LoginThirdParty = '[Login Page] Third Party Login',
  LoginShowMessage = '[Login Page] Login Show Message',
  MarketingLogin = '[Login Page] Marketing Login',
  ClearError = '[Login Page] Clear Error',
  ClearMessage = '[Login Page] Clear Message'
}

export class Login implements Action {
  readonly type = LoginPageActionTypes.Login;

  constructor(public payload: { credentials: any, fromModal?: boolean, showNewAccountModal?: boolean, fromRegister?: boolean }) {}
}

export class LoginWithJWT implements Action {
  readonly type = LoginPageActionTypes.LoginWithJWT;

  constructor(public payload: {
    accessToken: string,
    redirect: string,
    isWhiteLabel: boolean,
    utm_campaign?: string,
    utm_medium?: string,
    utm_source?: string
  }) {}
}

export class LoginThirdParty implements Action {
  readonly type = LoginPageActionTypes.LoginThirdParty;

  constructor(public payload: { authToken: string, platform: string, remember?: '0' | '1' }) {}
}

export class LoginShowMessage implements Action {
  readonly type = LoginPageActionTypes.LoginShowMessage;

  constructor(public payload: { message: any }) { }
}

export class MarketingLogin implements Action {
  readonly type = LoginPageActionTypes.MarketingLogin;

  constructor(public payload: { credentials: any }) {}
}

export class ClearError implements Action {
  readonly type = LoginPageActionTypes.ClearError;

  constructor() {}
}

export class ClearMessage implements Action {
  readonly type = LoginPageActionTypes.ClearMessage;

  constructor() {}
}

export type LoginPageActionsUnion = Login
  | LoginWithJWT
  | LoginThirdParty
  | LoginShowMessage
  | MarketingLogin
  | ClearError
  | ClearMessage;
