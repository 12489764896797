import { Action } from '@ngrx/store';
import { HotelsRecentSearch } from 'app/shared/models/hotels-recent-search';

export enum HotelsRecentSearchesApiActionTypes {
  LoadSuccess = '[Hotels Recent Searches/API] Load Success',
  LoadFailure = '[Hotels Recent Searches/API] Load Failure',
}

export class LoadSuccess implements Action {
  readonly type = HotelsRecentSearchesApiActionTypes.LoadSuccess;

  constructor(public payload: { destinations: HotelsRecentSearch[] }) {}
}

export class LoadFailure implements Action {
  readonly type = HotelsRecentSearchesApiActionTypes.LoadFailure;

  constructor(public payload: { error: any }) {}
}

export type HotelsRecentSearchesApiActionsUnion = LoadSuccess
  | LoadFailure;
