import { Action } from '@ngrx/store';
import { LogoutSuccessPayload } from 'app/auth/models/logout.interface';

export enum LogoutComponentActionTypes {
  Logout = '[Logout Component] Logout',
  Oauth2Logout = '[Logout Component] Logout oauth',
  MarketingLogout = '[Logout Component] Marketing Logout'
}

export class Logout implements Action {
  readonly type = LogoutComponentActionTypes.Logout;

  constructor(public payload?: LogoutSuccessPayload) {}
}

export class Oauth2Logout implements Action {
  readonly type = LogoutComponentActionTypes.Oauth2Logout;

  constructor(public payload?: { airlineCode: string }) {}
}

export class MarketingLogout implements Action {
  readonly type = LogoutComponentActionTypes.MarketingLogout;

  constructor(public payload?: any) {}
}

export type LogoutComponentActionsUnion = Logout
  | MarketingLogout | Oauth2Logout;
