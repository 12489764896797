<div class="card my-3 text-center car-trips clickable-card" (click)="viewTripClicked.emit(car)" data-cy="tripsCarItem">
  <img class="mx-auto w-50 pt-3" src="{{ car.vehicle_image }}" alt="Your trip to {{ car.pick_up_location_name }}">
  <div class="card-body pb-3">
    <div class="primary-content mt-2">{{ car.vendor_name }}</div>
    <div class="secondary-content mt-2">
      <div>{{ car.pick_up_location_name }}</div>
      <time datetime="'car.pick_up_datetime'">{{ pickupDate }}</time> -
      <time datetime="'car.drop_off_datetime'">{{ dropoffDate }}</time>
      <div>{{ car.vehicle_size }}</div>
    </div>
  </div>
  <div class="card-footer bg-transparent">
    <button type="button" class="btn btn-md text-black" (click)="viewTripClicked.emit(car)">{{'mobile.trips.view_trip' | translate}}</button>
  </div>
</div>
