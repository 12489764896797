import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { FeatureFlagsService } from 'app/shared/services/featureFlags/featureFlags.service';
import { featureFlags } from 'app/shared/models/featureFlags';
import * as fromShared from 'app/shared/store/reducers';
import * as fromRoot from 'reducers';
import * as fromAuth from 'app/auth/store/reducers';
import { skipWhile, map, tap } from 'rxjs/operators';
import { Observable, combineLatest, of } from 'rxjs';
import { AirlinesService } from 'app/shared/services/airlines/airlines.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureGuard  {
  private featureFlags$ = this.store.select<any>(fromShared.getAirlineFlags);
  private airlines$ = this.store.pipe(select(fromShared.getAirlines));
  private loggedIn$ = this.store.pipe(select(fromAuth.getLoggedIn));
  private error$ = this.store.pipe(select(fromAuth.getLoginPageError));
  private sessionTimeOut$ = this.store.pipe(select(fromAuth.getSessionTimeOut));

  loggedIn = false;
  airlines = null;
  sessionTimeOut;
  loginError;

  constructor(
    private store: Store<fromRoot.State>,
    private featureFlagsService: FeatureFlagsService,
    private airlinesService: AirlinesService,
    private router: Router
  ) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return combineLatest([this.featureFlags$, this.airlines$, this.loggedIn$, this.sessionTimeOut$, this.error$]).pipe(
      skipWhile(([flags, airlines, loggedIn, sessionTimeOut, loginError]) => !flags || flags.length === 0),
      map(([flags, airlines, loggedIn, sessionTimeOut, loginError]) => {
        this.airlines = airlines;
        this.loggedIn = loggedIn;
        this.sessionTimeOut = sessionTimeOut;
        this.loginError = loginError;
        return true;
      }),
      tap(() => {
        return of(this.canLoadFeature(route));
      }));
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return combineLatest([this.featureFlags$, this.airlines$, this.loggedIn$, this.sessionTimeOut$, this.error$]).pipe(
      skipWhile(([flags, airlines, loggedIn, sessionTimeOut, loginError]) => !flags || flags.length === 0),
      map(([flags, airlines, loggedIn, sessionTimeOut, loginError]) => {
        this.airlines = airlines;
        this.loggedIn = loggedIn;
        this.sessionTimeOut = sessionTimeOut;
        this.loginError = loginError;
        return true;
      }),
      tap(() => {
        return of(this.canLoadFeature(route));
      }));
  }

  private canLoadFeature(route): boolean {
    // Get the module name from route data
    const { data: {feature} } = route;
    if (!this.loggedIn && route.queryParamMap?.get('jwt')) {
      return true;
    }
    if (feature) {
      if (feature === featureFlags.LOGIN && (this.loggedIn && !this.sessionTimeOut && !this.loginError)) {
        return true;
      }
      if (this.featureFlagsService.isFeatureEnabled(feature)) {
        return true;
      }
    }
    if (this.airlinesService.getWhiteLabelAirlineCode(this.airlines)) {
      this.router.navigate(['home/white-label']);
    } else if (feature === featureFlags.LOGIN) {
      this.router.navigate(['404']);
    } else {
      this.router.navigate(['/']);
    }

    return false;
  }
}
