
import  rules  from 'configs/rules.json';
import { DestinationsConfig } from '../models/rules';
import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class DestinationHelper {
    constructor() { }


  getDestinationConfig(airlineCode: string, lineOfBusiness: string, pathOfBusiness: string, defaultConfig): DestinationsConfig {
    const destinationConfig = rules[lineOfBusiness][pathOfBusiness][airlineCode];
    return destinationConfig ? destinationConfig : defaultConfig;
  }
}
