import { Component, Output, Input, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { InsuranceTrip } from 'app/trips/models/insurance-trip';

@Component({
  selector: 'insurance-trips-card',
  templateUrl: './insurances-card.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsurancesTripsCardComponent {
  @Input() insurance: InsuranceTrip;
  @Output() viewTripClicked = new EventEmitter();

  get destination() {
    if (this.insurance.destination === 'Independent Insurance Path') {
      return 'Standalone Insurance';
    }

    if (this.insurance.destination.trim().slice(-1) === ',') {
      return this.insurance.destination.trim().slice(0, -1);
    }

    return this.insurance.destination;
  }
}
