import { Action } from '@ngrx/store';

export enum TrendingDestinationsActionTypes {
  Load = '[Trending Destinations] Load',
}

export class Load implements Action {
  readonly type = TrendingDestinationsActionTypes.Load;

  constructor(public payload: any) {}
}

export type TrendingDestinationsActionsUnion = Load;
