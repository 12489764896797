import { Action } from '@ngrx/store';
import { Hotel } from 'app/hotels/models/hotel';
import { HotelFilters } from 'app/hotels/models/hotel-filters';

export enum HotelResultsActionTypes {
  GetHotels = '[Hotel Results] Get Hotels',
  GetHotelsSuccess = '[Hotel Results] Get Hotels Success',
  GetHotelsFailure = '[Hotel Results] Get Hotels Failure',
  GetHotelsStream = '[Hotel Results] Get Hotels Stream',
  GetHotelsStreamSuccess = '[Hotel Results] Get Hotels Stream Success',
  GetHotelsStreamFailure = '[Hotel Results] Get Hotels Stream Failure',
}

export class GetHotels implements Action {
  readonly type = HotelResultsActionTypes.GetHotels;

  constructor(public payload: { searchId: string, [name: string]: any; } ) {}
}

export class GetHotelsSuccess implements Action {
  readonly type = HotelResultsActionTypes.GetHotelsSuccess;

  constructor(public payload: { hotels: Hotel[], meta: any, searchId: string }) {}
}

export class GetHotelsFailure implements Action {
  readonly type = HotelResultsActionTypes.GetHotelsFailure;

  constructor(public payload: { error: any, searchId: string }) {}
}
export class GetHotelsStream implements Action {
  readonly type = HotelResultsActionTypes.GetHotelsStream;

  constructor(public payload: {[name: string]: any, searchId : any; }) {}
}

export class GetHotelsStreamSuccess implements Action {
  readonly type = HotelResultsActionTypes.GetHotelsStreamSuccess;

  constructor(public payload: {
    hotels :Hotel[];
    meta :any;
    traceable :any;
    closeStream :boolean;
    cache_key :string;
    pending :boolean;
    success :boolean;
    error : any;
    initialContentLoad :boolean;
    endContentLoad :boolean;
    partialContentLoad : boolean;
    searchId: any;
  }) {}
}

export class GetHotelsStreamFailure implements Action {
  readonly type = HotelResultsActionTypes.GetHotelsStreamFailure;

  constructor(public payload: { error: any, searchId: string }) {}
}

export type HotelFilterActionsUnion =
  GetHotels
| GetHotelsSuccess
| GetHotelsFailure
|  GetHotelsStream
| GetHotelsStreamSuccess
| GetHotelsStream;
