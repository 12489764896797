<div *ngIf="!itinerary">
  <div class="customer-review" *ngIf="referrer">
    <div class="review-text">{{ storyText }}</div>
    <div class="review-author">
      <img class="img-fluid me-3 d-inline-block"
        src="angular_assets/images/customer-headshots/ambassador_placeholder.png" />
      <div class="customer-name d-inline-block">{{ referrer }}</div>
    </div>
  </div>

  <div class="customer-review" *ngIf="!referrer">
    <div class="review-text">{{ storyText }}</div>
    <div class="review-author">
      <img class="img-fluid me-3 d-inline-block"
        src="angular_assets/images/customer-headshots/ashley-smith_flight-attendant_southwest.png" />
      <div class="d-inline-block">
        <div class="customer-name">Ashley Smith</div>
        <div class="tertiary-content"> {{ 'home_page.flight_attendant' | translate }}</div>
        <div class="tertiary-content">Southwest Airlines</div>
      </div>
    </div>
  </div>
</div>

<section class="itinerary d-flex flex-column align-items-center h-100" *ngIf="itinerary">
  <div class="information d-flex flex-column justify-content-center pt-5">
    <h2 class="pb-4">{{ 'co_traveler.register_page.going_to' | translate }} <span class="text-capitalize">{{ itinerary.city | lowercase }}</span></h2>
    <div class="info">
      <img class="mb-3" [src]="itinerary.image">
      <p class="destination fw-bold m-0">{{ itinerary.name }}</p>
      <p>{{ itinerary.arrivalDate }} - {{ itinerary.departureDate }}</p>
    </div>
  </div>
  <div class="referrer d-flex flex-column justify-content-center">
    <div class="review-text">
      {{ 'co_traveler.register_page.next_adventure' | translate }}
    </div>
    <div class="review-author d-flex align-items-center justify-content-left">
      <avatar-circle class="me-3" [noavatar]="itinerary.ownerAvatar || null"></avatar-circle>
      <div *ngIf="itinerary.ownerName" class="customer-name text-align-center-left">{{ itinerary.ownerName }}</div>
      <div *ngIf="!itinerary.ownerName && itinerary.ownerEmail" class="customer-name text-align-center-left">
        {{ itinerary.ownerName }}
      </div>
    </div>
  </div>
</section>
