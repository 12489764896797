import { Action } from '@ngrx/store';
import { Modal } from '../../../shared/models/modal.model';
import { VirtualGiftCardAuthorization } from 'app/hotels/models/virtual-gift-card-authorization';
import { GiftCards } from 'app/hotels/models/gift-cards';

export enum VirtualGiftCardActionTypes {
  Create = '[Virtual Gift Card] Create',
  CreateSuccess = '[Virtual Gift Card] Create Success',
  CreateFailure = '[Virtual Gift Card] Create Failure',
}

export class Create implements Action {
  readonly type = VirtualGiftCardActionTypes.Create;
  constructor(public payload: VirtualGiftCardAuthorization) { }
}

export class CreateSuccess implements Action {
  readonly type = VirtualGiftCardActionTypes.CreateSuccess;
  constructor(public payload: any) { }
}

export class CreateFailure implements Action {
  readonly type = VirtualGiftCardActionTypes.CreateFailure;
  constructor(public payload: any) { }
}

export type VirtualGiftCardActionsUnion = Create | CreateSuccess | CreateFailure;
