import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { environment } from '@env/environment';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Store, select } from '@ngrx/store';
import { User } from 'app/auth/models/user';
import { AuthApiActions } from 'app/auth/store/actions';
import * as fromAuth from 'app/auth/store/reducers';
import { UsersWithImages, UsersWithOutImages } from 'app/shared/components/avatar-circle/avatar-circle.component';
import { Modal } from 'app/shared/models/modal.model';
import { AmazonS3Service } from 'app/shared/services/amazon-s3/amazon-s3.service';
import { ConfirmationDialogService } from 'app/shared/services/confirmation-dialog/confirmation-dialog.service';
import { ModalActions } from 'app/shared/store/actions';
import * as fromRoot from 'reducers';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'avatar',
  templateUrl: './avatar.html',
  styleUrls: ['./avatar.scss']
})

export class AvatarComponent implements OnInit, OnDestroy {
  @ViewChild('avatarInput', { static: false }) avatarInput: ElementRef;
  @Input() isHeader = false;
  readonly DEFAULT_IMAGE = 'angular_assets/images/icons/profile_icon.svg';
  readonly MAX_SIZE_ALLOWED = 3;
  readonly ALLOWED_EXTENSIONS = ['jpg', 'jpeg', 'png'];
  readonly FILE_RANDOM_NUMER = '159248001';
  readonly FILE_RANDOM_NUMER_2 = '159248002';
  readonly S3BUCKET = environment.avatarsBucket;
  loading = false;
  avatarImage: UsersWithImages;
  noAvatarImage: UsersWithOutImages;
  userInfo: User;
  shouldDisplayRemove: boolean;
  icons = { faTimesCircle };

  private ngUnsubscribe = new Subject<void>();

  constructor(
    private amazonS3Service: AmazonS3Service,
    private store: Store<fromRoot.State>,
    private confirmationDialogService: ConfirmationDialogService
  ) {}

  ngOnInit() {
    this.loadInfo();
  }

  loadInfo() {
    this.store.pipe(select(fromAuth.getUser)).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((user) => {
      this.userInfo = user;
      this.resetAvatar();
      this.getUserPicture();
      this.shouldDisplayRemove = this.hasUserPicture();
    });
  }

  getUserPicture() {
    if (this.userInfo.member && this.userInfo.member.profile_image_url) {
      this.avatarImage = this.formatMemberAvatar(this.userInfo.member.profile_image_url);
      return;
    }

    if (this.userInfo.account && !this.userInfo.account.firstName) {
      this.avatarImage = this.formatMemberAvatar(this.DEFAULT_IMAGE);
      return;
    }

    if (this.userInfo.account && this.userInfo.account.firstName) {
      this.noAvatarImage = this.formatMemberNoAvatar();
      return;
    }
  }

  hasUserPicture() {
    return this.avatarImage ? this.avatarImage.image !== this.DEFAULT_IMAGE : false;
  }

  formatMemberAvatar(image): UsersWithImages {
    return {
      image,
      name: this.userInfo.account.firstName || this.userInfo.account.personalEmail || ''
    };
  }

  formatMemberNoAvatar(): UsersWithOutImages {
    return {
      name: this.userInfo.account.firstName || this.userInfo.account.personalEmail || '',
      abbr: this.userInfo.account.firstName[0] ? this.userInfo.account.firstName[0] : 'X'
    };
  }

  uploadButton() {
    this.avatarInput.nativeElement.click();
  }

  async removePhoto() {
    const confirmationDialog = await this.confirmationDialogService.confirm({
      title: 'profile.avatar.remove_photo_title',
      message: 'profile.avatar.remove_photo_text',
      btnOkText: 'profile.avatar.remove_photo_confirm',
      btnCancelText: 'profile.avatar.remove_photo_cancel'
    });
    if (confirmationDialog) {
      this.store.dispatch(new AuthApiActions.AvatarUpload({ avatarUrl: '' }));
      this.loadInfo();
    }
  }

  resetAvatar() {
    this.avatarImage = null;
    this.noAvatarImage = null;
  }

  processAvatar(avatar: any) {
    if (!avatar) {
      return false;
    }

    const file = avatar.target.files[0];
    const size = file.size / 1024 / 1024;

    if (!this.checkMaxSize(size)) {
      return false;
    }
    this.uploadFile(file, file.size);
  }

  uploadFile(file, size) {
    this.loading = true;
    const ext = file.name.substr(file.name.lastIndexOf('.') + 1);
    const fileName = `${this.setFileName()}.${ext}`;

    if (this.ALLOWED_EXTENSIONS.includes(ext)) {
      this.amazonS3Service.getPresignedUrl(this.S3BUCKET, fileName, size).pipe(take(1)).subscribe((response) => {
        this.amazonS3Service.uploadImagePresigned(file, response).pipe(take(1)).subscribe(
        (xmlResponse) => {
          this.store.dispatch(new AuthApiActions.AvatarUpload({ avatarUrl: this.parseXmlResponse(xmlResponse) }));
          this.loadInfo();
          this.loading = false;
        },
        (e) => {
          this.loading = false;
          const data: Modal = {
            title: 'flights.search.modals.passengers.title',
            body:  this.parseXmlResponse(e.error, 'error'),
            modalOptions: { size: 'md' }
          };
          this.store.dispatch(new ModalActions.Show({ data }));
        });
      },                                                                                          (e) => {
        this.loading = false;
        const data: Modal = {
          title: 'flights.search.modals.passengers.title',
          body: e.error.error,
          modalOptions: { size: 'md' }
        };
        this.store.dispatch(new ModalActions.Show({ data }));
      });
    } else {
      this.loading = false;
      const data: Modal = {
        title: 'flights.search.modals.passengers.title',
        bodyTranslationKey: 'avatar.images_accepted',
        modalOptions: { size: 'md' }
      };
      this.store.dispatch(new ModalActions.Show({ data }));
    }
  }

  parseXmlResponse(xml, element = 'success') {
    if (!xml) {
      return;
    }

    let match = /<Location>([^<]*)<\/Location>/;
    if (element === 'error') {
      match = /<Message>([^<]*)<\/Message>/;
    }

    const location = xml.match(match);
    return location[1];
  }

  checkMaxSize(fileSize) {
    if (fileSize > this.MAX_SIZE_ALLOWED) {
      const data: Modal = {
        title: 'flights.search.modals.passengers.title',
        bodyTranslationKey: 'avatar.max_size_error',
        bodyTranslationVars: { max_allowed: this.MAX_SIZE_ALLOWED },
        modalOptions: { size: 'lg' }
      };
      this.store.dispatch(new ModalActions.Show({ data }));
      return false;
    }

    return true;
  }

  setFileName() {
    let random = this.FILE_RANDOM_NUMER;

    if (this.userInfo.member.profile_image_url) {
      // Remove extension & change to alternative name to reflect change in the store and subscriptors
      const currentUrl = this.userInfo.member.profile_image_url.replace(/\.[^/.]+$/, '');
      random = currentUrl.substr(currentUrl.length - 1) === '1' ? this.FILE_RANDOM_NUMER_2 : this.FILE_RANDOM_NUMER;
    }

    return `${this.userInfo.member.id90_user_id}${random}`;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
