import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { mergeMap, catchError, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { DemandCalendarActions } from 'app/hotels/store/actions';
import { DemandCalendarService } from 'app/hotels/services/demand-calendar/demand-calendar.service';
import { DemandCalendar, DemandCalendarRequest } from 'app/hotels/models/demand-calendar';

@Injectable()
export class DemandCalendarEffects {
    constructor(
        private actions$: Actions,
        private demandCalendarService: DemandCalendarService
    ) { }

    getDemandCalendar$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<DemandCalendarActions.GetDemandCalendar>(DemandCalendarActions.DemandCalendarActionTypes.GetDemandCalendar),
                mergeMap((action: { payload: DemandCalendarRequest }) => {
                    return this.demandCalendarService.get(action.payload.latitude, action.payload.longitude).pipe(
                        map((calendar: DemandCalendar[]) => new DemandCalendarActions.GetDemandCalendarSuccess( { calendar } )),
                        catchError(error => of(new DemandCalendarActions.GetDemandCalendarError({ error })))
                    )
                })
            )
    );
}
