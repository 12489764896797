<ng-template #itemMenu>
  <div [ngClass]="{'show-more-mode': showMoreMode, 'show-menu': showSelect || showMoreMode}" class="item-menu" *ngIf="showOrHiddenIE">
    <div *ngIf="label" class="secondary-content banner d-flex align-items-center justify-content-between p-2 mb-2"
         [class.px-lg-0]="showMoreMode" [class.justify-content-between] ="!enableResetButton || (enableResetButton && !showMoreMode)">
      <span class="pe-2">{{label}}</span>
      <span *ngIf="resetButtonEnabled" class="reset-button" [class.text-white]="!showMoreMode" (click)="onResetClick()">
        <fa-icon [icon]="icons.faTimes" class="me-1"></fa-icon>{{ 'reset' | translate }}
      </span>
    </div>
    <div #itemList class="item-list">
      <div
        class="d-flex align-items-baseline"
        [ngClass]="showMoreMode ? 'text-wrap' : 'text-nowrap'"
        id="{{ id }}_{{item.label | translate}}"
        *ngFor="let item of items; let i = index">
        <div *ngIf="i < showMin || !showMoreMode" class="form-check d-flex align-items-center" [class.w-100]="showMoreMode">
          <!-- INFO: we need input ids to be exactly this for GA reasons, even if they are duplicated across the DOM -->
          <input
            *ngIf="multiSelect"
            [id]="id + '_' + item.value + '_selection'"
            class="me-2 form-check-input {{item.value ? gtmClass+'-'+item.value : gtmClass}} position-relative mt-0"
            type="checkbox"
            value="{{item.value}}"
            [checked]="itemSelected(item.value)"
            [disabled]="item.disabled"
            (change)="updateValue($event)">
          <input
            *ngIf="!multiSelect"
            [id]="id + '_' + item.value + '_selection'"
            class="me-2 form-check-input {{item.value ? gtmClass+'-'+item.value : gtmClass}} position-relative mt-0"
            type="radio"
            [name]="'radio-' + id"
            value="{{item.value}}"
            [checked]="itemSelected(item.value)"
            [disabled]="item.disabled"
            (change)="updateValue($event)">
          <label class="clickable me-1 form-check-label {{item.value ? gtmClass+'-'+item.value : gtmClass}}" [for]="id + '_' + item.value + '_selection'">
            {{ item.label | translate }}
          </label>
          <fa-icon *ngIf="item.desc" [icon]="icons.faCircleInfo" class="ms-1 primary-color" container="{{showMoreMode ? '' : 'body'}}" [ngbTooltip]="item.desc" tooltipClass="custom-tooltip"></fa-icon>
        </div>
      </div>
    </div>
    <span *ngIf="showMoreMode && items.length > showMin" class="primary-color clickable p-2 d-block" (click)="showMore()" >
      {{ 'mobile.show_more' | translate }}
      <fa-icon [icon]="icons.faAngleDown" class="me-1"></fa-icon>
    </span>
  </div>
</ng-template>

<span #pillComponent *ngIf="!showMoreMode" class="pill d-flex align-items-center justify-content-between {{label}}_custom_select" [ngClass]="{'hilight':selected, 'shadow-sm': !updatedStyles, 'btn btn-sm sort-button bg-white': updatedStyles}">
  <span *ngIf="multiSelect">{{label}} {{ selectedCount }}</span>
  <span *ngIf="!multiSelect">{{label}}<ng-container *ngIf="selectedValue"> {{ selectedValue | translate }}</ng-container></span>
  <fa-icon [icon]="icons.faCaretDown" *ngIf="!updatedStyles; else doubleCaretIcon"></fa-icon>
  <ng-template #doubleCaretIcon>
    <img class="me-2" src="angular_assets/images/icons/double-caret.svg" alt="Double caret">
  </ng-template>
  <ng-container *ngIf="!showMoreMode" [ngTemplateOutlet]="itemMenu"></ng-container>
</span>

<ng-container *ngIf="showMoreMode" [ngTemplateOutlet]="itemMenu"></ng-container>
