import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromAuth from 'app/auth/store';
import { AuthApiActions } from 'app/auth/store/actions';
import { ProfileUserActions } from 'app/profile/store/actions';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';
import { Modal } from 'app/shared/models/modal.model';
import { ProfileService } from 'app/shared/services/profile/profile.service';
import { ModalActions } from 'app/shared/store/actions';
import * as fromRoot from 'reducers';
import { Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'register-email-address',
  templateUrl: './register-email-address.html'
})

export class RegisterEmailAddress implements OnInit, OnDestroy {
  userAccount$ = this.store.select(fromAuth.getUserAccount).pipe(take(1));
  userAccountSubscription: Subscription;

  registerEmailForm: UntypedFormGroup;
  loading = true;
  error = '';
  ngUnsubscribe$ = new Subject<void>();

  constructor(
    private router: Router,
    private profileService: ProfileService,
    private store: Store<fromRoot.State>,
    public formErrorsHelper: FormErrorsHelper,
    private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.userAccount$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((user) => {
      if (user.personalEmail) {
        return this.router.navigate(['/']);
      }
    });

    this.createForm();
    this.loading = false;
  }

  createForm() {
    this.registerEmailForm = this.formBuilder.group({
      email: new UntypedFormControl('', [Validators.email])
    });
  }

  submitForm() {
    if (this.registerEmailForm.valid) {
      this.registerEmail();
    } else {
      Object.keys(this.registerEmailForm.controls).forEach((field) => {
        const control = this.registerEmailForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  registerEmail() {
    this.loading = true;
    const params = { personalEmail: this.registerEmailForm.controls['email'].value };

    this.profileService.updateProfile(params).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(
      () => {
        this.store.dispatch(new ProfileUserActions.UpdateUserProfile(params));
        this.store.dispatch(new AuthApiActions.UpdateUserAccountInfo(params));
        const redirectURL = this.activatedRoute.snapshot.queryParams['redirect']
          ? decodeURIComponent(this.activatedRoute.snapshot.queryParams['redirect'])
          : '/';
        this.loading = false;

        const urlParts = redirectURL.split('?');
        if (urlParts.length > 1) {
          const queryParams = this.getUrlParams(urlParts[1]);
          return this.router.navigate([urlParts[0]], { queryParams });
        }
        return this.router.navigate([redirectURL]);
      },
      (e) => {
        const data: Modal = {
          body: e.error.error,
          modalOptions: { size: 'md' }
        };
        this.store.dispatch(new ModalActions.Show({ data }));
        this.loading = false;
      });
  }

  cancelAndLogout() {
    this.store.dispatch(new fromAuth.LogoutComponentActions.Logout());
  }

  get email() {
    if (this.registerEmailForm) {
      return this.registerEmailForm.get('email');
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  private getUrlParams(params: string) {
    const fields = params.split('&');
    const objectParams = {};
    fields.forEach((item) => {
      const parts = item.split('=');
      objectParams[parts[0]] = parts[1].replace(/%5B/g, '[')
        .replace(/%5D/g, ']')
        .replace(/%7C/g, '|')
        .replace(/%20/g, ' ');
    });
    return objectParams;

  }
}
