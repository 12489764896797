import { Injectable, Inject } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { forEach } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    private browserTitleService: Title,
    private browserMetaService: Meta,
    @Inject(DOCUMENT) private doc
  ) {}

  setTitle(title: string) {
    this.browserTitleService.setTitle(title);
  }

  updateMetaTag(tag, selector: string) {
    this.browserMetaService.updateTag(tag, selector);
  }

  removeMetaTag(selector: string) {
    this.browserMetaService.removeTag(selector);
  }

  createLinkForCanonicalURL(url: string) {
    // create new link
    const link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', url);

    // add the link to the DOM
    this.doc.head.appendChild(link);
  }

  removeLinkForCanonicalURL() {
    // search existing canonical links
    const links = this.doc.querySelectorAll('[rel=canonical]');
    if (links.length > 0) {
      // delete previous links
      forEach(links, (node, index) => {
        node.parentNode.removeChild(node);
      });
    }
  }

}
