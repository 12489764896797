<div class="gallery-modal">
  <div class="modal-header d-flex justify-content-end" data-bs-theme="dark">
    <button type="button" class="btn-close close" aria-label="Close" (click)="dismiss()"></button>
  </div>
  <div class="modal-body mx-3">
    <div class="main-gallery-container">
      <div>
        <swiper-container swiperElement #imageSwiper class="my-3 main-swiper-gallery" [thumbs]="thumbsSwiper" [config]="galleryTopConfig" (slidechange)="changeThumbnail($event)" />
        <div class="w-100 d-flex flex-row justify-content-center me-3">
          <p class="me-3">{{paginationValue}}</p>
        </div>
      </div>
    </div>

    <div class="pt-3" *ngIf="currentScreenSize === 'large'">
      <div class="categories-container d-md-flex">
        <div *ngFor="let category of categories" class="category-item me-5"
          [ngClass]="{'category-selected' : category.key === categorySelected}"
          (click)="onCategoryPressed(category.key)">
          {{ category.text }}
        </div>
      </div>
      <div class="thumb-container">
        <swiper-container swiperElement #thumbsSwiper class=" mt-3" [config]="thumbsConfig" [slidesPerView]="16" (click)="show()" />
      </div>
    </div>
  </div>
</div>
