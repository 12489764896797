import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { CreditHistoryActions } from '../actions';
import { CreditHistoryService } from 'app/shared/services/credits/credit-history.service';

@Injectable()
export class CreditHistoryEffects {

  loadCredits$ = createEffect(()=>
    this.actions$.pipe(
      ofType<CreditHistoryActions.LoadCredits>(CreditHistoryActions.CreditHistoryActionTypes.LoadCredits),
      switchMap(() =>  {
        return this.creditsService.getCreditHistory().pipe(
          map(data => new CreditHistoryActions.LoadCreditsSuccess(data)),
          catchError(error => of(new CreditHistoryActions.LoadCreditsFailure(error)))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private creditsService: CreditHistoryService,
  ) {}

}
