import { Injectable } from '@angular/core';
import { Observable, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class WindowEvents {
  eventsObservables: { [eventName: string]: Observable<Event> } = {};

  observe(eventName: string): Observable<Event> {
    if (typeof this.eventsObservables[eventName] === 'undefined') {
      this.eventsObservables[eventName] = fromEvent(window, eventName).pipe(debounceTime(300)) as Observable<Event>;
    }
    return this.eventsObservables[eventName];
  }

}
