<section class="create-account">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-5 col-lg-5 col-md-6 col-sm-8 col-xs-12 offset-lg-1 offset-md-1 offset-sm-2 pt-5">
        <div class="row pb-4">
          <div class="col-sm-12">
            <h2>{{ 'action_terms.create_account' | translate }}</h2>
            <div class="secondary-content" [innerHTML]="'membership_modal.have_an_account' | translate"></div>
          </div>
        </div>
        <ngb-alert type="danger" *ngIf="errorMessage" (closed)="closeAlertEvent()">
          <div [innerHtml]="errorMessage"></div>
        </ngb-alert>
        <loading-indicator *ngIf="pendingLogin"></loading-indicator>
        <form *ngIf="!pendingLogin" [formGroup]="createAccountForm" (ngSubmit)="createAccount()">
          <airlines-autocomplete-component *ngIf="createAccountForm.get('airline')" [airlines]="airlines" (valueSelected)='airlineSelected($event)' [form]="createAccountForm" [codeFieldName]="'airline_code'"
            [displayFieldName]="'airline'" [organizationIdFieldName]="'organization_id'" [control]="createAccountForm.get('airline')" [isSignIn]="false" [action]="'sign-up'">
          </airlines-autocomplete-component>
          <div class="row" *ngIf="createAccountForm.get('first_name')">
            <div class="col-12 col-md-6">
              <div class="form-group form-floating">
                <input type="text" id="firstName" class="form-control with-background" formControlName="first_name"
                       placeholder="" autocomplete="given-name"  required />
                <label for="firstName">{{ 'membership.create.first_name_label' | translate }}</label>
                <div class='error-message' *ngIf="getFieldControl('first_name')?.errors !== null && (getFieldControl('first_name')?.touched)"
                     [innerHtml]="formErrorsHelper.errorMessage(getFieldControl('first_name'), ('membership.create.first_name_label' | translate))">
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group form-floating">
                <input type="text" id="lastName"  autocomplete="family-name" class="form-control with-background" formControlName="last_name"
                       placeholder="" required />
                <label  for="lastName">{{ 'membership.create.last_name_label' | translate }}</label>
                <div class='error-message' *ngIf="getFieldControl('last_name')?.errors !== null && (getFieldControl('last_name').touched)"
                     [innerHtml]="formErrorsHelper.errorMessage(getFieldControl('last_name'), ('membership.create.last_name_label' | translate))">
                </div>
              </div>
            </div>
          </div>
          <div class="form-group form-floating" *ngIf="createAccountForm.get('employee_number') && displayEmployeeNumber">
            <input type="text" id="employee-number" class="form-control with-background" formControlName="employee_number" required placeholder=" " data-cy="employee_number">
            <label for="employee-number" data-error="error">{{ 'membership.create.employee_number_label' | translate }}</label>
            <div class='error-message' *ngIf="getFieldControl('employee_number')?.errors !== null && (getFieldControl('employee_number').touched)" [innerHtml]="formErrorsHelper.errorMessage(getFieldControl('employee_number'), ('membership.create.employee_number_label' | translate))">
            </div>
          </div>
          <div class="form-group" *ngIf="createAccountForm.get('email')">
            <div class="form-floating">
              <input type="text" id="email" class="form-control with-background" formControlName="email" required placeholder=" " data-cy="email" autocomplete="email" (change)="setEmployeeNumber()" maxlength="80">
              <label for="email">{{ 'membership.create.company_email_label' | translate }}</label>
            </div>
            <div class='error-message' *ngIf="getFieldControl('email')?.errors !== null && (getFieldControl('email').touched)" [innerHtml]="formErrorsHelper.errorMessage(getFieldControl('email'), ('membership.create.company_email_label' | translate))">
            </div>
            <label class="tertiary-content" *ngIf="!isWhiteLabel"> {{ 'membership.create.email_help' | translate }} </label>
          </div>
          <div class="form-group" *ngIf="createAccountForm.get('homePhone') && countries">
            <phone-input
              labelKey="profile.info.homePhone"
              formGroupName="homePhone"
              [countries]="countries"
              [formGroup]="createAccountForm.get('homePhone')"
              [countryEnabled]="true"
              [numberEnabled]="true"
              [phoneForceError]="phoneError"
              [showHelpText]="true"
              (onKeyUp)="checkHomePhone()"
            ></phone-input>
          </div>
          <div class="form-group" *ngIf="createAccountForm.get('birth_date')">
            <div class="position-relative ngbDatepicker">
              <input class="form-control with-background" placeholder=""
                     name="birth_date" [minDate]="minDate" [startDate]="formatStartDate()" [value]="startDate" [readonly]="true"
                     [maxDate]="maxDate" ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" (dateSelect)="onDatesSelected($event)">
              <label>{{'profile.info.dob' | translate}} (yyyy-mm-dd)</label>
            </div>
            <div class='error-message' *ngIf="getFieldControl('birth_date')?.errors !== null && (getFieldControl('birth_date')?.touched)" data-cy="error-birthDate"
                 [innerHtml]="formErrorsHelper.errorMessage(getFieldControl('birth_date'), 'membership.create.birthDate' | translate)"></div>
          </div>
          <div class="form-group" *ngIf="createAccountForm.get('password')">
            <password-input required [pattern]='".{5,}"' [control]="'password'" [id]="'password'" [form]="createAccountForm" [placeholder]=""
                            [label]="'membership.create.password_label' | translate" [showForgotPassword]="false" autocomplete="new-password">
            </password-input>
            <div class='error-message' *ngIf="getFieldControl('password')?.errors !== null && (getFieldControl('password').touched)" [innerHtml]="formErrorsHelper.errorMessage(getFieldControl('password') , ('membership.create.password_label' | translate))">
            </div>
          </div>
          <div class="row pb-3">
            <div class="col-12 col-md-6 mb-2 mb-md-0">
              <button type="submit" class="btn btn-primary w-100" data-cy="submit">{{'action_terms.create_account' | translate}}</button>
            </div>
            <div class="col-12 col-md-6" *ngIf="airlineRules.login_button">
              <button type="submit" class="btn btn-outline-primary w-100" data-cy="submit" (click)="redirectToLogin()">{{'membership_modal.have_account_button' | translate}}</button>
            </div>
          </div>
          <label class="tertiary-content">
            {{ 'membership.create.acknowledge_terms_and_conditions' | translate }}
            <a href="/navigation/terms_and_conditions" target="_blank">{{ 'membership.create.terms_and_condition' | translate }}</a>.
          </label>
          <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" id="email_opt_out" name="email_opt_out" formControlName="email_opt_out">
            <label class="form-check-label tertiary-content" for="email_opt_out">{{ 'membership_modal.email_opt_out' | translate }}</label>
          </div>
        </form>
        <div *ngIf="!isWhiteLabel" class="row pt-4">
          <div class="col-12">
            <h5> {{ 'membership.create.retiree_title' | translate }} </h5>
            <p class="tertiary-content"> {{ 'membership.create.retiree_text' | translate }} </p>
            <button class="btn btn-outline-primary" routerLink="/membership/request"> {{ 'membership.create.retiree_button' | translate }} </button>
          </div>
        </div>
      </div>
      <div *ngIf="airlineRules.image.reviews; else imageUrl" class="col-xl-5 col-lg-5 col-md-5 offset-xl-1 offset-lg-1 d-none d-md-block position-relative fadeIn animated customer-benefits">
        <brand-ambassador-review></brand-ambassador-review>
      </div>
      <ng-template #imageUrl>
        <div class="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-5 offset-lg-1 d-none d-lg-block customer-benefits fadeIn animated" [ngStyle]="{'background-image': 'url(' + airlineRules.image.url + ')'}">
        </div>
      </ng-template >
    </div>
  </div>
</section>
