import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import * as Sentry from '@sentry/angular';

@Injectable()
export class GlobalErrorHandler extends Sentry.SentryErrorHandler implements ErrorHandler {

  constructor() {
    super();
  }

  handleError(err: any): void {
    checkChunkMessage(err);

    if (err instanceof HttpErrorResponse) {
      return;
    }

    console.error(err);

    if (Sentry.isInitialized()) {
      super.handleError(err.originalError || err);
    }
  }
}

function checkChunkMessage(err) {
  const chunkFailedMessage = /Loading chunk [\d]+ failed/;

  if (chunkFailedMessage.test(err.message)) {
    window.location.reload();
  }
}
