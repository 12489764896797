<section id="trips" class="py-5 mt-4">
  <div class="container min-vh-100">
    <div class="button-tabs-left">
      <ul ngbNav #nav="ngbNav" class="nav nav-justified nav-tabs" (navChange)="onTabChange($event)">
        <ng-container *featureFlag="featureFlags.HOTELS">
          <li [ngbNavItem]="'hotels'">
            <a ngbNavLink *ngIf="showId90Tools">
              {{'profile.reservations.hotels.title' | translate}}
            </a>
            <ng-template ngbNavContent>
              <trips-container
                [futureItems]="futureHotels$ | async"
                [pastItems]="pastHotels$ | async"
                [sharedItems]="sharedHotels$ | async"
                [pastSharedItems]="pastSharedHotels$ | async"
                itemType="hotels"
                (onViewTripClicked)="onHotelViewTripClicked($event)"
                (loadPastItems)="onLoadPastItems($event, 'hotels')"
                (loadMoreFutureItems)="onLoadFutureItems($event, 'hotels')"
                (loadMorePastItems)="onLoadPastItems($event, 'hotels')"
                (loadSharedItems)="onLoadSharedHotels()">
              </trips-container>
            </ng-template>
          </li>
        </ng-container>
        <ng-container *featureFlag="featureFlags.FLIGHTS">
          <ng-container *ngIf="showFlights" >
            <li [ngbNavItem]="'flights'">
              <a ngbNavLink data-cy="flightsTripsTab">
                {{'profile.reservations.flights.title' | translate }}
              </a>
              <ng-template ngbNavContent>
                <trips-container
                  [futureItems]="futureFlights$ | async"
                  [pastItems]="pastFlights$ | async"
                  [availableTools]="availableTools"
                  [isUserSelectedByOperator]="userSelectedByOperator"
                  [eligibleTravelersEnabled]="eligibleTravelersEnabled"
                  [isInvitedTraveler]="invitedTravelerId !== null"
                  itemType="flights"
                  (onViewTripClicked)="onFlightViewTripClicked($event)"
                  (loadPastItems)="onLoadPastItems($event, 'flights')"
                  (loadMoreFutureItems)="onLoadFutureItems($event, 'flights')"
                  (loadMorePastItems)="onLoadPastItems($event, 'flights')"
                  (loadFlightsByPNR) ="onloadFlightsByPNR($event)"
                  (isFlightPNRClean) ="onloadFlightsWhenUserCleansSearchByPNRInput()"
                  (sectionSelected)="onSectionSelected($event)">
                </trips-container>
              </ng-template>
            </li>
          </ng-container>
        </ng-container>
        <ng-container *featureFlag="featureFlags.CARS">
          <li [ngbNavItem]="'cars'">
            <a ngbNavLink *ngIf="showId90Tools" >
              {{'profile.reservations.cars.title' | translate}}
            </a>
            <ng-template ngbNavContent>
              <trips-container
                [futureItems]="futureCars$ | async"
                [pastItems]="pastCars$ | async"
                itemType="cars"
                (onViewTripClicked)="onCarViewTripClicked($event)"
                (loadPastItems)="onLoadPastItems($event, 'cars')"
                (loadMoreFutureItems)="onLoadFutureItems($event, 'cars')"
                (loadMorePastItems)="onLoadPastItems($event, 'cars')">
              </trips-container>
            </ng-template>
          </li>
        </ng-container>
        <ng-container *featureFlag="featureFlags.INSURANCE">
          <li [ngbNavItem]="'insurance'">
            <a ngbNavLink *ngIf="showId90Tools">
              {{'profile.reservations.insurance.title' | translate}}
            </a>
            <ng-template ngbNavContent>
              <trips-container
                [showFlights]="showFlights"
                [futureItems]="hotelsInsurance$ | async"
                [sharedItems]="flightsInsurance$ | async"
                itemType="insurance"
                (onViewTripClicked)="onInsuranceViewTripClicked($event)"
                (loadMoreFutureItems)="onLoadFutureItems($event, 'insurance')"
                (loadSharedItems)="onLoadFutureItems($event, 'insurance')">
              </trips-container>
            </ng-template>
          </li>
        </ng-container>
      </ul>
      <div [ngbNavOutlet]="nav"></div>
    </div>
  </div>
</section>
