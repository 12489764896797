import { Component, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

type size = 'large' | 'small';
@Component({
  selector: 'toggle',
  templateUrl: './toggle.html',
  styleUrls: ['toggle.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleComponent),
      multi: true
    }
  ]
})

export class ToggleComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() id = 'toggle';
  @Input() disabled: boolean;
  @Input() design = 'row';
  @Input('value') _value = false;
  @Input() size: size = 'large';
  @Input() align: string = 'center';

  onChange: any = (val: boolean) => {};
  onTouched: any = () => {};

  @Output() toggleChanged = new EventEmitter();

  get value() {
    return this._value;
  }

  set value(val: boolean) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  writeValue(toggle: boolean) {
    this.value = toggle;
  }

  registerOnChange(fn: (toggle: boolean) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  switch() {
    this.value = !this.value;
    this.toggleChanged.emit(this.value);
  }
}
