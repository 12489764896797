import { Component, ChangeDetectionStrategy, Input, SimpleChanges, OnChanges } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'recent-views',
  templateUrl: './recent-views-section.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class RecentViewsSectionComponent implements OnChanges {
  @Input() recentHotelViews: any[] = [];
  recentViews: any[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if (changes.recentHotelViews && changes.recentHotelViews.currentValue) {
      this.generateRecentViews();
    }
  }

  generateRecentViews() {
    this.recentHotelViews.forEach((search) => {
      const recentView = Object.assign({}, search);
      recentView.place = this.formatDestinationName(search.destination);
      recentView.dates = [
        this.formatDate(search.checkin, true),
        this.formatDate(search.checkout, true)
      ];

      this.recentViews.push(recentView);
    });
  }

  formatDate(date: string, displayFormat: boolean = false) {
    if (displayFormat) {
      return moment.utc(date).format('MMM D');
    }
    return moment.utc(date).format('YYYY-MM-DD');
  }

  formatDestinationName(destination: string) {
    return destination.split(',')[0];
  }
}
