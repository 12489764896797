import { Action } from '@ngrx/store';

export enum AbandonedHotelActionTypes {
  Load = '[Abandoned Hotel] Load',
  ClearLoaded = '[Abandoned Hotel] Clear Loaded'
}

export class Load implements Action {
  readonly type = AbandonedHotelActionTypes.Load;
}

export class ClearLoaded implements Action {
  readonly type = AbandonedHotelActionTypes.ClearLoaded;
}

export type AbandonedHotelActionsUnion = Load
  | ClearLoaded;
