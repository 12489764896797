import { Injectable } from '@angular/core';
import { DynamicScriptLoaderService } from '../dynamic-script-load/dynamic-script-load.service';
import { environment } from '@env/environment';

declare var L: any;

@Injectable({
  providedIn: 'root'
})
export class MapLayerService {

  private mapLayer: any;

  constructor(private scriptLoader: DynamicScriptLoaderService) {}

  public initialize(): Promise<any> {
    return this.scriptLoader.load('mapkit', 'leaflet').then((resp) => {
      return this.scriptLoader.load('leaflet-mapkitmutant', 'leaflet-markercluster').then((resp) => {
        if (resp[0].status !== 'Already Loaded') {
          // leaflet mapkitmutant plugin to have leaflet work with mapkit
          this.mapLayer = L.mapkitMutant({
            type: 'standard',
            authorizationCallback: (done) => {
              done(environment.mapkitJWT);
            },
            language: 'en',
            debugRectangle: false
          });
        }
        return this.mapLayer;
      });
    });
  }

  public getMapLayer() {
    return this.mapLayer;
  }

  public removeLayer() {
    if (this.mapLayer) {
      this.mapLayer.remove();
    }
  }

}
