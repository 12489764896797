import { Component, OnInit } from '@angular/core';
import { GeneralHelper } from 'app/shared/helpers/general.helper';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as fromShared from '../../store/reducers';
import * as fromRoot from '../../../../reducers';
import { TranslateService } from '@ngx-translate/core';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import * as fromAuth from 'app/auth/store/reducers';
import { take } from 'rxjs/operators';
import { RedirectHelper } from 'app/shared/helpers/redirect.helper';

@Component({
  selector: 'light-layout',
  templateUrl: './light-layout.html',
  styleUrls: ['./light-layout.scss']
})

export class LightLayoutComponent implements OnInit {
  showAnnouncement = true;
  logo = 'angular_assets/images/color-logo.png';
  icons = { faPhone };
  public homeUrl: string;
  private loggedIn$ = this.store.pipe(select(fromAuth.getLoggedIn));

  constructor (
    private activatedRoute: ActivatedRoute,
    private generalHelper: GeneralHelper,
    private store: Store<fromRoot.State>,
    private translateService: TranslateService,
    private redirectHelper: RedirectHelper
  ) {}

  ngOnInit(): void {
    this.listenToLoggedIn();
  }

  onActivate(event: Event) {
    this.generalHelper.scrollToHeader();
    const childRoute = this.activatedRoute.snapshot.firstChild;
    if (childRoute) {
      const data = childRoute.firstChild && childRoute.firstChild.data
        ? childRoute.firstChild.data
        : childRoute.data ? childRoute.data : null;
      this.showAnnouncement = data && typeof data.showAnnouncement !== 'undefined' ? data.showAnnouncement : true;
    }
    this.logo = this.getLogo();
  }

  private listenToLoggedIn() {
    this.loggedIn$.pipe(take(1)).subscribe((logged: boolean) => {
      this.homeUrl = this.redirectHelper.getHomeUrl(logged);
    });
  }

  private getLogo() {
    return this.translateService.instant('logo').url ? this.translateService.instant('logo').url : 'angular_assets/images/color-logo.png';
  }
}
