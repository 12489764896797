import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyHelper } from 'app/shared/helpers/currency.helper';

@Pipe({ name: 'currencyConvert' })
export class CurrencyConvertPipe implements PipeTransform {

  transform(value: number, currencyCode: string = 'USD', digits: string = '1.2-2'): string {
    // TODO: this will make the actual conversion between currencies
    return this.currencyHelper.toSelectedCurrencySymbol(value, currencyCode, digits);
  }

  constructor(
    private currencyHelper: CurrencyHelper,
  ) {}

}
