import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';
import Client, { ISbStoriesParams, ISbStoryParams } from 'storyblok-js-client';
import { DynamicScriptLoaderService } from '../dynamic-script-load/dynamic-script-load.service';

@Injectable({
  providedIn: 'root'
})
export class StoryblokService {
  public listenForChanges$ = new BehaviorSubject(null);
  private sbClient = new Client({
    accessToken: environment.storyblok.apiKey,
    region: environment.storyblok.region
  });
  constructor(private readonly dynamicScriptLoaderService: DynamicScriptLoaderService) {
    dynamicScriptLoaderService.loadScript('storyblok').then(() => this.init());
  }

  async getStory(slug: string, params?: ISbStoryParams): Promise<any> {
    try {
      const res = await this.sbClient.getStory(slug, {
        version: environment.storyblok.version as 'draft' | 'published',
        ...params
      });
      return res.data;
    } catch (error) {
      return {
        error
      };
    }
  }

  async getStories(params?: Partial<ISbStoriesParams>): Promise<any> {
    try {
      return await this.sbClient.getStories(params);
    } catch (error) {
      return {
        error,
      };
    }
  }

  async getDatasource(idSlug: string): Promise<any> {
    const params: ISbStoriesParams = {
      version: environment.storyblok.version as 'draft' | 'published',
      datasource: idSlug,
      per_page: 500
    };

    try {
      const res = await this.sbClient.get('cdn/datasource_entries', params);
      return res.data?.datasource_entries;
    } catch (e) {
      return [];
    }
  }

  async fetchDatasourcesGrouped(slugs: string[], removeNotValidValues: boolean = false): Promise<any> {
    const params: ISbStoriesParams = {
      version: environment.storyblok.version as 'draft' | 'published',
      per_page: 500
    };

    try {
      const requests = slugs.map(slug =>
        this.sbClient.get('cdn/datasource_entries', { ...params, datasource: slug })
      );
      const responses = await Promise.all(requests);

      if (removeNotValidValues) {
        return responses.map(res => (res.data?.datasource_entries || []).filter(entry => !isNaN(parseFloat(entry.value))));
      }

      return responses.map(res => res.data?.datasource_entries || []);
    } catch (e) {
      console.error('Error fetching grouped datasources:', e);
      return slugs.map(() => []);
    }
  }

  init() {
    if (typeof window !== 'undefined' && window?.StoryblokBridge) {
      const storyblokInstance = new window.StoryblokBridge();
      storyblokInstance.on(['change', 'input'], (event) => {
        if (event) {
          this.listenForChanges$.next(event);
        }
      });
    }
  }

  parseTextRich(content) {
    return this.sbClient.richTextResolver.render(content);
  }
}
