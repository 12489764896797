<section class="py-5" *ngIf="recentCarsSearches.length > 0">
  <div class="container">
    <div class="row">
      <div class="col">
        <h2>{{'mobile.general.recent_car_searches' | translate }}</h2>
      </div>
    </div>
    <destinations
      [source]="'carsRecentSearches'"
      [defaultImage]="defaultImage"
      [destinationsList]="recentCarsSearches">
    </destinations>
  </div>
</section>