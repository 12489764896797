import { Action } from '@ngrx/store';

export enum ForgotPasswordActionTypes {
  RequestCode = '[Forgot Password] Request Code',
  RequestCodeSuccess = '[Forgot Password] Request Code Success',
  RequestCodeError = '[Forgot Password] Request Code Error',
  RequestLink = '[Forgot Password] Request Link',
  RequestLinkSuccess = '[Forgot Password] Request Link Success',
  RequestLinkError = '[Forgot Password] Request Link Error',
  ValidateCode = '[Forgot Password] Validate Code',
  ValidateCodeSuccess = '[Forgot Password] Validate Code Success',
  ValidateCodeError = '[Forgot Password] Validate Code Error',
  VerifyOptions = '[Forgot Password] Verify Options',
  VerifyOptionsSuccess = '[Forgot Password] Verify Options Success',
  VerifyOptionsError = '[Forgot Password] Verify Options Error',
  AddAttempt = '[Forgot Password] Add Attempt',
  ResetAttempts = '[Forgot Password] Reset Attempts',
  Reset = '[Forgot Password] Reset'
}

export class RequestCode implements Action {
  readonly type = ForgotPasswordActionTypes.RequestCode;

  constructor(public payload: { credentials: any }) { }
}

export class RequestCodeSuccess implements Action {
  readonly type = ForgotPasswordActionTypes.RequestCodeSuccess;

  constructor(public payload: { response: any }) { }
}

export class RequestCodeError implements Action {
  readonly type = ForgotPasswordActionTypes.RequestCodeError;

  constructor(public payload: { error: any }) { }
}

export class RequestLink implements Action {
  readonly type = ForgotPasswordActionTypes.RequestLink;

  constructor(public payload: { credentials: any }) { }
}

export class RequestLinkSuccess implements Action {
  readonly type = ForgotPasswordActionTypes.RequestLinkSuccess;

  constructor(public payload: { response: any }) { }
}

export class RequestLinkError implements Action {
  readonly type = ForgotPasswordActionTypes.RequestLinkError;

  constructor(public payload: { error: any }) { }
}

export class ValidateCode implements Action {
  readonly type = ForgotPasswordActionTypes.ValidateCode;

  constructor(public payload: {
    memberEmail: string,
    airlineCode: string,
    verificationCode: string,
    organizationId: string }) { }
  }

export class ValidateCodeSuccess implements Action {
  readonly type = ForgotPasswordActionTypes.ValidateCodeSuccess;

  constructor(public payload: { response: any }) { }
}

export class ValidateCodeError implements Action {
  readonly type = ForgotPasswordActionTypes.ValidateCodeError;

  constructor(public payload: { error: any }) { }
}

export class VerifyOptions implements Action {
  readonly type = ForgotPasswordActionTypes.VerifyOptions;

  constructor(public payload: { params: any }) { }
}

export class VerifyOptionsSuccess implements Action {
  readonly type = ForgotPasswordActionTypes.VerifyOptionsSuccess;

  constructor(public payload: { response: any }) { }
}

export class VerifyOptionsError implements Action {
  readonly type = ForgotPasswordActionTypes.VerifyOptionsError;

  constructor(public payload: { error: any }) { }
}
export class AddAttempt implements Action {
  readonly type = ForgotPasswordActionTypes.AddAttempt;
}
export class ResetAttempts implements Action {
  readonly type = ForgotPasswordActionTypes.ResetAttempts;
}
export class Reset implements Action {
  readonly type = ForgotPasswordActionTypes.Reset;

  constructor() { }
}

export type ForgotPasswordActionsUnion = RequestCode
  | RequestCodeSuccess
  | RequestCodeError
  | RequestLink
  | RequestLinkSuccess
  | RequestLinkError
  | ValidateCode
  | ValidateCodeSuccess
  | ValidateCodeError
  | VerifyOptions
  | VerifyOptionsSuccess
  | VerifyOptionsError
  | AddAttempt
  | ResetAttempts
  | Reset;
