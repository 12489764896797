import { Injectable } from '@angular/core';
import { HOTEL_RESULTS_MAP_REFRESH_TIME } from 'app/hotels/containers/results/hotel-results.const';
import { HotelMapMarker } from 'app/hotels/models/hotel-map-marker';
import { GeneralHelper } from 'app/shared/helpers/general.helper';
import { Subject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { MapMarker } from '../../../models/map-marker';
import { MapLayerService } from '../map-layer.service';
import { MapService } from '../map.service';

declare var L: any;

@Injectable({
  providedIn: 'root'
})
export class LeafletService implements MapService {

  KM_TO_MILES_MULTIPLIER = 0.621371;
  mapBuffer = 0.01;

  private defaultZoom = 10;
  private minZoom = 8;
  private maxZoom = 20;

  // Initial flyTo triggers visible hotels re-render, so we're tracking the initial zoom to prevent overcalculations.
  private hadInitialZoom = false;

  // buildMap triggers 2 region changes, with these 2 flags we make sure we dont fire api request from them
  private ignoreRegionChange: number = 1;
  private markerClusterGroup: any;
  private markerGroup: any;
  private destinationMarker: any;
  private polygons: any = [];
  private callbackReference: any;
  private clusterAnimationEnd$ = new Subject<Event>();
  private mapMoveEnd$ = new Subject<Event>();
  private mapZoomEnd$ = new Subject<Event>();
  private sortBy = 'recommended';
  private selectedMarker: HTMLElement;

  constructor(
    private mapLayerService: MapLayerService) {
    merge(this.clusterAnimationEnd$.asObservable(), this.mapMoveEnd$.asObservable(), this.mapZoomEnd$.asObservable())
      .pipe(
        debounceTime(HOTEL_RESULTS_MAP_REFRESH_TIME),
        distinctUntilChanged(),
        filter(() => !!this.callbackReference),
        filter(() => this.hadInitialZoom),
      )
      .subscribe((e) => {
        this.callbackReference(e);
      });
  }

  initialize(): Promise<any> {
    return this.mapLayerService.initialize();
  }

  removeLayer() {
    this.mapLayerService.removeLayer();

    this.clusterAnimationEnd$.complete();
    this.mapMoveEnd$.complete();
  }

  buildMap(latitude: number, longitude: number, radius?: number): any {
    const map = L.map('map', {
      center: L.latLng(latitude, longitude),
      maxZoom: this.maxZoom,
      minZoom: this.minZoom,
      zoom: this.defaultZoom,
      zoomControl: false,
      layers: [this.mapLayerService.getMapLayer()],
      attributionControl: false
    });
    const initialZoom = this.getZoom(map, latitude, longitude, radius);

    // Initial map zoom in
    map.whenReady(() => {
      setTimeout(() => {
        map.setZoom(initialZoom);
      }, 500)
      this.hadInitialZoom = true;
    });

    map.on('popupopen', (e: Event) => map.getContainer().classList.add('map-popup-open'));
    map.on('popupclose', (e: Event) => map.getContainer().classList.remove('map-popup-open'));
    map.on('moveend', (e: Event) => this.mapMoveEnd$.next(e));
    map.on('zoomend', (e: Event) => {
      if (!this.hadInitialZoom) {
        this.hadInitialZoom = true;
      }
      this.mapZoomEnd$.next(e)
    });

    // Popups trigger autopan to fit the entire hotel card in the map, triggering a movement.
    // Without the ignore region change validation, the hotels would update and the active popups would close.
    map.on('autopanstart', (e: Event) => {
      this.ignoreRegionChange++;
    });

    // get the markers currently on map
    map.getMarkersOnMap = () => {
      let totalMarkers = [];
      if (this.markerClusterGroup) {
        totalMarkers = this.markerClusterGroup._featureGroup.getLayers();
      } else if (this.markerGroup) {
        totalMarkers = this.markerGroup.getLayers();
      }
      return totalMarkers.filter((m: any) => map.getBounds().contains(m.getLatLng()));
    };

    return map;
  }

  setCenter(map: any, latitude: number, longitude: number, animate: boolean, radius: number): void {
    this.ignoreRegionChange++;
    if (map) {
      const zoomLevel = this.getZoom(map, latitude, longitude, radius);
      if (animate) {
        map.flyTo([latitude, longitude], zoomLevel);
      } else if (latitude && longitude) {
        map.panTo([latitude, longitude]);
      }
    }
  }

  getCenter(map: any) {
    const center = map.getCenter();
    return { latitude: center.lat, longitude: center.lng };
  }

  drawPolygons(map: any, polygons: any[]): any {
    const options = {
      color: '#F00',
      opacity: .2,
      weight: 2,
      dashArray: '2 2 6 2 6 2',
      interactive: false
    };
    polygons.forEach((polygon) => {
      if (polygon.geoInfo) {
        if (polygon.geoInfo && polygon.geoInfo.coordinates) {
          polygon.geoInfo.coordinates.forEach((shape) => {
            this.drawPolygon(map, shape, options);
          });
        }
      }
    });
    // fit all the polygons on the map
    if (this.polygons.length > 0) {
      map.fitBounds(L.featureGroup(this.polygons).getBounds());
    }
  }

  drawPolygon(map: any, polygon: any[], style: any) {
    const points = polygon.map((point) => {
      return [point[1], point[0]];
    });
    const leafletPolygon = L.polygon(points, style);
    this.polygons.push(leafletPolygon);
    map.addLayer(leafletPolygon);
  }

  removePolygons(map: any) {
    this.polygons.forEach(polygon => polygon.remove());
    this.polygons = [];
  }

  buildCluster(map: any, disableClusteringAtZoom: number, markers, clickCallback, getMarkersCallback, sortBy, maxClusterRadius: number = 30) {
    this.sortBy = sortBy;
    // create cluster group
    this.markerClusterGroup = L.markerClusterGroup({
      disableClusteringAtZoom,
      maxClusterRadius,
      iconCreateFunction: (cluster) => this.createClusterIcon(cluster, map),
      zoomToBoundsOnClick: false,
      spiderfyOnMaxZoom: false,
      removeOutsideVisibleBounds: true,
    });

    // broadcast the marker that are currently on map
    this.callbackReference = getMarkersCallback;
    this.markerClusterGroup.on("add", (e: Event) =>
      this.clusterAnimationEnd$.next(e)
    );

    // Highlight the marker on click and show highest ranked hotel popup
    this.markerClusterGroup.on("clusterclick", (c) => {
      const activeMarker = c.layer.getIconElement();
      this.updateActiveMarker(activeMarker);

      if (GeneralHelper.isMobileDevice()) {
        // Mobile shows hotel card instead
        return clickCallback(c);
      } else {
        // Show hotel item popup
        L.popup({
          className: "leaflet-popup-cluster-group",
        })
          .setLatLng(c.layer.getLatLng())
          .setContent(c.layer.popupDom)
          .openOn(map)
      }

      return clickCallback;
    });

    // add markers to clustergroup so that they can be clustered
    this.markerClusterGroup.addLayers(
      markers.filter((marker) => !!marker.getIcon().options.html)
    );

    // add clustergroup to the map
    map.addLayer(this.markerClusterGroup);
  }

  generateMarkers(marker: HotelMapMarker) {
    const { latitude, longitude, text, showBed } = marker;

    return L.marker([latitude, longitude], {
      icon: this.createIcon(text, showBed),
      alt: text
    })
  }

  updateActiveMarker(marker: HTMLElement) {
    if (!marker) {
      return;
    }

    if (this.selectedMarker) {
      this.selectedMarker?.classList?.remove('marker-active');
    }

    this.selectedMarker = marker;
    this.selectedMarker?.classList?.add('marker-active');
  }

  addMarker(map: any, marker: HotelMapMarker, onClick: any): any {
    const leafletMarker = this.generateMarkers(marker);

    leafletMarker.getIconElement = () => {
      return leafletMarker._icon;
    };

    leafletMarker.getData = () => {
      return marker;
    };

    if (marker.text && onClick) {
      leafletMarker.on('click', (ev) => {
        if (marker.popupDom) {
          onClick(ev);
          const displayPopup = marker && marker.instance && marker.instance.displayComponent;
          if (displayPopup !== false && !ev.target.getPopup()) {
            const popup = L.popup({ closeButton: true }).setContent(
              marker.popupDom
            );
            ev.target.bindPopup(popup).openPopup();
          }
        }
      });
    }

    if (!marker.text) {
      this.destinationMarker = leafletMarker;
      map.addLayer(leafletMarker);
    }

    return leafletMarker;
  }

  drawMarkersWithoutClustering(map: any, mapMarkers: any, getMarkerCallback) {
    this.markerGroup = L.layerGroup();

    mapMarkers.forEach((marker) => {
      marker.addTo(this.markerGroup);
    });

    // broadcast the marker that are currently on map
    this.callbackReference = getMarkerCallback;
    this.markerGroup.on('add', (e: Event) => this.clusterAnimationEnd$.next(e));

    map?.addLayer(this.markerGroup);
  }

  removeMarkers() {
    if (this.markerClusterGroup) {
      this.markerClusterGroup.clearLayers();
      this.markerClusterGroup.remove();
      this.markerClusterGroup = null;
    }
    if (this.markerGroup) {
      this.markerGroup.clearLayers();
      this.markerGroup.remove();
      this.markerGroup = null;
    }
    if (this.destinationMarker) {
      this.destinationMarker.remove();
      this.destinationMarker = null;
    }
  }

  extendBounds(map: any, markers: MapMarker[]): void {
    const latitudes = markers.map(x => x.latitude);
    const longitudes = markers.map(x => x.longitude);
    const westLng = Math.min(...longitudes) - this.mapBuffer;
    const eastLng = Math.max(...longitudes) + this.mapBuffer;
    const northLat = Math.max(...latitudes) + this.mapBuffer;
    const southLat = Math.min(...latitudes) - this.mapBuffer;

    map.fitBounds([[northLat, eastLng], [southLat, westLng]], { animate: true });

    this.ignoreRegionChange++;
  }

  onLocationChanged(map: any, fn: any): void {
    map.on('moveend', () => {
      if (this.ignoreRegionChange < 1) {
        const pos = this.getMapPosition(map);
        if (pos.latitude !== 0 && pos.longitude !== 0) {
          fn(pos);
        }
      } else {
        this.ignoreRegionChange--;
      }
    });
  }

  onLocationChangedSubject(map: any, fn: any): void {
    const moveEndSubject = new Subject<void>();

    moveEndSubject
      .pipe(
        debounceTime(HOTEL_RESULTS_MAP_REFRESH_TIME),
      )
      .subscribe(() => {
        if (this.ignoreRegionChange < 1) {
          const pos = this.getMapPosition(map);
          if (pos.latitude !== 0 && pos.longitude !== 0) {
            map.closePopup();
            fn(pos);
          }
        } else {
          this.ignoreRegionChange--;
        }
      });

    map.on('moveend', (data) => {
      moveEndSubject.next(data);
    });
  }

  getRadius(map: any) {
    const rectangle = map.getBounds();

    const lat1 = rectangle.getNorthEast().lat;
    const lng1 = rectangle.getNorthEast().lng;

    const lat2 = rectangle.getNorthWest().lat;
    const lng2 = rectangle.getNorthWest().lng;

    const latDistance = map.distance([lat1, lng1], [lat2, lng2]);

    const lat3 = rectangle.getSouthEast().lat;
    const lng3 = rectangle.getSouthEast().lng;

    const lngDistance = map.distance([lat1, lng1], [lat3, lng3]);

    return this.metersToMiles(Math.min(latDistance, lngDistance)) / 2;
  }

  setRadius(map: any, radius: number, animate?: boolean, lat?: number, lng?: number): void {
    let zoom = 0;
    if (lat && lng) {
      zoom = this.getZoom(map, lat, lng, radius);
    } else {
      zoom = this.getZoom(map, null, null, radius);
    }

    this.ignoreRegionChange++;
    map.setZoom(zoom);
  }

  setZoomEnabled(map: any, enabled: boolean): void {
    if (enabled) {
      map.setMinZoom(this.minZoom);
      map.setMaxZoom(this.maxZoom);
      map.setZoom(this.defaultZoom);
      L.control.zoom().addTo(map);
    } else {
      map.setMinZoom(this.defaultZoom);
      map.setMaxZoom(this.defaultZoom);
      map.setZoom(this.defaultZoom);
      L.control.zoom().remove();
    }
  }

  setZoomLevel(map: any, level: number): void {
    this.ignoreRegionChange++;
    map.setZoom(level);
  }

  showScale(map: any): void {
    L.control.scale({ position: 'topright' }).addTo(map);
  }

  setInteractive(map: any, isInteractive: boolean, options?: any) {
    if (!isInteractive) {
      map.dragging.disable();
      map.doubleClickZoom.disable();
      map.scrollWheelZoom.disable();
    }
  }

  private createIcon(text: string, showBed: boolean = false) {
    if (showBed) {
      return L.divIcon({ className: 'marker', html: `<img class="bed-image" src="angular_assets/images/icons/bed-solid-white.svg"></img>`, iconSize: [35, 25], iconAnchor: [15, 20] });
    }

    return text ? L.divIcon({ className: 'marker', html: text, iconSize: null, iconAnchor: [text.length * 5, 30], popupAnchor: [0, -30] }) : L.divIcon({ className: 'marker', iconSize: [30, 30] });
  }

  private createClusterIcon = (cluster, map) => {
    const highestRankedMarker = this.getHighestRankingMarker(cluster.getAllChildMarkers());
    const price = highestRankedMarker.getData().text;

    cluster._latlng = highestRankedMarker._latlng;
    cluster.highestRankingMarker = highestRankedMarker;
    cluster.getIconElement = () => cluster._icon;
    cluster.getData = highestRankedMarker.getData;
    cluster.popupDom = highestRankedMarker.getData().popupDom;

    return L.divIcon({
      className: 'marker',
      html: price,
      iconSize: null,
      iconAnchor: [price.length * 5, 30],
      popupAnchor: [0, -30]
    });
  }

  private getHighestRankingMarker = (markers) => {
    let max;
    switch (this.sortBy) {
      case 'priceHighLow':
        max = markers.reduce((prev, current) =>
          (this.convertTextPriceToInt(prev.getData().text) > this.convertTextPriceToInt(current.getData().text)) ? prev : current
        );
        break;
      case 'priceLowHigh':
        max = markers.reduce((prev, current) =>
          (this.convertTextPriceToInt(prev.getData().text) < this.convertTextPriceToInt(current.getData().text)) ? prev : current
        );
        break;
      case 'rating':
        max = this.compareMarker(markers, 'star_rating');
        break;
      case 'reviewScore':
        max = this.compareMarker(markers, 'review_rating');
        break;
      case 'savings':
        max = this.compareMarker(markers, 'savings_amount');
        break;
      case 'propertyName':
        max = markers.reduce((prev, current) => {
          const compare = prev.getData().name.localeCompare(current.getData().name);
          return compare <= 0 ? prev : current;
        });
        break;
      default:
        max = markers.reduce((prev, current) =>
          (prev.getData().feature.ranking_score > current.getData().feature.ranking_score) ? prev : current
        );
        break;
    }
    return max;
  }

  private getMapPosition(map: any) {
    return {
      ...this.getCenter(map),
      radius: this.getRadius(map)
    };
  }

  private getZoom(map: any, lat: number, lng: number, radius: number) {
    const center = lat && lng ? L.latLng(lat, lng) : map.getCenter();
    const radiusInMeters = this.milesToMeters(radius ? radius : this.defaultZoom);
    const bounds = center.toBounds(radiusInMeters * 2);

    return map.getBoundsZoom(bounds);
  }

  private milesToMeters(miles) {
    return (miles / this.KM_TO_MILES_MULTIPLIER) * 1000;
  }

  private metersToMiles(meters) {
    return (meters / 1000) * this.KM_TO_MILES_MULTIPLIER;
  }

  private convertTextPriceToInt(price) {
    return parseInt(price.substring(1).replace(/,/g, ''), 10);
  }

  private compareMarker(array, attribute) {
    return array.reduce((prev, current) => (prev.getData()[attribute] > current.getData()[attribute]) ? prev : current);
  }

}
