import { Action } from '@ngrx/store';
import { UserCreditHistoryItem } from 'app/profile/models/user-credit-history.interface';

export enum CreditHistoryActionTypes {
  LoadCredits = '[Credit/History] Load Credits',
  LoadCreditsSuccess = '[Credit/History] Load Credits Success',
  LoadCreditsFailure = '[Credit/History] Load Credits Failure'
}

export class LoadCredits implements Action {
  readonly type = CreditHistoryActionTypes.LoadCredits;
}

export class LoadCreditsSuccess implements Action {
  readonly type = CreditHistoryActionTypes.LoadCreditsSuccess;

  constructor(public payload: { credits: UserCreditHistoryItem[] }) {}
}

export class LoadCreditsFailure implements Action {
  readonly type = CreditHistoryActionTypes.LoadCreditsFailure;

  constructor(public payload: { error: any }) {}
}

export type CreditHistoryActionsUnion = LoadCredits
  | LoadCreditsSuccess
  | LoadCreditsFailure;
