import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'app/auth/models/user';
import * as fromAuth from 'app/auth/store/reducers';
import * as fromRoot from 'reducers';
import { map, tap, take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class FirstTimeLoginGuard  {
  user: User;

  constructor(
    public router: Router,
    private store: Store<fromRoot.State>,
    protected translateService: TranslateService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.select<any>(fromAuth.getUser).pipe(
      take(1),
      map((user) => {
        this.user = user;

        if (!this.user.member.member_id || !user.first_time_login) {
          return true;
        }

        if (user.account && !user.account.personalEmail && !this.user.call_center_admin) {
          return false;
        }

        return true;
      }),
      tap(() => {
        if (this.user.member.member_id && this.user.first_time_login) {
          let queryParams = {};
          if (this.user.member.redirect) {
            queryParams = { redirect: this.user.member.redirect };
          }
          this.router.navigate(['/first_time/new'], { queryParams });
          return of(false);
        }

        // If user don't have an email address ask for one
        if (this.user.member.member_id &&
            !this.user.call_center_admin &&
            !this.user.first_time_login_session &&
          !this.user.account.personalEmail) {
          this.router.navigate(['/members/register-email'], { queryParams: { redirect: encodeURIComponent(state.url) } });
          return of(false)
        }
        return of(true)
      })
    );
  }
}
