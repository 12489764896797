import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SanitizerHelper {

  constructor(private sanitizer: DomSanitizer) {}

  public decodeValue(encodedValue) {
    if (!encodedValue || encodedValue === '') { return encodedValue; }
    const sanitizedValue = this.sanitizer.sanitize(SecurityContext.HTML, encodedValue) || '';
    const textarea = document.createElement('textarea');
    textarea.innerHTML = sanitizedValue;
    return textarea.value;
  }
}
