import { PromoCodeAction } from '../actions';
export const initialState: any = {
  response: null,
  pending: false,
  error: null,
  success: false,
};

export function reducer(
  state = initialState,
  action: PromoCodeAction.PromoCodeUnion
) {
  switch (action.type) {
    case PromoCodeAction.PromoCodeActionType.CheckPromoCode:
      return {
        ...state,
        pending: true
      };
    case PromoCodeAction.PromoCodeActionType.CheckPromoCodeSuccess:
      return {
        ...state,
        response: action.payload ? action.payload.promoCode : null,
        loaded: true,
        pending: false,
        error: null
      };
    case PromoCodeAction.PromoCodeActionType.CheckPromoCodeFailure:
      return {
        ...state,
        pending: false,
        error: action.payload.error
      };
    case PromoCodeAction.PromoCodeActionType.RedeemPromoCode:
      return {
        ...state,
        pending: true
      };
    case PromoCodeAction.PromoCodeActionType.RedeemPromoCodeFailure:
      return {
        ...state,
        error: action.payload.error,
        pending: false
      };
    case PromoCodeAction.PromoCodeActionType.RedeemPromoCodeFailure:
      return {
        ...state,
        error: action.payload.error,
        pending: false,
      };
    case PromoCodeAction.PromoCodeActionType.RedeemPromoCodeSuccess:
      return {
        ...state,
        success: true,
        pending: false,
      };
    default: {
      return state;
    }
  }
}

export const checkPromoCode = (state: any): boolean => state.pending;
export const checkPromoCodeSuccess = (state: any): any => state.response;
export const checkPromoCodeError = (state: any): any => state.error;
export const redeemPromoCode = (state: any): boolean => state.pending;
export const redeemPromoCodeSuccess = (state: any): any => state.success;
export const redeemPromoCodeError = (state: any): any => state.error;
