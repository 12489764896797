<div class="col">
    <div class="row">
        <div class="col" style="text-align: center;">
            <h3>{{ 'members.email.duplicate' | translate }}</h3>
        </div>
    </div>
    <div class="row">
        <div class="col" style="text-align: center;">
            <p>{{ 'members.email.email_asociated' | translate }} </p>
        </div>
    </div>
    <div class="row">
        <div class="col" style="text-align: center;">
            <p>{{ 'members.email.unlink_explanation' | translate }} </p>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 col-md-6 mb-2" style="text-align: right;"><button (click)="dismiss()" class="btn btn-outline-primary w-100" type="button">{{ 'members.email.use_other_email' | translate }}</button></div>
        <div class="col-sm-12 col-md-6"><button (click)="unlinkEmail()" class="btn btn-primary w-100" type="button">{{ 'members.email.send_verification_code' | translate }}</button></div>
    </div>
</div>