import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';

interface LoginForm {
    username: FormControl<string>;
    password: FormControl<string>;
}

@Component({
  selector: 'event-login-form',
  templateUrl: './event-login-form.html'
})
export class EventLoginFormComponent {
  @Input() errorMessage: string | null;
  @Output() submitted = new EventEmitter();

  loginForm: FormGroup<LoginForm> = new FormGroup<LoginForm>({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  });

  constructor(
    public formErrorsHelper: FormErrorsHelper,
    public translate: TranslateService
  ) { }

  login() {
    if (this.isValid()) {
      this.submitted.emit({ login: this.loginForm.value });
      this.loginForm.get('password').reset();
    } else {
      Object.keys(this.loginForm.controls).forEach((field) => {
        const control = this.loginForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }
  get username() { return this.loginForm.get('username'); }
  get password() { return this.loginForm.get('password'); }

  isValid() {
    return this.loginForm.valid;
  }

  closeAlert() {
    this.errorMessage = null;
  }
}
