<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ 'confirmation_modal.title' | translate }}</h4>
  <button type="button" class="btn-close" aria-label="Close" *ngIf="!loading && isPopup" (click)="dismiss()">

  </button>
</div>
<div class="modal-body" *ngIf="loading">
  <loading-indicator mode="fill-parent" [fullScreen]="false" ></loading-indicator>
</div>
<div class="modal-body" *ngIf="!loading">
  <error-component *ngIf="this.error.length > 0" [content]="this.error"></error-component>
  <div>{{'payment.confirmation_delete' | translate}}</div>
</div>
<div class="modal-footer">
  <button  *ngIf="!loading" (click)="dismiss()" type="button" class="btn btn-outline-light text-dark border" data-cy="cancel">
    {{ 'confirmation_modal.cancel' | translate }}
  </button>
  <button *ngIf="!loading" type="button" class="btn btn-md btn-primary" (click)="onDeletedClick(token)" data-cy="remove-button">
    {{ 'lodging.search_form.remove_button' | translate }}
  </button>
</div>
