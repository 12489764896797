import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AuthApiActions } from 'app/auth/store';
import { ProfileUserActions } from 'app/profile/store/actions';
import { SimpleModalComponent } from 'app/shared/components/simple-modal/simple-modal.component';
import { ProfileService } from 'app/shared/services/profile/profile.service';
import { UnlinkEmailActions } from 'app/shared/store/actions';
import { Clear } from 'app/shared/store/actions/unlink-email.action';
import * as fromShared from 'app/shared/store/reducers';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'unlink-email-redirect',
  templateUrl: './unlink-email-redirect.html',
  encapsulation: ViewEncapsulation.None
})
export class UnlinkEmailRedirectPage implements OnInit, OnDestroy {
  loading = true;
  unlinkEmailPending$ = this.store.pipe(select(fromShared.getUnlinkEmailVerifyCodePending));
  unlinkEmailError$ = this.store.pipe(select(fromShared.getUnlinkEmailVerifyCodeError));
  unlinkEmailSuccess$ = this.store.pipe(select(fromShared.getUnlinkEmailVerifyCodeSuccess));

  private url = '';
  private memberEmail = '';
  private ngUnsubscribe$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private store: Store<fromShared.State>,
    private router: Router,
    private modalService: NgbModal,
    private translate: TranslateService,
    private profileService: ProfileService
  ) {}

  ngOnInit() {
    this.store.dispatch(new Clear());
    this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((params) => {
      if (params.unlink_email && params.verification_code && params.redirect) {
        this.url = params.redirect;
        this.memberEmail = params.unlink_email;
        this.store.dispatch(new UnlinkEmailActions.UnlinkEmailVerifyCode({
          member_email: params.unlink_email,
          verification_code: params.verification_code
        }));
      } else {
        this.router.navigate(['/']);
      }
    });
    this.unlinkEmailPending$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((pending) => {
      this.loading = pending;
    });
    this.unlinkEmailError$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((error: any) => {
      if (error) {
        this.openModal(this.translate.instant('unlink_email.error.content'));
        this.router.navigate(['/']);
      }
    });
    this.unlinkEmailSuccess$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((success) => {
      if (success) {
        const data = {'personalEmail': this.memberEmail };
        this.profileService.updateProfile(data).pipe(take(1)).subscribe(
          () => {
            this.store.dispatch(new ProfileUserActions.UpdateUserProfile(data));
            this.store.dispatch(new AuthApiActions.UpdateUserAccountInfo(data));
            const content = this.translate.instant('unlink_email.success.content');
            this.openModal(content);
            return this.router.navigate([this.url]);
          }, error => {
            this.openModal(this.translate.instant('unlink_email.success.login'));
          }
        );
      }
    });
  }

  openModal(content) {
    const modalRef = this.modalService.open(SimpleModalComponent, {});
    modalRef.componentInstance.title = this.translate.instant('unlink_email.title');
    modalRef.componentInstance.content = content;
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
