<div class="modal-header">
  <div class="profile-title primary-content">
    {{ 'profile.info.delete_account.link' | translate }}
  </div>
</div>
<div class="modal-body" *ngIf="!passwordShown">
  <strong>{{ 'profile.info.delete_account.header' | translate }}</strong>
  <p class="secondary-content confirmation-msg" [innerHtml]="'profile.info.delete_account.text_info' | translate"></p>
  <p class="secondary-content confirmation-msg" [innerHtml]="'profile.info.delete_account.text_confirm' | translate"></p>
</div>
<div class="modal-body" *ngIf="passwordShown">
  <ngb-alert *ngIf="this.error.length" type="danger" (closed)="closeAlert()">{{ error }}</ngb-alert>
  <form [formGroup]="deleteProfileForm">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <password-input required [pattern]='' [control]="'password'" [id]="'password'" [form]="deleteProfileForm" [placeholder]=""
                          [label]="'mobile.password_reset.current_password_label' | translate" [showForgotPassword]="false" [showTooltip]="false" autocomplete="password">
          </password-input>
          <div
            class="error-message"
            *ngIf="password.errors !== null && password.touched"
            [innerHtml]="passwordError">
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer pt-0 pt-md-4">
  <div class="d-md-flex flex-row justify-content-end">
    <div *ngIf="!passwordShown">
      <button type="button" class="btn btn-sm btn-link w-100 me-3" (click)="showPassword()">{{ 'profile.info.delete_account.yes_button' | translate }}</button>
    </div>
    <div *ngIf="passwordShown">
      <button type="button" class="btn btn-sm btn-link w-100 me-3" (click)="submit()" [disabled]="loading || !deleteProfileForm?.valid">{{ 'profile.info.delete_account.yes_button' | translate }}</button>
    </div>
    <div>
      <button type="button" class="btn btn-sm btn-primary w-100 px-5" (click)="dismiss()">{{ 'profile.info.delete_account.no_button' | translate }}</button>
    </div>
  </div>
</div>
