import { Component, Input, OnInit, ChangeDetectorRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { Address } from 'app/shared/models/address';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { StateService } from 'app/shared/services/state/state.service';
import { Country } from 'app/shared/models/country.model';
import { State } from 'app/shared/models/state.model';
import { LocationsAutocompleteComponent } from '../locations/locations.autocomplete.component';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';
import { GeocoderAddress } from 'app/shared/services/geocoding/geocoding.service';
import { environment } from '@env/environment';
import { take } from 'rxjs/operators';
import { throwError,combineLatest } from 'rxjs';
import * as fromRoot from 'reducers';
import * as fromShared from 'app/shared/store/reducers';
import { Store } from '@ngrx/store';
import { SanitizerHelper } from 'app/shared/helpers/sanitizer.helper';

@Component({
  selector: 'address-form',
  templateUrl: 'address-form.html'
})
export class AddressFormComponent implements OnInit {
  @ViewChild('addressStreet', { static: false }) addressStreet: LocationsAutocompleteComponent;
  @Input() address: Address = new Address();
  @Input() showStreet = true;
  @Input() showCity = true;
  @Input() showState = true;
  @Input() showCountry = true;
  @Input() showZip = true;
  @Input() disabledFields = {
    zipCode: false,
    street: false,
    city: false,
    state: false,
    country: false
  };
  @Input() cityMaxLength = 100;
  @Input() addressMaxLength = 100;
  @Output() addressSelected = new EventEmitter();

  addressForm: UntypedFormGroup;
  countries$ = this.store.select(fromShared.getCountries);
  countries: Country[];
  states: State[] = [];
  pendingState = '';
  // We have to use this so we can populate the country select with 2 or 3 letter country codes. Google uses 2, ArcGis uses 3.
  activeGeocoder = environment.geocodingVendor;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public formErrorsHelper: FormErrorsHelper,
    private stateService: StateService,
    private cd: ChangeDetectorRef,
    private store: Store<fromRoot.State>,
    private sanitizerHelper: SanitizerHelper
  ) { }

  ngOnInit() {
    this.address.city = this.sanitizerHelper.decodeValue(this.address.city);
    this.address.country = this.sanitizerHelper.decodeValue(this.address.country);
    this.address.street = this.sanitizerHelper.decodeValue(this.address.street);
    this.address.state = this.sanitizerHelper.decodeValue(this.address.state); 
    this.loadInfo();
  }

  loadInfo() {
    combineLatest([this.countries$]).pipe(take(1)).subscribe(([countries]) => {
      this.countries = countries;
    });
    this.addressForm = this.formBuilder.group({
      zipCode: [{ value: this.sanitizerHelper.decodeValue(this.address.zipCode), disabled: this.disabledFields.zipCode }, this.showZip ?
        [Validators.required, Validators.pattern('^[0-9-A-Za-z /]*$')] :
        null
      ],
      street: [{ value: this.sanitizerHelper.decodeValue(this.address.street), disabled: this.disabledFields.street }, this.showStreet ?
        Validators.required :
        null
      ],
      city: [{ value: this.sanitizerHelper.decodeValue(this.address.city), disabled: this.disabledFields.city }, this.showCity ?
        Validators.required :
        null
      ],
      state: [{ value: this.sanitizerHelper.decodeValue((this.address.state)), disabled: this.disabledFields.state }, this.showState ?
        Validators.required :
        null
      ],
      country: [{ value: this.sanitizerHelper.decodeValue(this.address.country), disabled: this.disabledFields.country }, this.showCountry ?
        Validators.required :
        null
      ]
    });

    if (this.address.country && this.address.country.length) { this.getStates(); }
  }

  getStates() {
    this.states = [];
    this.stateService.get(this.address.country).pipe(take(1)).subscribe((response) => {
      this.states = response;
      this.addressForm.get('state').setValidators(Validators.required);
      if (this.pendingState) {
        if (!this.checkIfStateHasDisplayValue(this.pendingState, response)) {
          this.addressForm.get('state').reset();
          return;
        }
        this.addressForm.get('state').setValue(this.pendingState);
        this.address.state = this.pendingState;
        this.pendingState = '';
      } else if (this.states.filter((value) => { return value['code'] === this.address.state; }).length === 0) {
        this.address.state = '';
      }
      this.cd.detectChanges();
    },((err) => {
      if (err.status === 404) {
        // this "country" has no states. ie, hong kong
        this.states = [];
        this.addressForm.get('state').clearValidators();
        this.addressForm.get('state').setValue(null);
        this.singleFieldChange(null,'state');
      }
      return throwError(err);
    }));
    this.addressSelected.emit();
  }

  isValid() {
    return this.addressForm.valid;
  }

  locationSelected(location: GeocoderAddress) {
    this.addressForm.reset();

    this.addressForm.get('street').setValue(this.sanitizerHelper.decodeValue(location.street));
    this.address.street = location.street;

    this.addressForm.get('city').setValue(this.sanitizerHelper.decodeValue(location.city));
    this.address.city = location.city;

    this.addressForm.get('zipCode').setValue(this.sanitizerHelper.decodeValue(location.zip));
    this.address.zipCode = location.zip;

    this.setCountry(location);

    this.pendingState = location.state;
    this.getStates();
    this.addressSelected.emit(this.address);
  }

  singleFieldChange(value: string, field: string) {
    this.addressForm.get(field).setValue(value);
    this.address[field] = value;
    return this.addressSelected.emit(this.address)
  }

  addressSet(street) {
    this.addressForm.get('street').setValue(street);
    this.address.street = street;
  }

  setCountry(location) {
    let countryCode = location.country;
    if (this.activeGeocoder === 'arcgis' || countryCode.length === 3) {
      const selectedCountry = this.countries.find(country => country.code3 === location.country);
      countryCode = selectedCountry.code;
    }
    this.addressForm.get('country').setValue(countryCode);
    this.address.country = countryCode;
  }

  checkIfStateHasDisplayValue(countryCode: string, allCountryCodes: any[]): boolean {
    return allCountryCodes.find((country) => country.code === countryCode);
  }

  get street() { return this.addressForm ? this.addressForm.get('street') : null; }
  get zipCode() { return this.addressForm ? this.addressForm.get('zipCode') : null; }
  get country() { return this.addressForm ? this.addressForm.get('country') : null; }
  get state() { return this.addressForm ? this.addressForm.get('state') : null; }
  get city() { return this.addressForm ? this.addressForm.get('city') : null; }
}
