import { Component, Input } from '@angular/core';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'member-loyalty-card',
  templateUrl: './member-loyalty-card.component.html',
  styleUrls: ['./member-loyalty-card.component.scss']
})
export class MemberLoyaltyCardComponent {

  @Input() userCredits;
  @Input() memberLoyalty;
  icons = { faTrophy };

  constructor() { }

}
