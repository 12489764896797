import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';

@Injectable()
export class CreditCardHelper {

  static cardTypes = [
    {
      code: 'amex',
      short_code: 'A',
      name: 'American Express',
      pattern: /^3[47]/,
      valid_length: [15],
      mask: [[0, 4], [4, 10], [10, 15]]
    }, {
      code: 'visa',
      short_code: 'V',
      name: 'Visa',
      pattern: /^4/,
      valid_length: [16],
      mask: [[0, 4], [4, 8], [8, 12], [12, 16]]
    }, {
      code: 'mc',
      short_code: 'M',
      name: 'MasterCard',
      pattern: /^5[1-5]/,
      valid_length: [16],
      mask: [[0, 4], [4, 8], [8, 12], [12, 16]]
    }, {
      code: 'disc',
      short_code: 'D',
      name: 'Discover',
      pattern: /^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)/,
      valid_length: [16],
      mask: [[0, 4], [4, 8], [8, 12], [12, 16]]
    }, {
      code: 'diners',
      short_code: 'DC',
      name: 'Diners Club',
      pattern: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
      valid_length: [16],
      mask: [[0, 5], [5, 9], [9, 16]]
    }
  ];

  static ALLOWED_HOTEL_CARDS = ['amex', 'visa', 'mc'];
  static NO_FOREIGN_CURRENCY_CARDS = ['amex'];

  static getCreditCardType(number): any {
    let type = { code: '', name: '', mask: [] };
    if (number) {
      CreditCardHelper.cardTypes.forEach((cardType) => {
        if (number.match(cardType.pattern)) {
          type = cardType;
        }
      });
    }
    return type;
  }

  static getCodeByShortCode(shortCode: string): any {
    const type = this.cardTypes.find((cc) => {
      return cc.short_code === shortCode;
    });

    return type.code;
  }

  static getCreditCardName(code) {
    const selectedCard =  this.cardTypes.find((card) => {
      return card.code === code;
    });
    return selectedCard.name;
  }

  static isExpired(expirationDate) {
    if (!expirationDate) {
      return false;
    }

    const current = new Date();
    let date = expirationDate.split('-');
    date = new Date(date[0], date[1], 0);
    return date < current;
  }

  static isValidHotelCCType(number) {
    const cc = CreditCardHelper.getCreditCardType(number);
    return CreditCardHelper.ALLOWED_HOTEL_CARDS.indexOf(cc.code) > -1;
  }

  getCreditCardType(number) {
    return CreditCardHelper.getCreditCardType(number);
  }

  static formatExpirationDate(date) {
    const splitDate = date.split('/');
    if (splitDate.length === 2) {
      return `20${splitDate[1]}-${splitDate[0]}`;
    }
    return date;
  }

  static acceptedCard(ccList = []): ValidatorFn {
    return (control: AbstractControl) => {
      const number = control.value.replace(/ /g, '');
      if (number) {
        const notAllowed = !this.validAndAllowedCC(number, ccList);
        if (notAllowed) {
          const type = this.getCreditCardType(number).name;
          if (type) {
            return { customError: 'payment.invalid_card_type' };
          }
          return { customError:  'payment.invalid_card_number' };
        }
        return undefined;
      }
    };
  }

  static validAndAllowedCC(number: string, ccListAllowed: string[]) {
    if (ccListAllowed.length) {
      const cc = CreditCardHelper.getCreditCardType(number);
      const allowed = ccListAllowed.filter((list) => {
        return list === cc.code;
      });

      return allowed.length > 0;
    }

    return this.isValidHotelCCType(number);
  }

  static isForeignCurrencyCompatible(): ValidatorFn {
    return (control: AbstractControl) => {
      const number = control.value.replace(/ /, '');
      const type = this.getCreditCardType(number).code;
      if (CreditCardHelper.NO_FOREIGN_CURRENCY_CARDS.indexOf(type) !== -1) {
        return { customError: 'payment.invalid_foreign_currency_cc' };
      }
      return undefined;
    };
  }

  static expireDateValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      const date = control.value;
      const dateSplit = date.split('/');
      const today = new Date();
      if (!date.match(/(0[1-9]|1[0-2])\/\d{2}/)) {
        return { customError: 'payment.date_format' };
      }

      if (
        dateSplit.length === 2 &&
        dateSplit[1].length === 2 &&
        today > new Date(parseInt(`20${dateSplit[1]}`, 10), dateSplit[0], 0)
      ) {
        return { customError: 'payment.future_date' };
      }

      return undefined;
    };
  }
}
