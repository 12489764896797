import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { CoTravelerItinerarySummary } from 'app/auth/components/co-traveler-itinerary-summary/co-traveler-itinerary-summary.component';
import { EligibleTravelerInfoSummary } from 'app/auth/components/eligible-traveler-summary/eligible-traveler-summary.component';
import { ShareItinerariesTypes } from 'app/hotels/models/share-itinerary';
import { Modal } from 'app/shared/models/modal.model';
import { CoTravelersService } from 'app/shared/services/co-travelers/co-travelers.service';
import { EligibleTravelersService } from 'app/shared/services/eligible-travelers/eligible-travelers.service';
import { InviteFriendsService } from 'app/shared/services/invite-friends/invite-friends.service';
import { SessionService } from 'app/shared/services/session/session.service';
import { AirlineActions, CountryActions, ModalActions } from 'app/shared/store/actions';
import * as fromShared from 'app/shared/store/reducers';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { RegisterPageActions } from '../store/actions';
import * as fromAuth from '../store/reducers';

@Component({
  selector: 'register-invited',
  templateUrl: './register-invited.html',
  styleUrls: ['./register-invited.scss']
})
export class RegisterInvitedPage implements OnInit, OnChanges, OnDestroy {
  referrerName: string;
  inviteCode = this.activatedRoute.snapshot.queryParams['invite_code'] || '';
  uniqueCode = this.activatedRoute.snapshot.queryParams['u_code'] || '';
  shareCode = this.activatedRoute.snapshot.queryParams['share_code'] || '';
  travelerCode = this.activatedRoute.snapshot.queryParams['traveler_code'] || '';
  inviteCodeType: string;
  inviteCodeSource: string;
  utmSource = this.activatedRoute.snapshot.queryParams['utm_source'] || '';
  utmMedium = this.activatedRoute.snapshot.queryParams['utm_medium'] || '';
  utmCampaign = this.activatedRoute.snapshot.queryParams['utm_campaign'] || '';
  promoCode = this.activatedRoute.snapshot.queryParams['promo_code'] || '';
  pending$ = this.store.pipe(select(fromAuth.getRegisterPagePending));
  error$ = this.store.pipe(select(fromAuth.getRegisterPageError));
  pendingLogin$ = this.store.pipe(select(fromAuth.getLoginPagePending));
  loggedIn$ = this.store.select<any>(fromAuth.getLoggedIn);
  airlines$ = this.store.pipe(select(fromShared.getAirlines));
  countries$ = this.store.pipe(select(fromShared.getCountries));

  itinerary: CoTravelerItinerarySummary;
  eligibleTravelerInfo: EligibleTravelerInfoSummary;
  defaultEmail: string;
  ngUnsubscribe$ = new Subject<void>();
  pendingTemplate = true;
  // private shareItinerarySource = '5';

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store<fromAuth.State>,
    private sessionService: SessionService,
    private coTravelerService: CoTravelersService,
    private eligibleTravelersService: EligibleTravelersService,
    private inviteFriendsService: InviteFriendsService
  ) { }

  ngOnInit() {
    this.store.dispatch(new CountryActions.LoadCountries());
    if (localStorage.getItem('invite_code')) {
      this.inviteCode = localStorage.getItem('invite_code');
    }
    this.inviteCodeSource = this.activatedRoute.snapshot.queryParams['s'] || this.sessionService.getInviteCodeSource() || '';
    this.inviteCodeType = this.activatedRoute.snapshot.queryParams['c'] || this.sessionService.getInviteCodeType() || '';
    this.activatedRoute.params.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(({ username }) => {
      this.referrerName = username && username !== 'id90Member' ? this.getUsernameFromPrettify(username) : '';
    });
    this.loggedIn$.pipe(take(1)).subscribe((loggedIn) => {
      if (loggedIn) {
        if (this.promoCode) {
          return this.router.navigate(['/profile/hotel_credits'], {
            queryParams: {
              cc: this.promoCode,
              ic: this.inviteCode
            }
          });
        }

        if (this.shareCode) {
          return this.router.navigate(['/share-itinerary'] , {
            queryParams: {
              fromRegister: true
            }
          });
        }

        return this.redirectToIndex();
      }

      if (!this.inviteCode && !this.promoCode) {
        return this.router.navigate(['/create-account']);
      }

      this.airlines$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((airlines) => {
        if (!airlines.length) {
          this.store.dispatch(new AirlineActions.LoadAirlines());
        }
      });
      this.saveCodes();
      this.validInviteCode();
      this.validateUniqueCode();
      this.validateTravelerCode();
      this.getItineraryInformation();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.eligibleTravelerInfo && changes.eligibleTravelerInfo.currentValue) {
      this.eligibleTravelerInfo = changes.eligibleTravelerInfo.currentValue;
    }
  }

  private getUsernameFromPrettify(username: string) {
    if (!username.includes('_')) return username;
    const user = username.split('_');
    return `${user[0]} ${user[1]}`;
  }

  validateUniqueCode() {
    if (!this.uniqueCode) { return; }
    this.validInviteExpiration();
    this.coTravelerService.getInvitedByUnique(this.uniqueCode).pipe(take(1)).subscribe((response) => {
      if (response && response.user && response.user.description) {
        this.defaultEmail = response.user.description;
      }
    });
  }

  validateTravelerCode() {
    if (this.travelerCode) {
      this.eligibleTravelersService.validateTravelerCode(this.travelerCode).pipe(take(1)).subscribe(
        (response) => {
          if (response.valid && response.employee_information) {
            this.eligibleTravelerInfo = this.buildEligibleTravelerData(response.employee_information);
            this.defaultEmail = response.invitation.email;
            this.pendingTemplate = false;
          }
        },
        () => {
          const data: Modal = {
            title: 'web.create_eligible_traveler_account.expiration_title',
            bodyTranslationKey: 'web.create_eligible_traveler_account.expiration_message'
          };
          this.store.dispatch(new ModalActions.Show({ data }));
          return this.router.navigate(['/']);
        }
      );
    } else {
      this.pendingTemplate = false;
    }

    return;
  }

  saveCodes() {
    if (this.inviteCode) {
      this.sessionService.setInviteCode(this.inviteCode);
    }

    if (this.uniqueCode) {
      this.sessionService.setUniqueInviteCode(this.uniqueCode);
    }

    if (this.inviteCodeType) {
      this.sessionService.setInviteCodeType(this.inviteCodeType);
    }

    if (this.inviteCodeSource) {
      this.sessionService.setInviteCodeSource(this.inviteCodeSource);
    }

    if (this.shareCode) {
      this.sessionService.setShareCode(this.shareCode);
    }

    if (this.promoCode) {
      this.sessionService.setPromoCode(this.promoCode);
    }

    this.store.dispatch(new RegisterPageActions.SetInviteParams({ inviteParams: this.activatedRoute.snapshot.queryParams }));
  }

  createAccount(credentials) {
    if (this.shareCode) {
      credentials.redirect = 'share-itinerary'; // After register redirect to share-itinerary
    }

    this.store.dispatch(new RegisterPageActions.Register({ credentials }));
  }

  getItineraryInformation() {
    if (!this.shareCode) {
      return false;
    }

    this.coTravelerService.getItineraryByShareCode(this.shareCode).pipe(take(1)).subscribe((response) => {
      if (response && response.data) {
        if (response.data.itinerary) {
          this.itinerary = this.buildItineraryData(response.data.itinerary);
        }

        if (response.data.invitation &&
          response.data.invitation.description &&
          response.data.invitation.type === ShareItinerariesTypes.EMAIL
        ) {
          this.defaultEmail = response.data.invitation.description;
        }
      }
    });
  }

  buildItineraryData(data: any): CoTravelerItinerarySummary {
    return {
      image: data.hotelImage,
      city: data.hotelCity,
      name: data.hotelName ? data.hotelName : '',
      arrivalDate: data.startDate ? moment(data.startDate).utc().format('MMM Do') : '',
      departureDate: data.endDate ? moment(data.endDate).utc().format('MMM Do') : '',
      ownerName: `${data.customerName} ${data.customerLastName}`,
      ownerEmail: data.bookingEmail ? data.bookingEmail : '',
      ownerAvatar: {
        abbr: data.customerName ? data.customerName[0] : data.bookingEmail[0],
        name: data.customerName || data.bookingEmail
      }
    };
  }

  buildEligibleTravelerData(employee: any): EligibleTravelerInfoSummary {
    const airlineName = employee.airlines.short_name.split(' (');
    return {
      employeeFirstName: employee.first_name,
      employeeLastName: employee.last_name,
      employeeFullName: `${employee.first_name} ${employee.last_name}`,
      employeeAirlineName: airlineName[0] ? airlineName[0] : '',
      employeeAvatar: {
        name: employee.first_name,
        abbr: employee.first_name[0]
      }
    };
  }

  redirectToIndex() {
    const data: Modal = {
      title: 'flights.search.modals.passengers.title',
      bodyTranslationKey: 'web.create_account_logged_in'
    };
    this.store.dispatch(new ModalActions.Show({ data }));
    return this.router.navigate(['/']);
  }
  validInviteCode() {
    this.inviteFriendsService.validInviteCode(this.inviteCode).pipe(take(1)).subscribe((response) => {
      if (response.disabled_invite) {
        return this.showDisabledModal();
      }
      if (!response.enable_invite) {
        return this.showExpiredModal();
      }
      return;
    });
  }

  validInviteExpiration() {
    this.inviteFriendsService.validateExpirationInvite(this.uniqueCode).pipe(take(1)).subscribe((response) => {
      if (!response.link_enabled) {
        return this.showExpiredModal();
      }
      return;
    });
  }

  showExpiredModal(){
    const data: Modal = {
      title: 'mobile.invite_friends.invite_expired_title',
      bodyTranslationKey: 'mobile.invite_friends.invite_expired_body'
    };
    this.showModal(data);
  }

  showDisabledModal(){
    const data: Modal = {
      titleTranslationKey: 'mobile.invite_friends.invite_disabled_title',
      bodyTranslationKey: 'mobile.invite_friends.invite_disabled_body'
    };
    this.showModal(data);
  }

  showModal(data: Modal) {
    this.store.dispatch(new ModalActions.Show({ data }));
    this.store.dispatch(new RegisterPageActions.ClearInviteParams());
    return this.router.navigate(['/']);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
