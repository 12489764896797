export const featureFlags = {
  // NOTE: Add a new feature alphabetically
  ABOUT: 'about',
  ACTIVITIES: 'activities',
  ADDRESS: 'address',
  AIRPORT_TRANSFER: 'airport_transfer',
  ALL_INCLUSIVE: 'all_inclusive',
  APPLE: 'apple_linked',
  BEAMER: 'beamer',
  BLOG: 'blog',
  BOOK_AGAIN: 'book_again',
  CARS: 'cars',
  CARS_RECENT_SEARCHES: 'cars_recent_searches',
  CHOOSE_AIRLINE_PRICING_IN_CRS: 'choose_airline_pricing_in_crs',
  CONTACT: 'contact',
  CO_TRAVELERS: 'co_travelers',
  CREATE_ACCOUNT: 'create_account',
  CRUISES: 'cruises',
  DOWNLOAD_APP: 'download_app',
  EDIT_PROFILE: 'edit_profile',
  ELIGIBLE_TRAVELERS: 'eligible_travelers',
  EMPLOYMENT: 'employment',
  FACEBOOK: 'facebook_linked',
  FAQ: 'faq',
  FLIGHTS: 'flights',
  FLIGHT_LOADS_PREDICTIONS: 'flight_load_predictions',
  FLIGHT_LOADS_PREDICTIONS_V2: 'flight_load_predictions_v2',
  GIFT_CARDS: 'gift_cards',
  GOOGLE: 'google_linked',
  HEADER: 'header',
  HELP_CHOOSE_CRUISES: 'help_choose_cruises',
  HOTELS: 'hotels',
  HOTEL_HERO_SECTION: 'hotel_hero_section_enabled',
  HOTELS_RECENT_SEARCHES: 'hotels_recent_searches',
  HOTELS_RECENT_VIEW: 'hotels_recent_view',
  HOW_TO_NON_REV: 'how_to_non_rev',
  INSURANCE: 'insurance',
  INVITE: 'invite',
  JUST_FOR_YOU: 'just_for_you',
  LANGUAGE: 'language',
  LOGIN: 'login',
  LOGIN_AND_SECURITY: 'login_&_security',
  MEMBER_REWARDS: 'member_rewards',
  MFA: 'mfa',
  NEW_PASSWORD: 'new_password',
  NEWS: 'news',
  PASSPORT: 'passport',
  PAYMENT_METHODS: 'payment_methods',
  PROFILE_MENU: 'profile_menu',
  RECOMMENDED_DESTINATIONS: 'recommended_destinations',
  SOCIAL_LINKS: 'social_links',
  STATIC_CARD: 'static_card',
  TRIPS: 'trips',
  THIRD_PARTY_LOGIN: 'third_party_login',
  WATCHLIST: 'watchlist',
  WEEKLY_RENTAL: 'weekly_rental',
  WHATS_NEW: 'whats_new',
  WHITE_LABEL: 'white_label',
  WEB_GAMIFICATION_SECTION: 'web_gamification_section',
};

