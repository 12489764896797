import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { exhaustMap, map, catchError, concatMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { HotelRoomsService } from 'app/hotels/services/rooms/hotel-rooms.service';
import { HotelRoomsActions } from '../actions';
import * as fromProfile from 'app/profile/store/reducers';
import * as fromHotels from 'app/hotels/store/reducers';
import { StealthCreditsHelper } from 'app/hotels/helpers/stealth-credits.helper';
import { RoomHelper } from 'app/hotels/helpers/room.helper';

@Injectable()
export class HotelRoomsEffects {
  private userCredits$ = this.store.pipe(select(fromProfile.getUserCredits));
  private stealthCredits$ = this.store.pipe(select(fromHotels.getStealthCredits));

  getRooms$ = createEffect(()=>
    this.actions$.pipe(
      ofType<HotelRoomsActions.GetRooms>(HotelRoomsActions.HotelRoomsActionTypes.GetRooms),
      map(action => action.payload),
      exhaustMap(payload => this.hotelRoomsService.getRooms(payload).pipe(
        map(res => new HotelRoomsActions.SetRoomsCredits(this.applyIsPayLater(res))),
        catchError(error => of(new HotelRoomsActions.GetRoomsFailure(error)))
      ))
    )
  );


  applyCredits$ = createEffect(()=>
    this.actions$.pipe(
      ofType<HotelRoomsActions.SetRoomsCredits>(HotelRoomsActions.HotelRoomsActionTypes.SetRoomsCredits),
      map(action => action.payload),
      concatMap(action => of(action).pipe(withLatestFrom(this.userCredits$, this.stealthCredits$))),
      map(([res, userCredits, stealthCredits]) => this.applyCredits(res, userCredits, stealthCredits)),
      map(res => new HotelRoomsActions.GetRoomsSuccess(res))
    )
  );

  getRoom$ = createEffect(()=>
    this.actions$.pipe(
      ofType<HotelRoomsActions.GetRoom>(HotelRoomsActions.HotelRoomsActionTypes.GetRoom),
      map(action => action.payload),
      concatMap(payload =>
        this.hotelRoomsService.getRoom(payload).pipe(
          map(res => new HotelRoomsActions.GetRoomSuccess(res)),
          catchError(error => of(new HotelRoomsActions.GetRoomFailure(error)))
        )
      )
    )
  );

  private applyIsPayLater(response) {
    const { rooms } = response;
    if (Array.isArray(rooms)) {
      rooms.map((item: any) => { item.isPayLater = this.roomHelper.isPayLater(item); });
    }
    return response;
  }

  private applyCredits(response, userCredits, stealthCredits) {
    const responseAux = { ...response };
    const { rooms } = responseAux;
    if (Array.isArray(rooms) && this.stealthCreditsHelper.canCreditsBeApplied(userCredits, stealthCredits)) {
      const roomsAux = rooms.map((item: any) => ({
        ...item,
        original_display_rate: 0
      }));
      this.stealthCreditsHelper.applyCredits(roomsAux, userCredits);
      responseAux.rooms = roomsAux;
    }
    return responseAux;
  }

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private hotelRoomsService: HotelRoomsService,
    private roomHelper: RoomHelper,
    private stealthCreditsHelper: StealthCreditsHelper,
  ) { }
}
