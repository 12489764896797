import { Injectable } from '@angular/core';
import { ThirdPartiesStrategyInterface } from 'app/auth/strategies/third-parties.strategy.interface';
import { Socials } from 'app/profile/models/third-party';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { AppleSignInService } from '../services/apple-sign-in.service';

@Injectable({
  providedIn: 'root'
})
export class ThirdPartiesFactory implements ThirdPartiesStrategyInterface {
  constructor(
    private socialAuthService: SocialAuthService,
    private appleSignInService: AppleSignInService
  ) {}

  public getProvider(platform: string): any {
    switch (platform) {
      case Socials.FACEBOOK:
        return FacebookLoginProvider.PROVIDER_ID;
      case Socials.GOOGLE:
        return GoogleLoginProvider.PROVIDER_ID;
      case Socials.APPLE:
        return AppleSignInService.PROVIDER_ID;
      default:
        return null;
    }
  }

  public getToken(user, platform): any {
    switch (platform) {
      case Socials.FACEBOOK:
        return user.authToken;
      case Socials.GOOGLE:
        return user.idToken;
      case Socials.APPLE:
        return { jwt: user.authorization.id_token };
      default:
        return null;
    }
  }

  public getService(platform: string) {
    switch (platform) {
      case Socials.APPLE:
        return this.appleSignInService;
      case Socials.GOOGLE:
        return this.socialAuthService;
      case Socials.FACEBOOK:
        return this.socialAuthService;
      default:
        return null;
    }
  }
}
