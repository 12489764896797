import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class EligibleTravelersService {

  constructor(private http: HttpClient) { }

  getTravelers(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/profile/travelers.json`, {});
  }

  getTraveler(id): Observable<any> {
    return this.http.get(`${environment.apiUrl}/profile/travelers/${id}/edit.json`, {});
  }

  updateTraveler(id, params): Observable<any> {
    return this.http.put(`${environment.apiUrl}/profile/travelers/${id}.json`, params);
  }

  updateTravelerGroup(params): Observable<any> {
    return this.http.post(`${environment.apiUrl}/flights/ticketing/eligible-traveler/edit`, params);
  }
  newTraveler(params): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/profile/travelers.json`,
      params
    );
  }

  submitTravelerList(params): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/profile/travelers/submit.json`,
      params
    );
  }

  deleteTraveler(id: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/profile/travelers/${id}.json`, {});
  }

  removeTravelerAccess(eligibleId, employeeId) {
    return this.http.patch(`${environment.apiUrl}/api/v1/rms_travelers/remove_access/${eligibleId}/${employeeId}`, {});
  }

  sendInviteCode(params) {
    return this.http.post(`${environment.apiUrl}/api/v1/rms_travelers`, params);
  }

  validateTravelerCode(travelerCode) {
    return this.http.get<any>(`${environment.apiUrl}/api/v1/rms_travelers/verify_code/${travelerCode}`, {});
  }

  addMemberToTraveler(travelerCode, password, employeeNumber): Observable<any> {
    return this.http.patch(`${environment.apiUrl}/api/v1/rms_travelers/add_member/${travelerCode}`, { password, employeeNumber });
  }

  getInvitedTravelerInfo(eligibleId): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/v1/rms_travelers/member_information`, { employeeNumber: eligibleId });
  }

  giveAccess(params) {
    return this.http.post(`${environment.apiUrl}/api/v1/rms_travelers/link_eligibles`, params);
  }
}
