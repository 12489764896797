export enum ShareItinerariesTypes {
  EMAIL = 'EMAIL',
  GMAIL = 'GMAIL',
  PHONE = 'PHONE',
  EXTERNAL = 'EXTERNAL'
}

export enum ShareItinerariesSources {
  HOTELS = 'HOTELS',
  FLIGHTS = 'FLIGHTS',
  CARS = 'CARS',
  INSURANCES = 'INSURANCES',
  CRUISES = 'CRUISES',
  SHARED_ITINERARY = 'SHARED_ITINERARY'
}

export interface SharedItinerary {
  id: number;
  referrerId: number;
  itineraryId: string;
  refereeId?: number;
  type: ShareItinerariesTypes;
  description?: string;
  createdAt?: string;
  deletedAt?: string;
  source?: ShareItinerariesSources;
  alias?: string;
  owner?: boolean;
}
