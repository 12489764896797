import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared/shared.module";
import { TripsRoutingModule } from "./trips-routing.module";
import { EffectsModule } from "@ngrx/effects";
import { TripsEffects } from "./store/effects/trips.effects";
import { CarsTripsCardComponent } from "./components/cars-card.component";
import { TripsFlightsCardComponent } from "./components/trips-flights-card.component";
import { HotelsTripsCardComponent } from "./components/hotels-card.component";
import { InsurancesTripsCardComponent } from "./components/insurances-card.component";
import { TripsContainerComponent } from "./components/trips-container.component";
import { TripsPage } from "./containers/trips/trips.page";
import { StoreModule } from "@ngrx/store";
import { reducers } from "./store/reducers";
import { PassesModalComponent } from "./components/passes-modal/passes-modal.component";
import { UcwordsPipe } from "app/shared/pipes/ucwords/ucwords.pipe";

@NgModule({
  imports: [
    TripsRoutingModule,
    SharedModule,
    StoreModule.forFeature('trips', reducers),
    EffectsModule.forFeature([
      TripsEffects,
    ]),
  ],
  declarations: [
    CarsTripsCardComponent,
    HotelsTripsCardComponent,
    InsurancesTripsCardComponent,
    PassesModalComponent,
    TripsContainerComponent,
    TripsFlightsCardComponent,
    TripsPage,
  ],
  providers: [
    UcwordsPipe,
  ],
})
export class TripsModule {}
