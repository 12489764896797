import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';

@Injectable({
  providedIn: 'root'
})
export class ProfileHelper {
  dateFormat = 'YYYY-MM-DD';
  readonly genders = { MALE: 'M', FEMALE: 'F' };

  checkoutDataToBeUpdatedOnProfile(userDataStored, checkoutData) {
    const newData: { [k: string]: string } = {};

    newData.firstName = userDataStored.firstName ? userDataStored.firstName : '';
    newData.middleName = userDataStored.middleName ? userDataStored.middleName : '';
    newData.lastName = userDataStored.lastName ? userDataStored.lastName : '';

    if (checkoutData.firstName && userDataStored.firstName !== checkoutData.firstName) {
      newData.firstName = checkoutData.firstName;
    }
    if (checkoutData.middleName && userDataStored.middleName !== checkoutData.middleName) {
      newData.middleName = checkoutData.middleName;
    }
    if (checkoutData.lastName && userDataStored.lastName !== checkoutData.lastName) {
      newData.lastName = checkoutData.lastName;
    }
    if (checkoutData.email && userDataStored.personalEmail !== checkoutData.email) {
      newData.personalEmail = checkoutData.email;
    }
    if (checkoutData.birthDate && userDataStored.birthDate !== checkoutData.birthDate) {
      newData.birthDate = checkoutData.birthDate;
    }
    if (checkoutData.employeeHireDate && userDataStored.hireDate !== checkoutData.employeeHireDate) {
      newData.hireDate = checkoutData.employeeHireDate;
    }
    if ((!userDataStored.homePhone || userDataStored.homePhone === '||') && checkoutData.phone) {
      newData.homePhone = checkoutData.phone;
    }
    if (!userDataStored.country && checkoutData.country) {
      newData.country = checkoutData.country;
    }
    if (!userDataStored.state && checkoutData.state) {
      newData.state = checkoutData.state;
    }
    if (!userDataStored.city && checkoutData.city) {
      newData.city = checkoutData.city;
    }
    return (Object.keys(newData).length) ? newData : null;
  }

  formatStartDate(startDate: string) {
    const date = startDate.split('-');
    return {
      year: parseInt(date[0], 10),
      month: parseInt(date[1], 10),
      day: parseInt(date[2], 10)
    };
  }

  formatEmail(email: string, hide = false) {
    if (!email) {
      return null;
    }

    if (!hide) {
      return email;
    }

    const splitEmail = email.split('@');

    if (splitEmail.length < 2) {
      return email;
    }

    const domain = splitEmail[1];
    const name = splitEmail[0];
    return name.substring(0, 3).concat('*******@').concat(domain);
  }

  formatPhone(phoneCountry: string, phoneNumber: string): string {
    if (!phoneCountry && !phoneNumber) {
      return '||';
    }

    const strPhoneNumber = String(phoneNumber).replace(/[)(\s-]/g, '');
    return `${phoneCountry}|${strPhoneNumber.substring(0, 3)}|${strPhoneNumber.substring(3)}`;
  }

  formatPhoneService(country: CountryCode, completeNumber: string) {
    const phone = { country: '', area: '', number: '' };
    const newPhone = parsePhoneNumber(completeNumber, country);
    const phoneParts = newPhone.formatInternational().split(' ');
    phone.country = String(newPhone.countryCallingCode ? newPhone.countryCallingCode : '1');

    if (completeNumber) {
      phone.area = phoneParts[1] ? phoneParts[1] : '';
      phone.number = newPhone ? newPhone.nationalNumber.substring(phoneParts[1].length) : '';
    }

    return phone;
  }

  formatDate(date) {
    const formattedDate = [];
    if (typeof date === 'string') {
      return date;
    }

    formattedDate[0] = date['year'];
    formattedDate[1] = date['month'].toString().padStart(2, '0');
    formattedDate[2] = date['day'].toString().padStart(2, '0');

    return moment(`${formattedDate[0]}-${formattedDate[1]}-${formattedDate[2]}`).format(this.dateFormat);
  }

  getMemberFromForm(memberForm) {
    const params: {[k: string]: any} = {};
    params.firstName = memberForm.get('employee').get('firstName').value;
    params.lastName = memberForm.get('employee').get('lastName').value;
    params.gender = memberForm.get('employee').get('gender').value;
    params.birth = memberForm.get('employee').get('birthDate').value;
    params.homePhone = memberForm.get('employee').get('homePhone').value.number;
    params.address = memberForm.get('address').get('address').value;
    return params;
  }

  getParamsToUpdate(employee, thirdPartyAccount) {
    const params: {[k: string]: any} = {};
    if (!employee.account.firstName && thirdPartyAccount.first_name) {
      params.firstName =  thirdPartyAccount.first_name;
    }
    if (!employee.account.lastName && thirdPartyAccount.last_name) {
      params.lastName =  thirdPartyAccount.last_name;
    }
    if (!employee.account.gender && thirdPartyAccount.gender) {
      params.gender =  this.genders[thirdPartyAccount.gender.toUpperCase()];
    }
    if (!employee.account.birthDate && thirdPartyAccount.birthday) {
      params.birth =  thirdPartyAccount.birthday;
    }
    if (!employee.member.profile_image_url && thirdPartyAccount.picture) {
      params.image =  thirdPartyAccount.picture;
    }
    return params;
  }
  
}
