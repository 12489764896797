import { CreditActions } from '../actions';
import { UserCredits } from 'app/profile/models/user-credits.interface';

export const initialState: UserCredits = {
  creditUSD: 0,
  currency: '',
  displayAmount: 0,
  expiration: ''
};

export function reducer(
  state = initialState,
  action:
    | CreditActions.CreditActionsUnion
) {
  switch (action.type) {
    case CreditActions.CreditsActionTypes.LoadCredits:
      return {
        ...state
      };
    case CreditActions.CreditsActionTypes.LoadCreditsSuccess:
      return {
        ...state,
        creditUSD: action.payload.credit,
        currency: action.payload.display_unit,
        displayAmount: action.payload.display_amount,
        expiration: action.payload.expiration ? action.payload.expiration : ''
      };
    case CreditActions.CreditsActionTypes.LoadCreditsFailure:
      return {
        ...state
      };
    case CreditActions.CreditsActionTypes.UpdateCredits:
      return {
        ...state,
        creditUSD: action.payload.credit,
        currency: action.payload.display_unit,
        displayAmount: action.payload.display_amount,
        expiration: action.payload.expiration,
      };
    default: {
      return state;
    }
  }
}

export const getCredits = state => state;
