import { AfterViewInit, Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { environment } from '@env/environment';
@Component({
  selector: 'car-search-external',
  templateUrl: './car-search-external.html'
})
export class CarSearchExternalComponent implements AfterViewInit, OnDestroy {
  MEILI_PTID = environment.carsProvider.meili.search.ptid;
  scriptElement: HTMLScriptElement;
  @Input() user;

  constructor(
    private renderer: Renderer2
  ) { }

  ngAfterViewInit(): void {
    if (!this.scriptElement) {
      this.scriptElement = this.renderer.createElement('script');
      this.scriptElement.src = environment.carsProvider.meili.search.url;
      this.renderer.appendChild(document.body, this.scriptElement);
    }
  }

  ngOnDestroy() {
    if (this.scriptElement) {
      this.renderer.removeChild(document.head, this.scriptElement);
      this.scriptElement = null;
    }
  }
}
