<ngb-alert *ngIf="success" type="success" (closed)="closeAlert()">{{ success | translate }}</ngb-alert>
<ngb-alert *ngIf="error" type="danger" (closed)="closeAlert()">{{ error }}</ngb-alert>
<div class="d-sm-none back-button">
  <button type="button" class="btn btn-link" (click)="backToTabs()">
    <fa-icon [icon]="icons.faChevronLeft" aria-hidden="true"></fa-icon>
    {{ "back" | translate }}
  </button>
</div>
<div class="profile-title primary-content">
  {{ "nav.contact.mailing_address" | translate }}
</div>
<div class="card shadow-none mb-4">
  <div class="card-body">
    <address-form
      #addressForm
      [address]="profileAddress"
      [disabledFields]="disabledFields">
    </address-form>
  </div>
</div>
<p class="text-end">
  <button type="button" class="btn btn-md btn-primary gtm-update-profile" (click)="onEditAddress()" data-cy="update" *ngIf="!loading">{{'profile.info.update' | translate}}</button>
  <button type="button" class="btn btn-md btn-primary"  *ngIf="loading" disabled><fa-icon [icon]="icons.faCircleNotch"></fa-icon> {{'invite.invite_processing' | translate}}</button>
</p>
