<div class="card shadow-none mb-2 col-lg-6 col-sm-12">
  <div class="card-body">
    <div class="row">
      <div class="col-4">
        <div class="trophy-container justify-content-center align-items-center">
          <fa-icon [icon]="icons.faTrophy" class="fa-2x"></fa-icon>
        </div>
      </div>
      <div class="col-8">
        <div class="d-flex justify-content-center align-content-center credits-display secondary-color">
          {{ userCredits.displayAmount | currencyConvert: memberLoyalty.user_currency }}
        </div>
        <div class="d-flex justify-content-center align-content-center mb-2 credits-available">
          {{ 'credits_available' | translate }}
        </div>
        <member-progress-bar
          [size]="'big'"
          [showAwayFromNextLevel]="false"
          [nextTierReward]="memberLoyalty.next_tier_reward"
          [currentTierLtv]="memberLoyalty.current_tier_ltv"
          [nextTierLtv]="memberLoyalty.next_tier_ltv"
          [nextTierGapLtv]="memberLoyalty.next_tier_gap_ltv"
          [currentLevel]="memberLoyalty.current_level"
          [nextLevel]="memberLoyalty.next_level"
          [nextTierCurrentLtv]="memberLoyalty.next_tier_current_ltv">
        </member-progress-bar>
      </div>
    </div>
    <div class="row">
      <div class="col-12 next-reward-message tertiary-content fst-italic">
        {{ 'member_rewards.away_from_next_level' | translate: {nextTierGapLtv: memberLoyalty.next_tier_gap_ltv | currency:'USD':'symbol-narrow':'1.0-2', nextTierReward: memberLoyalty.next_tier_reward | currency:'USD':'symbol-narrow':'1.0-2'  } }}
      </div>
    </div>
  </div>
</div>
