import { Action } from '@ngrx/store';
import { PaymentMethod } from 'app/profile/models/payment-method';

export enum PaymentMethodsActionTypes {
  Add ='[Payment Methods] Add Card',
  Reset ='[Payment Methods] Reset',
  GetAll = '[Payment Methods] Get All',
  GetAllFailure = '[Payment Methods] Get All Failure',
  GetAllSucess = '[Payment Methods] Get All Success',
  Delete ='[Payment Methods] Delete',
  DeleteFailure ='[Payment Methods] Delete Failure',
  DeleteSuccess = '[Payment Methods] Delete Success',
  Edit = '[Payment Metohds] Edit',
  EditSuccess = '[Payment Methods] Edit Success',
  EditFailure = '[Payment Methods] Edit Failure',
}

export class Add implements Action {
  readonly type = PaymentMethodsActionTypes.Add;

  constructor(public payload: PaymentMethod) {}
}

export class Reset implements Action {
  readonly type = PaymentMethodsActionTypes.Reset;

  constructor() {}
}

export class Delete implements Action {
  readonly type = PaymentMethodsActionTypes.Delete
  constructor(public payload: string ) {}
}

export class DeleteFailure implements Action {
  readonly type = PaymentMethodsActionTypes.DeleteFailure
  constructor (public payload: any) {}
}

export class DeleteSuccess implements Action {
  readonly type = PaymentMethodsActionTypes.DeleteSuccess
  constructor (public payload: any) {}
}


export class GetAll implements Action {
  readonly type = PaymentMethodsActionTypes.GetAll
  constructor(public payload: any) {}
}

export class GetAllFailure implements Action {
  readonly type = PaymentMethodsActionTypes.GetAllFailure
  constructor (public payload: any) {}
}


export class GetAllSucess implements Action {
  readonly type = PaymentMethodsActionTypes.GetAllSucess
  constructor (public payload: any) {}
}

export class Edit implements Action {
  readonly type = PaymentMethodsActionTypes.Edit
  constructor (public payload: any) {}
}

export class EditSuccess implements Action {
  readonly type = PaymentMethodsActionTypes.EditSuccess
  constructor (public payload: any) {}
}

export class EditFailure implements Action {
  readonly type = PaymentMethodsActionTypes.EditFailure
  constructor (public payload: any) {}
}
export type PaymentMethodsActionsUnion = 
   Add
 | Reset  
 | Delete
 | GetAll 
 | GetAllFailure 
 | GetAllSucess
 | DeleteFailure
 | DeleteSuccess
 | Edit
 | EditSuccess
 | EditFailure
