import { Action } from '@ngrx/store';

export enum AirlineActionTypes {
  LoadAirlines = '[Airlines] Load Airlines',
  LoadAirlinesSuccess = '[Airlines] Load Airlines Success',
  LoadAirlinesFailure = '[Airlines] Load Airlines Failure',
  LoadAirlinesPricingInCRS = '[Airlines] Load Airlines Pricing In CRS',
  LoadAirlinesPricingInCRSSuccess = '[Airlines] Load Airlines Pricing In CRS Success',
  LoadAirlinesPricingInCRSFailure = '[Airlines] Load Airlines Pricing In CRS Failure',
}

export class LoadAirlines implements Action {
  readonly type = AirlineActionTypes.LoadAirlines;
}

export class LoadAirlinesSuccess implements Action {
  readonly type = AirlineActionTypes.LoadAirlinesSuccess;

  constructor(public payload: { airlines: any }) {}
}

export class LoadAirlinesFailure implements Action {
  readonly type = AirlineActionTypes.LoadAirlinesFailure;

  constructor(public payload: { error: any }) {}
}

export class LoadAirlinesPricingInCRS implements Action {
  readonly type = AirlineActionTypes.LoadAirlinesPricingInCRS;
  constructor(public payload?: { employeeAirlineCode?: string }) {}
}

export class LoadAirlinesPricingInCRSSuccess implements Action {
  readonly type = AirlineActionTypes.LoadAirlinesPricingInCRSSuccess;

  constructor(public payload: { airlinesPricingInCRS: string[] }) {}
}

export class LoadAirlinesPricingInCRSFailure implements Action {
  readonly type = AirlineActionTypes.LoadAirlinesPricingInCRSFailure;

  constructor(public payload: { error: any }) {}
}

export type AirlineActionsUnion = LoadAirlines
  | LoadAirlinesSuccess
  | LoadAirlinesFailure
  | LoadAirlinesPricingInCRS
  | LoadAirlinesPricingInCRSSuccess
  | LoadAirlinesPricingInCRSFailure;
