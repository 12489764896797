import { Component, Input, Output, ViewChild, EventEmitter, OnInit } from '@angular/core';
import { AddPaymentMethodComponent } from 'app/profile/components/add-payment/add-payment-method.component';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { PaymentMethodsService } from 'app/profile/services/payment-methods/payment-methods.service';
import { PaymentMethod } from 'app/profile/models/payment-method';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromRoot from 'reducers';
import { PaymentMethodsActions } from 'app/profile/store/actions';
import { EditPaymentComponent } from '../edit-payment/edit-payment.component';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'payment-methods',
  templateUrl: './payment-methods.html',
  styleUrls: ['./payment-methods.scss']
})

export class PaymentMethodsComponent implements OnInit {
  @Input() paymentMethods: PaymentMethod[];
  @Input() user: any;
  @Input() userTools: any;
  @Input() requestMFA;
  @Input() userData: any;
  @Output() refreshPaymentMethods = new EventEmitter();
  @Output() showTabs = new EventEmitter();
  @ViewChild('addPaymentMethodComponent', { static: false }) addPaymentMethodModal: AddPaymentMethodComponent;
  success = false;
  primarySuccess = false;
  mfaModalOptions: NgbModalOptions = {
    backdrop : 'static',
    keyboard : false,
    size: 'lg'
  };
  icons = { faChevronLeft };

  constructor(
    public translate: TranslateService,
    private modalService: NgbModal,
    private paymentMethodsService: PaymentMethodsService,
    private store: Store<fromRoot.State>
  ) {}

  ngOnInit(){
    this.store.dispatch(new PaymentMethodsActions.Reset());
  }

  onAddOpen() {
    const modalRef = this.modalService.open(AddPaymentMethodComponent, { size: 'lg' });
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.userTools = this.userTools;
    modalRef.componentInstance.userData = this.userData;
    modalRef.componentInstance.requestMFA = this.requestMFA;
    modalRef.result.then(
      (result) => {
        this.success = result;
        setTimeout(() => this.success = false, 5000);
        this.refreshPaymentMethods.emit();
      },
      () => {
        this.success = false;
      }
    );
  }

  onEditOpen(event) {
    const modalRef = this.modalService.open(EditPaymentComponent, { size: 'lg' });
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.userTools = this.userTools;
    modalRef.componentInstance.userData = this.userData;
    modalRef.componentInstance.requestMFA = this.requestMFA;
    modalRef.componentInstance.card = event;
    modalRef.result.then(
      (result) => {
        this.success = result;
        setTimeout(() => this.success = false, 5000);
        this.refreshPaymentMethods.emit();
      },
      () => {
        this.success = false;
      }
    );
  }

  onPaymentMethodDeleted(id) {
    this.refreshPaymentMethods.emit();
  }

  onPaymentMethodPrimary(event) {
    this.paymentMethodsService.makePrimary({ token: event.token }).pipe(take(1)).subscribe(() => {
      this.primarySuccess = true;
      setTimeout(() => this.primarySuccess = false, 5000);
      this.refreshPaymentMethods.emit();
    });
  }

  closeAlert() {
    this.success = false;
    this.primarySuccess = false;
  }

  backToTabs() {
    this.showTabs.emit();
  }
}
