import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { AirportsRecentArrivalsSearchesActions, AirportsRecentArrivalsSearchesApiActions } from '../actions';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { RecentSearchesService } from '../../services/recent-searches/recent-searches.service';

@Injectable()
export class AirportsRecentArrivalsSearchesEffects {

  load$ = createEffect(()=>
    this.actions$.pipe(
      ofType<AirportsRecentArrivalsSearchesActions.Load>(AirportsRecentArrivalsSearchesActions.AirportsRecentArrivalsSearchesActionTypes.Load),
      switchMap(() =>
        this.recentSearchesService.airports('arrival').pipe(
          map(res => new AirportsRecentArrivalsSearchesApiActions.LoadSuccess({ destinations: res.data })),
          catchError(error => of(new AirportsRecentArrivalsSearchesApiActions.LoadFailure(error)))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private recentSearchesService: RecentSearchesService
  ) {}
}
