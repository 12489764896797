import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { HotelsRecentSearchesActions, HotelsRecentSearchesApiActions } from '../actions';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { RecentSearchesService } from 'app/shared/services/recent-searches/recent-searches.service';

@Injectable()
export class RecentSearchesEffects {

  load$ = createEffect(()=>
    this.actions$.pipe(
      ofType<HotelsRecentSearchesActions.Load>(HotelsRecentSearchesActions.HotelsRecentSearchesActionTypes.Load),
      switchMap(() =>
        this.recentSearchesService.hotels().pipe(
          map(res => new HotelsRecentSearchesApiActions.LoadSuccess({ destinations: res.data })),
          catchError(error => of(new HotelsRecentSearchesApiActions.LoadFailure(error)))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private recentSearchesService: RecentSearchesService
  ) {}
}
