import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UnlinkEmailActions } from '../../store/actions';
import * as fromShared from '../../store/reducers';

@Component({
  selector: 'unlink-email-modal',
  templateUrl: './unlink-email-modal.html'
})

export class UnlinkEmailModal implements OnInit, OnDestroy{

  @Input() email: string;

  success$ = this.store.pipe(select(fromShared.getUnlinkEmail));
  pending$ = this.store.pipe(select(fromShared.getUnlinkEmailPending));
  error$ = this.store.pipe(select(fromShared.getUnlinkEmailError));
  ngUnsuscribe$ = new Subject<void>();

  error = '';
  pending = false;

  constructor(
    private activeModal: NgbActiveModal,
    private store: Store<fromShared.State>,
    private router: Router
  ) {}

  ngOnInit() {
    this.store.dispatch(new UnlinkEmailActions.Clear());
    this.error$.pipe(takeUntil(this.ngUnsuscribe$)).subscribe((error) => {
      if (error) {
        this.activeModal.dismiss(error);
      }
    });
    this.success$.pipe(takeUntil(this.ngUnsuscribe$)).subscribe((success) => {
      if (success) {
        this.activeModal.close(true);
      }
    });
    this.pending$.pipe(takeUntil(this.ngUnsuscribe$)).subscribe((pending) => {
      this.pending = pending;
    });
  }

  unlinkEmail() {
    this.store.dispatch(new UnlinkEmailActions.UnlinkRequest({ member_email: this.email, redirect_to: this.getRedirectUrl() }));
  }

  getRedirectUrl() {
    return this.router.url;
  }

  dismiss() {
    this.activeModal.close();
  }

  ngOnDestroy() {
    this.ngUnsuscribe$.next();
  }

}
