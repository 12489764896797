<div class="modal-header">
  <div class="profile-title primary-content">{{ "mobile.flights.edit_eligible_traveler" | translate }}</div>
  <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()">

  </button>
</div>
<div class="modal-body eligible-traveler">
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs mt-2">
    <li *ngIf="!loading" [ngbNavItem]="1" (click)="validateForm()">
      <a ngbNavLink>
        {{ 'profile.travelers.traveler' | translate }}
        <fa-icon *ngIf="!isTravelerInformationValid && active !== 1" [icon]="icons.faExclamationCircle"></fa-icon>
      </a>
      <ng-template ngbNavContent>
        <div>
          <form [formGroup]="eligibleForm">
            <div formGroupName="eligible">
              <div class="row">
                <div class="col-12 col-md-4">
                  <div class="form-group form-floating">
                    <input type="text" formControlName="firstName" id="firstName"
                      class="form-control validate with-background" data-cy="firstName" />
                    <label for="firstName">{{ "profile.info.firstName" | translate }}</label>
                    <div class="error-message" *ngIf="firstName.errors !== null && firstName.touched"
                      [innerHtml]="formErrorsHelper.errorMessage(firstName, ('profile.info.firstName' | translate))">
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="form-group form-floating">
                    <input type="text" formControlName="middleName" id="middleName"
                      class="form-control validate with-background" />
                    <label for="middleName">{{ "profile.info.middleName" | translate }}</label>
                    <div class="error-message" *ngIf="middleName.errors !== null && middleName.touched"
                      [innerHtml]="formErrorsHelper.errorMessage(middleName, ('profile.info.middleName' | translate))">
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="form-group form-floating">
                    <input type="text" formControlName="lastName" id="lastName"
                      class="form-control validate with-background" data-cy="lastName" />
                    <label for="lastName">{{ "profile.info.lastName" | translate }}</label>
                    <div class="error-message" *ngIf="lastName.errors !== null && lastName.touched"
                      [innerHtml]="formErrorsHelper.errorMessage(lastName, ('profile.info.lastName' | translate))">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6" *ngIf="config.relationshipId?.show">
                  <div class="form-group form-floating">
                    <select class="form-control with-background" formControlName="relationshipId"
                      data-cy="relationshipId">
                      <option value="">{{ 'profile.info.select' | translate }}</option>
                      <option *ngFor="let relationship of relationships" [value]="relationship.id">{{
                        relationship.description }}</option>
                    </select>
                    <label>{{ "profile.travelers.relationship" | translate }}</label>
                    <div class="error-message" *ngIf="relationshipId.errors !== null && relationshipId.touched"
                      [innerHtml]="formErrorsHelper.errorMessage(relationshipId, ('profile.travelers.relationship' | translate))">
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6" *ngIf="config.student?.show">
                  <div class="form-group form-floating">
                    <select class="form-control with-background" formControlName="student" data-cy="student">
                      <option value="">{{ 'profile.info.select' | translate }}</option>
                      <option *ngFor="let studentOp of (studentOptions$ | async)" [value]="studentOp.key">{{
                        studentOp.value }}</option>
                    </select>
                    <label>{{ "profile.info.student" | translate }}</label>
                    <div class="error-message" *ngIf="student.errors !== null && student.touched"
                      [innerHtml]="formErrorsHelper.errorMessage(student, ('profile.info.student' | translate))">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <div class="position-relative ngbDatepicker form-floating">
                      <input *ngIf="!config.birthDate?.enabled" id="birthDate" class="form-control"
                        formControlName="birthDate" [readonly]="true" />
                      <ng-container *ngIf="config.birthDate?.enabled">
                        <input id="birthDate" class="form-control with-background" name="birthDate" [minDate]="minDate"
                          [startDate]="formatStartDate()" [value]="startDate" [maxDate]="previuosDayFormatted"
                          ngbDatepicker (dateSelect)="onDatesSelected($event)" [readonly]="true" #d3="ngbDatepicker"
                          (click)="d3.toggle()" data-cy="birthDate" />
                        <fa-icon *ngIf="config.birthDate?.enabled" (click)="d3.toggle()" [icon]="icons.faCalendar"
                          class="input-icon"></fa-icon>
                      </ng-container>
                      <label for="birthDate">{{ "profile.info.dob" | translate }}</label>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6" *ngIf="config.gender?.show">
                  <div class="form-group form-floating">
                    <select class="form-control with-background" formControlName="gender" data-cy="gender">
                      <option value="">{{ 'profile.info.select' | translate }}</option>
                      <option *ngFor="let gender of ('flights.requests.genders' | translate)" [value]="gender[1]">{{
                        gender[0] }}</option>
                    </select>
                    <label>{{ "flights.booking.ticketing.gender.title" | translate }}</label>
                    <div class="error-message" *ngIf="gender.errors !== null && gender.touched"
                      [innerHtml]="formErrorsHelper.errorMessage(gender, ('flights.booking.ticketing.gender.title' | translate))">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6" *ngIf="config.knownTravelerNumber?.show">
                  <div class="form-group form-floating">
                    <input type="text" formControlName="knownTravelerNumber" id="knownTravelerNumber"
                      class="form-control validate with-background" />
                    <label for="knownTravelerNumber">{{ "profile.info.knownTravelerNumber" | translate }}</label>
                    <div class="error-message"
                      *ngIf="knownTravelerNumber.errors !== null && knownTravelerNumber.touched"
                      [innerHtml]="formErrorsHelper.errorMessage(knownTravelerNumber, ('profile.info.knownTravelerNumber' | translate))">
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6" *ngIf="config.fqtvNumber?.show">
                  <div class="form-group form-floating">
                    <input type="text" formControlName="fqtvNumber" id="fqtvNumber"
                      class="form-control validate with-background" />
                    <label for="fqtvNumber">{{ "profile.info.fqtvNumber" | translate }}</label>
                    <div class="error-message" *ngIf="fqtvNumber.errors !== null && fqtvNumber.touched"
                      [innerHtml]="formErrorsHelper.errorMessage(fqtvNumber, ('profile.info.fqtvNumber' | translate))">
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6" *ngIf="showBasicGroup">
                  <div class="form-group">
                    <div class="col-12 col-md">
                      <label class="group-label grupo-basico">{{ "profile.info.basic_group" | translate }}</label>
                      <toggle [size]="'large'" [disabled]="!enableModify" formControlName="isBasicGroup"
                        class="toggle-selector" label=""></toggle>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </ng-template>
    </li>
    <li *ngIf="!loading" [ngbNavItem]="2" (click)="validateForm()">
      <a ngbNavLink>
        {{ 'profile.travelers.passport' | translate }}
        <fa-icon *ngIf="!isTravelerPassportValid && active !== 2" [icon]="icons.faExclamationCircle"></fa-icon>
      </a>
      <ng-template ngbNavContent>
        <div>
          <form [formGroup]="eligibleForm">
            <div formGroupName="passport">
              <div class="row">
                <div class="col-12 col-md-4">
                  <div class="form-group form-floating">
                    <input type="text" formControlName="passportFirstName" id="passportFirstName"
                      class="form-control validate with-background" />
                    <label for="passportFirstName">{{ "profile.info.passportFirstName" | translate }}</label>
                    <div class="error-message" *ngIf="passportFirstName.errors !== null && passportFirstName.touched"
                      [innerHtml]="formErrorsHelper.errorMessage(passportFirstName, ('profile.info.passportFirstName' | translate))">
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="form-group form-floating">
                    <input type="text" formControlName="passportMiddleName" id="passportMiddleName"
                      class="form-control validate with-background" />
                    <label for="passportMiddleName">{{ "profile.info.passportMiddleName" | translate }}</label>
                    <div class="error-message" *ngIf="passportMiddleName.errors !== null && passportMiddleName.touched"
                      [innerHtml]="formErrorsHelper.errorMessage(passportMiddleName, ('profile.info.passportMiddleName' | translate))">
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="form-group form-floating">
                    <input type="text" formControlName="passportLastName" id="passportLastName"
                      class="form-control validate with-background" />
                    <label for="passportLastName">{{ "profile.info.passportLastName" | translate }}</label>
                    <div class="error-message" *ngIf="passportLastName.errors !== null && passportLastName.touched"
                      [innerHtml]="formErrorsHelper.errorMessage(passportLastName, ('profile.info.passportLastName' | translate))">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group form-floating">
                    <select class="form-control with-background" id="passportNationality"
                      formControlName="passportNationality">
                      <option value="">{{ 'profile.info.select' | translate }}</option>
                      <option *ngFor="let country of countries" [value]="country.code">{{ country.name }}</option>
                    </select>
                    <label>{{ "profile.info.passportNationality" | translate }}</label>
                    <div class="error-message"
                      *ngIf="passportNationality.errors !== null && passportNationality.touched"
                      [innerHtml]="formErrorsHelper.errorMessage(passportNationality, ('profile.info.passportNationality' | translate))">
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group form-floating">
                    <input type="text" formControlName="passportNumber" id="passportNumber"
                      class="form-control validate with-background" />
                    <label for="passportNumber">{{ "profile.info.passportNumber" | translate }}</label>
                    <div class="error-message" *ngIf="passportNumber.errors !== null && passportNumber.touched"
                      [innerHtml]="formErrorsHelper.errorMessage(passportNumber, ('profile.info.passportNumber' | translate))">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6" *ngIf="config.passportNumberDomestic?.show">
                  <div class="form-group form-floating">
                    <input type="text" formControlName="passportNumberDomestic" id="passportNumberDomestic"
                      class="form-control validate with-background" />
                    <label for="passportNumberDomestic">{{ "profile.info.passportNumberDomestic" | translate }}</label>
                    <div class="error-message"
                      *ngIf="passportNumberDomestic.errors !== null && passportNumberDomestic.touched"
                      [innerHtml]="formErrorsHelper.errorMessage(passportNumberDomestic, ('profile.info.passportNumberDomestic' | translate))">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6" *ngIf="config.passportIssuanceDate?.show">
                  <div class="form-group">
                    <div class="position-relative ngbDatepicker form-floating">
                      <input *ngIf="!config.passportIssuanceDate?.enabled" id="passportIssuanceDate"
                        class="form-control" formControlName="passportIssuanceDate" [readonly]="true"
                        [value]="startPassportIssuanceDate" />
                      <ng-container *ngIf="config.passportIssuanceDate?.enabled">
                        <input class="form-control with-background" name="passportIssuanceDate" [minDate]="minDate"
                          [startDate]="formatStartIssuanceDate()" [value]="startPassportIssuanceDate"
                          [maxDate]="todayFormatted" ngbDatepicker (dateSelect)="onInsurranceDateSelected($event)"
                          #d="ngbDatepicker" (click)="d.toggle()" [readonly]="true" />
                        <fa-icon [icon]="icons.faCalendar" (click)="d.toggle()" class="input-icon"></fa-icon>
                      </ng-container>
                      <label>{{ "profile.info.passportIssuanceDate" | translate }}(yyyy-mm-dd)</label>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6" *ngIf="config.passportExpirationDate?.show">
                  <div class="form-group">
                    <div class="position-relative ngbDatepicker form-floating">
                      <input *ngIf="!config.passportExpirationDate?.enabled" id="passportExpirationDate"
                        class="form-control" formControlName="passportExpirationDate" [readonly]="true"
                        [value]="startPassportExpirationDate" />
                      <ng-container *ngIf="config.passportExpirationDate?.enabled">
                        <input class="form-control with-background" name="passportExpirationDate"
                          [minDate]="todayFormatted" [startDate]="formatStartExpirationDate()"
                          [value]="startPassportExpirationDate" [maxDate]="maxDatePassport" ngbDatepicker
                          #d2="ngbDatepicker" (click)="d2.toggle()" (dateSelect)="onExpirationDateSelected($event)"
                          [readonly]="true" />
                        <fa-icon [icon]="icons.faCalendar" (click)="d2.toggle()" class="input-icon"></fa-icon>
                      </ng-container>
                      <label>{{ "profile.info.passportExpirationDate" | translate }}(yyyy-mm-dd)</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6" *ngIf="countries">
                  <div class="form-group form-floating">
                    <select class="form-control with-background" id="passportIssuanceCountry"
                      formControlName="passportIssuanceCountry">
                      <option value="">{{ 'profile.info.select' | translate }}</option>
                      <option *ngFor="let country of countries" [value]="country.code">{{ country.name }}</option>
                    </select>
                    <label>{{ "profile.info.passportIssuanceCountry" | translate }}</label>
                    <div class="error-message"
                      *ngIf="passportIssuanceCountry.errors !== null && passportIssuanceCountry.touched"
                      [innerHtml]="formErrorsHelper.errorMessage(passportIssuanceCountry, ('profile.info.passportIssuanceCountry' | translate))">
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6" *ngIf="countries">
                  <div class="form-group form-floating">
                    <select class="form-control with-background" id="passportCountryBirth"
                      formControlName="passportCountryBirth">
                      <option value="">{{ 'profile.info.select' | translate }}</option>
                      <option *ngFor="let country of countries" [value]="country.code">{{ country.name }}</option>
                    </select>
                    <label>{{ "profile.info.passportCountryBirth" | translate }}</label>
                    <div class="error-message"
                      *ngIf="passportCountryBirth.errors !== null && passportCountryBirth.touched"
                      [innerHtml]="formErrorsHelper.errorMessage(passportCountryBirth, ('profile.info.passportCountryBirth' | translate))">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </ng-template>
    </li>
    <li
      *ngIf="!loading && canInvite && flightAccessForm && eligibleTravelerWithStatus?.eligibleForTravelerProgram && eligibleTravelerWithStatus.travelerStatus === ELIGIBLE_TRAVELER_STATUS.ACTIVE"
      [ngbNavItem]="3" (click)="validateForm()">
      <a ngbNavLink> {{ 'profile.travelers.flight_access' | translate }} </a>
      <ng-template ngbNavContent>
        <eligible-traveler-flight-access [canInvite]="canInvite" [eligibleTraveler]="eligibleTravelerWithStatus"
          [eligibleTravelers]="filteredEligibleTravelers" [flightAccessForm]="flightAccessForm">
        </eligible-traveler-flight-access>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
  <loading-indicator [fullScreen]="false" *ngIf="loading"></loading-indicator>
</div>
<div class="modal-footer text-end">
  <div *ngIf="active === 1 || active === 2" class="m-0">
    <button type="button" *ngIf="!loading && travelerId && enableModify" class="btn btn-md btn-danger m-1"
      (click)="deleteTraveler()">
      {{ "profile.travelers.delete" | translate }}
    </button>
    <button type="button" *ngIf="!loading && travelerId" class="btn btn-md btn-primary m-1"
      (click)="onEditEligibleTravelerClick()">
      {{ "profile.travelers.update_traveler" | translate }}
    </button>
    <button type="button" *ngIf="!loading && !travelerId" class="btn btn-md btn-primary m-1"
      (click)="onCreateEligibleTravelerClick()" data-cy="confirmTraveler">
      {{ "profile.travelers.add_traveler" | translate }}
    </button>
  </div>
  <div *ngIf="active === 3 && !loading && eligibleForm && canInvite" class="m-0">
    <eligible-traveler-flight-access-button [areOtherEligibleTravelers]="filteredEligibleTravelers.length > 0"
      [eligibleTraveler]="entity" [eligibleTravelerWithStatus]="eligibleTravelerWithStatus"
      [flightAccessForm]="flightAccessForm" [travelerId]="travelerId"
      (closeModal)="closeModal($event)"></eligible-traveler-flight-access-button>
  </div>
</div>
