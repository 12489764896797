import { Component, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromProfile from 'app/profile/store/reducers';
import { MeiliHelper } from 'app/shared/helpers/meili.helper';
import * as fromShared from 'app/shared/store/reducers';
import * as fromRoot from 'reducers';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CarSearchExternalComponent } from './external/car-search-external.component';
import { CarSearchInternalComponent } from './internal/car-search-internal.component';

@Component({
  selector: 'car-search',
  template: '<ng-container #container></ng-container>'
})
export class CarSearchComponent implements OnInit {
  @ViewChild('container', { read: ViewContainerRef, static: true }) container: ViewContainerRef;

  user$ = this.store.select(fromProfile.getUserProfile);
  configFlags$ = this.store.pipe(select(fromShared.getConfigFlag));
  private ngUnsubscribe = new Subject<void>();
  user;
  constructor(private store: Store<fromRoot.State>,
              private meiliHelper: MeiliHelper) {}

  ngOnInit() {
    combineLatest([this.user$, this.configFlags$]).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([user, configFlags]) => {
        this.user = user;
        if (user?.airlineCode && configFlags) {
          const externalContentProvider = this.meiliHelper.isEnabled(configFlags, user.airlineCode);
          this.loadComponent(externalContentProvider);
          this.ngUnsubscribe.next();
          this.ngUnsubscribe.complete();
        }
      });
  }

  private loadComponent(externalContentProvider: boolean) {
    this.container.clear();
    const component: Type<any> = externalContentProvider ? CarSearchExternalComponent : CarSearchInternalComponent;
    const componentRef = this.container.createComponent(component);
    if (externalContentProvider) {
      componentRef.setInput('user',this.user)
    }
  }
}
