import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HotelWatchlist } from 'app/profile/models/hotel-watchlist';

@Injectable({
  providedIn: 'root'
})
export class WatchlistService {
  constructor (
    private http: HttpClient
  ) {}

  getFlightWatchers():Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/flights/watchers.json`, {});
  }

  createFlightWatchlist(params, toolKey): Observable<any> {
    return this.http.post(`${environment.apiUrl}/flights/${toolKey}/check_load_watcher.json`, { watchlist: params });
  }

  updateFlightLoadWatcher(id):Observable<any> {
    return this.http.get(`${environment.apiUrl}/watchlist/watchers/${id}/refresh.json`, {});
  }

  deleteFlightWatcher(id):Observable<any> {
    return this.http.delete(`${environment.apiUrl}/api/v1/flights/watchers/${id}.json`, {});
  }

  getHotelWatchers(params = null) {
    return this.http.get(`${environment.apiUrl}/api/v1/hotels/watchers.json`, { params });
  }

  createHotelWatchlist(hotel: HotelWatchlist): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/v1/hotels/watchers.json`, hotel);
  }

  removeHotelWatchlist(watchlist: any): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/api/v1/hotels/watchers/${watchlist.watchlistId}.json`);
  }

  getUserWatchlistPreferences(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/watchlist_preferences.json`, {});
  }
}
