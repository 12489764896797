import { environment } from '@env/environment';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecentViewsService {
  constructor (
    private http: HttpClient
  ) {}

  hotels(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/recent_hotel_views.json`, { params: { enable: '' } });
  }
}
