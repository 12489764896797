import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';
import { Traveler } from 'app/shared/models/traveler';
import { CustomValidators } from 'app/shared/validators/custom-validators';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'traveler',
  templateUrl: 'traveler.html',
  styleUrls: ['traveler.scss']
})

export class TravelerComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() traveler: Traveler;
  @Input() index: number;
  @Input() travelers: Traveler[];
  @Output() removeTraveler = new EventEmitter();
  @Output() valid = new EventEmitter();
  travelerForm: UntypedFormGroup;
  minDate;
  maxDate;
  startDate;
  icons = { faTimesCircle };
  private ngUnsubscribe = new Subject<void>();
  constructor(
    private formBuilder: UntypedFormBuilder,
    public formErrorsHelper: FormErrorsHelper
  ) { }

  ngOnInit() {
    this.minDate = moment('19000101');
    this.maxDate = moment();
    this.startDate = this.traveler.dateOfBirth || '';
    this.travelerForm = this.formBuilder.group({
      firstName: [this.traveler.firstName, Validators.required],
      lastName: [this.traveler.lastName, Validators.required],
      dateOfBirth: [this.traveler.dateOfBirth, Validators.required],
      email: [this.traveler.email, Validators.compose([Validators.required, Validators.email])]
    });
  }
  ngAfterViewInit() {
    this.travelerForm.get('firstName').setValidators([CustomValidators.validateNonDuplicatedTravelers(this.travelers)]);
    this.travelerForm.get('lastName').setValidators([CustomValidators.validateNonDuplicatedTravelers(this.travelers)]);
    this.travelerForm.get('dateOfBirth').setValidators([CustomValidators.validateNonDuplicatedTravelers(this.travelers)]);
    this.travelerForm.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(() => {
      this.valid.emit({ valid: this.isValid(), index: this.index });
    });
  }

  get firstName() { return this.travelerForm.get('firstName'); }
  get lastName() { return this.travelerForm.get('lastName'); }
  get email() { return this.travelerForm.get('email'); }
  get dateOfBirth() { return this.travelerForm.get('dateOfBirth'); }

  onDatesSelected(e) {
    if (e.startDate !== this.travelerForm.get('dateOfBirth').value) {
      this.travelerForm.get('dateOfBirth').setValue(e.startDate);
      this.traveler.dateOfBirth = e.startDate;
    }
  }

  onRemoveTraveler(index) {
    this.removeTraveler.emit(index);
  }

  isValid() {
    return this.travelerForm.valid;
  }

  getForm(): UntypedFormGroup {
    return this.travelerForm;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
