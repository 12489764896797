<div class="modal-header">
  <h4 class="modal-title text-center" id="modal-basic-title">{{ 'action_terms.login' | translate }}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()"></button>
</div>
<div class="modal-body">
  <login-form
    (submitted)="login($event)"
    (closeAlert)="closeAlert()"
    [pending]="pending$ | async"
    [message]="message$ | async"
    [airlineDefault]="airline"
    [employeeNumberDefault]="employeeNumber"
    [emailDefault]="email"
    [inModal]="true"
    [airlines]="airlines$ | async">
  </login-form>
</div>
