import { Component, Input, OnChanges, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MenuService } from 'app/shared/services/menu/menu.service';
import * as fromAuth from 'app/auth/store/reducers';
import * as fromRoot from 'reducers';
import * as fromProfile from 'app/profile/store/reducers';
import { select, Store } from '@ngrx/store';
import { ProfileUserActions } from 'app/profile/store/actions';
import { AuthApiActions } from 'app/auth/store/actions';
import { User } from 'app/auth/models/user';
import { combineLatest, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { SwUpdate } from '@angular/service-worker';
import * as fromShared from 'app/shared/store/reducers';
import { AirlineActions } from 'app/shared/store/actions';
import { RciService } from 'app/shared/services/rci/rci.service';
import { GeneralHelper } from '../../helpers/general.helper';
import { Language } from 'app/auth/models/language';
import { ConfigFlags } from 'app/shared/models/configFlags';
import { BreamerHelper } from 'app/shared/helpers/breamer.helper';
import { TranslateService } from '@ngx-translate/core';
import { featureFlags } from 'app/shared/models/featureFlags';
import { faPlane, faBuilding, faCar, faShip, faShuttleVan, faLifeRing, faCalendarWeek, faGlassMartini, faMedkit, faUniversity, faUser, faGift, faSuitcase, faEye, faDownload, faEnvelope, faSignIn, faUserPlus, faSignOutAlt, faInstitution } from '@fortawesome/free-solid-svg-icons';
import { SmartlookHelper } from 'app/shared/helpers/smartlook.helper';
import { RedirectHelper } from 'app/shared/helpers/redirect.helper';
import { AnalyticsService } from 'app/shared/services/analytics/analytics.service';
import { NavigationEnd, Router } from '@angular/router';
@Component({
  selector: 'header-component',
  templateUrl: 'header.html',
  styleUrls: ['header.scss'],
  encapsulation: ViewEncapsulation.None
})

export class HeaderComponent implements OnInit, OnChanges, OnDestroy {
  @Input() scrollable = false;
  @Input() showAnnouncement = true;

  public homeUrl: string;
  desktopLinks = [];
  mobileLinks = [];
  user: User;
  customerSupportAgent = '';
  options = {
    enableFlights: false,
    cruiseDiscountAirline: false,
    verifiedMember: false,
    inviteAllowed: true,
    airline_blog_uri: '',
    virtual_gift_card_enabled: false,
    fraud_user: false
  };
  isCollapsed = false;
  storeUser$ = this.store.pipe(select(fromAuth.getUser));
  airlines$ = this.store.pipe(select(fromShared.getAirlines));
  languages$ = this.store.pipe(select(fromShared.getLanguages));
  userLanguage$ = this.store.pipe(select(fromAuth.getUserLanguage));
  configFlags$ = this.store.pipe(select(fromShared.getConfigFlag));
  isFraudUser$ = this.store.pipe(select(fromAuth.getFraudStatus));
  availableTools$ = this.store.pipe(select(fromAuth.getAvailableTools));
  airlineFlags$ = this.store.pipe(select(fromShared.getAirlineFlags));
  profileConfig$ = this.store.pipe(select(fromProfile.getProfileConfig));
  profileUser$ = this.store.pipe(select(fromProfile.getUserProfile));
  profileUser: any = null;
  featureFlags = featureFlags;
  logged = false;
  navClass = 'fixed-top';
  headerClass = 'fixed';
  availableTools = [];
  airlines = [];
  blogUri = '';
  rciUri = '';
  openInAppActive: boolean;
  logo = 'angular_assets/images/color-logo.png';
  icons = { faPlane, faBuilding, faCar, faShip, faShuttleVan, faLifeRing, faCalendarWeek, faGlassMartini, faMedkit, faUniversity, faUser, faGift, faSuitcase, faEye, faDownload, faEnvelope, faSignIn, faUserPlus, faSignOutAlt, faInstitution };
  isSmartBannerVisible: boolean = false;

  public showAlertIE: boolean = false;

  private ngUnsubscribe = new Subject<void>();

  constructor(
    private menuService: MenuService,
    private store: Store<fromRoot.State>,
    private updates: SwUpdate,
    private rciService: RciService,
    private breamerhelper: BreamerHelper,
    private translateService: TranslateService,
    private smartlookHelper: SmartlookHelper,
    private redirectHelper: RedirectHelper,
    private analyticsService: AnalyticsService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.store.dispatch(new ProfileUserActions.LoadConfigProfile());
    combineLatest([this.airlineFlags$, this.storeUser$, this.airlines$, this.configFlags$, this.isFraudUser$, this.profileUser$]).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(([flags, user, airlines, configFlags, isFraudUser, profileUser]) => {
      this.profileUser = profileUser
      this.user = user;
      this.airlines = airlines;
      this.logged = !!this.user.member.id && !!flags?.airlineCode?.length;
      this.customerSupportAgent = this.user.call_center_admin;
      this.options.fraud_user = isFraudUser;
      if (!this.airlines.length) {
        this.store.dispatch(new AirlineActions.LoadAirlines());
      }
      this.options.virtual_gift_card_enabled = !!(flags?.items?.find((flag => flag.name === 'gift_cards' && flag.status)));
      this.handleConfigFlags(configFlags);
      this.setMenu();
      this.showAlertIE = this.logged && GeneralHelper.isInternetExplorer();
      this.homeUrl = this.redirectHelper.getHomeUrl(this.logged);
    });

    this.updates.versionUpdates.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((version) => {
      this.updates.activateUpdate().then(() => {
        console.log('Reloading page due to updated application');
        document.location.reload();
      });
    });

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.desktopLinks.map(item => item.isActive = this.menuService.isMenuItemActive(item.feature));
        this.mobileLinks.map(item => item.isActive = this.menuService.isMenuItemActive(item.feature));
      });
  }

  ngOnChanges() {
    this.navClass = this.scrollable ? 'scrollable-top' : 'fixed-top';
    this.headerClass = this.scrollable ? 'scrollable' : 'fixed';
    this.breamerhelper.addCssClass();
    this.logo = this.getLogo();
  }

  handleConfigFlags(flags: ConfigFlags): void {
    if (flags && flags.rci_weeks_enabled === '1') {
      this.rciService.getRciRedirectUrl().pipe(take(1)).subscribe((data) => {
        this.rciUri = data.redirect_to;
        this.setMenuRciUri();
      });
    }
  }

  setMenu() {
    if (this.logged) {
      this.availableTools$.pipe(take(1)).subscribe((tools) => {
        this.availableTools = tools;
        this.prepareMenu();
      });
    } else {
      this.prepareMenu();
    }
  }

  prepareMenu() {
    this.setOptions();
    this.loadDesktopLinks();
    this.loadMobileLinks();
    this.setMenuRciUri();
  }

  loadDesktopLinks() {
    this.desktopLinks = this.menuService.getMenu('desktop', this.options, this.user, this.profileUser);
  }

  loadMobileLinks() {
    this.mobileLinks = this.menuService.getMenu('mobile', this.options, this.user, this.profileUser);
  }

  setOptions() {
    this.options.enableFlights = this.flightsEnabled();
    this.options.cruiseDiscountAirline = this.user.member ? this.user.member.cruise_discount_airline : false;
    this.options.verifiedMember = this.user.member ? this.user.member.confirmed : false;
    this.options.inviteAllowed = this.user.allowed_to_invite && (this.user.account.iafDisabled !== 1);
    this.options.airline_blog_uri = this.blogUri ? this.blogUri : this.getBlogUri();
  }

  flightsEnabled() {
    return this.availableTools && (this.availableTools.length > 1 || this.availableTools[0] !== 'id90');
  }

  mobileLinkClicked() {
    this.isCollapsed = !this.isCollapsed;
  }

  changeUser() {
    this.store.dispatch(new AuthApiActions.UnselectUser());
    this.smartlookHelper.finishRecordingSession();
  }

  handleOpenInApp() {
    if (this.openInAppActive) {
      this.openInAppActive = false;
      return localStorage.setItem('openInAppActive', 'false');
    }
    this.openInAppActive = true;
    return localStorage.setItem('openInAppActive', 'true');
  }

  getBlogUri() {
    if (this.airlines && this.user) {
      const userAirline = this.airlines.find(
        airline => airline.code.toLowerCase() === this.user.account.airlineCode.toLowerCase()
      );

      this.blogUri = userAirline && userAirline.airline_blog_uri ? userAirline.airline_blog_uri : '';
    }

    return this.blogUri;
  }

  setMenuRciUri() {
    if (this.rciUri !== '') {
      this.setLinkRciUri(this.desktopLinks);
      this.setLinkRciUri(this.mobileLinks);
    }
  }

  setLinkRciUri(links: any[]) {
    const mobileMenuIndex = links.findIndex(menu => menu.id === 'rciMenu');
    if (mobileMenuIndex >= 0) {
      links[mobileMenuIndex].url = this.rciUri;
    }
  }

  onLanguageSelected(language: Language) {
    this.store.dispatch(new AuthApiActions.UpdateUserLanguage({ language: language.code }));
  }

  private getLogo() {
    return this.translateService.instant('logo').url ? this.translateService.instant('logo').url : 'angular_assets/images/color-logo.png';
  }

  getIcon(iconName) {
    return this.icons[iconName];
  }

  needTranslation(featureName: string): boolean {
    const toBeTranslated = [
      'activities',
      'cruises',
      'download_app'
    ];
    return toBeTranslated.includes(featureName);
  }

  setSmartBannerVisibility(event) {
    this.isSmartBannerVisible = event;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  async trackAndNavigate(event, menuItem) {
    if (!menuItem) { return; }
    event.preventDefault();

    const url: string = menuItem.urlParams
      ? this.translateService.instant(menuItem.url, menuItem.urlParams)
      : this.translateService.instant(menuItem.url);

    switch (menuItem.feature) {
      case 'activities':
        this.analyticsService.logEvent('viator_menu_item', { destination_url: url });
        break;
      default:
        break;
    }

    if (menuItem.external) {
      window.open(url, menuItem.target);
    } else {
      await this.router.navigate([url]);
    }
  }

  openBeamer(feature: string, url: string) {
    if (feature === 'beamer' && (url === 'web.nav.url.whats_new' || url === "#")) {
      return window['Beamer']?.show();
    }
    window.location.href = url;
  }
}
