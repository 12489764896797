<div
  class="truncated-text-component"
  [ngClass]="{'inner-margins-disabled': !innerMargins}"
>
  <p
    class="secondary-content fw-light mb-0"
    [innerHTML]="displayText"
    [attr.itemprop]="microdataItemprop ? microdataItemprop : null"
  ></p>
  <div
    *ngIf="showReadMore"
    class="d-flex"
    [ngClass]="buttonFloatingLeft ? 'justify-content-start' : 'justify-content-end me-3 fw-bold'"
  >
    <span
      class="primary-color clickable"
      (click)="toggleShowMore()"
      [innerHTML]="readText"
    ></span>
  </div>
</div>
