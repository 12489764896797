import { Component, Input } from '@angular/core';

@Component({
  selector: 'bar-chart',
  templateUrl: './bar-chart.component.html',
})
export class BarChartComponent {
  @Input() actual: number;

  public highTreshold = 10;
  public mediumTreshold = 0;
}
