import { Component, Input, ChangeDetectionStrategy, SimpleChanges, OnChanges, ContentChildren, QueryList } from '@angular/core';
import { GeneralHelper } from 'app/shared/helpers/general.helper';
import { ItemComponentDirective } from './item-component.directive';

@Component({
  selector: 'loading-indicator',
  templateUrl: './loading-indicator.html',
  styleUrls: ['./loading-indicator.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingIndicatorComponent implements OnChanges {
  @Input() fullScreen = true;
  @Input() message: string;
  @Input() mode: 'full-screen-height' | 'basic-height' | 'fill-parent' = 'full-screen-height';
  @Input() displayComponent: boolean = true;
  @Input() multiLineMessage = false;
  @Input() messageOptions = { fontColor: '#6078ee', fontWeight: '400', fontSize: '18px' };
  @Input() forceHeigth = false;

  @ContentChildren(ItemComponentDirective) items: QueryList<ItemComponentDirective>;

  isInternetExplorer = GeneralHelper.isInternetExplorer();

  ngOnChanges(changes: SimpleChanges) {
    // fullScreen input overrides mode
    if (changes.fullScreen) {
      this.mode = this.fullScreen ? 'full-screen-height' : 'basic-height';
    }
  }
}
