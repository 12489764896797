<div class="card my-3 text-center flight-trips clickable-card" (click)="viewTripClicked.emit(flight)" data-cy="flightsCard">
  <img class="w-100" [defaultImage]="defaultImage" [lazyLoad]="cityImage" alt="Your trip on {{ flight.flightDate }}">
  <div class="card-body pb-3">
    <div class="secondary-content mt-2">{{ flight.route }}</div>
    <div class="primary-content" data-cy="flightsCardPnr">{{flight.pnr}}</div>
    <div class="secondary-content">
      <time datetime="'flight.flightDate'">{{ flightDate }}</time>
    </div>
    <div class="secondary-content" *ngIf="!!flight.allotmentsUsed">
      <span class="badge text-bg-info"><fa-icon [icon]="icons.faTicketAlt"></fa-icon> {{ flight.allotmentName }} {{ renderAllotmentsUsed() }}</span>
    </div>
  </div>
  <div class="card-footer bg-transparent">
    <button type="button" class="btn text-black btn-md" (click)="viewTripClicked.emit(flight)">{{'mobile.trips.view_trip' | translate}}</button>
  </div>
</div>
