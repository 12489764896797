<ngb-alert *ngIf="noGoggleSession" type="info" (closed)="closeGoogleAlert()">{{ 'home_page.third_parties_connect_modal.google_no_session' | translate }}</ngb-alert>
<ngb-alert *ngIf="mfaError" type="danger" (closed)="closeMfaError()">{{ 'errors.mfa_required' | translate }}</ngb-alert>
<div class="row">
  <div class="col-4" *ngIf="showFacebook">
    <button type="reset" (click)="clickAction(socials.FACEBOOK)" class="btn btn-facebook-big w-100 w-100 fw-normal">
      <fa-icon [icon]="icons.faFacebookF" class="social-icons"></fa-icon>
    </button>
  </div>
  <div class="col-4" *ngIf="showGoogle">
    <button type="reset" class="btn gmail-sign-btn-big w-100 w-100 fw-normal" (click)='openTab()'>
      <span>
        <svg-icon [icon]="'google-sign-icon-big'"></svg-icon>
      </span>
    </button>
  </div>
  <div class="col-4" *ngIf="showApple">
    <button type="reset" class="btn apple-sign-btn-big w-100 fw-normal" (click)="clickAction(socials.APPLE)">
      <fa-icon [icon]="icons.faApple" class="social-icons"></fa-icon>
    </button>
  </div>
</div>
