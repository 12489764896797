import { environment } from '@env/environment';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CreditsService {

  constructor(
    private http: HttpClient
  ) { }

  getAvailableCredit(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/members/credits.json`, {});
  }
}
