<div
  class="card my-3 text-center trips clickable-card"
  (click)="viewTripClicked.emit(hotel)"
>
  <img
    class="hotel-image"
    [src]="hotel.hotelImage ? hotel.hotelImage : defaultImage"
    (error)="useDefaultImageOnError($event)"
    alt="Hotel Image"
  />
  <div class="card-body pb-3">
    <div class="primary-content hotel-name mt-2">{{ hotel.hotelName }}</div>
    <div class="secondary-content mt-2">
      <time datetime="'hotel.startDate'">{{ startDate }}</time> -
      <time datetime="'hotel.endDate'">{{ endDate }}</time>
    </div>
  </div>
  <div class="card-footer bg-transparent">
    <button
      type="button"
      class="btn btn-md text-black"
      (click)="viewTripClicked.emit(hotel)"
    >
      {{'mobile.trips.view_trip' | translate}}
    </button>
  </div>
</div>
