import { MemberLoyaltyActions } from '../actions';
import {MemberLoyalty, MemberLoyaltySummary} from 'app/profile/models/member-loyalty.interface';

export const initialState: MemberLoyalty = {
  summary: null,
  loaded: false,
  pending: false,
  error: null
};

export function reducer(
  state = initialState,
  action: MemberLoyaltyActions.MemberLoyaltyActionsUnion
) {
  switch (action.type) {
    case MemberLoyaltyActions.MemberLoyaltyActionTypes.LoadLoyalty:
      return {
        ...state,
        pending: true
      };
    case MemberLoyaltyActions.MemberLoyaltyActionTypes.LoadLoyaltySuccess:
      return {
        ...state,
        summary: action.payload ? action.payload.summary :  null,
        loaded: true,
        pending: false,
        error: null
      };
    case MemberLoyaltyActions.MemberLoyaltyActionTypes.LoadLoyaltyFailure:
      return {
        ...state,
        pending: false,
        error: action.payload.error
      };
    default: {
      return state;
    }
  }
}

export const getMemberLoyalty = (state: MemberLoyalty): MemberLoyaltySummary => state.summary;
export const getMemberLoyaltyPending = (state: MemberLoyalty): boolean => state.pending;
export const getMemberLoyaltyLoaded = (state: MemberLoyalty): boolean => state.loaded;
export const getMemberLoyaltyError = (state: MemberLoyalty): any => state.error;
