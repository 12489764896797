import { AccountValidationActions, AuthApiActions } from '../actions';

export interface State {
  error: any;
  pending: boolean;
  success: boolean;
  userError: any;
  userPending: boolean;
  userSuccess: any;
}

export const initialState: State = {
  error: null,
  pending: false,
  success: false,
  userError: null,
  userPending: false,
  userSuccess: null
};

export function reducer(
  state = initialState,
  action: AuthApiActions.AuthApiActionsUnion | AccountValidationActions.AccountValidationActionsUnion
): State {
  switch (action.type) {
    case AccountValidationActions.AccountValidationActionTypes.Validate:
      return {
        ...state,
        error: null,
        pending: true
      };
    case AccountValidationActions.AccountValidationActionTypes.ValidateSuccess:
      return {
        ...state,
        error: null,
        pending: false,
        success: true
      };
    case AccountValidationActions.AccountValidationActionTypes.ValidateError:
      return {
        ...state,
        error: action.payload.error,
        pending: false
      };
    case AccountValidationActions.AccountValidationActionTypes.ValidateUser:
      return {
        ...state,
        userError: null,
        userPending: true,
        userSuccess: false
      };
    case AccountValidationActions.AccountValidationActionTypes.ValidateUserSuccess:
      return {
        ...state,
        userError: null,
        userPending: false,
        userSuccess: action.payload.response
      };
    case AccountValidationActions.AccountValidationActionTypes.ValidateUserError:
      return {
        ...state,
        userError: action.payload.error,
        userPending: false
      };
    case AccountValidationActions.AccountValidationActionTypes.ClearState:
      return initialState;
    default: {
      return state;
    }
  }
}

export const getError = (state: State) => state.error;
export const getPending = (state: State) => state.pending;
export const getSuccess = (state: State) => state.success;
export const getUsernameError = (state: State) => state.userError;
export const getUsernamePending = (state: State) => state.userPending;
export const getUsernameSuccess = (state: State) => state.userSuccess;
