<ng-container #container></ng-container>

<div class="col-12 w-100 h-100" *ngIf="pending">
   <div class="col-12">
      <loading-indicator [fullScreen]='true'></loading-indicator>
   </div>
</div>

<ng-template let-data="data" name="hero_section">
   <div [ngStyle]="{
   'width':data.width ? data.width + '%' : '100%',
   'margin-bottom': data.margin_bottom ? data.margin_bottom +'rem' : '',
   'min-height': data.min_height ? data.min_height + 'vh' : ''
    }">
      <div class="col-12 hero-section background-img d-flex w-100 h-100" [appStoryblok]="data._editable" [ngStyle]="{
      'background-image': 'url(' + data?.background_image.filename + '/m/2000x0' + ')',
      'text-align': data?.text_align,
      'background-color': data?.background_color?.color,
      '--opacity': data?.brightness,
      'background-position': data?.background_position
    }" [ngClass]="data?.brightness_filter  === true ? 'filter': ''">
         <div
            [ngClass]="data?.content_align === 'center' ? 'col-12 ms-3 mb-3 center-content mt-0' : 'col-12 ms-3 mb-3 mt-5'">
            <div [ngClass]="generateBootstrapColumn(data.column)" class="w-100">
               <div class="overlap-text" [innerHtml]="parseRichText(data?.title)"></div>
               <span class="pb-2 text-section" [ngStyle]="{'color': data?.text_color?.color }"> {{ data.text}} </span>
               <div class="row">
                  <div class="overlap-text d-flex w-100" [ngClass]="generateBootstrapColumn(button.column)" [ngStyle]="{
               'justify-content': button?.content_align,
             }" *ngFor="let button of data?.button">
                     <div>
                        <button type="button" class="btn btn-primary w-20 mt-5 mb-5" (click)="navigate(button.link.url, button?.external)"
                           [ngStyle]="{
                           'background-color': button?.background_color?.color ? button?.background_color?.color : '#0069d9',
                           'color': button?.text_color.color ? button?.text_color.color : '#fff',
                           'border-color': button?.background_color?.color ? button?.background_color?.color : '#0069d9',
                           'background-image': button?.background_color?.color ? 'none' : 'linear-gradient(to top,#6e60ee,#6078ee)'}">
                           {{button.label}}
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

</ng-template>

<ng-template let-data="data" name="member_travel_benefits_card">
   <div class="row card-body d-flex align-items-center justify-content-center ms-3" [appStoryblok]="data._editable">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 text-center member-travel-benefits-card card-text"
         [ngClass]="data.image_position  === 'left' ? 'order-2 overlap-img' : 'order-1'">
         <div class="m-2" [innerHtml]="parseRichText(data.title)"></div>
      </div>
      <div class="col-lg-6 card-image col-md-6 col-sm-6 col-xs-12 mb-3 mt-3"
         [ngClass]="data.image_position  === 'left' ? 'order-1' : 'order-2 overlap-img'">
         <img class="img" [src]="getImageUrlResized(data.image.filename, 1400)" alt="" srcset="">
      </div>
   </div>
</ng-template>

<ng-template let-data="data" name="simple_card">
   <div [ngClass]="generateBootstrapColumn(data.columns)" class="simple_card" [appStoryblok]="data._editable" [ngStyle]="{
    'text-align': data.text_align
  }">
      <ng-container *ngIf="data?.image_style === 'rounded'">
         <div class="rounded-img-container">
            <div class="rounded-img-inner">
               <img [src]="getImageUrlResized(data?.image?.filename, 600)" class="card-img-top rounded-img"
                  sizes="(max-width: 575px) calc((100vw - 0px) / 1), (max-width: 767px) calc((100vw - 100px) / 2), (max-width: 1099px) calc((100vw - 200px) / 3), (max-width: 1199px) calc((100vw - 200px) / 3), calc((100vw - 200px) / 3)">
            </div>
         </div>
      </ng-container>
      <ng-container *ngIf="data?.image_style === 'semi_rounded'">
         <div class="semi-rounded-img-container">
            <div class="semi-rounded-img-inner">
               <img [src]="getImageUrlResized(data?.image?.filename, 600)" class="card-img-top rounded-img"
                  sizes="(max-width: 575px) calc((100vw - 0px) / 1), (max-width: 767px) calc((100vw - 100px) / 2), (max-width: 1099px) calc((100vw - 200px) / 3), (max-width: 1199px) calc((100vw - 200px) / 3), calc((100vw - 200px) / 3)">
            </div>
         </div>
      </ng-container>
      <ng-container *ngIf="!data?.image_style || data?.image_style === 'default' ">
         <img [src]="getImageUrlResized(data?.image?.filename, 600)" class="card-img-top">
      </ng-container>
      <div class="card-body">
         <div class="card-title">
            <div [innerHTML]="parseRichText(data?.body_title)"></div>

         </div>
         <div class="card-text">
            <div [innerHTML]="parseRichText(data?.body)"> </div>
         </div>
         <div class="col-12" *ngFor="let button of data?.button">
            <button type="button" class="btn btn-primary w-20 mt-5 mb-5" (click)="navigate(button.link.url, button?.external)"
               [ngStyle]="{
               'background-color': button?.background_color?.color ? button?.background_color?.color : '#0069d9',
               'color': button?.text_color.color ? button?.text_color.color : '#fff',
               'border-color': button?.background_color?.color ? button?.background_color?.color : '#0069d9',
               'background-image': button?.background_color?.color ? 'none' : 'linear-gradient(to top,#6e60ee,#6078ee)'}">
            {{button.label}}
            </button>
         </div>
      </div>
   </div>
</ng-template>

<ng-template let-data="data" name="simple_cruises_card">
   <div [ngClass]="generateBootstrapColumn(data.columns)" class="simple_cruises_card" [appStoryblok]="data._editable" [ngStyle]="{
    'text-align': data.text_align
  }">
      <ng-container *ngIf="data?.image_style === 'rounded'">
         <div class="rounded-img-container">
            <div class="rounded-img-inner">
               <img [src]="getImageUrlResized(data?.image?.filename, 600)" class="card-img-top rounded-img"
                  sizes="(max-width: 575px) calc((100vw - 0px) / 1), (max-width: 767px) calc((100vw - 100px) / 2), (max-width: 1099px) calc((100vw - 200px) / 3), (max-width: 1199px) calc((100vw - 200px) / 3), calc((100vw - 200px) / 3)">
            </div>
         </div>
      </ng-container>
      <ng-container *ngIf="data?.image_style === 'semi_rounded'">
         <div class="semi-rounded-img-container">
            <div class="semi-rounded-img-inner">
               <img [src]="getImageUrlResized(data?.image?.filename, 600)" class="card-img-top rounded-img"
                  sizes="(max-width: 575px) calc((100vw - 0px) / 1), (max-width: 767px) calc((100vw - 100px) / 2), (max-width: 1099px) calc((100vw - 200px) / 3), (max-width: 1199px) calc((100vw - 200px) / 3), calc((100vw - 200px) / 3)">
            </div>
         </div>
      </ng-container>
      <ng-container *ngIf="!data?.image_style || data?.image_style === 'default' ">
         <img [src]="getImageUrlResized(data?.image?.filename, 600)" class="card-img-top">
      </ng-container>
      <div class="card-body">
         <div class="card-title">
            <div [innerHTML]="parseRichText(data?.body_title)"></div>

         </div>
         <div class="card-text">
            <div [innerHTML]="parseRichText(data?.body)"> </div>
         </div>
         <div class="col-12 ps-0" *ngFor="let button of data?.button">
            <button type="button" class="btn btn-primary w-20 mt-3 mb-3" (click)="navigate(button.link.url, button?.external)"
               [ngStyle]="{
               'background-color': button?.background_color?.color ? button?.background_color?.color : '#0069d9',
               'color': button?.text_color.color ? button?.text_color.color : '#fff',
               'border-color': button?.background_color?.color ? button?.background_color?.color : '#0069d9',
               'background-image': button?.background_color?.color ? 'none' : 'linear-gradient(to top,#6e60ee,#6078ee)'}">
            {{button.label}}
            </button>
         </div>
      </div>
   </div>
</ng-template>

<ng-template let-data="data" name="people_card">
   <div [ngClass]="generateBootstrapColumn(data.columns)" [appStoryblok]="data._editable"
      [ngStyle]="{'background-color': data?.background_color?.color}">
      <div class="content-container">
         <div class="image-container" [ngStyle]="{'background-color': data?.bg_image_color?.color}">
            <img [src]="getImageUrlResized(data?.image?.filename, 800)" />
         </div>
         <div class="text-container" [innerHtml]="parseRichText(data.title)"
            [ngStyle]="{'background-color': data?.bg_text_color?.color}"></div>
      </div>
   </div>
</ng-template>

<ng-template let-data="data" name="two-col-image-text-section">
   <div class="row w-100" [appStoryblok]="data._editable" [ngStyle]="{
      'background-color': data?.background_color?.color,
      'margin-top': data?.padding_top ? '1rem' : '0rem',
      'margin-bottom': data?.padding_bottom ? '1rem' : '0rem',
      }">
      <div class="col-lg-6 col-md-12 col-sm-12 ps-5" [ngClass]="data.text_order === 'left' ? 'order-1': 'order-2'">
          <div class="pt-3" [innerHtml]="parseRichText(data.text)"></div>
          <div class="col-12 overlap-text" *ngFor="let button of data?.button">
            <button type="button" class="btn btn-primary w-20 mt-5 mb-5" (click)="navigate(button.link.url, button?.external)" [ngStyle]="{
                'background-color': button?.background_color?.color ? button?.background_color?.color : '#0069d9',
                'color': button?.text_color.color ? button?.text_color.color : '#fff',
                'border-color': button?.background_color?.color ? button?.background_color?.color : '#0069d9',
                'background-image': button?.background_color?.color ? 'none' : 'linear-gradient(to top,#6e60ee,#6078ee)'}">
                {{button.label}}
            </button>
          </div>

      </div>
      <div class="col-lg-6 col-md-12 col-sm-12" [ngClass]="data.text_order === 'left' ? 'order-2': 'order-1'">
          <div class="row w-100 h-100">
            <div class="col-12 d-flex justify-content-center w-100 h-100 align-items-center"
                *ngFor="let image of data?.images">
                <img class="image-section" [src]="getImageUrlResized(image?.filename, 800)" alt="" [ngStyle]="{
                  'max-height': data?.image_size ? data.image_size  : '400px',
                  }">
            </div>
          </div>
      </div>
    </div>
</ng-template>

<ng-template let-data="data" name="text-section">
   <div [ngClass]="generateBootstrapColumn(data.columns) " [appStoryblok]="data._editable" [ngStyle]="{
      'background-color': data?.background_color?.color,
      'text-align': data.alignment
    }" class="w-100">
      <div [ngStyle]="{
         'padding-top': data.padding_top ? data.padding_top +'rem' : '3rem',
         'padding-bottom': data.padding_bottom ? data.padding_bottom +'rem' : '3rem',
         'padding-left': data.padding_left ? data.padding_left +'rem' : '3rem',
         'padding-right': data.padding_right ? data.padding_right +'rem' : '3rem',
      }" [innerHTML]="parseRichText(data.richtext)"></div>
      <div *ngIf="data.text">
         <span>{{ data.text }}</span>
      </div>
   </div>
   <div *ngIf="data.border_bottom" class="border-bottom w-100 pt-3" [ngClass]="generateBootstrapColumn(data.columns)">
   </div>
</ng-template>

<ng-template let-data="data" name="button">
   <div [ngClass]="generateBootstrapColumn(data.column)" class="d-flex justify-content-center"
      [appStoryblok]="data._editable">
      <button type="button" class="btn btn-primary w-20 mt-5 mb-5" (click)="navigate(data.link.url, data?.external)" [ngStyle]="{
         'background-color': data?.background_color?.color ? data?.background_color?.color : '#0069d9',
         'color': data?.text_color.color ? data?.text_color.color : '#fff',
         'border-color': data?.background_color?.color ? data?.background_color?.color : '#0069d9',
         'background-image': data?.background_color?.color ? 'none' : 'linear-gradient(to top,#6e60ee,#6078ee)'}">
         {{data.label}}
      </button>
   </div>
</ng-template>

<ng-template let-data="data" name="single_image">
   <div class="row row d-flex align-items-center justify-content-center">
      <div class="col-12">
         <img [src]="getImageUrlResized(data?.image?.filename, 1400)" class="img-fluid">
      </div>
   </div>
</ng-template>

<ng-template let-data="data" name="table">
   <table class="table m-5" [ngClass]="data.template" [appStoryblok]="data._editable">
      <thead>
         <tr>
            <th *ngFor="let head of data.table.thead">
               <span class="d-flex justify-content-center align-items-center m-1"
                  [innerHTML]="parseTable(head.value)"></span>
            </th>
         </tr>
      </thead>
      <tbody>
         <tr *ngFor="let row of data.table.tbody">
            <td *ngFor="let col of row.body">
               {{ col.value }}
            </td>
         </tr>
      </tbody>
   </table>
</ng-template>

<ng-template let-data="data" name="dynamic-form-group">
   <dynamic-form [ngClass]="generateBootstrapColumn(data.form_column)" class="card-body ms-3 me-3"
      [formData]="data"></dynamic-form>
</ng-template>

<ng-template let-data="data" name="two-column-text">
  <div class="col-12 d-flex justify-content-center" [ngStyle]="{
    'background-color': data?.container_background_color?.color,
  }">
    <div class="p-2" [ngClass]="generateBootstrapColumn(data.container_column)">
       <div class="row ms-3 me-3" [appStoryblok]="data._editable">
          <div class="col-lg-6 col-md-12 col-sm-12" [ngClass]="data.order_column_1 === 'left' ? 'order-1': 'order-2'"
             [ngStyle]="{
             'background-color': data?.background_color_column_1?.color,
             'text-align': data.align_content
           }">
             <div class="pt-3" [innerHtml]="parseRichText(data.text_column_1)"></div>
             <div *ngFor="let button of data?.button_column_1">
                <button type="button" class="btn btn-primary w-20 mt-5 mb-5" (click)="navigate(button?.link.url, button?.external)" [ngStyle]="{
                   'background-color': button?.background_color?.color ? button?.background_color?.color : '#0069d9',
                   'color': button?.text_color.color ? data?.text_color.color : '#fff',
                   'border-color': button?.background_color?.color ? data?.background_color?.color : '#0069d9',
                   'background-image': button?.background_color?.color ? 'none' : 'linear-gradient(to top,#6e60ee,#6078ee)'}">
                   {{button.label}}
                </button>
             </div>
          </div>
          <div [ngClass]="{'justify-content align-items-center d-flex': data.align_content === 'center'
          , 'order-2' : data.order_column_1 === 'left' , 'order-1' : data.order_column_1 === 'right'}"
             class="col-lg-6 col-md-12 col-sm-12 p-2" [ngStyle]="{
             'background-color': data?.background_color_column_2?.color,
             'text-align': data.align_content
           }">
             <div class="pt-3" [innerHtml]="parseRichText(data.text_column_2)"></div>
             <div *ngFor="let button of data?.button_column_2">
                <button type="button" class="btn btn-primary w-20 mt-5 mb-5" (click)="navigate(button?.link.url, button?.external)">{{button.label}}
                </button>
             </div>
          </div>
       </div>
    </div>
  </div>
</ng-template>

<ng-template let-data="data" name="people_card">
   <div [ngClass]="generateBootstrapColumn(data.columns)"[appStoryblok]="data._editable">
      <div class="row card-body d-flex align-items-center justify-content-center ms-3">
         <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 text-center member-travel-benefits-card card-text"
            [ngClass]="data.image_position  === 'left' ? 'order-2 overlap-img' : 'order-1'">
            <div class="card-title" [innerHtml]="parseRichText(data.title)"></div>
         </div>
         <div class="col-lg-6 card-image col-md-6 col-sm-6 col-xs-12 mb-3 mt-3"
            [ngClass]="data.image_position  === 'left' ? 'order-1' : 'order-2 overlap-img'">
            <div>
               <img class="image-section" [src]="getImageUrlResized(data?.image?.filename, 1000)"
                  sizes="(max-width: 575px) calc((100vw - 0px) / 1), (max-width: 767px) calc((100vw - 100px) / 2), (max-width: 1099px) calc((100vw - 200px) / 3), (max-width: 1199px) calc((100vw - 200px) / 3), calc((100vw - 200px) / 3)">
            </div>
         </div>
      </div>
   </div>
</ng-template>

<ng-template let-data="data" name="hbspt-form">
   <div [ngClass]="generateBootstrapColumn(data.columns)">
      <hbspt-form [formId]="data.form"></hbspt-form>
   </div>
</ng-template>

<ng-template let-data="data" name="list">
   <div class="mb-5" [ngClass]="generateBootstrapColumn(data.columns)" [appStoryblok]="data._editable">
      <div class="row">
         <div class="col-12 mt-5" *ngFor="let article of data?.list; index as i">
            <div [innerHTML]="parseRichText(article.listViewText)" [ngStyle]="{'text-align': article.alignment }"></div>
            <div *ngIf="article.showMore">
               <a class="read-more-link" (click)="toggleExpand(i, data)">{{ 'Read More' }}</a>
            </div>
         </div>
      </div>
   </div>
</ng-template>

<ng-template let-data="data" name="list-expanded-internal">
   <div class="mt-5" [ngClass]="generateBootstrapColumn(data.columns)" [appStoryblok]="data._editable">
      <div class="row">
         <div *ngIf="article.content" [innerHTML]="article.content"></div>
         <div class="col-12 d-flex justify-content-center" *ngFor="let button of data?.button">
            <button type="button" class="btn btn-primary w-20 mt-5 mb-5" (click)="navigate(button.link.url, button?.external)" [ngStyle]="{
            'background-color': button?.background_color?.color ? button?.background_color?.color : '#0069d9',
            'color': button?.text_color.color ? button?.text_color.color : '#fff',
            'border-color': button?.background_color?.color ? button?.background_color?.color : '#0069d9',
            'background-image': button?.background_color?.color ? 'none' : 'linear-gradient(to top,#6e60ee,#6078ee)'}">
               {{button.label}}
            </button>
         </div>
      </div>
   </div>
   <div class="row w-100">
      <div class="col-6 float-end mb-2">
         <div (click)="toggleExpand((article.index - 1))"  *ngIf="article.prev">
            <div class="row underline">
               <div class="col-1 text-end m-auto p-0">
                  <fa-icon [icon]="prevIcon"></fa-icon>
               </div>
               <div class="col-10 text-start  p-0 m-0">
                  <h3> {{ article.prev.title }} </h3>
               </div>
            </div>
         </div>
         <div (click)="noArticleBack()" *ngIf="!article.prev">
            <div class="row underline">
               <div class="col-1 text-end m-auto p-0">
                  <fa-icon [icon]="prevIcon"></fa-icon>
               </div>
               <div class="col-10 text-start m-auto p-0">
                  <h3> {{ data.no_prev }} </h3>
               </div>
            </div>
         </div>
      </div>

      <div class="col-6 mb-2" *ngIf="article.next">
         <div class="float-end" (click)="toggleExpand((article.index + 1))">
            <div class="row underline">
               <div class="col-10 text-end m-auto p-0">
                  <h3> {{ article.next.title }} </h3>
               </div>
               <div class="col-1 text-start m-auto p-0">
                  <fa-icon [icon]="nextIcon"></fa-icon>
               </div>
            </div>
         </div>
      </div>
   </div>
</ng-template>
