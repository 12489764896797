import { SessionService } from 'app/shared/services/session/session.service';
import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { environment } from '@env/environment';
import { TripsActions } from 'app/trips/store/actions';
import { Store } from '@ngrx/store';
import * as fromRoot from 'reducers';
import * as fromAuth from 'app/auth/store';

@Injectable({
  providedIn: 'root'
})

export class ForceRefreshGuard  {
  PATHS_REFRESH = [
    '/flights',
    '/profile',
    '/cobus'
  ];

  constructor(private store: Store<fromRoot.State>, private sessionService: SessionService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (!environment.forceRefresh) {
      return of(true);
    }

    this.PATHS_REFRESH.forEach((path) => {
      const searchPattern = new RegExp(`^${path}`, 'i');
      if (searchPattern.test(state.url)) {
        if (path === '/flights' &&
          ((environment.listingEnable && this.sessionService.getNewFlightListingCookie()) || environment.ticketingEnable)) {
          return of(true);
        }

        // reload flight trips (in case the user buys a ticket in the old desktop)
        this.store.dispatch(new TripsActions.ClearLoaded());
        setTimeout(() => {
          window.location.reload();
        });
        return of(true);
      }
    });

    return of(true);
  }
}
