import { Action } from '@ngrx/store';

export enum UnlinkEmailActionTypes {
  UnlinkRequest = '[Unlink Email] Unlink Request',
  UnlinkRequestSuccess = '[Unlink Email] Unlink Request Success',
  UnlinkRequestError = '[Unlink Email] Unlink Request Error',
  UnlinkEmailVerifyCode = '[Unlink Email] Unlink Verify Code',
  UnlinkEmailVerifyCodeSuccess = '[Unlink Email] Unlink Verify Code Success',
  UnlinkEmailVerifyCodeError = '[Unlink Email] Unlink Verify Code Error',
  Clear = '[Unlink Email] Clear',
}

export class UnlinkRequest implements Action {
  readonly type = UnlinkEmailActionTypes.UnlinkRequest;
  constructor(public payload: any) {}
}

export class UnlinkRequestSuccess implements Action {
  readonly type = UnlinkEmailActionTypes.UnlinkRequestSuccess;
  constructor(public payload: any) {}
}

export class UnlinkRequestError implements Action {
  readonly type = UnlinkEmailActionTypes.UnlinkRequestError;
  constructor(public payload: any) {}
}

export class UnlinkEmailVerifyCode implements Action {
  readonly type = UnlinkEmailActionTypes.UnlinkEmailVerifyCode;
  constructor(public payload: any) {}
}

export class UnlinkEmailVerifyCodeSuccess implements Action {
  readonly type = UnlinkEmailActionTypes.UnlinkEmailVerifyCodeSuccess;
  constructor(public payload: any) {}
}

export class UnlinkEmailVerifyCodeError implements Action {
  readonly type = UnlinkEmailActionTypes.UnlinkEmailVerifyCodeError;
  constructor(public payload: any) {}
}

export class Clear implements Action {
  readonly type = UnlinkEmailActionTypes.Clear;
}


export type UnlinkEmailActionUnion = UnlinkRequest
  | UnlinkRequestSuccess
  | UnlinkRequestError
  | UnlinkEmailVerifyCode
  | UnlinkEmailVerifyCodeSuccess
  | UnlinkEmailVerifyCodeError
  | Clear

