import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

declare let AppleID: any;

@Injectable({
  providedIn: 'root'
})

export class AppleSignInService  {
  clientId = 'com.id90travel.id90web';
  signInScope = 'name email';
  signInRedirect = `${environment.apiUrl}/login`;

  public static PROVIDER_ID = 'Apple';

  async signIn(platform) {
    AppleID.auth.init({
      clientId: this.clientId,
      scope: this.signInScope,
      redirectURI: this.signInRedirect,
      usePopup: true
    });
    return AppleID.auth.signIn();
  }
}
