import { Action } from '@ngrx/store';

export enum PaymentMethodsApiActionTypes {
  AddCardSuccess = '[Payment Methods/API] Add Card Success',
  AddCardFailure = '[Payment Methods/API] Add Card Failure',
}

export class AddCardSuccess implements Action {
  readonly type = PaymentMethodsApiActionTypes.AddCardSuccess;

  constructor(public payload: any) {}
}

export class AddCardFailure implements Action {
  readonly type = PaymentMethodsApiActionTypes.AddCardFailure;

  constructor(public payload: any) {}
}

export type PaymentMethodsApiActionsUnion = AddCardSuccess
  | AddCardFailure;
