import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent {
  @Input() steps = [];
  @Input() currentStep = 0;
  @Output() stepClickAction = new EventEmitter()
  icons = { faCheckCircle };

  onStepClick(step) {
    if (step < this.currentStep) {
      this.stepClickAction.emit(step);
    }
  }
}
