import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AuthApiActions, LogoutComponentActions } from 'app/auth/store';
import { PhoneNumberMapper } from 'app/profile/mappers/phone-number.mapper';
import { MfaActions } from 'app/profile/models/mfa';
import { ProfileUserActions } from 'app/profile/store/actions';
import * as fromProfile from 'app/profile/store/reducers';
import { TwoStepSecurityModal } from 'app/shared/containers/two-step-security/two-step-security-modal';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';
import { MissingInfoHelper } from 'app/shared/helpers/missing-info.helper';
import { ProfileHelper } from 'app/shared/helpers/profile.helper';
import { Country } from 'app/shared/models/country.model';
import { BaseMissingModal } from 'app/shared/models/missing-modal';
import { ProfileService } from 'app/shared/services/profile/profile.service';
import * as fromShared from 'app/shared/store/reducers';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';


@Component({
  selector: 'missing-info-modal',
  templateUrl: './missing-info-modal.component.html',
  styleUrls: ['./missing-info-modal.component.scss']
})
export class MissingInfoModalComponent implements OnInit, OnDestroy {
  @Input() modalconfig: BaseMissingModal | undefined = undefined;
  @Input() mfaRequest: boolean;
  @Input() user: any;
  formGroup: FormGroup | undefined = undefined;
  countries$ = this.store.pipe(select(fromShared.getCountries));
  countries: Country[];
  icons = { faPhone };
  inputError = false;
  private ngUnsubscribe = new Subject<void>();
  private ngbModalOpt: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'lg'
  };
  error = null;

  constructor(
    private activeModal: NgbActiveModal,
    private profileService: ProfileService,
    private store: Store<fromProfile.State>,
    private profileHelper: ProfileHelper,
    private modalService: NgbModal,
    private missingInfoHelper: MissingInfoHelper,
    public formErrorsHelper: FormErrorsHelper,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    if ((this.modalconfig?.formFields?.phone || this.modalconfig?.formFields?.address)) {
      this.countries$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
        if (response?.length > 0) {
          this.countries = response;
          this.formGroup = this.missingInfoHelper.buildFormGroup(this.modalconfig);
        }
      });
      return;
    }
    this.formGroup = this.missingInfoHelper.buildFormGroup(this.modalconfig);
  }

  public showFields(name: string): boolean {
    return this.modalconfig.formFields &&
      this.modalconfig.formFields[name] &&
      this.modalconfig.formFields[name]?.show;
  }

  dismiss(logout = false) {
    this.activeModal.dismiss(true);
    if (logout && this.modalconfig.close.logout) {
      this.store.dispatch(new LogoutComponentActions.Logout());
    }
    localStorage.setItem('dontAskAgain', 'true');
  }

  submit() {
    if (this.formGroup.valid) {
        if (this.mfaRequest) {
          const twoStepSecurityModal = this.modalService.open(TwoStepSecurityModal, this.ngbModalOpt);
          twoStepSecurityModal.componentInstance.userData = this.user;
          twoStepSecurityModal.componentInstance.action = MfaActions.MFA_NEW_DEVICE;
          twoStepSecurityModal.componentInstance.fromLogin = true;
          twoStepSecurityModal.componentInstance.showEmail = this.modalconfig.formFields.email ? false : true;
          twoStepSecurityModal.result.then((success) => {
             if (success) {
              this.updateProfile();
             }
           },
           () => {
              this.error = this.translateService.instant('errors.mfa_required');
           }
          )
        } else {
          this.updateProfile();
        }
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getAddress($event) {
    this.address.patchValue({
      ...$event
    })
  }
  closeAlert() {
    this.error = null;

  }

  getPhoneFormatted() {
    const country = this.phone.value?.country?.code || 'US';
    const number = this.phone.value?.number || '';
    return this.profileHelper.formatPhoneService(country, number);
  }

  formatedValues(): any {
    let { updateServiceValue, updateUserProfileValue, updateAccountInfo } = {
      updateAccountInfo: {},
      updateServiceValue: {},
      updateUserProfileValue: {}
    }

    for (const field in this.modalconfig.formFields) {
      if (Object.prototype.hasOwnProperty.call(this.modalconfig.formFields, field)) {
        switch (field) {
          case 'email':
            updateServiceValue = {
              ...updateServiceValue,
              personalEmail: this.formGroup.value.email,
            };
            updateUserProfileValue = {
              ...updateUserProfileValue,
              personalEmail: this.formGroup.value.email
            };
            updateAccountInfo = {
              ...updateAccountInfo,
              personalEmail: this.formGroup.value.email
            };
            break;
          case 'phone':
            const phoneElement = this.getPhoneFormatted();
            const phoneString = new PhoneNumberMapper().fromObject().toString(phoneElement);
            updateServiceValue = {
              ...updateServiceValue,
              homePhone: phoneElement,
            };
            updateUserProfileValue = {
              ...updateUserProfileValue,
              homePhone: phoneString
            };
            updateAccountInfo = {
              ...updateAccountInfo,
              homePhone: phoneString
            };
            break
          case 'firstName':
          case 'lastName':
            updateServiceValue = {
              ...updateServiceValue,
              [field]: this.formGroup[field].value,
            };
            updateUserProfileValue = {
              ...updateUserProfileValue,
              [field]: this.formGroup[field].value
            };
            updateAccountInfo = {
              ...updateAccountInfo,
              [field]: this.formGroup[field].value
            };
            break;

          default:
            break;
        }

      }
    }
    return {
      updateAccountInfo,
      updateServiceValue,
      updateUserProfileValue
    }
  }
  private updateProfile() {
    const { updateServiceValue, updateUserProfileValue, updateAccountInfo } = this.formatedValues();
    this.profileService.updateProfile({ ...updateServiceValue }).pipe(take(1)).subscribe(() => {
      if (this.modalconfig.formFields?.phone) { localStorage.setItem('dontAskAgain', 'true'); }
      this.store.dispatch(new ProfileUserActions.UpdateUserProfile({ ...updateUserProfileValue }));
      this.store.dispatch(new AuthApiActions.UpdateUserAccountInfo({ ...updateAccountInfo }));
      this.activeModal.dismiss(true);
    });
  }

  get email() { return this.formGroup ? this.formGroup.get('email') : null; }
  get phone() { return this.formGroup ? this.formGroup.get('phoneNumber') : null; }
  get address() { return this.formGroup ? this.formGroup.get('address') : null; }
  get firstName() { return this.formGroup ? this.formGroup.get('firstName') : null; }
  get lastName() { return this.formGroup ? this.formGroup.get('lastName') : null; }

}
