import { Action } from '@ngrx/store';

export enum ThirdPartyActionTypes {
  ConnectThirdParty = '[Third Party] Third Party Connect',
  ConnectThirdPartySuccess = '[Third Party] Third Party Connect Success',
  ConnectThirdPartyFailure = '[Third Party] Third Party Connect Failure',
  DisconnectThirdParty = '[Third Party] Third Party Disconnect',
  DisconnectThirdPartySuccess = '[Third Party] Third Party Disconnect Success',
  DisconnectThirdPartyFailure = '[Third Party] Third Party Disconnect Failure'
}

export class ConnectThirdParty implements Action {
  readonly type = ThirdPartyActionTypes.ConnectThirdParty;

  constructor(public payload: { authToken: string, platform: string, userInfo: any, showModalError: boolean }) {}
}

export class ConnectThirdPartySuccess implements Action {
  readonly type = ThirdPartyActionTypes.ConnectThirdPartySuccess;

  constructor(public payload: { params: any }) {}
}

export class ConnectThirdPartyFailure implements Action {
  readonly type = ThirdPartyActionTypes.ConnectThirdPartyFailure;

  constructor(public payload: { error: any, showModalError: boolean, platform: string }) {}
}

export class DisconnectThirdParty implements Action {
  readonly type = ThirdPartyActionTypes.DisconnectThirdParty;

  constructor(public payload: { platform: string, showModalError: boolean }) { }
}

export class DisconnectThirdPartySuccess implements Action {
  readonly type = ThirdPartyActionTypes.DisconnectThirdPartySuccess;
}

export class DisconnectThirdPartyFailure implements Action {
  readonly type = ThirdPartyActionTypes.DisconnectThirdPartyFailure;

  constructor(public payload: { error: any, showModalError: boolean, platform: string }) {}
}

export type ThirdPartyActionsUnion = ConnectThirdParty
  | ConnectThirdPartySuccess
  | ConnectThirdPartyFailure
  | DisconnectThirdParty
  | DisconnectThirdPartySuccess
  | DisconnectThirdPartyFailure;
