import { Injectable } from '@angular/core';
import { MapService } from '../map.service';
import { MapMarker } from 'app/shared/models/map-marker';

declare var google: any;

@Injectable()

export class GoogleMapsService implements MapService {
  METERS_TO_MILES_CONVERSION = 0.000621371;

  initialize() {
    return Promise.resolve();
  }

  buildMap(lat, lng) {
    const latLng = new google.maps.LatLng(lat, lng);
    const mapOptions = {
      clickableIcons: false,
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: false,
      center: latLng,
      zoom: 12,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    return new google.maps.Map('map', mapOptions);
  }

  addMarker(map: any, marker: MapMarker, onClick: any, popup: any) {
    google.maps.Marker.MAX_ZINDEX = 1000;
    return new google.maps.Marker({
      map,
      icon: {
        url: 'angular_assets/images/maps/price_bubble.png',
        labelOrigin: new google.maps.Point(25, 12),
        size: new google.maps.Size(53, 30)
      },
      label: marker.text,
      opacity: 1.0,
      position: { lat: marker.latitude, lng: marker.longitude }
    });
  }

  removeMarkers(map: any) {
    // TODO: Implement.
  }

  setCenter(map: any, latitude: number, longitude: number, animate: boolean, radius: number = null): void {
    // TODO: Implement.
  }

  getCenter(map: any) {
    // TODO: Implement.
    return { latitude: null, longitude: null };
  }

  extendBounds(map: any, markers: MapMarker[]) {
    // TODO: Implement.
  }

  setZoomEnabled(map: any, enabled: boolean) {
    // TODO: Implement.
  }

  setZoomLevel(map: any, level: number) {
    // TODO: Implement.
  }

  showScale(map: any) {
    // TODO: Implement.
  }

  onLocationChanged(map: any, fn: any) {
    // TODO: Implement.
  }

  makeMarkerInactive(marker, previousZIndex) {
    marker.setZIndex(previousZIndex);
    marker.setIcon({
      url: 'angular_assets/images/maps/price_bubble.png',
      labelOrigin: new google.maps.Point(25, 12),
    });
    marker.setLabel({
      text: marker.label.text || marker.label,
      color: '#000'
    });
  }

  makeMarkerActive(marker) {
    marker.setZIndex(1001);
    marker.setIcon({
      url: 'angular_assets/images/maps/price_bubble_selected.png',
      labelOrigin: new google.maps.Point(25, 12),
    });
    marker.setLabel({
      text: marker.label.text || marker.label,
      color: '#fff'
    });
  }

  updateSelectedMarker(previousActiveMarker, previousZIndex, marker) {
    let zIndex;
    if (previousActiveMarker) {
      previousActiveMarker.setZIndex(previousZIndex);
      previousActiveMarker.setIcon({
        url: 'angular_assets/images/maps/price_bubble.png',
        labelOrigin: new google.maps.Point(25, 12),
      });
      previousActiveMarker.setLabel({
        text: marker.label.text || marker.label,
        color: '#000'
      });
    }
    zIndex = marker.getZIndex();
    marker.setZIndex(1001);
    marker.setIcon({
      url: 'angular_assets/images/maps/price_bubble_selected.png',
      labelOrigin: new google.maps.Point(25, 12),
    });
    marker.setLabel({
      text: marker.label.text || marker.label,
      color: '#fff'
    });
    return { marker, zIndex };
  }

  getRadius(map) {
    const rectangle = map.getBounds();
    const lat = (rectangle.f.f + rectangle.f.b) / 2;
    const lng1 = rectangle.b.b;
    const lng2 = rectangle.b.f;
    const from = new google.maps.LatLng(lat, lng1);
    const to = new google.maps.LatLng(lat, lng2);
    return (
      google.maps.geometry.spherical.computeDistanceBetween(from, to) / 2
    ) * this.METERS_TO_MILES_CONVERSION;
  }

  setRadius(map: any, radius: number, animate: boolean = true) { }

  getStaticAPIKey() {
    return 'AIzaSyCL-kVW-Ize3MFZvkoBdpESv-RYaJHqBZc';
  }

  drawPolygons(map: any, polygons: any[]) { }

  removePolygons(map: any) { }
  buildCluster(map: any, number: number, mapMarkers: any[], clickCallback, getMarkersCallback, sortBy): void { }
  removeLayer() { }
  setInteractive(map: any, isInteractive: boolean, options?: any) { }
  drawMarkersWithoutClustering(map: any, mapMarkers: any, getMarkerCallback) { }
}
