<div class="modal-header">
  <img *ngIf='imageTitleSrc' [src]='imageTitleSrc' />
  <h4 data-cy="modal-title" *ngIf='title' class="modal-title">{{ title | translate }}</h4>
  <h4 data-cy="modal-title" *ngIf='titleTranslationKey' class="modal-title">{{ titleTranslationKey | translate }}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="closeModals()">

  </button>
</div>
<div class="modal-body">
  <img *ngIf='imageSrc' [src]='imageSrc' class="img-fluid mb-3 {{imageCls}}">
  <h5 *ngIf='subtitleTranslationKey' [innerHtml]='subtitleTranslationKey | translate'></h5>
  <p *ngIf='content' [innerHtml]='content'></p>
  <p *ngIf='contentTranslationKey' [innerHtml]='contentTranslationKey | translate:contentTranslationVars'></p>
</div>
<div class="modal-footer">
  <button
    *ngIf='secondaryButton'
    [routerLink]='secondaryButtonLink'
    type="button"
    class="btn btn-outline-light text-dark border"
    (click)="activeModal.close('Close click')"
  >
    {{ secondaryButton | translate }}
  </button>
  <button type="button" class="btn btn-outline-light text-dark border" (click)="closeModals()" data-cy="closeButton">{{ 'new_features.close' | translate }}</button>
</div>
