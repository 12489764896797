import { SessionService } from 'app/shared/services/session/session.service';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalActions } from 'app/shared/store/actions';
import { Modal } from 'app/shared/models/modal.model';
import { CoTravelersService } from 'app/shared/services/co-travelers/co-travelers.service';
import { ShareItinerariesSources, SharedItinerary } from 'app/hotels/models/share-itinerary';
import { HotelHelper } from 'app/shared/helpers/hotel.helper';
import { TripsActions } from 'app/trips/store/actions';
import { take } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import * as fromAuth from '../../../auth/store';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'share-itinerary',
  templateUrl: 'share-itinerary.html'
})
export class ShareItineraryComponent implements OnInit {
  shareCode: string | any;
  sharedItinerary: SharedItinerary;
  loggedIn$ = this.store.pipe(select(fromAuth.getLoggedIn));
  isFromRegister;
  isLogIn

  constructor(
    private activatedRoute: ActivatedRoute,
    private coTravelersService: CoTravelersService,
    private hotelHelper: HotelHelper,
    private store: Store<fromRoot.State>,
    public router: Router,
    public sessionService: SessionService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    combineLatest([this.activatedRoute.queryParams, this.loggedIn$]).pipe(take(1)).subscribe(([params, loggedIn]) => {
      this.shareCode = params.shareCode || params.share_code || this.sessionService.getShareCode();
      this.isFromRegister = params.fromRegister;
      this.isLogIn = loggedIn;
      if (!loggedIn) {
        this.sessionService.setShareCode(this.shareCode);
        return this.router.navigate(['/login']);
      }
    });

    if (this.isLogIn && this.isFromRegister) {
      return this.showErrorAndRedirect(this.translateService.instant('web.create_account_logged_in'));
    }
    if (!this.shareCode) {
      return this.showErrorAndRedirect(this.translateService.instant('co_traveler.errors.no_share_code'));
    }
    if (this.isLogIn) {
      this.coTravelersService.addMemberToItinerary(this.shareCode).pipe(take(1)).subscribe(
        (response: any) => {
          if (response.data) {
            this.sharedItinerary = response.data;
            this.sessionService.removeShareCode();
            this.store.dispatch(new TripsActions.ClearLoaded);
            this.redirectBySource();
          }
        },
        (e) => {
          return this.showErrorAndRedirect(e.error.message);
        }
      );
    }
  }

  redirectBySource() {
    switch (this.sharedItinerary.source) {
      case ShareItinerariesSources.HOTELS:
        this.redirectHotel();
        break;
      default:
        this.redirectHotel();
        break;
    }
  }

  redirectHotel() {
    if (!this.sharedItinerary.itineraryId) {
      return false;
    }
    const itineraryId = this.hotelHelper.encodeVendorPropertyID(this.sharedItinerary.itineraryId);
    return this.router.navigate([`hotels/reservations/confirmation/${itineraryId}`], { queryParams: { 'fromShareItinerary': this.sharedItinerary.owner ? false : true }});
  }

  showErrorAndRedirect(message: string) {
    if (message) {
      const data: Modal = {
        title: 'errors.error',
        body: message
      };
      this.store.dispatch(new ModalActions.Show({ data }));
    }
    return this.router.navigate(['/login']);
  }
}
