import { HotelsRecentSearchesActions, HotelsRecentSearchesApiActions } from 'app/shared/store/actions';
import { HotelsRecentSearch } from 'app/shared/models/hotels-recent-search';

export interface State {
  destinations: HotelsRecentSearch[] | null;
  pending: boolean;
  error: any | null;
  loaded: boolean;
  expiration:number;
}

export const initialState: State = {
  destinations: [],
  pending: false,
  error: null,
  loaded: false,
  expiration: null
};

export function reducer(
  state = initialState,
  action: HotelsRecentSearchesActions.HotelsRecentSearchesActionsUnion | HotelsRecentSearchesApiActions.HotelsRecentSearchesApiActionsUnion
) {
  switch (action.type) {
    case HotelsRecentSearchesActions.HotelsRecentSearchesActionTypes.Load:
      return {
        ...state,
        destinations: [],
        error: null,
        pending: true,
        expiration: null
      };
    case HotelsRecentSearchesApiActions.HotelsRecentSearchesApiActionTypes.LoadSuccess:
      return {
        destinations: action.payload.destinations,
        error: null,
        pending: false,
        loaded: true,
        expiration: Date.now() + 3600000
      };
    case HotelsRecentSearchesApiActions.HotelsRecentSearchesApiActionTypes.LoadFailure:
      return {
        destinations: [],
        error: action.payload.error,
        pending: false,
        loaded: false,
        expiration: null
      };
    case HotelsRecentSearchesActions.HotelsRecentSearchesActionTypes.SaveSearch:
      return {
        ...state,
        destinations: [action.payload, ...state.destinations]
      };
    default:
      return state;
  }
}

export const getDestinations = (state: State) => state.destinations;
export const getPending = (state: State) => state.pending;
export const getLoaded = (state: State) => state.loaded && state.expiration > Date.now();
