import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ProfileService } from 'app/shared/services/profile/profile.service';
import { Address } from 'app/shared/models/address';
import { take } from 'rxjs/operators';
import { AddressFormComponent } from 'app/shared/components/address/address-form.component';
import { TranslateService } from '@ngx-translate/core';
import { GeneralHelper } from 'app/shared/helpers/general.helper';
import { faChevronLeft, faCircleNotch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'profile-address',
  templateUrl: './profile-address.html'
})
export class ProfileAddressComponent implements OnInit {
  @ViewChild('addressForm', { static: false }) addressForm: AddressFormComponent;
  profileAddress = new Address();
  @Input() profileForm;
  @Input() profileConfig;
  @Input() countries;
  @Output() updateProfile = new EventEmitter();
  @Output() showTabs = new EventEmitter();
  error = null;
  success = null;
  loading = false;
  disabledFields = {};
  icons = { faChevronLeft, faCircleNotch };

  constructor(
    private profileService: ProfileService,
    private translate: TranslateService,
    private generalHelper: GeneralHelper
  ) {}

  ngOnInit() {
    this.getAddressValue();
    const address = this.getAddressValue();
    this.profileAddress.street = address.address;
    this.profileAddress.city = address.city;
    this.profileAddress.country = address.country;
    this.profileAddress.zipCode = address.zip;
    this.profileAddress.state = address.state;
    this.updateDisabledFields();
  }

  getAddressValue() {
    const addressForm = this.profileForm.controls.address;
    const addressValue = { 
      address : null,
      city: null,
      country: null,
      zip: null,
      state: null
    }; 
    Object.keys(addressForm.controls).forEach((field) => {
     addressValue[field] = addressForm.controls[field].value; 
    });
    return addressValue;
  }

  onEditAddress() {
    this.closeAlert();

    if (this.isValid()) {
      this.loading = true;
      const params = this.profileAddress.toJson();

      this.profileService.updateProfile(params).pipe(take(1)).subscribe(
        () => {
          this.loading = false;
          this.success = 'profile.info.successful_update';
          this.updateProfile.emit(params);
        },
        (error) => {
          this.error = error.error.error_description;
          this.loading = false;
        }
      );
    } else {
      this.generalHelper.touchForms([this.addressForm.addressForm]);
      this.error = this.translate.instant('mobile.validation_errors.required_all');
    }
  }

  isValid() {
    return this.addressForm.isValid();
  }

  closeAlert() {
    this.error = null;
    this.success = null;
  }

  backToTabs() {
    this.showTabs.emit();
  }

  get country()  { return this.profileForm.controls['address'].get('country'); }
  get state()  { return this.profileForm.controls['address'].get('state'); }
  get city()  { return this.profileForm.controls['address'].get('city'); }
  get zip()  { return this.profileForm.controls['address'].get('zip'); }
  get address()  { return this.profileForm.controls['address'].get('address'); }
  get aptUnit()  { return this.profileForm.controls['address'].get('aptUnit'); }

  updateDisabledFields() {
    const form = this.profileForm.controls.address;
    this.disabledFields = {
      zipCode: form.get('zip').disabled,
      street: form.get('address').disabled,
      city: form.get('city').disabled,
      state: form.get('state').disabled,
      country: form.get('country').disabled
    };
  }
}
