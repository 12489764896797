import { AbandonedHotelActions, AbandonedHotelApiActions } from 'app/shared/store/actions';
import { AbandonedHotel } from 'app/shared/models/abandoned-hotel';

export interface State {
  hotel: AbandonedHotel | null;
  pending: boolean;
  error: any | null;
  loaded: boolean;
}

export const initialState: State = {
  hotel: null,
  pending: false,
  error: null,
  loaded: false
};

export function reducer(
  state = initialState,
  action: AbandonedHotelActions.AbandonedHotelActionsUnion | AbandonedHotelApiActions.AbandonedHotelApiActionsUnion
) {
  switch (action.type) {
    case AbandonedHotelActions.AbandonedHotelActionTypes.Load:
      return {
        ...state,
        hotel: null,
        error: null,
        pending: true
      };
    case AbandonedHotelApiActions.AbandonedHotelApiActionTypes.LoadSuccess:
      return {
        hotel: action.payload,
        error: null,
        pending: false,
        loaded: true
      };
    case AbandonedHotelApiActions.AbandonedHotelApiActionTypes.LoadFailure:
      return {
        hotel: null,
        error: action.payload.error,
        pending: false,
        loaded: false
      };
    case AbandonedHotelActions.AbandonedHotelActionTypes.ClearLoaded:
      return {
        ...state,
        loaded: false
      };
    default:
      return state;
  }
}

export const getAbandonedHotel = (state: State) => state.hotel;
export const getPending = (state: State) => state.pending;
export const getLoaded = (state: State) => state.loaded;
