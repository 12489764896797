<div class="row">
  <div *ngFor="let destination of destinationsList; let i = index" class="destination-option" [ngClass]="{'col-md-12 col-xl-6': size === 'xl', 'col-md-6 col-lg-4 col-xl-3': size === 'sm'}">
    <div class="card {{displayClass(i)}} clickable-card" (click)="onDestinationSelected(destination)">
      <img class="img-fluid {{gtmCardClass()}}" [ngClass]="{'destination-image-square': showImageSquare, 'destination-image': !showImageSquare}" [offset]="100" [defaultImage]="defaultImage" [lazyLoad]="destination[image] ? destination[image] : defaultImage"/>
      <div class="card-body {{gtmCardClass()}}">
        <div class="primary-content text-truncate {{gtmCardClass()}}">{{destination[name]}}</div>
        <div class="secondary-content {{gtmCardClass()}}" *ngIf="destination.place">{{destination.place}}</div>
        <div class="secondary-content" *ngIf="destination.count">{{destination.count}} {{'lodging.hotels.properties' | translate}}</div>
        <div class="tertiary-content {{gtmCardClass()}}" *ngIf="destination.price_lowest">{{'landing_page.rate_from' | translate}} {{destination.price_lowest | currency:'USD':'symbol-narrow':'1.0-0'}}</div>
        <div class="tertiary-content" *ngIf="destination.dates">{{destination.dates[0]}} - {{destination.dates[1]}}</div>
        <rating class="recently-viewed-rating" *ngIf="destination.ratings" [value]="destination.ratings"></rating>
      </div>
    </div>
  </div>
</div>
<div class="text-end">
  <button type="button" class="btn btn-outline-primary gtm-show-more-cards" *ngIf="moreOptionsAvailable" (click)="showMoreCards()">{{ 'mobile.show_more' | translate }}</button>
</div>
