import { Action } from '@ngrx/store';
import { AirportsRecentSearch } from 'app/shared/models/airports-recent-search';

export enum AirportsRecentDeparturesSearchesApiActionTypes {
  LoadSuccess = '[Airports Recent Departures Searches/API] Load Success',
  LoadFailure = '[Airports Recent Departures Searches/API] Load Failure',
}

export class LoadSuccess implements Action {
  readonly type = AirportsRecentDeparturesSearchesApiActionTypes.LoadSuccess;

  constructor(public payload: { destinations: AirportsRecentSearch[] }) {}
}

export class LoadFailure implements Action {
  readonly type = AirportsRecentDeparturesSearchesApiActionTypes.LoadFailure;

  constructor(public payload: { error: any }) {}
}

export type AirportsRecentDeparturesSearchesApiActionsUnion = LoadSuccess
  | LoadFailure;