import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MfaActions } from 'app/profile/models/mfa';
import { PaymentMethod } from 'app/profile/models/payment-method';
import { PaymentMethodsActions } from 'app/profile/store/actions';
import * as fromProfile from 'app/profile/store/reducers';
import { PaymentComponent } from 'app/shared/components/payment/payment.component';
import { TwoStepSecurityModal } from 'app/shared/containers/two-step-security/two-step-security-modal';
import { CreditCardHelper } from 'app/shared/helpers/credit-card.helper';
import { GeneralHelper } from 'app/shared/helpers/general.helper';
import { SanitizerHelper } from 'app/shared/helpers/sanitizer.helper';
import { PaymentInformation } from 'app/shared/models/payment-information';
import * as fromRoot from 'reducers';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'add-payment-method',
  styleUrls: ['add-payment-method.scss'],
  templateUrl: 'add-payment-method.html'
})
export class AddPaymentMethodComponent implements OnInit , OnDestroy {
  @ViewChild(PaymentComponent, { static: false }) paymentDetails;
  @Input() requestMFA = false;
  @Input() userData;
  paymentInformation = new PaymentInformation({
    billingAddressRequired: true
  });
  user: any;
  userTools: any[];
  private ngUnsubscribe = new Subject<void>();
  error = '';
  loading = false;
  addCardSuccess$ = this.store.select(fromProfile.getPaymentMethodsSuccess);
  addCardError$ = this.store.select(fromProfile.getPaymentMethodsError);
  icons = { faSpinner };

  constructor(
    public activeModal: NgbActiveModal,
    private generalHelper: GeneralHelper,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private store: Store<fromRoot.State>,
    private sanitizerHelper: SanitizerHelper
  ) {}

  ngOnInit() {
    this.store.dispatch(new PaymentMethodsActions.Reset());
    this.paymentInformation.firstName = this.sanitizerHelper.decodeValue(this.user.firstName);
    this.paymentInformation.lastName = this.sanitizerHelper.decodeValue(this.user.lastName);
    this.paymentInformation.address.street = this.user.address;
    this.paymentInformation.address.city = this.user.city;
    this.paymentInformation.address.state = this.user.state;
    this.paymentInformation.address.zipCode = this.user.zip ? this.user.zip.toString() : '';
    this.paymentInformation.address.country = this.user.country;
    this.listenForPaymentFailure();
    this.listenForPaymentSuccess();
  }

  listenForPaymentFailure(){
    this.addCardError$.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((error: any) => {
          if (error && error.error_description) {
            this.error = error.error_description
            this.loading = false;
          }

      });
  }

  listenForPaymentSuccess(){
    this.addCardSuccess$.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((response: any) => {
        if (response) {
          this.loading = false;
          this.activeModal.close(true);
        }
      });
  }

  onAddPaymentMethodClick(): void {
    this.loading = true;
    this.error = '';
    this.generalHelper.touchForms([this.paymentDetails.paymentForm, this.paymentDetails.addressForm.addressForm]);
    const card = this.mapCreditCardParams(
      this.paymentDetails.paymentForm.value,
      this.paymentDetails.addressForm.addressForm.value
    );

    if (this.paymentDetails && this.paymentDetails.isValid()) {
      if (this.requestMFA) {
        const twoStepSecurityModal = this.modalService.open(TwoStepSecurityModal, { backdrop : 'static', keyboard : false, size: 'lg' });
        twoStepSecurityModal.componentInstance.userData = this.userData;
        twoStepSecurityModal.componentInstance.action = MfaActions.MFA_PROFILE_UPDATE;
        twoStepSecurityModal.result.then(
          (success) => {
            if (success) { this.addPaymentAction(card); }
          },
          (e) => {
            this.loading = false;
            this.error = this.translateService.instant('errors.mfa_required');
          }
        );
      } else {
        this.addPaymentAction(card);
      }
    } else {
      this.loading = false;
    }
  }

  addPaymentAction(card) {
    this.loading = true;
    this.store.dispatch(new PaymentMethodsActions.Add(card))
  }

  get showIsBusinessCheckbox() {
    return this.userTools.indexOf('travel_manager') !== -1;
  }

  mapCreditCardParams(paymentData, addressData): PaymentMethod {
    return {
      cc_type: CreditCardHelper.getCreditCardType(paymentData.cardNumber).code,
      cc_number: paymentData.cardNumber.replace(/\s/g, ''),
      ccv: paymentData.ccv,
      expiration_month: paymentData.expiration.split('/')[0],
      expiration_year: `20${paymentData.expiration.split('/')[1]}`,
      first_name: paymentData.firstName,
      last_name: paymentData.lastName,
      address: addressData.street,
      zip: addressData.zipCode,
      city: addressData.city,
      country: addressData.country,
      state: addressData.state,
      isWorldpayToken: false,
      is_business: paymentData.isBusiness
    };
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  closeError(){
    this.error = '';
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
