import { Injectable } from '@angular/core';
import { MapService } from 'app/shared/services/map/map.service';
import { MapMarker } from 'app/shared/models/map-marker';
import { DynamicScriptLoaderService } from 'app/shared/services/dynamic-script-load/dynamic-script-load.service';
import { environment } from '@env/environment';
declare var mapkit;

/*
 * 1 foot = 0.3048 meter
 * 1 international nautical mile = 1.1508 statute miles = 1.852 kilometers = .99933 U.S nautical mile (obsolete)
 * 1º latitude = 69.047 statute miles = 60 nautical miles = 111.12 kilometers
 * For longitude the conversion is the same as latitude except the value is multiplied by the cosine of the latitude.
 * WARNING: The number of kilometers spanned by a LONGITUDE range varies based on the current latitude. For example, one degree of
 * longitude spans a distance of approximately 111 kilometers (69 miles) at the equator but shrinks to 0 kilometers at the poles.
 */
@Injectable({
  providedIn: 'root'
})
export class MapkitService implements MapService {
  KM_TO_MILES_MULTIPLIER = 0.621371;
  DELTA_TO_STATUTE_MILES_MULTIPLIER = 69.047;
  mercadorRadius = 85445659.44705395;
  mercadorOffset = 268435456;
  markerWidth = 0.00036;
  markerHeight = 0.00036;
  mapBuffer = 0.01;

  private intendedLat: number;
  private intendedLng: number;
  private intendedRadius: number;
  // buildMap triggers 2 region changes, with these 2 flags we make sure we dont fire api request from them
  private initialRegionChange = false; // This flag indicates if the first region change has occurred
  private ignoreRegionChange: number = 1;

  constructor(
    private scriptLoader: DynamicScriptLoaderService
  ) {}

  initialize() {
    return this.scriptLoader.load('mapkit').then((resp) => {
      if (resp[0].status !== 'Already Loaded') {
        mapkit.init({
          authorizationCallback: (done: Function) => {
            done(environment.mapkitJWT);
          }
        });
      }
    }).catch(error => console.log(error, 'error'));
  }

  buildMap(lat: number, lng: number, layer, radius: number = null) {
    this.intendedLat = lat;
    this.intendedLng = lng;
    this.intendedRadius = radius;
    return new mapkit.Map('map', {
      center: new mapkit.Coordinate(lat, lng),
      showsCompass: false,
      showsMapTypeControl: false,
      cameraZoomRange: new mapkit.CameraZoomRange(700, 139173) // min radius: 0.25mi; max radius: 50mi
    });
  }

  isSamePosition(position1, position2) {
    return position1.latitude === position2.latitude
      && position1.longitude === position2.longitude
      && position1.radius === position2.radius;
  }

  setCenter(map: any, latitude: number, longitude: number, animate: boolean, radius: number = null) {
    const currentPosition = this.getMapPosition(map);
    if (!this.isSamePosition(currentPosition, { latitude, longitude, radius })) {
      const center = new mapkit.Coordinate(latitude, longitude);
      let span = new mapkit.CoordinateSpan(0.1, 0.1);

      if (radius) {
        this.intendedRadius = radius;
        const radiusSpan = this.getSpanFromRadius(map, radius);
        if (radiusSpan) {
          span = radiusSpan;
        }
      }

      this.ignoreRegionChange++;
      this.intendedLat = latitude;
      this.intendedLng = longitude;
      map.setRegionAnimated(new mapkit.CoordinateRegion(center, span), animate);
    }
  }

  getCenter(map: any) {
    return map.center;
  }

  drawPolygons(map: any, polygons: any[]) {
    const style = new mapkit.Style({
      strokeColor: '#F00',
      strokeOpacity: .2,
      lineWidth: 2,
      lineJoin: 'round',
      lineDash: [2, 2, 6, 2, 6, 2]
    });

    polygons.forEach((polygon) => {
      if (polygon.geoInfo) {
        if (polygon.geoInfo && polygon.geoInfo.coordinates) {
          polygon.geoInfo.coordinates.forEach((shape) => {
            this.drawPolygon(map, shape, style);
          });
        }
      }
    });
  }

  drawPolygon(map: any, polygon: any[], style: any) {
    const points = polygon.map((point) => {
      return new mapkit.Coordinate(point[1], point[0]);
    });
    const rectangle = new mapkit.PolygonOverlay([points], { style });
    map.addOverlay(rectangle);
  }

  removePolygons(map: any) {
    map.removeOverlays(map.overlays);
  }

  addMarker(map: any, marker: MapMarker, onClick: any, popup: any) {
    const factory = () => {
      const el = document.createElement('div');
      el.className = 'marker';
      el.innerHTML = marker.text;
      return el;
    };

    let callout = {};
    if (popup) {
      const calloutElementForAnnotation = () => {
        this.ignoreRegionChange++;
        return popup;
      };
      callout = { calloutElementForAnnotation: calloutElementForAnnotation.bind(this) };
    }
    const mapMarker = new mapkit.Annotation(new mapkit.Coordinate(marker.latitude, marker.longitude), factory, { callout });
    mapMarker.getElement = () => {
      return mapMarker.element;
    };

    mapMarker.addEventListener('select', onClick);

    map.addAnnotation(mapMarker);

    return mapMarker;
  }

  removeMarkers(map: any) {
    map.removeAnnotations(map.annotations);
  }

  getRadius(map: any) {
    const center = this.getCenter(map);
    const latitudeMiles = this.latDeltaToMiles(map.region.span.latitudeDelta);
    const longitudeMiles = this.lngDeltaToMiles(center.latitude, map.region.span.longitudeDelta);

    return Math.min(latitudeMiles, longitudeMiles) / 2; // we use the min between the width and the height of the map
  }

  setRadius(map: any, radius: number, animate: boolean = true, lat: number = null, lng: number = null) {
    this.intendedRadius = radius;
    const span = this.getSpanFromRadius(map, radius);
    this.ignoreRegionChange++;
    const center = lat && lng ? new mapkit.Coordinate(lat, lng) : this.getCenter(map);
    if (lat && lng) {
      this.intendedLat = lat;
      this.intendedLng = lng;
    }
    if (span && center.latitude !== 0 && center.longitude !== 0) {
      map.setRegionAnimated(new mapkit.CoordinateRegion(center, span), animate);
    }
  }

  private getSpanFromRadius(map: any, radius: number) {
    const center = this.getCenter(map);
    let span = null;

    const diameter = radius * 2;
    // Use a bigger radius for the view because the height and the width are usually different
    // If we use a square 'span' the view will show just a portion of it
    // This is in order to keep all the hotels inside the mapview
    let latMiles = this.latDeltaToMiles(map.region.span.latitudeDelta); // N-S distance
    let lngMiles = this.lngDeltaToMiles(center.latitude, map.region.span.longitudeDelta); // E-W distance
    // On initialization these values are not set
    if (latMiles && lngMiles) {
      const scalingFactor = Math.max(latMiles, lngMiles) / Math.min(latMiles, lngMiles);
      if (latMiles > lngMiles) {
        latMiles = diameter * scalingFactor;
        lngMiles = diameter;
      } else {
        latMiles = diameter;
        lngMiles = diameter * scalingFactor;
      }
      const latDelta = this.latMilesToDelta(latMiles);
      const lngDelta = this.lngMilesToDelta(center.latitude, lngMiles);
      span = new mapkit.CoordinateSpan(latDelta, lngDelta);
    }
    return span;
  }

  extendBounds(map: any, markers: MapMarker[]) {
    const latitudes = markers.map(x => x.latitude);
    const longitudes = markers.map(x => x.longitude);
    const westLng = Math.min(...longitudes) - this.mapBuffer;
    const eastLng = Math.max(...longitudes) + this.mapBuffer;
    const northLat = Math.max(...latitudes) + this.mapBuffer;
    const southLat = Math.min(...latitudes) - this.mapBuffer;
    const boundingRegion = new mapkit.BoundingRegion(northLat, eastLng, southLat, westLng);

    this.ignoreRegionChange++;
    map.setRegionAnimated(boundingRegion.toCoordinateRegion(), true);
  }

  setZoomEnabled(map: any, enabled: boolean) {
    map.isZoomEnabled = enabled;
  }

  setZoomLevel(map: any, level: number) {
    // FIXME: For now we skip implementation for Mapkit until we can figure out how to use zoom levels.
  }

  showScale(map: any) {
    map.showsScale = mapkit.FeatureVisibility.Visible;
  }

  onLocationChanged(map: any, fn: any) {
    const f = (x) => {
      if (!this.initialRegionChange) {
        this.initialRegionChange = true;
        if (this.intendedRadius) {
          setTimeout(() => this.setRadius(map, this.intendedRadius, true, this.intendedLat, this.intendedLng), 100);
        }
      } else {
        if (this.ignoreRegionChange < 1) {
          const pos = this.getMapPosition(map);
          if (pos.latitude !== 0 && pos.longitude !== 0) {
            fn(this.getMapPosition(map));
          }
        } else {
          this.ignoreRegionChange--;
        }
      }
    };
    map.addEventListener('region-change-end', f);
  }

  private getMapPosition(map: any) {
    return {
      ...this.getCenter(map),
      radius: this.getRadius(map)
    };
  }

  // longitude conversion depends on the latitude
  private lngMilesToDelta(latitude, lngMiles) {
    const scalingFactor = Math.abs(Math.cos(2 * Math.PI * latitude / 360.0));
    return lngMiles / (scalingFactor * this.DELTA_TO_STATUTE_MILES_MULTIPLIER);
  }

  // longitude conversion depends on the latitude
  private lngDeltaToMiles(latitude, lngDelta) {
    const scalingFactor = Math.abs(Math.cos(2 * Math.PI * latitude / 360.0));
    return lngDelta * (scalingFactor * this.DELTA_TO_STATUTE_MILES_MULTIPLIER);
  }

  private latMilesToDelta(miles: number): number {
    return miles / this.DELTA_TO_STATUTE_MILES_MULTIPLIER;
  }

  private latDeltaToMiles(delta: number): number {
    return delta * this.DELTA_TO_STATUTE_MILES_MULTIPLIER;
  }

  private milesToMeters(miles) {
    return miles / this.KM_TO_MILES_MULTIPLIER / 1000;
  }

  // NOTE: This is far from exact. It's an aproximation and does not take into account the view size
  // private getCameraDistanceFromRadius(x: number): number {
  //   return (136389 * x + 27) / 49;
  // }

  buildCluster(map: any, number: number, mapMarkers: any[], clickCallback, getMarkersCallback, sortBy) { }
  removeLayer() { }
  setInteractive(map: any, isInteractive: boolean, options?: any) { }
  drawMarkersWithoutClustering(map: any, mapMarkers: any, getMarkerCallback) { }
}
