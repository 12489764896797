<loading-indicator *ngIf="loading"></loading-indicator>
<ng-container *ngIf="!loading">
  <header>
    <div class="container-fluid">
      <nav class="navbar navbar-expand-lg">
        <a class="navbar-brand" routerLink="/">
          <img [src]="logo" class="d-inline-block align-top img-fluid header-logo" alt="" />
        </a>
      </nav>
    </div>
  </header>
  <main>
    <div class="landing-content" [style.background-image]="'url('+backgroundUrl+')'">
      <div class="row m-0">
        <div class="col p-3">
          <h1>{{ (isWhiteLabelEnabled ? title : 'landing_page.disabled.title') | translate }}</h1>
        </div>
      </div>
      <div class="row m-0">
        <div class="col col-md-6">
          <p class="secondary-content">{{ (isWhiteLabelEnabled ? p1Content : 'landing_page.disabled.content') | translate }}</p>
          <p *ngIf="isWhiteLabelEnabled" class="secondary-content" [innerHtml]="p2Content | translate"></p>
          <div *ngIf="isWhiteLabelEnabled" class="text-center">
            <a class="btn btn-outline-primary text-center external-link" href="{{ linkUrl | translate }}">{{ buttonText | translate }}</a>
          </div>
          <p *ngIf="!isWhiteLabelEnabled" class="secondary-content">{{ 'landing_page.refundable' | translate }}</p>
        </div>
      </div>
    </div>
    <div class="d-md-none pt-3">
      <img src="/angular_assets/images/white-label/landing/id90-{{airlineCode}}-landing-mobile.svg" width="100%" />
    </div>
  </main>
</ng-container>
