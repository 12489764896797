import {
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { environment } from '@env/environment';
import { faChevronLeft, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { LogoutComponentActions, ThirdPartyActions } from 'app/auth/store/actions';
import * as fromAuth from 'app/auth/store/reducers';
import { ThirdPartiesFactory } from 'app/auth/strategies/third-parties.factory';
import { ChangePasswordComponent } from 'app/profile/components/change-password/change-password.component';
import { DeleteProfileModal } from 'app/profile/components/delete-profile/delete-profile-modal';
import { MFAHelper } from 'app/profile/helpers/mfa.helper';
import { MfaActions } from 'app/profile/models/mfa';
import { Socials } from 'app/profile/models/third-party';
import { TwoStepSecurityModal } from 'app/shared/containers/two-step-security/two-step-security-modal';
import { PhoneNumberHelper } from 'app/shared/helpers/phone-number.helper';
import { featureFlags } from 'app/shared/models/featureFlags';
import { Modal } from 'app/shared/models/modal.model';
import { DateAgoPipe } from 'app/shared/pipes/date/date-ago.pipe';
import { ConfirmationDialogService } from 'app/shared/services/confirmation-dialog/confirmation-dialog.service';
import { DynamicScriptLoaderService } from 'app/shared/services/dynamic-script-load/dynamic-script-load.service';
import { FeatureFlagsService } from 'app/shared/services/featureFlags/featureFlags.service';
import { SessionService } from 'app/shared/services/session/session.service';
import { ModalActions } from 'app/shared/store/actions';
import * as fromShared from 'app/shared/store/reducers';
import { combineLatest, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import * as fromRoot from '/reducers';

@Component({
  selector: 'login-and-security',
  templateUrl: './login-and-security.html',
  styleUrls: ['./login-and-security.scss']
})

export class LoginAndSecurityComponent implements OnInit, OnDestroy {
  @Input() isPhoneNumberConfirmed: string;
  @Input() profileForm;
  @Input() requestMFA = false;
  @Output() showTabs = new EventEmitter();
  @Output() connectProfile = new EventEmitter();
  @Output() logout = new EventEmitter();

  lastPasswordUpdate: string;
  success: string;
  error: string;
  isFacebookConnected: boolean;
  isGoogleConnected: boolean;
  isAppleConnected: boolean;
  memberId: number;
  mfaAdditionalInfo = 'profile.login_and_security.two_step_security.mfa_additional_info';
  userInfo$ = this.store.pipe(select(fromAuth.getUser));
  featureFlags$ = this.store.pipe(select(fromShared.getFeatureFlags));
  thirdPartyPending$ = this.store.pipe(select(fromAuth.getThirdPartyPending));
  thirdPartyError$ = this.store.pipe(select(fromAuth.getThirdPartyError));
  userInfo: any;
  phoneAvailable = false;
  isPhoneValid: boolean;
  isPending = true;
  private ngUnsubscribe = new Subject<void>();
  socials = Socials;
  mfaActive = false;
  googleId = environment.googleContactsClientID;
  noGoggleSession: boolean = false;
  icons = { faChevronLeft, faTrashAlt };
  showFacebook: boolean = true;
  showApple: boolean = true;
  showGoogle: boolean = true;
  googleClient: any;
  invitedTravelerInfo$ = this.store.pipe(select<any, any>(fromAuth.getInvitedTravelerInfo));
  private appConfig$ = this.store.pipe(select(fromShared.getConfigFlag));


  constructor(
    public translate: TranslateService,
    private modalService: NgbModal,
    private sessionService: SessionService,
    private store: Store<fromRoot.State>,
    private confirmationDialogService: ConfirmationDialogService,
    private dateAgoPipe: DateAgoPipe,
    private thirdPartiesFactory: ThirdPartiesFactory,
    private mfaHelper: MFAHelper,
    private dynamicScriptLoader: DynamicScriptLoaderService,
    private ngZone: NgZone,
    private phoneNumberHelper: PhoneNumberHelper,
    private featureFlagsService: FeatureFlagsService
  ) { }

  ngOnInit() {
    combineLatest([this.thirdPartyError$, this.thirdPartyPending$]).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(([error, pending]) => {
      this.isPending = pending;
      this.error = error?.error_description === 'login_page.third_parties.error_check_account' ? null : error;
    });
    this.loadInfo();
    this.loadAppConfig();
  }

  handlerGoogleSignIn(response) {
    this.ngZone.run(() => {
      if (response){
          this.noGoggleSession = false;
          this.store.dispatch(new ThirdPartyActions.ConnectThirdParty({
          platform: Socials.GOOGLE,
          showModalError: false,
          authToken: response.access_token,
          userInfo: this.userInfo
        }));
      }
    }, err => {
      const data: Modal = {
        title: 'errors.error',
        body: this.translate.instant('login_page.third_parties.error', { platform: 'Google' }),
        modalOptions: { size: 'md' }
      };
      this.store.dispatch(new ModalActions.Show({ data }));
    });
  }

  private loadScripts() {
    this.dynamicScriptLoader.load('apisGoogleGSIClient').then((r) => {
      this.generateGoogleButton();
    }).catch(error => console.log(error, 'error'));
  }

  private generateGoogleButton() {
    this.googleClient = window.google.accounts.oauth2.initTokenClient({
      client_id: this.googleId,
      callback: this.handlerGoogleSignIn.bind(this),
      context: 'signin',
      scope: 'openid profile'
    });
  }

  openTab() {
    this.googleClient.requestAccessToken();
  }

  loadInfo() {
    combineLatest([this.featureFlags$, this.userInfo$]).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(([features, user]) => {
      this.loadScripts();
      if (features && user) {
        this.isFacebookConnected = features.includes(featureFlags.FACEBOOK);
        this.isGoogleConnected = features.includes(featureFlags.GOOGLE);
        this.isAppleConnected = features.includes(featureFlags.APPLE);
        this.userInfo = user;
        this.isPhoneValid = this.phoneNumberHelper.isValid(user.account.homePhone);
        this.mfaActive = this.mfaHelper.isActive(user, features);
        this.setLastPasswordUpdated(this.userInfo.member.password_updated_at);
      }
    });
  }

  setLastPasswordUpdated(date) {
    if (!date) {
      this.lastPasswordUpdate = this.translate.instant('profile.login_and_security.never_updated');
      return;
    }
    this.lastPasswordUpdate = `${this.translate.instant('profile.login_and_security.last_update')} ${this.dateAgoPipe.transform(date)}`;
  }

  connectThirdPartyAccount(platform) {
    if (this.requestMFA) {
      this.openTwoStepSecurityModal(MfaActions.MFA_PROFILE_UPDATE, 'connectThirdPartyAccount', platform);
    } else {
      this.connectThirdPartyAccountAction(platform);
    }
  }

  async connectThirdPartyAccountAction(platform, showModalError = true) {
    if (platform === Socials.GOOGLE) {
      this.noGoggleSession = false;
      return this.openTab();
    }
    try {
      const platformProvider = this.thirdPartiesFactory.getProvider(platform);
      const service = this.thirdPartiesFactory.getService(platform);
      const user = await service.signIn(platformProvider);
      const token = this.thirdPartiesFactory.getToken(user, platform);
      this.store.dispatch(new ThirdPartyActions.ConnectThirdParty({
        platform,
        showModalError,
        authToken: token,
        userInfo: this.userInfo
      }));
    } catch (e) {
      this.showMessage(e.error);
    }
  }

  disconnectThirdPartyAccount(platform) {
    this.confirmationDialogService.confirm({
      dialogSize: 'md',
      title: 'confirmation_modal.are_you_sure',
      message: `profile.login_and_security.${platform.toLowerCase()}_confirm_remove`,
      btnOkText: 'confirmation_modal.yes_please',
      btnCancelText: 'profile.login_and_security.disconnect_confirm_no'
    }).then((confirmed) => {
      if (confirmed) {
        if (this.requestMFA) {
          this.openTwoStepSecurityModal(MfaActions.MFA_PROFILE_UPDATE, 'disconnectThirdPartyAccount', platform);
        } else {
          this.disconnectThirdPartyAccountAction(platform);
        }
      }
    }).catch(() => { });
  }

  disconnectThirdPartyAccountAction(platform, showModalError = true) {
    this.store.dispatch(new ThirdPartyActions.DisconnectThirdParty({ platform, showModalError }));
  }

  confirmPhoneNumber() {

  }

  updatePassword() {
    const modalRef = this.modalService.open(ChangePasswordComponent, { backdrop : 'static', keyboard : false, size: 'lg' });
    modalRef.componentInstance.userData = this.userInfo;
    modalRef.componentInstance.requestMFA = this.requestMFA;
    modalRef.result.then(
      (data) => {
        if (data.confirmLogout) {
          this.logout.emit();
        }
        this.loadInfo();
        this.success = 'mobile.profile.change_password_success';
      },
      () => {
        this.success = null;
      }
    );
  }

  openTwoStepSecurityModal(action: string = '', actionType: string = '', platform: any = null) {
    const twoStepSecurityModal = this.modalService.open(TwoStepSecurityModal, { size: 'lg' });
    twoStepSecurityModal.componentInstance.userData = this.userInfo;
    twoStepSecurityModal.componentInstance.action = action || MfaActions.MFA_ENROLLMENT;
    twoStepSecurityModal.result.then(
      (success) => {
        if (success) {
          if (action === MfaActions.MFA_PROFILE_UPDATE && actionType === 'connectThirdPartyAccount') {
            return this.connectThirdPartyAccountAction(platform, false);
          }
          if (action === MfaActions.MFA_PROFILE_UPDATE && actionType === 'disconnectThirdPartyAccount') {
            return this.disconnectThirdPartyAccountAction(platform, false);
          }

          this.loadInfo();
        }
      },
      () => {
        if (action === MfaActions.MFA_PROFILE_UPDATE) {
          this.error = this.translate.instant('errors.mfa_required');
        }
      }
    );
  }

  deleteAccount() {
    const deleteProfileModal = this.modalService.open(DeleteProfileModal, { size: 'lg' });
    deleteProfileModal.componentInstance.memberId = this.memberId;
    deleteProfileModal.result.then((data) => {
      if (!data) {
        return;
      }
      this.store.dispatch(new LogoutComponentActions.Logout());
    }).catch((err) => { });
  }

  showMessage(error: any, customError: boolean = false) {
    const data: Modal = {
      title: 'errors.error',
      bodyTranslationKey: error ? (customError ? error : (error.error_description ? error.error_description :  'errors.try_again_later')) : 'errors.try_again_later',
      modalOptions: { size: 'md' }
    };
    this.store.dispatch(new ModalActions.Show({ data }));
  }

  backToTabs() {
    this.showTabs.emit();
  }

  closeAlert() {
    this.error = null;
    this.success = null;
    this.noGoggleSession = false;
  }

  isEligibleTraveler() {
    return this.getEligibleTravelerAirlineCode() != '';
  }

  getEligibleTravelerAirlineCode() {
    let airlineCode = '';
    this.invitedTravelerInfo$.pipe(take(1)).subscribe((invitedTravelerInfo) => {
      if (invitedTravelerInfo && invitedTravelerInfo.invitedTravelerSelected) {
        airlineCode = invitedTravelerInfo.invitedTravelerSelected.airlineCode ? invitedTravelerInfo.invitedTravelerSelected.airlineCode.toLowerCase() : '';
      }
    });
    return airlineCode;
  }

  private loadAppConfig() {
    this.appConfig$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((appConfig) => {
      if (!appConfig) return
      const isThirdPartyLoginEnabled = this.featureFlagsService.isFeatureEnabled(featureFlags.THIRD_PARTY_LOGIN);
      this.showApple = appConfig?.login_apple_enabled === '1' && isThirdPartyLoginEnabled;
      this.showFacebook = appConfig?.login_facebook_enabled === '1' && isThirdPartyLoginEnabled;
      this.showGoogle = appConfig?.login_google_enabled === '1' && isThirdPartyLoginEnabled;
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
