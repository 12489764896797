import { AfterContentChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faEnvelope, faEnvelopeOpen, faEnvelopeOpenText, faMobileAlt, faPhone } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MultifactorAuthenticationActions } from 'app/auth/store';
import * as fromAuth from 'app/auth/store/reducers';
import { MfaActions, MfaMediums } from 'app/profile/models/mfa';
import { GeneralHelper } from 'app/shared/helpers/general.helper';
import { PhoneNumberHelper } from 'app/shared/helpers/phone-number.helper';
import * as fromShared from 'app/shared/store/reducers';
import * as moment from 'moment';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProfileHelper } from '../../helpers/profile.helper';
import { Modal } from '../../models/modal.model';
import { ModalActions } from '../../store/actions';

@Component({
  selector: 'two-step-security-modal',
  templateUrl: './two-step-security-modal.html',
  styleUrls: ['./two-step-security-modal.scss']
})

export class TwoStepSecurityModal implements OnInit, OnDestroy, AfterContentChecked {
  @Input() userData;
  @Input() action;
  @Input() fromLogin = false;
  @Input() showEmail = true;
  expireTime = 10; // Verification code expiration time: 10 minutes
  actions = MfaActions;
  loading = false;
  errorMessage = '';
  isCodeResend = false;
  codeResendSuccess = false;
  enrollBySmsActive: boolean;
  modal$ = this.store.pipe(select(fromShared.getData));
  sendCodePending$ = this.store.pipe(select(fromAuth.sendCodePending));
  sendCodeError$ = this.store.pipe(select(fromAuth.sendCodeFailure));
  sendCodeSuccess$ = this.store.pipe(select(fromAuth.sendCodeSuccess));
  verifyCodePending$ = this.store.pipe(select(fromAuth.verifyCodePending));
  verifyCodeError$ = this.store.pipe(select(fromAuth.verifyCodeFailure));
  verifyCodeSuccess$ = this.store.pipe(select(fromAuth.verifyCodeSuccess));
  enrollPending$ = this.store.pipe(select(fromAuth.enrollPending));
  enrollSuccess$ = this.store.pipe(select(fromAuth.enrollSuccess));
  dismissModalPending$ = this.store.pipe(select(fromAuth.dismissModalPending));
  dismissModalError$ = this.store.pipe(select(fromAuth.dismissModalFailure));
  dismissModalSuccess$ = this.store.pipe(select(fromAuth.dismissModalSuccess));
  attempts$ = this.store.pipe(select(fromAuth.attempts));
  lastAttemptDate$ = this.store.pipe(select(fromAuth.lastAttemptDate));
  airlines$ = this.store.pipe(select(fromShared.getAirlines));
  showVerifyInsteadButton = false;
  showResendCodeButton = true;
  isPhoneValid: boolean;
  isMaxAttempts = false;
  private ngUnsubscribe = new Subject<void>();
  readonly modalTabs = {
    ENROLL_TEMPLATE: 'enrollTemplate',
    VERIFY_CODE_TEMPLATE: 'verifyCodeTemplate',
    SELECT_VERIFY_CODE_TEMPLATE: 'selectVerifyCodeTemplate'
  };
  template = this.modalTabs.SELECT_VERIFY_CODE_TEMPLATE;
  readonly MAX_ATTEMPTS = 3;
  readonly ATTEMPTS_RESET = 30;
  icons = { faEnvelope, faPhone, faEnvelopeOpenText, faEnvelopeOpen, faMobileAlt };

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    public phoneNumberHelper: PhoneNumberHelper,
    private store: Store,
    private translateService: TranslateService,
    public profileHelper: ProfileHelper,
    private ref: ChangeDetectorRef,
    private router: Router
  ) { }

  ngOnInit() {
    this.store.dispatch(new MultifactorAuthenticationActions.ResetSendCode());
    this.isPhoneValid = this.strictValidation();
    this.showVerifyInsteadButton = this.isPhoneValid;
    this.showModal();
    this.listenSubscriptions();
  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  showModal() {
    if (this.action === MfaActions.MFA_ENROLLMENT) {
      this.action = MfaActions.MFA_ENROLLMENT;
      this.template = this.modalTabs.ENROLL_TEMPLATE;
      return;
    }
    this.sendCodeAutomatically();
  }

  sendCodeAutomatically() {
    if (this.userData.account.mfaEmail && !this.isPhoneValid) { this.sendCodeByEmail(); }
  }

  dismiss() {
    this.store.dispatch(new MultifactorAuthenticationActions.ResetSendCode());
    this.activeModal.dismiss(true);
    if (this.fromLogin) {
      this.router.navigate(['/logout']);
    }
    if (this.action === MfaActions.MFA_NEW_DEVICE) {
      this.modalService.dismissAll();
    }
  }

  switchMethod() {
    this.enrollBySmsActive = !this.enrollBySmsActive;
    if (this.enrollBySmsActive) {
      return this.sendCodeBySMS();
    }
    return this.sendCodeByEmail();
  }

  sendCodeByEmail() {
    this.codeResendSuccess = true;
    this.errorMessage = '';
    this.enrollBySmsActive = false;
    this.loading = true;
    const params = {
      action: this.action
    };
    this.store.dispatch(new MultifactorAuthenticationActions.SendCodeByEmail({ params }));
  }

  sendCodeBySMS() {
    this.codeResendSuccess = true;
    this.errorMessage = '';
    this.enrollBySmsActive = true;
    this.loading = true;
    const params = {
      action: this.action
    };
    this.store.dispatch(new MultifactorAuthenticationActions.SendCodeBySMS({ params }));
  }

  verifyCode(value) {
    this.codeResendSuccess = false;
    this.errorMessage = '';
    this.loading = true;
    const params = {
      verificationCode: value,
      medium: this.enrollBySmsActive ? MfaMediums.SMS : MfaMediums.EMAIL,
      action: this.action
    };
    this.store.dispatch(new MultifactorAuthenticationActions.VerifyCode({ params }));
  }

  resendCode() {
    this.errorMessage = '';
    if (this.enrollBySmsActive) {
      this.sendCodeBySMS();
    } else {
      this.sendCodeByEmail();
    }
  }

  listenSubscriptions() {
    this.listenPending();
    this.listenErrors();
    this.listenSuccess();
    this.listenSendAttempts();
    this.emailIsValid();
  }

  async listenSendAttempts() {
    combineLatest([this.attempts$, this.lastAttemptDate$]).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([attempts, lastAttemptDate]) => {
        if (lastAttemptDate && moment() > moment(lastAttemptDate).add(this.ATTEMPTS_RESET, 'minutes')) {
          this.store.dispatch(new MultifactorAuthenticationActions.Reset());
          return;
        }
        if (attempts >= this.MAX_ATTEMPTS) {
          this.isMaxAttempts = true;
          this.showVerifyInsteadButton = false;
          this.showResendCodeButton = false;
          this.template = this.modalTabs.VERIFY_CODE_TEMPLATE;
        }
      });
  }

  listenPending() {
    combineLatest([this.sendCodePending$, this.verifyCodePending$, this.enrollPending$, this.dismissModalPending$]).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((results) => {
      this.loading = results[0] || results[1] || results[2] || results[3];
    });
  }

  listenErrors() {
    combineLatest([this.sendCodeError$, this.dismissModalError$]).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((results) => {
      const error = results[0] || results[1];
      if (error) {
        this.errorMessage = this.translateService.instant(`errors.${error.message}`);
      }
    });
    this.verifyCodeError$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (error) => {
      if (error) {
        if (error.remaining_attempts === 0) {
          await this.modalService.dismissAll();
          const data: Modal = {
            bodyTranslationKey: 'mfa.verify_account.account_locked',
            modalOptions: { size: 'lg' }
          };
          this.store.dispatch(new ModalActions.Show({ data }));
          return this.router.navigate(['logout']);
        }
        this.errorMessage = this.translateService.instant(
          `mfa.verify_account.${error.message}`,
          { remaining_attempts: error.remaining_attempts }
        );
      }
    });
  }

  listenSuccess() {
    this.sendCodeSuccess$.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((success) => {
      if (success) {
        this.template = this.modalTabs.VERIFY_CODE_TEMPLATE;
        if (this.isCodeResend) {
          this.codeResendSuccess = true;
          this.isCodeResend = false;
        }
      }
    });

    this.verifyCodeSuccess$.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((success) => {
      if (success) {
        this.store.dispatch(new MultifactorAuthenticationActions.ResetAttempts());
        if (this.action === MfaActions.MFA_ENROLLMENT) {
          this.errorMessage = '';
          const params = {
            mfaEmail: 1,
            medium: this.enrollBySmsActive ? MfaMediums.SMS : MfaMediums.EMAIL
          };
          this.store.dispatch(new MultifactorAuthenticationActions.Enroll({ params }));
        } else {
          this.activeModal.close(true);
        }
      }
    });

    this.enrollSuccess$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((success) => {
      if (success) {
        this.activeModal.close(true);
      }
    });

    this.dismissModalSuccess$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((success) => {
      if (success) {
        this.activeModal.close(true);
      }
    });
  }

  getPhoneNumberString(hide = false) {
    return this.phoneNumberHelper.formatPhoneNumberToString(this.userData.account.homePhone, !!this.fromLogin || hide);
  }

  getEmailString(hide = false) {
    return this.profileHelper.formatEmail(this.userData.account.personalEmail, !!this.fromLogin || hide);
  }

  private strictValidation(): boolean {
    const phoneObj = this.phoneNumberHelper.phoneObject(this.userData.account.homePhone);
    if (!phoneObj)  { return false }
    return this.phoneNumberHelper.isValid(phoneObj.number.number, {
      countryCode: phoneObj.number?.country || 'US'
    });
  }

  emailIsValid() {
    this.airlines$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((airlines) => {
      if (airlines) {
        const blockedDomains = GeneralHelper.getBlockDomains(airlines, this.userData.account.airlineCode);
        const invalidEmail = blockedDomains.find((blockedDomain) => {
          const userDomain = this.userData.account.personalEmail.split('@')[1];
          return blockedDomain === userDomain
        });
        this.showEmail = !invalidEmail;
      }
      });
    }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
