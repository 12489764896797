import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faTicketAlt } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { FlightTripReservation } from 'app/trips/models/flight-trip';
import { GeneralHelper } from 'app/shared/helpers/general.helper';
import { UcwordsPipe } from 'app/shared/pipes/ucwords/ucwords.pipe';
import { getAirportByCode } from 'app/shared/store/reducers';
import * as moment from 'moment';
import { take } from 'rxjs/operators';

@Component({
  selector: 'flights-trips-card',
  templateUrl: './trips-flights-card.component.html',
  styleUrls: ['./trips-flights-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TripsFlightsCardComponent implements OnInit {
  @Input() flight: FlightTripReservation;
  @Output() viewTripClicked = new EventEmitter();
  tripTypeLabel;
  defaultImage = 'angular_assets/images/trips/my-trips-flight.jpg';
  cityImage: string;
  actualYear = moment().year();
  shouldDisplayYear = false;
  flightDate: string;
  icons = { faTicketAlt };

  constructor(
    private store: Store,
    private ucwordsPipe: UcwordsPipe,
    public generalHelper: GeneralHelper,
  ) {}

  ngOnInit() {
    if (this.flight.ticketingType === 'COBUS') {
      this.tripTypeLabel = 'mobile.flights.labels.cobus';
    } else {
      this.tripTypeLabel = `mobile.flights.labels.${this.flight.tool_key}`;
    }
    this.setArrivalCityImage();
    this.shouldDisplayYear = this.generalHelper.compareTwoYears(this.flight.flightDate, this.actualYear);
    this.flightDate = this.generalHelper.formatDateOnYear(this.flight.flightDate, this.shouldDisplayYear);
  }

  setArrivalCityImage() {
    const arrivalCityCode = this.flight.route.split('-')[1];
    this.store.select(getAirportByCode(arrivalCityCode.trim())).pipe(take(1)).subscribe(airport => {
      const city = airport?.city.split(' (')[0];
      if (city) {
        this.cityImage = this.getCityImage(city);
      }
    });
  }

  private getCityImage(city) {
    const cityName = this.ucwordsPipe.transform(city).replace(' ', '_');
    return `//marketing.id90travel.com/email/hotel/city-banners/${cityName}.jpg`;
  }

  renderAllotmentsUsed() {
    if (this.flight.allotmentName != 'Bump Up') {
      return ': ' + this.flight.allotmentsUsed;
    } else {
      return '';
    }
  }
}
