<loading-indicator *ngIf="pendingSuccess"></loading-indicator>
<section *ngIf="!pendingSuccess">
  <div class="container-fluid">
    <div class="row login-form">
      <div class="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-5 offset-lg-1 offset-xl-1 offset-xxl-1 py-3 py-lg-5 mn-4">
        <div *ngIf="!displayLoginWithConnection">
          <h2>{{ 'login_page.welcome_back' | translate }}</h2>
          <div class="secondary-content mb-4">{{ 'login_page.no_account' | translate }}
            <a class="ps-1" routerLink="/create-account">{{ 'login_page.sign_up' | translate }}</a>
          </div>
        </div>
        <div class="mb-2" *ngIf="displayLoginWithConnection">
          <h2>{{ 'login_page.third_parties.connect' | translate: { platform: platformChosen } }}</h2>
          <div class="secondary-content">{{ 'login_page.third_parties.account_not_found' | translate: { platform: platformChosen } }}</div>
          <div class="secondary-content mb-4">{{ 'login_page.no_account' | translate }}
            <a class="ps-1" routerLink="/create-account">{{ 'login_page.sign_up' | translate }}</a>
          </div>
        </div>
        <login-form
          *ngIf="!loading"
          (submitted)="login($event)"
          (closeAlert)="closeAlert()"
          (thirdPartiesLogin)="thirdPartiesLogin($event)"
          (loginAndConnectAccount)="loginAndConnectAccount($event)"
          (loginSaml)="samlLogin($event)"
          [pending]="pending$ | async"
          [message]="message$ | async"
          [error$]="error$"
          [employeeNumberDefault]="employeeNumber"
          [emailDefault]="email"
          [airlineDefault]="airline"
          [airlines]="airlines"
          [platformChosen]="platformChosen"
          [displayLoginWithConnection]="displayLoginWithConnection"
          [configFlags]="configFlags$ | async"
          [isWhiteLabel]="isWhiteLabelEnabled">
        </login-form>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-5 offset-lg-1 position-relative d-none d-lg-block customer-benefits fadeIn animated" [ngStyle]="showPlaces && {'background-image': 'url(' + randomPlace.imagePath + ')'}">
        <div class="login-location">{{ randomPlace.placeName }}</div>
      </div>
    </div>
  </div>
</section>
<footer-component></footer-component>
