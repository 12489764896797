import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { HotelTrip } from 'app/trips/models/hotel-trip';
import { GeneralHelper } from 'app/shared/helpers/general.helper';

@Component({
  selector: 'hotels-trips-card',
  templateUrl: './hotels-card.html',
  styleUrls: ['./hotels-card.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class HotelsTripsCardComponent implements OnInit {
  @Input() hotel: HotelTrip;
  @Output() viewTripClicked = new EventEmitter();
  actualYear = moment().year();
  shouldDisplayStartYear = false;
  shouldDisplayEndYear = false;
  startDate : string;
  endDate : string;
  defaultImage = 'https://marketing.id90travel.com/web/assets/hotels-generic.png';

  constructor(
    public generalHelper: GeneralHelper
  ) {}

  ngOnInit() {
    this.shouldDisplayStartYear = this.generalHelper.compareTwoYears(this.hotel.startDate, this.actualYear);
    this.shouldDisplayEndYear = this.generalHelper.compareTwoYears(this.hotel.endDate, this.actualYear);
    this.startDate = this.generalHelper.formatDateOnYear(this.hotel.startDate, this.shouldDisplayStartYear);
    this.endDate = this.generalHelper.formatDateOnYear(this.hotel.endDate, this.shouldDisplayEndYear);
  }

  get canShare(): boolean {
    // Will need more logic here to determine shareability.
    return moment(this.hotel.endDate) >= moment().startOf('day');
  }

  useDefaultImageOnError(event: Event) {
  const image = event.target as HTMLImageElement;
    image.src = this.defaultImage;
  }
}
