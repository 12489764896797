<loading-indicator *ngIf="loading" [fullScreen]="false"></loading-indicator>
<div class="invite-elegible-traveler-modal" *ngIf="!loading">
  <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()">

  </button>
  <ngb-alert *ngIf="success" type="success" (closed)="closeAlert()">
    <div [innerHtml]="success"></div>
  </ngb-alert>
  <ngb-alert *ngIf="error" type="danger" (closed)="closeAlert()">
    <div [innerHtml]="error"></div>
  </ngb-alert>
  <div class="modal-header">
    <h3 class="modal-title text-capitalize">{{ 'profile.eligible_travelers.give_access' | translate }}</h3>
  </div>
  <div class="modal-body">
    <p class="secondary-content pb-3">{{ 'profile.eligible_travelers.invite_message' | translate }}.</p>
    <form [formGroup]="inviteEligibleTravelerForm">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <input [placeholder]="'mobile.checkout.traveler.email' | translate" type="email" class="form-control" formControlName="email">
            <div class='error-message' *ngIf="email.errors !== null && (email.touched)"
              [innerHtml]="formErrorsHelper.errorMessage(email, ('mobile.checkout.traveler.email' | translate))">
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-12 text-end">
        <button type="button" class="btn btn-primary gtm-submit-co-traveler me-3" (click)="sendInvitation()">
          {{ 'profile.eligible_travelers.invite_button' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
