import { Component, Input } from '@angular/core';
import { UsersWithOutImages } from 'app/shared/components/avatar-circle/avatar-circle.component';

export interface CoTravelerItinerarySummary {
  image: string;
  city: string;
  name?: string;
  arrivalDate: string;
  departureDate?: string;
  ownerName?: string;
  ownerEmail?: string;
  ownerAvatar?: UsersWithOutImages;
}

@Component({
  selector: 'co-traveler-itinerary-summary',
  templateUrl: './co-traveler-itinerary-summary.html',
  styleUrls: ['./co-traveler-itinerary-summary.scss']
})

export class CoTravelerItinerarySummaryComponent {
  @Input() itinerary: CoTravelerItinerarySummary;
  @Input() referrer: string;
  @Input() storyText: string = '';
  constructor() {}
}
