<div class="position-relative" *ngIf="!loading">
  <loading-indicator *ngIf="recentSearchesPending || trendingDestinationsPending" [fullScreen]="false"></loading-indicator>
  <form *ngIf="!recentSearchesPending && !trendingDestinationsPending"
    [formGroup]="hotelSearchForm"
    class="hotel-search-form"
    autocomplete="off"
    data-cy="search-form">
    <div class="row">
      <div *ngIf="showLocationSearch" [ngClass]="{'col-lg-4 col-md-12 me-0 filter': displayInLine, 'col-md-12 mb-3': !displayInLine}" data-test="destination" (click)="destinationClicked()">
        <locations-autocomplete-component
          #locationsAutocomplete
          [disabled]="destinationConfig.disabledChangeDestination"
          [form]="hotelSearchForm"
          (locationSelected)="locationSelected($event)"
          (locationChanging)="locationChanging()"
          (onSearchChanged)="onSearchChanged($event)"
          [controlName]="'destination'"
          placeholder="{{ 'lodging.hotels.desination_city_address' | translate }}"
          [trendingDestinations]="trendingDestinations"
          [recentSearches]="recentSearches"
          [label]=""
          [recentDetailsEnable]="false"
          [locationType]="allInclusive ? 'all-inclusive' : 'hotels'"
          [clearInputAndKeepResult]=clearInputAndKeepResult
          [showModalForMobile]="true"
          [hideHeader]="true"
          [alignBardgeLeft]="displayInLine"
          [destination]="selectedDestination"
          [showCurrentLocation]="true">
        </locations-autocomplete-component>
        <div class="error-message" *ngIf="location.touched && !isSearching && location.errors">
          {{ formErrorsHelper.errorMessage(location, ('lodging.hotels.desination_city_address' | translate)) }}
          &nbsp; <!-- prevent pushing content when showing error message  -->
        </div>
      </div>
      <div [ngClass]="{'col-lg-4 col-xl-4 me-0 mt-3 mt-lg-0': displayInLine, 'col-lg-6 col-md-12 with-padding-sm': !displayInLine}" class="col-xs-12">
        <datepicker-range
          inputName="hotelDates"
          [startDate]="dates.get('checkin').value"
          [endDate]="dates.get('checkout').value"
          [minDate]="datePickerConfig.minDate"
          [maxDate]="datePickerConfig.maxDate"
          [placeholderStart]="'lodging.hotels.checkin_label' | translate"
          [placeholderEnd]="'lodging.hotels.checkout_label' | translate"
          [showCalendarIcon]="true"
          [isError]="dates.errors"
          [showModalForMobile]="true"
          [openDatePicker]="openDatePicker"
          (datesSelected)="onDatesSelected($event)"
          [showDemandCalendar]="showDemandCalendar"
          [demandCalendar]="demandCalendar$ | async"
          >
        </datepicker-range>
        <div
          class='error-message'
          *ngIf="dates.touched && dates.errors"
          [innerHtml]="dates.errors.customError | translate">
        </div>
      </div>

      <ng-template let-validate="validate">
        <div *ngIf="validate"> <button
          #guestSelect
          *ngIf="guestValue"
          class="form-control tab-select form-select text-start"
          (click)="onMultipleGuestPress()">
          {{ guestValue.label }}
        </button> </div>
      </ng-template>
      <div  class="col-lg-2 col-md-6 col-xs-12 mt-3 mt-lg-0" [ngClass]="{'with-padding-sm': !displayInLine}">
        <div class="form-floating">
          <select
            *ngIf="guestsOptions"
            class="form-control tab-select form-select with-background"
            formControlName="guests"
            data-cy="guests-select"
            (change)="onGuestsChanged($event.target.value)">
              <option *ngFor="let g of guestsOptions;" [value]="g.value">{{ getGuestOptionLabel(g) }}</option>
          </select>
          <label for="guests">
            {{ 'mobile.hotel_details.guests' | translate }}
          </label>
        </div>
      </div>
      <div class="col d-flex">
        <button
          type="submit"
          class="btn btn-primary mt-3 mt-lg-0 w-100 {{ allInclusive ? 'gtm-all-inclusive-search-btn' : 'gtm-hotel-search-btn' }}"
          (click)="search()"
          data-cy="hotelSearchButton"
          >
          {{ 'lodging.search_form.search__id90_es_ru' | translate }}
        </button>
      </div>
    </div>
    <div class="row pt-3 pt-md-0 mt-4" *ngIf="showBusinessToggle$ | async">
      <div class="col">
        <toggle
          [id]="componentId + '_toggle'"
          [label]="'mobile.business_travel.toggle_label' | translate"
          formControlName="businessTrip">
        </toggle>
        <p class="tertiary-content m-0" *ngIf="(showBusinessToggle$ | async) && hotelSearchForm.value.businessTrip">
          *{{ 'mobile.business_travel.search_disclaimer' | translate }}
        </p>
      </div>
    </div>
    <input type="text" class="d-none" id="latitude" formControlName="latitude">
    <input type="text" class="d-none" id="longitude" formControlName="longitude">
  </form>
  <div class="row">
    <div class="col-12">
      <multiple-guests-section
        #multipleGuestsComponent
        class="multiple-guests-container"
        (formUpdated)="onGuestsUpdated($event)"
        [rooms]="rooms"
        [roomsWithErrors]="multipleGuestsErrors"
        [visible]="showMultipleGuest">
      </multiple-guests-section>
    </div>
  </div>
</div>

<ng-template #editGuestsModalContent let-modal>
  <div class="modal-header">
    <button type="button" class="btn-close" aria-label="Close" (click)="onModalClose()">
    </button>
  </div>
  <div class="modal-body edit-search">
    <div class="row">
      <div class="col-12">
        <multiple-guests-section
          class="multiple-guests-container"
          (formUpdated)="onGuestsUpdated($event)"
          [rooms]="rooms"
          [isOnPopUp]="true"
          [roomsWithErrors]="multipleGuestsErrors"
          [visible]="showMultipleGuest">
        </multiple-guests-section>
      </div>
    </div>
    <div class="row pt-4 pb-0 mb-0">
      <div class="offset-md-4 col-md-4">
        <button
          type="submit"
          class="btn btn-primary w-100 search-btn"
          [ngClass]="{'gtm-all-inclusive-search-btn': allInclusive, 'gtm-hotel-search-btn': !allInclusive}"
          (click)="searchFromPopup()">
          {{ 'mobile.action.update_search' | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
