import { filter, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromAirlines from 'app/shared/store/reducers';
import * as fromRoot from 'reducers';
import { Observable } from 'rxjs';
import { AirlinesService } from 'app/shared/services/airlines/airlines.service';
import { LoginWithJWT } from 'app/auth/store/actions/login-page.actions';
import { LogoutComponentActions } from 'app/auth/store';

@Injectable({
  providedIn: 'root'
})
export class JWTLoginGuard  {
  constructor(
    private store: Store<fromRoot.State>,
    private airlinesService: AirlinesService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.select<any>(fromAirlines.getAirlines).pipe(
      filter(airlines => !!airlines),
      map((airlines) => {
        const isUnsubscribing = route.url.some(segment => segment.path === 'unsubscribe');
        if (route.queryParamMap.get('jwt') && !isUnsubscribing) {
          this.store.dispatch(new LogoutComponentActions.Logout({ logout_freeze: true }));
          const isWhiteLabel = !!this.airlinesService.getWhiteLabelAirlineCode(airlines);
          this.store.dispatch(new LoginWithJWT({
             isWhiteLabel,
             accessToken: route.queryParams.jwt,
             redirect: this.extractJwt(),
             utm_campaign: route.queryParamMap.get('utm_campaign'),
             utm_medium: route.queryParamMap.get('utm_medium'),
             utm_source: route.queryParamMap.get('utm_source')
           }));
          return false;
        }
        return true;
      }));
  }

  private extractJwt(): string {
    const url = new URL(window.location.href);
    url.searchParams.delete('jwt');
    const pathnameWithParamsWithoutJwt = url.pathname + url.search.toString();
    return url.pathname === '/' ? 'search' : pathnameWithParamsWithoutJwt;
  }
}
