import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ParametersHelper {
  parseQueryParams(queryParams) {
    const params = Object.assign({}, queryParams);
    if (params.checkin_date) {
      params.checkin = queryParams.checkin_date;
    }
    if (params.checkout_date) {
      params.checkout = queryParams.checkout_date;
    }
    if (params.guests) {
      if (Array.isArray(params.guests)) {
        params.guests = params.guests[0];
      }
      params['guests[]'] = params.guests.replace(/[[\]]/g, '');
      delete params.guests;
    }
    if (params.room_id) {
      params.rid = params.room_id;
    }
    if (params.hotel_service_id) {
      params.id = params.hotel_service_id;
    }
    if (params.pick_up_location) {
      params.pickupLocation = params.pick_up_location;
    }
    if (params.pick_up_location_id) {
      params.pickupLocationId = params.pick_up_location_id;
    }
    if (params.drop_off_location) {
      params.dropoffLocation = params.drop_off_location;
    }
    if (params.drop_off_location_id) {
      params.dropoffLocationId = params.drop_off_location_id;
    }
    if (params.pick_up_date) {
      params.pickupDate = params.pick_up_date;
    }
    if (params.pick_up_time) {
      params.pickupTime = params.pick_up_time;
    }
    if (params.drop_off_date) {
      params.dropoffDate = params.drop_off_date;
    }
    if (params.drop_off_time) {
      params.dropoffTime = params.drop_off_time;
    }
    if (params.country_of_residence) {
      params.country = params.country_of_residence;
    }
    if (params.age) {
      params.over25 = parseInt(params.age, 10) >= 25 ? 'true' : 'false';
    }
    return params;
  }

  createObjFromURIParams(url) {
    const urlParams = decodeURIComponent(url).split('?')[1] ? decodeURIComponent(url).split('?')[1] : '';
    const uri = decodeURI(urlParams);
    const chunks = uri.split('&');
    let params = Object();

    for (var i = 0; i < chunks.length; i++) {
      var chunk = chunks[i].split('=');
      if (chunk[0].search("\\[\\]") !== -1) {
        if (typeof params[chunk[0]] === 'undefined') { params[chunk[0]] = [chunk[1]]; } 
        else { params[chunk[0]].push(chunk[1]); }
      } else { params[chunk[0]] = chunk[1]; }
    }
    return params;
  }
}
