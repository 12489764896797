<div class="primary-content" *ngIf="title">{{ title }}</div>

<div class="table-responsive">
  <table class="table table-striped table-borderless">
    <thead>
      <tr>
        <th class="text-nowrap w-25 secondary-content" scope="col">{{ 'profile.credits.transaction_date' | translate }}</th>
        <th class="text-nowrap w-25 secondary-content text-end" scope="col">{{ 'profile.credits.amount' | translate }} {{'lodging.payments.stay_info.currency.in_usd' | translate}}</th>
        <th class="text-nowrap w-25 secondary-content text-end" scope="col">{{ 'profile.credits.expiration_date' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let credit of visibleUserCreditHistory">
        <td>{{ credit.time_updated ? (credit.time_updated | amUtc | amDateFormat:'MM/DD/YYYY') : 'N/A' }}</td>
        <td class="text-end" [ngClass]="{ 'text-success': credit.amount > 0, 'text-danger': credit.amount < 0 }">
          {{ credit.amount > 0 ? '+' : '' }}{{ credit.amount | currencyConvert:'USD' }}
        </td>
        <td class="text-end">{{ credit.expiration_date ? (credit.expiration_date | amUtc | amDateFormat:'MM/DD/YYYY') : 'N/A' }}</td>
      </tr>
    </tbody>
  </table>
</div>

<nav aria-label="Page navigation" *ngIf="enablePagination && totalPages > 1">
  <ul class="pagination justify-content-center m-0">
    <li class="page-item" [class.disabled]="page <= 1">
      <button class="page-link border-0" [disabled]="page <= 1" type="button" aria-label="Previous" (click)="page=page-1">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
        </svg>
      </button>
    </li>
    <li class="page-item" style="line-height: 36px;">{{ page }} of {{ totalPages }}</li>
    <li class="page-item" [class.disabled]="page >= totalPages">
      <button class="page-link border-0" [disabled]="page >= totalPages" type="button" aria-label="Next" (click)="page=page+1">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
        </svg>
      </button>
    </li>
  </ul>
</nav>
