<just-for-you-card
[image]="image"
[dates]="dates"
[title]="data.hotel_name"
[destination]="data.city"
[type]="type"
[isAbandonedHotel]="true"
[hotelNightRate]="formatPrice(data.hotel_price)"
[hotelTotalCost]="formatPrice(data.hotel_total_cost)"
(click)="onClicked()"
></just-for-you-card>