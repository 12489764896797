import { environment } from '@env/environment';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AllInclusiveLocation } from 'app/shared/models/all-inclusive-location';

@Injectable({
  providedIn: 'root'
})
export class AllInclusiveService {
  constructor (
    private http: HttpClient) {
  }

  get() {
    return this.http.get<AllInclusiveLocation[]>(`${environment.apiUrl}/api/v1/hotels/all_inclusives.json`, {});
  }
}
