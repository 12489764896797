import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { SeoService } from 'app/shared/services/seo/seo.service';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';

@Injectable()
export class RouterEffects {

  // Set page title and remove meta description
  // meta description is empty by default
  // rel canonical is empty by default
  setDefaultSeo$ = createEffect(()=>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      map((action: RouterNavigationAction) => {
        this.seoService.setTitle('ID90 Travel');
        this.seoService.removeMetaTag('name="description"');
        this.seoService.removeLinkForCanonicalURL();
      })
    ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private seoService: SeoService,
  ) {}

}
