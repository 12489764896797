import { Action } from '@ngrx/store';
import { HotelDetails } from 'app/hotels/models/hotel-details';

export enum HotelDetailsActionTypes {
  Get = '[Hotel Details] Get Hotel Details',
  GetSuccess = '[Hotel Details] Get Hotel Details Success',
  GetFailure = '[Hotel Details] Get Hotel Details Failure',
  SetAmenity = '[Hotel Details] Set Amenity'
}

export class Get implements Action {
  readonly type = HotelDetailsActionTypes.Get;

  constructor(public payload: string) { }
}

export class GetSuccess implements Action {
  readonly type = HotelDetailsActionTypes.GetSuccess;

  constructor(public payload: HotelDetails) { }
}

export class GetFailure implements Action {
  readonly type = HotelDetailsActionTypes.GetFailure;

  constructor(public payload: any) { }
}

export class SetAmenity implements Action {
  readonly type = HotelDetailsActionTypes.SetAmenity;

  constructor(public payload: any) { }
}

export type HotelDetailsActionsUnion = Get | GetSuccess | GetFailure | SetAmenity;
