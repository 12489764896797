import { FormGroup, ValidatorFn } from "@angular/forms"

export interface BaseMissingModal {
    close?: {
        icon: string
        show: boolean,
        logout: boolean
    } | null,
    title? : {
        text: string
        class: string
    } | null,
    body? : {
        html: string,
    } | null
    formFields?: {
        email: {
            show: boolean
            validators: ValidatorFn[],
            value: any
        },
        address: {
            show: boolean
            validators: ValidatorFn[],
            value: any
        },
        phone: {
            show: boolean
            validators: ValidatorFn[],
            value: any
        },
        firstName: {
            show: boolean
            validators: ValidatorFn[],
            value: any
        },
        lastName: {
            show: boolean
            validators: ValidatorFn[],
            value: any
        }

    }
    dontAskAgain: {
        show: boolean
        text: string
    }
}

export enum Mode {
    customerSupportMsg = 'customerSupportMsg',
    phone = 'phone',
    email = 'email'
}