import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'gmail-contacts-modal',
  templateUrl: './gmail-contacts-modal.html',
  styleUrls: ['./gmail-contacts-modal.scss']
})

export class GmailContactsModalComponent implements OnInit {
  @Input() contacts;
  @Input() rewardPrice: number;
  @Input() modalTitle: string;
  @Input() buttonActionText: string;
  @Input() buttonActionTypeText: string;
  @Input() buttonAddActionText: string;
  @Input() buttonActionTypeTextN: string;
  filterContacts = '';

  loading = true;
  selectedAll = false;
  selectedCount = 0;
  icons = { faSearch };

  constructor(
    private activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.loading = false;
  }

  selectUnselectAll() {
    this.contacts.map((contact) => {
      contact.checked = !this.selectedAll;
      return contact;
    });
    this.selectedAll = !this.selectedAll;
    this.updateCount();
  }

  onCheck(contact) {
    contact.checked = !contact.checked;
    this.updateCount();
  }

  updateCount() {
    this.selectedCount = this.contacts.filter(contact => contact.checked).length;
  }

  confirmSelection() {
    this.activeModal.close(this.contacts.filter(contact => contact.checked));
  }

  dismiss() {
    this.activeModal.dismiss(null);
  }
}
