import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HotelBooking } from 'app/hotels/models/hotel-booking';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HotelBookingService {
  constructor (
    private http: HttpClient) {
  }

  book(hotelBooking: HotelBooking): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/api/v1/hotels/reservations.json`,
      hotelBooking.toJsonRequest()
    );
  }

  getReservations(): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/api/v1/hotels/reservations.json`
    );
  }

  getReservation(id): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/api/v1/hotels/itineraries/${id}.json`
    );
  }

  cancelReservation(id, params): Observable<any> {
    params.platform = environment.platform;
    return this.http.delete(
      `${environment.apiUrl}/profile/reservations/lodgings/${id}.json`,
      {
        params
      }
    );
  }

  checkReservation(params): Observable<any>  {
    return this.http.get(`${environment.apiUrl}/api/v1/hotels/check_reservation`, { params ,
      headers: { 'X-Requested-With' : 'XMLHttpRequest' } });
  }

  denyPreReservation(preReservationId): Observable<any>  {
    return this.http.post(
      `${environment.apiUrl}/api/v1/hotels/reservations/denyPreReservation`,
      { pre_reservation_id : preReservationId });
  }

  savePayPalAuthorization(paypalAuthorizationId): Observable<any>  {
    return this.http.post(
      `${environment.apiUrl}/api/v1/paypal_authorizations`,
      { paypal_authorization_id : paypalAuthorizationId }
    );
  }

}
