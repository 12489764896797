<form [formGroup]="addressForm">
  <div class="row">
    <div class="col" *ngIf="showStreet">
      <div class="form-group">
        <locations-autocomplete-component
          [inputModel]="address"
          [showIcon]="true"
          [form]="addressForm"
          [controlName]="'street'"
          [label]="'payment.address_short'"
          (locationSelected)="locationSelected($event)"
          (addressSet)="addressSet($event)"
          [label]=""
          [locationType]="'address'"
          [disabled]="disabledFields?.street"
          [addressMaxLength]="addressMaxLength"
          placeholder="{{ 'payment.address_short' | translate }}"
          #addressStreet>
        </locations-autocomplete-component>
        <div class='error-message'
          *ngIf="street.errors !== null && (street.touched)"
          [innerHtml]="formErrorsHelper.errorMessage(street, ('payment.address_short' | translate))">
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-6" *ngIf="showCity">
      <div class="form-group form-floating">
        <input type="text" class="form-control with-background" formControlName="city"
           [(ngModel)]="address.city" autocomplete="shipping locality" data-cy="addressCity"
           (change)="singleFieldChange($event.target.value,'city')" maxlength="{{ cityMaxLength }}">
        <label for="city">{{ 'payment.city' | translate }}</label>
        <div class='error-message'
          *ngIf="city.errors !== null && (city.touched)"
          [innerHtml]="formErrorsHelper.errorMessage(city, ('payment.city' | translate))">
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6" *ngIf="showZip">
      <div class="form-group form-floating">
        <input type="text" class="form-control with-background" formControlName="zipCode" [(ngModel)]="address.zipCode" autocomplete="shipping postal-code" data-cy="addressZipCode" (change)="singleFieldChange($event.target.value,'zipCode')">
        <label for="zipCode">{{ 'payment.zip' | translate }}</label>
        <div class='error-message'
          *ngIf="zipCode.errors !== null && (zipCode.touched)"
          [innerHtml]="formErrorsHelper.errorMessage(zipCode, ('flights.booking.errors.ZipCodeIncorrect' | translate))">
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-6" *ngIf="showCountry">
      <div class="form-group form-floating">
        <select (change)="getStates()" class="form-control form-select form-select with-background" formControlName="country" [(ngModel)]="address.country" data-cy="addressCountry">
          <option [value]="null">{{'profile.info.select' | translate}}</option>
          <option *ngFor="let country of countries" [value]="country.code">{{country.name}}</option>
        </select>
        <label for="country">{{ 'payment.country' | translate }}</label>
        <div class='error-message'
          *ngIf="country.errors !== null && (country.touched)"
          [innerHtml]="formErrorsHelper.errorMessage(country, ('payment.country' | translate))">
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6" *ngIf="showState && states.length">
      <div class="form-group form-floating">
        <select class="form-control form-select form-select with-background" formControlName="state" [(ngModel)]="address.state" data-cy="addressState" (change)="singleFieldChange($event.target.value,'state')">
          <option [value]="''">{{'profile.info.select' | translate}}</option>
          <option *ngFor="let state of states" [value]="state.code">{{state.name}}</option>
        </select>
        <label for="state">{{ 'payment.state' | translate }}</label>
        <div class='error-message'
          *ngIf="state.errors !== null && (state.touched)"
          [innerHtml]="formErrorsHelper.errorMessage(state, ('payment.state' | translate))">
        </div>
      </div>
    </div>
  </div>
</form>
