import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'app/auth/models/user';
import * as fromAuth from 'app/auth/store/reducers';
import * as fromRoot from 'reducers';
import * as fromShared from 'app/shared/store/reducers';
import { filter, map, skipWhile, tap } from 'rxjs/operators';
import { Observable, combineLatest, of } from 'rxjs';
import moment from 'moment';
import { ChangePasswordComponent } from 'app/profile/components/change-password/change-password.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MFAHelper } from 'app/profile/helpers/mfa.helper';

@Injectable({
  providedIn: 'root'
})

export class PasswordOldCheckGuard  {
  user$ = this.store.pipe(select(fromAuth.getUser));
  appConfig$ = this.store.pipe(select(fromShared.getConfigFlag));
  flags$ = this.store.pipe(select(fromShared.getFeatureFlags));
  flags;
  user: User;
  daysToExpire: number;

  constructor(
    public router: Router,
    private store: Store<fromRoot.State>,
    protected translateService: TranslateService,
    private modalService: NgbModal,
    private mfaHelper: MFAHelper
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return combineLatest([this.appConfig$, this.user$, this.flags$]).pipe(
      skipWhile(([appConfig, user, flags]) => {
        if (!user?.member?.member_id) { return false; }
        return !flags;
      }),
      map(([appConfig, user, flags]) => {
        this.user = user;
        this.flags = flags;
        this.daysToExpire = appConfig?.password_expiration_days || 365;
        return true;
      }),
      tap(() => {
        if (!this.featureIsEnable(this.flags, this.user)) { return of(true); }
        if (this.passwordExpired()) {
          this.updatePassword(this.user, this.flags);
          return of(false);
        }
        return of(true);
      })
    );
  }

  updatePassword(user, flags) {
    if (this.isMfaModalActive()) { return; }
    if (this.modalService.hasOpenModals()) { return; }
    const modalRef = this.modalService.open(ChangePasswordComponent, { backdrop : 'static', keyboard : false, size: 'lg' });
    modalRef.componentInstance.modalTitle = 'mobile.profile.expired_password';
    modalRef.componentInstance.userData = user;
    modalRef.componentInstance.requestMFA = this.mfaHelper.requestMFA(user, flags, true);
    modalRef.componentInstance.passwordExpired = true;
    modalRef.result.then(
      (data) => {
        if (data.confirmLogout) {
          this.router.navigate(['/logout']);
        }
      },
      () => {
        this.router.navigate(['/logout']);
      }
    );
  }

  featureIsEnable(flags: any, user: User): boolean {
    if (!user || user.generic_user || user.call_center_admin || user.account?.restricted || user.cobus_authorization) {
      return false;
    }
    return flags && flags?.includes('new_password');
  }

  passwordExpired() {
    if (!this.user.member.password_updated_at) {
      const diffCreate = moment(new Date()).diff(this.user.account.signupDate, 'days');
      return diffCreate > this.daysToExpire;
    }
    const diffUpdate = moment(new Date()).diff(this.user.member.password_updated_at, 'days');
    return diffUpdate > this.daysToExpire;
  }

  isMfaModalActive() {
    return this.mfaHelper.showMFAModal(this.user, this.flags);
  }
}
