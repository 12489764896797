import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AbandonedHotel } from 'app/shared/models/abandoned-hotel';

@Injectable({
  providedIn: 'root'
})
export class AbandonHotelService {
  constructor (
    private http: HttpClient
  ) {}

  getCardContent(): Observable<AbandonedHotel> {
    return this.http.get<AbandonedHotel>(`${environment.apiUrl}/api/v2/abandon_hotel_card.json`, {});
  }

}
