import { TrendingDestinationsActions, TrendingDestinationsApiActions } from 'app/shared/store/actions';
import { TrendingDestination } from 'app/shared/models/trending-destination';

export interface State {
  destinations: TrendingDestination[] | null;
  pending: boolean;
  error: any | null;
  loaded: boolean;
}

export const initialState: State = {
  destinations: [],
  pending: false,
  error: null,
  loaded: false
};

export function reducer(
  state = initialState,
  action: TrendingDestinationsActions.TrendingDestinationsActionsUnion | TrendingDestinationsApiActions.TrendingDestinationsApiActionsUnion
) {
  switch (action.type) {
    case TrendingDestinationsActions.TrendingDestinationsActionTypes.Load:
      return {
        ...state,
        destinations: [],
        error: null,
        pending: true
      };
    case TrendingDestinationsApiActions.TrendingDestinationsApiActionTypes.LoadSuccess:
      return {
        destinations: action.payload.destinations,
        error: null,
        pending: false,
        loaded: true
      };
    case TrendingDestinationsApiActions.TrendingDestinationsApiActionTypes.LoadFailure:
      return {
        destinations: [],
        error: action.payload.error,
        pending: false,
        loaded: false
      };
    default:
      return state;
  }
}

export const getDestinations = (state: State) => state.destinations;
export const getPending = (state: State) => state.pending;
export const getLoaded = (state: State) => state.loaded;
