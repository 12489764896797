<first-time-login-form
  *ngIf="!emailSent"
  (submitted)="performFirstTimeLogin($event)"
  (canceled)="cancelFirstTimeLogin()">
</first-time-login-form>
<section *ngIf="emailSent" class="first-time-login">
  <div class="row">
    <div class="col-lg-4 col-md-6 col-sm-8 col-xs-12 offset-lg-4 offset-md-3 offset-sm-2 pt-5">
      <div class="row pb-4">
        <div class="col-sm-12">
          <h1 class="primary-content">{{'first_login.check_your_email_title' | translate }}</h1>
        </div>
        <div class="col-sm-12">
          <p>{{'first_login.check_your_email_text_a' | translate }}</p>
        </div>
        <div class="col-sm-12">
          <p>{{'first_login.check_your_email_text_b' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
</section>