
import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromProfile from 'app/profile/store/reducers';
import { LogoutComponentActions } from '../store/actions';
import { take } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { Oauth2Helper } from 'app/shared/helpers/oauth2.helper';
import * as fromShared from 'app/shared/store/reducers';

@Component({
  template: ''
})

export class LogoutComponent implements OnInit {
  userProfile$ = this.store.pipe(select(fromProfile.getUserProfile));
  configFlags$ = this.store.pipe(select(fromShared.getConfigFlag));

  constructor(
    private store: Store<fromProfile.State>,
    private oauth2helper: Oauth2Helper
  ) { }

  ngOnInit() {
    combineLatest([this.userProfile$, this.configFlags$])
    .pipe(take(1))
    .subscribe(([profile, configFlags]) => {
      if (profile && configFlags && this.oauth2helper.inOauth2Login(profile.airlineCode, configFlags)) {
        this.store.dispatch(new LogoutComponentActions.Oauth2Logout({ airlineCode: profile.airlineCode }));
      } else {
        this.store.dispatch(new LogoutComponentActions.Logout());
      }
    });
  }
}
