<div class="flex-header">
  <div class="d-md-block">
    <a href="/">
      <img class="header-logo" src="angular_assets/images/white-logo.png">
    </a>
  </div>
  <div *ngIf="!fromLink" class="text-sm-center">
    <h1 class="airline text-capitalize">{{ 'flex.title' | translate : {airlineName: airlineName } }},</h1>
    <div class="secondary-text">{{ 'flex.intro_text' | translate }}</div>
  </div>
  <div *ngIf="fromLink" class="text-center generic-header">
    <h1>{{ 'flex.generic_screen.title' | translate }}</h1>
    <div>{{ 'flex.generic_screen.intro_paragraph_1' | translate }}</div>
    <div>{{ 'flex.generic_screen.intro_paragraph_2' | translate }}</div>
  </div>
  <div class="background"></div>
</div>
<section id="flex-intro" class="flex-content text-center">
  <div class="container">
    <h2 *ngIf="!fromLink" class="d-none d-md-block">{{ 'flex.introducing' | translate }}</h2>
    <ng-container *ngTemplateOutlet="downloadApp"></ng-container>
  </div>
  <div class="container-fluid flex-section-two">
    <div class="row">
      <div class="col-12 col-md-4 offset-md-2 text-start pe-0 pe-md-5 py-5">
        <div class="flex-section-header d-none d-md-block mb-4">
          <div class="subtitle">{{ 'flex.intro_subtitle' | translate }}</div>
          <h2>{{ 'flex.intro_title' | translate }}</h2>
        </div>
        <p class="secondary-content">{{ 'flex.intro_paragraph_1' | translate }}</p>
        <p class="secondary-content" [innerHTML]="'flex.intro_paragraph_2' | translate"></p>
      </div>
      <div class="col col-md-6 d-none d-md-block p-0">
        <img class="img-fluid w-100" src="angular_assets/images/flex/FLEX_inspo_image.jpg">
      </div>
    </div>
  </div>
</section>
<section id="flex-app-features">
  <div *ngIf="!fromLink" class="container">
    <div class="row align-items-center">
      <div class="col-md-5 col">
        <div class="flex-section-header">
          <div class="subtitle">{{ 'flex.get_app_subtitle' | translate }}</div>
          <h2>{{ 'flex.get_app_title' | translate }}</h2>
        </div>
        <div class="feature row text-center">
          <div class="col-3 text-center">
            <img src="angular_assets/images/flex/selling-point-1-icon.svg">
          </div>
          <div class="col-8 secondary-content text-start ps-3">{{ 'flex.get_app_paragraph_1' | translate }}</div>
        </div>
        <div class="feature row">
          <div class="col-3 text-center">
            <img src="angular_assets/images/flex/selling-point-2-icon.svg">
          </div>
          <div class="col-8 secondary-content text-start ps-3">{{ 'flex.get_app_paragraph_2' | translate }}</div>
        </div>
        <div class="feature row">
          <div class="col-3 text-center">
            <img src="angular_assets/images/flex/selling-point-3-icon.svg">
          </div>
          <div class="col-8 secondary-content text-start ps-3">{{ 'flex.get_app_paragraph_3' | translate }}</div>
        </div>
      </div>
      <div class="col-md-6 d-none d-md-block text-center">
        <img [offset]="200" [lazyLoad]="'angular_assets/images/homepage/phones-500.png'" class="img-fluid floatUpDown" [alt]="'notification_app.app_name' | translate" />
      </div>
    </div>
  </div>
</section>
<section id="members">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-md-2 offset-md-2 col-4"><img class="value-image" src="angular_assets/images/icons/fast delivery@4x.png" alt=""></div>
      <div class="col-7 col-md-4">{{ 'flex.members_banner' | translate }}</div>
    </div>
  </div>
</section>
<section *ngIf="!fromLink" id="reviews" class="d-none d-md-block">
  <div class="text-center">
    <div class="subtitle">{{ 'home_page.testimonials_title' | translate }}</div>
    <h2>{{ 'flex.reviews_title' | translate }}</h2>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-6 col-lg-4">
      <div class="card m-3 p-4 secondary-content">
        "{{ 'flex.reviews_1' | translate }}"
        <div class="rating">
          <img src="angular_assets/images/flex/rating.png">
          <div class="text-uppercase">{{ 'flex.reviews_android_user' | translate }}</div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-4">
      <div class="card m-3 p-4 secondary-content">
        "{{ 'flex.reviews_2' | translate }}"
        <div class="rating">
          <img src="angular_assets/images/flex/rating.png">
          <div class="text-uppercase">{{ 'flex.reviews_ios_user' | translate }}</div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-4">
      <div class="card m-3 p-4 secondary-content">
        "{{ 'flex.reviews_3' | translate }}"
        <div class="rating">
          <img src="angular_assets/images/flex/rating.png">
          <div class="text-uppercase">{{ 'flex.reviews_ios_user' | translate }}</div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-4">
      <div class="card m-3 p-4 secondary-content">
        "{{ 'flex.reviews_4' | translate }}"
        <div class="rating">
          <img src="angular_assets/images/flex/rating.png">
          <div class="text-uppercase">{{ 'flex.reviews_android_user' | translate }}</div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-4">
      <div class="card m-3 p-4 secondary-content">
        "{{ 'flex.reviews_5' | translate }}"
        <div class="rating">
          <img src="angular_assets/images/flex/rating.png">
          <div class="text-uppercase pt-2">{{ 'flex.reviews_android_user' | translate }}</div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-4">
      <div class="card m-3 p-4 secondary-content">
        "{{ 'flex.reviews_6' | translate }}"
        <div class="rating">
          <img src="angular_assets/images/flex/rating.png">
          <div class="text-uppercase">{{ 'flex.reviews_ios_user' | translate }}</div>
        </div>
      </div>
    </div>
  </div>
</section>
<section id="flex-footer" [ngClass]="fromLink ? 'mt-4' : ''">
  <div class="container">
    <div *ngIf="!fromLink" >
      <div class="d-none d-md-block container text-center">
        <div class="title" [innerHTML]="'flex.start_today' | translate: { airlineName: airlineName }"></div>
        <div class="subtitle" [innerHTML]="'flex.download_app' | translate"></div>
      </div>
      <p class="pt-4 pb-4 d-md-none secondary-content">
        <span [innerHTML]="'flex.start_today' | translate: { airlineName: airlineName }"></span>
        <span [innerHTML]="'flex.download_app' | translate"></span>
      </p>
    </div>
    <ng-container *ngTemplateOutlet="downloadApp"></ng-container>
  </div>
</section>
<ng-template #downloadApp>
  <img class="d-md-none flex-logo" src="angular_assets/images/flex/flex-logo.png">
  <div class="flex-download-card card">
    <div class="row">
      <div class="col-md-6 col-sm-12 d-none d-md-block">
        <img class="flex-logo" src="angular_assets/images/flex/flex-logo.png">
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="text-uppercase download-links-title">{{ 'flex.exclusively_in_app' | translate }}</div>
        <a class="gtm-download-ios pe-2" href="https://itunes.apple.com/us/app/id90-travel/id1032894432" target="_blank">
          <img [offset]="200" [lazyLoad]="'/angular_assets/images/common/app-store-download.png'" />
        </a>
        <a class="gtm-download-android" href="https://play.google.com/store/apps/details?id=com.id90travel.id90app&hl=en_US" target="_blank">
          <img [offset]="200" [lazyLoad]="'/angular_assets/images/common/google-play-download.png'" />
        </a>
      </div>
    </div>
  </div>
</ng-template>
