import { Action } from '@ngrx/store';
import { RecommendedDestination } from 'app/shared/models/recommended-destination';

export enum RecommendedDestinationsApiActionTypes {
  LoadSuccess = '[Recommended Destinations/API] Load Success',
  LoadFailure = '[Recommended Destinations/API] Load Failure',
}

export class LoadSuccess implements Action {
  readonly type = RecommendedDestinationsApiActionTypes.LoadSuccess;

  constructor(public payload: { destinations: RecommendedDestination[] }) {}
}

export class LoadFailure implements Action {
  readonly type = RecommendedDestinationsApiActionTypes.LoadFailure;

  constructor(public payload: { error: any }) {}
}

export type RecommendedDestinationsApiActionsUnion = LoadSuccess
  | LoadFailure;
