export interface PhoneObject {
  country: string;
  area: string;
  number: string;
}

export class PhoneNumberMapper {

  fromObject() {
    return {
      toString: this.objectToString
    }
  }

  fromString() {
    return {
      toObject: this.stringToObject
    }
  }

  private objectToString({ country, area, number}: PhoneObject) {
    return `${country}|${area}|${number}`;
  }

  private stringToObject() {
    // TO DO: MOVE OUT FROM HELPER TO MAPPER
  }
}
