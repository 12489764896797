import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationDialogService {

  constructor(private modalService: NgbModal) { }

  public confirm(config: any): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmationDialogComponent, { size: config.dialogSize || 'lg' });
    modalRef.componentInstance.title = config.title;
    modalRef.componentInstance.titleParams = config.titleParams;
    modalRef.componentInstance.subtitle = config.subtitle;
    modalRef.componentInstance.message = config.message;
    modalRef.componentInstance.btnOkText = config.btnOkText;
    modalRef.componentInstance.btnCancelText = config.btnCancelText;
    modalRef.componentInstance.primaryBtn = config.primaryBtn;

    return modalRef.result;
  }
}
