
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { versions } from '@env/version';

@Injectable()
export class VersioningInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {

    if (req.headers.get('no-version')) {
      const headers = req.headers.delete('no-version');
      return next.handle(req.clone({ headers }));
    }

    const additionalHeaders = this.getHeaders(req);
    return next.handle(
      req.clone({
        setParams: {
          v: versions.revision ? versions.revision : ''
        },
        setHeaders: additionalHeaders
      })
    );
  }

  getHeaders(req) {
    return {
      'X-APP-VERSION': versions.revision ? versions.revision : '',
      'X-APP-NAME': 'angular-desktop'
    };
  }
}
