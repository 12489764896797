import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';

export class CustomMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    const error = `Missing translation: ${params.key}`;
    if (Sentry.isInitialized()) {
      Sentry.captureMessage(error);
    } else {
      console.error(error);
    }

    return params.key;
  }
}
