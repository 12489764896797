import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AbstractControl } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FormErrorsHelper {
  errorTypes;

  constructor(
    private translate: TranslateService
  ) {
    const invalidDomain$ = this.translate.get('mobile.validation_errors.email_domain');
    const mismatchedPassword$ = this.translate.get('password_reset.errors.new_password_confirm');
    forkJoin([invalidDomain$, mismatchedPassword$]).pipe(take(1)).subscribe((response) => {
      this.errorTypes = {
        required: (prefix: string) => `${prefix} ${this.translate.instant('errors.required')}`,
        requiredTrue: (prefix: string) => `${prefix} ${this.translate.instant('errors.required')}`,
        email: (prefix: string) => `${prefix} ${this.translate.instant('errors.format_invalid')}`,
        password: () => `${this.translate.instant('errors.invalid_password_format')}`,
        minLength: (prefix: string, length: string) => `${prefix} ${this.translate.instant('errors.min_length', { length })}`,
        maxLength: (prefix: string, length: string) => `${prefix} ${this.translate.instant('errors.max_length', { length })}`,
        minDigitsLength: (prefix: string, length: string) => `${prefix} ${this.translate.instant('errors.min_digits_length', { length })}`,
        numeric: (prefix: string) => `${prefix} ${this.translate.instant('errors.only_numbers')}`,
        text: (prefix: string) => `${prefix} ${this.translate.instant('errors.only_letters')}`,
        textAndNumbers: (prefix: string) => `${prefix} ${this.translate.instant('errors.only_letters_and_numbers')}`,
        textHasBlankSpaces: (prefix: string) => `${prefix} ${this.translate.instant('errors.has_blank_spaces')}`,
        invalidDomain: () => response[0],
        mismatchedPassword: () => response[1],
        phoneInput: (prefix: string) => `${prefix} ${this.translate.instant('errors.is_invalid')}`,
        alphanumeric: (prefix: string) => `${prefix} ${this.translate.instant('errors.format_invalid')}`,
        address: (prefix: string) => `${prefix} ${this.translate.instant('errors.format_invalid')}`,
        duplicateTraveler : () => `${this.translate.instant('errors.duplicate_traveler')}`,
        dateFormat: (prefix, format: string) => `${prefix} ${this.translate.instant('errors.format_invalid_specific')} ${format}`,
        textAndHyphen: (prefix: string) => `${prefix} ${this.translate.instant('errors.text_and_hyphen')}`,
        blockDomain: (prefix: string, value: string) => `${this.translate.instant('errors.block_domain', {
          blockDomain: value
        })}`,
        sameEmail: (prefix: string) => `${prefix} ${this.translate.instant('co_traveler.errors.duplicate')}`,
        emailContainsSpaces: (prefix: string) => `${prefix} ${this.translate.instant('errors.email_spaces')}`,
        textIsOnlyBlankSpaces: (prefix: string) => `${prefix} ${this.translate.instant('errors.text_is_only_blank_spaces')}`,
      };
    });
  }

  errorMessage(control: AbstractControl, controlName = '') {
    if (control.errors &&
      (control.errors.number || (control.errors.pattern && control.errors.pattern.requiredPattern === '^[0-9]*$'))
    ) {
      return this.errorTypes.numeric(controlName);
    }

    if (control.errors && control.errors.required) {
      return this.errorTypes.required(controlName);
    }

    if (control.errors && control.errors.requiredTrue) {
      return this.errorTypes.requiredTrue(controlName);
    }

    if (
      control.errors &&
      (control.errors.email ||
        control.errors.invalidEmailFormat ||
        (controlName === this.translate.instant('profile.info.email') && control.errors.valid === false))
    ) {
      return this.errorTypes.email(controlName);
    }

    if (control.errors && control.errors.password) {
      return this.errorTypes.password();
    }

    if (control.errors && control.errors.text) {
      return this.errorTypes.text(controlName);
    }

    if (control.errors && control.errors.textAndNumbers) {
      return this.errorTypes.textAndNumbers(controlName);
    }

    if (control.errors && control.errors.textHasBlankSpaces) {
      return this.errorTypes.textHasBlankSpaces(controlName);
    }

    if (control.errors && control.errors.minlength) {
      return this.errorTypes.minLength(
        controlName,
        control.errors.minlength.requiredLength
      );
    }

    if (control.errors && control.errors.maxlength) {
      return this.errorTypes.maxLength(controlName, control.errors.maxlength.requiredLength);
    }

    if (control.errors && control.errors.minDigitsLength) {
      return this.errorTypes.minDigitsLength(controlName, control.errors.length);
    }

    if (control.errors && control.errors.invalidDomain) {
      return this.errorTypes.invalidDomain();
    }

    if (control.errors && control.errors.mismatchedPassword) {
      return this.errorTypes.mismatchedPassword();
    }

    if (control.errors && control.errors.customError) {
      return control.errors.customError;
    }

    if (control.errors && control.errors.phoneInput) {
      return this.errorTypes.phoneInput(controlName);
    }

    if (control.errors && control.errors.alphanumeric) {
      return this.errorTypes.alphanumeric(controlName);
    }

    if (control.errors && control.errors.address) {
      return this.errorTypes.address(controlName);
    }
    if (control.errors && control.errors.duplicateTraveler) {
      return this.errorTypes.duplicateTraveler();
    }

    if (control.errors && control.errors.dateFormat) {
      return this.errorTypes.dateFormat(controlName, control.errors.correctFormat.toLowerCase());
    }

    if (control.errors && control.errors.textAndHyphen) {
      return this.errorTypes.textAndHyphen(controlName);
    }

    if (control.errors && control.errors.blockDomain && control.errors.isBlockDomain) {
      return this.errorTypes.blockDomain(controlName, control.errors.isBlockDomain.toLocaleLowerCase())
    }

    if (control.errors && control.errors.sameEmail) {
      return this.errorTypes.sameEmail(controlName);
    }

    if (control.errors && control.errors.emailContainsSpaces) {
      return this.errorTypes.emailContainsSpaces(controlName);
    }

    if (control.errors && control.errors.textIsOnlyBlankSpaces) {
      return this.errorTypes.textIsOnlyBlankSpaces(controlName);
    }

    if (control.errors?.['pattern']) {
      return this.errorTypes.email(controlName);
    }

    return controlName;
  }
}
