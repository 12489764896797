import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { faCheckCircle, faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { HotelBookingService } from 'app/hotels/services/book/hotel-booking.service';
import { HotelReviewsService } from 'app/hotels/services/reviews/hotel-reviews.service';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';
import { GeneralHelper } from 'app/shared/helpers/general.helper';
import { HotelHelper } from 'app/shared/helpers/hotel.helper';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'hotel-review-modal',
  templateUrl: './hotel-review-modal.html',
  styleUrls: ['./hotel-review-modal.scss']
})

export class HotelReviewModal implements OnInit, OnDestroy {
  @Input() completeItineraryId;
  hotelReviewForm: UntypedFormGroup;
  itineraryData;
  loading: boolean = false;
  defaultImage = '/angular_assets/images/common/default-city.jpg';
  maxLength = 350;
  thumbUp: boolean = false;
  thumbDown: boolean = false;
  error = '';
  success = '';
  itineraryId;
  vendorKey = null;
  vendorId = null;
  counterChar = 0;
  disableForm: boolean = false;
  icons = { faCheckCircle, faThumbsUp, faThumbsDown };
  ngUnsubscribe = new Subject<void>();

  constructor(
    private activeModal: NgbActiveModal,
    private hotelHelper: HotelHelper,
    private hotelBookingService: HotelBookingService,
    private generalHelper: GeneralHelper,
    private formBuilder: UntypedFormBuilder,
    public formErrorsHelper: FormErrorsHelper,
    private hotelReviewsService: HotelReviewsService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    if (this.completeItineraryId) {
      this.loading = true;
      this.itineraryId = this.completeItineraryId.replace('itineraryId-', '');
      this.setVendorVars(this.itineraryId);
      this.getItineraryDetails();
    }
  }

  setVendorVars(itineraryId) {
    const decodedVendorPropertyID = this.hotelHelper.decodedVendorPropertyID(itineraryId);
    this.vendorKey = decodedVendorPropertyID.substr(0, 3).toUpperCase();
    this.vendorId = decodedVendorPropertyID.substr(8, decodedVendorPropertyID.length);
  }

  createForm(review = null) {
    if (review) {
      const thumbUp = review.thumb_up === 1 || review.thumbUp === 1;
      this.disableForm = true;
      this.thumbUp = thumbUp;
      this.thumbDown = !thumbUp;
      this.counterChar = review.description.length;
    }
    this.hotelReviewForm = this.formBuilder.group({
      reviewDescription: new UntypedFormControl({ value: review ? review.description : '', disabled: review },
                                         [Validators.required, Validators.maxLength(this.maxLength)])
    });
  }

  getItineraryDetails() {
    this.hotelBookingService.getReservation(this.itineraryId).pipe(take(1)).subscribe(
      (response) => {
        if (response.review) {
          this.createForm(response.review[0]);
        } else {
          this.createForm();
        }
        this.countChar();
        this.itineraryData = response;
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }

  sendReview() {
    if (this.isValid() && this.validThumbs()) {
      this.loading = true;
      const review = {
        description: this.hotelReviewForm.value.reviewDescription,
        thumbUp: this.thumbUp ? 1 : 0,
        itineraryId: this.itineraryData.itinerary_id,
        vendorKey: this.vendorKey,
        vendorId: this.vendorId
      };
      this.error = '';
      this.hotelReviewsService.post(review).pipe(take(1)).subscribe(
        () => {
          this.loading = false;
          this.showSuccess({ reviewSent: true });
        },
        () => {
          this.loading = false;
          this.error = this.translate.instant('errors.internal_server_error');
        }
      );
    } else {
      Object.keys(this.hotelReviewForm.controls).forEach((field) => {
        const control = this.hotelReviewForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  private validThumbs() {
    if (!this.thumbUp && !this.thumbDown) {
      this.error = this.translate.instant('reviews.thumbs_error');
      return false;
    }

    return true;
  }

  private showSuccess(data) {
    this.success = 'reviews.create_success';
    setTimeout(() => {
      this.success = '';
      this.close(data);
    },         1500);
  }

  get startDate() {
    return this.generalHelper.formatDateOnYear(this.itineraryData.start_date, false);
  }

  get endDate() {
    return this.generalHelper.formatDateOnYear(this.itineraryData.end_date, false);
  }

  get hotelImage() {
    const imageUrl = this.itineraryData && this.itineraryData.hotel_image ? this.itineraryData.hotel_image : this.defaultImage;
    const formatImageUrl = imageUrl.replace('c_b.', 'c_z.');
    return `https:${formatImageUrl}`;
  }

  get reviewDescription() { return this.hotelReviewForm.get('reviewDescription'); }

  setThumbUp() {
    if (!this.disableForm) {
      this.thumbUp = true;
      this.thumbDown = false;
    }
  }

  setThumbDown() {
    if (!this.disableForm) {
      this.thumbDown = true;
      this.thumbUp = false;
    }
  }

  isValid() {
    return this.hotelReviewForm.valid;
  }

  closeAlert() {
    this.error = '';
    this.success = '';
  }

  dismiss() {
    this.activeModal.dismiss(null);
  }

  close(data) {
    this.activeModal.close(data);
  }

  countChar() {
    this.hotelReviewForm.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => this.counterChar = res.reviewDescription.length);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
