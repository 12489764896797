import { map, catchError, mergeMap, switchMap } from 'rxjs/operators';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { GamificationService } from 'app/shared/services/gamification/gamification.service';
import { GamificationActions } from 'app/shared/store/actions';
import { AnalyticsService } from 'app/shared/services/analytics/analytics.service';
import { getGamificationStatistics } from 'app/shared/store/reducers';
import { Store } from '@ngrx/store';

@Injectable()
export class GamificationEffects {

  loadCampaigns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GamificationActions.loadActiveCampaigns),
      mergeMap((action) => {
        return this.gamificationService.getActiveCampaigns().pipe(
          map(( campaigns: any ) => GamificationActions.loadActiveCampaignsSuccess( { campaigns } )),
          catchError((error: any) => of(GamificationActions.loadActiveCampaignsFailure({ error })))
        );
      })
    )
  );

  participate$ = createEffect(() =>
   this.actions$.pipe(
     ofType(GamificationActions.participateCampaign),
     mergeMap((action) => {
       return this.gamificationService.participateCampaign(action.idCampaign, action.participate).pipe(
         map(() => {
          this.analyticsService.logContent({
            content_type: action.participate ? 'Accept Participation' : 'Decline Participation',
            content_id: action.idCampaign
          });
          this.store.dispatch(GamificationActions.getStatistics());
          return GamificationActions.participateCampaignSuccess({ campaignId: action.idCampaign, participate: action.participate });
         }),
         catchError((error: any) => of(GamificationActions.participateCampaignFailure({ error })))
       );
     })
   )
  );

  freeEntry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GamificationActions.checkFreeEntryAvailable),
      switchMap((action) => {
        return this.gamificationService.checkFreeEntryAvailable().pipe(
          map(() => GamificationActions.checkFreeEntryAvailableSuccess({ campaignId: action.campaignId })),
          catchError((error: any) => of(GamificationActions.checkFreeEntryAvailableFailure({ error })))
        );
      })
    )
  );

  getStatistics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GamificationActions.getStatistics),
      switchMap((action) => {
        return this.gamificationService.getStatistics().pipe(
          map(( statistics: any) => GamificationActions.getStatisticsSuccess({ statistics })),
          catchError((error: any) => of(GamificationActions.getStatisticsFailure({ error })))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private gamificationService: GamificationService,
    private analyticsService: AnalyticsService,
    private store: Store
  ) { }
}
