import { PaymentMethod } from 'app/profile/models/payment-method';
import { PaymentMethodsActions, PaymentMethodsApiActions } from '../actions';

export interface State {
  pending: boolean;
  error: any | null;
  success: boolean;
  creditsCards: PaymentMethod[] | null;
}

export const initialState: State = {
  pending: false,
  error: null,
  success: false,
  creditsCards: null
};

export function reducer(
  state = initialState,
  action: PaymentMethodsApiActions.PaymentMethodsApiActionsUnion | PaymentMethodsActions.PaymentMethodsActionsUnion
) {
  switch (action.type) {
    case PaymentMethodsActions.PaymentMethodsActionTypes.Add:
      return {
        error: null,
        pending: true,
        success: false
      };
    case PaymentMethodsApiActions.PaymentMethodsApiActionTypes.AddCardSuccess:
      return {
        error: null,
        pending: false,
        success: true
      };
    case PaymentMethodsApiActions.PaymentMethodsApiActionTypes.AddCardFailure:
      return {
        error: action.payload.error,
        pending: false,
        success: false
      };
    case PaymentMethodsActions.PaymentMethodsActionTypes.Reset:
      return initialState;
    case PaymentMethodsActions.PaymentMethodsActionTypes.Delete:
      return {
        error: null,
        pending: true,
        success: false
      };
    case PaymentMethodsActions.PaymentMethodsActionTypes.DeleteSuccess:
      return {
        error: null,
        pending: false,
        success: true
      };
    case PaymentMethodsActions.PaymentMethodsActionTypes.DeleteFailure:
      return {
        error: action.payload.error,
        pending: false,
        success: false
      };
    case PaymentMethodsActions.PaymentMethodsActionTypes.GetAll:
      return {
        error: null,
        pending: true,
        success: false
      };
    case PaymentMethodsActions.PaymentMethodsActionTypes.GetAllSucess:
      return {
        error: null,
        pending: false,
        success: true,
        creditsCards: action.payload
      };
    case PaymentMethodsActions.PaymentMethodsActionTypes.Edit:
      return {
        error: null,
        pending: true,
        success: false,
      };
    case PaymentMethodsActions.PaymentMethodsActionTypes.EditSuccess:
      return {
        error: null,
        pending: false,
        success: true,
      };
    case PaymentMethodsActions.PaymentMethodsActionTypes.EditFailure:
      return {
        error: action.payload,
        pending: false,
        success: false,
      };
    default:
      return state;
  }
}

export const getPendingState = (state: State) => state.pending;
export const getSuccessState = (state: State) => state.success;
export const getErrorState = (state: State) => state.error;
export const getPaymentMethods = (state: State) => state.creditsCards;
