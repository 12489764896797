import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoggedUserGuard } from 'app/auth/store/guards/logged-user.guard';
import { VerifiedUserGuard } from 'app/auth/store/guards/verified-user.guard';
import { TripsPage } from 'app/trips/containers/trips/trips.page';

const routes: Routes = [
  {
    path: '',
    component: TripsPage,
    pathMatch: 'full',
    canActivate: [LoggedUserGuard, VerifiedUserGuard],
  },
  {
    path: ':tab',
    component: TripsPage,
    pathMatch: 'full',
    canActivate: [LoggedUserGuard, VerifiedUserGuard],
  },
  {
    path: ':tab/:section',
    component: TripsPage,
    pathMatch: 'full',
    canActivate: [LoggedUserGuard, VerifiedUserGuard],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TripsRoutingModule {}
