import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType, act } from '@ngrx/effects';
import { CreditActions, CreditHistoryActions, PromoCodeAction } from '../actions';
import { map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { PromoCodeCreditsService } from 'app/shared/services/promo-code-credits/promo-code-credits';
import { Store } from '@ngrx/store';

@Injectable()
export class PromoCodeEffects {

  checkPromoCode$ = createEffect(()=>
    this.actions$.pipe(
      ofType<PromoCodeAction.CheckPromoCode>(PromoCodeAction.PromoCodeActionType.CheckPromoCode),
      switchMap((action) =>  {
        return this.promoCodeService.validCreditsPromotionCode(action.payload).pipe(
          map(response => new PromoCodeAction.CheckPromoCodeSuccess({ promoCode : response })),
          catchError(error => of(new PromoCodeAction.CheckPromoCodeFailure(error)))
        );
      })
    )
  );

  redeemPromoCode$ = createEffect(()=>
    this.actions$.pipe(
      ofType<PromoCodeAction.RedeemPromoCode>(PromoCodeAction.PromoCodeActionType.RedeemPromoCode),
      switchMap((action) =>  {
        return this.promoCodeService.redeemCreditsPromotionCode(action.payload).pipe(
          map(res => new PromoCodeAction.RedeemPromoCodeSuccess({ payload : res })),
          catchError(error => of(new PromoCodeAction.RedeemPromoCodeFailure(error)))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private promoCodeService: PromoCodeCreditsService,
    private store : Store<any>
  ) {}
}
