<div class="gcontacts-modals">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ modalTitle }}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()">

    </button>
  </div>
  <div *ngIf="contacts.length === 0" class="text-center">
    <img src="angular_assets/images/icons/social/gmail.png"/>
    <div class="mt-4">{{ 'invite.no_contacts' | translate }}</div>
  </div>
  <div *ngIf="contacts.length > 0">
    <div class="row">
      <div class="col">
        <div class="form-group">
          <input type="text" class="form-control ps-5" name="filterContacts"
            placeholder="{{ 'mobile.invite_friends.search_filter' | translate }}" [(ngModel)]="filterContacts">
          <fa-icon [icon]="icons.faSearch"></fa-icon>
        </div>
      </div>
    </div>
    <div class="modal-body">
      <form class="contactsForm">
        <div class="contacts-list d-flex align-items-center"
          *ngFor="let contact of contacts | contactFilter:filterContacts; let i=index">
          <input type="checkbox" [id]="'email_' + i" class="me-2" [checked]="contact.checked" (change)="onCheck(contact)" />
          <label [for]="'email_' + i" class="w-100 d-flex flex-column">
            <h5 class="mb-0 fw-normal">{{ contact.name }}</h5>
            <p class="mb-0 fw-light">{{ contact.email }}</p>
          </label>
        </div>
      </form>
    </div>
    <div class="modal-footer flex-column flex-md-row d-flex">
      <div class="reward text-start d-inline-flex mb-2 mb-md-0" *ngIf="rewardPrice">
        <h4 class="secondary-content mb-0">
          {{ 'mobile.invite_friends.potential_reward' | translate }}
        </h4>
        <p class="secondary-content ps-1 fw-bold m-0">${{selectedCount * rewardPrice}}</p>
      </div>
      <div class="actions">
        <a href="javascript:void(0)" class="me-2" (click)='selectUnselectAll()'>
          {{ (selectedAll ? 'mobile.invite_friends.deselect_all' : 'mobile.invite_friends.select_all') | translate }}
        </a>
        <button type="button" [disabled]="selectedCount < 1" *ngIf="!loading" class="btn btn-md btn-primary"
          (click)="confirmSelection()">
          {{ (selectedCount == 0 ? buttonActionText : selectedCount > 1 ? buttonAddActionText +' '+ selectedCount +' '+ buttonActionTypeTextN : buttonActionTypeText) }}
        </button>
      </div>
    </div>
  </div>
</div>
