import { Component, EventEmitter, Input, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';
import { take } from 'rxjs/operators';
import * as fromProfile from 'app/profile/store/reducers';
import { SetMemberStatus } from 'app/profile/store/actions/member-interests.actions';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'communication-preferences',
  templateUrl: './communication-preferences.html',
  styleUrls: ['./communication-preferences.scss']
})

export class CommunicationPreferencesComponent {
  @Input() mailchimpPreferencesForm;
  @Input() allInterests = [];
  @Input() profileUser: any;
  @Input() pending: boolean;
  private radioInterests: any = {};
  loading = false;
  success = null;
  error = null;
  icons = { faChevronLeft };
  @Output() showTabs = new EventEmitter();

  private memberInterestsSuccess$ = this.store.pipe(select(fromProfile.getMemberStatusSuccess));

  constructor(
    public formErrorsHelper: FormErrorsHelper,
    private store: Store<fromProfile.State>
  ) {}

  setStatus(event, interestKey, eventType) {
    this.loading = true;

    const interests = {};
    if (eventType === 'radio') {
      this.radioInterests = this.getAllRadioButtons();
      this.radioInterests[0].interests.forEach((interest) => {
        if (interest.id === interestKey) { interests[interest.id] = true; }
        else { interests[interest.id] = false; }
      });
    } else {
      interests[interestKey] = event;
    }

    this.store.dispatch(new SetMemberStatus({
      data: JSON.stringify(interests),
      isToken: false
    }));
    this.listenForUpdateSuccess();
  }

  getAllRadioButtons() {
    return this.allInterests.filter(allInterest => allInterest.type === 'radio');
  }

  listenForUpdateSuccess() {
    this.memberInterestsSuccess$.pipe(take(1)).subscribe(
      () => {
        this.success = 'profile.info.successful_update';
        setTimeout(() => this.success = '', 2000);
        this.loading = false;
      },
      (error) => {
        this.error = error.error.error_description;
        this.loading = false;
      }
    );
  }

  closeAlert() {
    this.error = null;
    this.success = null;
  }
}
