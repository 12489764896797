import { ForgotPasswordActions, AuthApiActions } from '../actions';

export interface State {
  codeError: any;
  codeSuccess: any;
  linkError: any;
  linkSuccess: any;
  codeValidation: any;
  codeValidationEror: any;
  pending: boolean;
  verifyOptionsResult: any;
  verifyOptionsError: any;
  attempts: number;
}

export const initialState: State = {
  codeError: null,
  codeSuccess: null,
  linkError: null,
  linkSuccess: null,
  pending: false,
  codeValidation: null,
  codeValidationEror: null,
  verifyOptionsResult: null,
  verifyOptionsError: null,
  attempts: 0
};

export function reducer(
  state = initialState,
  action: AuthApiActions.AuthApiActionsUnion | ForgotPasswordActions.ForgotPasswordActionsUnion
): State {
  switch (action.type) {
    case ForgotPasswordActions.ForgotPasswordActionTypes.RequestCode:
      return {
        ...state,
        codeError: null,
        pending: true,
        codeSuccess: null
      };
    case ForgotPasswordActions.ForgotPasswordActionTypes.RequestCodeSuccess:
      return {
        ...state,
        codeError: null,
        pending: false,
        codeSuccess: action.payload.response
      };
    case ForgotPasswordActions.ForgotPasswordActionTypes.RequestCodeError:
      return {
        ...state,
        codeError: action.payload.error,
        pending: false,
        codeSuccess: null
      };
    case ForgotPasswordActions.ForgotPasswordActionTypes.RequestLink:
      return {
        ...state,
        linkError: null,
        pending: true,
        linkSuccess: false
      };
    case ForgotPasswordActions.ForgotPasswordActionTypes.RequestLinkSuccess:
      return {
        ...state,
        linkError: null,
        pending: false,
        linkSuccess: action.payload.response
      };
    case ForgotPasswordActions.ForgotPasswordActionTypes.RequestLinkError:
      return {
        ...state,
        linkError: action.payload.error,
        pending: false,
        linkSuccess: false
      };
    case ForgotPasswordActions.ForgotPasswordActionTypes.RequestLinkSuccess:
      return {
        ...state,
        linkError: null,
        pending: false,
        linkSuccess: action.payload.response
      };
    case ForgotPasswordActions.ForgotPasswordActionTypes.ValidateCode:
      return {
        ...state,
        pending: true
      };
    case ForgotPasswordActions.ForgotPasswordActionTypes.ValidateCodeSuccess:
      return {
        ...state,
        pending: false,
        codeValidation: action.payload.response
      };
    case ForgotPasswordActions.ForgotPasswordActionTypes.ValidateCodeError:
      return {
        ...state,
        pending: false,
        codeValidationEror: action.payload.error
      };
    case ForgotPasswordActions.ForgotPasswordActionTypes.VerifyOptions:
      return {
        ...state,
        pending: true
      };
    case ForgotPasswordActions.ForgotPasswordActionTypes.VerifyOptionsSuccess:
      return {
        ...state,
        pending: false,
        verifyOptionsResult: { ...action.payload.response }
      };
    case ForgotPasswordActions.ForgotPasswordActionTypes.VerifyOptionsError:
      return {
        ...state,
        pending: false,
        verifyOptionsError: action.payload.error
      };
    case ForgotPasswordActions.ForgotPasswordActionTypes.AddAttempt:
      return {
        ...state,
        attempts:  state.attempts + 1
      };
    case ForgotPasswordActions.ForgotPasswordActionTypes.ResetAttempts:
      return {
        ...state,
        attempts: 0
      };
    case ForgotPasswordActions.ForgotPasswordActionTypes.Reset:
      return {
        ...initialState,
        attempts: state.attempts
      };
    default: {
      return state;
    }
  }
}

export const getCodeError = (state: State) => state.codeError;
export const getCodeSuccess = (state: State) => state.codeSuccess;
export const getLinkError = (state: State) => state.linkError;
export const getLinkSuccess = (state: State) => state.linkSuccess;
export const getCodeValidation = (state: State) => state.codeValidation;
export const getCodeValidationError = (state: State) => state.codeValidationEror;
export const getPending = (state: State) => state.pending;
export const getVerifyOptionsResult = (state: State) => state.verifyOptionsResult;
export const getVerifyOptionsError = (state: State) => state.verifyOptionsError;
export const getAttempts = (state: State) => state.attempts;
