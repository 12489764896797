import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { SessionService } from 'app/shared/services/session/session.service';
import { mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(
    private http: HttpClient,
    private sessionService: SessionService
  ) {}

  login(credentials) {
    return this.http.post<any>(
      `${environment.apiUrl}/api/v1/sessions`,
      credentials,
      {
        headers: new HttpHeaders({
          'No-Auth': 'true',
          'X-API-CALL-V2': 'true'
        })
      }
    ).pipe(
      mergeMap((resp) => {
        this.sessionService.setState(resp);
        return of(resp);
      })
    );
  }

  logout() {
    return this.http.delete(`${environment.apiUrl}/api/v1/sessions/1.json`);
  }

  selectEmployee(employeeNumber) {
    return this.http.post<any>(
      `${environment.apiUrl}/session/select_employee.json`,
      { employee: employeeNumber.trim() },
      { headers: new HttpHeaders().set('X-API-CALL', 'true') }
    );
  }

  selectUser(employeeNumber) {
    return this.http.post<any>(
      `${environment.apiUrl}/api/v1/sessions/select_employee.json`,
      { employee: employeeNumber.trim(), organizationId: 1326 },
      { headers: new HttpHeaders().set('X-API-CALL', 'true') }
    );
  }

  currentSession(accessToken): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/api/v1/sessions/show`,
      { headers: new HttpHeaders().set('token', accessToken) }
    );
  }

  marketingLogin(credentials) {
    return this.http.post<any>(
      `${environment.apiUrl}/event-registration/login.json`,
      credentials,
      {
        headers: new HttpHeaders({
          'No-Auth': 'true',
          'X-API-CALL-V2': 'true'
        })
      }
    );
  }

  marketingLogout() {
    return this.http.get<any>(
      `${environment.apiUrl}/event-registration/logout.json`,
      {
        headers: new HttpHeaders({
          'No-Auth': 'true',
          'X-API-CALL-V2': 'true'
        })
      }
    );
  }

  oauth2Logout(airlineCode) {
    return this.http.get<any>(
      `${environment.apiUrl}/oauth2/logout.json`,
      {
        headers: new HttpHeaders({
          'No-Auth': 'true',
          'X-API-CALL-V2': 'true'
        }),
        params: new HttpParams().set('airline_code', airlineCode)
      }
    );
  }

  register(credentials) {
    return this.http.post<any>(
      `${environment.apiUrl}/api/v1/members.json`,
      credentials,
      {
        headers: new HttpHeaders().set('No-Auth', 'true'),
      }
    );
  }

  resendEmailConfirmation() {
    return this.http.get(`${environment.apiUrl}/api/v1/membership/resend-confirmation-email.json`, {});
  }

  confirmEmail(id, token) {
    const params = new HttpParams().set('token', token);
    return this.http.get<any>(`${environment.apiUrl}/api/v1/confirm_emails/${id}.json`, { params });
  }

  loginJWT(params) {
    return this.http.post<any>(`${environment.apiUrl}/api/v1/sessions/jwt_login.json`, params, { headers: new HttpHeaders().set('No-Auth', 'true') });
  }

  validateAccount(params) {
    return this.http.post<any>(`${environment.apiUrl}/api/v1/validate_user_information.json`, params, {
      headers: new HttpHeaders().set('No-Auth', 'true'),
    });
  }

  verifyUser(params) {
    return this.http.post<any>(`${environment.apiUrl}/api/v1/validate_employee_number.json`, params, {
      headers: new HttpHeaders().set('No-Auth', 'true')
    });
  }
}
