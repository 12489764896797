import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { reducers } from 'app/auth/store';
import { RegisterEmailAddress } from './containers/register-email-address.page';
import { FirstTimeLoginPage } from './containers/first-time-login.page';
import { FirstTimeLoginFormComponent } from './components/first-time-login-form.component';
import { LogoutComponent } from 'app/auth/containers/logout.component';
import { AuthEffects } from 'app/auth/store/effects';
import { LogoutEffects } from 'app/auth/store/effects';
import { SharedModule } from 'app/shared/shared.module';
import { RegisterPage } from './containers/register.page';
import { ResendEmailConfirmation } from './containers/resend-email-confirmation.component';
import { RegisterInvitedPage } from './containers/register-invited.page';
import { RegisterFormComponent } from './components/register-form.component';
import { RegisterInvitedFormComponent } from './components/register-invited-form/register-invited-form.component';
import { ForgotPasswordRequestPage } from './containers/forgot-password/request/request.page';
import { ForgotPasswordResetPage } from './containers/forgot-password/reset/reset.page';
import { EventLoginPage } from './containers/event-registration/event-login/event-login.page';
import { EventLoginFormComponent } from './components/event-registration/event-login-form.component';
import { AirlineSelectPage } from './containers/event-registration/airline-select/airline-select.page';
import { EventRegistrationPage } from './containers/event-registration/event-registration.page';
import { EventRegistrationAirlineFormComponent } from './components/event-registration/event-registration-airline-form-component';
import { EventRegistrationFormComponent } from './components/event-registration/event-registration-form.component';
import { BrandAmbassadorReviewComponent } from 'app/shared/components/brand-ambassador-review/brand-ambassador-review.component';
import { LoginModalComponent } from './containers/login-modal/login-modal.component';
import { SamlHelper } from 'app/shared/helpers/saml.helper';
import { ConfirmEmailComponent } from './containers/confirm-email.component';
import { UserSelectorComponent } from './components/user-selector/user-selector.component';
import { ShareItineraryComponent } from './containers/share-itinerary/share-itinerary.component';
import { CoTravelerItinerarySummaryComponent } from './components/co-traveler-itinerary-summary/co-traveler-itinerary-summary.component';
import { EligibleTravelerSummaryComponent } from './components/eligible-traveler-summary/eligible-traveler-summary.component';
import { ThirdPartyEffects } from 'app/auth/store/effects';
import { ResetPasswordRequestFormComponent } from './components/reset-password/request-form/reset-password-request-form.component';
import { ForgotPasswordEffects, AccountValidationEffects } from './store/effects';
import { ResetPasswordCodeFormComponent } from './components/reset-password/code-form/reset-password-code-form.component';
import { AccountAccessPage } from './containers/account-access/account-access.page';
import { AccountValidationPage } from './containers/account-validation/account-validation.page';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MissingInfoModalComponent } from './components/missing-info-modal/missing-info-modal.component';
import { SendCodeOptionsComponent } from './components/reset-password/send-code-options/send-code-options.component';
import { ZendeskFormPage } from 'app/shared/containers/zendesk-form/zendesk-form.page';
import { NgxCaptchaModule } from 'ngx-captcha';
import { LoginPage } from './containers/login.page';
import { ProfileModule } from 'app/profile/profile.module';
import { TripsModule } from 'app/trips/trips.module';
import { MultiFactorAuthenticationEffects } from './store/effects/multifactor-authentication.effects';
import { SocialLoginModule } from '@abacritt/angularx-social-login';

export const COMPONENTS = [
  AccountAccessPage,
  AccountValidationPage,
  AirlineSelectPage,
  BrandAmbassadorReviewComponent,
  ConfirmEmailComponent,
  EventLoginFormComponent,
  EventLoginPage,
  EventRegistrationAirlineFormComponent,
  EventRegistrationFormComponent,
  EventRegistrationPage,
  FirstTimeLoginFormComponent,
  FirstTimeLoginPage,
  ForgotPasswordResetPage,
  ForgotPasswordRequestPage,
  LoginPage,
  LoginModalComponent,
  LogoutComponent,
  ZendeskFormPage,
  RegisterEmailAddress,
  RegisterFormComponent,
  RegisterInvitedFormComponent,
  RegisterInvitedPage,
  RegisterPage,
  ResendEmailConfirmation,
  ResetPasswordCodeFormComponent,
  ResetPasswordRequestFormComponent,
  ShareItineraryComponent,
  CoTravelerItinerarySummaryComponent,
  EligibleTravelerSummaryComponent,
  UserSelectorComponent,
  MissingInfoModalComponent,
  SendCodeOptionsComponent
];

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([
      AccountValidationEffects,
      AuthEffects,
      ForgotPasswordEffects,
      LogoutEffects,
      MultiFactorAuthenticationEffects,
      ThirdPartyEffects,
    ]),
    FontAwesomeModule,
    FormsModule,
    NgbModule,
    NgxCaptchaModule,
    ProfileModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    SocialLoginModule,
    StoreModule.forFeature('auth', reducers),
    TripsModule,
  ],
  declarations: COMPONENTS,
  providers: [
    SamlHelper
  ]
})
export class AuthModule {}
