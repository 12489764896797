import { getCurrencySymbol } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { faDollar, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { CreditActions, MemberLoyaltyActions, ProfileUserActions } from 'app/profile/store/actions';
import { MeiliHelper } from 'app/shared/helpers/meili.helper';
import { ProfileService } from 'app/shared/services/profile/profile.service';
import { SessionService } from 'app/shared/services/session/session.service';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'currency-selector',
  templateUrl: './currency-selector.component.html',
  styleUrls: ['./currency-selector.component.scss']
})
export class CurrencySelectorComponent implements OnChanges, OnInit, OnDestroy {

  constructor(
    private profileService: ProfileService,
    private sessionService: SessionService,
    private meiliHelper: MeiliHelper,
    private store: Store,
    private router: Router
  ) { }

  @Input() forMobile = false;
  @Input() user: any;
  @Input() profileConfig;
  @Input() configFlags: any;
  selectedCurrency: string;
  isCollapsed = true;
  icons = { faDollar, faSpinner };
  showloading: boolean = false;
  disabledPath = ['/flights'];
  isPathEnabled = true;

  readonly DEFAULT_CURRENCY = 'USD';
  readonly ALL_CURRENCYS = ['USD', 'EUR', 'CAD', 'AUD', 'GBP', 'NZD', 'MXN'];
  private ngUnsubscribe = new Subject<void>();

  get show(): boolean {
    return this.profileConfig?.currency?.show && !this.showloading && this.isPathEnabled;
  }

  ngOnInit(): void {
    this.checkPath();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.user && changes.user) {
      this.selectedCurrency = this.user.currency;
      if (this.meiliHelper.isEnabled(this.configFlags,this.user.airlineCode)){
        this.disabledPath = [...this.disabledPath, '/cars'];
        this.checkPath();
      }
    }
  }

  selectCurrency(currencyCode: string) {
    this.showloading = true;
    this.isCollapsed = true;
    this.profileService.updateProfile({
      currency: currencyCode
    }).pipe(take(1)).subscribe(() => {
      this.sessionService.setCurrency(currencyCode);
      this.store.dispatch(new CreditActions.LoadCredits());
      this.store.dispatch(new MemberLoyaltyActions.LoadLoyalty({ currency: currencyCode }));
      this.store.dispatch(new ProfileUserActions.UpdateUserProfile({
        currency: currencyCode
      }));
      this.selectedCurrency = currencyCode;
      this.showloading = false;
    })
  }

  checkPath() {
    this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
      if (!(event instanceof NavigationEnd)) return
      if (this.disabledPath.some(((element) => event.url.includes(element)))) {
        this.isPathEnabled = false;
        return
      }
      this.isPathEnabled = true;
      return
    });
    const urlWithOutParams = this.router.url.split('?')[0];
    if (this.disabledPath.some((element) => urlWithOutParams.includes(element))) {
      this.isPathEnabled = false;
    }
  }

  getCurrencySym(currency) {
   return getCurrencySymbol(currency , 'narrow');
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
