import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { FeatureFlagsService } from 'app/shared/services/featureFlags/featureFlags.service';

@Directive({
  selector: '[featureFlag]'
})
export class FeatureFlagDirective implements OnInit {
  // A directive can be a string or an array of strings to manipulate many features at a time
  // i.e. if one feature of the array is enabled, it will enable the all section. So to disable a whole section
  // all the features must not accomplish with the condition.
  @Input() featureFlag: string | string[];

  constructor(
    private tpl: TemplateRef<any>,
    private vcr: ViewContainerRef,
    private featureFlagsService: FeatureFlagsService
  ) {}

  ngOnInit() {
    let isEnabled = false;
    if (typeof this.featureFlag === 'string') {
      isEnabled = this.featureFlagsService.isFeatureEnabled(this.featureFlag);
    }
    if (Array.isArray(this.featureFlag)) {
      const firstFlagEnabled = this.featureFlag.find(featureFlag => this.featureFlagsService.isFeatureEnabled(featureFlag));
      isEnabled = !!firstFlagEnabled;
    }
    if (isEnabled) {
      this.vcr.createEmbeddedView(this.tpl);
    }
  }
}
