import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

export interface UsersWithImages {
  image: string;
  name: string;
}
export interface UsersWithOutImages {
  name: string;
  abbr: string;
  cssClass?: string;
}

@Component({
  selector: 'avatar-circle',
  templateUrl: './avatar-circle.html',
  styleUrls: ['./avatar-circle.scss']
})

export class AvatarCircleComponent implements OnInit, OnChanges{
  @Input() avatar: UsersWithImages;
  @Input() noavatar: UsersWithOutImages;
  @Input() type = '';

  ngOnInit() {
    if (this.noavatar) {
      this.noavatar.cssClass = this.randomColorClass();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.noavatar && changes.noavatar.currentValue) {
      this.noavatar.cssClass = this.randomColorClass();
    }
  }

  randomColorClass(): string {
    const isProfileAvatar =  this.type === 'header' || this.type === 'profile';
    const colors = isProfileAvatar ?
      ['profile-bg'] :
      ['blue', 'black', 'teal', 'red', 'cyan', 'green', 'orange', 'purple', 'indigo', 'pink'];
    return colors[(Math.random() * colors.length) | 0];
  }
}
