import { LoginPageActions, AuthApiActions } from '../actions';

export interface State {
  error: string | null;
  pending: boolean;
  message: { type: string, message: string } | null;
}

export const initialState: State = {
  error: null,
  pending: false,
  message: null
};

export function reducer(
  state = initialState,
  action:
    | AuthApiActions.AuthApiActionsUnion
    | LoginPageActions.LoginPageActionsUnion
): State {
  switch (action.type) {
    case LoginPageActions.LoginPageActionTypes.Login:
      return {
        ...state,
        error: null,
        pending: true
      };
    case LoginPageActions.LoginPageActionTypes.LoginWithJWT:
      return {
        ...state,
        error: null,
        pending: true
      };
    case LoginPageActions.LoginPageActionTypes.LoginThirdParty:
      return {
        ...state,
        error: null,
        pending: true
      };
    case AuthApiActions.AuthApiActionTypes.LoginSuccess:
      return {
        ...state,
        error: null,
        pending: false
      };
    case AuthApiActions.AuthApiActionTypes.LoginAdminSuccess:
      return {
        ...state,
        error: null,
        pending: false
      };
    case AuthApiActions.AuthApiActionTypes.LoginFailure:
      return {
        ...state,
        error: action.payload.error,
        pending: false
      };
    case LoginPageActions.LoginPageActionTypes.LoginShowMessage:
      return {
        ...state,
        error: null,
        pending: false,
        message: action.payload.message
      };
    case LoginPageActions.LoginPageActionTypes.ClearError:
      return {
        ...state,
        error: null,
        pending: false
      };
    case LoginPageActions.LoginPageActionTypes.ClearMessage:
      return {
        ...state,
        pending: false,
        message: null
      };
    default: {
      return state;
    }
  }
}

export const getError = (state: State) => state.error;
export const getPending = (state: State) => state.pending;
export const getMessage = (state: State) => state.message;
