<div class="card-body" data-cy="credit_card_form">
  <form *ngIf="creditInformation.userCredit > 0 || isPayLater" [formGroup]="creditForm">
    <div class="d-flex">
      <div class="pe-3">
        <toggle
          label=""
          [disabled]="isPayLater"
          formControlName="applyCredit">
        </toggle>
      </div>

      <div class="apply-credit flex-grow-1">
        <ng-container *ngIf="!isPayLater">
          <div class="title">
            {{ 'lodging.credits.apply' | translate }}:
            <span class="credit-amount fw-bold">
              {{ remainingCredit }}</span> - {{ 'lodging.credits.available' | translate: { amount: savePerNight } }}<span *ngIf="saveFees"> {{ 'lodging.credits.saved_fees' | translate: { amount: saveFees } }}</span>.
          </div>
        </ng-container>

        <ng-container *ngIf="isPayLater">
          <div class="title" [innerHTML]="'lodging.credits.pay_later_unavailable' | translate"></div>
        </ng-container>

        <div class="d-lg-flex align-items-baseline justify-content-between">
          <ng-container *ngIf="isPayLater">
            <a [routerLink]="[]" class="gtm-back-to-details small" (click)="goBackClick.emit()">&lt;&nbsp;{{ 'lodging.credits.go_back' | translate }}</a>
          </ng-container>

          <a target="_blank" routerLink="/invite/terms/20/10" fragment="creditsTermsTitle" class="gtm-invite small">
            {{ 'lodging.credits.credits_tac' | translate }}
          </a>
        </div>
      </div>
    </div>
  </form>
</div>
