import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SmartAppService } from '../../service/smart-app.service';

@Component({
  selector: 'smart-app-banner',
  templateUrl: './smart-app-banner.component.html',
  styleUrls: ['./smart-app-banner.component.scss']
})

export class SmartAppBannerComponent implements OnInit {
  displayBanner: boolean = false;

  @Output() isBannerVisible = new EventEmitter();

  constructor(private smartAppService: SmartAppService) { }

  ngOnInit(): void {
    this.displayBanner = this.smartAppService.isPlatformEnabled();
    this.notifyBannerStatus();
  }

  openInApp(event: Event) {
    event.preventDefault();
    const storeURL = this.smartAppService.getStoreURL();
    const timeout = this.smartAppService.isFirefoxOnIos() ? 2000 : 1000;
    const cleanedPathName = window.location.pathname.startsWith('/') 
      ? window.location.pathname.slice(1)
      : window.location.pathname;
    const utmParams = '?utm_source=mobile_web&utm_medium=owned&utm_campaign=app_redirect';
    const deepLinkURL = this.smartAppService.getDeepLinkUrl(cleanedPathName+utmParams);
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = deepLinkURL;

    document.body.appendChild(iframe);
    setTimeout(() => {
      document.body.removeChild(iframe);
      window.location.href = storeURL;
    }, timeout);
  }

  closeBanner() {
    this.displayBanner = false;
    this.notifyBannerStatus();
  }

  notifyBannerStatus() {
    this.isBannerVisible.emit(this.displayBanner)
  }
}
