import { CarsRecentSearchesActions, CarsRecentSearchesApiActions } from '../actions';
import { CarsRecentSearch } from 'app/shared/models/cars-recent-search';

export interface State {
  destinations: CarsRecentSearch[] | null;
  pending: boolean;
  error: any | null;
  loaded: boolean;
}

export const initialState: State = {
  destinations: [],
  pending: false,
  error: null,
  loaded: false
};

export function reducer(
  state = initialState,
  action: CarsRecentSearchesActions.CarsRecentSearchesActionsUnion | CarsRecentSearchesApiActions.CarsRecentSearchesApiActionsUnion
) {
  switch (action.type) {
    case CarsRecentSearchesActions.CarsRecentSearchesActionTypes.Load:
      return {
        ...state,
        destinations: [],
        error: null,
        pending: true
      };
    case CarsRecentSearchesApiActions.CarsRecentSearchesApiActionTypes.LoadSuccess:
      return {
        destinations: action.payload.destinations,
        error: null,
        pending: false,
        loaded: true
      };
    case CarsRecentSearchesApiActions.CarsRecentSearchesApiActionTypes.LoadFailure:
      return {
        destinations: [],
        error: action.payload.error,
        pending: false,
        loaded: false
      };
    case CarsRecentSearchesActions.CarsRecentSearchesActionTypes.SaveSearch:
      return {
        ...state,
        destinations: [action.payload, ...state.destinations]
      };
    default:
      return state;
  }
}

export const getDestinations = (state: State) => state.destinations;
export const getPending = (state: State) => state.pending;
export const getLoaded = (state: State) => state.loaded;
