import { Action } from '@ngrx/store';
import { Hotel } from 'app/hotels/models/hotel';

export enum SimilarHotelsActionTypes {
    GetSimilarHotels = '[Similar Hotels] Get Similar Hotels',
    GetSimilarHotelsSuccess = '[Similar Hotels] Get Similar Hotels Success',
    GetSimilarHotelsError = '[Similar Hotels] Get Similar Hotels Error',
}

export class GetSimilarHotels implements Action {
    readonly type = SimilarHotelsActionTypes.GetSimilarHotels;
  
    constructor(public payload: any) { }
  }
  
  export class GetSimilarHotelsSuccess implements Action {
    readonly type = SimilarHotelsActionTypes.GetSimilarHotelsSuccess;
  
    constructor(public payload: { hotels: Hotel[], meta: any }) {}
  }
  
  export class GetSimilarHotelsError implements Action {
    readonly type = SimilarHotelsActionTypes.GetSimilarHotelsError;
  
    constructor(public payload: { error: any }) { }
  }

  export type SimilarHotelsActionsUnion = 
  GetSimilarHotels | 
  GetSimilarHotelsSuccess | 
  GetSimilarHotelsError;