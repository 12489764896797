<div class="text-center mb-3">{{ 'password_reset.generic_message_code' | translate }}</div>
<verification-code-form
  [medium]="medium"
  [resendSuccess]="resendSuccess"
  [error]="error"
  (reSendCode)="resetCode()"
  (codeSubmitted)="submitCode($event)"
  (endTimerEvent)="endTimerEvent()">
</verification-code-form>
<div class="row align-items-center my-4">
  <div class="col-5">
    <hr />
  </div>
  <div class="col-2">
    <h4 class="text-center secondary-content">{{ 'co_traveler.or' | translate }}</h4>
  </div>
  <div class="col-5">
    <hr />
  </div>
</div>
<div class="row">
  <div [ngClass]="getVisibleButtonsSize() == 1 ? 'col-12' : 'col-6'" *ngFor="let button of visiblebuttons; index as i">
    <div [ngClass]="getVisibleButtonsSize() == 1 ? 'text-center' : i === 0 ? 'text-end' : 'text-start' ">
      <button type="button" [disabled]="button.disable" class="btn btn-outline-primary gtm-send-code" (click)="button.onClick(button.medium)" [attr.data-cy]="button.medium === 'sms' ? 'sms' : button.medium === 'code' ? 'code' : 'link'" >
        <fa-icon [icon]="getIconName(button.icon)"></fa-icon>
        {{ button.text }}
      </button>
    </div>
  </div>
</div>
