import { Action } from '@ngrx/store';

export enum RecommendedDestinationsActionTypes {
  Load = '[Recommended Destinations] Load'
}

export class Load implements Action {
  readonly type = RecommendedDestinationsActionTypes.Load;
}

export type RecommendedDestinationsActionsUnion = Load;
