import { Component, OnInit, SecurityContext } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';
import { GeneralHelper } from 'app/shared/helpers/general.helper';
import { DomSanitizer } from '@angular/platform-browser';
import { UrlHelper } from 'app/shared/helpers/url.helper';

@Component({
  selector: 'redirect',
  templateUrl: './redirect.html'
})
export class RedirectComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private generalHelper: GeneralHelper,
    private router: Router,
    private sanitizer: DomSanitizer,
    private urlHelper: UrlHelper
  ) {}

  ngOnInit() {
    const url = decodeURIComponent(this.activatedRoute.snapshot.queryParams['url']);
    const tostores = decodeURIComponent(this.activatedRoute.snapshot.queryParams['tostores']);

    if (tostores === 'true') {
      this.redirectToStores();
    } else if (url !== 'undefined') {
      setTimeout(
        () => {
          this.redirectTo(url);
        },
        2000
      );
    } else {
      this.router.navigate(['/']);
    }
  }

  redirectToStores() {
    setTimeout(
      () => {
        const osType = this.generalHelper.getMobileOperatingSystem();
        if (osType === 'Android') {
          return window.location.href = environment.androidStore;
        }
        return window.location.href = environment.iosStore;
      },
      1000
    );
  }

  private redirectTo(url: string): void {
    const sanitizedUrl = this.sanitizer.sanitize(SecurityContext.URL, url);
    if (this.urlHelper.isInternalUrl(url)) {
      this.router.navigateByUrl(url);
    } else if (this.urlHelper.isAllowedExternalUrl(url)) {
      window.location.href = sanitizedUrl;
    } else {
      this.router.navigate(['/']);
    }
  }

}
