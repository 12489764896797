import { Action } from '@ngrx/store';
import { User } from 'app/auth/models/user';

export enum AuthApiActionTypes {
  AvatarUpload = '[Auth/API] Avatar Upload',
  AvatarUploadSuccess = '[Auth/API] Avatar Uploade Success',
  ConfirmEmail = '[Auth/API] Confirm Email',
  FirstTimeLoginSuccess = '[Auth/API] First Time Login Success',
  FirstTimeAccessSuccess = '[Auth/API] First Time Access Success',
  FirstTimeAccessRestore = '[Auth/API] First Time Access Restore',
  LoadStatistics = '[Auth/API] Load Statistics',
  LoadStatisticsSuccess = '[Auth/API] Load Statistics Success',
  LoadStatisticsFailure = '[Auth/API] Load Statistics Failure',
  LoadUserConfigSuccess = '[Auth/API] Load User Config Success',
  LoginAdminSuccess = '[Auth/API] Login Admin Success',
  LoginSuccess = '[Auth/API] Login Success',
  LoginFailure = '[Auth/API] Login Failure',
  LoginRedirect = '[Auth/API] Login Redirect',
  LoginMarketingEventSuccess = '[Auth/API] Login Marketing Event Success',
  LoginMarketingEventFailure = '[Auth/API] Login Marketing Event Failure',
  LogoutSuccess = '[Auth/API] Logout Success',
  LogoutFailure = '[Auth/API] Logout Failure',
  LogoutMarketingEventSuccess = '[Auth/API] Logout Marketing Event Success',
  RegisterSuccess = '[Auth/API] Register Success',
  RegisterFailure = '[Auth/API] Register Failure',
  ReloadUser = '[Auth/API] Reload user',
  ReloadSSOUser = '[Auth/API] Reload SSO user',
  CheckVerifyUser = '[Auth/API] Check verify user',
  SelectEmployee = '[Auth/API] Select employee',
  SelectUser = '[Auth/API] Select user',
  UnselectUser = '[Auth/API] unselect user',
  SelectUserSuccess = '[Auth/API] Select user Success',
  SelectUserFailure = '[Auth/API] Select user failure',
  SessionTimeOut = '[Auth/API] Session Time Out',
  CheckVerifyFailure = '[Auth/API] Check verify failure',
  UpdateUserAccountInfo = '[Auth/API] Update user account info',
  UpdateUserInfo = '[Auth/API] Update User Info',
  UpdateMemberChangePasswordDate = '[Auth/API] Update member change password date',
  UpdateUserLanguage = '[Auth/API] Update users language',
  UpdateUserLanguageSuccess = '[Auth/API] Update users language Success',
  UpdateUserLanguageFailure = '[Auth/API] Update users language Failure',
  LoadUserPreferences = '[Auth/API] Load User Preferences',
  LoadUserPreferencesSuccess = '[Auth/API] Load User Preferences Success',
  LoadUserPreferencesFailure = '[Auth/API] Load User Preferences Failure',
  UpdateInvitedTraveler = '[Auth/API] Update invited traveler',
  UpdateInvitedTravelerSuccess = '[Auth/API] Update invited traveler Success',
  UpdateInvitedTravelerFailure = '[Auth/API] Update invited traveler Failure',
  LoadInvitedTravelerInfo = '[Auth/API] Load invited traveler Info',
  LoadInvitedTravelerInfoSuccess = '[Auth/API] Load invited traveler Info Success',
  LoadInvitedTravelerInfoFailure = '[Auth/API] Load invited traveler Info Failure',
  UpdatePassword = '[Auth/API] Update Password',
  UpdateInvitedTravelerId = '[Auth/API] Update Invited Traveler Id',
  ClearRedirectUserURL = '[Auth/API] Clear Redirect User URL',
  LoadUserTools = '[Auth/API] Load User Tools',
  LoadUserToolsSuccess = '[Auth/API] Load User Tools Success',
  LoadUserToolsFailure = '[Auth/API] Load User Tools Failure',
}

export class LoginSuccess implements Action {
  readonly type = AuthApiActionTypes.LoginSuccess;

  constructor(public payload: { user: any, showNewAccountModal?: boolean, method?: string , fromRegister?: boolean}) {}
}

export class LoginAdminSuccess implements Action {
  readonly type = AuthApiActionTypes.LoginAdminSuccess;
}

export class LoginFailure implements Action {
  readonly type = AuthApiActionTypes.LoginFailure;

  constructor(public payload: { error: any }) {}
}

export class ReloadUser implements Action {
  readonly type = AuthApiActionTypes.ReloadUser;

  constructor(public payload: { accessToken: any }) { }
}

export class ReloadSSOUser implements Action {
  readonly type = AuthApiActionTypes.ReloadSSOUser;

  constructor(public payload: { accessToken: any, redirect: string, platform?: string }) { }
}

export class SelectUser implements Action {
  readonly type = AuthApiActionTypes.SelectUser;

  constructor(public payload: { employeeNumber: string}) {}
}
export class SelectEmployee implements Action {
  readonly type = AuthApiActionTypes.SelectEmployee;

  constructor(public payload: { employeeNumber: string}) {}
}
export class UnselectUser implements Action {
  readonly type = AuthApiActionTypes.UnselectUser;
}

export class SelectUserFailure implements Action {
  readonly type = AuthApiActionTypes.SelectUserFailure;

  constructor(public payload: { error: any }) { }
}

export class SelectUserSuccess implements Action {
  readonly type = AuthApiActionTypes.SelectUserSuccess;

  constructor(public payload: { accessToken: any }) { }
}

export class LoginRedirect implements Action {
  readonly type = AuthApiActionTypes.LoginRedirect;
}

export class LogoutSuccess implements Action {
  readonly type = AuthApiActionTypes.LogoutSuccess;

  constructor(public payload?: { response: any } | any) {}
}

export class LogoutFailure implements Action {
  readonly type = AuthApiActionTypes.LogoutFailure;

  constructor(public payload: { error: any }) {}
}

export class SessionTimeOut implements Action {
  readonly type = AuthApiActionTypes.SessionTimeOut;

  constructor(public payload?: { response: boolean }) {}
}

export class RegisterSuccess implements Action {
  readonly type = AuthApiActionTypes.RegisterSuccess;

  constructor(public payload: { auth: any }) {}
}

export class RegisterFailure implements Action {
  readonly type = AuthApiActionTypes.RegisterFailure;

  constructor(public payload: { error: any }) {}
}

export class AvatarUpload implements Action {
  readonly type = AuthApiActionTypes.AvatarUpload;

  constructor(public payload: { avatarUrl: any }) { }
}

export class AvatarUploadSuccess implements Action {
  readonly type = AuthApiActionTypes.AvatarUploadSuccess;

  constructor(public payload: { avatarUrl: any }) { }
}

export class UpdatePassword implements Action {
  readonly type = AuthApiActionTypes.UpdatePassword;
}

export class LoginMarketingEventSuccess implements Action {
  readonly type = AuthApiActionTypes.LoginMarketingEventSuccess;

  constructor(public payload: { }) {}
}

export class LoginMarketingEventFailure implements Action {
  readonly type = AuthApiActionTypes.LoginMarketingEventFailure;

  constructor(public payload: { error: any }) {}
}

export class LogoutMarketingEventSuccess implements Action {
  readonly type = AuthApiActionTypes.LogoutMarketingEventSuccess;

  constructor(public payload: { }) {}
}

export class ConfirmEmail implements Action {
  readonly type = AuthApiActionTypes.ConfirmEmail;

  constructor(public payload: {}) { }
}

export class LoadStatistics implements Action {
  readonly type = AuthApiActionTypes.LoadStatistics;
}

export class LoadStatisticsSuccess implements Action {
  readonly type = AuthApiActionTypes.LoadStatisticsSuccess;

  constructor(public payload: { statistics: any }) {}
}

export class LoadStatisticsFailure implements Action {
  readonly type = AuthApiActionTypes.LoadStatisticsFailure;

  constructor(public payload: { error: any }) {}
}

export class FirstTimeLoginSuccess implements Action {
  readonly type = AuthApiActionTypes.FirstTimeLoginSuccess;
}

export class FirstTimeAccessSuccess implements Action {
  readonly type = AuthApiActionTypes.FirstTimeAccessSuccess;
}

export class FirstTimeAccessRestore implements Action {
  readonly type = AuthApiActionTypes.FirstTimeAccessRestore;
}

export class LoadUserConfigSuccess implements Action {
  readonly type = AuthApiActionTypes.LoadUserConfigSuccess;
}

export class CheckVerifyUser implements Action {
  readonly type = AuthApiActionTypes.CheckVerifyUser;

  constructor(public payload: { accessToken: string, url: string }) {}
}
export class CheckVerifyFailure implements Action {
  readonly type = AuthApiActionTypes.CheckVerifyFailure;

  constructor(public payload: { error: any, url: string }) { }
}
export class UpdateUserInfo implements Action {
  readonly type = AuthApiActionTypes.UpdateUserInfo;
  constructor(public payload) { }
}

export class UpdateUserAccountInfo implements Action {
  readonly type = AuthApiActionTypes.UpdateUserAccountInfo;

  constructor(public payload) { }
}

export class UpdateMemberChangePasswordDate implements Action {
  readonly type = AuthApiActionTypes.UpdateMemberChangePasswordDate;

  constructor(public payload: { newChangePasswordDate: Date }) {}
}

export class UpdateUserLanguage implements Action {
  readonly type = AuthApiActionTypes.UpdateUserLanguage;

  constructor(public payload: { language: string }) {}
}

export class UpdateUserLanguageSuccess implements Action {
  readonly type = AuthApiActionTypes.UpdateUserLanguageSuccess;
}

export class UpdateUserLanguageFailure implements Action {
  readonly type = AuthApiActionTypes.UpdateUserLanguageFailure;

  constructor(public payload: { error: any }) {}
}

export class LoadUserPreferences implements Action {
  readonly type = AuthApiActionTypes.LoadUserPreferences;
}

export class LoadUserPreferencesSuccess implements Action {
  readonly type = AuthApiActionTypes.LoadUserPreferencesSuccess;

  constructor(public payload: any) {}
}

export class LoadUserPreferencesFailure implements Action {
  readonly type = AuthApiActionTypes.LoadUserPreferencesFailure;

  constructor(public payload: { error: any }) {}
}

export class UpdateInvitedTraveler implements Action {
  readonly type = AuthApiActionTypes.UpdateInvitedTraveler;

  constructor(public payload: { travelerCode: string, employeeNumber: string, password: string }) {}
}

export class UpdateInvitedTravelerSuccess implements Action {
  readonly type = AuthApiActionTypes.UpdateInvitedTravelerSuccess;
}

export class UpdateInvitedTravelerFailure implements Action {
  readonly type = AuthApiActionTypes.UpdateInvitedTravelerFailure;

  constructor(public payload: { error: any }) {}
}

export class UpdateInvitedTravelerId implements Action {
  readonly type = AuthApiActionTypes.UpdateInvitedTravelerId;

  constructor(public payload: { id: string, eligibleTravelerId: string, airlineCode: string }) {}
}

export class LoadInvitedTravelerInfo implements Action {
  readonly type = AuthApiActionTypes.LoadInvitedTravelerInfo;

  constructor(public payload: { employeeNumber: string }) {}
}

export class LoadInvitedTravelerInfoSuccess implements Action {
  readonly type = AuthApiActionTypes.LoadInvitedTravelerInfoSuccess;

  constructor(public payload: { airlines: any }) {}
}

export class LoadInvitedTravelerInfoFailure implements Action {
  readonly type = AuthApiActionTypes.LoadInvitedTravelerInfoFailure;

  constructor(public payload: { error: any }) {}
}

export class ClearRedirectUserURL implements Action {
  readonly type = AuthApiActionTypes.ClearRedirectUserURL;
}

export class LoadUserTools implements Action {
  readonly type = AuthApiActionTypes.LoadUserTools;

  constructor(public payload: { user: User }) {}
}

export class LoadUserToolsSuccess implements Action {
  readonly type = AuthApiActionTypes.LoadUserToolsSuccess;

  constructor(public payload: { tools: any }) {}
}

export class LoadUserToolsFailure implements Action {
  readonly type = AuthApiActionTypes.LoadUserToolsFailure;

  constructor(public payload: { error: any }) {}
}

export type AuthApiActionsUnion = LoginSuccess
  | ClearRedirectUserURL
  | LoginAdminSuccess
  | LoginFailure
  | LoginRedirect
  | LogoutSuccess
  | LogoutFailure
  | SessionTimeOut
  | RegisterFailure
  | RegisterSuccess
  | AvatarUpload
  | AvatarUploadSuccess
  | LoginMarketingEventSuccess
  | LoginMarketingEventFailure
  | LogoutMarketingEventSuccess
  | ConfirmEmail
  | LoadUserConfigSuccess
  | FirstTimeLoginSuccess
  | FirstTimeAccessSuccess
  | FirstTimeAccessRestore
  | LoadStatistics
  | LoadStatisticsSuccess
  | LoadStatisticsFailure
  | SelectEmployee
  | SelectUser
  | UnselectUser
  | SelectUserSuccess
  | SelectUserFailure
  | ReloadUser
  | ReloadSSOUser
  | CheckVerifyUser
  | CheckVerifyFailure
  | UpdateUserInfo
  | UpdateUserAccountInfo
  | UpdateMemberChangePasswordDate
  | UpdateUserLanguage
  | UpdateUserLanguageSuccess
  | UpdateUserLanguageFailure
  | LoadUserPreferences
  | UpdateInvitedTraveler
  | UpdateInvitedTravelerSuccess
  | UpdateInvitedTravelerFailure
  | LoadInvitedTravelerInfo
  | LoadInvitedTravelerInfoSuccess
  | LoadInvitedTravelerInfoFailure
  | UpdatePassword
  | UpdateInvitedTravelerId
  | LoadUserTools
  | LoadUserToolsSuccess
  | LoadUserToolsFailure;
