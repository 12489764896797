import { HttpClient } from '@angular/common/http';
import { GoogleMapsService } from './shared/services/map/google-maps/google-maps.service';
import { MapkitService } from './shared/services/map/mapkit/mapkit.service';
import { DynamicScriptLoaderService } from './shared/services/dynamic-script-load/dynamic-script-load.service';
import { environment } from '@env/environment';
import { LeafletService } from 'app/shared/services/map/leaflet/leaflet.service';
import { MapLayerService } from 'app/shared/services/map/map-layer.service';

export function mapFactory(http: HttpClient, scriptLoader: DynamicScriptLoaderService, mapLayer: MapLayerService) {

  if (environment.mapkitEnabled) {
    return new MapkitService(scriptLoader);
  }

  if (environment.leafletEnabled) {
    return new LeafletService(mapLayer);
  }

  return new GoogleMapsService();
}
