import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'base-watchlist-card',
  templateUrl: './base-watchlist-card.html'
})

export class BaseWatchlistCardComponent {
  @Input() item;
  @Output() learnMoreClicked;
  @Output() bookNowClicked;
  @Output() deleteClicked;

  constructor() {
    this.learnMoreClicked = new EventEmitter();
    this.bookNowClicked = new EventEmitter();
    this.deleteClicked = new EventEmitter();
  }

  onLearnMoreClicked() {
    this.learnMoreClicked.emit({ item: this.item });
  }

  onBookNowClicked() {
    this.bookNowClicked.emit(this.item);
  }

  onDeleteClicked() {
    this.deleteClicked.emit(this.item);
  }
}
