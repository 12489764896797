import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { RecommendedDestination } from 'app/shared/models/recommended-destination';

@Component({
  selector: 'recommended-destinations',
  templateUrl: './recommended-destinations-section.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecommendedDestinationsSectionComponent {
  @Input() recommendedDestinations: RecommendedDestination[] = [];
  @Input() showTitle: boolean = true;
}
