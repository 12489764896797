import { Injectable } from '@angular/core';
import { FirebaseService } from '../services/firebase/firebase.service';

@Injectable({
  providedIn: 'root'
})
export class FirebaseHelper {

  constructor(
        private firebaseService: FirebaseService
    ) {}

  async getKey(key: string) {
    try {
      await this.firebaseService.fetchAndActivate();
    } catch (e) {
      return null;
    }
    return localStorage.getItem('remote_fetch') ? JSON.parse(localStorage.getItem('remote_fetch'))[key] : null;
  }

  isDatesValid(startDate, endDate) {
    const now = new Date();
    return now >= new Date(startDate) && now <= new Date(endDate);
  }
}
