<loading-indicator [fullScreen]="true" *ngIf="loading"></loading-indicator>
<section *ngIf="!loading" class="register-email container-fluid">
  <div class="row">
    <div class="col-lg-4 col-md-6 col-sm-8 col-xs-12 offset-lg-4 offset-md-3 offset-sm-2 pt-5">
      <div class="row pb-4">
        <div class="col-sm-7 align-self-center">
          <h4 class="primary-content">Hi, Traveler!</h4>
          <p class="secondary-content">We noticed you don't have a primary email for your account.<br>
            Please enter your preferred email to reach you in the future.</p>
        </div>
        <div class="col-sm-5">
          <img class="img-fluid" src="/angular_assets/images/homepage/paper_plane_2.png">
        </div>
      </div>
      <form [formGroup]="registerEmailForm" (ngSubmit)="submitForm()">
        <div class="form-group">
          <input type="text" id="email" class="form-control" formControlName="email"
            placeholder="{{ 'intro_page.email_label' | translate }}" required autocomplete="email"/>
          <div class='error-message' *ngIf="email.errors !== null && (email.touched)"
            [innerHtml]="formErrorsHelper.errorMessage(email, ('intro_page.email_label' | translate))">
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-sm-6 text-end">
            <button type="submit" class="btn btn-primary w-100 my-2">
              {{ 'mobile.action.complete_setup' | translate }}
            </button>
          </div>
          <div class="col-md-6 col-sm-6 text-end">
            <button type="button" (click)="cancelAndLogout()" class="btn btn-gray w-100 my-2">
              {{ 'flights.search.modals.cancel' | translate }} (Logout)
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
