<div class="indicator-static" [ngClass]="{ 'pb-5': showLoadMoreButton }" *ngIf="virtualScroll && cdkVirtual">
  <cdk-virtual-scroll-viewport [itemSize]="itemHeight" (scrolledIndexChange)="onIndexChange($event)">
    <ng-container *cdkVirtualFor="let item of items; let i = index; trackBy: trackByIdx">
      <ng-container
        [ngTemplateOutlet]="getItemTemplate(item)"
        [ngTemplateOutletContext]="getItemTemplateContext(i, item)">
      </ng-container>
    </ng-container>
  </cdk-virtual-scroll-viewport>

  <ng-container [ngTemplateOutlet]="extraContents"></ng-container>
</div>

<div class="indicator-static"
  *ngIf="!virtualScroll" infiniteScroll
  [scrollWindow]="true"
  [alwaysCallback]="true"
  (scrolled)="onScrolled()">
  <ng-container *ngIf="!loading || !hideListOnLoading">
    <div class="fadeInAnimation" *ngIf="displayAnimation"></div>

    <div *ngFor="let item of items; let i = index; trackBy: trackByIdx" id="{{i}}">
      <ng-container [ngTemplateOutlet]="getItemTemplate(item)" [ngTemplateOutletContext]="getItemTemplateContext(i, item)">
      </ng-container>
    </div>
  </ng-container>

  <loading-indicator [forceHeigth]="false" [fullScreen]="false" [mode]="spinnerMode" *ngIf="displayPagination && !isLastPage">
  </loading-indicator>

  <ng-container [ngTemplateOutlet]="extraContents"></ng-container>
</div>

<ng-template #extraContents>
  <div class="text-center" *ngIf="showLoadMoreButton">
    <button type="button" class="btn btn-primary w-100 rounded-0"
      [ngClass]="{ 'disabled': loadingMore }" [disabled]="loadingMore"
      (click)="loadMoreClick.emit(true);">
      {{ 'mobile.show_more' | translate }}
      <fa-icon *ngIf="loadingMore" [icon]="icons.faSpinner" class="float-end rotation-animation fa-spin"></fa-icon>
    </button>
  </div>

  <loading-indicator [forceHeigth]="true" [mode]="spinnerMode" *ngIf="loading && waitToScroll">
  </loading-indicator>

</ng-template>
