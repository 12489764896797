import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';
import { CustomValidators } from 'app/shared/validators/custom-validators';

@Component({
  selector: 'event-registration-form',
  templateUrl: './event-registration-form.html'
})

export class EventRegistrationFormComponent implements OnInit, OnChanges {
  @Output() submitted = new EventEmitter();
  @Output() logout = new EventEmitter();
  @Output() clear = new EventEmitter();
  @Input() airline;
  @Input() utmCampaign;
  @Input() errorMessage;
  @Input() pending;
  @Input() success;

  registrationForm: UntypedFormGroup;

  constructor(
    public formErrorsHelper: FormErrorsHelper,
    public translate: TranslateService
  ) { }

  ngOnInit() {
    this.registrationForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      airline_code: new UntypedFormControl('', Validators.required),
      employee_number: new UntypedFormControl('', Validators.required),
      marketing_event: new UntypedFormControl('true'),
      organization_id: new UntypedFormControl(''),
      password: new UntypedFormControl('', [Validators.required, CustomValidators.password]),
      utm_campaign: new UntypedFormControl(this.utmCampaign),
      utm_medium: new UntypedFormControl('offline-airline-event')
    });
    this.registrationForm.controls['airline_code'].setValue(this.airline.code);
    this.registrationForm.controls['organization_id'].setValue(this.airline.id);
    this.registrationForm.controls['utm_campaign'].setValue(this.utmCampaign);
    this.registrationForm.controls['marketing_event'].setValue(true);
  }

  ngOnChanges(changes) {
    if (changes.success && changes.success.currentValue === true) {
      this.resetForm();
    }
  }

  submit() {
    if (this.isValid()) {
      this.submitted.emit({
        ...this.registrationForm.value,
        registrationId: Date.now(),
      });
    } else {
      Object.keys(this.registrationForm.controls).forEach((field) => {
        const control = this.registrationForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }
  get email() { return this.registrationForm.get('email'); }
  get employee_number() { return this.registrationForm.get('employee_number'); }
  get password() { return this.registrationForm.get('password'); }

  isValid() {
    return this.registrationForm.valid;
  }

  closeAlert() {
    this.errorMessage = null;
    this.success = false;
  }

  resetForm() {
    this.registrationForm.controls['email'].setValue('');
    this.registrationForm.controls['employee_number'].setValue('');
    this.registrationForm.controls['password'].setValue('');
    this.errorMessage = null;
    Object.keys(this.registrationForm.controls).forEach((field) => {
      const control = this.registrationForm.get(field);
      control.markAsUntouched();
    });
  }

  resetButton() {
    this.resetForm();
    this.clear.emit(true);
  }

  onLogout() {
    this.logout.emit({});
  }
}
