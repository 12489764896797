import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import {
  EligiblesTravelers,
  EligibleTravelerStatus,
  InvitedTravelerStatus,
} from 'app/profile/models/eligibles-travelers';
import { ConfirmationDialogService } from 'app/shared/services/confirmation-dialog/confirmation-dialog.service';
import { EligibleTravelersService } from 'app/shared/services/eligible-travelers/eligible-travelers.service';

@Component({
  selector: 'eligible-traveler-flight-access-button',
  templateUrl: './eligible-traveler-flight-access-button.html',
  styleUrls: ['./eligible-traveler-flight-access-button.scss'],
})
export class EligibleTravelerFlightAccessButtonComponent {
  @Input() areOtherEligibleTravelers: boolean;
  @Input() eligibleTraveler;
  @Input() eligibleTravelerWithStatus: EligiblesTravelers;
  @Input() flightAccessForm;
  @Input() travelerId;
  @Output() closeModal = new EventEmitter();

  readonly ELIGIBLE_TRAVELER_STATUS = EligibleTravelerStatus;
  readonly INVITED_TRAVELER_STATUS = InvitedTravelerStatus;

  constructor(
    private confirmationDialogService: ConfirmationDialogService,
    private eligibleTravelersService: EligibleTravelersService,
    private translateService: TranslateService,
  ) {}

  calculateAge(birthDate) {
    const bday = new Date(birthDate);
    const timeDiff = Math.abs(Date.now() - bday.getTime());
    return Math.floor(timeDiff / (1000 * 3600 * 24) / 365);
  }

  giveAccess(successMessage?: string) {
    const params = {
      bookerId: Number(this.travelerId),
      eligibleTravelers: this.flightAccessForm.value.eligibleTravelers.filter((et) => et.enabled).map((et) => et.id),
      employeeId: Number(this.eligibleTraveler.employeeId),
    };
    this.eligibleTravelersService
      .giveAccess(params)
      .pipe(take(1))
      .subscribe(
        () => {
          successMessage =
            successMessage ?? this.translateService.instant('profile.eligible_travelers.success_update_access');
          this.closeModal.emit({ success: successMessage });
        },
        (e) => {
          const error = e?.error?.translation_key
            ? this.translateService.instant(`profile.eligible_travelers.${e.error.translation_key}`)
            : 'Error';
          this.closeModal.emit({ error });
        },
      );
  }

  sendInvitation() {
    if (this.validForm()) {
      const params = {
        eligibleId: this.eligibleTraveler.id,
        employeeId: this.eligibleTraveler.employeeId,
        email: this.flightAccessForm.value.email,
      };
      this.eligibleTravelersService
        .sendInviteCode(params)
        .pipe(take(1))
        .subscribe(
          () => {
            const successMessage = this.translateService.instant('profile.eligible_travelers.success_message');
            this.giveAccess(successMessage);
          },
          (e) => {
            const error = e?.error?.translation_key
              ? this.translateService.instant(`profile.eligible_travelers.${e.error.translation_key}`)
              : 'Error';
            this.closeModal.emit({ error });
          },
        );
    }
  }

  async onRemoveAccessClick() {
    const { id: eligibleId, firstName, lastName } = this.eligibleTraveler;

    const name = `${firstName} ${lastName}`;
    const confirmationDialog = await this.confirmationDialogService.confirm({
      title: 'profile.travelers.submit_alert_title',
      message: this.translateService.instant('profile.eligible_travelers.remove_access_confirm_message', { name }),
      btnOkText: 'profile.travelers.traveler_confirm',
      btnCancelText: 'profile.travelers.traveler_cancel',
    });

    if (confirmationDialog && eligibleId) {
      this.eligibleTravelersService
        .removeTravelerAccess(eligibleId, this.eligibleTraveler.employeeId)
        .pipe(take(1))
        .subscribe(
          () => {
            const successMessage = this.translateService.instant('profile.eligible_travelers.success_remove_access');
            this.closeModal.emit({ success: successMessage });
          },
          () => {
            const errorMessage = this.translateService.instant('profile.travelers.submit_error');
            this.closeModal.emit({ error: errorMessage });
          },
        );
    }
  }

  validAge() {
    return this.eligibleTraveler.birthDate && this.calculateAge(this.eligibleTraveler.birthDate) >= 18;
  }

  validForm() {
    this.flightAccessForm.get('email').markAsTouched();
    if (!this.validAge()) {
      const error = this.translateService.instant('profile.eligible_travelers.age_validation');
      this.closeModal.emit({ error });
    }

    return this.flightAccessForm.valid;
  }
}
