import { Action } from '@ngrx/store';
import { Modal } from '../../models/modal.model';

export enum ModalActionTypes {
  Show = '[Modal] Show',
  Hide = '[Modal] Hide'
}

export class Show implements Action {
  readonly type = ModalActionTypes.Show;

  constructor(public payload: { data: Modal }) { }
}

export class Hide implements Action {
  readonly type = ModalActionTypes.Hide;
}

export type ModalActionsUnion = Show
  | Hide;
