<section id="forgot-password-request">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-sm-12 text-center py-4">
        <h1>{{ 'password_reset.title' | translate }}</h1>
      </div>
      <loading-indicator [mode]="'fill-parent'" *ngIf="loading"></loading-indicator>
      <ng-container *ngIf="!loading && !success">
        <div class="col-md-8 col-sm-12">
          <ngb-alert *ngIf="msgError" [type]="type" (closed)="closeAlert()">{{ msgError }}</ngb-alert>
          <ngb-alert *ngIf="msgInfo" [type]="'info'" (closed)="closeAlert()">{{ msgInfo }}</ngb-alert>
          <reset-password-form
            *ngIf="!showCodeInput && !showResetOptions"
            [defaultAirlineCode]="defaultAirlineCode"
            [isWhiteLabel]="isWhiteLabelEnabled"
            (verifyAccountAction)="verifyAccountCredentials($event)">
          </reset-password-form>
          <send-code-options *ngIf="showResetOptions && !showCodeInput"
            [enabledResetOptions]="enabledResetOptions"
            (sendResetLink)="resetLink()"
            (sendResetCode)="resetCode($event)">
          </send-code-options>
          <reset-password-code-form
            *ngIf="showCodeInput"
            [enabledResetOptions]="enabledResetOptions"
            [resendSuccess]="codeResendSuccess"
            [medium]="credentials.medium"
            (sendResetLink)="resetLink()"
            (reSendCode)="resetCode()"
            (switchCodeEmit)="switchCode($event)"
            (codeSubmitted)="submitCode($event)">
          </reset-password-code-form>
        </div>
      </ng-container>
      <ng-container *ngIf="!loading && success">
        <div class="row">
          <div class="col-8 mx-auto success-message" data-cy="message-link">
            <div class="fw-bold">{{ 'password_reset.message_link.title' | translate }}</div>
            <div class="mt-1">{{ 'password_reset.message_link.first_content' | translate }}</div>
            <div class="mt-3">{{ 'password_reset.message_link.second_content' | translate }}</div>
          </div>
          <div class="col-8 mx-auto text-center mt-4">
            <button type="button" class="btn btn-primary btn-lg" (click)="closeAlert()" data-cy="start-over">
              {{ 'password_reset.start_over' | translate }}
            </button>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="row mt-3 text-center" *ngIf="!showResetOptions">
      <div class="col-md-8 offset-md-2 col-sm-12">
        <h6>{{ 'forgot_password_help_text_header' | translate }}</h6>
        <div class="secondary-content" [innerHtml]="'forgot_password_help_text' | translate"></div>
      </div>
    </div>
  </div>
</section>
