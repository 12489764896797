import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { AllInterestsActions } from '../actions';
import { map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { MailchimpService } from 'app/profile/services/mailchimp/mailchimp.service';

@Injectable()
export class AllInterestsEffects {

  loadAllInterests$ = createEffect(()=>
    this.actions$.pipe(
      ofType<AllInterestsActions.LoadAllInterests>(AllInterestsActions.AllInterestsActionTypes.LoadAllInterests),
      switchMap(() => {
        return this.mailchimpService.getAllInterests().pipe(
          map(response => new AllInterestsActions.LoadAllInterestsSuccess({ response })),
          catchError(error => of(new AllInterestsActions.LoadAllInterestsFailure(error)))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private mailchimpService: MailchimpService
  ) {}
}
