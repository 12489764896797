<div class="position-relative">
  <loading-indicator *ngIf="recentSearchesPending" [fullScreen]="false"></loading-indicator>
  <form *ngIf="!recentSearchesPending" [formGroup]="carSearchForm" autocomplete="off">
    <div class="row">
      <div class="col-sm-6 col-xs-12 pb-3"> <locations-autocomplete-component
          [form]="carSearchForm"
          (locationSelected)="locationSelected($event, 'pickup')"
          [controlName]="'pickupLocation'"
          [label]=""
          placeholder="{{ 'cars.form_labels.pickup_location' | translate }}"
          [recentSearches]="recentSearches"
          [locationType]="'cars'"
          [showModalForMobile]="true"
          [hideHeader]="true"
          [showCurrentLocation]="true">
        </locations-autocomplete-component>
        <div class='error-message' *ngIf="(pickupLocation.touched && pickupLocation.errors) || (pickupLocationId.touched && pickupLocationId.errors)"
          [innerHtml]="pickupLocationError">
        </div>
      </div>
      <div *ngIf="!mobileView" class="col-sm-6 col-xs-12" [ngClass]="{'pb-3': !isIE ,'pt-3 pb-2' : isIE }">
        <div class="form-floating">
          <input
            #inputField
            #instance="ngbTypeahead"
            ngbTooltip="{{ 'cars.form_labels.country_of_residence' | translate }}"
            id="countries"
            type="text"
            class="form-control"
            autocomplete="disable"
            placeholder="{{ 'cars.form_labels.country_of_residence' | translate }}"
            class="form-control with-background"
            [value]="selectedCountry.label"
            [ngbTypeahead]="searchCountry"
            [resultTemplate]="countryTemplate"
            [inputFormatter]="formatterCountry"
            (selectItem)="countriesSelected($event)"
            (blur)="countryOnBlur()" />
          <label for="countries">
            {{ 'cars.form_labels.country_of_residence' | translate }}
          </label>
        </div>
        <ng-template #countryTemplate let-r="result" let-t="term">
          {{ r.name }}
        </ng-template>
        <div class='error-message' *ngIf="country.touched && country.errors"
          [innerHtml]="countryError">
        </div>
      </div>
    </div>
    <div class="row">
      <div *ngIf="dropoff" class="col-sm-6 col-xs-12 pb-3">
        <locations-autocomplete-component
          [form]="carSearchForm"
          (locationSelected)="locationSelected($event, 'dropoff')"
          [controlName]="'dropoffLocation'"
          [label]="'cars.form_labels.dropoff_location' | translate"
          placeholder="{{ 'cars.form_labels.dropoff_location' | translate }}"
          [locationType]="'cars'"
          [showModalForMobile]="true"
          [hideHeader]="true">
        </locations-autocomplete-component>
      </div>
      <div *ngIf="mobileView" class="col-sm-6 col-xs-12 pb-3">
        <div class="form-floating">
          <input [ngbTypeahead]="searchCountry"
            ngbTooltip="{{ 'cars.form_labels.country_of_residence' | translate }}"
            id="countries" type="text" class="form-control" autocomplete="disable"
            placeholder="{{ 'cars.form_labels.country_of_residence' | translate }}"
            class="form-control with-background"
            [value]="selectedCountry.label"
            (selectItem)="countriesSelected($event)"
            (blur)="countryOnBlur()"
            [resultTemplate]="countryTemplate"
            [inputFormatter]="formatterCountry" />
          <label for="countries">
            {{ 'cars.form_labels.country_of_residence' | translate }}
          </label>
        </div>
        <ng-template #countryTemplate let-r="result" let-t="term">
          {{ r.name }}
        </ng-template>
        <div class='error-message' *ngIf="country.touched && country.errors"
          [innerHtml]="countryError">
        </div>
      </div>
      <div class="col-lg-8 col-xs-12 pb-3">
        <datepicker-range
          [startDate]="carSearchForm.value.pickupDate"
          [endDate]="carSearchForm.value.dropoffDate"
          [placeholderStart]="'cars.form_labels.pickup_date' | translate"
          [placeholderEnd]="'cars.form_labels.dropoff_date' | translate"
          [inputName]="'carDates'"
          [singleInput]="true"
          [showModalForMobile]="true"
          [showCalendarIcon]="true"
          [enableOneDayOption]="true"
          (datesSelected)="onDatesSelected($event)">
        </datepicker-range>
        <input type="text" formControlName="pickupDate" id="pickupDate" class="d-none">
        <input type="text" formControlName="dropoffDate" id="dropoffDate" class="d-none">
      </div>
      <div class="col-lg-4" *ngIf="!isMobileView">
        <button *ngIf="!loading" type="submit" class="btn btn-md btn-primary w-100  gtm-car-search-btn" (click)="search()" data-cy="carSearchButton"  >{{ 'lodging.search_form.search__id90_es_ru' | translate }}</button>
        <div *ngIf="loading">{{ 'loading.general' | translate }}</div>
      </div>
      <div class="col-lg-4 col-sm-6 col-xs-2 pb-3 form-floating">
        <div class="form-floating">
          <select class="form-control select-time form-select with-background" formControlName="pickupTime" required placement="top" ngbTooltip="{{ 'cars.form_labels.pickup_time' | translate }}">
            <option *ngFor="let time of times" value="{{time.value}}">{{time.display}}</option>
          </select>
          <label for="countries">
            {{ 'cars.form_labels.pickup_time' | translate}}
          </label>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 col-xs-2 pb-3">
        <div class="form-floating">
          <select class="form-control select-time form-select with-background" formControlName="dropoffTime" required placement="top" ngbTooltip="{{ 'cars.form_labels.dropoff_time' | translate }}">
            <option *ngFor="let time of times" value="{{time.value}}">{{time.display}}</option>
          </select>
          <label for="countries">
            {{ 'cars.form_labels.dropoff_time' | translate}}
          </label>
        </div>
      </div>
      <div class="col-lg-4 col-sm-2 d-flex align-content-center flex-wrap checkbox-over25">
        <div class="form-group form-check m-0">
          <input type="checkbox" class="filled-in form-check-input" [id]="componentId + '_over_25'" name="over_25" formControlName="over25">
          <label class="color-light form-check-label" [for]="componentId + '_over_25'">{{ 'cars.form_labels.older_than_25' | translate }}</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm d-flex align-content-center flex-wrap">
        <div class="form-group form-check m-0">
          <input type="checkbox" [checked]="dropoff" (change)="onDropOffChange($event)" class="filled-in form-check-input" [id]="componentId + '_different_dropoff'" name="different_dropoff">
          <label class="color-light form-check-label" [for]="componentId + '_different_dropoff'">{{ 'cars.form_labels.option' | translate }}</label>
        </div>
      </div>
    </div>
    <div class="col-12 mt-2" *ngIf="isMobileView">
      <button *ngIf="!loading" type="submit" class="btn btn-md btn-primary w-100  gtm-car-search-btn" data-cy="carSearchButton" (click)="search()" >{{ 'lodging.search_form.search__id90_es_ru' | translate }}</button>
      <div *ngIf="loading">{{ 'loading.general' | translate }}</div>
    </div>
  </form>
  <div class="row">
    <div class="col-md-12">
      <div class="tertiary-content pt-3"><a href="https://cars.cartrawler.com/id90travel/?clientID=533939#/search" target="_blank">{{ 'cars.form_labels.airport_transfer_language' | translate }}</a></div>
    </div>
  </div>
</div>
