import { Component, EventEmitter, Input, Output, ViewEncapsulation, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import { Language } from 'app/auth/models/language';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'language-selector-component',
  templateUrl: 'language-selector.html',
  styleUrls: ['language-selector.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class LanguageSelectorComponent implements OnChanges {
  @Input() forMobile = false;
  @Input() languages: Language[] = [];
  @Input() userLanguage: string;
  @Output() languageSelected = new EventEmitter();
  selectedLanguage: Language;
  isCollapsed = true;
  icons = { faGlobe };

  readonly DEFAULT_LANGUAGE = { code: 'en', name: 'English', flag: 'us' };

  ngOnChanges() {
    if (this.languages.length > 0 && this.userLanguage) return this.selectedLanguage = this.getLanguage(this.userLanguage);
    if (this.languages.length) this.selectedLanguage = this.getLanguage(this.DEFAULT_LANGUAGE.code);
  }

  getLanguage(language: string) {
    return this.languages.find((l) => {
      return l.code === language;
    });
  }

  selectLang(lang: Language) {
    this.languageSelected.emit(lang);
  }
}
