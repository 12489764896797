import { Action } from '@ngrx/store';

export enum LanguageActionTypes {
  LoadLanguages = '[Languages] Load Languages',
  LoadLanguagesSuccess = '[Languages] Load Languages Success',
  LoadLanguagesFailure = '[Languages] Load Languages Failure',
}

export class LoadLanguages implements Action {
  readonly type = LanguageActionTypes.LoadLanguages;
}

export class LoadLanguagesSuccess implements Action {
  readonly type = LanguageActionTypes.LoadLanguagesSuccess;

  constructor(public payload: { languages: any }) { }
}

export class LoadLanguagesFailure implements Action {
  readonly type = LanguageActionTypes.LoadLanguagesFailure;

  constructor(public payload: { error: any }) { }
}

export type LanguageActionsUnion = LoadLanguages
  | LoadLanguagesSuccess
  | LoadLanguagesFailure;
