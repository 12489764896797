<section *ngIf="recommendedDestinations.length > 0"
  [ngClass]="{'gray-blue-background py-5': showTitle}">
  <div class="container">
    <div class="row">
      <div class="col" *ngIf="showTitle">
        <h2>{{'mobile.home.recommended_destinations' | translate }}</h2>
      </div>
    </div>
    <destinations [source]="'recommendedDestinations'"
                  [destinationsList]="recommendedDestinations"
                  [showImageSquare]="!showTitle">
    </destinations>
  </div>
</section>