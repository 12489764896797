import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResponsiveService {
  private isMobile = new Subject<boolean>();
  public screenWidth: string;
  private mobileBreakpoint = 768;
  private largeBreakpoint = 992;

  constructor() {
    this.checkWidth();
  }

  getScreenSizeAlias(): string {
    const width = window.innerWidth;
    if (width <= this.mobileBreakpoint) {
      return 'small';
    }

    if (width > this.mobileBreakpoint && width <= this.largeBreakpoint) {
      return 'medium';
    }

    return 'large';
  }

  onMobileChange(status: boolean) {
    this.isMobile.next(status);
  }

  getMobileStatus(): Observable<any> {
    return this.isMobile.asObservable();
  }

  checkWidth() {
    const width = window.innerWidth;
    if (width <= this.mobileBreakpoint) {
      this.screenWidth = 'sm';
      this.onMobileChange(true);
    } else if (width > this.mobileBreakpoint && width <= this.largeBreakpoint) {
      this.screenWidth = 'md';
      this.onMobileChange(false);
    } else {
      this.screenWidth = 'lg';
      this.onMobileChange(false);
    }
  }
}
