import { Injectable } from '@angular/core';
import { GeneralHelper } from 'app/shared/helpers/general.helper';
import { ActivatedRoute } from '@angular/router';
import { SessionService } from '../services/session/session.service';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class RedirectHelper {
  id90AppUrl = 'id90travelapp://';

  constructor(
    private generalHelper: GeneralHelper,
    private route: ActivatedRoute,
    private sessionService: SessionService
  ) { }

  async openApp(url) {
    if (this.shouldTryToOpenApp()) {
      setTimeout(() => {
        window.location.href = window.location.href;
      },         5);
      window.location.href = `${this.id90AppUrl}${url}`;
    }
  }

  shouldTryToOpenApp() {
    let fromMailchimp = false;
    if (this.generalHelper.getMobileOperatingSystem()) {
      this.route.queryParams.pipe(take(1)).subscribe((params) => {
        if (params.from === 'mailchimp') {
          fromMailchimp = true;
        }
      });
      return fromMailchimp;
    }
  }

  getHomeUrl(logged: boolean) {
    const homeUrl = '/search/hotels';
    const defaultUrl = '/'
    return logged ? homeUrl : defaultUrl; 
  }

  redirectFromLogin(user, data) {
    if (user.first_time_login || data.credentials.recovery_from_admin) {
      const recovery = data.credentials.recovery_from_admin ? '?recovery=true' : '';
      return `/first_time/new${recovery}`;
    }
    if (this.sessionService.getShareCode()) {
      return '/share-itinerary';
    }
    if (user.generic_user || user.account.restricted) {
      return '/flights';
    }
    if (data.credentials.redirect && data.credentials.redirect !== '/') {
      return data.credentials.redirect;
    }
    return '/hotels';
  }
}
