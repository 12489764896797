import { Action } from '@ngrx/store';

export enum SettingsActionTypes {
  LoadSettings = '[Profile] Load Settings',
  LoadSettingsSuccess = '[Profile] Load Settings Success',
  LoadSettingsFailure = '[Profile] Load Settings Failure'
}

export class LoadSettings implements Action {
  readonly type = SettingsActionTypes.LoadSettings;
}

export class LoadSettingsSuccess implements Action {
  readonly type = SettingsActionTypes.LoadSettingsSuccess;

  constructor(public payload: { settings: any }) {}
}

export class LoadSettingsFailure implements Action {
  readonly type = SettingsActionTypes.LoadSettingsFailure;

  constructor(public payload: { error: any }) {}
}

export type SettingsActionsUnion = LoadSettings
  | LoadSettingsSuccess
  | LoadSettingsFailure;
