import { ProfileUserActions } from '../actions';
import { ProfileUserState } from 'app/profile/models/profile-user.interface';

export const initialState: ProfileUserState = {
  error: null,
  pending: false,
  config: null,
  info: null,
  brandUserStructure: null,
  showBusinessToggle: null
};

export function reducer(
  state = initialState,
  action:
    | ProfileUserActions.ProfileUserActionsUnion
): ProfileUserState {
  switch (action.type) {
    case ProfileUserActions.ProfileUserActionTypes.LoadConfigProfile:
      return {
        ...state,
        pending: true
      };
    case ProfileUserActions.ProfileUserActionTypes.LoadConfigProfileSuccess:
      return {
        ...state,
        pending: false,
        config: action.payload.config,
        error: initialState.error
      };
    case ProfileUserActions.ProfileUserActionTypes.LoadConfigProfileFailure:
      return {
        ...state,
        pending: false,
        error: action.payload.error
      };
    case ProfileUserActions.ProfileUserActionTypes.LoadUserProfile:
      return {
        ...state,
        pending: true
      };
    case ProfileUserActions.ProfileUserActionTypes.LoadUserProfileSuccess:
      return {
        ...state,
        pending: false,
        info: action.payload.info,
        error: initialState.error
      };
    case ProfileUserActions.ProfileUserActionTypes.LoadUserProfileFailure:
      return {
        ...state,
        pending: false,
        error: action.payload.error
      };
    case ProfileUserActions.ProfileUserActionTypes.LoadUserProfileStructure:
      return {
        ...state,
        pending: true
      };
    case ProfileUserActions.ProfileUserActionTypes.LoadUserProfileStructureSuccess:
      return {
        ...state,
        pending: false,
        brandUserStructure: action.payload.brandUserStructure,
        error: initialState.error
      };
    case ProfileUserActions.ProfileUserActionTypes.LoadUserProfileStructureFailure:
      return {
        ...state,
        pending: false,
        error: action.payload.error
      };
    case ProfileUserActions.ProfileUserActionTypes.LoadBusinessToggle:
      return {
        ...state,
        pending: true
      };
    case ProfileUserActions.ProfileUserActionTypes.LoadBusinessToggleSuccess:
      return {
        ...state,
        pending: false,
        showBusinessToggle: action.payload.showBusinessToggle,
        error: initialState.error
      };
    case ProfileUserActions.ProfileUserActionTypes.LoadBusinessToggleFailure:
      return {
        ...state,
        pending: false,
        error: action.payload.error
      };
    case ProfileUserActions.ProfileUserActionTypes.UpdateUserProfile:
      return {
        ...state,
        info: { ...state.info, ...action.payload }
      };
    default: {
      return state;
    }
  }
}

export const getError = (state: ProfileUserState) => state.error;
export const getPending = (state: ProfileUserState) => state.pending;
export const getConfig = (state: ProfileUserState) => state.config;
export const getUserProfile = (state: ProfileUserState) => state.info;
export const getBusinessToggle = (state: ProfileUserState) => state.showBusinessToggle;
export const getProfileStructure = (state: ProfileUserState) => state.brandUserStructure;
