import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

interface Button {
  url: string;
  display: string;
  image: string;
  external: boolean;
  class: string;
  shouldRedirect: boolean;
}

@Component({
  selector: 'button-list',
  styleUrls: ['./button-list.scss'],
  templateUrl: './button-list.html'
})

export class ButtonListComponent {

  @Input() buttons: Button[];
  @Output() buttonSelected = new EventEmitter();

  constructor(
    private router: Router
  ) {}

  handleButtonClick(button: Button) {
    this.buttonSelected.emit(button);
    if (button.shouldRedirect) {
      return this.router.navigate([button.url]);
    }
  }

}
