import { TitleCasePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { AccountValidationActions } from 'app/auth/store';
import * as fromAuth from 'app/auth/store/reducers';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';
import { ConfigFlags } from 'app/shared/models/configFlags';
import * as fromAirlines from 'app/shared/store/reducers';
import * as fromShared from 'app/shared/store/reducers';
import * as fromRoot from 'reducers';
import { Observable, Subject } from 'rxjs';
import { map, skipWhile, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'account-access',
  templateUrl: './account-access.html'
})

export class AccountAccessPage implements OnInit, OnDestroy {
  userForm;
  airlineCode;
  airlineName;
  organizationId;
  error;
  airlines$: any = this.store.pipe(select(fromAirlines.getAirlines));
  userValidationSuccess$ = this.store.pipe(select(fromAuth.getUsernameValidationSuccess));
  userValidationError$ = this.store.pipe(select(fromAuth.getUsernameValidationError));
  pending$ = this.store.pipe(select(fromAuth.getUsernameValidationPending));
  configFlags$ = this.store.pipe(select(fromShared.getConfigFlag));
  private ngUnsubscribe = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    public formErrorsHelper: FormErrorsHelper,
    private store: Store<fromRoot.State>,
    private titleCasePipe: TitleCasePipe
  ) { }

  ngOnInit() {
    this.store.dispatch(new AccountValidationActions.ClearState());
    this.airlineCode = this.activatedRoute.snapshot.params.airline;
    this.airlines$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((airlines) => {
      if (airlines.length > 0) {
        const airline = airlines.find(airline => airline.code.toLowerCase() === this.airlineCode.toLowerCase());
        this.airlineName = this.titleCasePipe.transform(airline.display_name.split('(')[0]);
        this.organizationId = airline.id;
      }
    });
    this.userForm = this.formBuilder.group({
      username: new UntypedFormControl('', [Validators.required])
    });
    this.listenForError();
    this.listenForSuccess();
  }

  get allowValidation(): Observable<boolean> {
    return this.configFlags$.pipe(
      skipWhile(() => !this.airlineCode),
      take(1),
      map((configs: ConfigFlags) => {
        const skipValidationAirlines = configs?.skip_account_validation?.split(' ') ?? [];
        return !skipValidationAirlines.includes(this.airlineCode.toUpperCase());
      })
    );
  }

  listenForSuccess() {
    this.userValidationSuccess$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
      if (response) {
        if (response.valid && !response.ftl) {
          this.router.navigate([`/validate-account/${this.airlineCode}/${this.organizationId}/${this.userForm.value.username}`]);
        } else if (response.valid && response.ftl) {
          this.router.navigate([`/password/new/ftl`]);
        } else {
          this.error = true;
        }
      }
    });
  }

  listenForError() {
    this.userValidationError$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((error) => {
      if (error) {
        this.error = error;
      }
    });
  }

  validateAccount() {
    if (!this.userForm.valid) {
      Object.keys(this.userForm.controls).forEach((field) => {
        const control = this.userForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    } else {
      const credentials = {
        employeeNumber: this.userForm.value.username,
        airlineCode: this.airlineCode,
        organizationId: this.organizationId
      };
      this.store.dispatch(new AccountValidationActions.ValidateUser({ credentials }));
    }
  }

  login() {
    return this.router.navigate(['/login']);
  }

  get username() { return this.userForm.get('username'); }

  closeAlert() {
    this.error = false;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
