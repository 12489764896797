import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MultifactorAuthenticationService {

  constructor(private http: HttpClient) { }

  sendEmailCode(params) {
    return this.http.post<any>(`${environment.apiUrl}/api/v1/mms_mfa_email/send_verification_code`, params);
  }

  sendSMSCode(params) {
    return this.http.post<any>(`${environment.apiUrl}/api/v1/mms_sms/send_verification_code`, params);
  }

  enroll(params: any) {
    return this.http.post<any>(`${environment.apiUrl}/api/v1/mms_mfa/enroll`, params);
  }

  verifyCode(params: any) {
    return this.http.post<any>(`${environment.apiUrl}/api/v1/mms_mfa/verify_code`, params);
  }

  dismissVerifyModal() {
    return this.http.post<any>(`${environment.apiUrl}/api/v1/membership/skip_mfa`, {});
  }

  insertNewIpAddress() {
    return this.http.post<any>(`${environment.apiUrl}/api/v1/mms_mfa/add_ip_address`, {});
  }
}
