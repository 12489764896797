import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import * as fromAirlines from 'app/shared/store/reducers';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as fromRoot from '../../../../reducers';
import { LoginPageActions } from '../../store/actions';
import * as fromAuth from '../../store/reducers';

@Component({
  selector: 'login-modal',
  templateUrl: 'login-modal.html'
})

export class LoginModalComponent implements OnInit, OnDestroy{
  airline;
  employeeNumber = '';
  email = '';
  pending$ = this.store.pipe(select(fromAuth.getLoginPagePending));
  message$ = this.store.pipe(select(fromAuth.getLoginPageMessage));
  userAccount$ = this.store.pipe(select(fromAuth.getUserAccount));
  sessionTimeOut$ = this.store.pipe(select(fromAuth.getSessionTimeOut));
  airlines$: any = this.store.pipe(select(fromAirlines.getAirlines));
  private ngUnsubscribe$ = new Subject<void>();

  constructor(
    public router: Router,
    private store: Store<fromRoot.State>,
    private activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.userAccount$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((user) => {
      if (user) {
        this.airline = user.airlineCode;
        if (user.invitedByUserId) {
          this.email = user.employeeNumber.toString();
        } else {
          this.employeeNumber = user.employeeNumber.toString();
        }
      }
    });

    this.sessionTimeOut$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((sessionTimeOut) => {
      if (!sessionTimeOut) {
        this.activeModal.close(true);
      }
    });
  }

  dismiss() {
    this.activeModal.dismiss();
    return this.router.navigate(['/login'], { queryParams: { redirect: encodeURIComponent(this.router.url) } });
  }

  login(credentials) {
    credentials.remember_me = credentials.remember_me ? '1' : '0';
    credentials.check_generic = true;
    this.store.dispatch(new LoginPageActions.ClearError());
    this.store.dispatch(new LoginPageActions.ClearMessage());
    this.store.dispatch(new LoginPageActions.Login({ credentials, fromModal: true }));
  }

  closeAlert() {
    const message = null;
    this.store.dispatch(new LoginPageActions.LoginShowMessage({ message }));
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
