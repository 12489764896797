import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { CarsRecentSearchesActions, CarsRecentSearchesApiActions } from '../actions';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { RecentSearchesService } from '../../services/recent-searches/recent-searches.service';

@Injectable()
export class CarsRecentSearchesEffects {

  load$ = createEffect(()=>
    this.actions$.pipe(
      ofType<CarsRecentSearchesActions.Load>(CarsRecentSearchesActions.CarsRecentSearchesActionTypes.Load),
      switchMap(() =>
        this.recentSearchesService.cars().pipe(
          map(res => new CarsRecentSearchesApiActions.LoadSuccess({ destinations: res.data })),
          catchError(error => of(new CarsRecentSearchesApiActions.LoadFailure(error)))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private recentSearchesService: RecentSearchesService
  ) {}
}
