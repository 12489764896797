import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as fromAuth from 'app/auth/store/reducers';
import { PhoneNumberMapper } from 'app/profile/mappers/phone-number.mapper';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';
import { PhoneNumberHelper } from 'app/shared/helpers/phone-number.helper';
import { ProfileHelper } from 'app/shared/helpers/profile.helper';
import { Country } from 'app/shared/models/country.model';
import { CountryActions } from 'app/shared/store/actions';
import * as fromShared from 'app/shared/store/reducers';
import { CustomValidators } from 'app/shared/validators/custom-validators';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'first-time-login-form',
  templateUrl: './first-time-login-form.html'
})
export class FirstTimeLoginFormComponent implements OnInit, OnDestroy {
  @Output() submitted = new EventEmitter();
  @Output() canceled = new EventEmitter();

  firstTimeForm: UntypedFormGroup;
  recoveryPasswordFromAdmin = false;
  ngUnsubscribe = new Subject<void>();
  prepopulateEmail = '';
  phoneError = false;
  countries: Country[];
  countries$ = this.store.pipe(select(fromShared.getCountries));

  constructor(
    public formErrorsHelper: FormErrorsHelper,
    public translate: TranslateService,
    public router: Router,
    public route: ActivatedRoute,
    public formBuilder: UntypedFormBuilder,
    private store: Store<fromAuth.State>,
    private phoneNumberHelper: PhoneNumberHelper,
    private profileHelper: ProfileHelper
  ) { }

  ngOnInit() {
    this.countries$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((countries) => {
      if (countries?.length > 0) {
        this.countries = countries;
      }
      else { this.store.dispatch(new CountryActions.LoadCountries()); }
    });
    this.route.queryParamMap.pipe(takeUntil(this.ngUnsubscribe)).subscribe((queryParams) => {
      this.recoveryPasswordFromAdmin = queryParams.get('recovery') === 'true';
      this.prepopulateEmail = queryParams.get('email');
    });
    this.createForm();
    this.addPhoneToForm();
  }

  createForm() {
    this.firstTimeForm = this.formBuilder.group({
      email: new UntypedFormControl(this.prepopulateEmail, [Validators.email]),
      newPassword: new UntypedFormControl('', Validators.compose([Validators.required, CustomValidators.password])),
      newPasswordConfirm: new UntypedFormControl('', Validators.compose([Validators.required, CustomValidators.duplicatePassword])),
      recovery_from_admin: this.recoveryPasswordFromAdmin
    });
  }

  addPhoneToForm() {
    const phoneControl = this.phoneNumberHelper.createPhoneFormGroup(false, false);
    phoneControl.get('country')?.setValue({
      code: 'US',
      code3: 'US',
      currency: "USD",
      name: "United States of America",
      phone: 1
    });
    phoneControl.get('area')?.setValue('');
    phoneControl.get('number')?.setValue('');
    this.firstTimeForm.addControl('homePhone', phoneControl)
  }

  complete() {
    if (this.firstTimeForm.valid) {
      const rawPhone = this.firstTimeForm.get('homePhone').value;
      this.submitted.emit({ ...this.firstTimeForm.value, homePhone: this.getPhoneFormatted(rawPhone) });
    } else {
      Object.keys(this.firstTimeForm.controls).forEach((field) => {
        const control = this.firstTimeForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  onCancelClicked() {
    this.canceled.emit();
  }

  checkHomePhone() {
    this.phoneError = false;
    if (!this.firstTimeForm.get('homePhone').valid) {
      this.phoneError = true;
    }
  }

  getPhoneFormatted(rawPhone) {
    const country = rawPhone.country?.code || 'US';
    const number = rawPhone?.number || '';
    if (number.length === 0) { return '||' };
    const objPhone = this.profileHelper.formatPhoneService(country, number);
    return new PhoneNumberMapper().fromObject().toString(objPhone);
  }

  get email() { return this.firstTimeForm.get('email'); }
  get homePhone() { return this.firstTimeForm ? this.firstTimeForm.get('homePhone') : null; }
  get newPassword() { return this.firstTimeForm.get('newPassword'); }
  get newPasswordConfirm() { return this.firstTimeForm.get('newPasswordConfirm'); }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
