import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { GoogleGeocodingService } from './shared/services/geocoding/google/google-geocoding.service';
import { LocationiqGeocodingService } from './shared/services/geocoding/locationiq/locationiq-geocoding.service';

import { ArcGisGeocodingService } from './shared/services/geocoding/arcgis/arcgis.service';

export function geocoderFactory(http: HttpClient) {
  switch (environment.geocodingVendor) {
    case 'arcgis':
      return new ArcGisGeocodingService(http);
    case 'google':
      return new GoogleGeocodingService(http);
    case 'locationiq':
      return new LocationiqGeocodingService(http);
    default:
      return new GoogleGeocodingService(http);
  }
}
