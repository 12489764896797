import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { AirportActions } from '../actions';
import { map, catchError, switchMap, debounceTime } from 'rxjs/operators';
import { of } from 'rxjs';
import { AirportsService } from 'app/shared/services/airports/airports.service';

@Injectable()
export class AirportEffects {

  loadAirports$ = createEffect(()=>
    this.actions$.pipe(
      ofType<AirportActions.LoadAirports>(AirportActions.AirportActionTypes.LoadAirports),
      debounceTime(100),
      switchMap((action) =>  {
        return this.airportsService.getAirports(action?.payload?.airlineCode).pipe(
            map(airports => new AirportActions.LoadAirportsSuccess({ airports })),
            catchError(error => of(new AirportActions.LoadAirportsFailure(error)))
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private airportsService: AirportsService,
  ) {}
}
