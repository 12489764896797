<section class="first-time-login">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 text-center">
        <h1>{{ 'membership.partner.access_account' | translate }}</h1>
      </div>
      <div class="col-md-6 col-sm-8 col-xs-12 offset-md-3 offset-sm-2 pt-5">
        <form [formGroup]="userForm" (ngSubmit)="validateAccount()">
          <ngb-alert [type]="'info'" [dismissible]="false">
            <div>
              <div class="fw-bold">{{ 'membership.partner.good_news' | translate }} {{airlineName}}!</div>
              <div>
                <p>{{ airlineName }} {{ 'membership.partner.partner_account_created' | translate }}</p>
                <p>{{ 'membership.partner.partner_login_steps' | translate }}</p>
              </div>
              <div class="fw-bold">{{ 'membership.partner.partner_login_retiree_steps_title' | translate }}</div>
              <div>
                <p [innerHtml]="'membership.partner.partner_login_retiree_steps' | translate"></p>
              </div>
            </div>
          </ngb-alert>
          <ngb-alert *ngIf="error" type="danger" (closed)="closeAlert()">
            <div class="fw-bold" data-cy="generic-error">{{ 'membership.partner.generic_error_title' | translate }}</div>
            <p>{{ 'membership.partner.generic_error_p2' | translate }}</p>
          </ngb-alert>
          <div class="form-group form-floating">
            <input type="text" id="email" class="form-control with-background" formControlName="username" required
              autocomplete="email" data-cy="username"/>
            <label for="username" data-error="error">{{ 'profile.info.employeeNumber' | translate }}</label>
            <div class='error-message' *ngIf="username.errors !== null && (username.touched)"
              [innerHtml]="formErrorsHelper.errorMessage(username, ('profile.info.employeeNumber' | translate))">
            </div>
          </div>
          <loading-indicator [fullScreen]="false" *ngIf="pending$ | async"></loading-indicator>
          <div *ngIf="!(pending$ | async)">
            <div class="row" *ngIf="allowValidation | async">
              <div class="offset-md-2 col-md-8 text-center">
                <button type="submit" class="btn btn-primary w-100 my-2" data-cy="begin-validation">
                  {{ 'membership.partner.begin_validation' | translate }}
                </button>
              </div>
            </div>
            <div class="row">
              <div class="offset-md-2 col-md-8 text-center">
                <button type="button" class="btn btn-outline-primary w-100 my-2" (click)="login()" data-cy="login-password">
                  {{ 'membership.partner.login_temporary_password' | translate }}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
