import { mergeMap, map, catchError, withLatestFrom, filter, switchMap, retry } from 'rxjs/operators';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { FlagsActions } from '../actions';
import * as fromShared from '../reducers';
import { of } from 'rxjs';
import { HelpersService } from 'app/shared/services/helpers/helpers.service';
import * as moment from 'moment';
const DEFAULT_MAX_RETRIES = 5;
const ABTEST_PREFIX = 'abtest_';

@Injectable()
export class FlagsEffects {
  loading$ = this.store$.select(fromShared.loadingConfigFlag);
  configDate$ = this.store$.select(fromShared.getConfigDate);

  initConfigFlags$ = createEffect(()=>
    this.actions$.pipe(
      ofType(FlagsActions.initConfigFlags),
      withLatestFrom(this.loading$, this.configDate$),
      filter(([_action, loading, configDate]) => !loading && (!configDate || moment(new Date()).diff(configDate, 'hours') >= 1)) ,
      map(() => FlagsActions.loadConfigFlags())
    )
  );

  getConfigFlags$ = createEffect(()=>
    this.actions$.pipe(
      ofType(FlagsActions.loadConfigFlags),
      mergeMap(() => this.helpersService.getAvailableConfigFlags().pipe(
        map(res => FlagsActions.loadConfigFlagsSuccess({ configFlags: res })),
        catchError(error => of(FlagsActions.loadConfigFlagsFailure(error)))
      ))
    )
  );

  initFeatureFlags$ = createEffect(()=>
    this.actions$.pipe(
      ofType(FlagsActions.initFeatureFlags),
      map(() => FlagsActions.loadFeatureFlags())
    )
  );

  getFeatureFlags$ = createEffect(()=>
    this.actions$.pipe(
      ofType(FlagsActions.loadFeatureFlags),
      mergeMap(() => this.helpersService.getAvailableFeatureFlags().pipe(
        map(resp => this.pushAbTestGroupsToDataLayer(resp)),
        map(resp => FlagsActions.loadFeatureFlagsSuccess({ featureFlags: resp })),
        catchError(error => of(FlagsActions.loadFeatureFlagsFailure(error)))
      ))
    )
  );

  addFeatureFlags$ = createEffect(()=>
    this.actions$.pipe(
      ofType(FlagsActions.addFeatureFlags),
      map(action => action.feature),
      switchMap((feature: string) => {
        return this.helpersService.setUserFeatureFlag(feature).pipe(
          retry(DEFAULT_MAX_RETRIES),
          map(() => FlagsActions.loadFeatureFlags()),
          catchError(error => of(FlagsActions.addFeatureFlagsFailure({ error })))
        );
      })
    )
  );

  removeFeatureFlags$ = createEffect(()=>
    this.actions$.pipe(
      ofType(FlagsActions.removeFeatureFlags),
      map(action => action.feature),
      switchMap((feature: string) => {
        return this.helpersService.deleteUserFeatureFlag(feature).pipe(
          retry(DEFAULT_MAX_RETRIES),
          map(() => FlagsActions.loadFeatureFlags()),
          catchError(error => of(FlagsActions.removeFeatureFlagsFailure({ error })))
        );
      })
    )
  );

  getAirlineFlags$ = createEffect(()=>
    this.actions$.pipe(
      ofType(FlagsActions.loadAirlineFlags),
      map(action => action.code),
      switchMap(data => this.helpersService.getAirlineFeatureFlags(data).pipe(
        map((res) => {
          const result = {
            items: res,
            airlineCode: data.toLowerCase()
          };
          return FlagsActions.loadAirlineFlagsSuccess({ airlineFlags: result});
        }),
        catchError(error => of(FlagsActions.loadAirlineFlagsFailure(error)))
      ))
    )
  );

  private pushAbTestGroupsToDataLayer(response) {
    // if (response && response.length > 0) {
    //   const abTestGroups = response.filter(feature => feature.startsWith(ABTEST_PREFIX));
    //   if (abTestGroups && abTestGroups.length > 0) {
    //     this.dataLayerHelper.pushAbTestGroups(this.dataLayerHelper.DESCRIPTORS.AB_TEST_GROUPS, abTestGroups);
    //   }
    // }
    return response;
  }

  constructor(
    private actions$: Actions,
    private store$: Store<fromShared.State>,
    private helpersService: HelpersService
  ) { }
}
