export interface DemandCalendar {
  city: string;
  latitude: number;
  longitude: number;
  check_in: string;
  mode: string;
  demand: DemandCalendarDemand;
}

export enum DemandCalendarDemand {
  None = "None",
  High = "High",
  Low = "Low",
  Medium = "Medium",
}

export interface DemandCalendarRequest {
  latitude: number;
  longitude: number;
}