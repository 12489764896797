import { HttpClient } from '@angular/common/http';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { faChartLine, faClock, faHotel, faLocationArrow, faMapMarkedAlt, faMapMarkerAlt, faPlaneArrival, faPlaneDeparture, faSearch, faSpinner, faSubway } from '@fortawesome/free-solid-svg-icons';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as fromAuth from 'app/auth/store/reducers';
import { geocoderFactory } from 'app/geocoder.factory';
import { GeneralHelper } from 'app/shared/helpers/general.helper';
import { StringHelper } from 'app/shared/helpers/string.helper';
import { AirportLocationsAutocomplete } from 'app/shared/models/airport';
import { AirportsService } from 'app/shared/services/airports/airports.service';
import { GeocoderLocation, GeocodingService } from 'app/shared/services/geocoding/geocoding.service';
import { TrendingDestinationsService } from 'app/shared/services/trending-destinations/trending-destinations.service';
import { AirportActions, CurrentLocationActions } from 'app/shared/store/actions';
import * as fromCurrentLocation from 'app/shared/store/reducers';
import { find, flatten, intersection, filter as lodashFilter, reverse, sortBy } from 'lodash';
import * as moment from 'moment';
import { merge, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import * as fromRoot from '../../../../reducers';
import * as fromProfile from '../../../profile/store/reducers';
import { ConfigFlags } from '../../models/configFlags';
import { AutocompleteService } from '../../services/geocoding/autocomplete.service';
import * as fromShared from '../../store/reducers';

@Component({
  selector: 'locations-autocomplete-component',
  templateUrl: './locations.autocomplete.html',
  styleUrls: ['./locations.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: GeocodingService,
      useFactory: geocoderFactory,
      deps: [HttpClient]
    }
  ]
})
export class LocationsAutocompleteComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('instance', { static: false }) instance: NgbTypeahead;
  @ViewChild('instanceModal', { static: false }) instanceModal: NgbTypeahead;
  @ViewChild('inputField', { static: false }) inputField: ElementRef;
  @Input() inputModel = { street: '' };
  @Input() showIcon = true;
  @Input() codeFieldName;
  @Input() displayFieldName;
  @Input() organizationIdFieldName;
  @Input() placeholder;
  @Input() label;
  @Input() controlName;
  @Input() form: UntypedFormGroup;
  @Input() locationType = 'hotels';
  @Input() trendingDestinations;
  @Input() recentSearches;
  @Input() clearInputAndKeepResult: boolean = false;
  @Input() isFirstInput: boolean;
  @Input() showModalForMobile: boolean;
  @Input() hideHeader: boolean;
  @Input() alignBardgeLeft: boolean = false;
  @Input() icon: string;
  @Input() iconClass: string = '';
  @Input() set disabled(value: boolean) {
    if (!this.form) { return }
    if (value) {
      this.form.get(this.controlName).disable();
      return;
    }
    this.form.get(this.controlName).enable();
  }
  @Input() currentTool?: string;
  @Input() showCurrentLocation = false;
  @Input() recentDetailsEnable = true;
  @Input() set destination(value: any) {
    if (value) {
      this.form.get(this.controlName).setValue(value);
    }
  }
  @Input() addressMaxLength = 100;

  @Output() locationSelected = new EventEmitter();
  @Output() locationChanging = new EventEmitter();
  @Output() addressSet = new EventEmitter();
  @Output() onSearchChanged = new EventEmitter();

  formattedTrendingDestinations: any[] = [];
  formattedRecentSearches: any[] = [];
  formattedCurrentLocation = [];
  airports: AirportLocationsAutocomplete[] = [];
  allInclusiveDestinations = [];
  firstLocation: GeocoderLocation;
  click$ = new Subject<string>();
  currentResult: string = '';
  searching = false;
  searchFailed = false;
  focusFirst = true;
  showModal = false;
  firstOpen: boolean = true;
  configFlags: ConfigFlags;
  featureFlags: any = [];
  memberId: any;
  airlineCode: string;
  icons = { faSearch, faSpinner, faPlaneDeparture, faPlaneArrival, faClock, faChartLine, faLocationArrow, faMapMarkedAlt, faMapMarkerAlt, faHotel, faSubway };
  private ngUnsubscribe = new Subject<void>();
  public isIE = GeneralHelper.isInternetExplorer();
  private results: any[] = [];
  private currentLocationPending;
  private currentLocationCallback;
  private currentLocationError$ = this.store.pipe(select(fromShared.getCurrentLocationError));
  airlineCode$ = this.store.pipe(select(fromAuth.getAirlineCode));
  airports$ = this.store.pipe(select(fromShared.getAirports));

  constructor(
    private geocodingService: GeocodingService,
    private cd: ChangeDetectorRef,
    private airportsService: AirportsService,
    private trendingDestinationsService: TrendingDestinationsService,
    private stringHelper: StringHelper,
    private autocompleteService: AutocompleteService,
    private store: Store<fromRoot.State>,
    private translate: TranslateService
  ) { }

  ngOnInit() {

    if (this.showCurrentLocation) {
      if (this.locationType === 'airport') {
        this.store.select<any>(fromCurrentLocation.getCurrentLocation)
          .pipe(take(1))
          .subscribe((currentPosition) => {
            if (!currentPosition) {
              this.store.dispatch(new CurrentLocationActions.Load());
            }
          }
          );
      }
    }
    this.focusFirst = this.locationType !== 'address';
    this.store.pipe(select(fromShared.getFeatureFlags))
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe(featureFlags => this.featureFlags = featureFlags);

    this.store.pipe(select(fromShared.getConfigFlag))
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe(configFlags => this.configFlags = configFlags);

    this.store.pipe(select(fromProfile.getUserProfile))
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
        this.memberId = user ? user.idMembers : '';
      });

    if (this.locationType === 'all-inclusive') {
      this.trendingDestinationsService.getAllInclusiveLocations().pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
        this.allInclusiveDestinations = reverse(sortBy(
          flatten(data.map((d) => {
            return d.destinations;
          })),
          ai => ai.count
        ));
      });
    }

    this.airlineCode$.pipe(take(1)).subscribe((airlineCode) => {
      this.airlineCode = airlineCode;
      let currentAirline = this.airlineCode;
      if (this.currentTool === 'listing' && this.locationType === 'airport') {
        currentAirline = null;
      }
      this.store.dispatch(new AirportActions.LoadAirports({ airlineCode: currentAirline }));
    });

    this.airports$.pipe(take(1)).subscribe((data) => {
      this.airports = Object.values(data).map(val => ({
        code: val.code,
        name: val.name,
        city: val.city,
        lat: val.coordinates.latitude,
        lng: val.coordinates.longitude,
      }));
    });

    if (this.showCurrentLocation) {
      this.formatCurrentLocation();
    }

    if (this.clearInputAndKeepResult) {
      this.currentResult = this.form.get(this.controlName).value;
    }

    // close the modal when an item is selected
    this.form.get(this.controlName).valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((val) => {
      // in large screens we get the values as the user is typing, in mobile only when a location is selected
      if (this.showModal && val) {
        this.closeModal();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.recentSearches) {
      this.formatRecentSearches();
    }
    if (changes.trendingDestinations) {
      this.formatTrendingDestinations();
    }
  }

  selectValue() {
    if (this.locationType === 'address') {
      this.addressSet.emit(this.form.get(this.controlName).value);
      return;
    }
    if (this.firstLocation) {
      this.onSelect({ item: this.firstLocation }, false);
    }

    if ((this.locationType === 'hotels'
      || this.locationType === 'all-inclusive'
      || this.locationType === 'cars') && this.searching) {
      this.handleLeaveInput();
      return;
    }

    if (this.locationType === 'cars' && !this.searching) {
      this.handleLeaveCarsType();
      return;
    }

    this.onSearchChanged.emit(false);
  }

  search = (text$: Observable<string>) => {
    if (this.isAllInclusivePopupSearch()) {
      return;
    }

    const debouncedText$ = text$.pipe(
      debounceTime(300),
      filter((query) => {
        // Start searching after 3 char (only for hotels)
        return !((this.locationType === 'hotels' || this.locationType === 'airport' || this.locationType === 'all-inclusive') &&
          query.length < 3 && query.length !== 0);
      }),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance || !this.instance.isPopupOpen()));
    const clicksWithClosedPopupModal$ = this.click$.pipe(filter(() => !this.instanceModal || !this.instanceModal.isPopupOpen()));
    return merge(debouncedText$, clicksWithClosedPopup$, clicksWithClosedPopupModal$).pipe(
      switchMap((result) => {
        let term = result;
        this.locationChanging.emit();
        this.onSearchChanged.emit(true);
        this.firstLocation = null;
        if (this.clearInputAndKeepResult) {
          if (term === this.currentResult) {
            this.form.get(this.controlName).setValue('');
          } else if (term === '') {
            term = this.currentResult;
          }
        }

        if (term === '') {
          this.form.markAsUntouched();
        }

        // Append hotel recents searches
        if ((term === '' || term === this.currentResult) && this.locationType === 'hotels') {
          return of(this.formattedCurrentLocation.concat(
            this.formattedRecentSearches.concat(this.formattedTrendingDestinations)
          ));
        }

        if (term === '' && this.locationType === 'cars') {
          return of(this.formattedRecentSearches.concat(this.formattedTrendingDestinations));
        }

        if (term === '' && this.locationType === 'airport') {
          if (this.isFirstInput) {
            return of(this.formattedCurrentLocation.concat(this.formattedRecentSearches));
          }
          return of(this.formattedRecentSearches);
        }

        if (this.locationType === 'all-inclusive') {
          return this.filterAllInclusiveDestinations(term);
        }

        // Filter for flights.
        if (this.locationType === 'airport') {
          return this.filterAirports(term);
        }

        return this.filterAirportsAndLocation(term);
      })
    );
  }

  locations(term: string): Observable<GeocoderLocation[]> {
    this.searching = true;
    if (this.isAutocompleteConfigFlagEnabled()) {
      return this.autocompleteService.getLocations(term, this.locationType, this.memberId).pipe(
        take(1),
        tap((results) => {
          this.searching = false;
          this.firstLocation = results[0];
          this.results = this.locationType === 'cars' && results;
          this.searchFailed = false;
          this.form.markAsUntouched();
          this.cd.detectChanges();
        }),
        catchError(() => {
          return this.searchLocationsDirectly(term);
        })
      );
    }
    return this.searchLocationsDirectly(term);
  }

  searchLocationsDirectly(term: string): Observable<GeocoderLocation[]> {
    return this.geocodingService.getLocations(term, this.locationType).pipe(
      take(1),
      tap((results) => {
        this.searching = false;
        this.firstLocation = results[0];
        this.results = this.locationType === 'cars' && results;
        this.searchFailed = false;
        this.form.markAsUntouched();
        this.cd.detectChanges();
      }),
      catchError(() => {
        this.searching = false;
        this.searchFailed = true;
        return of([]);
      })
    );
  }
  filterAllInclusiveDestinations(term) {
    if (!term) {
      return of(this.allInclusiveDestinations);
    }
    const airport = find(this.airports, a => a.code === term.toUpperCase());
    const airportItem = [];
    if (airport) {
      airportItem.push({
        displayName: `(${airport.code}) ${airport.name}, ${airport.city}`,
        type: 'airport',
        latitude: airport.lat,
        longitude: airport.lng
      });
    }

    const destinations = lodashFilter(this.allInclusiveDestinations, (ai) => {
      const destination = this.stringHelper.accentFold(ai.name + ai.country);
      return destination.toLowerCase().match(this.escapeRegExp(term).toLowerCase()) !== null;
    });

    this.firstLocation = destinations[0];

    if (this.configFlags && this.configFlags.autocomplete_api_enabled === '1') {
      return this.autocompleteService.getAiHotels(term).pipe(mergeMap((result) => {
        let aiHotels = result;
        if (aiHotels.length > 0) {
          aiHotels = this.formatAIHotels(aiHotels);
          if (!this.firstLocation) this.firstLocation = aiHotels[0];
          return of(airportItem.concat(destinations.length ? [destinations[0]] : []).concat(aiHotels));
        }
        return of(airportItem.concat(destinations.length ? [destinations[0]] : []));
      }));
    }
    return of(airportItem.concat(destinations.length ? [destinations[0]] : []));
  }

  formatAIHotels(result) {
    return result.map((hotel) => {
      return {
        id: hotel.id,
        name: hotel.hotelName,
        lat: hotel.lat,
        lng: hotel.lng,
        displayName: `${hotel.hotelName}, ${hotel.city}, ${hotel.countryCode}`,
        city: hotel.city,
        countryCode: hotel.countryCode,
        location_type: 'all-inclusive'
      };
    });
  }
  escapeRegExp(str: string): string {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
  }

  filterAirportsAndLocation(term) {
    if (term.length < 3) return of([]);
    // autocomplete-api elastic search will return airports
    if (this.isAutocompleteConfigFlagEnabled()) {
      return this.locations(term);
    }
    const airport = find(this.airports, a => a.code === term.toUpperCase());
    if (term.length !== 3 || this.locationType === 'cars' || !airport) { return this.locations(term); }

    const displayName = `(${airport.code}) ${airport.name}, ${airport.city}`;
    // Returns a the airport code along with the first result of a location with the airport city search
    return of([{ displayName, type: 'airport', latitude: airport.lat, longitude: airport.lng } as GeocoderLocation]).pipe(
      mergeMap((res) => {
        return this.locations(airport.city).pipe(
          map((location) => {
            const result = res.concat(location.length ? [location[0]] : []);
            this.firstLocation = result[0];
            return result;
          })
        );
      })
    );
  }

  filterAirports(term: string) {
    this.searching = false;

    const upperTerm = term.toUpperCase();
    const airport = this.airports
      .filter(airport =>
        airport.code.toUpperCase().includes(upperTerm) ||
        airport.city.toUpperCase().includes(upperTerm) ||
        airport.name.toUpperCase().includes(upperTerm)
      )
      .map(airport => ({
        ...airport,
        sortFirst: airport.code.toUpperCase().includes(upperTerm)
      }));
    airport.sort((left, right) => {
      return left.sortFirst ? -1 : right.sortFirst ? 1 : 0;
    });

    return of(airport.map((airport) => {
      return { description: `${airport.code} ${airport.name}, ${airport.city}`, type: 'airport' };
    }));
  }

  formatCurrentLocation() {
    this.store.select<any>(fromCurrentLocation.getCurrentLocation)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((currentPosition) => {
        if (currentPosition) {
          if (this.locationType === 'airport') {
            this.airportsService.getNearbyAirports(currentPosition).pipe(take(1)).subscribe((res) => {
              this.formattedCurrentLocation = [];
              this.formattedCurrentLocation.push(
                { type: 'title', title: 'flights.search.modals.nearby_airports', description: '' }
              );
              res.forEach((airport) => {
                const nearbyAirport = {
                  name: airport,
                  latitude: currentPosition.latitude,
                  longitude: currentPosition.longitude,
                };
                this.formattedCurrentLocation.push(this.formatDestination(nearbyAirport, 'current'));
              });
            });
          } else {
            this.formattedCurrentLocation = [this.formatDestination(currentPosition, 'current')];
            if (this.currentLocationPending) {
              this.currentLocationPending = false;
              this.currentLocationCallback({ item: this.formattedCurrentLocation[0] });
            }
          }
        } else {
          const emptyCurrentLocation = { name: this.translate.instant('lodging.search_form.current_location') };
          this.formattedCurrentLocation = [this.formatDestination(emptyCurrentLocation, 'current')];
          this.currentLocationError$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((error) => {
            if (error && this.currentLocationPending) {
              this.form.get(this.controlName).setValue('');
              this.currentLocationPending = false;
            }
          });
        }
      }
      );
  }

  formatRecentSearches() {
    if (this.recentSearches && this.recentSearches.length > 0) {
      this.formattedRecentSearches = [];
      this.formattedRecentSearches.push({ type: 'title', title: 'mobile.general.recent_destinations', description: '' });
      this.recentSearches.forEach((s) => {
        const isValidDestination = !!(s.destination && s.destination.replace(/\s/g, '').length || (s.pick_up_location &&
          s.pick_up_location.replace(/\s/g, '').length));
        const isValidAirportCode = !!(s.airport_code && s.airport_code.replace(/\s/g, '').length);
        if ((isValidDestination || isValidAirportCode) && this.formattedRecentSearches.length <= 5) {
          this.formattedRecentSearches.push(this.formatDestination(s, 'recent'));
        }
      });
    }
  }

  formatTrendingDestinations() {
    if (this.trendingDestinations && this.trendingDestinations.length > 0) {
      this.formattedTrendingDestinations = [];
      this.formattedTrendingDestinations.push({ type: 'title', title: 'mobile.general.trending_destinations', description: '' });
      this.trendingDestinations.forEach((s) => {
        this.formattedTrendingDestinations.push(this.formatDestination(s, 'trending'));
      });
    }
  }

  formatDestination(item, type) {
    const formattedItem: any = {};
    formattedItem.type = type;
    formattedItem.displayName = item.name || item.destination || item.pick_up_location || item.airport_code;
    formattedItem.latitude = item.latitude;
    formattedItem.longitude = item.longitude;
    formattedItem.id = item.pick_up_location_id;
    formattedItem.name = formattedItem.description;
    formattedItem.checkin = moment.utc(item.checkin);
    formattedItem.checkout = moment.utc(item.checkout);
    formattedItem.travelers = item.travelers;
    formattedItem.countryCode = item.countryCode;

    return formattedItem;
  }

  formatMatches = (value: any) => {
    if (this.locationType === 'address' || this.locationType === 'hotels') {
      return value.displayName || value;
    }

    let formattedValue = typeof (value) === 'string' ? value : value.name || value.description || value.displayName;

    if (value.city) {
      formattedValue += `, ${value.city}`;
    }

    if (value.state) {
      formattedValue += `, ${value.state}`;
    }

    if (value.country) {
      formattedValue += ` - ${value.country}`;
    }

    if (value.airport_code) {
      formattedValue += ` (${value.airport_code})`;
    }
    return formattedValue;
  }

  onSelect($event, clearFirst = true) {
    if (this.locationType !== 'flights' && $event.item.type === 'current' && !$event.item.latitude) {
      this.currentLocationPending = true;
      this.currentLocationCallback = this.getCurrentLocationHandler();
      this.store.dispatch(new CurrentLocationActions.Load());
    }
    if (clearFirst) {
      this.firstLocation = null;
    }
    if ($event.item) {
      if ($event.item.type === 'title') {
        if (this.locationType === 'hotels' || this.locationType === 'all-inclusive' || this.locationType === 'cars') {
          this.handleLeaveInput();
        }
        return $event.preventDefault();
      }

      if (this.locationType === 'hotels' || this.locationType === 'all-inclusive') {
        this.onSelectedHotel($event);
      } else if (this.locationType === 'address') {
        this.onSelectedAddress($event);
      } else if (this.locationType === 'airport') {
        this.onSelectedAirport($event);
      } else {
        this.onSelectedCar($event);
      }
    }
  }

  getCurrentLocationHandler() {
    switch (this.locationType) {
      case 'hotels':
        return this.onSelectedHotel;
      case 'all-inclusive':
        return this.onSelectedHotel;
      case 'address':
        return this.onSelectedAddress;
      default:
        return this.onSelectedCar;
    }
  }

  handleLeaveInput() {
    setTimeout(() => {
      this.instance.writeValue(this.getCurrentResult());
      this.inputField.nativeElement.focus();
      this.inputField.nativeElement.scrollIntoView(true);
      this.inputField.nativeElement.select();
      this.inputField.nativeElement.click();
      this.results = [];
    }, 150);
  }

  handleLeaveCarsType() {
    if (!this.currentResult) {
      setTimeout(() => {
        this.instance.writeValue('');
        this.form.get(this.controlName).setValue('');
        this.results = [];
      }, 150);
    }
  }

  onSelectedHotel($event) {
    if (this.locationType === 'all-inclusive' || $event.item.type === 'recent' ||
      $event.item.type === 'trending' || $event.item.type === 'current' || $event.item.type === 'airport'
    ) {
      return this.selectHotel($event);
    }

    if (this.isAutocompleteConfigFlagEnabled()) {
      // elastic result will have latlng no need to call again and get coordinates
      if (LocationsAutocompleteComponent.isLatLngPresent($event.item)) {
        $event.item.locationResolution = $event.item.resolution;
        $event.item.locationType = $event.item.resultType;
        this.autocompleteService.clearSessionToken();
        return this.selectHotel($event);
      }
      const checkArcgis = LocationsAutocompleteComponent.isArcgis($event);
      return this.autocompleteService.getLocationCoords(checkArcgis.id, this.memberId, checkArcgis.isArcgis)
        .pipe(take(1)).subscribe((response) => {
          this.selectHotel(response);
        }, () => {
          this.getLocationCoordsDirectly($event);
        });
    }

    return this.getLocationCoordsDirectly($event);
  }

  getLocationCoordsDirectly($event) {
    return this.geocodingService.getLocationCoords($event.item).pipe(take(1)).subscribe((response) => {
      this.getLocationTypeAndResolution($event, response);
      this.selectHotel(response);
    });
  }

  onSelectedAddress($event) {
    this.searching = true;
    if (this.isAutocompleteConfigFlagEnabled()) {
      const checkArcgis = LocationsAutocompleteComponent.isArcgis($event);
      return this.autocompleteService.getLocationDetails(checkArcgis.id, this.memberId, checkArcgis.isArcgis)
        .pipe(take(1)).subscribe((response) => {
          this.selectHotel(response);
        }, () => {
          this.getLocationDetailsDirectly($event);
        });
    }
    return this.getLocationDetailsDirectly($event);
  }

  getLocationDetailsDirectly($event) {
    return this.geocodingService.getLocationDetails($event.item).pipe(take(1)).subscribe((response) => {
      this.selectHotel(response);
    });
  }

  selectHotel($event) {
    const data = $event.item || $event;
    if (typeof (data.displayName) === 'undefined' && (data.name || data.displayText)) {
      data.displayName = data.name || data.displayText;
    }
    if (this.form.get(this.controlName).value !== data.displayName) {
      this.form.get(this.controlName).setValue(data.displayName);
    }

    this.searching = false;
    this.firstOpen = true;
    this.currentResult = this.getCurrentResult();
    this.onSearchChanged.emit(false);
    this.locationSelected.emit(data);
  }

  onSelectedCar($event) {
    let carItem = null;
    if ($event && $event.item) {
      carItem = $event.item.type === 'recent'
        ? $event.item
        : this.results.find(item => item.id === $event.item.id);
    }

    if (carItem) {
      this.currentResult = carItem;
      this.locationSelected.emit(carItem);
    } else {
      this.currentResult = '';
    }
    this.results = [];
  }

  onSelectedAirport($event) {
    this.locationSelected.emit($event);
  }

  numberOfGuests(guestCount): number {
    let numberOfGuests = 0;
    guestCount.split('|').forEach((room) => {
      const roomGuests = room.split(',');
      numberOfGuests += parseInt(room[0], 10) + roomGuests.slice(1).length;
    });

    return numberOfGuests;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  closeModal() {
    this.showModal = false;
    this.firstOpen = true;
    this.currentResult = this.getCurrentResult();
    window.scrollTo(0, 0);
    const activeElement = (document.activeElement as HTMLElement);
    if (typeof activeElement.blur === 'function') {
      // lose focus in order to close the keyboard in mobile view
      activeElement.blur();
    }
    if (this.isIos()) {
      document.body.style.overflow = '';
      document.body.style.position = '';
    }
  }

  getCurrentResult(): string {
    const inputValue = this.form.get(this.controlName).value;
    return inputValue && inputValue.displayName ? inputValue.displayName : inputValue;
  }

  openModal() {
    this.showModal = true;
    this.inputField.nativeElement.click();
    this.inputField.nativeElement.scrollIntoView(true);

    if (this.isIos()) {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      setTimeout(() => { this.inputField.nativeElement.focus(); }, 500);
    } else {
      this.inputField.nativeElement.focus();
    }
  }

  isIos() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  }

  getLocationTypeAndResolution($event, response) {
    // get location resolution based on google location type
    if ($event.item.types) {
      if ($event.item.types.indexOf('point_of_interest') !== -1) {
        response.locationResolution = 0.43;
        response.locationType = 'point_of_interest';
      }
      if (intersection($event.item.types, ['street_address', 'premise']).length) {
        response.locationResolution = 0.09;
        response.locationType = 'street_address';
      }
    } else if (response.radiusFromExtent) { // get location type based on arcgis calculated radius
      const locationType = LocationsAutocompleteComponent.getLocationType(response.radiusFromExtent);
      if (locationType) {
        response.locationResolution = response.radiusFromExtent;
        response.locationType = locationType;
      }
    }
  }
  private static getLocationType(radius) {
    // address calculated radius is approx 0.0900
    if (radius > 0 && radius <= 0.10) {
      return 'street_address';
    }
    // point of interests calculated radius is approx 0.438
    if (radius > 0.10 && radius <= 0.50) {
      return 'point_of_interest';
    }
  }

  private isAutocompleteConfigFlagEnabled() {
    return this.configFlags && this.configFlags.autocomplete_api_enabled === '1';
  }

  private static isLatLngPresent(item) {
    return item.lat && item.lng;
  }

  private static isArcgis($event) {
    let id = $event.item.placeId;
    let isArcgis = false;
    if ($event.item.magicKey) {
      id = $event.item.magicKey;
      isArcgis = true;
    }
    return { id, isArcgis };
  }
  getLocationIcon(result) {
    let type = result.type || result.resultType || (result.types && result.types[0]);
    type = type ? type.toLowerCase() : type;
    return this.checkLocationType(type);
  }

  checkLocationType(type: string) {
    switch (type) {
      case 'recent':
        return 'faClock';
      case 'trending':
        return 'faChartLine';
      case 'current':
        return 'faLocationArrow';
      case 'airport':
        return 'faPlaneDeparture';
      case 'city':
      case 'multicity':
      case 'neighborhood':
      case 'multiregion':
      case 'metrocode':
        return 'faMapMarkedAlt';
      case 'poi':
        return 'faMapMarkerAlt';
      case 'hotel':
        return 'faHotel';
      case 'trainstation':
      case 'metrostation':
        return 'faSubway';
      default:
        return 'faMapMarkerAlt';
    }
  }

  isAllInclusivePopupSearch() {
    return this.locationType === 'all-inclusive' && this.featureFlags && this.featureFlags.includes('abtest_all_inclusive_search.popup');
  }
}
