import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WindowService {
  assignLocation(location: string) {
    window.location.assign(location);
  }
}
