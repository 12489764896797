import { Injectable } from "@angular/core";
import { HammerGestureConfig } from "@angular/platform-browser";
import * as Hammer from '@squadette/hammerjs';

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  overrides = <any> {
      pinch: { enable: false },
      rotate: { enable: false },
      swipe: { direction: Hammer.DIRECTION_ALL },
  }
}
