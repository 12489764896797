import { Action } from '@ngrx/store';
import { HotelsRecentSearch } from 'app/shared/models/hotels-recent-search';

export enum HotelsRecentSearchesActionTypes {
  Load = '[Hotel Recent Searches] Load',
  SaveSearch = '[Hotel Recent Searches] Save Search'
}

export class Load implements Action {
  readonly type = HotelsRecentSearchesActionTypes.Load;

  constructor() {}
}

export class SaveSearch implements Action {
  readonly type = HotelsRecentSearchesActionTypes.SaveSearch;

  constructor(public payload: HotelsRecentSearch) {}
}

export type HotelsRecentSearchesActionsUnion = Load
  | SaveSearch;
