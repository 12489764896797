<section class="py-5">
  <div class="container">
    <div class="row">
      <div class="col">
        <h2>{{renderData.title | translate}}</h2>
        <div *ngIf="renderData.subtitle" class="tertiary-content pb-3">{{renderData.subtitle | translate}}</div>
      </div>
    </div>
    <div class="row">
      <div *ngFor="let deal of renderData.cards" class="col-md-6 col-lg-3">
        <div id="{{deal.primaryKey | translate}}" class="card clickable-card gtm-cruise-card" (click)="search(deal.params)">
          <img id="{{deal.primaryKey | translate}}" class="img-fluid cruise-img gtm-cruise-card" [offset]="10" [defaultImage]="'angular_assets/images/common/city-loader.jpg'" [lazyLoad]="deal.img"/>
          <div id="{{deal.primaryKey | translate}}" class="card-body gtm-cruise-card">
            <div id="{{deal.primaryKey | translate}}" class="primary-content text-truncate gtm-cruise-card">{{deal.primaryKey | translate}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>