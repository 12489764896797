import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CreditCard } from 'app/shared/models/credit-card';
import { PaymentMethod } from 'app/profile/models/payment-method';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodsService {

  constructor(private http: HttpClient) { }

  getAll(includeBusinessCards = false): Observable<CreditCard[]> {
    const params = new HttpParams().set('include_business_cards', includeBusinessCards.toString());
    return this.http.get<CreditCard[]>(`${environment.apiUrl}/profile/payment_methods.json`, { params });
  }

  delete(params) {
    return this.http.delete(`${environment.apiUrl}/profile/payment_methods.json`, { params });
  }

  create(card: PaymentMethod) {
    return this.http.post(`${environment.apiUrl}/profile/payment_methods.json`, card, {});
  }

  update(card: PaymentMethod) {
    return this.http.put(`${environment.apiUrl}/profile/payment_methods/token.json`, card); // << Poner la magia aca
  }

  makePrimary(params) {
    return this.http.patch(`${environment.apiUrl}/profile/payment_methods.json`, params, {});
  }
}
