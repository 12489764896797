<section class="py-5 gray-blue-background" *ngIf="recentHotelViews.length > 0">
  <div class="container">
    <div class="row">
      <div class="col">
        <h2>{{'mobile.general.recently_viewed' | translate }}</h2>
      </div>
    </div>
    <destinations
      size="xl"
      [source]="'recentViews'"
      [destinationsList]="recentViews">
    </destinations>
  </div>
</section>