<section class="py-5" *ngIf="destinations.length > 0">
  <div class="container">
    <div class="row pt-4">
      <div class="col">
        <h2>{{'mobile.general.all_inclusive' | translate }}</h2>
      </div>
    </div>
    <destinations
      [image]="'destination_image'"
      [name]="'destination_name'"
      [source]="'allInclusive'"
      [destinationsList]="destinations">
    </destinations>
  </div>
</section>
