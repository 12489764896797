import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'child-age-selector',
  templateUrl: './child-age-selector.component.html',
  styleUrls: ['./child-age-selector.component.css']
})
export class ChildAgeSelectorComponent implements OnInit {
  @Input() childNumber: number = 0;
  @Input() childAge;
  @Output() ageSelected = new EventEmitter();

  childAges: any[] = [];
  valueSelected: number = 0;
  childAgeForm: FormGroup = this.formBuilder.group({
    age: ['', [Validators.required]],
  });

  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.privatecreateForm();
    this.setupChildAges();
  }

  public onChildAgeChanged (event) {
    this.valueSelected = event;
    this.ageSelected.emit(event);
  }

  privatecreateForm() {
    this.childAgeForm.patchValue({
      age: this.childAge,
    });
  }

  private setupChildAges () {
    this.valueSelected = this.childAge;
    if (!this.childAges.length) {
      this.childAges = [];
      const yearsOld = this.translate.instant('lodging.search_form.years_old');
      this.childAges.push({
        key: 1,
        value: this.translate.instant('lodging.search_form.infant_1y')
      });
      for (let index = 2; index < 18; index++) {
        this.childAges.push({
          key: index,
          value: `${index} ${yearsOld}`
        });
      }
    }
  }

  
}
