<section class="container">
    <div class="login-form">
      <div class="row pb-4">
        <div class="col-md-6 col-xs-12 offset-md-3 py-5">
          <ngb-alert *ngIf="errorMessage" type="danger" (closed)="closeAlert()">{{ errorMessage }}</ngb-alert>
          <ngb-alert *ngIf="success" type="success" (closed)="closeAlert()"> {{ 'flash_messages.user_created' | translate }} </ngb-alert>
          <loading-indicator [fullScreen]="false" *ngIf="pending"></loading-indicator>
          <form *ngIf="!pending" name="registrationForm" class="mt-3" [formGroup]="registrationForm" (ngSubmit)="submit()">
            <div class="form-group" >
              <label for="employee_number" data-error="error">{{ 'membership.create.employee_number_label' | translate }}</label>
              <input
                type="text"
                id="employee_number"
                class="form-control"
                formControlName="employee_number"
                autocapitalize="none"
                required="required"
                [placeholder]="'membership.create.employee_number_placeholder' | translate">
              <div class='error-message'
                   *ngIf="employee_number.errors !== null && (employee_number.touched)"
                   [innerHtml]="formErrorsHelper.errorMessage(employee_number, ('membership.create.employee_number_label' | translate))">
              </div>
            </div>
            <div class="form-group">
              <label for="email" data-error="error">{{ 'intro_page.email_label' | translate }}</label>
              <input
                type="text"
                id="email"
                class="form-control"
                formControlName="email"
                autocapitalize="none"
                required="required"
                placeholder="{{'membership.create.email_placeholder' | translate}}">
              <div class='error-message'
                   *ngIf="email.errors !== null && (email.touched)"
                   [innerHtml]="formErrorsHelper.errorMessage(email, ('intro_page.email_label' | translate))">
              </div>
            </div>
            <div class="form-group">
              <label for="password" data-error="error">{{ 'membership.create.password_label' | translate }}</label>
              <input
                type="password"
                id="password"
                class="form-control"
                formControlName="password"
                autocapitalize="none"
                required="required"
                placeholder="{{'membership.create.password_placeholder' | translate}}">
              <div class='error-message'
                   *ngIf="password.errors !== null && (password.touched)"
                   [innerHtml]="formErrorsHelper.errorMessage(password, ('membership.create.password_label' | translate))">
              </div>
            </div>
            <div class="row d-none d-sm-block">
              <div class="col-lg-6 col-md-8">
                <button type="submit" class="btn btn-primary my-2 float-start">{{ 'action_terms.create_account' | translate }}</button>
              </div>
            </div>
            <div>
                <button *ngIf="email" (click)="resetButton()" type="button" class="btn btn-outline-primary my-2 float-end btn-sm">Clear</button>
            </div>
          </form>
        </div>
      </div>
    </div>
</section>
<floating-container-bottom class="d-xs-block d-sm-none">
  <button type="submit" class="btn btn-primary my-2 w-100">{{ 'action_terms.create_account' | translate }}</button>
</floating-container-bottom>
