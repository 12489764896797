import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'cvv-information',
  templateUrl: './cvv-information.html',
  styleUrls: ['./cvv-information.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CvvInformationComponent {
  @Input() cardType: string;
}
