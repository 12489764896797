import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Swiper } from 'swiper';
// import { SwiperOptions } from 'swiper/types';

@Component({
  selector: 'carousel-component',
  templateUrl: './carousel-component.html',
  styleUrls: ['./carousel-component.scss']
})

export class CarouselComponent implements AfterViewInit {
  @ViewChild('swiperView', { static: false }) swiperView: ElementRef;

  private swiper: Swiper;

  @Input() swiperConfig: any = {
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      dynamicBullets: true,
    },
  };

  constructor() { }

  ngAfterViewInit(): void {
    this.swiper = this.swiperView.nativeElement.swiper;
  }

  setIndex(index) {
    if (this.swiper) {
      this.swiper.slideTo(index);
    }
  }
}
