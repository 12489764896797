import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faPlusCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'multiple-guests-section',
  templateUrl: './multiple-guests-section.html',
  styleUrls: ['./multiple-guests-section.scss']
})

export class MultipleGuestsSectionComponent implements OnInit, OnDestroy, OnChanges {
  @Input() rooms: any[];
  @Input() set visible(value: boolean) {
    this._visible = value;
  }
  @Input() isOnPopUp: boolean;
  @Input() roomsWithErrors: number[] = [];

  @Output() formUpdated = new EventEmitter();

  guestsForm: UntypedFormGroup;
  roomsList: UntypedFormArray;
  numberOfAdults = [[]];
  numberOfChildren = [[]];
  ageOptions = [];
  maxNumberOfAdults = [8];
  maxNumberOfChildren = [8];
  MAX_TOTAL_GUESTS = 16;
  _visible = false;
  icons = { faPlusCircle, faTimesCircle };

  private ngUnsubscribe = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit() {
    for (let i = 1; i < 18; i++) {
      this.ageOptions.push({
        value: i.toString(), label: i.toString()
      });
    }

    this.setRoomForm();
  }

  ngOnChanges(changes) {
    if (changes.rooms) { this.setRoomForm(); }
  }

  setRoomForm() {
    this.roomsList = this.formBuilder.array([]);
    this.rooms.forEach((room, index) => {
      const roomOccupancy = this.formBuilder.group({
        adults: this.rooms[index].adults,
        children: this.rooms[index].children,
        ages: [this.formBuilder.array(this.rooms[index].ages) || this.formBuilder.array([]), Validators.minLength(1)]
      });
      this.roomsList.push(roomOccupancy);
    });
    this.guestsForm = this.formBuilder.group({
      numberOfRooms: this.rooms.length,
      rooms: this.roomsList
    });
    this.rooms.forEach((room, index) => {
      this.adultsChanged(this.rooms[index].adults, index);
      this.childrenChanged(this.rooms[index].children, index);
    });
    this.guestsForm.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((value) => {
      const formFormatted = this.cleanChildAges();
      this.formUpdated.emit(formFormatted);
    });
  }

  private cleanChildAges() {
    const rooms = [];
    const formValue = {...this.guestsForm.value};
    formValue.rooms.forEach(room => {
      if (room.children < room.ages.length) {
        rooms.push({...room, ages: room.ages.slice(0, room.ages.length - 1)})
      } else if (room.children > room.ages.length) {
        rooms.push({...room, ages: [...room.ages, 0]})
      } else {
        rooms.push(room);
      }
    });
    formValue.rooms = rooms;
    return formValue;
  }

  get roomControls(): any[] {
    const rooms = this.guestsForm.get('rooms') as UntypedFormArray;
    return rooms.controls;
  }

  roomChildrenAgesControls(roomIndex) {
    const children = this.roomControls[roomIndex].get('ages') as UntypedFormArray;
    return children.controls;
  }

  adultsChanged(numberOfAdults: number, roomIndex: number) {
    this.roomControls[roomIndex].get('adults').setValue(numberOfAdults);
    this.maxNumberOfChildren[roomIndex] = this.MAX_TOTAL_GUESTS - this.roomControls[roomIndex].get('adults').value;
    this.maxNumberOfAdults[roomIndex] = this.MAX_TOTAL_GUESTS - this.roomControls[roomIndex].get('children').value;
    this.updateNumberOfGuests(roomIndex);
  }

  childrenChanged(numberOfChildren: number, roomIndex: number) {
    this.roomControls[roomIndex].get('children').setValue(numberOfChildren);
    this.maxNumberOfAdults[roomIndex] = this.MAX_TOTAL_GUESTS - this.roomControls[roomIndex].get('children').value;
    this.maxNumberOfChildren[roomIndex] = this.MAX_TOTAL_GUESTS - this.roomControls[roomIndex].get('adults').value;
    this.updateAges(roomIndex);
    this.updateNumberOfGuests(roomIndex);
  }

  updateAges(roomIndex: number) {
    const numberOfChildren = this.roomControls[roomIndex].get('children').value;
    const ages = this.roomControls[roomIndex].get('ages')?.value;
    const newAges = [];
    if (ages.value) {
      for (let i = 0; i < numberOfChildren; i++) {
        newAges.push(ages && ages.length > i ? ages.value[i] : 0);
      }
      this.roomControls[roomIndex].setControl('ages', this.formBuilder.array(newAges));
    }
  }

  agesChanged(age: number, roomIndex: number, childIndex: number) {
    const ages = this.roomControls[roomIndex].get('ages') as UntypedFormArray;
    ages.controls[childIndex].setValue(age);
  }

  getNumberOfChilds(room, childIndex) {
    const value = this.roomControls[room].get('ages').controls.length > childIndex
      ? this.roomControls[room].get('ages').controls[childIndex].value
      : 0;
    return value;
  }

  addRoom() {
    this.roomsList.push(
      this.formBuilder.group({
        adults: [2],
        children: [0],
        ages: this.formBuilder.array([])
      })
    );
    this.guestsForm.setControl('rooms', this.roomsList);
    this.adultsChanged(2, this.roomsList.length - 1);
    this.guestsForm.get('numberOfRooms').setValue(this.roomsList.length);
  }

  updateNumberOfGuests(index: number) {
    this.numberOfAdults[index] = [];
    this.numberOfChildren[index] = [];
    for (let i = 1; i <= 6; i++) {
      this.numberOfAdults[index].push({
        value: i, label: i.toString(), disabled: this.maxNumberOfAdults[index] < i
      });
    }
    for (let i = 0; i <= 6; i++) {
      this.numberOfChildren[index].push({
        value: i, label: i.toString(), disabled: this.maxNumberOfChildren[index] < i
      });
    }
  }

  openSection() {
    this._visible = true;
  }

  closeSection() {
    this._visible = false;
  }

  removeRoom(index: number) {
    this.roomsList.removeAt(index);
    this.guestsForm.get('numberOfRooms').setValue(this.roomsList.length);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  existErrorForRoom(roomIndex: number): boolean {
    return this.roomsWithErrors.some(error => error === roomIndex);
  }
}
