<div class="secondary-content text-center title">{{ 'forgot_password.enter_email' | translate }}</div>
<form [formGroup]="resetPasswordRequestForm">
  <airlines-autocomplete-component *ngIf="airlineLogin && !isWhiteLabel" [airlines]="airlines" [form]="resetPasswordRequestForm"
    [codeFieldName]="'airlineCode'" [displayFieldName]="'airline'" [organizationIdFieldName]="'organizationId'"
    [control]="resetPasswordRequestForm.get('airlineCode')" [isSignIn]="true" [action]="'reset-pwd'">
  </airlines-autocomplete-component>
  <div class="form-group">
    <label for="memberEmail" data-error="error">
      {{ (airlineLogin && !isWhiteLabel ? 'login_page.employee_number_placeholder' : 'trebeca.login.place_holder') | translate }}
    </label>
    <input type="text" id="memberEmail" class="form-control" formControlName="memberEmail" (click)="resetUser()"
      placeholder="{{ (airlineLogin && !isWhiteLabel ? 'password_reset.username_label' : 'trebeca.login.label') | translate }}" data-cy="username">
    <div class='error-message' *ngIf="memberEmail.errors !== null && (memberEmail.touched)"
      [innerHtml]="!isWhiteLabel ? formErrorsHelper.errorMessage(memberEmail, ('password_reset.username_label' | translate)) : formErrorsHelper.errorMessage(memberEmail, ('trebeca.login.label' | translate))">
    </div>
    <div class='error-message' *ngIf="userNotFound && !memberEmail.touched" [innerHtml]="'errors.user_not_found' | translate"></div>
  </div>
  <div class="row">
    <div class='error-message' *ngIf="this.attemptsHelper.isBlockedDueToAttempts()" [innerHtml]="'errors.blocked_due_to_attempts' | translate"></div>
    <div class="col-12 text-center">
      <button type="submit" class="btn btn-primary my-2 w-50" (click)="verifyAccount()" [disabled]="this.attemptsHelper.isBlockedDueToAttempts()" data-cy="continue">{{ 'home_page.continue' | translate }}</button>
    </div>
  </div>
</form>