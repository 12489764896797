<div class="form-group">
  <div class="input-group input-border" [formGroup]="formGroup">
    <ng-select
      class="country-select no-border selector-wrapper disable-cursor"
      [items]="countries"
      [clearable]="false"
      [searchable]="true"
      [searchFn]="searchCountry"
      [(ngModel)]="selectedCountry"
      formControlName="country"
      placeholder="+x"
      autocomplete="tel-country-code"
      [readonly]="!countryEnabled">
      <ng-template ng-label-tmp let-item="item">
        <div class="ps-1">
          <span [class]="'me-1 fi fi-' + item?.code?.toLowerCase()"></span>
          +{{ item.phone }}
        </div>
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        <div class="ps-1">
          <span [class]="'me-1 fi fi-' + item.code.toLowerCase()"></span>
          {{ item.name }} (+{{ item.phone }})
        </div>
      </ng-template>
    </ng-select>
    <div class="position-relative input-wrapper form-floating">
      <input *ngIf="!hideNumber" type="tel" class="form-control with-background no-border" data-cy="phoneInput" aria-label="Text input with dropdown button" maxlength="15"
      formControlName="number" placeholder="{{getPlaceHolderByCountry}}" autocomplete="tel-national" (keyup)="keyUpController($event)" [readonly]="!numberEnabled">
      <input *ngIf="hideNumber" class="form-control with-background no-border" formControlName="number" [placeholder]="getPlaceHolderByCountry" autocomplete="tel" [readonly]="!numberEnabled">
      <label [for]="formGroupName">{{ labelKey | translate }}</label>
    </div>
  </div>
  <label class="tertiary-content" *ngIf="showHelpText">
    {{ 'membership.create.phone_help' | translate }}
  </label>
  <div class='error-message' *ngIf="(formGroup.errors !== null && formGroup.touched) || phoneForceError" data-cy="error-phoneNumber"
    [innerHtml]="formErrorsHelper.errorMessage(formGroup, labelKey | translate)"></div>
</div>
