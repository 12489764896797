import { TranslateLoader } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { take, mergeMap, skipWhile } from 'rxjs/operators';
import * as fromAuth from 'app/auth/store/reducers';
import * as fromShared from 'app/shared/store/reducers';
import { merge } from 'lodash';
import { AirlinesService } from 'app/shared/services/airlines/airlines.service';

export class TranslationsLoaderService implements TranslateLoader {
  defaultLanguage = 'en';

  constructor(
    private store: Store<fromAuth.State>,
    private airlinesService: AirlinesService
  ) { }

  getTranslation(lang: string): Observable<any> {
    let allTranslations;
    try {
      delete require.cache[require.resolve(`../../../../angular_assets/i18n/${lang}.json`)];
      delete require.cache[require.resolve(`../../../../angular_assets/i18n/${this.defaultLanguage}.json`)];
      allTranslations = require(`../../../../angular_assets/i18n/${lang}.json`);
    } catch (err) {
      allTranslations = require(`../../../../angular_assets/i18n/${this.defaultLanguage}.json`);
    }
    this.store.select<any>(fromShared.getAirlines).pipe(skipWhile(airlines => !airlines)).subscribe((airlines) => {
      const whiteLabelAirlineCode = this.airlinesService.getWhiteLabelAirlineCode(airlines);
      if (whiteLabelAirlineCode) {
        try {
          const airlineTranslations = require(`../../../../angular_assets/i18n/${whiteLabelAirlineCode.toUpperCase()}/${lang}.json`);
          allTranslations = merge(allTranslations, airlineTranslations);
        } catch (err) {
          // No airline translations in selected language.
          try {
            const airlineTranslations = require(`../../../../angular_assets/i18n/${whiteLabelAirlineCode.toUpperCase()}/${this.defaultLanguage}.json`);
            allTranslations = merge(allTranslations, airlineTranslations);
          } catch (err) {
            // No airline translations.
          }
        }
      }
    });

    return this.store.select<any>(fromAuth.getUser).pipe(
      take(1),
      mergeMap((user) => {
        if (typeof user.account.airlineCode !== 'undefined' && user.account.airlineCode !== '') {
          const airline = user.account.airlineCode;
          try {
            const airlineTranslations = require(`../../../../angular_assets/i18n/${airline}/${lang}.json`);
            allTranslations = merge(allTranslations, airlineTranslations);
          } catch (err) {
            // No airline translations in selected language.
            try {
              const airlineTranslations = require(`../../../../angular_assets/i18n/${airline}/${this.defaultLanguage}.json`);
              allTranslations = merge(allTranslations, airlineTranslations);
            } catch (err) {
              // No airline translations.
            }
          }
        }
        return of(allTranslations);
      })
    );
  }
}
