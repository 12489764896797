import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'member-progress-bar',
  templateUrl: './member-progress-bar.component.html',
  styleUrls: ['./member-progress-bar.component.scss'],
})
export class MemberProgressBarComponent implements OnInit, OnChanges {
  @Input() size: 'small' | 'normal' | 'big' = 'normal';

  @Input() showLevels = true;
  @Input() showAwayFromNextLevel = true;
  @Input() showGradient = true;
  @Input() colorLevel = '#7b7d9d';

  @Input() nextTierReward: number;
  @Input() currentTierLtv: number;
  @Input() nextTierLtv: number;
  @Input() nextTierGapLtv: number;
  @Input() currentLevel: number;
  @Input() nextLevel: number;
  @Input() nextTierCurrentLtv : number;
  @Input() currency : string;

  percent = '';
  isSmall: boolean;
  isNormal: boolean;
  isBig: boolean;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentTierLtv || changes.nextTierLtv) {
      this.calculatePercent();
    }
    if (changes.size) {
      this.initSizeFlags();
    }
  }

  ngOnInit() {
    this.initSizeFlags();
  }

  private calculatePercent() {
    const percent = (this.nextTierCurrentLtv * 100) / this.nextTierLtv;
    if (percent > 0) {
      this.percent = `${percent.toFixed()}%`;
    }
  }

  private initSizeFlags() {
    this.isSmall = this.size === 'small';
    this.isNormal = this.size === 'normal';
    this.isBig = this.size === 'big';
  }
}
