import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmartAppBannerComponent } from './components/smart-app-banner/smart-app-banner.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    SmartAppBannerComponent
  ],
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [
    SmartAppBannerComponent
  ]
})
export class SmartAppBannerModule { }
