<div *ngIf="!isFraudUser;else fraudUser" [ngClass]="{'h-100': !isMobileView}">
  <ng-container
    [ngTemplateOutlet]="displayTabs ? displayFormTabs : displayForm"
  ></ng-container>
</div>

<ng-template #displayForm>
  <ng-container [ngSwitch]="selectedTab">
    <ng-container *ngSwitchCase="'hotels-tab'">
      <hotel-search
        *ngIf="showHotelForm"
        [recentSearches]="recentHotelSearches"
        [recentSearchesPending]="recentHotelSearchesPending"
        [trendingDestinations]="trendingHotelDestinations"
        [trendingDestinationsPending]="trendingHotelDestinationsPending"
        [displayInLine]="displayInLine"
        (searchInitiated)="hotelSearchInitiated.emit($event)"
      >
      </hotel-search>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #displayFormTabs>
  <div class="form-tabs" [id]="'search-tabs'">
    <ul
      ngbNav
      #nav="ngbNav"
      class="justify-content-start nav nav-tabs"
      [ngClass]="{'no-border': !displayInLine}"
      (navChange)="tabChanged($event)"
    >
      <li [ngbNavItem]="'hotels-tab'">
        <a ngbNavLink *featureFlag="featureFlags.HOTELS"
          >{{'nav.title.lodging' | translate}}</a
        >
        <ng-template ngbNavContent class="align-items-center h-100">
          <hotel-search
            *ngIf="showHotelForm"
            [recentSearches]="recentHotelSearches"
            [recentSearchesPending]="recentHotelSearchesPending"
            [trendingDestinations]="trendingHotelDestinations"
            [trendingDestinationsPending]="trendingHotelDestinationsPending"
            [displayInLine]="displayInLine"
            (searchInitiated)="hotelSearchInitiated.emit($event)"
          >
          </hotel-search>
        </ng-template>
      </li>
      <li [ngbNavItem]="'cars-tab'">
        <a ngbNavLink *featureFlag="featureFlags.CARS"
          >{{ 'nav.title.cars' | translate }}</a
        >
        <ng-template ngbNavContent class="align-items-center">
          <car-search
            *ngIf="showCarsForm"
            [recentSearches]="recentCarSearches"
            [recentSearchesPending]="recentCarSearchesPending"
          >
          </car-search>
        </ng-template>
      </li>
      <li [ngbNavItem]="'cruises-tab'">
        <a ngbNavLink *featureFlag="featureFlags.CRUISES"
          >{{ 'nav.title.cruises' | translate }}</a
        >
        <ng-template ngbNavContent class="align-items-center">
          <cruise-search></cruise-search>
        </ng-template>
      </li>
      <li [ngbNavItem]="'all-inclusive-tab'">
        <a ngbNavLink *featureFlag="featureFlags.ALL_INCLUSIVE"
          >{{ 'nav.title.all-inclusive' | translate }}</a
        >
        <ng-template ngbNavContent class="align-items-center">
          <hotel-search
            [allInclusive]="true"
            [allInclusivePopupSearch]="allInclusiveAbTest===1"
            [destination]="destination"
            (destinetionFocused)="handleAllInclusiveTab()"
            (searchInitiated)="hotelSearchInitiated.emit($event)"
          >
          </hotel-search>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" [ngClass]="{'shadow p-3': displayInLine}"></div>
  </div>
</ng-template>

<ng-template #fraudUser>
  <div class="form-tabs bg-white py-4 px-3 h-100">
    <p
      class="p-0 m-0"
      [innerHTML]="'flash_messages.fraud_user' | translate"
    ></p>
  </div>
</ng-template>
