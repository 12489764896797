import { UTMParametersActions } from 'app/shared/store/actions';

export interface State {
  utmCampaign: string;
  utmMedium: string;
  utmSource: string;
  utmAffiliate: string;
  expirationTime: string;
}

export const initialState: State = {
  utmCampaign: '',
  utmMedium: '',
  utmSource: '',
  utmAffiliate: '',
  expirationTime: ''
};

export function reducer(
  state = initialState,
  action: UTMParametersActions.UTMParametersActionsUnion
) {
  switch (action.type) {

    case UTMParametersActions.UTMParametersActionTypes.Load:
      return {
        ...state,
        ...action.payload
      };
    case UTMParametersActions.UTMParametersActionTypes.Clear:
      return {
        utmCampaign: '',
        utmMedium: '',
        utmSource: '',
        utmAffiliate: '',
        expirationTime: ''
      };
    default:
      return state;
  }
}

export const getUtmParameters = (state: State) => state;
