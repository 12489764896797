import { Component, Input, Output, EventEmitter, ViewEncapsulation, OnInit } from '@angular/core';
import { DeletePaymentMethodComponent } from 'app/profile/components/delete-payment/delete-payment-method.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreditCardHelper } from 'app/shared/helpers/credit-card.helper';
import { EditPaymentComponent } from '../edit-payment/edit-payment.component';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'payment-methods-card',
  templateUrl: './payment-methods-card.html',
  styleUrls: ['./payment-methods-card.scss'],
  encapsulation: ViewEncapsulation.None
})

export class PaymentMethodsCardComponent implements OnInit {
  @Input() item;
  @Input() numberOfCards;
  @Input() requestMFA;
  @Input() userData: any;
  @Output() deleteClicked: EventEmitter<any>;
  @Output() primaryClicked: EventEmitter<any>;
  @Output() editClicked: EventEmitter<any>;
  isPrimary = false;
  isExpired = false;
  icons = { faCheckCircle };

  constructor(private modalService: NgbModal) {
    this.deleteClicked = new EventEmitter();
    this.primaryClicked = new EventEmitter();
    this.editClicked = new EventEmitter();
  }

  ngOnInit() {
    this.isPrimary = this.item.preferred === '1' || this.numberOfCards === 1;
    this.isExpired = CreditCardHelper.isExpired(this.item.expiration_date);
  }

  onDeleteOpen(token) {
    const modalRef = this.modalService.open(DeletePaymentMethodComponent, { size: 'lg' });
    modalRef.componentInstance.token = token;
    modalRef.componentInstance.requestMFA = this.requestMFA;
    modalRef.componentInstance.userData = this.userData;
    modalRef.result.then((result) => {
      this.onPaymentMethodDeleted(result);
    });
  }

  onEditOpen(card) {
    this.editClicked.emit(card);
  }

  onPaymentMethodDeleted(event) {
    this.deleteClicked.emit(event);
  }

  onPrimaryCliked() {
    this.primaryClicked.emit({
      token: this.item.token
    });
  }

}
