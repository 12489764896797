<section class="py-5" *ngIf="recentHotelSearches.length > 0">
  <div class="container">
    <div class="row">
      <div class="col">
        <h2>{{'mobile.general.recent_hotel_searches' | translate }}</h2>
      </div>
    </div>
    <destinations
      [source]="'recentSearches'"
      [destinationsList]="recentHotelSearches">
    </destinations>
  </div>
</section>