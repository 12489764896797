import { Action } from '@ngrx/store';

export enum PromoCodeActionType {
    CheckPromoCode = '[Promo Code] Check Promo Code',
    CheckPromoCodeSuccess = '[Promo Code] Check Promo Code Success',
    CheckPromoCodeFailure = '[Promo Code] Check Promo Code Failure',
    RedeemPromoCode = '[Promo Code] Redeem Promo Code',
    RedeemPromoCodeSuccess = '[Promo Code] Redeem Promo Code Success',
    RedeemPromoCodeFailure = '[Promo Code] Redeem Promo Code Failure',
}

export class CheckPromoCode implements Action {
  readonly type = PromoCodeActionType.CheckPromoCode;
  constructor(public payload: { promoCode: any }) {}
}

export class CheckPromoCodeSuccess implements Action {
  readonly type = PromoCodeActionType.CheckPromoCodeSuccess;

  constructor(public payload: { promoCode: any }) {}
}

export class CheckPromoCodeFailure implements Action {
  readonly type = PromoCodeActionType.CheckPromoCodeFailure;

  constructor(public payload: { error: any }) {}
}
export class RedeemPromoCode implements Action {
  readonly type = PromoCodeActionType.RedeemPromoCode;
  constructor(public payload: { code: any }) {}
}
export class RedeemPromoCodeSuccess implements Action {
  readonly type = PromoCodeActionType.RedeemPromoCodeSuccess;
  constructor(public payload: { payload: any }) {}
}
export class RedeemPromoCodeFailure implements Action {
  readonly type = PromoCodeActionType.RedeemPromoCodeFailure;
  constructor(public payload: { error: any }) {}
}

export type PromoCodeUnion =
    CheckPromoCode
  | CheckPromoCodeFailure
  | CheckPromoCodeSuccess
  | RedeemPromoCode
  | RedeemPromoCodeSuccess
  | RedeemPromoCodeFailure;
