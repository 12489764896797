<ngb-alert *ngIf="success" type="success" (closed)="closeAlert()">{{ success | translate }}</ngb-alert>
<ngb-alert *ngIf="error" type="danger" (closed)="closeAlert()">{{ error }}</ngb-alert>
<div class="d-sm-none back-button">
  <button type="button" class="btn btn-link" (click)="backToTabs()"><fa-icon [icon]="icons.faChevronLeft" aria-hidden="true"></fa-icon> {{'back' | translate}} </button>
</div>
<div class="card shadow-none mb-2">
  <div class="card-body">
    <div class="row">
      <div class="col-sm-5">
        <avatar></avatar>
      </div>
      <div class="col-sm-7" *featureFlag="featureFlags.LOGIN_AND_SECURITY">
        <link-social-account
          [requestMFA]="requestMFA"
          [profileForm]="profileForm"
          [userStatistics]="userStatistics"
          [userInfo]="userInfo">
        </link-social-account>
      </div>
    </div>
  </div>
</div>
<member-savings-card [memberSavings]='memberSavings' [memberLoyalty]='memberLoyalty'></member-savings-card>
<member-loyalty-card *ngIf="userCredits && memberLoyalty && !isFraudUser" [userCredits]="userCredits" [memberLoyalty]="memberLoyalty"></member-loyalty-card>
<div class="card shadow-none mb-4 profile-form">
  <div class="card-body">
    <div [formGroup]="profileForm" *ngIf="ready">
      <div formGroupName="employee">
        <div class="row">
          <div class="col-12 col-md-6" *ngIf="profileConfig.firstName.show">
            <div class="form-group form-floating">
              <input placeholder="First Name" type="text" formControlName="firstName" id="firstName" class="form-control validate with-background" placeholder=" "
                required autocomplete="given-name">
              <label for="firstName">{{'profile.info.firstName' | translate}}</label>
              <div class='error-message' *ngIf="firstName.errors !== null && (firstName.touched)"
                [innerHtml]="formErrorsHelper.errorMessage(firstName, 'profile.info.firstName' | translate)"></div>
            </div>
          </div>
          <div class="col-12 col-md-6" *ngIf="profileConfig.lastName.show">
            <div class="form-group form-floating">
              <input placeholder="Last Name" type="text" formControlName="lastName" id="lastName" class="form-control validate with-background" placeholder=" "
                required autocomplete="family-name">
              <label for="lastName">{{'profile.info.lastName' | translate}}</label>
              <div class='error-message' *ngIf="lastName.errors !== null && (lastName.touched)"
                [innerHtml]="formErrorsHelper.errorMessage(lastName, 'profile.info.lastName' | translate)"></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6" *ngIf="profileConfig.middleName.show">
            <div class="form-group form-floating">
              <input placeholder="Middle Name" type="text" formControlName="middleName" id="middleName" class="form-control validate with-background" placeholder=" "
                autocomplete="given-name" [disabled]="!profileConfig.middleName.enabled" data-cy="middle-name">
              <label for="middleName">{{'profile.info.middleName' | translate}}</label>
              <div class='error-message' *ngIf="middleName.errors !== null && (middleName.touched)"
                [innerHtml]="formErrorsHelper.errorMessage(middleName, 'profile.info.middleName' | translate)"></div>
            </div>
          </div>
          <div class="col-12 col-md-6" *ngIf="profileConfig.gender.show">
            <div class="form-group form-floating" *ngIf="profileConfig.gender.show">
              <select class="form-control form-select form-select with-background" placeholder=" " formControlName="gender" required data-cy="gender">
                <option *ngFor="let gender of genderOptions" [value]="gender.value">{{gender.label}}</option>
              </select>
              <label>{{'flights.booking.ticketing.gender.title' | translate }}</label>
              <div class='error-message' *ngIf="gender.errors !== null && (gender.touched)"
                [innerHtml]="formErrorsHelper.errorMessage(gender, 'flights.booking.ticketing.gender.title' | translate)"></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6" *ngIf="profileConfig.personalEmail.show">
            <div class="form-group">
              <div class="position-relative form-floating">
                <input type="email" formControlName="personalEmail" id="personalEmail" class="form-control validate with-background" placeholder=" "
                       required autocomplete="email" [readonly]="isInvitedTraveler || !loginEnabled" data-cy="personal-email">
                <label for="personalEmail">{{'membership.create.personal_email' | translate}}</label>
                <fa-icon [icon]="icons.faEnvelope" class="input-icon"></fa-icon>
              </div>
              <div class='error-message' *ngIf="personalEmail.errors !== null && (personalEmail.touched)" data-cy="error-personalEmail"
                   [innerHtml]="formErrorsHelper.errorMessage(personalEmail, 'membership.create.personal_email' | translate)"></div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <phone-input
              labelKey="profile.info.homePhone"
              formGroupName="homePhone"
              [countries]="countries"
              [formGroup]="homePhone"
              [countryEnabled]="profileConfig.homePhone && profileConfig.homePhone.enabled"
              [numberEnabled]="profileConfig.homePhone && profileConfig.homePhone.enabled"
              [phoneForceError]="phoneError"
              [strictPhoneValidation]="true"
              (onKeyUp)="checkHomePhone()"
            ></phone-input>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6" *ngIf="profileConfig.birthDate.show">
            <div class="form-group">
              <div class="position-relative ngbDatepicker form-floating">
                <input class="form-control with-background" placeholder="" [disabled]="!profileConfig.birthDate.enabled"
                         name="birthDate" [minDate]="minDate" [startDate]="formatStartDate()" [value]="startDate" [readonly]="true"
                         [maxDate]="maxDate" ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" (dateSelect)="onDatesSelected($event)">
                <label for="birthDate">{{'profile.info.dob' | translate}}(yyyy-mm-dd)</label>
                <fa-icon [icon]="icons.faCalendar" class="input-icon" (click)="d.toggle()"></fa-icon>
              </div>
              <div class='error-message' *ngIf="birthDate.errors !== null && (birthDate.touched)" data-cy="error-birthDate"
                   [innerHtml]="formErrorsHelper.errorMessage(birthDate, 'membership.create.birthDate' | translate)"></div>
            </div>
          </div>
          <div class="col-12 col-md-6" *ngIf="profileConfig.countryOfResidence.show">
            <div class="form-group form-floating">
              <select class="form-control form-select form-select with-background" placeholder=" " formControlName="countryOfResidence" data-cy="countryOfResidence">
                <option [value]="null">{{'profile.info.select' | translate}}</option>
                <option *ngFor="let country of countries" [value]="country.code">{{country.name}}</option>
              </select>
              <label for="countryOfResidence">{{ 'cars.form_labels.country_of_residence' | translate }}</label>
              <div class='error-message'
                *ngIf="countryOfResidence.errors !== null && (countryOfResidence.touched)"
                [innerHtml]="formErrorsHelper.errorMessage(countryOfResidence, ('cars.form_labels.country_of_residence' | translate))">
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6" *ngIf="displayKnownTravelerNumber">
            <div class="form-group form-floating">
              <input type="text" formControlName="knownTravelerNumber" id="knownTravelerNumber" placeholder=" " class="form-control validate with-background" data-cy="known-traveler-number">
              <label for="knownTravelerNumber">{{'profile.info.knownTravelerNumber' | translate}}</label>
              <div class='error-message' *ngIf="knownTravelerNumber.errors !== null && (knownTravelerNumber.touched)"
              [innerHtml]="formErrorsHelper.errorMessage(knownTravelerNumber, 'profile.info.knownTravelerNumber' | translate)"></div>
            </div>
          </div>
          <ng-container *featureFlag="featureFlags.LOGIN">
            <div class="col-12 col-md-6" *ngIf="profileConfig.employeeNumber.show">
              <div class="form-group form-floating">
                <input type="text" formControlName="employeeNumber" placeholder=" " id="employeeNumber" class="form-control validate with-background" required>
                <label for="employeeNumber">{{'profile.info.employeeNumber' | translate}}</label>
                <div class='error-message' *ngIf="employeeNumber.errors !== null && (employeeNumber.touched)"
                  [innerHtml]="formErrorsHelper.errorMessage(employeeNumber, 'profile.info.employeeNumber' | translate)"></div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row bd-highlight profile-form my-3 align-items-center text-center">
  <div class="col-lg-3 col-12 bd-highlight order-md-3 order-1">
    <button type="button" class="btn btn-primary gtm-update-profile w-100" (click)="onEditProfile()" *ngIf="!loading && !isFraudUser" data-cy="update-profile">
      {{'profile.info.update' | translate}}
    </button>
    <button type="button" class="btn btn-primary" *ngIf="loading" disabled><fa-icon [icon]="icons.faCircleNotch"></fa-icon>
      {{'invite.invite_processing' | translate}}
    </button>
  </div>
</div>
