import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { WatchlistService } from 'app/shared/services/watchlist/watchlist.service';
import { BaseWatchlistCardComponent } from '../watchlist-card/base-watchlist-card.component';
import { take } from 'rxjs/operators';
import { select, Store } from "@ngrx/store";
import * as fromAuth from "app/auth/store/reducers";
import { FlightHelper } from "app/shared/helpers/flight.helper";
import { AnalyticsService } from "app/shared/services/analytics/analytics.service";

@Component({
  selector: 'flight-watchlist-card',
  templateUrl: './flight-watchlist-card.html',
  styleUrls: ['./flight-watchlist-card.scss']
})

export class FlightWatchlistCardComponent extends BaseWatchlistCardComponent implements OnInit {
  flight: any;
  defaultBackground: string = 'https://marketing.id90travel.com/web/tgen/miami_beach.jpg';
  watcherLoading: any = [];
  user$ = this.store.pipe(select(fromAuth.getUser));

  constructor(
    private watchlistService: WatchlistService,
    private flightHelper: FlightHelper,
    private store: Store<fromAuth.State>,
    private analyticsService: AnalyticsService
  ) {
    super();
  }

  ngOnInit() {
    // We create the flight object here to prevent change detection in the view.
    this.setFlightObject();
  }

  setFlightObject() {
    this.flight = {
      image: this.airlineImage(this.item),
      date: this.formatDate(this.item.checkin_at),
      flightNumber: `${this.item.watching_data.transportingCarrier}-${this.item.watching_data.flightNumber}`,
      departureName: this.item.watching_data.departureAirportName,
      departureCode: this.item.watching_data.departureAirportCode,
      departureTime: this.item.watching_data.departureTime,
      arrivalName: this.item.watching_data.arrivalAirportName,
      arrivalCode: this.item.watching_data.arrivalAirportCode,
      arrivalTime: this.item.watching_data.arrivalTime,
      destinationImage: this.defaultBackground,
      watchingData: this.item.watching_data
    };
  }

  formatDate(date: string) : string {
    return moment(date).format('dddd, MMMM D, YYYY');
  }

  airlineImage(flight: any) : string {
    const airline = flight.watching_data.transportingCarrier;
    if (airline.length > 0) {
      return `angular_assets/images/flights/airlines/${airline.toUpperCase()}.png`;
    }
    return 'angular_assets/images/flights/airlines/NONE.png';

  }

  onUpdateFlightStatusClicked() {
    this.watcherLoading[this.item.api_id] = true;
    this.watchlistService.updateFlightLoadWatcher(this.item.api_id).pipe(take(1)).subscribe((data) => {
      this.item = data.watcher;
      this.setFlightObject();
      this.logFlightsWatcherEvent('update');
      this.watcherLoading[this.item.api_id] = false;
    });
  }

  logFlightsWatcherEvent(type: string){
    this.user$.pipe(
      take(1)
    ).subscribe((user) => {
      if (user && this.flightHelper.hasAnyFlightsTools(user)) {
        const event = {
          line_of_business: 'Flights',
          item_name: `Flights ${type} watchlist`,
          user_action: type,
          user_id: user.account.id || user.account.idMembers,
          is_invited: user.account.airlineCode === '100'
        };
        this.analyticsService.logEvent(`${type}_watchlist`, event);
      }
    });
  }
}
