import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { GamificationStatistics } from 'app/profile/models/gamification-statistics.interface';
@Injectable({
  providedIn: 'root'
})
export class GamificationService {

  constructor(
    private http: HttpClient
  ) { }

  initGame() {}



  getActiveCampaigns() {
    return this.http.get(`${environment.apiUrl}/api/v1/gamification/campaigns`);
  }

  getCampaignImage (imageName:string, outputFormat?:string) {
    return `${environment.apiUrl}/api/v1/gamification/files/campaign/${imageName}${outputFormat ? `?outputFormat=${outputFormat}` : '' }`;
  }

  participateCampaign(idCampaign, participate) {
    return this.http.post(`${environment.apiUrl}/api/v1/gamification/participation` , { campaign_id: idCampaign, participating: participate });
  }

  isUserNotParticipating(campaigns) {
    return !!campaigns.find((c) => c.require_participation && c.user_participating === null);
  }

  isUserParticipating(campaigns) {
    return !!campaigns.find((c) => c.require_participation && c.user_participating === true);
  }

  hasFreeEntry(campaigns) {
    return !!campaigns.find((c) => c.require_participation && c.user_participating === true && c.free_entry);
  }

  canSendTransaction(campaigns, statistics) {
    return !!campaigns.find((c) => c.require_participation && c.user_participating === true && !c.finished) && !this.goalIsMet(statistics);
  }

  canCancelTransaction(campaigns) {
    return !!campaigns.find((c) => c.require_participation && c.user_participating === true && !c.finished);
  }

  getParticipatingCampaign(campaigns) {
    return campaigns.find((c) => c.require_participation);
  }

  goalIsMet(gamificationStatistics) {
    return gamificationStatistics?.value >= gamificationStatistics?.metadata?.target;
  }

  sendTransaction(transaction) {
    return this.http.post(`${environment.apiUrl}/api/v1/gamification/transaction` , transaction);
  }

  cancelTransaction(transaction) {
    return this.http.put(`${environment.apiUrl}/api/v1/gamification/transaction` , transaction);
  }

  checkFreeEntryAvailable() {
    return this.http.get(`${environment.apiUrl}/api/v1/gamification/transaction/isDailyFreeEntryAvailable`);
  }

  getStatistics() {
    return this.http.get<GamificationStatistics>(`${environment.apiUrl}/api/v1/gamification/campaigns/getUserStats`)
  }

  checkGoalMetTransactionDone(campaignId: number) {
    return this.http.get<boolean>(`${environment.apiUrl}/api/v1/gamification/transaction/hasDoneGoalMetTx/${campaignId}`);
  }

  hasTransactions(campaignId: number) {
    return this.http.get<boolean>(`${environment.apiUrl}/api/v1/gamification/transaction/hasTransactions/${campaignId}`);
  }
}
