import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import * as fromAuth from 'app/auth/store/reducers';
import { FlightHelper } from 'app/shared/helpers/flight.helper';
import { HotelHelper } from 'app/shared/helpers/hotel.helper';
import { InviteTravelerHelper } from 'app/shared/helpers/invite-traveler.helper';
import { StringHelper } from 'app/shared/helpers/string.helper';
import { featureFlags } from 'app/shared/models/featureFlags';
import { AnalyticsService } from 'app/shared/services/analytics/analytics.service';
import { SeoService } from 'app/shared/services/seo/seo.service';
import * as fromShared from 'app/shared/store/reducers';
import { CarTrip } from 'app/trips/models/car-trip';
import { FlightTripReservation } from 'app/trips/models/flight-trip';
import { HotelTrip } from 'app/trips/models/hotel-trip';
import { InsuranceTrip } from 'app/trips/models/insurance-trip';
import { TripsActions } from 'app/trips/store/actions';
import * as fromTrips from 'app/trips/store/reducers';
import keysJson from 'configs/pathsKeysConfig.json';
import * as fromRoot from 'reducers';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'trips',
  templateUrl: './trips.html',
  styleUrls: ['./trips.scss'],
  encapsulation: ViewEncapsulation.None
})

export class TripsPage implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('nav', { static: false }) private tabs: NgbNav;
  selectedTab: any         = { tab: 'hotels' };
  futureHotels$            = this.store.pipe(select(fromTrips.getFutureHotels));
  pastHotels$              = this.store.pipe(select(fromTrips.getPastHotels));
  futureFlights$           = this.store.pipe(select(fromTrips.getFutureFlights));
  pastFlights$             = this.store.pipe(select(fromTrips.getPastFlights));
  futureCars$              = this.store.pipe(select(fromTrips.getFutureCars));
  pastCars$                = this.store.pipe(select(fromTrips.getPastCars));
  hotelsInsurance$         = this.store.pipe(select(fromTrips.getHotelsInsurance));
  flightsInsurance$        = this.store.pipe(select(fromTrips.getFlightsInsurance));
  availableTools$          = this.store.pipe(select(fromAuth.getAvailableTools));
  sharedHotels$            = this.store.pipe(select(fromTrips.getSharedHotels));
  pastSharedHotels$        = this.store.pipe(select(fromTrips.getPastSharedHotels));
  customerSupport$         = this.store.pipe(select(fromAuth.getCustomerSupport));
  invitedTravelerInfo$     = this.store.pipe(select(fromAuth.getInvitedTravelerInfo));
  configFlags$             = this.store.pipe(select(fromShared.getConfigFlag));
  featureFlags$            = this.store.pipe(select(fromShared.getFeatureFlags));
  featureFlags             = featureFlags;
  hotelsLoadedSubscription: Subscription;
  carsLoadedSubscription: Subscription;
  insuranceLoadedSubscription: Subscription;
  userSelectedByOperator: boolean = false;
  showFlights = false;
  showId90Tools = false;
  isUsedLoadFlightsByPNR = false;
  invitedTravelerId: string;
  eligibleTravelersEnabled: boolean;
  availableTools: string[] = [];
  routes = {
    hotels: {
      route: '/trips/hotels',
      sections: []
    },
    flights: {
      route: '/trips/flights',
      sections: ['ticketing', 'company_business', 'listing', 'cobus_admin', 'invited_traveler']
    },
    cars: {
      route: '/trips/cars',
      sections: []
    },
    insurance: {
      route: '/trips/insurance',
      sections: []
    },
  };
  private ngUnsubscribe = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<fromRoot.State>,
    private hotelHelper: HotelHelper,
    private cdRef: ChangeDetectorRef,
    private seoService: SeoService,
    private inviteTravelerHelper: InviteTravelerHelper,
    private flightHelper: FlightHelper,
    private analyticsService: AnalyticsService,
    private stringHelper: StringHelper
  ) {}

  ngOnInit(): void {
    this.seoService.setTitle('My Trips');
    this.store.dispatch(new TripsActions.CleanHotelTrips());
    this.store.dispatch(new TripsActions.CleanFlightTrips());
    this.store.dispatch(new TripsActions.CleanCarTrips());
    this.store.dispatch(new TripsActions.CleanInsuranceTrips());
    combineLatest([
      this.availableTools$,
      this.invitedTravelerInfo$,
      this.customerSupport$,
      this.route.params,
      this.configFlags$,
      this.featureFlags$
    ])
      .pipe(
        debounceTime(0), // Patch combineLatest glitch (triggered once per observable)
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([tools, invitedTravelerInfo, cSupport, params, configFlags, featureFlags]) => {
        this.showId90Tools = tools && tools.includes('id90');
        this.availableTools = tools;
        if (invitedTravelerInfo) {
          this.invitedTravelerId = invitedTravelerInfo.invitedTravelerSelected.id;
        }
        this.showFlights = this.canViewFlightsTab(tools, this.invitedTravelerId);
        this.userSelectedByOperator = cSupport.userSelectedByOperator;
        if (params.tab) {
          this.selectedTab.tab = params.tab;
        }
        if (params.section) {
          this.selectedTab.section = params.section;
        }
        if (this.selectedTab.tab === 'flights') {
          // Move to Flight confirmation Page when flights implementation is ready
          this.store.dispatch(new TripsActions.ClearFlights());
        }
        this.logAnalyticsPageView();
        this.eligibleTravelersEnabled = this.availableTools.includes('ticketing') &&
          this.inviteTravelerHelper.canInvite(configFlags, featureFlags);
        this.loadTrips();
      });
  }

  onSectionSelected(subTab) {
    this.selectedTab.section = subTab;
  }

  logAnalyticsPageView() {
    const page = this.selectedTab.section ?
      `${this.stringHelper.cleanAndCapitalize(this.selectedTab.tab)} ${this.stringHelper.cleanAndCapitalize(this.selectedTab.section)}` :
      this.stringHelper.cleanAndCapitalize(this.selectedTab.tab);
    this.selectedTab.section = null;
    this.analyticsService.logPageView(`Trips ${page}`);
  }

  loadTrips() {
    switch (this.selectedTab.tab) {
      case 'cars':
        this.carsLoadedSubscription = this.store.pipe(
        take(1),
        select(fromTrips.getFutureCarsLoaded))
          .subscribe((carsLoaded: boolean) => {
            if (!carsLoaded) {
              this.store.dispatch(new TripsActions.LoadFutureCars({ page: '1' }));
              this.store.dispatch(new TripsActions.LoadPastCars({ page: '1' }));
            }
          });
        return;
      case 'flights':
        this.store.pipe(
         take(1),
         select(fromTrips.getFutureFlightsLoaded))
         .subscribe((flightsLoaded: boolean) => {
           if (!flightsLoaded && !this.isUsedLoadFlightsByPNR) {
             this.store.dispatch(new TripsActions.LoadFutureFlights({
               page: '1',
               invitedTravelerId: this.invitedTravelerId,
               eligibleTravelersEnabled: true
             }));
             this.store.dispatch(new TripsActions.LoadPastFlights({
               page: '1',
               invitedTravelerId: this.invitedTravelerId,
               eligibleTravelersEnabled: true
             }));
           }
         });
        return;
      case 'insurance':
        this.insuranceLoadedSubscription = this.store.pipe(
          take(1),
          select(fromTrips.getHotelsInsuranceLoaded))
          .subscribe((insuranceLoaded: boolean) => {
            if (!insuranceLoaded) {
              this.store.dispatch(new TripsActions.LoadHotelsInsurance({ page: '1' }));
            }
          });
        return;
      case 'hotels':
        this.hotelsLoadedSubscription = this.store.pipe(
          take(1),
          select(fromTrips.getFutureHotelsLoaded))
          .subscribe((hotelsLoaded: boolean) => {
            console.log('[HOTELS LOADED]', hotelsLoaded);
            if (!hotelsLoaded) {
              this.store.dispatch(new TripsActions.LoadFutureHotels({ page: '1' }));
              this.store.dispatch(new TripsActions.LoadPastHotels({ page: '1' }));
            }
          });
        return;
    }
  }

  ngAfterViewChecked(): void {
     if (this.tabs) {
      this.tabs.select(this.selectedTab.tab);
    }
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.carsLoadedSubscription) {
      this.carsLoadedSubscription.unsubscribe();
    }
    if (this.hotelsLoadedSubscription) {
      this.hotelsLoadedSubscription.unsubscribe();
    }
    if (this.insuranceLoadedSubscription) {
      this.insuranceLoadedSubscription.unsubscribe();
    }
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onTabChange($event): void {
    this.buildNextUrl($event.nextId);
  }

  buildNextUrl(newPage) {
    let buildUrl = this.routes[newPage].route;

    if (this.selectedTab.section && this.routes[newPage].sections.includes(this.selectedTab.section)) {
      buildUrl = `${buildUrl}/${this.selectedTab.section}`;
    }
    const config = keysJson.config;
    let match;
    if (buildUrl === '/trips/flights') {
      match = [0, 'ticketing'];
    } else {
      match = buildUrl.match((new RegExp(config.tripsPattern)));
    }

    let extras = { replaceUrl: true };
    if (match && match[1]) {
      const toolKey = config[match[1]];
      extras['queryParams'] = { key: `${toolKey}-${config.tripsStep}` };
    }
    return this.router.navigate([buildUrl], extras);
  }

  onCarViewTripClicked(car: CarTrip) {
    return this.router.navigate([`/cars/reservations/confirmation/${car.api_id}`, {
      itinerary_id: car.confirmation_id
    }]);
  }

  onFlightViewTripClicked(item: { flight: FlightTripReservation, type: string}) {
    if (item.flight.tool_key === 'listing') {
      return this.router.navigate([`flights/reservation/confirmation/${item.flight.id}/${item.flight.tool_key}/${item.type}`]);
    }
    const tool = this.selectedTab?.section == 'company_business' ? 'cobus' : item.flight.tool_key;
    return this.router.navigate([`flights/reservation/confirmation/${item.flight.reservationId}/${tool}`]);
  }

  onHotelViewTripClicked(hotel: HotelTrip) {
    const itineraryId = this.hotelHelper.encodeVendorPropertyID(hotel.itineraryId);
    return this.router.navigate([`hotels/reservations/confirmation/${itineraryId}`]);
  }

  onInsuranceViewTripClicked(insurance: InsuranceTrip) {
    return this.router.navigate([`insurances/reservations/confirmation/${insurance.id}`]);
  }

  onLoadFutureItems(page, type) {
    switch (type) {
      case 'cars':
        this.store.dispatch(new TripsActions.LoadFutureCars({ page }));
        return;
      case 'flights':
        this.store.dispatch(new TripsActions.LoadFutureFlights({
          page,
          invitedTravelerId: this.invitedTravelerId,
          eligibleTravelersEnabled: true
        }));
        return;
      case 'insurance':
        if (page.type === 'hotels') {
          this.store.dispatch(new TripsActions.LoadHotelsInsurance({ page: page.pageNumber }));
        } else {
          this.store.dispatch(new TripsActions.LoadFlightsInsurance({ page: page.pageNumber }));
        }
        return;
      case 'hotels':
        this.store.dispatch(new TripsActions.LoadFutureHotels({ page }));
        return;
      default:
        return;
    }
  }

  onLoadPastItems(page, type) {
    switch (type) {
      case 'cars':
        this.store.dispatch(new TripsActions.LoadPastCars({ page }));
        return;
      case 'flights':
        this.store.dispatch(new TripsActions.LoadPastFlights({
          page,
          invitedTravelerId: this.invitedTravelerId,
          eligibleTravelersEnabled: true
        }));
        return;
      case 'hotels':
        this.store.dispatch(new TripsActions.LoadPastHotels({ page }));
        return;
      default:
        return;
    }
  }

  onLoadSharedHotels() {
    this.store.dispatch(new TripsActions.LoadSharedItineraries());
  }

  onloadFlightsByPNR($event) {
    this.isUsedLoadFlightsByPNR = true;
    this.store.dispatch(new TripsActions.LoadFutureFlightsByPNR({
      page: 1,
      pnr: $event.pnr,
      invitedTravelerId: this.invitedTravelerId,
      eligibleTravelersEnabled: true
    }));
    this.store.dispatch(new TripsActions.LoadPastFlightsByPNR({
      page: 1,
      pnr: $event.pnr,
      invitedTravelerId: this.invitedTravelerId,
      eligibleTravelersEnabled: true
    }));
    this.store.dispatch(new TripsActions.ClearFlights());
  }

  onloadFlightsWhenUserCleansSearchByPNRInput() {
    if (this.isUsedLoadFlightsByPNR) {
      this.store.dispatch(new TripsActions.ClearFlights());
      this.store.dispatch(new TripsActions.LoadFutureFlights({ page: '1', eligibleTravelersEnabled: true }));
      this.isUsedLoadFlightsByPNR = false;
    }
  }

  canViewFlightsTab(tools: string[], invitedTravelerId: string): boolean {
    const itId = parseInt(invitedTravelerId);
    if (tools.length > 1 || tools[0] !== 'id90') {
      if (FlightHelper.isToolInFlightsTools(tools)) {
        return true;
      } else {
        if (itId || 'cobus' === tools[0]) { //ET or cobus auth
          return true;
        }
      }
    }
    return false;
  }
}
