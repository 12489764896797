<section class="container">
  <div class="login-form">
    <div class="row pb-4">
      <div class="col-md-6 col-xs-12 offset-md-3 py-5">
        <h2>Login</h2>
        <ngb-alert *ngIf="errorMessage" type="danger" (closed)="closeAlert()">{{ 'login_page.invalid' | translate }}</ngb-alert>
        <form name="loginForm" class="mt-3" [formGroup]="loginForm" (ngSubmit)="login()">
          <div class="form-group">
            <label for="username" data-error="error">Username</label>
            <input type="text" id="username" placeholder="Enter Your Username" class="form-control" formControlName="username" autocapitalize="none" required="required">
            <div class='error-message' *ngIf="username.errors !== null && (username.touched)" [innerHtml]="formErrorsHelper.errorMessage(username, 'Username')">
            </div>
          </div>
          <div class="form-group">
            <label for="password">{{ 'membership.create.password_label' | translate }}</label>
            <input type="password" placeholder="Enter your password" class="form-control" formControlName="password" id="password" required="required" autocomplete="current-password">
            <div class='error-message' *ngIf="password.errors !== null && (password.touched)" [innerHtml]="formErrorsHelper.errorMessage(password, ('membership.create.password_label' | translate))">
            </div>
          </div>
          <div class="row d-none d-sm-block">
            <div class="col-lg-4 col-md-5">
              <button type="submit" class="btn btn-primary my-2 w-100">{{ 'action_terms.login' | translate }}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
<floating-container-bottom class="d-xs-block d-sm-none">
  <button type="submit" class="btn btn-primary my-2 w-100">{{ 'action_terms.login' | translate }}</button>
</floating-container-bottom>
