import { Component, Input } from '@angular/core';

@Component({
  selector: 'text-panel-component',
  templateUrl: './text-panel.html'
})

export class TextPanelComponent {
  @Input() title;
  @Input() subtitle = '';
  @Input() text;
  @Input() maxLength = 100;
  @Input() microdataItemprop = '';
  @Input() buttonFloatingLeft: boolean = true;
  /* Enables or disables default margin for elements inside the innerHTML of truncated-text */
  @Input() innerMargins: boolean = true;
}
