<ngb-alert class="success-message" *ngIf="success" type="success" (closed)="closeAlert()">{{ 'payment.add_message' | translate }}</ngb-alert>
<div class="d-sm-none back-button">
	<button type="button" class="btn btn-link" (click)="backToTabs()">
	<fa-icon [icon]="icons.faChevronLeft" aria-hidden="true"></fa-icon> {{'back' | translate}} </button>
</div>
<ngb-alert class="success-message" *ngIf="primarySuccess" type="success" (closed)="closeAlert()">{{ 'payment.set_default_confirmation' | translate }}</ngb-alert>
<div class="profile-title primary-content">{{'payment.payment_methods' | translate}}</div>
<div class="mb-4">
	<div class="payment-methods">
		<div *ngIf="paymentMethods && paymentMethods.length > 0">
			<payment-methods-card *ngFor="let paymentMethod of paymentMethods"
				class="mt-3 col-12 col-sm-6 col-lg-4"
				[requestMFA]="requestMFA"
				[userData]="userData"
				[item]="paymentMethod"
				[numberOfCards]="paymentMethods.length"
			 	(deleteClicked)="onPaymentMethodDeleted($event)"
				(primaryClicked)="onPaymentMethodPrimary($event)"
				(editClicked)="onEditOpen($event)">
			</payment-methods-card>
		</div>
		<div class="row mb-4" *ngIf="paymentMethods && paymentMethods.length === 0">
			<div class="col-12">
				<ngb-alert [dismissible]="false">
					<strong>{{ 'mobile.profile.credit_cards.no_cards' | translate }}</strong>
				</ngb-alert>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-md-4 offset-md-8">
				<button *ngIf="paymentMethods && paymentMethods.length < 3" type="button" class="btn btn-primary clickable w-100 gtm-manage-profile-cards" (click)="onAddOpen()" data-cy="add-cc">{{'cars.payment.add_cc' | translate}}</button>
			</div>
		</div>
	</div>
</div>
