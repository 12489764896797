import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { User } from 'app/auth/models/user';
import * as fromAuth from 'app/auth/store/reducers';
import * as fromRoot from 'reducers';
import { map, tap, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class GenericUserGuard  {
  user: User;

  constructor(
    public router: Router,
    private store: Store<fromRoot.State>
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.select<any>(fromAuth.getUser).pipe(
      take(1),
      map((user) => {
        this.user = user;

        if (user.member.member_id && user.generic_user) {
          return false;
        }

        return true;
      }),
      tap(() => {
        // Generic users only can use flights.
        if (this.user.member.member_id && !this.user.first_time_login && this.user.generic_user) {
          this.router.navigate(['/flights']);
        }
      })
    );
  }
}
