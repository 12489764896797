import { Action } from '@ngrx/store';

export enum MfaActionTypes {
  ResetSendCode= '[Multifactor Authentication] Reset Send Code View',
  SendCodeByEmail = '[Multifactor Authentication] Send Code By Email',
  SendCodeBySMS = '[Multifactor Authentication] Send Code By SMS',
  SendCodeSuccess = '[Multifactor Authentication] Send Code Success',
  SendCodeFailure = '[Multifactor Authentication] Send Code Failure',
  VerifyCode = '[Multifactor Authentication] Verify Code',
  VerifyCodeSuccess = '[Multifactor Authentication] Verify Code Success',
  VerifyCodeFailure = '[Multifactor Authentication] Verify Code Failure',
  Enroll = '[Multifactor Authentication] Enroll',
  EnrollSuccess = '[Multifactor Authentication] Enroll Success',
  EnrollFailure = '[Multifactor Authentication] Enroll Failure',
  DismissModal = '[Multifactor Authentication] DismissModal',
  DismissModalSuccess = '[Multifactor Authentication] DismissModal Success',
  DismissModalFailure = '[Multifactor Authentication] DismissModal Failure',
  InsertNewIP = '[Multifactor Authentication] Insert New IP',
  InsertNewIPSuccess = '[Multifactor Authentication] Insert New IP Success',
  InsertNewIPFailure = '[Multifactor Authentication] Insert New IP Failure',
  Reset = '[Multifactor Authentication] Reset',
  ResetAttempts = '[Multifactor Authentication] Reset Attempts'
}

export class SendCodeByEmail implements Action {
  readonly type = MfaActionTypes.SendCodeByEmail;
  constructor(public payload: { params: any }) {}
}

export class SendCodeBySMS implements Action {
  readonly type = MfaActionTypes.SendCodeBySMS;
  constructor(public payload: { params: any }) {}
}

export class SendCodeSuccess implements Action {
  readonly type = MfaActionTypes.SendCodeSuccess;
  constructor(public payload: { response: any }) {}
}

export class SendCodeFailure implements Action {
  readonly type = MfaActionTypes.SendCodeFailure;
  constructor(public payload: { error: any }) {}
}

export class VerifyCode implements Action {
  readonly type = MfaActionTypes.VerifyCode;
  constructor(public payload: { params: any }) {}
}

export class VerifyCodeSuccess implements Action {
  readonly type = MfaActionTypes.VerifyCodeSuccess;
}

export class VerifyCodeFailure implements Action {
  readonly type = MfaActionTypes.VerifyCodeFailure;
  constructor(public payload: { error: any }) {}
}

export class Enroll implements Action {
  readonly type = MfaActionTypes.Enroll;
  constructor(public payload: { params: any }) {}
}

export class EnrollSuccess implements Action {
  readonly type = MfaActionTypes.EnrollSuccess;
}

export class EnrollFailure implements Action {
  readonly type = MfaActionTypes.EnrollFailure;
  constructor(public payload: { error: any }) {}
}

export class DismissModal implements Action {
  readonly type = MfaActionTypes.DismissModal;
}

export class DismissModalSuccess implements Action {
  readonly type = MfaActionTypes.DismissModalSuccess;
}

export class DismissModalFailure implements Action {
  readonly type = MfaActionTypes.DismissModalFailure;
  constructor(public payload: { error: any }) {}
}

export class InsertNewIP implements Action {
  readonly type = MfaActionTypes.InsertNewIP;
}

export class InsertNewIPSuccess implements Action {
  readonly type = MfaActionTypes.InsertNewIPSuccess;
}

export class InsertNewIPFailure implements Action {
  readonly type = MfaActionTypes.InsertNewIPFailure;
  constructor(public payload: { error: any }) {}
}

export class Reset implements Action {
  readonly type = MfaActionTypes.Reset;
}

export class ResetAttempts implements Action {
  readonly type = MfaActionTypes.ResetAttempts;
}

export class ResetSendCode implements Action {
  readonly type = MfaActionTypes.ResetSendCode;
}

export type MultifactorAuthentificationActionsUnion =
  | SendCodeByEmail
  | SendCodeBySMS
  | SendCodeSuccess
  | SendCodeFailure
  | VerifyCode
  | VerifyCodeSuccess
  | VerifyCodeFailure
  | Enroll
  | EnrollSuccess
  | EnrollFailure
  | DismissModal
  | DismissModalSuccess
  | DismissModalFailure
  | InsertNewIP
  | InsertNewIPSuccess
  | InsertNewIPFailure
  | Reset
  | ResetAttempts
  | ResetSendCode;
