import { MailchimpParametersActions } from 'app/shared/store/actions';

export interface State {
  campaignId: string;
  expiration: number;
}

export const initialState: State = {
  campaignId: null,
  expiration: null
};

export function reducer(
  state = initialState,
  action: MailchimpParametersActions.MailchimpParametersActionsUnion
) {
  switch (action.type) {
    case MailchimpParametersActions.MailchimpParametersActionTypes.Load:
      return {
        campaignId: action.payload.campaignId,
        expiration: Date.now() + 3600000
      };
    case MailchimpParametersActions.MailchimpParametersActionTypes.Clear:
      return {
        campaignId: null,
        expiration: null
      };
    default:
      return state;
  }
}

export const getMailchimpCampaign = (state: State) => state.expiration && state.expiration > Date.now() ? state.campaignId : null;
