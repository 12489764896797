import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { EligibleTravelersService } from 'app/shared/services/eligible-travelers/eligible-travelers.service';
import { take } from 'rxjs/operators';
import { EligibleTraveler } from 'app/profile/models/eligible-traveler';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'invite-eligible-traveler-modal',
  templateUrl: './invite-eligible-traveler-modal.html'
})

export class InviteEligibleTravelerModal implements OnInit {
  @Input() travelerId = null;
  eligibleTraveler: EligibleTraveler;
  inviteEligibleTravelerForm: UntypedFormGroup;
  loading = false;
  error = null;
  success = null;
  profileSub: any = {};

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    public formErrorsHelper: FormErrorsHelper,
    private eligibleTravelersService: EligibleTravelersService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.getTravelerInformation();
    this.inviteEligibleTravelerForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])]
    });
  }

  getTravelerInformation() {
    this.loading = true;
    this.profileSub = this.eligibleTravelersService.getTraveler(this.travelerId).pipe(take(1)).subscribe(
      (response) => {
        this.eligibleTraveler = response.traveler;
        this.loading = false;
      },
      () => this.loading = false
    );
  }

  validForm() {
    if (!this.inviteEligibleTravelerForm.valid) {
      this.error = this.translateService.instant('invite.status.invalid');
      return false;
    }

    if (!this.validAge()) {
      this.error = this.translateService.instant('profile.eligible_travelers.age_validation');
      return false;
    }

    return true;
  }

  sendInvitation() {
    if (this.validForm()) {
      const params = {
        eligibleId: this.eligibleTraveler.id,
        employeeId: this.eligibleTraveler.employeeId,
        email: this.inviteEligibleTravelerForm.value.email
      };
      this.loading = true;
      this.eligibleTravelersService.sendInviteCode(params).pipe(take(1)).subscribe(
        (response) => {
          if (response['inviteUrl']) {
            this.loading = false;
            this.activeModal.close(true);
          }
        },
        (e) => {
          this.error = e?.error?.translation_key ? this.error = this.translateService.instant(`profile.eligible_travelers.${e.error.translation_key}`) : 'Error';
          this.loading = false;
        }
      );
    }
  }

  calculateAge(birthDate) {
    const bday = new Date(birthDate);
    const timeDiff = Math.abs(Date.now() - bday.getTime());
    return Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
  }

  validAge() {
    return this.eligibleTraveler.birthDate && this.calculateAge(this.eligibleTraveler.birthDate) >= 18;
  }

  dismiss() {
    this.activeModal.dismiss(null);
  }

  closeAlert() {
    this.error = null;
    this.success = null;
  }

  get email() { return this.inviteEligibleTravelerForm.get('email'); }
}
