<!-- DESKTOP VERSION -->
<li *ngIf="!forMobile && selectedLanguage"  display="dynamic" class="nav-item language-selector">
  <a class="nav-link black-text gtm-nav" href="javascript:void(0)" [ngbPopover]="popContent"
     placement="bottom" popoverClass="language-selector">
    <svg-icon [icon]="'icon-flag-' + selectedLanguage.flag"></svg-icon>
    <span class="lang-name">{{ selectedLanguage.code | uppercase }}</span>
  </a>
  <ng-template #popContent>
    <ul class="ps-0">
      <ng-container *ngFor="let lang of languages">
        <li *ngIf="lang.code !== selectedLanguage.code" (click)="selectLang(lang)" class="gtm-language lang-{{lang.name}}">
          <svg-icon [icon]="'icon-flag-' + lang.flag"></svg-icon>{{ lang.name }}
        </li>
      </ng-container>
    </ul>
  </ng-template>
</li>
<!-- MOBILE VERSION -->
<div *ngIf="forMobile" class="language-selector-mobile">
  <ul class="navbar-nav collapsible collapsible-accordion">
    <li class="nav-item">
      <a class="nav-link black-text" href="javascript:void(0)" (click)="isCollapsed = !isCollapsed">
        {{ 'mobile.profile.language' | translate }} <fa-icon [icon]="icons.faGlobe" class="float-end"></fa-icon>
      </a>
    </li>
    <div [ngbCollapse]="isCollapsed" class="container-mobile">
      <ng-container *ngFor="let lang of languages">
        <li (click)="selectLang(lang)" class="nav-item" [ngClass]="lang?.code === selectedLanguage?.code ? 'lang-selected' : ''">
          <a class="nav-link black-text gtm-language" href="javascript:void(0)">
            {{ lang.name }}
            <svg-icon [icon]="'icon-flag-' + lang.flag" class="float-end"></svg-icon>
          </a>
        </li>
      </ng-container>
    </div>
  </ul>
</div>
