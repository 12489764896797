import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import * as fromLoginPage from './login-page.reducer';
import * as fromRegisterPage from './register-page.reducer';
import * as fromLogoutComponent from './logout-component.reducer';
import * as fromAuth from './auth.reducer';
import * as fromThirdParty from './third-party.reducer';
import * as fromEventRegistration from './event-registration.reducer';
import * as fromForgotPassword from './forgot-password.reducer';
import * as fromAccountValidation from './account-validation.reducer';
import * as fromMultifactorAuthentication from './multifactor-authentification.reducer';
import { User } from 'app/auth/models/user';
import { availableToolkeys } from 'app/flights/models/available-tools';

export interface AuthState {
  accountValidation: fromAccountValidation.State;
  eventRegistration: fromEventRegistration.State;
  forgotPassword: fromForgotPassword.State;
  loginPage: fromLoginPage.State;
  logoutComponent: fromLogoutComponent.State;
  multifactorAuthentication: fromMultifactorAuthentication.State;
  registerPage: fromRegisterPage.State;
  status: fromAuth.State;
  thirdParty: fromThirdParty.State;
}

export interface State extends fromRoot.State {
  auth: AuthState;
}

export const reducers: ActionReducerMap<AuthState> = {
  accountValidation: fromAccountValidation.reducer,
  eventRegistration: fromEventRegistration.reducer,
  forgotPassword: fromForgotPassword.reducer,
  loginPage: fromLoginPage.reducer,
  logoutComponent: fromLogoutComponent.reducer,
  multifactorAuthentication: fromMultifactorAuthentication.reducer,
  registerPage: fromRegisterPage.reducer,
  status: fromAuth.reducer as any,
  thirdParty: fromThirdParty.reducer,
};

export const selectAuthState = createFeatureSelector<State, AuthState>('auth');
export const selectAuthStatusState = createSelector(selectAuthState, (state: AuthState) => state.status);
export const selectEventRegistrationState = createSelector(selectAuthState, (state: AuthState) => state.eventRegistration);
export const getUser = createSelector(selectAuthStatusState, fromAuth.getUser);
export const getUserAccount = createSelector(getUser, state => state ? state.account : null);
export const getLoggedIn = createSelector(selectAuthStatusState, fromAuth.isUserLoggedIn);
export const getSessionTimeOut = createSelector(selectAuthStatusState, fromAuth.isSessionTimeOut);
export const getMarketingLoggedIn = createSelector(selectEventRegistrationState, fromEventRegistration.isLoggedIn);
export const getAccessToken = createSelector(getUser, state => state ? state.access_token : null);
export const getRememberMeToken = createSelector(getUser, state => state ? state.remember_me_token : null);
export const getFirstTimeLogin = createSelector(getUser, (state: User) => state.first_time_login);
export const getAvailableTools = createSelector(getUser, (state: User) => state.tools);
export const getPartner = createSelector(getUser, (state: User) => state.is_partner);
export const getUserStatistics = createSelector(getUser, (state: User) => state.statistics);
export const selectLoginPageState = createSelector(selectAuthState, (state: AuthState) => state.loginPage);
export const selectThirdPartyState = createSelector(selectAuthState, (state: AuthState) => state.thirdParty);
export const getLoginPageError = createSelector(selectLoginPageState, fromLoginPage.getError);
export const getLoginPagePending = createSelector(selectLoginPageState, fromLoginPage.getPending);
export const getThirdPartyPending = createSelector(selectThirdPartyState, fromThirdParty.getPending);
export const getThirdPartyError = createSelector(selectThirdPartyState, fromThirdParty.getError);
export const getThirdPartySuccess = createSelector(selectThirdPartyState, fromThirdParty.getSuccess);
export const getLoginPageMessage = createSelector(selectLoginPageState, fromLoginPage.getMessage);
export const selectRegisterPageState = createSelector(selectAuthState, (state: AuthState) => state.registerPage);
export const getRegisterPageError = createSelector(selectRegisterPageState, fromRegisterPage.getError);
export const getRegisterPagePending = createSelector(selectRegisterPageState, fromRegisterPage.getPending);
export const getRegisterPageInviteParams = createSelector(selectRegisterPageState, fromRegisterPage.getInviteParams);
export const getEventAirline = createSelector(selectEventRegistrationState, fromEventRegistration.getAirline);
export const getEventCampaign = createSelector(selectEventRegistrationState, fromEventRegistration.getCampaign);
export const getEventRegistrationPending = createSelector(selectEventRegistrationState, fromEventRegistration.getPending);
export const getEventRegistrationSuccess = createSelector(selectEventRegistrationState, fromEventRegistration.getRegisterSuccess);
export const getEventRegistrationError = createSelector(selectEventRegistrationState, fromEventRegistration.getRegisterError);
export const getRegistrations = createSelector(selectEventRegistrationState, fromEventRegistration.getRegistrations);
export const getMarketingLoginError = createSelector(selectEventRegistrationState, fromEventRegistration.getLoginError);
export const getCustomerSupport = createSelector(selectAuthStatusState, fromAuth.getCustomerSupport);
export const getUserLanguage = createSelector(selectAuthStatusState, fromAuth.getUserLanguage);
export const getInvitedTravelerInfo = createSelector(selectAuthStatusState, fromAuth.getInvitedTravelerInfo);
export const getFraudStatus = createSelector(selectAuthStatusState, fromAuth.isRestricted);
export const selectForgotPasswordState = createSelector(selectAuthState, (state: AuthState) => state.forgotPassword);
export const getForgotPasswordCodeSuccess = createSelector(selectForgotPasswordState, fromForgotPassword.getCodeSuccess);
export const getForgotPasswordCodeError = createSelector(selectForgotPasswordState, fromForgotPassword.getCodeError);
export const getForgotPasswordLinkSuccess = createSelector(selectForgotPasswordState, fromForgotPassword.getLinkSuccess);
export const getForgotPasswordLinkError = createSelector(selectForgotPasswordState, fromForgotPassword.getLinkError);
export const getForgotPasswordPending = createSelector(selectForgotPasswordState, fromForgotPassword.getPending);
export const getForgotPasswordCodeValidation = createSelector(selectForgotPasswordState, fromForgotPassword.getCodeValidation);
export const getForgotPasswordCodeValidationError = createSelector(selectForgotPasswordState, fromForgotPassword.getCodeValidationError);
export const selectAccountValidationState = createSelector(selectAuthState, (state: AuthState) => state.accountValidation);
export const getAccountValidationPending = createSelector(selectAccountValidationState, fromAccountValidation.getPending);
export const getAccountValidationSuccess = createSelector(selectAccountValidationState, fromAccountValidation.getSuccess);
export const getAccountValidationError = createSelector(selectAccountValidationState, fromAccountValidation.getError);
export const getUsernameValidationPending = createSelector(selectAccountValidationState, fromAccountValidation.getUsernamePending);
export const getUsernameValidationSuccess = createSelector(selectAccountValidationState, fromAccountValidation.getUsernameSuccess);
export const getUsernameValidationError = createSelector(selectAccountValidationState, fromAccountValidation.getUsernameError);
export const getAirlineCode = createSelector(selectAuthStatusState, fromAuth.getAirlineCode);
export const selectLogoutPageState = createSelector(selectAuthState, (state: AuthState) => state.logoutComponent);
export const getLogoutPending = createSelector(selectLogoutPageState, fromLogoutComponent.getPending);
export const getVerifyOptionsResultSuccess = createSelector(selectForgotPasswordState, fromForgotPassword.getVerifyOptionsResult);
export const getVerifyOptionsError = createSelector(selectForgotPasswordState, fromForgotPassword.getVerifyOptionsError);
export const getResetPasswordAttempts = createSelector(selectForgotPasswordState, fromForgotPassword.getAttempts);

export const selectMfaState = createSelector(selectAuthState, state => state.multifactorAuthentication);
export const sendCodePending = createSelector(selectMfaState, fromMultifactorAuthentication.sendCodePending);
export const sendCodeSuccess = createSelector(selectMfaState, fromMultifactorAuthentication.sendCodeSuccess);
export const sendCodeFailure = createSelector(selectMfaState, fromMultifactorAuthentication.sendCodeFailure);
export const verifyCodePending = createSelector(selectMfaState, fromMultifactorAuthentication.verifyCodePending);
export const verifyCodeSuccess = createSelector(selectMfaState, fromMultifactorAuthentication.verifyCodeSuccess);
export const verifyCodeFailure = createSelector(selectMfaState, fromMultifactorAuthentication.verifyCodeFailure);
export const enrollPending = createSelector(selectMfaState, fromMultifactorAuthentication.enrollPending);
export const enrollSuccess = createSelector(selectMfaState, fromMultifactorAuthentication.enrollSuccess);
export const enrollFailure = createSelector(selectMfaState, fromMultifactorAuthentication.enrollFailure);
export const dismissModalPending = createSelector(selectMfaState, fromMultifactorAuthentication.dismissModalPending);
export const dismissModalSuccess = createSelector(selectMfaState, fromMultifactorAuthentication.dismissModalSuccess);
export const dismissModalFailure = createSelector(selectMfaState, fromMultifactorAuthentication.dismissModalFailure);
export const insertNewIPPending = createSelector(selectMfaState, fromMultifactorAuthentication.insertNewIPPending);
export const insertNewIPSuccess = createSelector(selectMfaState, fromMultifactorAuthentication.insertNewIPSuccess);
export const insertNewIPFailure = createSelector(selectMfaState, fromMultifactorAuthentication.insertNewIPFailure);
export const mfaRequest = createSelector(selectMfaState, fromMultifactorAuthentication.mfaRequest);
export const attempts = createSelector(selectMfaState, fromMultifactorAuthentication.attempts);
export const lastAttemptDate = createSelector(selectMfaState, fromMultifactorAuthentication.lastAttemptDate);

export const getToolkey = createSelector(
  getAvailableTools,
  (tools: any) => {
    if (tools.includes(availableToolkeys.ticketing)) {
      return availableToolkeys.ticketing;
    }

    if (tools.includes(availableToolkeys.listing)) {
      return availableToolkeys.listing;
    }

    if (tools.includes(availableToolkeys.cobus)) {
      return availableToolkeys.cobus;
    }

    return availableToolkeys.id90;
  }
);
