import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { datesConfig } from 'configs/dates';

@Pipe({
  name: 'dateTranslate',
  pure: false
})
export class DateTranslatePipe implements PipeTransform {

  constructor(
    private readonly translateService: TranslateService
  ) { }

  transform(value: any, format?: any): any {
    const defaultFormat = 'dddd, MMMM DD, YYYY';
    const formatToUse = format && this.isFormatValid(format) ? format : defaultFormat;
    const lang = this.translateService.currentLang ?? this.translateService.defaultLang;
    let translated = value;
    if (typeof value === 'string') {
      const date = moment(value, datesConfig.momentFormats);
      translated = date.locale(lang).format(formatToUse);
    }
    return translated === 'Invalid date' ? value : translated;
  }

  isFormatValid(format: string) {
    return moment('2021-01-01', format).isValid();
  }
}
