import { Action } from "@ngrx/store";

export enum AllInterestsActionTypes {
  LoadAllInterests = "[Profile] Load All Interests",
  LoadAllInterestsSuccess = "[Profile] Load All Interests Success",
  LoadAllInterestsFailure = "[Profile] Load All Interests Failure",
}

export class LoadAllInterests implements Action {
  readonly type = AllInterestsActionTypes.LoadAllInterests;
}

export class LoadAllInterestsSuccess implements Action {
  readonly type = AllInterestsActionTypes.LoadAllInterestsSuccess;

  constructor(public payload: { response: any }) {}
}

export class LoadAllInterestsFailure implements Action {
  readonly type = AllInterestsActionTypes.LoadAllInterestsFailure;

  constructor(public payload: { error: any }) {}
}

export type AllInterestsActionsUnion =
  | LoadAllInterests
  | LoadAllInterestsSuccess
  | LoadAllInterestsFailure;
