import { Action } from '@ngrx/store';

export enum TripsApiActionTypes {
  LoadFutureHotelTripsSuccess = '[Trips/API] Load Future Hotel Trips Success',
  LoadFutureHotelTripsFailure = '[Trips/API] Load Future Hotel Trips Failure',
  LoadPastHotelTripsSuccess = '[Trips/API] Load Past Hotel Trips Success',
  LoadPastHotelTripsFailure = '[Trips/API] Load Past Hotel Trips Failure',
  LoadFutureCarTripsSuccess = '[Trips/API] Load Future Car Trips Success',
  LoadFutureCarTripsFailure = '[Trips/API] Load Future Car Trips Failure',
  LoadPastCarTripsSuccess = '[Trips/API] Load Past Car Trips Success',
  LoadPastCarTripsFailure = '[Trips/API] Load Past Car Trips Failure',
  LoadFutureFlightTripsSuccess = '[Trips/API] Load Future Flight Trips Success',
  LoadFutureFlightTripsByPNRSuccess = '[Trips/API] Load Future Flight Trips By PNR Success',
  LoadFutureFlightTripsFailure = '[Trips/API] Load Future Flight Trips Failure',
  LoadPastFlightTripsSuccess = '[Trips/API] Load Past Flight Trips Success',
  LoadPastFlightTripsByPNRSuccess = '[Trips/API] Load Past Flight Trips By PNR Success',
  LoadPastFlightTripsFailure = '[Trips/API] Load Past Flight Trips Failure',
  LoadHotelsInsuranceTripsSuccess = '[Trips/API] Load Hotels Insurance Trips Success',
  LoadHotelsInsuranceTripsFailure = '[Trips/API] Load Hotels Insurance Trips Failure',
  LoadFlightsInsuranceTripsSuccess = '[Trips/API] Load Flights Insurance Trips Success',
  LoadFlightsInsuranceTripsFailure = '[Trips/API] Load Flights Insurance Trips Failure',
  LoadSharedItinerariesSuccess = '[Trips/API] Load Shared Itineraries Success',
  LoadSharedItinerariesFailure = '[Trips/API] Load Shared Itineraries Failure'
}

export class LoadFutureHotelTripsSuccess implements Action {
  readonly type = TripsApiActionTypes.LoadFutureHotelTripsSuccess;

  constructor(public payload: any) { }
}

export class LoadFutureHotelTripsFailure implements Action {
  readonly type = TripsApiActionTypes.LoadFutureHotelTripsFailure;

  constructor(public payload: { error: any }) { }
}

export class LoadPastHotelTripsSuccess implements Action {
  readonly type = TripsApiActionTypes.LoadPastHotelTripsSuccess;

  constructor(public payload: any) { }
}

export class LoadPastHotelTripsFailure implements Action {
  readonly type = TripsApiActionTypes.LoadPastHotelTripsFailure;

  constructor(public payload: { error: any }) { }
}

export class LoadFutureCarTripsSuccess implements Action {
  readonly type = TripsApiActionTypes.LoadFutureCarTripsSuccess;

  constructor(public payload: any) { }
}

export class LoadFutureCarTripsFailure implements Action {
  readonly type = TripsApiActionTypes.LoadFutureCarTripsFailure;

  constructor(public payload: { error: any }) { }
}

export class LoadPastCarTripsSuccess implements Action {
  readonly type = TripsApiActionTypes.LoadPastCarTripsSuccess;

  constructor(public payload: any) { }
}

export class LoadPastCarTripsFailure implements Action {
  readonly type = TripsApiActionTypes.LoadPastCarTripsFailure;

  constructor(public payload: { error: any }) { }
}



export class LoadFutureFlightTripsSuccess implements Action {
  readonly type = TripsApiActionTypes.LoadFutureFlightTripsSuccess;

  constructor(public payload: any) { }
}

export class LoadFutureFlightTripsFailure implements Action {
  readonly type = TripsApiActionTypes.LoadFutureFlightTripsFailure;

  constructor(public payload: { error: any }) { }
}

export class LoadPastFlightTripsSuccess implements Action {
  readonly type = TripsApiActionTypes.LoadPastFlightTripsSuccess;

  constructor(public payload: any) { }
}

export class LoadPastFlightTripsFailure implements Action {
  readonly type = TripsApiActionTypes.LoadPastFlightTripsFailure;

  constructor(public payload: { error: any }) { }
}


export class LoadHotelsInsuranceTripsSuccess implements Action {
  readonly type = TripsApiActionTypes.LoadHotelsInsuranceTripsSuccess;

  constructor(public payload: any) { }
}

export class LoadHotelsInsuranceTripsFailure implements Action {
  readonly type = TripsApiActionTypes.LoadHotelsInsuranceTripsFailure;

  constructor(public payload: { error: any }) { }
}
export class LoadFlightsInsuranceTripsSuccess implements Action {
  readonly type = TripsApiActionTypes.LoadFlightsInsuranceTripsSuccess;

  constructor(public payload: any) { }
}

export class LoadFlightsInsuranceTripsFailure implements Action {
  readonly type = TripsApiActionTypes.LoadFlightsInsuranceTripsFailure;

  constructor(public payload: { error: any }) { }
}
export class LoadSharedItinerariesSuccess implements Action {
  readonly type = TripsApiActionTypes.LoadSharedItinerariesSuccess;

  constructor(public payload: any) { }
}

export class LoadSharedItinerariesFailure implements Action {
  readonly type = TripsApiActionTypes.LoadSharedItinerariesFailure;

  constructor(public payload: { error: any }) { }
}

export class LoadFutureFlightTripsByPNRSuccess implements Action {
  readonly type = TripsApiActionTypes.LoadFutureFlightTripsByPNRSuccess;

  constructor(public payload: any) { }
}

export class LoadPastFlightTripsByPNRSuccess implements Action {
  readonly type = TripsApiActionTypes.LoadPastFlightTripsByPNRSuccess;

  constructor(public payload: any) { }
}

export type TripsApiActionsUnion = LoadFutureHotelTripsSuccess
  | LoadFutureHotelTripsFailure
  | LoadPastHotelTripsSuccess
  | LoadPastHotelTripsFailure
  | LoadFutureCarTripsSuccess
  | LoadFutureCarTripsFailure
  | LoadPastCarTripsSuccess
  | LoadPastCarTripsFailure
  | LoadFutureFlightTripsSuccess
  | LoadFutureFlightTripsFailure
  | LoadPastFlightTripsSuccess
  | LoadPastFlightTripsFailure
  | LoadHotelsInsuranceTripsSuccess
  | LoadHotelsInsuranceTripsFailure
  | LoadFlightsInsuranceTripsSuccess
  | LoadFlightsInsuranceTripsFailure
  | LoadSharedItinerariesSuccess
  | LoadSharedItinerariesFailure
  | LoadFutureFlightTripsByPNRSuccess
  | LoadPastFlightTripsByPNRSuccess;
