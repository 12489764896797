<infinite-scroll [items]="[{ template: contents, templateContext: this }]" [loading]="scrollLoading && !isSearchFlightByPNR" (pageEndReach)="loadMoreItems()" [displayPagination]="!loading && scrollLoading && !isSearchFlightByPNR"></infinite-scroll>
<loading-indicator [fullScreen]="false" *ngIf="loading"></loading-indicator>
<ng-template #contents>
  <ng-container>
    <div *ngIf="itemType !== 'insurance'" class="profile-title primary-content">{{ 'mobile.trips.upcoming_' + itemType | translate }}</div>
    <div class="content-tab">
      <div class="row position-relative py-3">
        <div id="tools-tab" *ngIf="itemType === 'flights'" [ngClass]="canSearchByPNR ? 'col-lg-8 col-12 text-start' : 'col-12 text-center'">
          <button [disabled]='isSearchFlightByPNR' *ngIf="availableTools.includes('ticketing') || availableTools.includes('ticketing_invited')" type="button" [class]="toolSelected === 'ticketing' && !isSearchFlightByPNR ? 'btn btn-sm btn-dark' : 'btn btn-sm btn-light'" (click)="groupBy('ticketing')" data-cy="ticketingTripsTab">{{ 'mobile.flights.labels.ticketing__id90_ru' | translate }}</button>
          <button [disabled]='isSearchFlightByPNR' *ngIf="availableTools.includes('listing')" type="button" [class]="toolSelected === 'listing' && !isSearchFlightByPNR ? 'btn btn-sm btn-dark' : 'btn btn-sm btn-light'" (click)="groupBy('listing')" data-cy="listingTripsTab">{{ 'mobile.flights.labels.listing__id90_ru' | translate }}</button>
          <button [disabled]='isSearchFlightByPNR' *ngIf="availableTools.includes('cobus')" type="button" [class]="toolSelected === 'company_business' && !isSearchFlightByPNR ? 'btn btn-sm btn-dark' : 'btn btn-sm btn-light'" (click)="groupBy('company_business')" data-cy="cobusTripsTab">{{ 'mobile.flights.labels.cobus__id90_ru' | translate }}</button>
          <button [disabled]='isSearchFlightByPNR' *ngIf="availableTools.includes('cobus_admin')" type="button" [class]="toolSelected === 'cobus_admin' && !isSearchFlightByPNR ? 'btn btn-sm btn-dark' : 'btn btn-sm btn-light'" (click)="groupBy('cobus_admin')" data-cy="cobus-adminTripsTab">{{ 'mobile.flights.labels.cobus_admin__id90_ru' | translate }}</button>
          <button [disabled]='isSearchFlightByPNR' *ngIf="eligibleTravelersEnabled" type="button" [class]="toolSelected === 'invited_traveler' && !isSearchFlightByPNR ? 'btn btn-sm btn-dark' : 'btn btn-sm btn-light'" (click)="groupBy('invited_traveler')">{{ 'mobile.flights.eligible_travelers' | translate }}</button>
        </div>
        <div [ngClass]="canSearchByPNR ? 'col-lg-4 col-12' : 'col-12'" class="text-end">
          <div *ngIf="itemType === 'flights' && canSearchByPNR" class="input-group p-0 d-sm-flex">
            <input type="text" class="form-control" maxlength="6"
              placeholder="{{'mobile.trips.search_by_pnr' | translate}}"
              (blur)="parsePNR()"
              (keyup.enter)="searchByPNR()"
              (keyup)="parsePNR()"
              [(ngModel)]="pnr"
            >
            <button class="btn btn-primary" type="button" (click)="searchByPNR()">
              <fa-icon [icon]="icons.faSearch"></fa-icon>
            </button>
          </div>
          <div *ngIf="displayPassesLink()">
            <button class="btn btn-link float-end gtm-passes-link" (click)="showAvailablePasses()">{{ 'profile.reservations.flights.available_passes' | translate }}</button>
          </div>
        </div>
        <div id="hotels-tab" *ngIf="itemType === 'hotels'" class="col-12 text-center hotels-tab">
          <button type="button" [class]="hotelType === 'reservation' ? 'btn btn-sm btn-dark' : 'btn btn-sm btn-light'" (click)="displayHotels('reservation')">
            {{ 'watchlist.hotels.title' | translate }}
          </button>
          <button type="button" [class]="hotelType === 'shared' ? 'btn btn-sm btn-dark' : 'btn btn-sm btn-light'" (click)="displayHotels('shared')">
            {{ 'mobile.trips.shared_reservations' | translate }}
          </button>
        </div>
        <div id="insurances-tab" *ngIf="itemType === 'insurance'" class="col-12 text-center insurances-tab">
          <button type="button" [class]="insuranceType === 'hotels' ? 'btn btn-sm btn-dark' : 'btn btn-sm btn-light'" (click)="displayInsurance('hotels')" data-cy="hotelsinsuranceTripsTab">
            {{ 'profile.reservations.hotels.title' | translate }}
          </button>
          <button *ngIf="showFlights" type="button" [class]="insuranceType === 'flights' ? 'btn btn-sm btn-dark' : 'btn btn-sm btn-light'" (click)="displayInsurance('flights')">
            {{ 'profile.reservations.flights.title' | translate }}
          </button>
        </div>
      </div>
      <ng-container [ngTemplateOutlet]="noResultsForSearchPNR" [ngTemplateOutletContext]="{ items: futureItems, reservations: futureReservations, type: 'upcoming' }" data-cy="trips-container"></ng-container>
      <ng-container *ngIf="!showSharedItineraries" [ngTemplateOutlet]="listingTemplate" [ngTemplateOutletContext]="{ items: futureItems, reservations: futureReservations, type: 'upcoming' }" data-cy="trips-container"></ng-container>
      <ng-container *ngIf="showSharedItineraries" [ngTemplateOutlet]="listingTemplate" [ngTemplateOutletContext]="{ items: sharedItems, reservations: sharedItems.reservations, type: 'upcoming' }" data-cy="trips-container"></ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="showPast && !noFlightsWhenSearchFlightsByPNR">
   <div *ngIf="!loading">
    <div class="profile-title primary-content">{{ 'mobile.trips.past_' + itemType | translate }}</div>

    <div class="content-tab">
      <ng-container *ngIf="!showSharedItineraries" [ngTemplateOutlet]="listingTemplate" [ngTemplateOutletContext]="{ items: pastItems, reservations: pastReservations, type: 'history' }"></ng-container>

      <ng-container *ngIf="showSharedItineraries" [ngTemplateOutlet]="listingTemplate" [ngTemplateOutletContext]="{ items: pastSharedItems, reservations: pastSharedItems.reservations, type: 'history' }"></ng-container>
    </div>
   </div>
  </ng-container>
</ng-template>

<ng-template #listingTemplate let-items="items" let-reservations="reservations" let-type="type" let-noResultsTemplate="noResultsTemplate">
  <ng-container *ngIf="items.loaded && reservations && reservations.length > 0">
    <div class="row {{type}}" *ngIf="!loading">
      <div class="col-sm-12 col-md-4" *ngFor="let item of reservations">
        <ng-container [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{ item: item, type: type }"></ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!items.pending && items.pageLoaded > 0 && (!reservations || (reservations && reservations.length === 0))" [ngTemplateOutlet]='noResults'></ng-container>
</ng-template>

<ng-template #hotelTemplate let-item="item">
  <div *ngIf="!loading">
    <hotels-trips-card [hotel]="item" (viewTripClicked)="onViewTripClicked.emit(item)"></hotels-trips-card>
  </div>
</ng-template>

<ng-template #flightTemplate let-item="item" let-type="type" data-cy="trips-container">
  <div *ngIf="!loading">
    <flights-trips-card [flight]="item" (viewTripClicked)="onViewTripClicked.emit({flight: item, type: type})"></flights-trips-card>

  </div>
</ng-template>

<ng-template #carTemplate let-item="item">
  <div *ngIf="!loading">
    <cars-trips-card [car]="item" (viewTripClicked)="onViewTripClicked.emit(item)"></cars-trips-card>
  </div>
</ng-template>

<ng-template #insuranceTemplate let-item="item">
  <div *ngIf="!loading">
    <insurance-trips-card [insurance]="item" (viewTripClicked)="onViewTripClicked.emit(item)"></insurance-trips-card>
  </div>
</ng-template>

<ng-template #noResults data-cy="noResults">
  <div *ngIf= "!loading && !noFlightsWhenSearchFlightsByPNR" class="col-md-4 col-sm-6 offset-md-4 offset-sm-3 text-center pt-5">
    <img src="angular_assets/images/trips/no-trips.png" class="img-fluid py-3 no-trips-img" />
    <div class="primary-content">
      {{ "mobile.trips.no_upcomming" | translate }}
    </div>
    <div class="py-2">{{ "mobile.trips.sub_title" | translate }}</div>
    <button type="button" class="btn btn-primary" (click)="redirectTo(tabSelected)" *ngIf="!eligibleTravelersEnabled">
      {{ "mobile.trips.new_search" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #noResultsForSearchPNR let-items="items" let-reservations="reservations" let-type="type">
  <div *ngIf="isSearchFlightByPNR && noFlightsWhenSearchFlightsByPNR">
    <div class="col-md-4 col-sm-6 offset-md-4 offset-sm-3 text-center pt-5 m-h-300">
      <h1 class="primary-content">
        {{ "mobile.trips.search_flights_by_pnr_not_found" | translate }}
      </h1>
    </div>
  </div>
</ng-template>
