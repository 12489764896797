import { Component, OnDestroy, OnInit } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { Modal } from 'app/shared/models/modal.model';
import { ModalActions } from 'app/shared/store/actions';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as fromAuth from '../../store';

@Component({
  selector: 'user-selector',
  templateUrl: './user-selector.html'
})

export class UserSelectorComponent implements OnInit, OnDestroy {
  employee = '';
  loading = false;
  error = '';
  user: any;
  user$ = this.store.pipe(select(fromAuth.getUser));
  customerSupport$ = this.store.pipe(select(fromAuth.getCustomerSupport));
  icons = { faSpinner };

  private ngUnsubscribe = new Subject<void>();

  constructor(
    public activeModal: NgbActiveModal,
    private store: Store<fromAuth.State>
  ) { }

  ngOnInit() {
    this.user$.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((user) => {
      this.user = user;
    });

    this.customerSupport$.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((customerSupport) => {
      if (!customerSupport) {
        this.activeModal.dismiss();
      }
      this.error = customerSupport.error;
      this.loading = customerSupport.pending;
      if (!customerSupport.userSelectedByOperator || customerSupport.pending) {
        return;
      }
      this.activeModal.dismiss();
      const member = this.user.member;
      const data: Modal = {
        title: 'flash_messages.logged_in_as',
        body: `${member.employee_number}(${member.airline_code}) - ${member.last_name}, ${member.first_name}`,
        modalOptions: { size: 'md' }
      };
      this.store.dispatch(new ModalActions.Show({ data }));
    });
  }

  clearErrors() {
    this.error = '';
  }
  select() {
    if (!this.employee) {
      return false;
    }
    this.store.dispatch(new fromAuth.AuthApiActions.SelectUser({ employeeNumber: this.employee }));
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
