import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { select, Store } from '@ngrx/store';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';
import { Modal } from 'app/shared/models/modal.model';
import { ZendeskForm } from 'app/shared/models/zendesk-form';
import { ZendeskService } from 'app/shared/services/zendesk/zendesk.service';
import { ModalActions } from 'app/shared/store/actions';
import * as fromShared from 'app/shared/store/reducers';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'zendesk-form',
  templateUrl: './zendesk-form.page.html'
})

export class ZendeskFormPage implements OnInit, OnDestroy {

  recaptchaKey = environment.recaptchaPublicKey;
  airlines$: any = this.store.pipe(select(fromShared.getAirlines));
  contactForm: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    airline: new UntypedFormControl('', Validators.required),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    identification: new UntypedFormControl(null, Validators.required),
    badge: new UntypedFormControl(null, Validators.required),
    recaptcha: new UntypedFormControl('', Validators.required)
  });
  success = false;
  error = false;
  loading = true;
  airlines = [];
  formTitle = '';
  formContent = '';
  urlOrigin = '';
  formDetails = {
    [ZendeskForm.MEMBERSHIP]: {
      title: 'membership.request.title',
      content: 'membership.request.content',
      urlOrigin: 'membership'
    },
    [ZendeskForm.PASSWORD]: {
      title: 'mobile.general.password.form.title',
      content: 'mobile.general.password.form.content',
      urlOrigin: 'password'
    }
  };
  private ngUnsubscribe = new Subject<void>();
  private MAX_FILE_SIZE = 2;
  constructor(
    private zendeskService: ZendeskService,
    private store: Store,
    public formErrorsHelper: FormErrorsHelper,
    private router: Router
  )  {}

  ngOnInit() {
    this.airlines$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((airlines) => {
      if (airlines.length) {
        this.loading = false;
        this.airlines = airlines;
      }
    });
    this.setFormData();
  }

  onFileChange(controlName: string, event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const file: File = inputElement.files && inputElement.files.length > 0 ? inputElement.files[0] : null;
    if (file) {
      const size = file.size / 1024 / 1024;
      if (size > this.MAX_FILE_SIZE) {
        const data: Modal = {
          title: 'flights.search.modals.passengers.title',
          bodyTranslationKey: 'membership.request.max_size_error',
          bodyTranslationVars: { max_allowed: this.MAX_FILE_SIZE },
          modalOptions: { size: 'md' }
        };
        this.store.dispatch(new ModalActions.Show({ data }));
        this.contactForm.get(controlName).setValue(null);
        return false;
      } else {
        this.contactForm.patchValue({
          [controlName]: file
        });
      }
    }
  }

  createTicket() {
    if (this.contactForm.valid) {
      this.loading = true;
      this.error = false;
      this.success = false;

      const formData = new FormData();
      const formValue = this.contactForm.value;
      formData.append('badge', formValue.badge, formValue.badge.name);
      formData.append('identification', formValue.identification, formValue.identification.name);
      formData.append('name', formValue.name);
      formData.append('airline', formValue.airline);
      formData.append('email', encodeURIComponent(formValue.email));
      formData.append('recaptcha', formValue.recaptcha);
      formData.append('urlOrigin', this.urlOrigin);

      this.zendeskService.createTicket(formData).subscribe(
        () => {
          this.loading = false;
          this.success = true;
          this.contactForm.reset();
        },
        () => {
          this.loading = false;
          this.error = true;
          this.contactForm.reset();
        }
      );
    } else {
      Object.keys(this.contactForm.controls).forEach((field) => {
        const control = this.contactForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  closeAlert() {
    this.success = false;
    this.error = false;
  }

  setFormData() {
    const details = this.formDetails[this.router.url];
    if (details) {
      this.formTitle = details.title;
      this.formContent = details.content;
      this.urlOrigin = details.urlOrigin;
    }
  }

  get name() { return this.contactForm ? this.contactForm.get('name') : null; }
  get email() { return this.contactForm ? this.contactForm.get('email') : null; }
  get identification() { return this.contactForm ? this.contactForm.get('identification') : null; }
  get badge() { return this.contactForm ? this.contactForm.get('badge') : null; }
  get recaptcha() {  return this.contactForm ? this.contactForm.get('recaptcha') : null; }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
