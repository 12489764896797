<div *ngIf="showModalForMobile" class="d-sm-none">
  <full-screen-modal [showModal]="showModal" [forceHideNav]="hideHeader" [scrollBody]="false" (modalClosed)="closeModal()" (keepResult)="handleLeaveInput()">
    <div body class="p-3 locations-modal-content">
      <div class="input-group mb-0 form-floating">
        <div class="invalid-feedback" *ngIf="searchFailed">{{ 'mobile.general.autocomplete_failure' | translate }}</div>
        <input
          *ngIf="!inputModel.street"
          [ngClass]="{'ps-5': showIcon}"
          [ngbTypeahead]="search"
          type="text"
          class="form-control rounded"
          [attr.maxlength]="addressMaxLength"
          (selectItem)="onSelect($event)"
          [inputFormatter]="formatMatches"
          [resultTemplate]="rt"
          [focusFirst]="focusFirst"
          autocomplete="off"
          (click)="click$.next($event.target.value)"
          (blur)="selectValue()"
          #instance="ngbTypeahead"
          #inputField
        />
        <label [ngClass]="{'ps-5': showIcon}" for="{{controlName}}">{{ placeholder }}</label>
        <input
          *ngIf="inputModel.street"
          [(ngModel)]="inputModel.street"
          [ngClass]="{'ps-5': showIcon}"
          [ngbTypeahead]="search"
          type="text"
          class="form-control rounded"
          [attr.maxlength]="addressMaxLength"
          (selectItem)="onSelect($event)"
          [inputFormatter]="formatMatches"
          [resultTemplate]="rt"
          [focusFirst]="focusFirst"
          autocomplete="off"
          (click)="click$.next($event.target.value)"
          (blur)="selectValue()"
          #instance="ngbTypeahead"
          #inputField
        />
        <fa-icon [icon]="icons.faSearch" *ngIf="showIcon" class="input-icon search-icon text-primary"></fa-icon>
        <span *ngIf="searching" class="input-group-text"><fa-icon [icon]="icons.faSpinner" class="rotation-animation fa-spin"></fa-icon></span>
      </div>
    </div>
  </full-screen-modal>
</div>

<ng-template #rt let-r="result" let-t="term">
  <fa-icon *ngIf="r.type !== 'title'" [icon]="icons[getLocationIcon(r)]" class="pe-2"></fa-icon>
  <div *ngIf="r.type === 'title'" class="primary-content">{{ r.title | translate }}</div>
  <ngb-highlight *ngIf="r.type !== 'title'" [result]="formatMatches(r)" [term]="t"></ngb-highlight>

  <div *ngIf="r.type === 'recent' && r.travelers && recentDetailsEnable" class="{{ alignBardgeLeft ? 'badge-container-inline' : 'badge-container' }} d-none d-sm-block">
    <p
      *ngIf="alignBardgeLeft"
      class="badge rounded-pill text-bg-light">
      {{ numberOfGuests(r.travelers) }} Guests, {{ r.checkin | amDateFormat:'MMM D'}} - {{ r.checkout |amDateFormat :'MMM D' }}
    </p>
    <span
      *ngIf="!alignBardgeLeft"
      class="badge rounded-pill text-bg-light">
      {{ numberOfGuests(r.travelers) }} Guests, {{ r.checkin | amDateFormat:'MMM D'}} - {{ r.checkout |amDateFormat :'MMM D' }}
    </span>
  </div>
</ng-template>

<div *ngIf="showModalForMobile" class="d-sm-none">
  <div class="input-group" [formGroup]="form">
    <input autocomplete="disable" [readonly]="isIos()" formControlName="{{controlName}}" id="{{controlName}}" placeholder="{{ placeholder }}" class="form-control" (focus)="openModal()"/>
  </div>
</div>

<div [class]="showModalForMobile ? 'd-none d-sm-block' : ''" [formGroup]="form">
  <div class="input-group form-floating mb-0" [ngClass]="{'ng-invalid': form.get(controlName).invalid, 'ng-touched': form.get(controlName).touched}">
    <div class="invalid-feedback" *ngIf="searchFailed">{{ 'mobile.general.autocomplete_failure' | translate }}</div>
    <span *ngIf="icon" class="input-group-text">
      <fa-icon [icon]="icons[icon]" [class]="iconClass ? iconClass : 'search-icon text-primary'"></fa-icon>
    </span>
    <span *ngIf="showIcon" class="input-group-text">
      <fa-icon [icon]="icons.faSearch" class="search-icon text-primary"></fa-icon>
    </span>
    <input
      *ngIf="!inputModel.street"
      #inputField
      #instanceModal="ngbTypeahead"
      autocomplete="off"
      class="form-control"
      data-cy="locationsAutocompleteInput"
      formControlName="{{controlName}}"
      id="{{controlName}}-sm"
      placement="bottom-left"
      type="text"
      [attr.maxlength]="addressMaxLength"
      [focusFirst]="focusFirst"
      [inputFormatter]="formatMatches"
      [ngbTypeahead]="search"
      [ngClass]="{'all-inclusive-input': isAllInclusivePopupSearch()}"
      [placeholder]="placeholder"
      [readonly]="isAllInclusivePopupSearch()"
      [resultTemplate]="rt"
      (blur)="selectValue()"
      (click)="click$.next($event.target.value)"
      (selectItem)="onSelect($event)"
    />
    <input
      *ngIf="inputModel.street"
      #inputField
      #instanceModal="ngbTypeahead"
      autocomplete="off"
      class="form-control"
      data-cy="locationsAutocompleteInput"
      formControlName="{{controlName}}"
      id="{{controlName}}"
      placement="bottom-left"
      type="text"
      [attr.maxlength]="addressMaxLength"
      [focusFirst]="focusFirst"
      [inputFormatter]="formatMatches"
      [ngbTypeahead]="search"
      [placeholder]="placeholder"
      [resultTemplate]="rt"
      (blur)="selectValue()"
      (click)="click$.next($event.target.value)"
      (selectItem)="onSelect($event)"
      [(ngModel)]="inputModel.street"
    />
    <label [for]="controlName + (inputModel.street ? '' : '-sm')">{{ placeholder }}</label>
    <span class="input-group-text">
      <fa-icon *ngIf="searching" [icon]="icons.faSpinner" class="rotation-animation fa-spin"></fa-icon>
    </span>
  </div>
</div>
