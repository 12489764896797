<div class='card shadow-none'>
  <credit-component
    *ngIf="userHasCredit && (!rawCCOnly && creditInformation && !bookingData?.isPayLater)"
    [creditInformation]="creditInformation"
    [bookingData]="bookingData"
    [currency]="currency"
    [totalPayment]="totalPayment"
    [isPayLater]="bookingData?.isPayLater"
    (updateTotal)="updateTotal()"
    (goBackClick)="goBackClick.emit($event)"
    (toggleStealthCredits)="toggleStealthCredits.emit($event)"
  ></credit-component>
  <loading-indicator [fullScreen]="false" *ngIf="!ready"></loading-indicator>
  <ng-container *ngIf="ready">
    <form [formGroup]="paymentForm" data-cy="credit_card_form" >
      <div *ngIf="cashOptionAvailable" (click)="select(cashPaymentOption)"
        [ngClass]="{'gray': paymentForm.controls['paymentSelection'].value !== cashPaymentOption}" class='card-body'>
        <div class='card-title d-flex clickable'>
          <div class='pe-4 gtm-select-cash'>
            <input class="gtm-select-cash" formControlName="paymentSelection" type="radio" [value]="cashPaymentOption" data-cy="cashPaymentOption">
          </div>
          <div class='payment-selection'>
            <h5 class='fw-normal mb-0'>{{ 'payment.cash' | translate }}</h5>
            <div class='tertiary-content'>{{ 'payment.cash_description' | translate }}</div>
            <div class='card-images d-flex justify-content-end gtm-select-cash'>
              <img class="gtm-select-cash" src="angular_assets/images/icons/cash.svg" />
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!rawCCOnly && showPaypal && currency === 'USD'" (click)="select('paypal')" [ngClass]="{'gray': paymentForm.controls['paymentSelection'].value !== 'paypal'}" class='card-body'>
        <div class='d-flex clickable'>
          <div class='pe-4 gtm-select-paypal'>
            <input class="gtm-select-paypal" formControlName="paymentSelection" type="radio" value="paypal">
          </div>
          <div class='payment-selection'>
            <h5 class='fw-normal mb-0'>PayPal</h5>
            <div class='tertiary-content'>Safe payment online.</div>
            <div class='paypal-logo d-flex justify-content-end gtm-select-paypal'>
              <img class="gtm-select-paypal" src="angular_assets/images/icons/paypal-logo.png" />
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!rawCCOnly && showAffirm && (currency === 'USD' || currency === 'CAD')" (click)="select('affirm')" class='card-body' [ngClass]="{'gray': paymentForm.controls['paymentSelection'].value !== 'affirm'}" class='card-body'>
        <div class='d-flex clickable'>
          <div class='pe-4 gtm-select-affirm'>
            <input class="gtm-select-affirm" formControlName="paymentSelection" type="radio" value="affirm">
          </div>
          <div class='payment-selection'>
            <div class="row">
              <div class="col-6">
                <h5 class='fw-normal mb-0'>Affirm</h5>
                <div  class='tertiary-content'>
                  <p #message class="affirm-as-low-as" data-page-type="product" attr.data-amount="{{formatValueAffirm()}}" onchange="updateAffirmMessage()"></p>
                </div>
              </div>
              <div class="col-6">
                <div class='d-flex affirm-logo'>
                  <img class="gtm-select-affirm" src="angular_assets/images/icons/affirm.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="showCreditCard" [ngClass]="{'gray': paymentForm.controls['paymentSelection'].value !== 'cc'}" class='card-body'>
        <div (click)="select('cc')" [ngClass]="{'clickable' : !rawCCOnly && currency === 'USD'}" class='card-title'>
          <div class='d-flex'>
            <div *ngIf="!rawCCOnly && currency === 'USD' || cashOptionAvailable" class='pe-4'>
              <input formControlName="paymentSelection" type="radio" value="cc">
            </div>
            <div class='payment-selection'>
              <div class="d-flex flex-nowrap">
                <h5 class="fw-normal mb-0" pe-1 style="white-space: nowrap">{{ 'mailers.hotel_reservation.credit_card' | translate }}</h5>
                <div class="c-card-images d-flex flex-fill flex-shrink-1 justify-content-end">
                  <div class="d-flex flex-nowrap">
                    <div *ngFor="let icon of allowedCreditsCardsIcons" class="flex-shrink-1">
                      <img src="angular_assets/images/icons/{{icon}}.png" />
                    </div>
                  </div>
                </div>
              </div>
              <div class='tertiary-content'>{{ 'payment.safe_money' | translate }}</div>
            </div>
          </div>
        </div>
        <div *ngIf="paymentForm.controls['paymentSelection'].value === 'cc'" class='card-text'>
          <ng-container *ngIf="paymentMethods && paymentMethods.length > 0 && !showNewPaymentMethod">
            <payment-card [card]="selectedCard"></payment-card>
            <div class="mt-3 d-flex justify-content-end">
              <span id="gtm-select-another-card" class="primary-color clickable pe-3" (click)="showPaymentMethods()" *ngIf="paymentMethods.length > 1">
                {{ 'payment.another_card' | translate }}
              </span>
              <span id="gtm-select-another-card" class="primary-color clickable pe-3" (click)="showPaymentMethods()" *ngIf="paymentMethods.length === 1 && canEditCard(paymentMethods[0])">
                {{ 'payment.edit_card' | translate }}
              </span>
              <span data-cy="new_card" id="gtm-add-new-card" class="primary-color clickable" (click)="toggleForm(true)">{{ 'cars.payment.add_cc' | translate }}</span>
            </div>
          </ng-container>
          <ng-container class="new-payment" *ngIf="showNewPaymentMethod">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group form-floating">
                  <input type="text" class="form-control with-background" formControlName="firstName" autocomplete="given-name" [(ngModel)]="paymentInformation.firstName" data-cy="paymentFirstName">
                  <label for="first-name">{{ 'payment.first_name' | translate }}</label>
                  <div class='error-message'
                    *ngIf="firstName.errors !== null && (firstName.touched)"
                    [innerHtml]="formErrorsHelper.errorMessage(firstName, ('payment.first_name' | translate))">
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group form-floating">
                  <input type="text" class="form-control with-background" formControlName="lastName" data-cy="paymentLastName" autocomplete="family-name" [(ngModel)]="paymentInformation.lastName">
                  <label for="last-name">{{ 'payment.last_name' | translate }}</label>
                  <div class='error-message'
                    *ngIf="lastName.errors !== null && (lastName.touched)"
                    [innerHtml]="formErrorsHelper.errorMessage(lastName, ('payment.last_name' | translate))">
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="bookingData?.isPayLater && checkNames()" class="row">
              <div class="col tertiary-content">{{'payment.cc_matching_name' | translate}}</div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <div class="position-relative form-floating">
                    <input (input)="formatCreditCard()" type="text" class="form-control inspectlet-sensitive with-background" formControlName="cardNumber" [(ngModel)]="paymentInformation.cardNumber" autocomplete="cc-number" data-cy="paymentCardNumber">
                    <label for="card-number">{{ 'payment.card_number_long' | translate }}</label>
                    <img *ngIf="paymentInformation.cardType.length" class="input-icon" [src]="'angular_assets/images/icons/' + paymentInformation.cardType + '.png'" data-cy="credit-card-icon"/>
                    <fa-icon *ngIf="!paymentInformation.cardType.length" [icon]="icons.faCreditCard" class="input-icon"></fa-icon>
                  </div>
                  <div class='error-message' data-cy="error-card-number"
                    *ngIf="cardNumber.errors !== null && (cardNumber.touched || cardNumber.value.length > 3)"
                    [innerHtml]="(formErrorsHelper.errorMessage(cardNumber, ('payment.card_number' | translate)) | translate)">
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <div class="position-relative form-floating">
                    <input #expirationDateField (input)="formatDate($event)" type="text" class="form-control inspectlet-sensitive with-background" formControlName="expiration" [(ngModel)]="paymentInformation.expiration" autocomplete="cc-exp" data-cy="paymentExpirationDate" data-sl="mask">
                    <label for="expiration">{{ 'payment.expiration_date' | translate }} (MM/YY)</label>
                    <fa-icon [icon]="icons.faCalendarAlt" class="input-icon"></fa-icon>
                  </div>
                  <div class='error-message' data-cy="error-card-expiration"
                    *ngIf="expiration.errors !== null && (expiration.touched)"
                    [innerHtml]="(formErrorsHelper.errorMessage(expiration, ('payment.expiration_date_short' | translate))  | translate)">
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <div class="position-relative form-floating">
                    <input #cvvField (input)="checkCvv()" type="text" class="form-control inspectlet-sensitive with-background" formControlName="ccv" [(ngModel)]="paymentInformation.ccv" maxlength="{{ccvMaxLength}}" autocomplete="cc-csc" data-cy="paymentCCV" data-sl="exclude">
                    <label for="ccv">{{ 'payment.cvv_code' | translate }}</label>
                    <fa-icon [icon]="icons.faInfoCircle" class="d-none d-md-inline input-icon" placement="top" tooltipClass="cvv-tooltip" [ngbTooltip]="cvvTooltip"></fa-icon>
                    <fa-icon (click)="showCvvModal()" [icon]="icons.faInfoCircle" class="d-inline d-md-none input-icon"></fa-icon>
                  </div>
                  <div class='error-message' data-cy="error-cvv"
                    *ngIf="ccv.errors !== null && (ccv.touched)"
                    [innerHtml]="formErrorsHelper.errorMessage(ccv, ('payment.cvv_code_short' | translate))">
                  </div>
                </div>
              </div>
            </div>
            <address-form
              #addressForm
              *ngIf="paymentInformation.billingAddressRequired || isInsuranceSelected"
              [address]="paymentInformation.address"
              [showStreet]="addressFields.showStreet"
              [showCity]="addressFields.showCity"
              [showCountry]="addressFields.showCountry"
              [showState]="addressFields.showState"
              [showZip]="addressFields.showZip"
              [cityMaxLength]="cityMaxLength"
              [addressMaxLength]="addressMaxLength"
              (addressSelected)="addressSelected($event)">
            </address-form>
            <ng-container *featureFlag="featureFlags.PAYMENT_METHODS">
              <div *ngIf="!rawCCOnly" class="row">
                <div class="col">
                  <div class="form-group d-flex">
                    <div class='pe-3'>
                      <input id="gtm-save-card" type="checkbox" formControlName="savePayment">
                    </div>
                    <div class='payment-save'>
                      <h5 class='fw-normal mb-0'>{{ 'payment.save_cc' | translate }}</h5>
                      <div class='tertiary-content'>{{ 'payment.save_cc_sub' | translate }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <div *ngIf="showIsBusinessCheckbox" class="row">
              <div class="col">
                <div class="form-group d-flex">
                  <div class='pe-3'>
                    <input type="checkbox" formControlName="isBusiness">
                  </div>
                  <div class='payment-save'>
                    <h5 class='fw-normal mb-0'>Is this a Corporate Card?</h5>
                    <div class='tertiary-content'>Corporate Cards are intended to be shared with your Business Travelers</div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="!rawCCOnly && enabledPaymentMethods && enabledPaymentMethods.length > 0" class="mt-2 d-flex justify-content-end">
              <span (click)="toggleForm(false)" id="gtm-use-stored-card" class="primary-color clickable pe-3">{{ 'payment.use_cc' | translate }}</span>
            </div>
          </ng-container>
        </div>
      </div>
    </form>
  </ng-container>
</div>

<ng-template #cvvTooltip>
  <div class="row text-capitalize pb-1 ccv-tooltip">
    <div class="col-sm-12">
      <cvv-information [cardType]="paymentInformation.cardType"></cvv-information>
    </div>
  </div>
</ng-template>
