import { Component, Input } from '@angular/core';
import { faStar } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'review-static-card',
  templateUrl: './review-static-card.html',
  styleUrls: ['review-static-card.scss']
})
export class ReviewStaticCardComponent {
  @Input() cardTitle: string = '';
  @Input() cardText: string = '';
  @Input() cardAuthor: string = '';
  icons = { faStar };
}
