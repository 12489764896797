<div class="card shadow-none">
  <div class="card-body">
    <div class="primary-content" *ngIf="showTitle">{{ 'flights.booking.insurance_title' | translate }}</div>
    <div *ngFor="let traveler of trip.travelers">
      <div class="d-flex flex-nowrap my-3">
        <div class="flex-fill flex-shrink-1">
          <div class="secondary-content">{{ traveler.firstName }} {{ traveler.lastName }}</div>
          <div class="tertiary-content traveler-email">{{ traveler.email }}</div>
          <div class="tertiary-content">{{ 'lodging.hotels.insurance.headers.bd' | translate }}: {{ traveler.dateOfBirth | amDateFormat:'MMM D, YYYY' }}</div>
        </div>
        <div class="d-flex flex-fill justify-content-end">
          <div class="secondary-content">{{ trip.insuranceTotalCost / trip.travelers.length | currency:trip.currencyCode:'symbol':'1.0-2' }}</div>
        </div>
      </div>
    </div>
  </div>
</div>