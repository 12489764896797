import { Action } from '@ngrx/store';
import { CarTrip } from 'app/trips/models/car-trip';
import { HotelTrip } from 'app/trips/models/hotel-trip';

export enum TripsActionTypes {
  ClearLoaded = '[Trips] Clear Loaded',
  ClearPastLoaded = '[Trips] Clear Past Loaded',
  ClearFutureLoaded = '[Trips] Clear Future Loaded',
  ClearInsurancesLoaded = '[Trips] Clear Insurances Loaded',
  SaveFutureCar = '[Trips] Save Future Car',
  SaveFutureHotel = '[Trips] Save Future Hotel',
  LoadFutureHotels = '[Trips] Load Future Hotels',
  LoadPastHotels = '[Trips] Load Past Hotels',
  LoadFutureCars = '[Trips] Load Future Cars',
  LoadPastCars = '[Trips] Load Past Cars',
  LoadFutureFlights = '[Trips] Load Future Flights',
  LoadPastFlights = '[Trips] Load Past Flights',
  LoadHotelsInsurance = '[Trips] Load Hotels Insurance',
  LoadFlightsInsurance = '[Trips] Load Flights Insurance',
  LoadSharedItineraries = '[Trips] Load Shared Itineraries',
  ClearFlights = '[Trips] Clear Flights',
  ClearCars = '[Trips] Clear Cars',
  ClearFutureHotels = '[Trips] Clear Future Hotels',
  LoadFutureFlightsByPNR = '[Trips] Load Future Flights By PNR',
  LoadPastFlightsByPNR = '[Trips] Load Past Flights By PNR',
  CleanHotelTrips = '[Trips] Clean Hotel Trips',
  CleanCarTrips = '[Trips] Clean Car Trips',
  CleanFlightTrips = '[Trips] Clean Flight Trips',
  CleanInsuranceTrips = '[Trips] Clean Insurance Trips',
}

export class ClearLoaded implements Action {
  readonly type = TripsActionTypes.ClearLoaded;

  constructor() { }
}

export class ClearPastLoaded implements Action {
  readonly type = TripsActionTypes.ClearPastLoaded;

  constructor() { }
}

export class ClearFutureLoaded implements Action {
  readonly type = TripsActionTypes.ClearFutureLoaded;

  constructor() { }
}

export class SaveFutureCar implements Action {
  readonly type = TripsActionTypes.SaveFutureCar;

  constructor(public payload: CarTrip) { }
}

export class SaveFutureHotel implements Action {
  readonly type = TripsActionTypes.SaveFutureHotel;

  constructor(public payload: HotelTrip) { }
}

export class LoadFutureHotels implements Action {
  readonly type = TripsActionTypes.LoadFutureHotels;

  constructor(public payload: any) { }
}
export class LoadPastHotels implements Action {
  readonly type = TripsActionTypes.LoadPastHotels;

  constructor(public payload: any) { }
}
export class LoadFutureCars implements Action {
  readonly type = TripsActionTypes.LoadFutureCars;

  constructor(public payload: any) { }
}
export class LoadPastCars implements Action {
  readonly type = TripsActionTypes.LoadPastCars;

  constructor(public payload: any) { }
}
export class LoadFutureFlights implements Action {
  readonly type = TripsActionTypes.LoadFutureFlights;

  constructor(public payload: any) { }
}
export class LoadPastFlights implements Action {
  readonly type = TripsActionTypes.LoadPastFlights;

  constructor(public payload: any) { }
}
export class LoadHotelsInsurance implements Action {
  readonly type = TripsActionTypes.LoadHotelsInsurance;

  constructor(public payload: any) { }
}
export class LoadFlightsInsurance implements Action {
  readonly type = TripsActionTypes.LoadFlightsInsurance;

  constructor(public payload: any) { }
}
export class LoadSharedItineraries implements Action {
  readonly type = TripsActionTypes.LoadSharedItineraries;

  constructor() { }
}
export class ClearFlights implements Action {
  readonly type = TripsActionTypes.ClearFlights;

  constructor() { }
}

export class ClearCars implements Action {
  readonly type = TripsActionTypes.ClearCars;

  constructor() { }
}
export class ClearFutureHotels implements Action {
  readonly type = TripsActionTypes.ClearFutureHotels;

  constructor() { }
}

export class LoadFutureFlightsByPNR implements Action {
  readonly type = TripsActionTypes.LoadFutureFlightsByPNR;
  constructor(public payload: any) { }
}

export class LoadPastFlightsByPNR implements Action {
  readonly type = TripsActionTypes.LoadPastFlightsByPNR;
  constructor(public payload: any) { }
}

export class ClearInsurancesLoaded implements Action {
  readonly type = TripsActionTypes.ClearInsurancesLoaded;
  constructor() { }
}

export class CleanHotelTrips implements Action {
  readonly type = TripsActionTypes.CleanHotelTrips;

  constructor() { }
}

export class CleanCarTrips implements Action {
  readonly type = TripsActionTypes.CleanCarTrips;

  constructor() { }
}

export class CleanFlightTrips implements Action {
  readonly type = TripsActionTypes.CleanFlightTrips;

  constructor() { }
}

export class CleanInsuranceTrips implements Action {
  readonly type = TripsActionTypes.CleanInsuranceTrips;

  constructor() { }
}

export type TripsActionsUnion = SaveFutureCar
  | SaveFutureHotel
  | ClearLoaded
  | ClearPastLoaded
  | ClearFutureLoaded
  | ClearLoaded
  | LoadFutureCars
  | LoadPastCars
  | LoadFutureFlights
  | LoadPastFlights
  | LoadFutureHotels
  | LoadPastHotels
  | LoadHotelsInsurance
  | LoadFlightsInsurance
  | LoadSharedItineraries
  | ClearFlights
  | ClearCars
  | ClearFutureHotels
  | ClearInsurancesLoaded
  | LoadFutureFlightsByPNR
  | LoadPastFlightsByPNR
  | CleanHotelTrips
  | CleanCarTrips
  | CleanFlightTrips
  | CleanInsuranceTrips;
