import { Component, EventEmitter, Input, Output, ChangeDetectionStrategy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { CarTrip } from 'app/trips/models/car-trip';
import { GeneralHelper } from 'app/shared/helpers/general.helper';

@Component({
  selector: 'cars-trips-card',
  templateUrl: './cars-card.html',
  styleUrls: ['./cars-card.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CarsTripsCardComponent implements OnInit {
  @Input() car: CarTrip;
  @Output() viewTripClicked = new EventEmitter();
  shouldDisplayPickupYear = false;
  shouldDisplayDropoffYear = false;
  pickupDate: string;
  dropoffDate: string;
  actualYear = moment().year();

  constructor (
    public generalHelper: GeneralHelper
  ) {}

  ngOnInit() {
    this.shouldDisplayPickupYear = this.generalHelper.compareTwoYears(this.car.pick_up_datetime, this.actualYear);
    this.shouldDisplayDropoffYear = this.generalHelper.compareTwoYears(this.car.drop_off_datetime, this.actualYear);

    this.pickupDate = this.shouldDisplayPickupYear
      ? moment.utc(this.car.pick_up_datetime).format('ddd, MMM D')
      : moment.utc(this.car.pick_up_datetime).format('ll');

    this.dropoffDate = this.shouldDisplayDropoffYear
      ? moment.utc(this.car.drop_off_datetime).format('ddd, MMM D')
      : moment.utc(this.car.drop_off_datetime).format('ll');
  }
}
