import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'app/auth/services/authentication.service';
import { Router } from '@angular/router';
import { Modal } from 'app/shared/models/modal.model';
import * as fromShared from 'app/shared/store/reducers';
import { ModalActions } from '../../shared/store/actions';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

@Component({
  template: '<loading-indicator></loading-indicator>'
})

export class ResendEmailConfirmation implements OnInit {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private store: Store<fromShared.State>
  ) { }

  ngOnInit() {
    this.resendEmailConfirmation();
  }

  resendEmailConfirmation() {
    this.authService.resendEmailConfirmation().pipe(take(1)).subscribe((response:any) => {
      if (response) {
        const data: Modal = {
          body: response.message,
          modalOptions: { size: 'lg' }
        };
        this.store.dispatch(new ModalActions.Show({ data }));
      }
      return this.router.navigate(['/']);
    });
  }
}
