

<ng-container *ngIf="showModal">
  <div
  class="full-screen-modal show"
  (click)="preserveFocus($event)"
>
  <div class="full-screen-modal-header" *ngIf="displayCloseButon">
    <fa-icon [icon]="icons.faTimes" (click)="close()"></fa-icon>
  </div>
  <div
    class="full-screen-modal-custom-header"
    *ngIf="!displayCloseButon && customHeader"
  >
    <ng-content select="[header]"></ng-content>
  </div>
  <div
    class="full-screen-modal-body"
    [ngClass]="{'scroll': scrollBody, 'full-screen-modal-body-whitout-footer': !displayFooter}"
  >
    <ng-content select="[body]"></ng-content>
  </div>
  <div class="full-screen-modal-footer" *ngIf="displayFooter">
    <ng-content select="[footer]"></ng-content>
  </div>
</div>
</ng-container>
