import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class MeiliHelper {

  constructor() { }

  isEnabled(configFlags, userAirlineCode) {
    return configFlags?.meili_available_airlines?.split(' ').includes(userAirlineCode?.toUpperCase()) ||
      configFlags?.meili_available_airlines === 'ALL';
  }

  getSearchQuery(checkin, checkout, destination?, latitude?, longitude?, hotelName?) {
    return [
      {
        pickupLocation: {
          'searchCode': destination,
          'latitude': latitude,
          'longitude': longitude,
          'name': hotelName
        },
        dropoffLocation: {
          'searchCode': destination,
          'latitude': latitude,
          'longitude': longitude,
          'name': hotelName
        },
        pickupDate: moment(checkin).format('YYYY-MM-DD'),
        pickupTime: moment(checkin).format('HH:mm'),
        dropoffDate: moment(checkout).format('YYYY-MM-DD'),
        dropoffTime: moment(checkout).format('HH:mm'),
        driverAge: '30',
        residency: 'IE'
      }
    ];
  }

}
