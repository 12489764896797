<div *ngIf="tldr" class="my-3">
  For your convenience we offer travel protection provided by Travelex Insurance Services, Inc.
</div>

<div *ngIf="!tldr">
  <div class="primary-content">Trip Insurance</div>
  Travel protection is offered along with any flight or hotel booking made on ID90 Travel  during the checkout process.<br/>
  <br/>
  As a member of ID90 Travel, you can also purchase travel protection at any time up until departure, regardless of when you booked your travel. For your convenience, we offer travel protection provided by Travelex Insurance Services, Inc.<br/>
  <br/>
  If you are not completely satisfied within 21 days of purchasing this plan and have not departed on your trip or filed a claim, we will happily refund your plan cost. To process a refund, please call our customer support at 877-298-5233.<br/>
  <br/>
  Below are some highlights of the available plan:<br/>
</div>

<div class="tg-wrap">
  <table class="tg w-100">
    <thead>
      <tr>
        <th class="tg-dfii" colspan="2">
          <span class="fw-bold ms-1">Benefits</span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="tg-0lax">Trip Interruption</td>
        <td class="tg-0lax"><span class="fw-bold">100% of Trip Cost up to $1,000</span><br>Eligible for
          coverage if you are unable to continue<br>your trip for any covered reason listed under the<br>Trip
          Interruption section in the <a href="https://partner.travelexinsurance.com/policy/?plannumber=501ZPD-0924" target="_blank">policy</a><br></td>
      </tr>
      <tr>
        <td class="tg-0lax">Travel Delay</td>
        <td class="tg-0lax"><span class="fw-bold">Up to $1,000 ($350/day)</span><br>Delay of at least 6
          hours<br></td>
      </tr>
      <tr>
        <td class="tg-0lax">Emergency Medical Expense</td>
        <td class="tg-0lax">Coverage up to $25,000</td>
      </tr>
      <tr>
        <td class="tg-0lax">Emergency Evacuation &amp; Repatriation</td>
        <td class="tg-0lax">Coverage up to $50,000</td>
      </tr>
    </tbody>
  </table>
</div>

<div class="mt-3" *ngIf="!tldr">
  <div class="my-3">If you have questions about coverage available under our plan, please review your <a href="https://partner.travelexinsurance.com/policy/?plannumber=501ZPD-0924" target="_blank">state-specific policy</a> or contact Travelex Insurance Services at 844.877.1882 or email <a href="mailto:customersolutions@travelexinsurance.com">customersolutions&#64;travelexinsurance.com</a>.</div>
  <div class="my-3">For additional information and disclaimers, <a href="https://partner.travelexinsurance.com/policy/?plannumber=501ZPD-0924" target="_blank">click here</a>.</div>
  <div class="my-3 text-disclaimer">The product descriptions provided here are only brief summaries and may be changed without notice. The full coverage terms and details, including limitations and exclusions, are contained in the insurance policy. For plan and coverage questions please contact Travelex at 1-844-877-1882 or email at <a href="mailto:customersolutions@travelexinsurance.com">customersolutions&#64;travelexinsurance.com</a> and reference location number 43-0305. Travelex Insurance Services, Inc. CA Agency License #0D10209. Insurance coverages underwritten by individual member companies of Zurich in North America, including Zurich American Insurance Company (NAIC #16535, state of domicile: New York). ID9WEB</div>
</div>
