import { AirportsRecentArrivalsSearchesActions, AirportsRecentArrivalsSearchesApiActions } from 'app/shared/store/actions';
import { AirportsRecentSearch } from 'app/shared/models/airports-recent-search';

export interface State {
  destinations: AirportsRecentSearch[] | null;
  pending: boolean;
  error: any | null;
  loaded: boolean;
}

export const initialState: State = {
  destinations: [],
  pending: false,
  error: null,
  loaded: false
};

export function reducer(
  state = initialState,
  action: AirportsRecentArrivalsSearchesActions.AirportsRecentArrivalsSearchesActionsUnion | 
  AirportsRecentArrivalsSearchesApiActions.AirportsRecentArrivalsSearchesApiActionsUnion
) {
  switch (action.type) {
    case AirportsRecentArrivalsSearchesActions.AirportsRecentArrivalsSearchesActionTypes.Load:
      return {
        ...state,
        destinations: [],
        error: null,
        pending: true
      };
    case AirportsRecentArrivalsSearchesApiActions.AirportsRecentArrivalsSearchesApiActionTypes.LoadSuccess:
      return {
        destinations: action.payload.destinations,
        error: null,
        pending: false,
        loaded: true
      };
    case AirportsRecentArrivalsSearchesApiActions.AirportsRecentArrivalsSearchesApiActionTypes.LoadFailure:
      return {
        destinations: [],
        error: action.payload.error,
        pending: false,
        loaded: false
      };
    case AirportsRecentArrivalsSearchesActions.AirportsRecentArrivalsSearchesActionTypes.SaveSearch:
      return {
        ...state,
        destinations: [action.payload, ...state.destinations]
      };
    default:
      return state;
  }
}

export const getDestinations = (state: State) => state.destinations;
export const getPending = (state: State) => state.pending;
export const getLoaded = (state: State) => state.loaded;
