import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { GeneralHelper } from 'app/shared/helpers/general.helper';
import { Router } from '@angular/router';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'breadcrumbs',
  styleUrls: ['./breadcrumbs.scss'],
  templateUrl: './breadcrumbs.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class BreadcrumbsComponent {
  icons = { faArrowLeft };

  constructor(
    private generalHelper: GeneralHelper,
    private router: Router
  ) {}

  @Input() links: [];
  @Input() goback = false;
  @Input() defaultNavigationBack: any = { url: ['/'], params: {} };
  @Input() title = 'go_back';

  isLast(index: number): boolean {
    return index === this.links.length - 1;
  }

  goBack() {
    if (this.defaultNavigationBack.forceNavigation === true) {
      return this.router.navigate(this.defaultNavigationBack.url, this.defaultNavigationBack.params);
    }
    const params = [this.defaultNavigationBack.url, this.defaultNavigationBack.params];
    if (typeof this.defaultNavigationBack.excludedUris !== 'undefined') {
      params.push(this.defaultNavigationBack.excludedUris);
    }
    this.generalHelper.locationBack(...params);
  }

}
