import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ResetPasswordCodeFormComponent } from 'app/auth/components/reset-password/code-form/reset-password-code-form.component';
import { ResetPasswordOptions } from 'app/auth/models/reset-password-options';
import { ForgotPasswordActions } from 'app/auth/store/actions';
import * as fromAuth from 'app/auth/store/reducers';
import { AttemptsHelper } from 'app/shared/helpers/attempts.helper';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';
import { ProfileHelper } from 'app/shared/helpers/profile.helper';
import { AirlinesService } from 'app/shared/services/airlines/airlines.service';
import { AirlineActions } from 'app/shared/store/actions';
import * as fromShared from 'app/shared/store/reducers';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'forgot-password-request',
  templateUrl: './request.html',
  styleUrls: ['./request.page.scss']
})

export class ForgotPasswordRequestPage implements OnInit {
  loading = false;
  airlineLogin = true;
  fieldGroups;
  msgError = '';
  msgInfo = '';
  success = false;
  requestCodeSuccess$ = this.store.pipe(select(fromAuth.getForgotPasswordCodeSuccess));
  requestCodeError$ = this.store.pipe(select(fromAuth.getForgotPasswordCodeError));
  requestLinkSuccess$ = this.store.pipe(select(fromAuth.getForgotPasswordLinkSuccess));
  requestLinkError$ = this.store.pipe(select(fromAuth.getForgotPasswordLinkError));
  codeValidation$ = this.store.pipe(select(fromAuth.getForgotPasswordCodeValidation));
  codeValidationError$ = this.store.pipe(select(fromAuth.getForgotPasswordCodeValidationError));
  verifyOptionsSuccess$ = this.store.pipe(select(fromAuth.getVerifyOptionsResultSuccess));
  verifyOptionsError$ = this.store.pipe(select(fromAuth.getVerifyOptionsError));
  airlines$: any = this.store.pipe(select(fromShared.getAirlines));
  params;
  credentials;
  airlines;
  showCodeInput = false;
  isCodeResend = false;
  codeResendSuccess = false;
  type = 'danger';
  showResetOptions: boolean = false;
  enabledResetOptions: ResetPasswordOptions = { push_notification: false, sms: false, email: false };
  isWhiteLabelEnabled: boolean = false;
  defaultAirlineCode: string;
  @ViewChild(ResetPasswordCodeFormComponent, { static: false }) codeForm;
  private ngUnsubscribe = new Subject<void>();

  constructor(
    private router: Router,
    private translate: TranslateService,
    public formErrorsHelper: FormErrorsHelper,
    private profileHelper: ProfileHelper,
    private store: Store<fromAuth.State>,
    private route: ActivatedRoute,
    public attemptsHelper: AttemptsHelper,
    private airlinesService: AirlinesService
  ) { }

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => {
      if (params && params.type === 'ftl') {
        this.msgInfo = this.translate.instant('forgot_password.user_exist');
      }
    });
    this.airlines$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((airlines) => {
      this.airlines = airlines;
      if (!airlines.length) {
        this.store.dispatch(new AirlineActions.LoadAirlines());
      } else {
        this.isWhiteLabelEnabled = this.airlinesService.isWhiteLabelON(airlines) || false;
        this.defaultAirlineCode = this.isWhiteLabelEnabled && this.airlinesService.getWhiteLabelAirlineCode(airlines) ? this.airlinesService.getWhiteLabelAirlineCode(airlines) : '';
      }
    });
    this.store.dispatch(new ForgotPasswordActions.Reset());
    this.listenForLinkSuccess();
    this.listenForLinkError();
    this.listenForCodeSuccess();
    this.listenForCodeError();
    this.listenForValidCode();
    this.listenForValidCodeError();
    this.listenForVerifyOptionsSuccess();
    this.listenForVerifyOptionsError();
  }

  resetLink() {
    this.loading = true;
    this.showCodeInput = false;
    let organizationId = this.credentials.organizationId;
    let airline = this.airlines.find(airline => airline.code.toUpperCase() === this.checkRetireeAirline(this.credentials.airlineCode));
    this.credentials.organizationId = airline.id;
    this.store.dispatch(new ForgotPasswordActions.RequestLink(this.credentials));
    this.credentials.organizationId = organizationId;
  }

  resetCode(medium = null) {
    this.loading = true;
    this.closeAlert();
    if (medium) {
      this.credentials.verificationCode = '';
      this.credentials.medium = medium;
    } else {
      this.isCodeResend = true;
    }
    this.codeResendSuccess = false;
    let airlineCode = this.credentials.airlineCode;
    this.credentials.airlineCode = this.checkRetireeAirline(this.credentials.airlineCode);
    let organizationId = this.credentials.organizationId;
    let airline = this.airlines.find(airline => airline.code.toUpperCase() === this.checkRetireeAirline(this.credentials.airlineCode));
    this.credentials.organizationId = airline.id;
    this.store.dispatch(new ForgotPasswordActions.RequestCode(this.credentials));
    this.credentials.organizationId = organizationId;
    this.credentials.airlineCode = airlineCode;
  }

  switchCode(medium) {
    this.loading = true;
    this.credentials.medium = medium;
    let airlineCode = this.credentials.airlineCode;
    this.credentials.airlineCode = this.checkRetireeAirline(this.credentials.airlineCode);
    let organizationId = this.credentials.organizationId;
    let airline = this.airlines.find(airline => airline.code.toUpperCase() === this.checkRetireeAirline(this.credentials.airlineCode));
    this.credentials.organizationId = airline.id;
    this.store.dispatch(new ForgotPasswordActions.RequestCode(this.credentials));
    this.credentials.organizationId = organizationId;
    this.credentials.airlineCode = airlineCode;
  }

  verifyAccountCredentials(event) {
    this.loading = true;
    this.closeAlert();
    let airlineCode;
    if (event.credentials) {
      this.credentials = event.credentials;
      airlineCode = this.credentials.airlineCode;
      this.credentials.airlineCode = this.checkRetireeAirline(this.credentials.airlineCode);
    }
    this.store.dispatch(new ForgotPasswordActions.VerifyOptions(this.credentials));
    this.credentials.airlineCode = airlineCode;
  }

  checkRetireeAirline(airlineCode) {
    const RETIREE_AIRLINES = ['OKR','F9R','NKR'];
    if (RETIREE_AIRLINES.includes(airlineCode.toUpperCase())) {
      airlineCode = airlineCode.substring(0, 2);
    }
    return airlineCode.toUpperCase();
  }

  showErrorMessage(err) {
    let error_description = err.error.error_description;
    if (typeof error_description === 'undefined') {
      error_description = 'We\'re sorry, but something went wrong';
    }
    this.msgError = error_description;
  }

  listenForLinkSuccess() {
    this.requestLinkSuccess$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
      if (response) {
        this.loading = false;
        this.success = true;
      }
    });
  }

  listenForLinkError() {
    this.requestLinkError$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((error) => {
      let errorDescription;
      if (error) {
        this.loading = false;
        if ( error.error && error.error.error_code === 'MemberNotFound') { this.success = true; }
        if (error.error?.error_description === 'undefined') {
          errorDescription = 'We\'re sorry, but something went wrong';
          this.msgError = errorDescription;
        }
      }
    });
  }

  listenForCodeSuccess() {
    this.requestCodeSuccess$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((success) => {
      if (success) {
        this.loading = false;
        this.showCodeInput = true;
        if (this.isCodeResend) {
          this.codeResendSuccess = true;
          this.isCodeResend = false;
        }
      }
    });
  }

  listenForVerifyOptionsSuccess() {
    this.verifyOptionsSuccess$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
      if (response) {
        this.loading = false;
        if (!response.user_exist) {
          this.store.dispatch(new ForgotPasswordActions.AddAttempt());
          this.attemptsHelper.checkAmountOfAttempts();
          return false;
        }
        this.showResetOptions = true;
        this.enabledResetOptions = {
          push_notification: response.notification_opt.app,
          sms: response.notification_opt.phone_number,
          email: response.notification_opt.email
        };
      }
    });
  }

  submitCode(code) {
    this.msgError = '';
    this.msgInfo = '';
    this.success = false;
    const params = {
      memberEmail: this.credentials.memberEmail,
      airlineCode: this.credentials.airlineCode,
      verificationCode: code,
      organizationId: this.credentials.organizationId
    };
    this.store.dispatch(new ForgotPasswordActions.ValidateCode(params));
  }

  listenForCodeError() {
    this.requestCodeError$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((error) => {
      if (error) {
        this.loading = false;
        let errorDescription = error.error.message;
        if (typeof errorDescription === 'undefined') {
          errorDescription = 'We\'re sorry, but something went wrong';
        }
        this.msgError = errorDescription;
      }
    });
  }

  listenForValidCode() {
    this.codeValidation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
      if (response) {
        this.loading = false;
        return this.router.navigate(['/password/edit'], { queryParams: { h: response.h } });
      }
    });
  }

  listenForValidCodeError() {
    this.codeValidationError$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((error) => {
      if (error) {
        this.loading = false;
        this.msgError = this.translate.instant(`errors.${error.error.message}`);
      }
    });
  }

  listenForVerifyOptionsError() {
    this.verifyOptionsError$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((error) => {
      if (error) {
        this.msgError = this.translate.instant(`errors.internal_server_error`);
        this.loading = false;
      }
    });
  }

  closeAlert() {
    this.msgError = '';
    this.msgInfo = '';
    this.success = false;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
