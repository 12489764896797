import { Modal } from '../../models/modal.model';
import { ModalActions } from '../actions';

export interface ModalState {
  data: Modal;
  show: boolean;
}

export const initialState: ModalState = {
  data: {},
  show: false
};

export function reducer(state = initialState, action: ModalActions.ModalActionsUnion): ModalState {
  switch (action.type) {
    case ModalActions.ModalActionTypes.Show:
      return {
        ...state,
        show: true,
        data: action.payload.data
      };
    case ModalActions.ModalActionTypes.Hide:
      return {
        ...state,
        show: false
      };
    default:
      return state;
  }
}

export const getModalData = (state: ModalState) => state;
