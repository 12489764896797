import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NeedARoomWidgetService {
  constructor (
    private http: HttpClient
  ) {}

  getContent(params): Observable<any> {
    params.return_html = true;
    return this.http.get(`${environment.apiUrl}/api/v1/need_a_room_widget`,
      {
        headers: new HttpHeaders().set('Accept', 'text/html'),
        params: params,
        responseType: 'text'
      }
    );
  }
}
