import { Action } from '@ngrx/store';
import { CarsRecentSearch } from 'app/shared/models/cars-recent-search';

export enum CarsRecentSearchesApiActionTypes {
  LoadSuccess = '[Cars Recent Searches/API] Load Success',
  LoadFailure = '[Cars Recent Searches/API] Load Failure',
}

export class LoadSuccess implements Action {
  readonly type = CarsRecentSearchesApiActionTypes.LoadSuccess;

  constructor(public payload: { destinations: CarsRecentSearch[] }) {}
}

export class LoadFailure implements Action {
  readonly type = CarsRecentSearchesApiActionTypes.LoadFailure;

  constructor(public payload: { error: any }) {}
}

export type CarsRecentSearchesApiActionsUnion = LoadSuccess
  | LoadFailure;
