import { AuthApiActions, LogoutComponentActions, MultifactorAuthenticationActions } from 'app/auth/store/actions/';
import { throwError, Observable, ObservableInput } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpXsrfTokenExtractor } from '@angular/common/http';
import { catchError, mergeMap, tap, publishReplay, refCount, take } from 'rxjs/operators';
import { environment } from '@env/environment';
import { AuthService } from 'app/shared/services/auth/auth.service';
import { select, Store } from '@ngrx/store';
import * as fromRoot from 'reducers';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from '../services/session/session.service';
import { FeatureFlagsService } from 'app/shared/services/featureFlags/featureFlags.service';
import { featureFlags } from 'app/shared/models/featureFlags';
import { ModalActions } from '../store/actions';
import { Modal } from '../models/modal.model';
import * as fromAuth from 'app/auth/store/reducers';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  readonly PATHS_REQUIRE_LOGIN = [
    '/hotels/checkout',
    '/cars/checkout',
    '/gift-card-checkout'
  ];

  readonly PATHS_NO_SESSION_MODAL = [
    'flights-admin'
  ];

  useCookies: boolean = environment.useCookies;
  showTokenErrorAgain = true;
  refreshCache$: Observable<any>;
  private userAccount$ = this.store.pipe(select(fromAuth.selectAuthStatusState));

  constructor(
    private injector: Injector,
    public modalService: NgbModal,
    public router: Router,
    private store: Store<fromRoot.State>,
    private sessionService: SessionService,
    private featureFlagsService: FeatureFlagsService,
  ) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.interceptWithTokens(req, next);
  }

  showExpiredSessionModal(): void {
    if (!this.featureFlagsService.isFeatureEnabled(featureFlags.LOGIN)) {
      return;
    }
    if (!this.modalService.hasOpenModals() && this.checkRoutesRequireLogin()) {
      const data: Modal = {
        title: 'flash_messages.sign_in',
        bodyTranslationKey: 'token_expired_popup',
        modalOptions: { size: 'lg' }
      };
      this.store.dispatch(new ModalActions.Show({ data }));
    }
  }

  checkRoutesRequireLogin(): Boolean {
    for (const path of this.PATHS_REQUIRE_LOGIN) {
      const searchPattern = new RegExp(`^${path}`, 'i');
      if (searchPattern.test(this.router.url)) {
        return true;
      }
    }

    return false;
  }

  private interceptWithTokens(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.get('No-Auth')) {
      const headers = req.headers.delete('No-Auth');
      return next.handle(req.clone({ headers }));
    }

    const token = this.sessionService.getAccessToken();
    return this.userAccount$.pipe(
      take(1),
      mergeMap((userAccount) => {
        return next.handle(this.attachHeaders(req, token)).pipe(
          tap(() => this.showTokenErrorAgain = true),
          catchError((error: HttpErrorResponse, caught: Observable<any>): ObservableInput<any> => {
            if (error.status === 401 && this.isSessionRequest(req)) {
              return throwError(error);
            }
            if (error.status === 401 && !this.isRefreshRequest(req)) {
              return this.refreshToken().pipe(
                mergeMap((user: any) => {
                  this.store.dispatch(new MultifactorAuthenticationActions.Reset());
                  this.sessionService.setAccessToken(user.access_token);
                  return next.handle(this.attachHeaders(req, user.access_token));
                }),
                catchError((err) => {
                  return this.handleError(req, next, err, userAccount);
                })
              );
            }
            return this.handleError(req, next, error, userAccount);
          })
        );

      }));

  }

  private handleError(req, next, error, userAccount) {
    const isOpUser = userAccount.user.call_center_admin;
    const opUserSetupped = userAccount.customerSupport && userAccount.customerSupport.userSelectedByOperator;
    const handleOpUser = isOpUser ? opUserSetupped : true;
    if (handleOpUser && (error.status === 401 || error.status === 403)) {
      this.store.dispatch(new AuthApiActions.SessionTimeOut({ response: true }));
      const pathname = window.location.pathname;
      const redirectUrl = pathname || '/';
      this.store.dispatch(new LogoutComponentActions.Logout({ redirectUrl }));
      this.showExpiredSessionModal();
      this.showTokenErrorAgain = false;
      return next.handle(this.attachHeaders(req));
    }
    return throwError(error);
  }

  private attachHeaders(req, token = null) {
    const contentType = req.headers.get('Content-Type') || 'application/json';
    const additionalHeaders = { 'X-API-CALL-V2': 'true', 'Content-Type': contentType };
    if (req.headers.get('token')) {
      additionalHeaders['Authorization'] = `Token token=${req.headers.get('token')}`;
    } else if (token != null) {
      additionalHeaders['Authorization'] = `Token token=${token}`;
    }

    return req.clone({ headers: req.headers.delete('token'), setHeaders: additionalHeaders });
  }

  private refreshToken() {
    if (!this.refreshCache$) {
      this.refreshCache$ = this.injector.get(AuthService).refreshAccessToken().pipe(
        publishReplay(1, 30000),
        refCount(),
        take(1)
      );
    }

    return this.refreshCache$;
  }

  private isRefreshRequest(req) {
    return req.url.indexOf('/sessions/show') > -1;
  }

  private isSessionRequest(req) {
    return req.url.indexOf('/api/v1/sessions') > -1;
  }

  private getHttpErrorInformation(req: HttpRequest<any>, error: HttpErrorResponse, userAccount): any {
    const { url, status, message } = error;
    const { urlWithParams } = req;
    return {
      userId: userAccount?.user?.account.id,
      idMembers: userAccount?.user?.account.idMembers,
      url,
      urlWithParams,
      status,
      message,
      platform: 'DESKTOP'
    }
  }
}
