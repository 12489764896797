import { Action } from '@ngrx/store';
import { CobusAdminReasonsForTravelRequest } from 'app/flights/models/searches.interface';
import { CobusSearchFormConfigRequest, CobusAdminError, CobusCreateAuthorizationRequest, CobusAdminAuthorizationRequest, CobusAdminAuthorizationNoValidatedEmployeeRequest } from 'app/flights/models/cobus-admin.interface';

export enum CobusAdminActionTypes {
  LoadAuthorizationPage = '[Cobus Admin] Load Authorization Page',
  LoadAuthorizationNoValidatedEmployeePage = '[Cobus Admin] Load Authorization No Validate Employee Page',
  LoadAuthorizationPageSuccess = '[Cobus Admin] Authorization Success',
  LoadAuthorizationPageFailure = '[Cobus Admin] Authorization Form Failure',
  SearchReasonsForTravel = '[Cobus Admin] Search Reasons For Travel',
  SearchReasonsForTravelSuccess = '[Cobus Admin] Search Reasons For Travel Success',
  SearchReasonsForTravelFailure = '[Cobus Admin] Search Reasons For Travel Failure',
  ValidateEmployee = '[Cobus Admin] Validate Employee',
  ValidateEmployeeSuccess = '[Cobus Admin] Validate Employee Success',
  ValidateEmployeeFailure = '[Cobus Admin] Validate Employee Failure',
  CreateAuthorization = '[Cobus Admin] Create Authorization',
  CreateAuthorizationSuccess = '[Cobus Admin] Create Authorization Success',
  CreateAuthorizationFailure = '[Cobus Admin] Create Authorization Failure',
  CancelAuthFlow = '[Cobus Admin] Cancel Authorization Flow',
  CancelAuthFlowSuccess = '[Cobus Admin] Cancel Authorization Flow Success',
  CancelAuthFlowFailure = '[Cobus Admin] Cancel Authorization Flow Failure',
  GetCobusSearchFormConfig = '[Cobus Admin] Get Cobus Search Form Config',
  GetCobusSearchFormConfigSuccess = '[Cobus Admin] Get Cobus Search Form Config Success',
  GetCobusSearchFormConfigFailure = '[Cobus Admin] Get Cobus Search Form Config Failure',
  ClearCobusAdminErrors = '[Cobus Admin] Clear Errors',
}

export class LoadAuthorizationPage implements Action {
  readonly type = CobusAdminActionTypes.LoadAuthorizationPage;

  constructor(public payload: { params: CobusAdminAuthorizationRequest }) { }
}

export class LoadAuthorizationNoValidatedEmployeePage implements Action {
  readonly type = CobusAdminActionTypes.LoadAuthorizationNoValidatedEmployeePage;

  constructor(public payload: { params: CobusAdminAuthorizationNoValidatedEmployeeRequest }) { }
}

export class LoadAuthorizationPageSuccess implements Action {
  readonly type = CobusAdminActionTypes.LoadAuthorizationPageSuccess;

  constructor(public payload: { data: any }) { }
}

export class LoadAuthorizationPageFailure implements Action {
  readonly type = CobusAdminActionTypes.LoadAuthorizationPageFailure;

  constructor(public payload: { error: any }) { }
}

export class SearchReasonsForTravel implements Action {
  readonly type = CobusAdminActionTypes.SearchReasonsForTravel;

  constructor(public payload: { params: CobusAdminReasonsForTravelRequest }) { }
}

export class SearchReasonsForTravelSuccess implements Action {
  readonly type = CobusAdminActionTypes.SearchReasonsForTravelSuccess;

  constructor(public payload: { data: any }) { }
}

export class SearchReasonsForTravelFailure implements Action {
  readonly type = CobusAdminActionTypes.SearchReasonsForTravelFailure;

  constructor(public payload: { error: any }) { }
}

export class ValidateEmployee implements Action {
  readonly type = CobusAdminActionTypes.ValidateEmployee;

  constructor(public payload: { params: any }) { }
}
export class ValidateEmployeeSuccess implements Action {
  readonly type = CobusAdminActionTypes.ValidateEmployeeSuccess;

  constructor(public payload: { data: any }) { }
}

export class ValidateEmployeeFailure implements Action {
  readonly type = CobusAdminActionTypes.ValidateEmployeeFailure;

  constructor(public payload: { error: CobusAdminError }) { }
}

export class CreateAuthorization implements Action {
  readonly type = CobusAdminActionTypes.CreateAuthorization;

  constructor(public payload: { params: CobusCreateAuthorizationRequest }) { }
}

export class CreateAuthorizationSuccess implements Action {
  readonly type = CobusAdminActionTypes.CreateAuthorizationSuccess;

  constructor(public payload: { data: any }) { }
}

export class CreateAuthorizationFailure implements Action {
  readonly type = CobusAdminActionTypes.CreateAuthorizationFailure;

  constructor(public payload: { error: any }) { }
}

export class CancelAuthFlow implements Action {
  readonly type = CobusAdminActionTypes.CancelAuthFlow;

  constructor() { }
}

export class CancelAuthFlowSuccess implements Action {
  readonly type = CobusAdminActionTypes.CancelAuthFlowSuccess;

  constructor() { }
}

export class CancelAuthFlowFailure implements Action {
  readonly type = CobusAdminActionTypes.CancelAuthFlowFailure;

  constructor(public payload: { error: any }) { }
}

export class GetCobusSearchFormConfig implements Action {
  readonly type = CobusAdminActionTypes.GetCobusSearchFormConfig;

  constructor(public payload: { params: CobusSearchFormConfigRequest }) { }
}

export class GetCobusSearchFormConfigSuccess implements Action {
  readonly type = CobusAdminActionTypes.GetCobusSearchFormConfigSuccess;

  constructor(public payload: { data: any }) { }
}

export class GetCobusSearchFormConfigFailure implements Action {
  readonly type = CobusAdminActionTypes.GetCobusSearchFormConfigFailure;

  constructor(public payload: { error: any }) { }
}
export class CrearCobusAdminErrors implements Action {
  readonly type = CobusAdminActionTypes.ClearCobusAdminErrors;
}

export type CobusAdminActionsUnion = LoadAuthorizationPage
  | LoadAuthorizationPageSuccess
  | LoadAuthorizationPageFailure
  | SearchReasonsForTravel
  | SearchReasonsForTravelSuccess
  | SearchReasonsForTravelFailure
  | ValidateEmployee
  | ValidateEmployeeSuccess
  | ValidateEmployeeFailure
  | CreateAuthorization
  | CreateAuthorizationSuccess
  | CreateAuthorizationFailure
  | CancelAuthFlow
  | CancelAuthFlowSuccess
  | CancelAuthFlowFailure
  | GetCobusSearchFormConfig
  | GetCobusSearchFormConfigSuccess
  | GetCobusSearchFormConfigFailure
  | LoadAuthorizationNoValidatedEmployeePage
  | CrearCobusAdminErrors;
