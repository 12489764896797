<footer *ngIf="loggedIn$ | async">
  <div class="container">
    <div class="row py-4">
      <div class="col-lg-4 col-md-6 col-sm-12 py-2">
        <h5 class="primary-content">{{'mobile.action.learn_more' | translate}}</h5>
        <div class="row useful-links">
          <div class="col-md-5 col-sm-12" *featureFlag="[featureFlags.BEAMER, featureFlags.ABOUT, featureFlags.BLOG, featureFlags.FAQ]">
            <div  class="d-none d-md-block secondary-content gtm-footer-link clickable" *featureFlag="featureFlags.BEAMER">
             <a class="link" (click)="openBeamer('web.nav.url.whats_new')"> {{ 'web.nav.title.whats_new' | translate }} </a>
            </div>
            <a class="d-block secondary-content gtm-footer-link" target="_blank" rel="noopener noreferrer" href="{{'nav.url.about_us' | translate}}" *featureFlag="featureFlags.ABOUT">{{'nav.title.about' | translate}}</a>
            <a class="d-block secondary-content gtm-footer-link" target="_blank" href="http://interliner.id90travel.com/blog/" *featureFlag="featureFlags.BLOG">{{'blog' | translate}}</a>
          </div>
          <div class="col-md-6 col-sm-12 ps-md-0">
            <a class="d-block secondary-content gtm-footer-link" routerLink="/navigation/contact/new" *featureFlag="featureFlags.CONTACT">{{ 'footer.contact_us' | translate }}</a>
            <a class="d-block secondary-content gtm-footer-link" routerLink="/navigation/terms_and_conditions">{{'invite.terms_and_conditions' | translate}}</a>
            <a class="d-block secondary-content gtm-footer-link" routerLink="/navigation/privacy_policy">{{'nav.privacy_policy.title' | translate}}</a>
            <a class="d-block secondary-content gtm-footer-link" routerLink="/navigation/data_policy_framework">{{'nav.data_policy_framework.title' | translate}}</a>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 py-2 ps-md-0">
        <h5 class="primary-content gtm-footer-link">{{ 'footer.contact_us' | translate }}</h5>
        <a class="d-block secondary-content gtm-footer-link" href="{{ 'nav.contact.support_help_center_link' | translate }}">{{ 'nav.contact.support_help_center_title' | translate }}</a>
        <a class="d-block secondary-content gtm-footer-link" href="mailto:{{ 'nav.contact.support_email' | translate }}">{{ 'nav.contact.support_email_title' | translate }}</a>
        <a class="d-block secondary-content gtm-footer-link" href="mailto:{{ 'nav.contact.id90.cruise_email' | translate }}">{{ 'nav.contact.id90.cruise_email_title' | translate }}</a>
      </div>
      <div class="col-lg-4 py-2 d-none d-lg-block text-end" >
        <img *ngIf="('footer.logo_url_bbb' | translate)" [offset]="100" [lazyLoad]="('footer.logo_url_bbb' | translate)" class="img-fluid me-3" />
        <img *ngIf="('footer.logo_url_nglcc' | translate)" [offset]="100" [lazyLoad]="('footer.logo_url_nglcc' | translate)" class="img-fluid w-25" />
      </div>
    </div>
  </div>
  <div class="container footer-copyright d-none d-md-block py-4">
    <div class="row">
      <div class="col-md-6 d-flex align-items-center tertiary-content">
        <img *ngIf="('logo.url' | translate)" [offset]="100" [lazyLoad]="('logo.url' | translate)" class="me-5 w-25" alt="">
        <div [innerHTML]="'footer.copyright.main' | translate: { year : currentYear }"></div>
      </div>
      <div class="col-md-6 d-flex align-items-center justify-content-end" *featureFlag="featureFlags.SOCIAL_LINKS">
        <a *ngIf="('social_media.facebook_url' | translate) !== 'social_media.facebook_url'" [href]="'social_media.facebook_url' | translate" target="_blank" class="btn-social gtm-footer-social"><fa-icon [icon]="icons.faFacebookF"></fa-icon></a>
        <a *ngIf="('social_media.instagram_url' | translate) !== 'social_media.instagram_url'" [href]="'social_media.instagram_url' | translate" target="_blank"  class="btn-social gtm-footer-social"><fa-icon [icon]="icons.faInstagram"></fa-icon></a>
        <a *ngIf="('social_media.youtube_url' | translate) !== 'social_media.youtube_url'" [href]="'social_media.youtube_url' | translate" target="_blank" class="btn-social gtm-footer-social"><fa-icon [icon]="icons.faYoutube"></fa-icon></a>
        <a *ngIf="('social_media.tiktok_url' | translate) !== 'social_media.tiktok_url'" [href]="'social_media.tiktok_url' | translate" target="_blank" class="btn-social gtm-footer-social"><fa-icon [icon]="icons.faTiktok"></fa-icon></a>
        <a *ngIf="('social_media.linkedin_url' | translate) !== 'social_media.linkedin_url'" [href]="'social_media.linkedin_url' | translate" target="_blank" class="btn-social gtm-footer-social"><fa-icon [icon]="icons.faLinkedinIn"></fa-icon></a>
      </div>
    </div>
  </div>
</footer>
<!-- Only for home page -->
<footer *ngIf="!(loggedIn$ | async)" class="mt-3">
  <div id="consent_blackbar"></div>
  <div class="container-fluid footer-container">
    <div class="row py-5">
      <div class="col-lg-3 col-md-4 col-sm-12 py-2">
        <h5 class="primary-content">{{'mobile.action.learn_more' | translate}}</h5>
        <div class="row useful-links">
          <div class="col" *featureFlag="[featureFlags.ABOUT, featureFlags.FAQ]">
            <a class="d-block secondary-content gtm-footer-link" target="_blank" rel="noopener noreferrer" href="{{'nav.url.about_us' | translate}}" *featureFlag="featureFlags.ABOUT">{{'nav.title.about' | translate}}</a>
            <a class="d-block secondary-content gtm-footer-link" routerLink="/navigation/terms_and_conditions">{{'invite.terms_and_conditions' | translate}}</a>
            <a class="d-block secondary-content gtm-footer-link" routerLink="/navigation/privacy_policy">{{'nav.privacy_policy.title' | translate}}</a>
            <a class="d-block secondary-content gtm-footer-link" routerLink="/navigation/data_policy_framework">{{'nav.data_policy_framework.title' | translate}}</a>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-12 py-2 ps-md-0">
        <h5 class="primary-content gtm-footer-link">{{ 'footer.contact_us_title' | translate }}</h5>
        <a class="d-block secondary-content gtm-footer-link" href="tel:{{ 'nav.contact.support_number_nt_link' | translate }}"><fa-icon [icon]="icons.faPhone" class="me-2"></fa-icon>{{ 'nav.contact.support_number_nt' | translate }}</a>
        <a class="d-block secondary-content gtm-footer-link" href="mailto:{{ 'nav.contact.support_email' | translate }}"><fa-icon [icon]="icons.faEnvelope" class="me-2"></fa-icon>{{ 'nav.contact.support_email' | translate }}</a>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-12 py-2 ps-md-0">
        <h5 class="primary-content gtm-footer-link">{{ 'footer.sales_inquiries' | translate }}</h5>
        <a class="d-block secondary-content gtm-footer-link" href="mailto:customersupport@id90travel.com"><fa-icon [icon]="icons.faEnvelope" class="me-2"></fa-icon>{{ 'nav.contact.id90.sales_email' | translate }}</a>
      </div>
      <div class="col-lg-3 py-2 d-none d-lg-flex align-items-center justify-content-end">
        <img *ngIf="('footer.logo_url_bbb' | translate)" [offset]="100" [lazyLoad]="('footer.logo_url_bbb' | translate)" class="img-fluid bbb-certificate-image me-3" />
        <img *ngIf="('footer.logo_url_nglcc' | translate)" [offset]="100" [lazyLoad]="('footer.logo_url_nglcc' | translate)" class="img-fluid nglcc-certificate-image" />
      </div>
    </div>
  </div>
  <div class="footer-copyright d-none d-md-block">
    <div class="container-fluid footer-container py-3">
      <div class="row">
        <div class="col-md-6 d-flex align-items-center tertiary-content">
          <div [innerHTML]="'footer.copyright.main' | translate: { year : currentYear }"></div>
        </div>
        <div class="col-md-6 d-flex align-items-center justify-content-end" *featureFlag="featureFlags.SOCIAL_LINKS">
          <a *ngIf="('social_media.facebook_url' | translate) !== 'social_media.facebook_url'" [href]="'social_media.facebook_url' | translate" target="_blank" class="btn-social gtm-footer-social"><fa-icon [icon]="icons.faFacebookF"></fa-icon></a>
          <a *ngIf="('social_media.instagram_url' | translate) !== 'social_media.instagram_url'" [href]="'social_media.instagram_url' | translate" target="_blank" class="btn-social gtm-footer-social"><fa-icon [icon]="icons.faInstagram"></fa-icon></a>
          <a *ngIf="('social_media.youtube_url' | translate) !== 'social_media.youtube_url'" [href]="'social_media.youtube_url' | translate" target="_blank" class="btn-social gtm-footer-social"><fa-icon [icon]="icons.faYoutube"></fa-icon></a>
          <a *ngIf="('social_media.tiktok_url' | translate) !== 'social_media.tiktok_url'" [href]="'social_media.tiktok_url' | translate" target="_blank" class="btn-social gtm-footer-social"><fa-icon [icon]="icons.faTiktok"></fa-icon></a>
          <a *ngIf="('social_media.linkedin_url' | translate) !== 'social_media.linkedin_url'" [href]="'social_media.linkedin_url' | translate" target="_blank" class="btn-social gtm-footer-social"><fa-icon [icon]="icons.faLinkedinIn"></fa-icon></a>
        </div>
      </div>
    </div>
  </div>
</footer>
