<div [ngClass]="{'disabled' : card.disabled || card.isExpired}" class="card-container p-3" [ngClass]="{'editable-mode-enabled': isEditableMode}">
  <div class="card-body">
    <div class="row align-items-center">
      <div class="col-md-2 d-none d-md-block" [ngClass]="{'grayscale' : card.disabled || card.isExpired}">
        <img class="img-fluid" [src]="'angular_assets/images/icons/' + card.payment_method_id + '.png'" />
      </div>

      <ng-container *ngIf="card.is_business === '1'">
        <div class="col-md-6 col-7 mt-2 mt-md-0">
          <div class="secondary-content text-uppercase d-block d-md-none">{{ card.payment_method_id }}</div>
          <div class="secondary-content">{{ 'payment.business_card' | translate }}</div>
        </div>
        <div class="col-md-4 col-5 mt-2 mt-md-0 text-end">
          <fa-icon [icon]="icons.faPlaneDeparture" class="primary-color"></fa-icon>
          <div *ngIf="card.isExpired" class="error-message">{{'payment.business_card_expired' | translate}}</div>
        </div>
      </ng-container>

      <ng-container *ngIf="card.is_business !== '1'">
        <div class="col-md-5 col-6 mt-2 mt-md-0">
          <div class="secondary-content d-none d-md-block">{{ 'payment.card_number' | translate }}</div>
          <div class="secondary-content text-uppercase d-block d-md-none">{{ card.payment_method_id }}</div>
          <div class="tertiary-content" data-cy="card-end">{{ 'payment.ending_in' | translate }}  {{ card.card_number }}</div>
        </div>
        <div class="col-md-5 col-6 mt-2 mt-md-0 text-end">
          <div class="secondary-content">{{ 'payment.expiration_date_short' | translate }}</div>
          <div class="tertiary-content">{{ card.expiration_date | amParse:'YYYY-MM' | amDateFormat:'MM/YY' }}</div>
          <div *ngIf="card.isExpired" class="error-message">{{'flights.booking.errors.CCExp' | translate}}</div>
        </div>
      </ng-container>
    </div>
    <div *ngIf="card.disabled" class="error-message">{{ 'payment.invalid_foreign_currency_cc' | translate }}</div>
  </div>
</div>
