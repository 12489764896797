<div class="customer-review" *ngIf="!eligibleInfo">
  <div class="review-text">
    <strong>ID90 Travel</strong> is the only way I travel now. Fast,
    easy, and the best deals around.
  </div>
  <div class="review-author">
    <img class="img-fluid me-3"
      src="angular_assets/images/customer-headshots/ashley-smith_flight-attendant_southwest.png" />
    <div class="customer-name">Ashley Smith</div>
    <div class="tertiary-content">Flight Attendant</div>
    <div class="tertiary-content">Southwest Airlines</div>
  </div>
</div>
<div class="customer-review" *ngIf="eligibleInfo">
  <div class="review-text">
    <strong>ID90 Travel</strong> is the only way I travel now. Fast,
    easy, and the best deals around.
  </div>
  <div class="review-author row">
    <div class="col-2">
      <avatar-circle class="me-3" [noavatar]="eligibleInfo.employeeAvatar || null"></avatar-circle>
    </div>
    <div class="col-10">
      <div class="customer-name">{{eligibleInfo.employeeFullName}}</div>
      <div class="tertiary-content">{{eligibleInfo.employeeAirlineName}}</div>
    </div>
  </div>
</div>
