import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ForgotPasswordActions } from '../actions';
import { ResetPasswordService } from 'app/auth/services/reset-password/reset-password.service';
import { map, catchError, exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class ForgotPasswordEffects {

  requestLink$ = createEffect(()=>
    this.actions$.pipe(
      ofType<ForgotPasswordActions.RequestLink>(ForgotPasswordActions.ForgotPasswordActionTypes.RequestLink),
      map(action => action.payload),
      exhaustMap((payload) =>
        this.resetPasswordService.request(payload).pipe(
          map(response => new ForgotPasswordActions.RequestLinkSuccess({ response })),
          catchError(error => of(new ForgotPasswordActions.RequestLinkError({ error })))
        )
      )
    )
  );


  requestCode$ = createEffect(()=>
    this.actions$.pipe(
      ofType<ForgotPasswordActions.RequestLink>(ForgotPasswordActions.ForgotPasswordActionTypes.RequestCode),
      map(action => action.payload),
      exhaustMap((payload) =>
        this.resetPasswordService.requestCode(payload).pipe(
          map(response => new ForgotPasswordActions.RequestCodeSuccess({ response })),
          catchError(error => of(new ForgotPasswordActions.RequestCodeError({ error })))
        )
      )
    )
  );

  validateCode$ = createEffect(()=>
    this.actions$.pipe(
      ofType<ForgotPasswordActions.ValidateCode>(ForgotPasswordActions.ForgotPasswordActionTypes.ValidateCode),
      map(action => action.payload),
      exhaustMap((payload) =>
        this.resetPasswordService.validateCode(payload).pipe(
          map(response => new ForgotPasswordActions.ValidateCodeSuccess({ response })),
          catchError(error => of(new ForgotPasswordActions.ValidateCodeError({ error })))
        )
      )
    )
  );

  verifyOptions$ = createEffect(()=>
    this.actions$.pipe(
      ofType<ForgotPasswordActions.VerifyOptions>(ForgotPasswordActions.ForgotPasswordActionTypes.VerifyOptions),
      map(action => action.payload),
      exhaustMap((params) =>
        this.resetPasswordService.verifyOptions(params).pipe(
          map(response => new ForgotPasswordActions.VerifyOptionsSuccess({ response })),
          catchError(error => of(new ForgotPasswordActions.VerifyOptionsError({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private resetPasswordService: ResetPasswordService
  ) { }
}