import { Injectable } from '@angular/core';
import { CustomValidators } from '../validators/custom-validators';
import { TravelerSearchListProgram, TravelersSearchListConfig } from 'app/flights/models/travelers-search-list.interface';
import { availableToolkeys } from 'app/flights/models/available-tools';
import { User } from 'app/auth/models/user';
import moment from 'moment';

const statusOpen = 'Open';
const statusCancelled = 'Cancelled';
const statusCheckin = 'Checkin';
const statusModify = 'Modify';
const statusFlown = 'Flown';
const statusRefund = 'Refund';
const statusExchange = 'Exchange';
const FLIGHT_TOOLS = [
  availableToolkeys.ticketing,
  availableToolkeys.listing,
  availableToolkeys.request,
  availableToolkeys.research,
  availableToolkeys.check_loads,
  availableToolkeys.ticketing_invited
];
const FLIGHTS_VALID_STATUS_CODE = ['A', 'R', 'L', 'F'];
@Injectable({
  providedIn: 'root'
})
export class FlightHelper {


  static get statusOpen(): string {
    return statusOpen;
  }

  static get statusCancelled(): string {
    return statusCancelled;
  }

  static get statusCheckin(): string {
    return statusCheckin;
  }

  static get statusModify(): string {
    return statusModify;
  }

  static get statusFlown(): string {
    return statusFlown;
  }

  static get statusRefund(): string {
    return statusRefund;
  }

  static get statusExchange(): string {
    return statusExchange;
  }

  static get validStatusCodes(): string[] {
    return FLIGHTS_VALID_STATUS_CODE;
  }

  static getBadgeClass(status) {
    let result = 'bg-light text-dark';
    switch (status) {
      case FlightHelper.statusOpen:
        result = 'badge-success';
        break;
      case FlightHelper.statusFlown:
      case FlightHelper.statusCheckin:
        result = 'badge-light';
        break;
      case FlightHelper.statusRefund:
      case FlightHelper.statusCancelled:
        result = 'badge-warning';
        break;
      case FlightHelper.statusExchange:
      case FlightHelper.statusModify:
        result = 'badge-pending';
        break;
    }
    return result;
  }

  getSpecialPassengerFormConfig(parameters, remaining) {
    const validators = {
      firstName: [CustomValidators.textName, CustomValidators.requiredTrue, CustomValidators.textNotOnlyBlankSpaces],
      middleName: [CustomValidators.textName],
      lastName: [CustomValidators.textName, CustomValidators.requiredTrue, CustomValidators.textNotOnlyBlankSpaces],
    };
    const display = {
      firstName: true,
      lastName: true,
      middleName: true,
      relationshipId: 'hidden',
      passengerType: 'hidden',
      specialPassengerType: 'hidden',
    };
    const initValues = {
      relationshipId: parameters.relationshipId,
      passengerType: parameters.passengerType,
      specialPassengerType: parameters.specialPassengerType,
      count: parameters.count,
      firstName: '',
      middleName: '',
      lastName: ''
    };
    const program: TravelerSearchListProgram = {
      remaining,
      show: parameters.show,
      confirmedDiscountShow: parameters.confirmedDiscountShow,
      confirmedDiscountAlwaysShow: parameters.confirmedDiscountAlwaysShow,
      programTitle: parameters.description,
      relationshipId: parameters.relationshipId,
      passengerType: parameters.passengerType,
      configs: { validators, display, initValues } as TravelersSearchListConfig
    };
    return { program, configs: { validators, display, initValues } };
  }

  hasAnyFlightsTools(user) {
    return FLIGHT_TOOLS.some(t => user.tools.includes(t));
  }

  static isToolInFlightsTools(tools: string[]) {
    return FLIGHT_TOOLS.some(t => tools.includes(t));
  }

  static canViewFlights(user: User) {
    const hasListing = (user?.tools ?? []).includes('listing');
    return (FlightHelper.isToolInFlightsTools(user.tools) &&
      (FlightHelper.validStatusCodes.includes(user.account?.employmentType) || user.account.airlineCode === '100')) ||
      hasListing;
  }

  static parseMinutes(min: number) {
    const hours = Math.floor(min / 60);
    const minutes = min % 60;
    return `${hours}h ${minutes}min`;
  }

  static formatDate(inputStr: string, format = 'YYYY-MM-DD'): string {
    return inputStr ? moment(inputStr).format(format) : '';
  }

  static getAirlineCode(segment) {
    return segment.airlineCode ? segment.airlineCode : segment.transportingCarrier;
  }

  public calculateSegmentDayDifference(departureDate: string, arrivalDate: string): number {
    const departureDateM = moment(departureDate);
    const arrivalDateM = moment(arrivalDate);
    const dayDifference = arrivalDateM.diff(departureDateM, 'days');

    return dayDifference;
  }

  static parseTotalTravelTime(totTravelTime: string | number): string {
    if (typeof totTravelTime === 'number') {
      return FlightHelper.parseMinutes(totTravelTime);
    } else {
      const flightTimeParts = totTravelTime.split(':');
      const minutes = flightTimeParts[1].split('.');
      return `${flightTimeParts[0]}h ${minutes[0]}m`;
    }
  }
}
