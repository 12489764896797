export class Address {
  street: String;
  city: String;
  country: String;
  state: String;
  zipCode: String;

  constructor(street?: string, city?: string, country?: string, state?: string, zip?: string) {
    this.street = street;
    this.city = city;
    this.country = country;
    this.state = state;
    this.zipCode = zip;
  }

  isValid() {
    return this.street !== '' &&
    this.zipCode !== '';
  }

  toJson() {
    return {
      address: this.street,
      city: this.city,
      country: this.country,
      zip: this.zipCode,
      state: this.state
    };
  }
}
