import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { availableToolkeys } from 'app/flights/models/available-tools';

@Injectable({
  providedIn: 'root'
})
export class ReservationsService {

  constructor (private http: HttpClient) {}

  getReservations(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/profile/reservations.json`, {});
  }

  getHotelReservations(type, pageNumber): Observable<any> {
    let params = this.getPaginationParams(type, pageNumber, '', 'true', 'false');
    if (type === 'future') {
      params = params.set('segment', 'future');
    }
    return this.http.get(`${environment.apiUrl}/api/v2/hotels/reservations.json`, { params });
  }

  getSharedHotelReservations(): Observable<any> {
    const params = new HttpParams().set('only_shared_itineraries', 'true');
    return this.http.get(`${environment.apiUrl}/api/v2/hotels/reservations.json`, { params });
  }

  getFlightReservations(type, pageNumber, pnr = '', invitedTravelerId = null, eligibleTravelersEnabled): Observable<any> {
    let params = this.getPaginationParams(type, pageNumber, pnr, null, 'false', eligibleTravelersEnabled.toString());
    if (invitedTravelerId) {
      params = params.append('invited_traveler_id', invitedTravelerId);
    }
    return this.http.get(`${environment.apiUrl}/api/v2/flights/reservations_v2.json`, { params });
  }

  getCarsReservations(type, pageNumber): Observable<any> {
    const params = this.getPaginationParams(type, pageNumber, null, null, 'true', 'false');
    return this.http.get(`${environment.apiUrl}/api/v2/cars_reservations.json`, { params });
  }

  getInsuranceReservations(type, pageNumber): Observable<any> {
    const params = new HttpParams().set('page_size', environment.tripsPageSize.toString())
      .set('page_number', pageNumber)
      .set('type', type);
    return this.http.get(`${environment.apiUrl}/api/v1/insurance_reservations.json`, { params });
  }

  getFlightReservation(toolKey, itineraryId, history = false, invitedTravelerId = null): Observable<any> {
    let params = new HttpParams();
    if (history) {
      params = params.append('history', 'true');
    }
    if (invitedTravelerId) {
      params = params.append('invited_traveler_id', invitedTravelerId);
    }
    return this.http.get(`${environment.apiUrl}/profile/reservations/flights/${toolKey}/${itineraryId}.json`, { params });
  }

  cancelSegments(toolKey, params): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: params
    };
    return this.http.delete(`${environment.apiUrl}/profile/reservations/flights/${toolKey}.json`, options);
  }

  getBooking(toolKey, bookingId, invitedTravelerId = null): Observable<any> {
    const params = {};
    if (invitedTravelerId) {
      params['invited_traveler_id'] = invitedTravelerId;
    }
    return this.http.get(`${environment.apiUrl}/flights/${toolKey}/bookings/${bookingId}.json`, { params });
  }

  modifyReservation(toolKey, itineraryId, pnr, params = {}): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/profile/reservations/flights/${toolKey}/${itineraryId}/pnr/${pnr}/start_edit.json`,
      params
    );
  }

  modifyListingReservation (toolKey, listingId, listingHash): Observable<any> {
    const params = {
      listing_id: listingId,
      listing_hash: listingHash,
      commit: 'modify'
    };
    return this.http.get(`${environment.apiUrl}/flights/${toolKey}/bookings/${listingId}/edit.json`, { params });
  }

  cancelListingReservation(toolKey, bookingId): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/flights/${toolKey}/bookings/${bookingId}.json`);
  }

  getPaginationParams(type, pageNumber, pnr = '', ignoreSharedItineraries = 'false', showAll = 'false', eligibleTravelersEnabled = 'false') {
    const params = new HttpParams().set('page_size', environment.tripsPageSize.toString())
      .set('page_number', pageNumber)
      .set(type, 'true')
      .set('pnr', pnr)
      .set('ignore_shared_itineraries', ignoreSharedItineraries)
      .set('show_all', showAll)
      .set('eligible_travelers_enabled', eligibleTravelersEnabled);
    return params;
  }
}
