import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { ProfileUserActions } from '../actions';
import { map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ProfileService } from 'app/shared/services/profile/profile.service';

@Injectable()
export class ProfileUserEffects {

  loadConfig$ = createEffect(()=>
    this.actions$.pipe(
      ofType<ProfileUserActions.LoadConfigProfile>(ProfileUserActions.ProfileUserActionTypes.LoadConfigProfile),
      switchMap(() =>  {
        return this.profileService.getProfileConfig().pipe(
          map(res => new ProfileUserActions.LoadConfigProfileSuccess({ config: res.config })),
          catchError(error => of(new ProfileUserActions.LoadConfigProfileFailure(error)))
        );
      })
    )
  );

  loadUserProfile$ = createEffect(()=>
    this.actions$.pipe(
      ofType<ProfileUserActions.LoadUserProfile>(ProfileUserActions.ProfileUserActionTypes.LoadUserProfile),
      switchMap(() =>  {
        return this.profileService.getProfileUser().pipe(
          map(res => new ProfileUserActions.LoadUserProfileSuccess({ info: res.employee })),
          catchError(error => of(new ProfileUserActions.LoadUserProfileFailure(error)))
        );
      })
    )
  );

  loadBusinessToggle$ = createEffect(()=>
    this.actions$.pipe(
      ofType<ProfileUserActions.LoadBusinessToggle>(ProfileUserActions.ProfileUserActionTypes.LoadBusinessToggle),
      switchMap(() =>  {
        return this.profileService.getProfileBusinessToggle().pipe(
          map(res => new ProfileUserActions.LoadBusinessToggleSuccess({ showBusinessToggle: res.show_business_toggle })),
          catchError(error => of(new ProfileUserActions.LoadBusinessToggleFailure(error)))
        );
      })
    )
  );

  loadUserProfileStructure$ = createEffect(()=>
    this.actions$.pipe(
      ofType<ProfileUserActions.LoadUserProfileStructure>(ProfileUserActions.ProfileUserActionTypes.LoadUserProfileStructure),
      switchMap(() =>  {
        return this.profileService.getProfileStructure().pipe(
          map(res => new ProfileUserActions.LoadUserProfileStructureSuccess({ brandUserStructure: res.brand_profile_structure })),
          catchError(error => of(new ProfileUserActions.LoadUserProfileStructureFailure(error)))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private profileService: ProfileService,
  ) {}
}
