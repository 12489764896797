import { environment } from '@env/environment';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Country } from '../../models/country.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<Country[]>(
      `${environment.apiUrl}/api/v1/countries.json`,
      { headers: { cacheable: 'true' }, params: { nanp: 'true' } }
    );
  }

  getByCode(code) {
    return this.getAll().pipe(map((countries) => {
      let find;
      countries.forEach((country) => {
        if (country.code === code) {
          find = country;
          return false;
        }
      });
      return find;
    }));
  }
}
