export enum MfaMediums {
  SMS = 'sms',
  PUSH = 'push',
  EMAIL = 'email'
}

export enum MfaActions {
  RESET_PWD = 'reset_pwd',
  MFA_NEW_DEVICE = 'mfa_new_device',
  MFA_PROFILE_UPDATE = 'mfa_profile_update',
  MFA_ENROLLMENT = 'mfa_enrollment'
}
