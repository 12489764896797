import { Component, Input, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import { times } from 'lodash';
import { faStar } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'rating',
  templateUrl: 'rating.html',
  styleUrls: ['rating.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RatingComponent implements OnChanges {
  @Input() value;
  @Input() whiteFill = false;
  @Input() maxStars = 5;
  @Input() starSize = 'normal';
  @Input() showTextFormat = false;

  numberOfStars: string[];
  percentWidth: number;
  icons = { faStar };

  ngOnChanges() {
    this.numberOfStars = times(this.maxStars, String);
    this.percentWidth = Math.round((this.value / this.maxStars) * 100);
  }
}
