<ngb-alert *ngIf="success" type="success" (closed)="closeAlert()">{{ promoCode }}: {{ 'profile.credits.successful_redeem' | translate }}</ngb-alert>
<ngb-alert *ngIf="error" type="danger" (closed)="closeAlert()">{{ error }}</ngb-alert>
<div class="d-sm-none back-button">
  <button type="button" class="btn btn-link" (click)="backToTabs()">
    <fa-icon [icon]="icons.faChevronLeft" aria-hidden="true"></fa-icon>
    {{ "back" | translate }}
  </button>
</div>
<div class="profile-title primary-content">
  {{ 'profile.info.sections.credits' | translate }}
</div>
<div class="card shadow-none mb-4">
  <div class="card-body hotel-credits-info text-center text-md-start">
    <div class="primary-content mb-3">{{ 'profile.credits.title' | translate }}</div>
    <div class="row mt-1">
      <div class="col-sm-12 col-md-2 mb-4 mt-1 d-flex justify-content-center align-items-center ">
        <fa-icon [icon]="icons.faTrophy" class="ico"></fa-icon>
      </div>
      <div class="col-sm-12 col-md-6 tertiary-content text-center">
        <span [innerHTML]="'profile.credits.description' | translate"></span>
        <member-progress-bar
        class="d-flex align-items-center flex-column w-100 mb-3 mt-3 padding"
        [nextTierReward]="nextTierReward"
        [nextLevel]="nextLevel"
        colorLevel="black"
        [nextTierLtv]="nextTierLtv"
        [nextTierGapLtv]="nextTierGapLtv"
        [currentLevel]="currentLevel"
        [currentTierLtv]="currentTierLtv"
        [showAwayFromNextLevel]="showAwayFromNextLevel"
        [showLevels]="showLevels"
        [size]='size'
        [nextTierCurrentLtv]="nextTierCurrentLtv"
        [currency]='userCredits.currency'>
      </member-progress-bar>

        <span class="py-2">
          {{ 'member_rewards.away_from_next_level' | translate: {nextTierGapLtv: nextTierGapLtv | currencyConvert:userCredits.currency, nextTierReward: nextTierReward | currencyConvert:userCredits.currency  } }}
        </span>
      </div>
      <div class="col-sm-12 col-md-4 mt-3 mt-md-0 text-center">
        <div class="row justify-content-center">
          <div class="col-md-auto secondary-color {{isMxnOrRub()}}">
            {{ userCredits.displayAmount | currency:userCredits.currency:'symbol-narrow':'1.2-2' }}
          </div>
        </div>
        <div class="row justify-content-center mt-3 mt-md-0"*ngIf="userCredits.expiration">
          <div class="col-md-auto tertiary-content">
            {{ allCreditsExpire ? ('profile.credits.credits_expires' | translate) : ('profile.credits.some_credits_expire' | translate) }}: {{ userCredits.expiration | amUtc | amDateFormat:'MMM DD, YYYY' }}
          </div>
        </div>
          <div class="d-lg-none row justify-content-center mt-3 mt-md-0" *ngIf="userCreditHistory.length">
            <div class="col-md-auto">
              <button class="btn btn-outline-primary rounded-pill" type="button" (click)="openCreditHistoryMobileModal()">
                {{ 'profile.credits.view_all_xs' | translate }}
              </button>
            </div>
          </div>
        <div class="row justify-content-center mt-1">
          <div class="col-md-auto primary-color credit-terms-and-conditions">
            <a target="_blank" routerLink="/invite/terms/20/10" fragment="creditsTermsTitle" class="gtm-invite small">
              {{ 'profile.credits.credits_tac' | translate }}
            </a><br>
            <a *ngIf="programDetailsLink" target="_blank" routerLink="{{ programDetailsLink }}" class="gtm-invite small">
              {{ 'profile.credits.program_details' | translate }}
            </a>
          </div>
        </div>
        <div class="row justify-content-center mt-1" *ngIf="!isUsd">
          <div class="col-md-auto estimate-usd-message my-3">
            {{ 'profile.credits.estimate_in_usd' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="card shadow-none mb-4" #reedemSection>
  <div class="card-body hotel-credits-redeem">
    <div class="primary-content">{{ 'profile.credits.redeem_credit_promo_title' | translate }}</div>
    <div class="tertiary-content col-md-8 ps-0">
      {{ 'profile.credits.credit_promo_code_info' | translate }}
    </div>
    <form [formGroup]="creditPromoCodeForm">
      <div class="form row align-items-center pt-md-4">
        <div class="col-12 col-md-7">
          <div class="form-group form-floating">
            <input type="text" formControlName="creditPromoCodeValue" id="creditPromoCodeValue" class="form-control with-background" [class.disabled]="loading" required (keyup.enter)="onRedeemCode()"/>
            <label for="creditPromoCodeValue">{{ 'profile.credits.credit_promo_code_input' | translate }}</label>
            <div
              class="error-message"
              *ngIf="creditPromoCodeValueFormControl.errors !== null && creditPromoCodeValueFormControl.touched"
              [innerHtml]="formErrorsHelper.errorMessage(creditPromoCodeValueFormControl, ('profile.credits.credit_promo_code_input' | translate))">
            </div>
          </div>
        </div>
        <div class="col-12 col-md-5 ">
          <div class="form-group">
            <button type="button" class="btn btn-primary w-100 gtm-redeem-credit-promo-code" [disabled]="loading" (click)="onRedeemCode()">
              <span *ngIf="!loading">{{ 'profile.credits.credit_promo_code_button' | translate }}</span>
              <span *ngIf="loading">
                <fa-icon [icon]="icons.faSpinner" class="rotation-animation fa-spin"></fa-icon>
              </span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="d-none d-lg-flex card shadow-none mb-4" *ngIf="userCreditHistory.length">
  <div class="card-body hotel-credits-redeem">
    <hotel-credit-history
      pageSize="6"
      [title]="'profile.credits.history_title' | translate"
      [userCreditHistory]="userCreditHistory"
      [enablePagination]="false"
    ></hotel-credit-history>

    <ng-container *ngIf="userCreditHistory.length > 6">
      <button class="btn btn-link float-end" type="button" (click)="onModalOpen(creditHistoryModal)">
        {{ 'profile.credits.view_all' | translate }} &raquo;
      </button>

      <!-- Desktop Modal -->
      <ng-template #creditHistoryModal let-modal>
            <div class="modal-header">
              <span class="modal-title primary-content">{{ 'profile.credits.history_modal_title' | translate }}</span>
              <button type="button" class="btn-close" aria-label="Close" (click)="onModalClose()"></button>
            </div>
            <div class="modal-body">
              <hotel-credit-history
                [userCreditHistory]="userCreditHistory"
                [title]="false"
                pageSize="10"
              ></hotel-credit-history>
            </div>
      </ng-template>
    </ng-container>
  </div>
</div>
