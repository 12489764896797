<ng-container *ngIf="showDefaultInsurance">
  <insurance-table class="tertiary-content" [tldr]="true"/>
</ng-container>

<div class='card shadow-none mt-3'>
  <div class="card-body" *ngIf="error">
    <div class="badge badge-pending text-wrap">
      {{ 'trip_insurance.unavailable' | translate }}
    </div>
  </div>
  <form [formGroup]="insuranceForm" *ngIf="!error && !insuranceDisabled && !hotelInsuranceDisabled && !isCashPayment">
    <div class='card-body' *ngIf="showDefaultInsurance">
      <div class='card-title d-flex'>
        <div class='pe-4'>
          <input class="gtm-add-insurance" (change)="toggleForm($event)" formControlName="insuranceSelection" type="checkbox" data-cy="insurance-check" >
        </div>
        <div class='payment-selection'>
          <h5 class='fw-normal mb-0'>
            {{ 'insurance.add' | translate }} {{ pricePerTraveler }} {{ 'insurance.per_person' | translate }}
          </h5>
          <div class="tertiary-content">
            I have read and understand the <a href="https://partner.travelexinsurance.com/policy/?plannumber=501ZPD-0924">policy</a>, which includes the full coverage term details,
            including exclusions and limitations, and the
            <simple-modal-link [inLine]="true" class="highlights"
            linkText="Protection Plan highlights"
            [content]="'trip_insurance.id90_landing_text' | translate"
            [config]="{ size: 'lg' }"></simple-modal-link>, which includes important consumer information,
            plan overview, and disclaimers. I affirm I am a resident of the United States (excluding Puerto Rico) or Canada.
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="insuranceInformation.purchaseInsurance || !showToggle" class='pt-0'>
      <div class='card-header'>
        {{ 'trip_insurance.trip_coverage_dates'  | translate }}
      </div>
      <div class='coverage-dates card-body'>
        <div class="form-group">
          <datepicker-range
            inputName="fromDate"
            [displayMonths]="2"
            [startLabel]="'lodging.hotels.checkin_label' | translate"
            [startDate]="insuranceForm.value.fromDate"
            [endLabel]="'lodging.hotels.checkout_label' | translate"
            [endDate]="insuranceForm.value.toDate"
            [maxDate]="maxDate"
            [singleDate]="false"
            [singleInput]="false"
            [showCalendarIcon]="true"
            [placeholderStart]="'lodging.hotels.checkin_label' | translate"
            [placeholderEnd]="'lodging.hotels.checkout_label' | translate"
            [showSeparator]="false"
            (datesSelected)="onCheckinDatesSelected($event)">
          </datepicker-range>
        </div>
      </div>
      <div *ngFor="let traveler of insuranceInformation.travelers; let i = index">
        <traveler #trv [travelers]="insuranceInformation.travelers" [traveler]="traveler" [index]="i" (removeTraveler)="removeTraveler($event)" (valid)="formValid($event)" ></traveler>
      </div>
      <div class="card-body pt-0">
        <div class='gtm-add-traveler clickable text-end' *ngIf="!passengers.length" (click)="addTraveler()" data-cy="addTraveler">
          <fa-icon [icon]="icons.faPlusCircle" class="primary-color me-2 gtm-add-traveler"></fa-icon> {{ 'action_terms.add_more_travelers'  | translate }}
        </div>
        <hr *ngIf="showInsuranceTotal">
        <div class='primary-content' *ngIf="showInsuranceTotal">{{ 'insurance.label_total' | translate }}
          <div class='float-end'>{{ insuranceTotal }}</div>
        </div>
      </div>
    </div>
  </form>
</div>
