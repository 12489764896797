import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'story-blok-render',
  templateUrl: './story-blok-render.component.html',
  styleUrls: ['./story-blok-render.component.scss']
})
export class StoryBlokRenderComponent {
  page: string;
  folder: string;
  notFound = false;
  private ngUnsubscribe = new Subject<void>();

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    combineLatest([this.route.paramMap, this.route.data])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([parameters, data]) => {
        this.folder = data.folder;
        this.page = parameters.get('page');
        this.notFound = false;
      }
      );
  }
  noContent() {
    this.notFound = true;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
