import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';

@Component({
  selector: 'event-registration-airline-form',
  templateUrl: './event-registration-airline-form.html'
})
export class EventRegistrationAirlineFormComponent {
  @Input() errorMessage: string | null;
  @Input() airlines = [];
  @Output() submitted = new EventEmitter();

  selectedAirline;
  airlineForm: UntypedFormGroup = new UntypedFormGroup({
    airline: new UntypedFormControl('', Validators.required),
    campaign: new UntypedFormControl('', Validators.required),
  });

  constructor(
    public formErrorsHelper: FormErrorsHelper,
    public translate: TranslateService
  ) { }

  submit() {
    if (this.isValid()) {
      this.submitted.emit({
        airline: this.selectedAirline,
        campaign: this.airlineForm.value.campaign
      });
    } else {
      Object.keys(this.airlineForm.controls).forEach((field) => {
        const control = this.airlineForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }
  get airline() { return this.airlineForm.get('airline'); }
  get campaign() { return this.airlineForm.get('campaign'); }

  isValid() {
    return this.airlineForm.valid;
  }
  closeAlert() {
    this.errorMessage = null;
  }

  setAirline(airline) {
    this.selectedAirline = airline;
  }
}
