import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CarTrawlerHelper {
  clientsId = {
    navBar: {
      clientId: 533939,
      internalCarTrawlerName: 'ID90_HomepageNavBar_Mobility'
    },
    carSearchLink: {
      clientId: 533939,
      internalCarTrawlerName: 'ID90_HomepageSearchBox_Mobility'
    },
    airportTransfersPopup: {
      clientId: 551228,
      internalCarTrawlerName: 'ID90_Mobility_HotelPNR'
    },
    itineraryLink: {
      clientId: 536982,
      internalCartrawlerName: 'ID90_Mobility_HotelMMB'
    },
    allInclusive: {
      clientId: 537271,
      internalCartrawlerName: 'ID90_Mobility_AIPNR'
    }
  };

  constructor() { }

  getClientId(path: string): number {
    return this.clientsId[path].clientId;
  }


}