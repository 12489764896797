import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Review } from 'app/hotels/models/review';
import { FeatureFlagsService } from 'app/shared/services/featureFlags/featureFlags.service';

@Injectable({
  providedIn: 'root'
})
export class HotelReviewsService {

  constructor (
    private http: HttpClient,
    private featureFlagsService: FeatureFlagsService,
  ) {}

  getReviews(hotelId, params) {
    let version = 'v2';
    if (this.featureFlagsService.isFeatureEnabled('hotel_reviews_v3')) {
      version = 'v3';
    }
    return this.http.get<Review>(`${environment.apiUrl}/api/${version}/hotel/reviews?id=${hotelId}`, { params });
  }

  getMemberReview(hotelId) {
    return this.http.get(`${environment.apiUrl}/api/v1/hotels/reviews/review_by_member/${hotelId}.json` , {});
  }

  post(params) {
    return this.http.post(`${environment.apiUrl}/api/v1/hotels/reviews.json`, params);
  }
}
