import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { AccountValidationActions, AuthApiActions } from '../actions';
import { exhaustMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AuthenticationService } from '../../services/authentication.service';
import { SessionService } from 'app/shared/services/session/session.service';

@Injectable()
export class AccountValidationEffects {

  validate$ = createEffect(()=>
    this.actions$.pipe(
      ofType<AccountValidationActions.Validate>(AccountValidationActions.AccountValidationActionTypes.Validate),
      map(action => action.payload.credentials),
      exhaustMap((credentials: any) =>
        this.authService.validateAccount(credentials).pipe(
          map(data => {
            this.sessionService.setAccessToken(data.response.access_token);
            return new AuthApiActions.ReloadUser({ accessToken: data.response.access_token });
          }),
          catchError(error => of(new AccountValidationActions.ValidateError({ error })))
        )
      )
    )
  );

  validateUser$ = createEffect(()=>
    this.actions$.pipe(
      ofType<AccountValidationActions.ValidateUser>(AccountValidationActions.AccountValidationActionTypes.ValidateUser),
      map(action => action.payload.credentials),
      exhaustMap((credentials: any) =>
        this.authService.verifyUser(credentials).pipe(
          map(response => new AccountValidationActions.ValidateUserSuccess({ response })),
          catchError(error => of(new AccountValidationActions.ValidateUserError({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private authService: AuthenticationService,
    private sessionService: SessionService
  ) { }
}
