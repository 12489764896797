import { Component, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'terms-and-conditions',
  styleUrls: ['./terms-and-conditions.scss'],
  templateUrl: './terms-and-conditions.html'
})

export class TermsAndConditionsPage implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute) { }
  isPdf = false;
  content: string = '';
  urlSafe: SafeResourceUrl;
  failStoryBlok: boolean = false;
  folder = null;
  page = null;
  loading = false;
  keys = {
    terms_and_conditions: 'nav.tac.id90travel',
    privacy_policy: 'nav.privacy_policy.id90travel',
    data_policy_framework: 'nav.data_policy_framework.id90travel',
    privacy_shield: 'nav.privacy_shield.id90travel',
    gamification: 'gamification.terms_condition'
  };
  private ngUnsubscribe = new Subject<void>();

  ngOnInit(): void {
    combineLatest([this.activatedRoute.paramMap, this.activatedRoute.data])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([parameters, data]) => {
        this.folder = data.folder;
        this.page = parameters.get('page');
        this.loading = false;
      }
      );
  }


  noContent() {
    this.content = this.keys[this.page];
    this.failStoryBlok = true;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
