import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';
import { ProfileService } from 'app/shared/services/profile/profile.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'delete-profile-modal',
  templateUrl: './delete-profile-modal.html'
})

export class DeleteProfileModal implements OnInit {
  @Input() memberId;
  passwordShown = false;
  deleteProfileForm: UntypedFormGroup;
  loading = false;
  error = '';

  constructor(public activeModal: NgbActiveModal,
              private formBuilder: UntypedFormBuilder,
              public formErrorsHelper: FormErrorsHelper,
              private profileService: ProfileService) { }

  ngOnInit() {
    this.deleteProfileForm = this.formBuilder.group({
      password: ['', Validators.required]
    });
  }

  submit() {
    if (this.isValid()) {
      this.loading = true;
      this.error = '';
      const password = this.deleteProfileForm.value;
      this.profileService.deleteProfile(password).pipe(take(1)).subscribe(
        () => {
          this.loading = false;
          this.activeModal.close(true);
        },
        (error) => {
          this.error = error.error.error_description;
          this.loading = false;
        }
      );
    } else {
      Object.keys(this.deleteProfileForm.controls).forEach((field) => {
        const control = this.deleteProfileForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  isValid() {
    return this.deleteProfileForm.valid;
  }

  closeAlert() {
    this.error = '';
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  showPassword() {
    this.passwordShown = true;
  }

  get password()   { return this.deleteProfileForm.get('password'); }
  get passwordError() {
    return this.formErrorsHelper.errorMessage(
      this.password as UntypedFormControl,
      'Password'
    );
  }
}
