import { AirlineActions } from '../actions';

export const initialState: any = {
  error: null,
  pending: false,
  airlinesData: [],
  airlinesPricingInCRS: []
};

export function reducer(
  state = initialState,
  action:
    | AirlineActions.AirlineActionsUnion
) {
  switch (action.type) {
    case AirlineActions.AirlineActionTypes.LoadAirlines:
      return {
        ...state,
        pending: true
      };
    case AirlineActions.AirlineActionTypes.LoadAirlinesSuccess:
      return {
        ...state,
        pending: false,
        airlinesData: action.payload.airlines,
        error: initialState.error
      };
    case AirlineActions.AirlineActionTypes.LoadAirlinesFailure:
      return {
        ...state,
        pending: false,
        error: action.payload.error
      };
    case AirlineActions.AirlineActionTypes.LoadAirlinesPricingInCRS:
      return {
        ...state,
        pending: true
      };
    case AirlineActions.AirlineActionTypes.LoadAirlinesPricingInCRSSuccess:
      return {
        ...state,
        pending: false,
        airlinesPricingInCRS: action.payload.airlinesPricingInCRS,
        error: initialState.error
      };
    case AirlineActions.AirlineActionTypes.LoadAirlinesPricingInCRSFailure:
      return {
        ...state,
        pending: false,
        error: action.payload.error
      };
    default: {
      return state;
    }
  }
}

export const getError = (state: any) => state.error;
export const getPending = (state: any) => state.pending;
export const getAirlines = (state: any) => state.airlinesData;
