import { Pipe, PipeTransform } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DEMAND_CALENDAR_DEMAND_CIRCLE_CLASSES } from 'app/hotels/constants/demand-calendar.const';
import { DemandCalendar } from 'app/hotels/models/demand-calendar';

@Pipe({
  name: 'demandCalendar'
})
export class DemandCalendarPipe implements PipeTransform {
  constructor(private ngbDateParserFormatter: NgbDateParserFormatter) { }

  transform(date: NgbDateStruct, demandCalendar: DemandCalendar[]): unknown {
    if (!date || !demandCalendar) { return DEMAND_CALENDAR_DEMAND_CIRCLE_CLASSES.None }

    const formattedDate = this.ngbDateParserFormatter.format(date);
    const demandDate = demandCalendar.find((date) => date.check_in == formattedDate);
    return demandDate ? DEMAND_CALENDAR_DEMAND_CIRCLE_CLASSES[demandDate.demand] : DEMAND_CALENDAR_DEMAND_CIRCLE_CLASSES.None;
  }

}
