import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NeedARoomService } from 'app/shared/services/need-a-room/need-a-room.service';
import { isEmpty } from 'lodash';
import { MemberService } from 'app/shared/services/member/member.service';
import { AbandonedHotel } from 'app/shared/models/abandoned-hotel';
import { UserCredits } from 'app/profile/models/user-credits.interface';
import { take } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { featureFlags } from 'app/shared/models/featureFlags';
import { FeatureFlagsService } from 'app/shared/services/featureFlags/featureFlags.service';
import * as fromShared from 'app/shared/store/reducers';

@Component({
  selector: 'just-for-you',
  templateUrl: './just-for-you.html'
})

export class JustForYouComponent implements OnInit {
  @Input() userStatistics: any = {};
  @Input() userCredits: UserCredits = null;
  @Input() abandonedHotel: AbandonedHotel;

  showSection: boolean = false;
  needARoomData: any;
  showInviteAFriendCard: boolean = false;
  promoData: any;
  showStaticCards = false;
  featureFlags = featureFlags;
  appConfig$ = this.store.pipe(select(fromShared.getConfigFlag));

  constructor(
    private featureFlagsService: FeatureFlagsService,
    private memberService: MemberService,
    private needARoomService: NeedARoomService,
    private router: Router,
    private store: Store,
  ) { }

  ngOnInit() {
    this.showStaticCards = this.featureFlagsService.isFeatureEnabled(featureFlags.STATIC_CARD);
    this.initInviteFriend();
    this.initNeedARoom();
    this.initPromo();
    this.shouldShowSection();
  }

  navigateTo(params) {
    return this.router.navigate([params.path, params.data]);
  }

  initInviteFriend() {
    const userStats = this.userStatistics;
    if (!isEmpty(userStats) && userStats.allowed_to_invite && userStats.total_invited_users === 0) {
      this.showInviteAFriendCard = true;
    } else {
      this.showInviteAFriendCard = false;
    }
  }

  initNeedARoom() {
    this.needARoomService.getCardContent().pipe(take(1)).subscribe((response) => {
      if (Object.keys(response).length > 0) {
        this.needARoomData = response;
      }
    });
  }

  initPromo() {
    this.memberService.getMemberPromotions().pipe(take(1)).subscribe((response) => {
      if (response) {
        this.promoData = response[0];
      }
    });
  }

  shouldShowSection() {
    if (
      this.featureFlagsService.isFeatureEnabled(featureFlags.JUST_FOR_YOU) &&
      (
        (this.showInviteAFriendCard && this.featureFlagsService.isFeatureEnabled(featureFlags.INVITE)) ||
        this.needARoomData != null ||
        this.promoData != null ||
        this.abandonedHotel != null ||
        ( this.userCredits && this.userCredits.creditUSD )
      )
    ) {
      this.showSection = true;
      return;
    }
    this.showSection = false;
  }

}
