<div class="card card-review">
  <div class="card-body">
    <span>
      <fa-icon class="star-icon pe-1" [icon]="icons.faStar" aria-hidden="true"></fa-icon>
      <fa-icon class="star-icon pe-1" [icon]="icons.faStar" aria-hidden="true"></fa-icon>
      <fa-icon class="star-icon pe-1" [icon]="icons.faStar" aria-hidden="true"></fa-icon>
      <fa-icon class="star-icon pe-1" [icon]="icons.faStar" aria-hidden="true"></fa-icon>
      <fa-icon class="star-icon pe-1" [icon]="icons.faStar" aria-hidden="true"></fa-icon>
    </span>
    <div class="card-title pt-2"> "{{ cardTitle }}" </div>
    <p class="card-text secondary-content"> {{ cardText }}</p>
    <hr>
    <span class="card-text tertiary-content author"> {{ cardAuthor }} </span>
  </div>
</div>
