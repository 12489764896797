<div class="container mb-2">
  {{'gamification.sweepstakes.content' | translate: { airline: airlineName } }}
</div>
<div class="row d-flex">
  <div class="col-lg-6 col-sm-12 col-md-12" *ngIf="statistics">
    <div class="card shadow-none mb-4">
      <div class="w-100">
        <div class="text-center">
          <img class="airline-logo-header mt-2" src="angular_assets/images/flights/partners/{{airlineCode}}_header_logo.png" (error)="'angular_assets/images/color-logo.png'">
        </div>
        <h4 class="modal-title text-center" >{{ 'gamification.hotel_booking_challenge.title' | translate }}</h4>
      </div>
      <div class="modal-body mb-2">
        <div class="text-center">
          <img src="angular_assets/images/hotels/hotel_gamification.svg" width="60%">
          <div class="mt-3 row">
            <div class="col-12">
              <span class="blue-text">{{statistics.value}} / {{statistics?.metadata?.target || '0'}}</span>
            </div>
            <div class="col-12">
              <span class="night-booked w-100 text-center">
                {{ 'gamification.sweepstakes.nights_booked' | translate }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-6 col-sm-12 col-md-12">
    <div class="card shadow-none mb-4">
      <div class="card-body">
        <ng-container *ngIf="goalIsMet || activeCampaign?.finished; else freeEntry">
          <span [innerHTML]="'gamification.hotel_booking_challenge.goal_met' | translate: { airline_name: airlineName }"></span>
        </ng-container>
        <ng-template #freeEntry>
          <button *ngIf="!activeCampaign.user_participating" type="button" (click)="participate()" class="btn btn-primary w-100">
            {{ 'gamification.participate' | translate }}
          </button>
          <button *ngIf="activeCampaign.user_participating" type="button" [disabled]="!isFreePlayAvailable"
                  (click)="clickHandler()" class="btn btn-primary w-100">
            {{ 'gamification.daily_free_entry' | translate }}
          </button>
          <div *ngIf="activeCampaign.user_participating && !isFreePlayAvailable" class="text-danger text-start mt-1">
            * {{ 'gamification.check_back_tomorrow' | translate }}
          </div>
          <div class="secondary-content mt-2" *ngIf="activeCampaign.user_participating">
            <a routerLink="/navigation/gamification" target="_blank">{{'gamification.sweepstakes.terms_condition' | translate }}</a>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
