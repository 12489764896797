import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { EventRegistrationActions } from 'app/auth/store/actions';
import * as fromEventRegistration from 'app/auth/store/reducers';
import * as AirlineActions from 'app/shared/store/actions/airlines.actions';
import * as fromAirlines from 'app/shared/store/reducers';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'airline-select',
  templateUrl: './airline-select.html'
})

export class AirlineSelectPage implements OnInit, OnDestroy {
  airlineSet$ = this.store.pipe(select(fromEventRegistration.getEventAirline));
  airlines$: any = this.store.pipe(select(fromAirlines.getAirlines));
  airlines = [];
  private airlinesUnsubscribe$ = new Subject<void>();
  private ngUnsubscribe$ = new Subject<void>();

  constructor(
    private store: Store<fromEventRegistration.State>,
    private router: Router
  ) { }

  ngOnInit() {
    this.airlineSet$.pipe(
      takeUntil(this.ngUnsubscribe$)
    ).subscribe((airline) => {
      if (airline !== '') {
        return this.router.navigate(['event-registration/new']);
      }
    });

    this.airlines$.pipe(takeUntil(this.airlinesUnsubscribe$)).subscribe((airlines) => {
      if (!airlines.length) {
        this.store.dispatch(new AirlineActions.LoadAirlines());
      } else {
        this.airlines = this.filterPartnerAirlines(airlines);
        this.airlinesUnsubscribe$.next();
        this.airlinesUnsubscribe$.complete();
      }
    });
  }

  filterPartnerAirlines(airlines): [] {
    if (!airlines.length) {
      return [];
    }

    return airlines.filter(airline => airline.partner !== true);
  }

  setAirline(eventData) {
    this.store.dispatch(new EventRegistrationActions.AirlineSet({
      airline: eventData.airline,
      campaign: eventData.campaign
    }));
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
