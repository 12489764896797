import { Action } from '@ngrx/store';


export enum HotelFilterActionTypes {
  Save = '[Hotel Filter] Save Filters',
  Clear = '[Hotel Filter] Clear',
  MarkState = '[Hotel Filter] Mark State',
  ChangeDateSuccess = '[Hotel Filter] ChangeDateSuccess'
}

export class Save implements Action {
  readonly type = HotelFilterActionTypes.Save;

  constructor(public payload: any) { }
}

export class Clear implements Action {
  readonly type = HotelFilterActionTypes.Clear;

  constructor() {}
}
export class MarkState implements Action {
  readonly type = HotelFilterActionTypes.MarkState;

  constructor(public payload: any) { }
}
export class ChangeDateSuccess implements Action {
  readonly type = HotelFilterActionTypes.ChangeDateSuccess;

  constructor(public payload: any) { }

}

export type HotelFilterActionsUnion = Save | Clear | MarkState;
