import { CountryActions } from 'app/shared/store/actions';
import { Country } from 'app/shared/models/country.model';

export interface State {
  countries: Country[] | null;
  error: any | null;
  pending: boolean;
  loaded: boolean;
}

export const initialState: State = {
  countries: [],
  pending: false,
  error: null,
  loaded: false
};

export function reducer(
  state = initialState,
  action: CountryActions.CountryActionsUnion
): State {
  switch (action.type) {
    case CountryActions.CountryActionTypes.LoadCountries:
      return {
        ...state,
        pending: true
      };
    case CountryActions.CountryActionTypes.LoadCountriesSuccess:
      return {
        countries: action.payload.countries,
        error: null,
        pending: false,
        loaded: true
      };
    case CountryActions.CountryActionTypes.LoadCountriesFailure:
      return {
        countries: [],
        error: action.payload.error,
        pending: false,
        loaded: false
      };
    default:
      return state;
  }
}

export const getCountries = (state: State) => state.countries;
export const getPending = (state: State) => state.pending;
export const getLoaded = (state: State) => state.loaded;
export const getError   = (state: State) => state.error;
