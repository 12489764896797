import { Component, Input } from '@angular/core'

@Component({
  selector: 'error-component',
  templateUrl: 'error-component.html',
  styleUrls: ['./error-component.scss']
})
export class ErrorComponent {
  @Input() content : string = '';
}
