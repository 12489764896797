import { Component, OnDestroy, OnInit } from '@angular/core';
import { faCheck, faSpinner, faSync, faTimes } from '@fortawesome/free-solid-svg-icons';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EventRegistrationActions, LogoutComponentActions } from '../../store/actions';
import * as fromEventRegistration from '../../store/reducers';

@Component({
  selector: 'event-registration',
  templateUrl: './event-registration.html',
  styleUrls: ['./event-registration.scss']
})

export class EventRegistrationPage implements OnInit, OnDestroy {
  registerSuccess$ = this.store.pipe(select(fromEventRegistration.getEventRegistrationSuccess));
  marketingLoggedIn$ = this.store.pipe(select(fromEventRegistration.getLoggedIn));
  pending$ = this.store.pipe(select(fromEventRegistration.getEventRegistrationPending));
  airline$ = this.store.pipe(select(fromEventRegistration.getEventAirline));
  campaign$ = this.store.pipe(select(fromEventRegistration.getEventCampaign));
  error$ = this.store.pipe(select(fromEventRegistration.getEventRegistrationError));
  registrations$ = this.store.pipe(select(fromEventRegistration.getRegistrations));
  registrations = { pending: [], failed: [], completed: [] };
  success = false;
  errorMessage = '';
  syncRunning:boolean = false;
  autoSync: any;
  syncInProgress: boolean = false;
  showForm:boolean = true;
  icons = { faSpinner, faSync, faTimes, faCheck };

  private ngUnsubscribe = new Subject<void>();

  constructor(
    private store: Store<fromEventRegistration.State>,
  ) { }

  ngOnInit() {
    this.registrations$.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((registrations) => {
      this.registrations = registrations;
      if (this.registrations.pending.length > 0) {
        this.startSync();
      } else {
        this.stopSync();
      }
    });

    this.error$.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((error) => {
      this.errorMessage = error;
    });

    this.registerSuccess$.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((success) => {
      if (success) {
        this.success = true;
        setTimeout(() => this.success = false, 4000);
      }
    });

    this.marketingLoggedIn$.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((loggedIn) => {
      if (!loggedIn) {
        this.store.dispatch(new LogoutComponentActions.MarketingLogout());
      }
    });
  }

  createAccount(credentials) {
    this.store.dispatch(new EventRegistrationActions.OfflineMemberRegister({ credentials }));
  }

  logout() {
    this.store.dispatch(new LogoutComponentActions.MarketingLogout());
  }

  startSync() {
    if (this.autoSync) return;
    this.autoSync = setInterval(() => { this.sync(); }, 300000);
  }

  stopSync() {
    clearInterval(this.autoSync);
    this.autoSync = null;
  }

  sync() {
    if (this.syncInProgress) return;
    this.syncInProgress = true;
    this.registrations.pending.forEach((credentials) => {
      this.store.dispatch(new EventRegistrationActions.MemberRegisterSync({ credentials }));
    });
    setTimeout(() => { this.syncInProgress = false; }, 2000);
  }

  dash() {
    this.showForm = !this.showForm;
  }

  retry(auth) {
    this.store.dispatch(new EventRegistrationActions.MemberRegisterRetry({ auth }));
  }

  remove(auth) {
    this.store.dispatch(new EventRegistrationActions.MemberRegisterRemove({ auth }));
  }

  clearStatus() {
    this.store.dispatch(new EventRegistrationActions.MemberRegisterClear());
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
