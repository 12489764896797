import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import { StealthCreditsActions } from '../store/actions';
import {HotelHelper} from '../../shared/helpers/hotel.helper';

@Injectable({ providedIn: 'root' })
export class StealthCreditsHelper {

  private stealthCreditsFeatureFlag: boolean = false;

  constructor(private store: Store<fromRoot.State>, private hotelHelper: HotelHelper) {}

  isFlagOn(flag) {
    this.stealthCreditsFeatureFlag = flag && flag.stealth_credits_enabled == 1;
    return this.stealthCreditsFeatureFlag;
  }

  canCreditsBeApplied(userCredits, state) {
    return this.stealthCreditsFeatureFlag && userCredits.displayAmount > 0 && state.applyCredits;
  }

  stealthCreditsToggleEnabled(userCredits) {
    return this.stealthCreditsFeatureFlag && userCredits.displayAmount > 0;
  }

  applyCredits(hotelOrRoom, creditInfo) {
    if (this.stealthCreditsFeatureFlag && hotelOrRoom?.length > 0 && creditInfo.displayAmount > 0) {
      let rateApplicableForCredit = false;
      hotelOrRoom.map((data: any) => {
        let id;
        if (data.room_id) {
          id = data.room_id;
        } else {
          id = data.id;
        }

        if (this.canCreditsBeAppliedToRate(id)) {
          rateApplicableForCredit = true;
          const creditsApplicablePerNight = (creditInfo.displayAmount / data.nights) / data.number_of_rooms;
          const newRate = data.display_rate - creditsApplicablePerNight;
          data.original_display_rate = data.display_rate;
          data.display_rate = newRate > 0 ? newRate : 0;

          data.original_savings_percent = data.savings_percent;
          data.savings_percent = -1 * this.hotelHelper.percentOff(data.display_rate, data.retail_rate);

          // only do it on results page to show total on hotel card
          if(!data.hasOwnProperty('room_id')) {
            // TOTAL_COST_CALCULATION
            data.original_total =  data.total;
            const newTotal = data.original_total - creditInfo.displayAmount;
            const fees = data.total_cost - data.total;
            const newTotalCost = newTotal + fees;
            data.total = newTotal > 0 ? newTotal : 0;
            data.total_cost = newTotalCost > 0 ? newTotalCost : 0;
          }

        }
      });
      if (!rateApplicableForCredit) {
        this.store.dispatch(new StealthCreditsActions.Remove());
      }
    }
  }

  removeCredits(hotelOrRoom) {
    if (this.stealthCreditsFeatureFlag && hotelOrRoom.length > 0) {
      hotelOrRoom.map((data: any) => {
        if (data.original_display_rate) {
          data.display_rate = data.original_display_rate;
        }
        if(data.original_total) {
          data.total = data.original_total;
        }
      });
    }
  }

  // get the total credits applied to show in the summary
  getCreditsAppliedTotal(room, creditInfo) {
    if (this.stealthCreditsFeatureFlag && creditInfo.displayAmount > 0 && this.canCreditsBeAppliedToRate(room.room_id)) {
      if (room.total <= creditInfo.displayAmount) {
        return room.total;
      }
      return creditInfo.displayAmount;
    }
    return 0;
  }

  // only apply credits is rate is pkg or rmp
  canCreditsBeAppliedToRate(id) {
    return id[2] === '1' || id[2] === '2' || id[2] === '5';
  }

}
