<div class="modal-header">
  <h4 class="modal-title">{{ title | translate:titleParams }}</h4>
</div>
<div *ngIf="message" class="modal-body">
  <strong *ngIf="subtitle">{{ subtitle | translate }}</strong>
  <p *ngIf="message" class="secondary-content confirmation-msg" [innerHtml]="message | translate"></p>
  <p *ngIf="messageTxt" class="secondary-content confirmation-msg" [innerHtml]="messageTxt"></p>
</div>
<div class="modal-footer pt-0 pt-md-4">
  <div class="d-md-flex flex-row justify-content-end">
    <div>
      <button type="button" class="btn btn-sm btn-link w-100 me-3" (click)="secondary()" data-cy="secondaryButton">{{ secondaryText | translate }}</button>
    </div>
    <div class="ms-2">
      <button type="button" class="btn btn-sm btn-primary w-100 px-5" (click)="primary()" data-cy="primaryButton">{{ primaryText | translate }}</button>
    </div>
  </div>
</div>
