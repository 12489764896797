import { Component, Input, SimpleChanges, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import * as moment from 'moment';
import { HotelsRecentSearch } from 'app/shared/models/hotels-recent-search';

@Component({
  selector: 'hotels-recent-searches',
  templateUrl: './hotels-recent-searches-section.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class HotelsRecentSearchesSectionComponent implements OnChanges {
  @Input() recentSearches: HotelsRecentSearch[] = [];
  recentHotelSearches: any[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if (changes.recentSearches && changes.recentSearches.currentValue) {
      this.generateRecentSearches();
    }
  }

  generateRecentSearches() {
    this.recentHotelSearches = [];

    const now = moment();
    this.recentSearches.forEach((hotel) => {
      if (hotel.destination && moment(hotel.checkin) >= now && moment(hotel.checkout) >= now) {
        const destination = Object.assign({}, hotel) as any;
        const params = {} as any;
        if (hotel.latitude != null && hotel.longitude != null) {
          params.latitude = hotel.latitude;
          params.longitude = hotel.longitude;
        }
        destination.name = this.formatDestinationName(hotel.destination);
        destination.dates = [
          this.formatDate(hotel.checkin, true),
          this.formatDate(hotel.checkout, true)
        ];

        this.recentHotelSearches.push(destination);
      }
    });
  }

  formatDate(date: string, displayFormat: boolean = false) {
    if (displayFormat) {
      return moment.utc(date).format('MMM D');
    }
    return moment.utc(date).format('YYYY-MM-DD');
  }

  formatDestinationName(destination: string) {
    return destination.split(',')[0];
  }
}
