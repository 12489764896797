import { createReducer, on, Action } from '@ngrx/store';
import { WalkthroughsActions } from '../actions';

export interface State {
  predictionWalkthrough: boolean;
}

export const initialState: State = {
  predictionWalkthrough: true,
};

const walktroughsReducer = createReducer(
  initialState,
  on(WalkthroughsActions.updateWalkthroughFirstTime, (state, action) => ({ ...state, [action.name]: action.value })),
);

export function reducer(state: State | undefined, action: Action) {
  return walktroughsReducer(state, action);
}
