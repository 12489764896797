import { StealthCreditsActions } from '../actions';

export interface State {
  applyCredits: boolean
  forceUpdate: boolean
}

export const initialState: State = {
  applyCredits: true,
  forceUpdate: false
};

export function reducer(
  state = initialState,
  action: StealthCreditsActions.StealthCreditsActionsUnion
) {
  switch (action.type) {
    case StealthCreditsActions.StealthCreditsActionsType.Toggle:
      return {
        applyCredits: !state.applyCredits,
        forceUpdate: false
      };
    case StealthCreditsActions.StealthCreditsActionsType.Remove:
      return {
        applyCredits: false,
        forceUpdate: true
      };
    case StealthCreditsActions.StealthCreditsActionsType.Apply:
      return {
        applyCredits: true,
        forceUpdate: true
      };
    case StealthCreditsActions.StealthCreditsActionsType.Reset:
      return initialState;
    default:
      return state;
  }
}

export const getStealthCredits = (state: State) => state;
