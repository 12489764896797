import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { datesConfig } from 'configs/dates';
import { featureFlags } from 'app/shared/models/featureFlags';

@Injectable({
  providedIn: 'root'
})

export class MFAHelper {
  isActive(user, features) {
    if (!features || !features.includes(featureFlags.MFA)) return false;
    if (user && user.generic_user ||
      user.call_center_admin ||
      user.first_time_login ||
      user.cobus_authorization ||
      !user.member.confirmed) return false;
    return true;
  }

  requestMFA(user, features, mfaRequest) {
    return this.isActive(user, features) &&
      this.isEnabled(user, mfaRequest) &&
      !this.isMfaSkipped(user);
  }

  showMFAModal(user, features) {
    if (!user?.member?.id) { return false; }
    if (!this.isActive(user, features)) { return false; }
    // if (user.is_new_device) { return true; }
    if (this.isEnabled(user)) { return false; }
    return true;
  }

  isMfaSkipped(user) {
    return moment() <= moment(user.member.mfa_skipped, datesConfig.momentFormats);
  }

  isFirstAccessAfterValidateAccount(user) {
    return user.first_time_access;
  }

  private isEnabled(user, mfaRequestFromStore = true) {
    return mfaRequestFromStore && user.account.mfaEmail === 1;
  }

}
