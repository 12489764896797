<div class="modal-header">
    <h4 class="modal-title text-start" id="modal-basic-title" [innerHTML]="modalconfig?.title?.text"></h4>
    <button *ngIf="modalconfig?.close?.show" [class]=" modalconfig.close.logout ? 'btn-close sign-out-button' : 'btn-close close-button' " id="close" data-cy="close-missing-info" (click)="dismiss(true)">
        <span aria-hidden="true" [innerHTML]="modalconfig?.close?.icon"></span>
    </button>
</div>
<div class="modal-body">
    <div class="row d-flex">
        <ngb-alert *ngIf="error" type="danger" (closed)="closeAlert()">{{ error }}</ngb-alert>
        <div class="col-12 text-center" [innerHTML]="modalconfig?.body?.html">
        </div>
    </div>
    <form [formGroup]="formGroup" *ngIf="modalconfig?.formFields && formGroup">
        <div *ngIf="showFields('firstName')">
            <div class="form-group">
                <input type="text" id="firstName" class="form-control" formControlName="firstName"
                    placeholder="{{ 'intro_page.first_name' | translate }}" required />
                <div class='error-message' *ngIf="firstName.errors !== null && (firstName.touched)"
                    [innerHtml]="formErrorsHelper.errorMessage(firstName, ('intro_page.first_name_label' | translate))">
                </div>
            </div>
        </div>
        <div *ngIf="showFields('lastName')">
            <div class="form-group">
                <input type="text" id="lastName" class="form-control" formControlName="lastName"
                    placeholder="{{ 'intro_page.last_name' | translate }}" required />
                <div class='error-message' *ngIf="lastName.errors !== null && (lastName.touched)"
                    [innerHtml]="formErrorsHelper.errorMessage(lastName, ('intro_page.last_name_label' | translate))">
                </div>
            </div>
        </div>
        <div *ngIf="showFields('email')">
            <div class="form-group">
                <input type="text" id="email" class="form-control" formControlName="email"
                    placeholder="{{ 'intro_page.email_label' | translate }}" required autocomplete="email" />
                <div class='error-message' *ngIf="email.errors !== null && (email.touched)"
                    [innerHtml]="formErrorsHelper.errorMessage(email, ('intro_page.email_label' | translate))">
                </div>
            </div>
        </div>
        <div *ngIf="showFields('phone') && countries.length > 0">
            <div class="row phone-row">
                <div class="col-12">
                  <div class="form-group">
                    <phone-input labelKey="profile.info.homePhone" formGroupName="phoneNumber" [countries]="countries"
                    [formGroup]="phone" [countryEnabled]="true" [numberEnabled]="true" [strictPhoneValidation]="true"></phone-input>
                    <fa-icon [icon]="icons.faPhone" class="input-icon"></fa-icon>
                  </div>
                </div>
              </div>
        </div>
        <div *ngIf="showFields('address')">
            <address-form (addressSelected)="getAddress($event)"></address-form>
        </div>
        <div>
        </div>
        <div class="row">
            <div class="col-12 text-center">
                <button (click)="submit()" class="btn btn-primary" data-cy="update-missing-info">{{'profile.login_and_security.update' | translate}}</button>
            </div>
        </div>
    </form>
</div>
