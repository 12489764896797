import { CreditHistoryActions } from '../actions';
import { UserCreditHistory, UserCreditHistoryItem } from 'app/profile/models/user-credit-history.interface';

export const initialState: UserCreditHistory = {
  credits: [],
  loaded: false,
  pending: false,
  error: null
};

export function reducer(
  state = initialState,
  action: CreditHistoryActions.CreditHistoryActionsUnion
) {
  switch (action.type) {
    case CreditHistoryActions.CreditHistoryActionTypes.LoadCredits:
      return {
        ...state,
        pending: true
      };
    case CreditHistoryActions.CreditHistoryActionTypes.LoadCreditsSuccess:
      return {
        ...state,
        credits: action.payload ? action.payload.credits : [],
        loaded: true,
        pending: false,
        error: null
      };
    case CreditHistoryActions.CreditHistoryActionTypes.LoadCreditsFailure:
      return {
        ...state,
        pending: false,
        error: action.payload.error
      };
    default: {
      return state;
    }
  }
}

export const getCreditHistory = (state: UserCreditHistory): UserCreditHistoryItem[] => state.credits;
export const getCreditHistoryPending = (state: UserCreditHistory): boolean => state.pending;
export const getCreditHistoryLoaded = (state: UserCreditHistory): boolean => state.loaded;
export const getCreditHistoryError = (state: UserCreditHistory): any => state.error;
