import { act } from '@ngrx/effects';
import { Hotel } from 'app/hotels/models/hotel';
import { HotelResultsActions, ShareItineraryActions } from '../actions';

export interface State {
  hotels :Hotel[];
  meta :any;
  traceable :any;
  closeStream :boolean;
  cache_key :string;
  pending :boolean;
  success :boolean;
  error : any;
  initialContentLoad :boolean;
  endContentLoad :boolean;
  partialContentLoad : boolean;
  searchId: any;

}

export const initialState: State = {
  hotels: [],
  meta: null,
  traceable: null,
  closeStream :null,
  cache_key : null,
  pending : null,
  success : null,
  error: null,
  initialContentLoad: null,
  endContentLoad : null,
  partialContentLoad : null,
  searchId: null,
};

export function reducer(
    state = initialState,
    action: HotelResultsActions.HotelFilterActionsUnion
) {
  switch (action.type) {
    case HotelResultsActions.HotelResultsActionTypes.GetHotelsStream:
      return {
        ...state,
        pending: true,
        searchId: action.payload.searchId,
      };
    case HotelResultsActions.HotelResultsActionTypes.GetHotelsStreamSuccess:
      return {
        ...state,
        pending: false,
        hotels: action.payload?.hotels,
        success: true,
        closeStream : action.payload.closeStream,
        meta : action.payload.meta,
        cacheKey : action.payload.cache_key,
        traceable : action.payload.traceable,
        initialContentLoad : action.payload.initialContentLoad,
        partialContentLoad : action.payload.partialContentLoad,
        endContentLoad : action.payload.endContentLoad

      };
    case HotelResultsActions.HotelResultsActionTypes.GetHotelsFailure:
      return {
        ...state,
        pending: false,
        success: false,
        error: action.payload.error
      };
  }
}

export const getHotelsSuccess = (state: State) => state;
export const getHotelsStreamFailure = (state: State) => state;
export const getHotelStream = (state : State) => state.pending;
