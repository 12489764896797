import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { DynamicScriptLoaderService } from 'app/shared/services/dynamic-script-load/dynamic-script-load.service';

@Component({
  selector: 'hbspt-form',
  templateUrl: './hbspt-form.component.html',
  styleUrls: ['./hbspt-form.component.scss']
})
export class HbsptFormComponent implements OnInit {
  @Input() formId: string;
  private target: string = '.hbspt-form'
  constructor(private dynamicScriptLoaderService: DynamicScriptLoaderService) {

  }

  async ngOnInit() {
    await this.dynamicScriptLoaderService.loadScript('hbspt');
    window.hbspt.forms.create({
      region: environment.hubspot.region,
      portalId: environment.hubspot.portalId,
      formId: this.formId,
      target: this.target
  
    });
  }
}
