import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PhoneNumberMapper } from 'app/profile/mappers/phone-number.mapper';
import { PromoCodeAction } from 'app/profile/store/actions';
import * as fromProfile from 'app/profile/store/reducers';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';
import { GeneralHelper } from 'app/shared/helpers/general.helper';
import { PhoneNumberHelper } from 'app/shared/helpers/phone-number.helper';
import { ProfileHelper } from 'app/shared/helpers/profile.helper';
import { AuthService } from 'app/shared/services/auth/auth.service';
import { StoryblokService } from 'app/shared/services/storyblok/storyblok.service';
import { CustomValidators } from 'app/shared/validators/custom-validators';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CoTravelerItinerarySummary } from '../co-traveler-itinerary-summary/co-traveler-itinerary-summary.component';
import { EligibleTravelerInfoSummary } from '../eligible-traveler-summary/eligible-traveler-summary.component';
@Component({
  selector: 'register-invited-form',
  templateUrl: './register-invited-form.html',
  styleUrls: ['./register-invited-form.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegisterInvitedFormComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Output() submitted = new EventEmitter();
  @Input() errorMessage: string | null;
  @Input() pendingLogin: boolean;
  @Input() pending: boolean;
  @Input() inviteCode: string;
  @Input() uniqueCode: string;
  @Input() travelerCode: string;
  @Input() inviteCodeType: string;
  @Input() inviteCodeSource: string;
  @Input() itinerary: CoTravelerItinerarySummary;
  @Input() eligibleTravelerInfo: EligibleTravelerInfoSummary;
  @Input() defaultEmail = '';
  @Input() shareCode = '';
  @Input() airlines;
  @Input() countries;
  @Input() referrerName: string = '';
  @Input() utmSource = '';
  @Input() utmMedium = '';
  @Input() utmCampaign = '';
  @Input() pendingTemplate = '';
  @Input() promoCode = null;

  createAccountForm: UntypedFormGroup;

  checkPromoCodeSuccess$ = this.store.select<any>(fromProfile.promoCodeCheckSucccess);
  checkPromoCodeFailure$ = this.store.select<any>(fromProfile.promoCodeCheckError);
  ngUnsubscribe = new Subject<void>();
  // Only for test ET Template
  eligibleTravelerTestTemplate = false;
  employeeAvatar = {
    fullName: '',
    airlineName: '',
    avatar: {}
  };
  remoteContent = {
    title: '',
    description: '',
    messageText: '',
    landingDescription: '',
    eligibleTravelerDescription: '',
  };
  showLandingInvitePage: boolean = false;
  phoneError = false;
  phoneReady = false;

  constructor(
    public formErrorsHelper: FormErrorsHelper,
    private generalHelper: GeneralHelper,
    private phoneNumberHelper: PhoneNumberHelper,
    private profileHelper: ProfileHelper,
    private store: Store,
    private storyblokService: StoryblokService,
    private translate: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    if (this.createAccountForm && this.countries) {
      const phoneControl = this.phoneNumberHelper.createPhoneFormGroup(false, false);
      phoneControl.get('country')?.setValue({
        code: 'US',
        code3: 'US',
        currency: "USD",
        name: "United States of America",
        phone: 1
      });
      phoneControl.get('area')?.setValue('');
      phoneControl.get('number')?.setValue('');
      this.createAccountForm.addControl('homePhone', phoneControl);
      this.phoneReady = true;
    }
  }

  ngOnInit() {
    if (this.utmMedium === 'share-link' || this.utmMedium === 'facebook-share-link') { this.showLandingInvitePage = true; }
    this.createAccountForm = new UntypedFormGroup({
      airline_code: new UntypedFormControl(AuthService.INVITED_MEMBERS_AIRLINE_CODE),
      organization_id: new UntypedFormControl(AuthService.INVITED_MEMBERS_ORGANIZATION_ID, Validators.required),
      employee_number: new UntypedFormControl('', [Validators.required, Validators.email, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]),
      email: new UntypedFormControl(''),
      password: new UntypedFormControl('', [Validators.required, CustomValidators.password]),
      invite_code: new UntypedFormControl(this.inviteCode),
      unique_code: new UntypedFormControl(this.uniqueCode),
      traveler_code: new UntypedFormControl(this.travelerCode),
      invite_code_type: new UntypedFormControl(this.inviteCodeType),
      invite_code_source: new UntypedFormControl(this.inviteCodeSource),
      accept_terms_of_service: new UntypedFormControl(true),
      email_opt_out: new UntypedFormControl(false),
      share_code: new UntypedFormControl(''),
      utm_source: new UntypedFormControl(this.utmSource),
      utm_medium: new UntypedFormControl(this.utmMedium),
      utm_campaign: new UntypedFormControl(this.utmCampaign),
      promo_code: new UntypedFormControl(this.promoCode),
      password_confirm: new UntypedFormControl('', Validators.compose([Validators.required, CustomValidators.checkPasswordConfirmed])),
    });
    if (this.promoCode) {
      this.promoCodeIsValid();
    }
    this.loadRemoteContent();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.defaultEmail && changes.defaultEmail.currentValue && !this.employeeNumber.value) {
      this.employeeNumber.setValue(changes.defaultEmail.currentValue);
    }
    this.setEmployeeAvatarInfo();
  }

  async loadRemoteContent() {
    const storyblokData = await this.storyblokService.getStory('join/');

    if (!!storyblokData?.story?.content) {
      this.remoteContent.title = this.replaceConstants(storyblokData.story.content.title || '');
      this.remoteContent.description = this.replaceConstants(storyblokData.story.content.description || '');
      this.remoteContent.messageText = this.replaceConstants(storyblokData.story.content.message_text || '');
      this.remoteContent.landingDescription = this.replaceConstants(this.storyblokService.parseTextRich(storyblokData.story.content.landing_description) || '');
      this.remoteContent.eligibleTravelerDescription = this.replaceConstants(this.storyblokService.parseTextRich(storyblokData.story.content.eligible_traveler_description) || '');
      this.changeDetectorRef.detectChanges();
    }
  }

  replaceConstants(text: string) {
    return text
      .replace('REFERRER_NAME', this.referrerName)
      .replace('AIRLINE_NAME', this.eligibleTravelerInfo?.employeeAirlineName);
  }

  createAccount() {
    if (this.createAccountForm.valid) {
      if (this.createAccountForm.value['employee_number']) {
        this.createAccountForm.controls['email'].setValue(this.createAccountForm.value['employee_number']);
      }

      this.isAirlineAccount();
      this.createAccountForm.get('invite_code').setValue(this.inviteCode);
      this.createAccountForm.get('unique_code').setValue(this.uniqueCode);
      this.createAccountForm.get('traveler_code').setValue(this.travelerCode);
      this.createAccountForm.get('invite_code_type').setValue(this.inviteCodeType);
      this.createAccountForm.get('invite_code_source').setValue(this.inviteCodeSource);
      this.createAccountForm.get('share_code').setValue(this.shareCode);
      this.createAccountForm.get('utm_source').setValue(this.utmSource);
      this.createAccountForm.get('utm_medium').setValue(this.utmMedium);
      this.createAccountForm.get('utm_campaign').setValue(this.utmCampaign);
      this.createAccountForm.get('promo_code').setValue(this.promoCode);
      const rawPhone = this.createAccountForm.get('homePhone').value;

      this.submitted.emit({ ... this.createAccountForm.value, homePhone: this.getPhoneFormatted(rawPhone) });
    } else {
      Object.keys(this.createAccountForm.controls).forEach((field) => {
        const control = this.createAccountForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  isAirlineAccount() {
    const domain = this.employeeNumber.value.split('@')[1];
    const airline = this.getAirlineFromDomain(domain);

    if (airline) {
      this.createAccountForm.get('organization_id').setValue(airline.id);
      this.createAccountForm.get('airline_code').setValue(airline.code);
    }
  }

  getAirlineFromDomain(domain: string) {
    if  (!this.generalHelper.isAirlineForceEmail(this.employeeNumber.value)) return null;

    const airlines = this.airlines.filter(airline => airline.partner === false && airline.email_domains.includes(domain));
    return airlines.length === 1 ? airlines[0] : null;
  }

  get primaryContent() {
    if (this.travelerCode && !this.eligibleTravelerTestTemplate) {
      return this.translate.instant('web.create_eligible_traveler_account.title');
    }

    if (this.referrerName && this.remoteContent.title.length ) {
      return this.remoteContent.title;
    }

    return this.translate.instant('membership.create.one_step');
  }

  get secondaryContent() {
    if (this.travelerCode && this.eligibleTravelerInfo) {
      return this.remoteContent.eligibleTravelerDescription;
    }

    return this.remoteContent.description;
  }

  setEmployeeAvatarInfo() {
    if (this.itinerary) {
      this.employeeAvatar.fullName = this.itinerary.ownerName ? this.itinerary.ownerName : '';
      this.employeeAvatar.avatar = this.itinerary.ownerAvatar ? this.itinerary.ownerAvatar : '';
    }

    if (this.eligibleTravelerInfo) {
      // Only for test ET Template
      this.eligibleTravelerTestTemplate = true;
      this.employeeAvatar.fullName = this.eligibleTravelerInfo.employeeFullName ? this.eligibleTravelerInfo.employeeFullName : '';
      this.employeeAvatar.airlineName = this.eligibleTravelerInfo.employeeAirlineName ?
        this.eligibleTravelerInfo.employeeAirlineName :
        '';
      this.employeeAvatar.avatar = this.eligibleTravelerInfo.employeeAvatar ? this.eligibleTravelerInfo.employeeAvatar : '';
    }
  }

  handlerPromoCode() {
    this.checkPromoCodeSuccess$.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((response) => {
      if (response) {
        return response.credit_promotion_code.invite_code || (!response.credit_promotion_code.invite_code && response.credit_promotion_code.new_member_credit_value > 0)  ?
          this.createAccountForm.controls['promo_code'].setErrors(null) :
          this.createAccountForm.controls['promo_code'].setErrors({ incorrect: true });
      }
    });
    this.checkPromoCodeFailure$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((error) => {
      if (error) {
        this.createAccountForm.controls['promo_code'].setErrors({ incorrect: true });
      }
    });
  }

  clickAcceptInvitation() {
    this.showLandingInvitePage = false;
  }

  promoCodeIsValid(e = null) {
    this.promoCode = e ? e.target.value : this.promoCode;
    this.store.dispatch(new PromoCodeAction.CheckPromoCode(this.promoCode));
    this.handlerPromoCode();
  }

  checkHomePhone() {
    this.phoneError = false;
    if (!this.createAccountForm.get('homePhone').valid) {
      this.phoneError = true;
    }
  }

  getPhoneFormatted(rawPhone) {
    const country = rawPhone.country?.code || 'US';
    const number = rawPhone?.number || '';
    if (number.length === 0) { return '||' };
    const objPhone = this.profileHelper.formatPhoneService(country, number);
    return new PhoneNumberMapper().fromObject().toString(objPhone);
  }

  get loading()           { return this.pending || this.pendingLogin; }
  get employeeNumber()    { return this.createAccountForm.get('employee_number'); }
  get password()          { return this.createAccountForm.get('password'); }
  get emailOptOut()       { return this.createAccountForm.get('email_opt_out'); }
  get password_confirm()  { return this.createAccountForm.get('password_confirm'); }
  get promo_code()        { return this.createAccountForm.get('promo_code'); }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
