import { AuthApiActions, EventRegistrationActions } from '../actions';

export interface State {
  airline: string;
  campaign: string;
  loginError: string;
  loggedIn: boolean;
  pending: boolean;
  registerError: string;
  registerSuccess: boolean;
  registrations: any;
}

export const initialState: State = {
  airline: '',
  campaign: '',
  loginError: '',
  loggedIn: false,
  pending: false,
  registerSuccess: false,
  registerError: '',
  registrations: { pending: [], failed: [], completed: [] }
};

export function reducer(
  state = initialState,
  action:
    | AuthApiActions.AuthApiActionsUnion
    | EventRegistrationActions.EventRegistrationActionsUnion
) {
  switch (action.type) {
    case AuthApiActions.AuthApiActionTypes.LoginMarketingEventSuccess:
      return {
        ...state,
        loggedIn: true,
        loginError: ''
      };
    case AuthApiActions.AuthApiActionTypes.LoginMarketingEventFailure:
      return {
        ...state,
        loggedIn: false,
        loginError: action.payload.error
      };
    case AuthApiActions.AuthApiActionTypes.LogoutMarketingEventSuccess:
      return {
        ...state,
        airline: '',
        campaign: '',
        loggedIn: false,
        loginError: '',
        registerError: ''
      };
    case EventRegistrationActions.EventRegistrationActionTypes.AirlineSet:
      return {
        ...state,
        airline: action.payload.airline,
        campaign: action.payload.campaign,
        registerSuccess: false
      };
    case EventRegistrationActions.EventRegistrationActionTypes.OfflineMemberRegister:
      return {
        ...state,
        registerError: null,
        pending: true,
        registerSuccess: false
      };
    case EventRegistrationActions.EventRegistrationActionTypes.MemberRegisterSuccess:
      return {
        ...state,
        registerError: null,
        pending: false,
        registerSuccess: true,
        registrations: {
          ...state.registrations,
          completed: [
            ...state.registrations.completed,
            action.payload.auth
          ]
        }
      };
    case EventRegistrationActions.EventRegistrationActionTypes.MemberRegisterFailure:
      return {
        ...state,
        registerError: action.payload.error.error.error_description,
        pending: false,
        registerSuccess: false
      };
    case EventRegistrationActions.EventRegistrationActionTypes.MemberRegisterSyncSuccess:
      return {
        ...state,
        registrations: {
          ...state.registrations,
          pending: state.registrations.pending.filter((pending) => {
            return pending.registrationId !== action.payload.auth.registrationId;
          }),
          completed: [
            ...state.registrations.completed,
            action.payload.auth
          ]
        }
      };
    case EventRegistrationActions.EventRegistrationActionTypes.MemberRegisterSyncFailure:
      return {
        ...state,
        registrations: {
          ...state.registrations,
          pending: state.registrations.pending.filter((pending) => {
            return pending.registrationId !== action.payload.auth.registrationId;
          }),
          failed: [
            ...state.registrations.failed,
            { error: action.payload.error.error.error_description, auth: action.payload.auth }
          ]
        }
      };
    case EventRegistrationActions.EventRegistrationActionTypes.MemberRegisterSendLater:
      return {
        ...state,
        pending: false,
        registerSuccess: true,
        registrations: {
          ...state.registrations,
          pending: [
            ...state.registrations.pending,
            action.payload.auth
          ]
        }
      };
    case EventRegistrationActions.EventRegistrationActionTypes.MemberRegisterRetry:
      return {
        ...state,
        registrations: {
          ...state.registrations,
          pending: [
            ...state.registrations.pending,
            action.payload.auth
          ],
          failed: state.registrations.failed.filter((failed) => {
            return failed.auth.registrationId !== action.payload.auth.registrationId;
          })
        }
      };
    case EventRegistrationActions.EventRegistrationActionTypes.MemberRegisterRemove:
      return {
        ...state,
        registrations: {
          ...state.registrations,
          pending: state.registrations.pending.filter((pending) => {
            return pending.registrationId !== action.payload.auth.registrationId;
          }),
          failed: state.registrations.failed.filter((failed) => {
            return failed.auth.registrationId !== action.payload.auth.registrationId;
          })
        }
      };
    case EventRegistrationActions.EventRegistrationActionTypes.MemberRegisterClear:
      return {
        ...state,
        registerError: '',
        registerSuccess: false,
      };
    default:
      return state;
  }
}

export const getAirline = (state: State) => state.airline;
export const getCampaign = (state: State) => state.campaign;
export const isLoggedIn = (state: State) => state.loggedIn;
export const getLoginError = (state: State) => state.loginError;
export const getPending = (state: State) => state.pending;
export const getRegisterError = (state: State) => state.registerError;
export const getRegisterSuccess = (state: State) => state.registerSuccess;
export const getRegistrations = (state: State) => state.registrations;
