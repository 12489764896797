import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CoTravelersService {
  constructor (
    private http: HttpClient) {
  }

  addMemberToItinerary(shareCode: string): Observable<any> {
    return this.http.patch(`${environment.apiUrl}/api/v1/rms_itineraries/add_member/${shareCode}`, {});
  }

  getCoTravelersByItinerary(itineraryID: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/rms_itineraries/get_members/${itineraryID}`);
  }

  getItineraryByShareCode(shareCode: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/rms_itineraries/get_itinerary/${shareCode}`);
  }

  inviteCoTraveler(coTravelers): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/v1/rms_itineraries`, coTravelers);
  }

  removeTraveler(shareItineraryId): Observable<any> {
    return this.http.patch(`${environment.apiUrl}/api/v1/rms_itineraries/remove_from_itinerary/${shareItineraryId}`, {})
  }

  getInvitedByUnique(unique: string): Observable<any> {
    const params = new HttpParams()
      .append('inviteCode', unique);
    return this.http.get(`${environment.apiUrl}/api/v1/rms_invites/get_invited_by_unique`, { params })
  }

  shareItinerary(invites): Observable<any>{
    return this.http.post(`${environment.apiUrl}/api/v1/rms_itineraries`, invites.payload);
  }
}
