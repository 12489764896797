import { Injectable } from '@angular/core';
import placesJson from 'configs/places.json';

@Injectable({
  providedIn: 'root'
})

export class PlacesService {

  constructor() {}

  readonly folderPath = '/angular_assets/images/places';

  getRandomPlace(): any {
    const place: any = placesJson[Math.floor(Math.random() * placesJson.length)];
    place.imagePath = `${this.folderPath}/${place.fileName}`;
    return place;
  }
}
