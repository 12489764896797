
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';

@Injectable()
export class CachingInterceptor implements HttpInterceptor {
  cache = new Map();

  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (req.headers.get('cacheable') !== 'true') {
      return next.handle(req);
    }

    if (this.cache.get(req.url)) {
      return of(this.cache.get(req.url));
    }

    return this.sendRequest(req, next);
  }

  sendRequest(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(tap((response) => {
      if (response instanceof HttpResponse) {
        this.cache.set(req.url, response);
      }
    }));
  }
}
