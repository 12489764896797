import { Action } from '@ngrx/store';
import { DemandCalendar, DemandCalendarRequest } from 'app/hotels/models/demand-calendar';

export enum DemandCalendarActionTypes {
  ClearDemandCalendar = '[Demand Calendar] Clear Demand Calendar',
  GetDemandCalendar = '[Demand Calendar] Get Demand Calendar',
  GetDemandCalendarSuccess = '[Demand Calendar] Get Demand Calendar Success',
  GetDemandCalendarError = '[Demand Calendar] Get Demand Calendar Error',
}

export class ClearDemandCalendar implements Action {
  readonly type = DemandCalendarActionTypes.ClearDemandCalendar;

  constructor() { }
}

export class GetDemandCalendar implements Action {
  readonly type = DemandCalendarActionTypes.GetDemandCalendar;

  constructor(public payload: DemandCalendarRequest) { }
}

export class GetDemandCalendarSuccess implements Action {
  readonly type = DemandCalendarActionTypes.GetDemandCalendarSuccess;

  constructor(public payload: { calendar: DemandCalendar[] }) { }
}

export class GetDemandCalendarError implements Action {
  readonly type = DemandCalendarActionTypes.GetDemandCalendarError;

  constructor(public payload: { error }) { }
}

export type DemandCalendarActionsUnion =
  ClearDemandCalendar |
  GetDemandCalendar |
  GetDemandCalendarSuccess |
  GetDemandCalendarError;