import { Action } from '@ngrx/store';
import { HotelsRecentView } from 'app/shared/models/hotels-recent-view';

export enum HotelsRecentViewsActionTypes {
  Load = '[Hotel Recent Views] Load',
  SaveSearch = '[Hotel Recent Views] Save Search'
}

export class Load implements Action {
  readonly type = HotelsRecentViewsActionTypes.Load;

  constructor() {}
}

export class SaveSearch implements Action {
  readonly type = HotelsRecentViewsActionTypes.SaveSearch;

  constructor(public payload: HotelsRecentView) {}
}

export type HotelsRecentViewsActionsUnion = Load
  | SaveSearch;
