import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TrendingDestination } from 'app/shared/models/trending-destination';

@Injectable({
  providedIn: 'root'
})
export class TrendingDestinationsService {

  constructor(
    private http: HttpClient
  ) {}

  getTrendingDestinations(type, location): Observable<TrendingDestination[]> {
    if (type === 'hotels') {
      return this.http.get<TrendingDestination[]>(
        `${environment.apiUrl}/api/v2/trending/destinations?latitude=${location.latitude ? location.latitude : '0'}&longitude=${location.longitude ? location.longitude : '0'}&limit=10`,
        {}
      );
    }
  }

  getAllInclusiveLocations() {
    return this.http.get<any[]>(
      `${environment.apiUrl}/api/v1/all-inclusive/destinations`,
      { headers: { cacheable: 'true' } }
    );
  }
}
