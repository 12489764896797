import { RegisterPageActions, AuthApiActions } from '../actions';

export interface State {
  error: string | null;
  pending: boolean;
  inviteParams: any;
}

export const initialState: State = {
  error: null,
  pending: false,
  inviteParams: null
};

export function reducer(
  state = initialState,
  action:
    | AuthApiActions.AuthApiActionsUnion
    | RegisterPageActions.RegisterPageActionsUnion
): State {
  switch (action.type) {
    case RegisterPageActions.RegisterPageActionTypes.Register:
      return {
        ...state,
        error: null,
        pending: true
      };
    case AuthApiActions.AuthApiActionTypes.RegisterSuccess:
      return {
        ...state,
        error: null,
        pending: false
      };
    case AuthApiActions.AuthApiActionTypes.RegisterFailure:
      return {
        ...state,
        error: action.payload.error.error.error_description,
        pending: false
      };
    case RegisterPageActions.RegisterPageActionTypes.RegisterClearError:
      return {
        ...state,
        error: null
      };
    case RegisterPageActions.RegisterPageActionTypes.SetInviteParams:
      return {
        ...state,
        inviteParams: action.payload.inviteParams
      };
    case RegisterPageActions.RegisterPageActionTypes.ClearInviteParams:
      return {
        ...state,
        inviteParams: null
      };
    default: {
      return state;
    }
  }
}

export const getError = (state: State) => state.error;
export const getPending = (state: State) => state.pending;
export const getInviteParams = (state: State) => state.inviteParams;
