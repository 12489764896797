import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, TemplateRef, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { StoryBlokNameDirective } from 'app/shared/directives/storyblok-name.directive';
import { StoryblokService } from 'app/shared/services/storyblok/storyblok.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ISbStoryParams } from 'storyblok-js-client';

@Component({
  selector: 'dynamic-page',
  templateUrl: './dynamic-page.component.html',
  styleUrls: ['./dynamic-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class DynamicPageComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;
  @ViewChildren(StoryBlokNameDirective) templates: QueryList<StoryBlokNameDirective>;
  @Input() slugName: string;
  @Input() folder: string;
  @Output() notFound = new EventEmitter();
  story: any;
  pending = true;
  article = {
    prev: null,
    next: null,
    content: null,
    index: null,
  };
  prevIcon = faAngleDoubleLeft;
  nextIcon = faAngleDoubleRight;
  articleList = [];

  private ngUnsubscribe = new Subject<void>();


  constructor(private storyblokService: StoryblokService,
    private router: Router,
    private ref: ChangeDetectorRef,
    private sanitizer: DomSanitizer) { }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.slugName.currentValue && !this.pending) {
      const params: ISbStoryParams = {
        version: environment.storyblok.version as "draft" | "published"
      };

      let data = await this.storyblokService.getStory(`${this.folder}/${this.slugName}`, params);
      if (data.error) {
        this.container.clear();
        this.pending = false;
       return this.notFound.emit();
      }
      this.story = data.story;
      this.pending = true;
      this.container.clear();
      window.scroll({
        top:0,
        left:0,
        behavior: 'instant'
      });
      this.buildComponent(this.story.content);
      this.pending = false;
    }
  }

  async ngOnInit(): Promise<void> {
    const params: ISbStoryParams = {
      version: environment.storyblok.version as "draft" | "published"
    };

    let data = await this.storyblokService.getStory(`${this.folder}/${this.slugName}`, params);
    if (data.error) {
      this.container.clear();
      this.pending = false;
     return this.notFound.emit();
    }
    this.pending = false;
    this.story = data.story;
    this.buildComponent(data.story.content);
    this.listenForChange();

  }

  listenForChange() {
    this.storyblokService.listenForChanges$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
      if (!event || !this.story) { return; }
      if (this.story && event?.story?.content?._uid === this.story?.content._uid && event) {
        this.story = event.story;
        this.pending = true;
        this.container.clear();
        this.buildComponent(this.story.content);
        this.ref.markForCheck();
        this.pending = false;
      }
    });
  }

  parseRichText(richText: any): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.storyblokService.parseTextRich(richText));
  };

  navigate(url: string, external: boolean) {
    const isSpecialLink = url.includes('mailto:') || url.includes('tel:') || external;
    if (isSpecialLink) {
      window.location.href = url;
      return;
    }
    return this.router.navigate([url]);
  }

  parseTable(content: any): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(content)

  }

  private findTemplate(key): TemplateRef<any> {
    const templateRef = this.templates.find(component => component.name === key);
    return templateRef?.template || null;


  }

  private renderComponent(storyBlokComponent: TemplateRef<any>, data: any) {
    const view = storyBlokComponent.createEmbeddedView({ data });
    this.container.insert(view);
  }

  private buildComponent(content: any): void {
    for (const key in content) {
      if (Object.prototype.hasOwnProperty.call(content, key) && Array.isArray(content[key])) {
        for (let index = 0; index < content[key].length; index++) {
          const template = this.findTemplate(content[key][index].component);
          if(template) {
            this.renderComponent(template, content[key][index]);

          }
        }
      }
    }
    this.ref.markForCheck();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.container.clear();
  }

  generateBootstrapColumn(column) {
    if (!column) return 'col-auto'
    const sizeMap = {
      "xsmall": "xs",
      "small": "sm",
      "medium": "md",
      "large": "lg",
      "xlarge": "xl"
    };
    let classes = [];
    for (const [key, value] of Object.entries(column)) {
      if (sizeMap[key]) {
        classes.push(`col-${sizeMap[key]}-${value}`);
      }
    }

   return classes.join(' ');
  }

  toggleExpand(index: number, articleList?) {
    if (articleList) {
      this.articleList = articleList.list
    }
    this.clearArticle();
    this.setArticle(
      this.parseRichText(this.articleList[index].fullText),
      this.articleList[index - 1],
      this.articleList[index + 1],
      index
    );
    this.article.content = this.parseRichText(this.articleList[index].fullText);
    this.renderInternalComponent(this.articleList[index],'list-expanded-internal');
    this.pending = false;
  }

  clearArticle() {
    this.pending = true;
    this.article.next = null;
    this.article.prev = null;
    this.article.content = "";
    this.article.index = null;
  }

  setArticle(content, prev, next, index) {
    this.article.content = this.parseRichText(content);
    this.article.prev = prev;
    this.article.next = next;
    this.article.index = index;
    window.scroll({
      top:0,
      left:0,
      behavior: 'instant'
    });
  };

  noArticleBack() {
    this.container.clear();
    this.buildComponent(this.story.content);
    window.scroll({
      top:0,
      left:0,
      behavior: 'instant'
    });

  }

  renderInternalComponent(data, templateKey: string) {
    const expandedTemplate = this.findTemplate(templateKey);
    this.container.clear();
    this.renderComponent(expandedTemplate, data);
    this.ref.markForCheck();
  }

  getImageUrlResized(url: string, resizeWidth: number = 600) {
    return `${url}/m/${resizeWidth}x0`;
  }
}
