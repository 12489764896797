<div class="promo-card my-2">
  <div class="row card-content">
    <div class="col-6">
      <div class="image-container">
        <img class="card-image img-fluid" src="/angular_assets/images/homepage/just-for-you/hotel-promo.png">
      </div>
    </div>
    <div class="col-6 p-4 text-container">
      {{data.message}}
    </div>
  </div>
</div>
