import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'dateAgo',
  pure: true
})
export class DateAgoPipe implements PipeTransform {

  constructor(
    private readonly translateService: TranslateService
  ) { }

  transform(value: any, args?: any): any {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 29) {
        return 'Just now';
      }
      const intervals = {
        year: {
          labelSingular: this.translateService.instant('profile.login_and_security.year'),
          labelPlural: this.translateService.instant('profile.login_and_security.years'),
          time: 31536000
        },
        month: {
          labelSingular: this.translateService.instant('profile.login_and_security.months'),
          labelPlural: this.translateService.instant('profile.login_and_security.months'),
          time: 2592000
        },
        week: {
          labelSingular: this.translateService.instant('profile.login_and_security.week'),
          labelPlural: this.translateService.instant('profile.login_and_security.weeks'),
          time: 604800
        },
        day: {
          labelSingular: this.translateService.instant('profile.login_and_security.day'),
          labelPlural: this.translateService.instant('profile.login_and_security.days'),
          time: 86400
        },
        hour: {
          labelSingular: this.translateService.instant('profile.login_and_security.hour'),
          labelPlural: this.translateService.instant('profile.login_and_security.hours'),
          time: 3600
        },
        minute: {
          labelSingular: this.translateService.instant('profile.login_and_security.minute'),
          labelPlural: this.translateService.instant('profile.login_and_security.minutes'),
          time: 60
        },
        second: {
          labelSingular: this.translateService.instant('profile.login_and_security.second'),
          labelPlural: this.translateService.instant('profile.login_and_security.seconds'),
          time: 1
        }
      };
      let counter;
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i].time);
        if (counter > 0) {
          if (counter === 1) {
            return this.translateService.instant('time_ago', { unity: intervals[i].labelSingular, time: counter });
          }
          return this.translateService.instant('time_ago', { unity: intervals[i].labelPlural, time: counter });
        }
      }
    }
    return value;
  }

}
