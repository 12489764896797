<div class="form-group" [formGroup]='form'>
  <div [ngClass]="{ 'form-floating': !placeHolder }">
    <input
      [ngbTypeahead]="search"
      (selectItem)="airlineSelected($event.item)"
      [resultTemplate]="rt"
      [inputFormatter]="formatter"
      [value]="defaultValue"
      (change)="onChange($event)"
      id="airline"
      type="text"
      [placeholder]="placeHolder"
      autocomplete="off"
      required
      class="form-control with-background"
      data-cy="airlines"
      [ngClass]="{'ng-invalid ng-touched': control.errors !== null && (control.touched)}"/>
    <label *ngIf="!placeHolder" for="airline">{{ 'airlines.headers.name' | translate }}</label>
  </div>
  <div class='error-message'
    *ngIf="control.errors && control.touched"
    [innerHtml]="formErrorsHelper.errorMessage(control, ('airlines.headers.name' | translate))">
  </div>
</div>
<ng-template #rt let-r="result" let-t="term">
  {{ r.display_name }}
</ng-template>
