<div class="modal-header">
  <h4 class="modal-title text-center" id="modal-basic-title">{{ 'profile.reservations.flights.available_passes' | translate }}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()">

  </button>
</div>
<div class="modal-body">
  <div *ngFor=' let remainFtpInfo of remainingFtp ' class="mb-2">
    <div [innerHTML]="'profile.reservations.flights.remaining_passes' | translate:{ count: remainFtpInfo.count, period: remainFtpInfo.period, entitlement: (entitlementTextKey(remainFtpInfo.entitlement) | translate)}"></div>
  </div>
  <table class="table table-striped mt-3" *ngIf="remainingRetireePasses && remainingRetireePasses.passengers">
    <tr>
      <th scope="col">{{ 'membership.create.name_label' | translate }}</th>
      <th class="text-center" scope="col">{{ 'profile.reservations.flights.retiree_passes' | translate }}</th>
      <th class="text-end" scope="col">{{ 'profile.reservations.flights.retiree_passes_valid_for' | translate }}</th>
    </tr>
    <tr text-center *ngFor=' let pass of remainingRetireePasses.passengers '>
      <td>{{pass.firstName}} {{pass.lastName}}</td>
      <td class="text-center">{{pass.availablePasses}}</td>
      <td class="text-end">{{remainingRetireePasses.applicableYear}}</td>
    </tr>
  </table>
</div>
