import { createAction, props } from '@ngrx/store';
import { ConfigFlags } from 'app/shared/models/configFlags';

export const loadConfigFlags = createAction(
  '[Flags] Load Config Flags'
);

export const initConfigFlags = createAction(
  '[Flags] Init Config Flags'
);

export const loadConfigFlagsSuccess = createAction(
  '[Flags] Load Config Flags Success',
  props<{ configFlags: ConfigFlags  }>()
);

export const loadConfigFlagsFailure = createAction(
  '[Flags] Load Config Flags Failure',
  props<{ error: any  }>()
);

export const loadFeatureFlags = createAction(
  '[Flags] Load Feature Flags'
);

export const initFeatureFlags = createAction(
  '[Flags] Init Feature Flags'
);

export const loadFeatureFlagsSuccess = createAction(
  '[Flags] Load Feature Flags Success',
  props<{ featureFlags: any  }>()
);

export const loadFeatureFlagsFailure = createAction(
  '[Flags] Load Feature Flags Failure',
  props<{ error: any  }>()
);

export const addFeatureFlags = createAction(
  '[Flags] Add Feature Flag',
  props<{ feature: string  }>()
);

export const addFeatureFlagsSuccess = createAction(
  '[Flags] Add Feature Flag Success'
);

export const addFeatureFlagsFailure = createAction(
  '[Flags] Add Feature Flag Failure',
  props<{ error: any  }>()
);

export const removeFeatureFlags = createAction(
  '[Flags] Remove Feature Flag',
  props<{ feature: string  }>()
);

export const removeFeatureFlagsSuccess = createAction(
  '[Flags] Remove Feature Flag Success'
);

export const removeFeatureFlagsFailure = createAction(
  '[Flags] Remove Feature Flag Failure',
  props<{ error: any  }>()
);

export const loadAirlineFlags = createAction(
  '[Flags] Load Airline Flags',
  props<{ code: string  }>()
);

export const loadAirlineFlagsSuccess = createAction(
  '[Flags] Load Airline Flags Success',
  props<{ airlineFlags: any  }>()
);

export const loadAirlineFlagsFailure = createAction(
  '[Flags] Load Airline Flags Failure',
  props<{ error: any  }>()
);
