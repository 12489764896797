if (typeof zE === "function") {
    zE('webWidget', 'hide');
    zE('webWidget:on', 'close', function () {
      zE('webWidget', 'hide');
    });
  
    zE('webWidget:on', 'chat:status', function(status) {
      window.zendesk.chatStatus = status;
    });
  
    window.zESettings = {
      webWidget: {
        contactForm: {
          suppress: true
        }
      }
    };
  
    window.zendesk = {
      ...window.zendesk,
      hide: function () {
        zE('webWidget', 'hide');
      },
      open: function () {
        zE('webWidget', 'open');
      },
      show: function () {
        zE('webWidget', 'show');
      },
      prefill: function (user) {
        zE('webWidget', 'prefill', {
          name: {
            value: user.name,
            readOnly: true
          },
          email: {
            value: user.email,
            readOnly: true
          }
        });
      },
      identify: function (user) {
        zE('webWidget', 'identify', {
          name: user.name,
          email: user.email
        });
      },
      handleNotifications: function (cb) {
        zE('webWidget:on', 'chat:unreadMessages', function event() {
          cb();
        });
      },
      verifyChatAvailability: function (cb) {
      }
    }
  }  