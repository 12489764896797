import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ISbStory } from 'storyblok-js-client';
import { StoryblokService } from 'app/shared/services/storyblok/storyblok.service';

@Component({
  selector: 'invite-a-friend-card',
  styleUrls: ['./invite-a-friend-card.scss'],
  templateUrl: './invite-a-friend-card.html'
})

export class InviteAFriendCardComponent implements OnInit {
  @Output() clicked = new EventEmitter();
  public path = '/invite';
  public storyblokContent = '';
  public storyblokTitle = '';
  public storyblokText = '';
  private folder = 'hotel-home';
  private slugName = 'hotel-search-page';

  constructor(
    private storyblokService: StoryblokService,
  ) { }

  ngOnInit(): void {
    this.loadRemoteContent();
  }

  private async loadRemoteContent() {
    const storyblokData: ISbStory['data'] | { error } = await this.storyblokService.getStory(`${this.folder}/${this.slugName}`);
    if ('error' in storyblokData) {
      return;
    }

    const storyblokContent = storyblokData.story.content.invite_a_friend_card[0];

    this.storyblokTitle = storyblokContent?.title;
    this.storyblokText = storyblokContent?.text;
  }

  onClicked() {
    this.clicked.emit({ path: this.path, data: [] });
  }
}
