import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class UrlHelper{

  allowedUrlRegex: RegExp = /^http:\/\/localhost:[0-9]{1,4}$|(.*\.)?id90.com(:[0-9]+)?$|(.*\.)?id90travel.com(:[0-9]+)?$/;
  relativeUrlRegex = /^([^:/?#]+:)?(\/\/[^/?#]*)?[^?#]*$/;

  constructor() { }

  isInternalUrl(url: string): boolean {
    return this.relativeUrlRegex.test(url) && url.startsWith('/');
  }

  isAllowedExternalUrl(url: string): boolean {
    try {
      const fullUrl = new URL(url);
      const host = fullUrl.host;
      return this.allowedUrlRegex.test(host);
    } catch (error) {
      return false;
    }
  }

}
