import { FlagsActions } from 'app/shared/store/actions';
import { ConfigFlags } from 'app/shared/models/configFlags';
import { Action, createReducer, on } from '@ngrx/store';
import { AirlineFlags } from 'app/shared/models/flags';

export interface State {
  config: ConfigFlags;
  loadingConfig: boolean;
  configDate?: Date;
  loadingFeature: boolean;
  feature: any;
  airlineFlags: AirlineFlags;
  airlineFlagsError: any;
  airlineFlagsPending: boolean;
}

export const initialState: State = {
  config: null,
  loadingConfig: false,
  feature: null,
  loadingFeature: false,
  airlineFlags: null,
  airlineFlagsError: null,
  airlineFlagsPending: false
};


export const getFeatureFlagsLoading = (state: State) => state.loadingFeature;
export const getAirlineFlags = (state: State) => state.airlineFlags;

const flagsReducer = createReducer(
  initialState,
  on(FlagsActions.loadConfigFlags, state => ({ ...state, config: null, loadingConfig: true })),
  on(FlagsActions.loadConfigFlagsSuccess, (state, { configFlags }) => ({
    ...state,
    config: { ...configFlags },
    loadingConfig: false,
    configDate: new Date()
  })),
  on(FlagsActions.loadConfigFlagsFailure, state => ({ ...state, loadingConfig: false })),
  on(FlagsActions.loadFeatureFlags, state => ({ ...state, feature: null, loadingFeature: true })),
  on(FlagsActions.loadFeatureFlagsSuccess, (state, { featureFlags }) => ({
    ...state,
    feature: featureFlags,
    loadingFeature: false
  })),
  on(FlagsActions.loadFeatureFlagsFailure, state => ({ ...state, loadingFeature: false })),
  on(FlagsActions.loadAirlineFlags, state => ({ ...state, airlineFlagsPending: true })),
  on(FlagsActions.loadAirlineFlagsSuccess, (state,  { airlineFlags }) => ({
    ...state,
    airlineFlags: airlineFlags,
    airlineFlagsPending: false
  })),
  on(FlagsActions.loadAirlineFlagsFailure, (state, { error }) => ({ ...state, airlineFlagsPending: false, airlineFlagsError: error })),
  on(FlagsActions.addFeatureFlags, state => ({ ...state, loadingFeature: true })),
  on(FlagsActions.addFeatureFlagsSuccess, (state) => ({
    ...state,
    loadingFeature: false,
  })),
  on(FlagsActions.addFeatureFlagsFailure, state => ({ ...state, loadingFeature: false })),
  on(FlagsActions.removeFeatureFlags, state => ({ ...state, loadingFeature: true })),
  on(FlagsActions.removeFeatureFlagsSuccess, (state) => ({
    ...state,
    loadingFeature: false
  })),
  on(FlagsActions.removeFeatureFlagsFailure, state => ({ ...state, loadingFeature: false }))
);


export function reducer(state: State | undefined, action: Action) {
  return flagsReducer(state, action);
}
