import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { RecommendedDestinationsActions, RecommendedDestinationsApiActions } from '../actions';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { RecommendedDestinationsService } from 'app/shared/services/recommended-destinations/recommended-destinations.service';

@Injectable()
export class RecommendedDestinationsEffects {

  load$ = createEffect(()=>
    this.actions$.pipe(
      ofType<RecommendedDestinationsActions.Load>(RecommendedDestinationsActions.RecommendedDestinationsActionTypes.Load),
      switchMap(() =>
        this.recommendedDestinationsService.get().pipe(
          map(res => new RecommendedDestinationsApiActions.LoadSuccess({ destinations: res.destinations })),
          catchError(error => of(new RecommendedDestinationsApiActions.LoadFailure(error)))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private recommendedDestinationsService: RecommendedDestinationsService
  ) {}
}
