
<form class="row" (ngSubmit)="onSubmit()" [formGroup]="this.dynamicForm" *ngIf="this.formData" >
  <div class="row">
    <ng-container #container></ng-container>
  </div>
  <div class="row w-100" [ngStyle]="{
    'justify-content':this.formData.button_alignment
  }">
    <div [ngClass]="generateBootstrapColumn(this.formData.button_size)" >
      <button type="submit" class="btn btn-primary w-100 my-2">
        {{ this.formData.button_label }}
      </button>
    </div>
  </div>
</form>

<div class="col-12 w-100 h-100" *ngIf="pending">
   <div class="col-12">
      <loading-indicator [fullScreen]='true'></loading-indicator>
   </div>
</div>

<ng-template let-data="data" let-formGroup="dynamicForm" name="text">
    <div *ngIf="!pending && formGroup" [ngClass]="generateBootstrapColumn(data.column)" [appStoryblok]="data._editable" [ngStyle]="{
        'text-align': data.alignment
      }" >
      <div class="form-group form-floating">
        <input type="text" [id]="data.id" class="form-control with-background" [formControl]="formGroup.get(data.id)" placement="right"/>
        <label for="{{data.id}}" data-error="error">{{data.label}}</label>
        <div class='error-message' *ngIf="formGroup.get(data.id).errors !== null && (formGroup.get(data.id).touched)" [innerHtml]="formErrorsHelper.errorMessage(formGroup.get(data.id), data.label)"></div>
      </div>
    </div>
</ng-template>

<ng-template let-data="data" let-formGroup="dynamicForm" name="email">
  <div [ngClass]="generateBootstrapColumn(data.column)"  [appStoryblok]="data._editable" [ngStyle]="{
      'text-align': data.alignment
    }">
    <div class="form-group form-floating">
      <input type="email" [id]="data.id" class="form-control with-background" [formControl]="formGroup.get(data.id)" placement="right"/>
      <label for="{{data.id}}" data-error="error">{{data.label}}</label>
      <div class='error-message' *ngIf="formGroup.get(data.id).errors !== null && (formGroup.get(data.id).touched)" [innerHtml]="formErrorsHelper.errorMessage(formGroup.get(data.id), data.label)"></div>
    </div>
  </div>
</ng-template>

<ng-template let-data="data" let-formGroup="dynamicForm" name="number">
  <div [ngClass]="generateBootstrapColumn(data.column)"  [appStoryblok]="data._editable" [ngStyle]="{
      'text-align': data.alignment
    }">
    <div class="form-group form-floating">
      <input type="number" [id]="data.id" class="form-control with-background" [formControl]="formGroup.get(data.id)" placement="right"/>
      <label for="{{data.id}}" data-error="error">{{data.label}}</label>
      <div class='error-message' *ngIf="formGroup.get(data.id).errors !== null && (formGroup.get(data.id).touched)" [innerHtml]="formErrorsHelper.errorMessage(formGroup.get(data.id), data.label)"></div>
    </div>
  </div>
</ng-template>

<ng-template let-data="data" let-formGroup="dynamicForm" name="tel">
  <div [ngClass]="generateBootstrapColumn(data.column)" [appStoryblok]="data._editable" [ngStyle]="{
      'text-align': data.alignment
    }">
    <div class="form-group form-floating">
      <input type="tel" [id]="data.id" class="form-control with-background" [formControl]="formGroup.get(data.id)" placement="right"/>
      <label for="{{data.id}}" data-error="error">{{data.label}}</label>
      <div class='error-message' *ngIf="formGroup.get(data.id).errors !== null && (formGroup.get(data.id).touched)" [innerHtml]="formErrorsHelper.errorMessage(formGroup.get(data.id), data.label)"></div>
    </div>
  </div>
</ng-template>

<ng-template let-data="data" let-formGroup="dynamicForm" name="textarea">
  <div [ngClass]="generateBootstrapColumn(data.column)"  [appStoryblok]="data._editable" class="pt-3" [ngStyle]="{
      'text-align': data.alignment
    }">
    <span [innerHTML]="parseRichText(data.title)"></span>
    <div class="form-group form-floating">
      <textarea [id]="data.id" [formControl]="formGroup.get(data.id)" maxlength=255 class="form-control with-background" rows="4"
              placeholder="{{ data.placeholder }}"></textarea>
      <label for="{{data.id}}" data-error="error">{{data.label}}</label>
      <div class='error-message' *ngIf="formGroup.get(data.id).errors !== null && (formGroup.get(data.id).touched)" [innerHtml]="formErrorsHelper.errorMessage(formGroup.get(data.id), data.label)"></div>
    </div>
  </div>
</ng-template>

<ng-template let-data="data" let-formGroup="dynamicForm" name="dynamic-radio-group">
  <div [ngClass]="generateBootstrapColumn(data.column)"  role="group" [appStoryblok]="data._editable" class="pt-3" [ngStyle]="{
      'text-align': data.alignment
    }">
    <span [innerHTML]="parseRichText(data.richtext)"></span>
    <div class="form-check" *ngFor="let option of data.options" >
      <input class="form-check-input" [attr.name]="data.id" [id]="option.id" type="radio" [value]="option.value" [formControl]="formGroup.get(data.id)">
      <label class="form-check-label" for="{{option.id}}" data-error="error">{{option.label}}</label>
    </div>
    <div class='error-message' *ngIf="formGroup.get(data.id).errors !== null && (formGroup.get(data.id).touched)" [innerHtml]="formErrorsHelper.errorMessage(formGroup.get(data.id), 'An Option')"></div>
  </div>
</ng-template>

<ng-template let-data="data" let-formGroup="dynamicForm" name="dynamic-checkbox-group">
  <div [ngClass]="generateBootstrapColumn(data.column)" role="group" [appStoryblok]="data._editable" class="pt-3" [ngStyle]="{
      'text-align': data.alignment
    }">
    <span class="mt-3" [innerHTML]="parseRichText(data.richtext)"></span>
    <div class="form-check" *ngFor="let option of data.options" >
      <input class="form-check-input" [value]="option.value" [attr.name]="data.id" [id]="option.id" type="checkbox"  [formControl]="formGroup.get(data.id)">
      <label  for="{{option.id}}" data-error="error">{{option.label}}</label>
    </div>
    <div class='error-message' *ngIf="formGroup.get(data.id).errors !== null && (formGroup.get(data.id).touched)" [innerHtml]="formErrorsHelper.errorMessage(formGroup.get(data.id), 'An Option')"></div>
  </div>

</ng-template>

<ng-template let-data="data" let-formGroup="dynamicForm" name="checkbox">
  <div [ngClass]="generateBootstrapColumn(data.column)"  [appStoryblok]="data._editable" [ngStyle]="{
      'text-align': data.alignment
    }">
    <div class="form-check">
      <input class="form-check-input" [id]="data.id" [name]="data.id" type="checkbox" [formControl]="formGroup.get(data.id)">
      <label class="form-check-label" for="{{data.id}}" data-error="error">{{data.label}}</label>
      <div class='error-message' *ngIf="formGroup.get(data.id).errors !== null && (formGroup.get(data.id).touched)" [innerHtml]="formErrorsHelper.errorMessage(formGroup.get(data.id), data.label)">
      </div>
    </div>
  </div>
</ng-template>

