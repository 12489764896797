<div class="card my-3 hotel-watchlist-card">
  <div>
    <div class="watchlist-badges position-absolute m-1 w-100">
      <div *ngFor="let promo of promotions" class="badge rounded-pill badge-promo">
        <fa-icon [icon]="icons.faTag" class="pe-2"></fa-icon>
        {{promo.name}}
      </div>
      <div *ngIf="room && room.free_cancellation_datetime"
        class="badge rounded-pill text-bg-success text-capitalize float-end m-1 me-2">
        <fa-icon [icon]="icons.faCheck" class="pe-2"></fa-icon>{{ 'lodging.hotels.free_cancellation_label' | translate | lowercase }}
      </div>
      <div *ngIf="room && !room.free_cancellation_datetime" class="float-end m-1 me-2">
        <div *ngIf="room && room.refundable_verbose === 'PARTIALLY REFUNDABLE' "
          class="badge rounded-pill text-bg-success text-capitalize">
          <fa-icon [icon]="icons.faPercentage" class="pe-2"></fa-icon>{{ 'lodging.hotels.partially_refundable' | translate | lowercase }}
        </div>
        <div *ngIf="room && room.refundable_verbose !== 'PARTIALLY REFUNDABLE' "
          class="badge rounded-pill badge-gray text-capitalize float-end m-1 me-2">
          <fa-icon [icon]="icons.faExclamationCircle" class="pe-2"></fa-icon>{{ 'lodging.hotels.none_refundable' | translate | lowercase }}
        </div>
      </div>
    </div>
    <img src="{{'https://' + item.hotel_thumbnail}}" class="card-img-top hotel-image" alt="{{ item.hotel_name }}">
  </div>
  <div class="card-body">
    <div class="row primary-content">
      <div class="col-7 text-truncate">{{ item.hotel_name }}</div>
      <div class="col-5 d-flex align-items-center justify-content-end latest-rate">{{item.room_latest_display_rate | currency: item.room_display_currency}}</div>
    </div>
    <div class="row secondary-content">
      <div class="col-8 text-truncate">{{item.hotel_address}}</div>
      <div class="col-4 text-end per-night-label tertiary-content">{{ 'lodging.payments.per_night' | translate }}</div>
    </div>
    <div class="row rating-row mb-3">
      <div class="col-8">
        <rating [value]='item.hotel_star_rating' [showTextFormat]="true">
        </rating>
      </div>
      <div class="col-4">
        <small *ngIf="displayDiscount && percentSaving !== 0" class="text-end previous-price" [ngClass]="{'increased-price': !isDiscount, 'decreased-price': isDiscount}">
          <fa-icon *ngIf="isDiscount" [icon]="icons.faCaretDown"></fa-icon>
          <fa-icon *ngIf="!isDiscount" [icon]="icons.faCaretUp"></fa-icon>
          {{ percentSaving | percent:'1.0-0' }}
        </small>
      </div>
    </div>
    <div class="row secondary-content">
      <div class="col">{{dates}}</div>
    </div>
    <div class="row">
      <div class="col">
        <hotel-pricing-total [totalRate]="totalCost" [tooltip]="false" [boldRate]="true" [isPrimaryRate]="true" />
      </div>
    </div>
    <div *ngIf="item.graph_url" class="row mt-3 pt-3 border-top">
      <div class="col">
        <h5>{{ 'lodging.payments.price_variation_per_nigh' | translate }}</h5>
      </div>
    </div>
    <div *ngIf="item.graph_url" class="card-reveal mt-1">
      <img class="img-fluid" src="{{item.graph_url}}" alt="">
    </div>
  </div>
  <div class="card-footer bg-transparent">
    <button *ngIf="item.state !== 'unavailable'" type="button" class="btn btn-primary w-100 gtm-book-watchlist"
      (click)="onBookNowClicked()">{{'watchlist.hotels.view_property' | translate}}
    </button>
    <button type="button" class="btn btn-link w-100 gtm-delete-watchlist text-danger"
      (click)="onDeleteClicked()">{{'profile.watchlist.delete' | translate}}
    </button>
    <div *ngIf="item.state === 'unavailable'" class="text-danger">{{ 'watchlist.unavailable' | translate }}
    </div>
  </div>
</div>
