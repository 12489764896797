<loading-indicator *ngIf="loading"></loading-indicator>
<section class="contact-us-section" *ngIf="!loading">
  <div class="py-5 gray-blue-background map-background">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-12 my-3 mt-md-0">
          <div class="card">
            <div class="card-body contact-info pt-4">
              <h3>{{ 'contact.need_help' | translate }}</h3>
              <div class="secondary-content">{{ 'contact.live_people' | translate }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-md col-xs-12 my-3 mt-md-0">
          <div class="card h-100">
            <div class="card-body contact-info pt-4">
              <h3 class="primary-content">{{ 'contact.general_questions' | translate }}</h3>
              <div class="secondary-content">{{ 'contact.tools_questions' | translate }}</div>
              <p class="secondary-content">{{ 'contact.hours' | translate }}</p>
              <h4>{{ 'contact.phone' | translate }}</h4>
              <p class="secondary-content" *ngIf="!isWhiteLabel">
                {{ 'contact.phone_local' | translate }}
                <a href="tel:{{ 'nav.contact.support_number_nt_link' | translate }}">{{ 'nav.contact.support_number_nt' | translate }}</a>
              </p>
              <p class="secondary-content">
                {{ 'nav.contact.toll_free' | translate }}
                <a href="tel:{{ 'nav.contact.support_number_2_nt_link' | translate }}">{{ 'nav.contact.support_number_2_nt' | translate }}</a>
              </p>
              <h4>{{ 'contact.email' | translate }}</h4>
              <p class="secondary-content">
                <a href="mailto:{{ 'nav.contact.support_email' | translate }}">{{ 'nav.contact.support_email' | translate }}</a>
              </p>
            </div>
          </div>
        </div>
        <div class="col-md col-xs-12 my-3 mt-md-0">
          <div class="card h-100">
            <div class="card-body contact-info pt-4">
              <h3 class="primary-content">{{ 'contact.cruise_information' | translate }}</h3>
              <div class="secondary-content">{{ 'contact.cruise_questions' | translate }}</div>
              <p class="secondary-content">{{ 'contact.cruise_hours' | translate }}</p>
              <h4>{{ 'contact.phone' | translate }}</h4>
              <p class="secondary-content"  *ngIf="!isWhiteLabel">
                {{ 'contact.cruise_local' | translate }}
                <a href="tel:{{ 'nav.contact.id90.cruise_support_number_nt_link' | translate }}">
                  {{ 'nav.contact.id90.cruise_support_number_nt' | translate }}
                </a>
              </p>
              <p class="secondary-content">
                {{ 'nav.contact.cruise_toll_free' | translate }}
                <a href="tel:{{ 'nav.contact.support_number_3_nt_link' | translate }}">
                  {{ 'nav.contact.support_number_3_nt' | translate }}
                </a>
              </p>
              <h4>{{ 'contact.email' | translate }}</h4>
              <p class="secondary-content">
                <a href="mailto:{{ 'nav.contact.id90.cruise_email' | translate }}">{{ 'nav.contact.id90.cruise_email' | translate }}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container" *ngIf="!isWhiteLabel">
    <div class="row mt-5">
      <div class="col-sm-12 col-md-6">
        <div class="col-md-12">
          <h3>{{ 'contact.dallas' | translate }}</h3>
          <p [innerHtml]="'contact.dallas_address' | translate"></p>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3348.889708872723!2d-97.115883048782!3d32.92751208083326!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c2b3b22d432c3%3A0xa84acbdbab28b38a!2sID90+Travel!5e0!3m2!1ses-419!2sar!4v1539089523451"
            width="100%" height="250px" frameborder="0" style="border:0"></iframe>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="col-md-12">
          <h3>{{ 'contact.cordoba' | translate }}</h3>
          <p [innerHtml]="'contact.cordoba_address' | translate"></p>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3405.6323153012463!2d-64.24518294881254!3d-31.39670038131946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94329f79d3eaaae3%3A0x98b6c75dff89e948!2sID90+Travel!5e0!3m2!1ses-419!2sar!4v1539895127542"
            width="100%" height="250px" frameborder="0" style="border:0"></iframe>
        </div>
      </div>
    </div>
  </div>
</section>
