<ngb-alert *ngIf="message" (closed)="closeAlertEvent()" [dismissible]="true" type="{{ message.type }}">
  <div [innerHtml]="parseMessage(message)"></div>
</ngb-alert>
<loading-indicator [fullScreen]="!inModal" *ngIf="pending"></loading-indicator>
<ng-container *ngIf="!pending">
  <ul [ngStyle]="ulStyle" ngbNav #nav="ngbNav" [(activeId)]="currentTab" class="nav nav-justified nav-pills" (navChange)="onTabChange($event)">
    <li [ngbNavItem]="'airlineLogin'">
      <a ngbNavLink> {{ 'login_page.travel_and_tourism' | translate }}</a>
      <ng-template ngbNavContent>
        <form id="airline-employee-form-3" name="loginForm" class="mt-3" [formGroup]="loginForm" (ngSubmit)="login()">
          <airlines-autocomplete-component
            *ngIf="!isWhiteLabel"
            [airlines]="airlines"
            [codeFieldName]="'airline'"
            [control]="loginForm.get('airline')"
            [defaultValue]='airlineDefault'
            [form]="loginForm"
            [formSubmitted]="loginSubmitted"
            [isSignIn]="true"
            [placeHolder]="'airlines.headers.name' | translate"
            [action]="'sign-in'"
            (valueSelected)="setAirline($event)">
          </airlines-autocomplete-component>
          <div class="form-group">
            <div class="position-relative">
              <input
                type="text"
                id="airline-email-3"
                class="form-control with-background"
                formControlName="employee_number"
                placeholder="{{ (!isWhiteLabel ? 'login_page.email_or_employee_number_label' : 'intro_page.email_label') | translate }}"
                autocapitalize="none"
                required="required"
                autocomplete="email">
            </div>
            <div class='error-message' *ngIf="employee_number.errors !== null && (employee_number.touched)" [innerHtml]="!isWhiteLabel ? formErrorsHelper.errorMessage(employee_number, ('login_page.email_or_employee_number_label' | translate)) : formErrorsHelper.errorMessage(employee_number, ('intro_page.email_label' | translate))">
            </div>
          </div>
          <div class="form-group">
            <password-input [control]="'password'"
              [id]="'airline-password-3'"
              [form]="loginForm"
              [label]="'membership.create.password_label' | translate"
              [placeholder]="'membership.create.password_label' | translate"
              [showForgotPassword]="false"
              autocomplete="current-password"
              [showTooltip]="false">
            </password-input>
            <div class='error-message' *ngIf="password.errors !== null && (password.touched)" [innerHtml]="formErrorsHelper.errorMessage(password, ('membership.create.password_label' | translate))">
            </div>
          </div>
          <div class="form-group" *ngIf="requireRecaptcha">
            <ngx-recaptcha2 #captchaElem [siteKey]="recaptchaKey" [useGlobalDomain]="false" formControlName="recaptcha">
            </ngx-recaptcha2>
            <div class='error-message' *ngIf="recaptcha.errors !== null && (recaptcha.touched)"
                 [innerHtml]="formErrorsHelper.errorMessage(recaptcha, ('membership.create.captcha' | translate))">
            </div>
          </div>
          <div class="form-group form-check">
            <div class="row">
              <div class="col-12 text-end">
                <a class="gtm-forgot-pass" [routerLink]="'/password/new'" data-cy="forgot-password">{{ 'forgot_password?' | translate }}</a>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="!displayLoginWithConnection">
            <div class="col-md-12 col-sm-12">
              <button type="submit" class="btn btn-primary my-2 w-100">{{ 'action_terms.login' | translate }}</button>
            </div>
          </div>
          <ng-container *ngIf="displayLoginWithConnection" [ngTemplateOutlet]="loginWithLinking" [ngTemplateOutletContext]="{ formSubmit: '' }"></ng-container>
        </form>
      </ng-template>
    </li>
    <li [ngbNavItem]="'nonAirlineLogin'">
      <a ngbNavLink data-cy="friends-and-family">{{ 'login_page.friends_and_family' | translate }}</a>
      <ng-template ngbNavContent>
        <form id="friends-family-form" class="mt-3" [formGroup]="loginForm" (ngSubmit)="login('invited')">
          <div class="form-group">
            <input type="email" id="email" class="form-control with-background" placeholder="{{ 'intro_page.email_label' | translate }}" formControlName="employee_number" autocomplete="email">
            <fa-icon [icon]="icons.faEnvelope" class="input-icon"></fa-icon>
            <div class='error-message' *ngIf="employee_number.errors !== null && (employee_number.touched)" [innerHtml]="formErrorsHelper.errorMessage(employee_number, ('intro_page.email_label' | translate))">
            </div>
          </div>
            <password-input
              [control]="'password'"
              [id]="'invited-password'"
              [form]="loginForm"
              [placeholder]="'membership.create.password_label' | translate"
              [label]="'membership.create.password_label' | translate"
              [showForgotPassword]="false"
              [showTooltip]="false"
              autocomplete="current-password">
            </password-input>
            <div class='error-message' *ngIf="password.errors !== null && (password.touched)" [innerHtml]="formErrorsHelper.errorMessage(password, ('membership.create.password_label' | translate))">
          </div>
          <div class="form-group form-check">
            <div class="row mt-3">
              <div class="col-12 text-end">
                <a class="gtm-forgot-pass" [routerLink]="'/password/new/friend'" data-cy="forgot-password">{{ 'forgot_password?' | translate }}</a>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="!displayLoginWithConnection">
            <div class="col-md-12 col-sm-12">
              <button type="submit" class="btn btn-primary my-2 w-100">{{ 'action_terms.login' | translate }}</button>
            </div>
          </div>
          <div class="form-group" *ngIf="requireRecaptcha">
            <ngx-recaptcha2 #captchaElem [siteKey]="recaptchaKey" [useGlobalDomain]="false" formControlName="recaptcha">
            </ngx-recaptcha2>
            <div class='error-message' *ngIf="recaptcha.errors !== null && (recaptcha.touched)"
              [innerHtml]="formErrorsHelper.errorMessage(recaptcha, ('membership.create.captcha' | translate))">
            </div>
          </div>
          <ng-container *ngIf="displayLoginWithConnection" [ngTemplateOutlet]="loginWithLinking" [ngTemplateOutletContext]="{ formSubmit: 'invited' }"></ng-container>
        </form>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</ng-container>
<ng-container *ngIf="!pending && !displayLoginWithConnection"></ng-container>
<ng-template #loginWithLinking let-formSubmit="formSubmit">
  <div class="row third-party-login">
    <div class="col-12 col-md-12">
      <button type="submit" *ngIf="platformChosen === socials.FACEBOOK" (click)="loginAndVinculate(formSubmit, socials.FACEBOOK)" class="btn btn-facebook w-100">
        <fa-icon [icon]="icons.faFacebookSquare"></fa-icon> {{ 'login_page.third_parties.login_connect' | translate: { platform: socials.FACEBOOK } }}
      </button>
      <button type="submit" *ngIf="platformChosen === socials.GOOGLE" (click)="loginAndVinculate(formSubmit, socials.GOOGLE)" class="btn gmail-sign-btn gtm-invite w-100 fw-normal">
        <svg-icon [icon]="'google-sign-icon'" class="google-icon"></svg-icon> {{ 'login_page.third_parties.login_connect' | translate: { platform: socials.GOOGLE } }}
      </button>
      <button type="submit" *ngIf="platformChosen === socials.APPLE" (click)="loginAndVinculate(formSubmit, socials.APPLE)" class="btn apple-sign-btn w-100 fw-normal">
        <fa-icon [icon]="icons.faApple"></fa-icon>
        {{ 'login_page.third_parties.login_connect' | translate: { platform: socials.APPLE } }}
      </button>
    </div>
  </div>
</ng-template>
<ng-container *ngIf="showApple || showFacebook || showGoogle">
  <div class="row align-items-center pb-3 pt-2"  *ngIf="showApple || showFacebook || showGoogle">
    <div class="col-4">
      <hr/>
    </div>
    <div class="col-4">
      <h4 class="text-center secondary-content">{{ 'co_traveler.or' | translate }}</h4>
    </div>
    <div class="col-4">
      <hr/>
    </div>
  </div>
  <social-buttons-component [showApple]="showApple" [showFacebook]="showFacebook" [showGoogle]="showGoogle" (clickEventAction)="thirdPartyAuth($event)"></social-buttons-component>
  <div class="row" *ngIf="!isWhiteLabel">
    <div class="col-12 mt-3 text-end">
      <a class="gtm-forgot-pass" target="_blank" rel="noopener noreferrer" [href]="(currentTab === 'airlineLogin' ? logInHelpInfoAirlines : logInHelpInfoFriends)">{{ 'login_page.need_help_logging_in?' | translate }}</a>
    </div>
  </div>
</ng-container>
