export const availableToolkeys = {
  ticketing: 'ticketing',
  listing: 'listing',
  cobus: 'cobus',
  cobus_admin: 'cobus-admin',
  id90: 'id90',
  request: 'request',
  research: 'research',
  check_loads: 'check_loads',
  ticketing_invited: 'ticketing_invited'
};
