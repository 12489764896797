import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NeedARoomService {
  constructor(private http: HttpClient) { }

  getCardContent(): Observable<any>  {
    return this.http.get(`${environment.apiUrl}/api/v2/need_a_room_card.json`, {});
  }
}
