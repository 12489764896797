import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CarSearch } from 'app/cars/models/car-search';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';
import { GeneralHelper } from 'app/shared/helpers/general.helper';
import { SmartlookHelper } from 'app/shared/helpers/smartlook.helper';
import { CarsRecentSearch } from 'app/shared/models/cars-recent-search';
import { CarsRecentSearchesActions, CountryActions } from 'app/shared/store/actions';
import * as fromShared from 'app/shared/store/reducers';
import * as moment from 'moment';
import { combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'car-search-internal',
  templateUrl: './car-search-internal.html',
  styleUrls: ['./car-search-internal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarSearchInternalComponent implements OnInit, OnDestroy {
  @ViewChild('inputField', { static: false }) inputField: ElementRef;
  @ViewChild('instance', { static: false }) instance: NgbTypeahead;

  @Input() carSearch = new CarSearch();
  @Input() recentSearches: CarsRecentSearch[];
  @Input() recentSearchesPending: boolean;
  @Output() searchInitiated = new EventEmitter();
  loading = false;
  dropoff = false;
  carSearchForm: UntypedFormGroup;
  dateFormat = 'MMM DD, YYYY';
  startDate = moment();
  endDate = moment().add(1, 'day');
  times = [];
  countries$ = this.store.select(fromShared.getCountries);
  countriesLoaded$ = this.store.select(fromShared.getCountriesLoaded);
  countries;
  selectedCountry = {
    value: 'US',
    label: 'United States of America'
  };
  componentId = `_${Math.random().toString(36).substr(2, 9)}`;
  newRecentSearch: CarsRecentSearch;
  searchCountryValue: string = '';
  isMobileView = GeneralHelper.isMobileDevice();
  public isIE = GeneralHelper.isInternetExplorer();
  private ngUnsubscribe = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    public formErrorsHelper: FormErrorsHelper,
    private store: Store<fromShared.State>,
    private translate: TranslateService,
    private smartlookHelper: SmartlookHelper
  ) {}

  ngOnInit() {
    this.initTimes();
    this.countriesLoaded$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((loaded: boolean) => {
      if (!loaded) {
        this.store.dispatch(new CountryActions.LoadCountries());
      }
    });

    combineLatest([this.countries$]).pipe(takeUntil(this.ngUnsubscribe)).subscribe(([countries]) => {
      this.countries = countries;
    });
    this.carSearchForm = this.formBuilder.group({
      country: [this.carSearch.country || this.selectedCountry.value, Validators.required],
      dropoffDate: [this.carSearch.dropoffDate || moment().add(2, 'day').format(this.dateFormat).toString(), Validators.required],
      dropoffLocation: [this.carSearch.dropoffLocation || ''],
      dropoffLocationId: [this.carSearch.dropoffLocationId || ''],
      dropoffTime: [this.carSearch.dropoffTime || '10:00', Validators.required],
      over25: [true],
      pickupLocation: [this.carSearch.pickupLocation || '', Validators.required],
      pickupLocationId: [this.carSearch.pickupLocationId || '', Validators.required],
      pickupDate: [this.carSearch.pickupDate || moment().add(1, 'day').format(this.dateFormat).toString(), Validators.required],
      pickupTime: [this.carSearch.pickupTime || '10:00', Validators.required]
    });

    if (this.carSearch.dropoffLocation && this.carSearch.dropoffLocationId) {
      this.dropoff = this.carSearch.dropoffLocationId !== this.carSearch.pickupLocationId;
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobileView = GeneralHelper.isMobileDevice();
  }

  searchCountry = (searchString$: Observable<string>) => searchString$.pipe(
    takeUntil(this.ngUnsubscribe),
    debounceTime(100),
    distinctUntilChanged(),
    map(term => term.length < 2
      ? []
      : this.getCountriesFiltered(term)
    )
  )

  getCountriesFiltered(term: string): any[] {
    this.searchCountryValue = term;
    return this.getCountriesByText(term);
  }

  getCountriesByText(term: string): any[] {
    return this.countries.filter(countries => countries.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10);
  }

  countryOnBlur(): void {
    if (this.searchCountryValue) {
      const result = this.getCountriesByText(this.searchCountryValue);
      const item = result && result.length > 0 ? result[0] : { code: '', name: '' };

      this.selectedCountry = { value: item.code, label: item.name };
      this.setFormValue('country', this.selectedCountry.value);
      this.searchCountryValue = '';
    }

    if (!this.isCountrySelected()) {
      this.setFormValue('country', null);
      this.inputField.nativeElement.value = null;
      this.carSearchForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    }
  }

  isCountrySelected() {
    const value = this.carSearchForm.get('country').value;
    return this.countries.find(country => country.code === value);
  }

  formatterCountry = (country: { name: string }) => {
    return country.name;
  }

  search() {
    if (this.carSearchForm.valid) {
      const pickupLocationValue = this.carSearchForm.value.pickupLocation;
      if (pickupLocationValue.displayName) {
        this.setFormValue('pickupLocation', pickupLocationValue.displayName);
      }

      const dropoffLocationValue = this.carSearchForm.value.dropoffLocation;
      if (dropoffLocationValue && dropoffLocationValue.displayName) {
        this.setFormValue('dropoffLocation', dropoffLocationValue.displayName);
      }

      const countryValue = this.carSearchForm.value.country;
      if (countryValue.code) {
        this.setFormValue('country', countryValue.code);
      }

      if (
        !this.recentSearches ||
        !this.recentSearches.find(x => x.pick_up_location === this.carSearchForm.value.pickupLocation)
      ) {
        this.newRecentSearch = {
          created_at: new Date().toString(),
          country: this.carSearchForm.value.country,
          age: this.carSearchForm.value.over25 ? 25 : 20,
          drop_off_datetime: this.endDatetime,
          drop_off_location: this.carSearchForm.value.dropoffLocation,
          drop_off_location_id: this.carSearchForm.value.dropoffLocationId,
          pick_up_datetime: this.startDatetime,
          pick_up_location: this.carSearchForm.value.pickupLocation,
          pick_up_location_id: this.carSearchForm.value.pickupLocationId
        };
      }

      if (this.newRecentSearch) {
        this.store.dispatch(new CarsRecentSearchesActions.SaveSearch(this.newRecentSearch));
      }

      this.searchInitiated.emit(this.carSearchForm.value);
      this.smartlookHelper.customEvent('Cars - Search start', {
        country: this.carSearchForm.value.country,
        drop_off_datetime: this.endDatetime,
        drop_off_location: this.carSearchForm.value.dropoffLocation,
        pick_up_datetime: this.startDatetime,
        pick_up_location: this.carSearchForm.value.pickupLocation,
      });
      this.router.navigate(['/cars/search', this.carSearchForm.value]);

    } else {
      Object.keys(this.carSearchForm.controls).forEach((field) => {
        const control = this.carSearchForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  countriesSelected(countrySelected) {
    this.searchCountryValue = '';
    this.setFormValue('country', countrySelected.item.code);
  }

  locationSelected(item, loc) {
    if (loc === 'pickup') {
      this.setFormValue('pickupLocation', item.displayName);
      this.setFormValue('pickupLocationId', item.id);
      if (!this.dropoff) {
        this.setFormValue('dropoffLocation', item.displayName);
        this.setFormValue('dropoffLocationId', item.id);
      }
    } else if (loc === 'dropoff') {
      this.setFormValue('dropoffLocation', item.displayName);
      this.setFormValue('dropoffLocationId', item.id);
    }
  }

  onDropOffChange(event) {
    this.dropoff = !this.dropoff;
    const dropoffLocation = this.carSearch.dropoffLocation || '';
    const dropoffLocationId = this.carSearch.dropoffLocationId || '';
    const pickupLocation = this.getFormValue('pickupLocation');
    const pickupLocationId = this.getFormValue('pickupLocationId');

    if (!this.dropoff) {
      // set dropoff and pickup same location if dropoff checkbox not selected
      this.setFormValue('dropoffLocation', pickupLocation);
      this.setFormValue('dropoffLocationId', pickupLocationId);
    } else {
      // show empty dropoff location if pickup and drop off are same else show the previous dropofflocation selected
      this.setFormValue('dropoffLocation', (dropoffLocation === pickupLocation) ? '' : dropoffLocation);
      this.setFormValue('dropoffLocationId', (dropoffLocationId === pickupLocationId) ? '' : dropoffLocationId);
    }
  }

  onDatesSelected(event) {
    this.setFormValue('pickupDate', event.startDate);
    this.setFormValue('dropoffDate', event.endDate);
  }

  getFormValue(input) {
    return this.carSearchForm.controls[input].value;
  }

  setFormValue(input, value) {
    this.carSearchForm.get(input).setValue(value);
  }

  initTimes() {
    const time = moment();
    time.hours(0);
    time.minutes(0);
    for (let i = 0; i < 48; i++) {
      this.times.push({ value: time.format('HH:mm'), display: time.format('hh:mm A') });
      time.add(30, 'm');
    }
  }

  get pickupLocation() { return this.carSearchForm ? this.carSearchForm.get('pickupLocation') : null; }

  get pickupLocationId() { return this.carSearchForm ? this.carSearchForm.get('pickupLocationId') : null; }

  get pickupLocationError()  {
    return this.formErrorsHelper.errorMessage(
      this.pickupLocation as UntypedFormControl,
      this.translate.instant('cars.form_labels.pickup_location')
    );
  }
  get country() { return this.carSearchForm ? this.carSearchForm.get('country') : null; }
  get countryError() {
    return this.formErrorsHelper.errorMessage(
      this.country as UntypedFormControl,
      this.translate.instant('cars.form_labels.country_of_residence')
    );
  }
  get startDatetime() {
    return `${this.carSearchForm.value.pickupDate} ${this.carSearchForm.value.pickupTime}`;
  }
  get endDatetime() {
    return `${this.carSearchForm.value.dropoffDate} ${this.carSearchForm.value.dropoffTime}`;
  }
  get mobileView() {
    return window.screen.width < 576;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
