import { Component, Input, EventEmitter, Output, OnInit, OnChanges, SimpleChanges, ViewChild, ElementRef, ViewEncapsulation,  AfterViewInit, QueryList, ViewChildren, OnDestroy } from '@angular/core';
import { PaymentInformation } from 'app/shared/models/payment-information';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CreditCard } from 'app/shared/models/credit-card';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreditInformation } from 'app/shared/models/credit-information';
import { AddressFormComponent } from 'app/shared/components/address/address-form.component';
import { StoredPaymentSelectionComponent } from '../stored-payment-selection/stored-payment-selection.component';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';
import { CreditCardHelper } from 'app/shared/helpers/credit-card.helper';
import { PaymentMethod } from 'app/profile/models/payment-method';
import { CvvModalComponent } from 'app/shared/components/cvv-modal/cvv-modal.component';
import { Store, select } from '@ngrx/store';
import * as fromShared from 'app/shared/store/reducers';
import { AffirmService } from 'app/shared/services/affirm/affirm.service';
import { FeatureFlagsService } from 'app/shared/services/featureFlags/featureFlags.service';
import { featureFlags } from 'app/shared/models/featureFlags';
import { faCreditCard, faCalendarAlt, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'payment',
  templateUrl: 'payment.html',
  styleUrls: ['payment.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PaymentComponent implements OnInit, OnChanges , AfterViewInit, OnDestroy  {
  @ViewChild(AddressFormComponent, { static: false }) addressForm: AddressFormComponent;
  @ViewChild('expirationDateField', { static: false }) expirationDateField: ElementRef;
  @ViewChild('cvvField', { static: false }) cvvField: ElementRef;
  @ViewChildren('message') message: QueryList<any>;

  @Input() paymentInformation: PaymentInformation;
  @Input() paymentMethods: CreditCard[];
  @Input() creditInformation: CreditInformation;
  @Input() totalPayment: number;
  @Input() bookingData: any;
  @Input() userData: any = null;
  @Input() currency: any;
  @Input() rawCCOnly = false;
  @Input() showApplyCredits = true;
  @Input() addressFields: any = {};
  @Input() personalDetails: any = {};
  @Input() preferBusinessCard = false;
  @Input() showIsBusinessCheckbox = false;
  @Input() availablesCreditCards: Array<string>;
  @Input() cashOptionAvailable = false;
  @Input() showAffirm = false;
  @Input() showPaypal = false;
  @Input() isInsuranceSelected: boolean;
  @Input() cityMaxLength = 100;
  @Input() addressMaxLength = 100;
  @Output() goBackClick = new EventEmitter();
  @Output() billingAddressSelected = new EventEmitter();
  @Output() changePaymentMethod = new EventEmitter();
  @Output() toggleStealthCredits = new EventEmitter();
  paymentForm: UntypedFormGroup;
  reverify = false;
  selectedCard: CreditCard;
  ready = false;
  showNewPaymentMethod = true;
  ccvMaxLength = 3;
  enabledPaymentMethods: CreditCard[];
  allowedCreditsCardsIcons = CreditCardHelper.ALLOWED_HOTEL_CARDS;
  cashPaymentOption = 'CA';
  airlineFlags$ = this.store.pipe(select(fromShared.getAirlineFlags));
  observerMessage;
  messageSubscription;
  featureFlags = featureFlags;
  icons = { faCreditCard, faCalendarAlt, faInfoCircle };
  showCreditCard = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public formErrorsHelper: FormErrorsHelper,
    private modalService: NgbModal,
    private store: Store<fromShared.State>,
    private featureFlagsService: FeatureFlagsService,
    private affirmService: AffirmService
  ) {}

  ngOnInit() {
    this.paymentForm = this.formBuilder.group({
      paymentSelection: ['cc', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      cardNumber: ['',  Validators.compose(this.getCardNumberValidators())],
      expiration: ['', Validators.compose([Validators.required, CreditCardHelper.expireDateValidator()])],
      ccv: ['', Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(4),
        Validators.pattern('^[0-9]*$')
      ])],
      isBusiness: [false],
      savePayment: [false],
      isCashPayment: [false]
    });

    if (this.rawCCOnly) { this.ready = true; }

    this.getAllowedCreditCardsIcons();
    if (this.availablesCreditCards) {
      this.showCreditCard = !!this.availablesCreditCards.length
    }
  }

  ngAfterViewInit() {
    if (this.currency === 'USD' || this.currency === 'CAD') {
      this.messageSubscription = this.message.changes.subscribe((changes) => {
        this.affirmService.affirmUiRefresh();
        if (changes?.last?.nativeElement) {
          this.updateAffirmMessage(changes.last.nativeElement);
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.firstChange) return;
    if (!this.rawCCOnly && changes.paymentMethods && changes.paymentMethods.currentValue) {
      this.retrievePaymentMethods();
    }

    if (changes.paymentInformation && this.paymentForm) {
      let paymentMethod = changes?.paymentInformation?.currentValue?.isPayPalToken ? 'paypal' : undefined;
      paymentMethod = !paymentMethod && changes?.paymentInformation?.currentValue?.isAffirmToken ? 'affirm' : undefined;
      paymentMethod = !paymentMethod ? paymentMethod : 'cc';
      this.select(paymentMethod);
    }
    if (changes.showAffirm && !changes.showAffirm?.currentValue && this.paymentInformation?.isAffirmToken) {
      this.select('cc');
    }
  }

  get firstName()         { return this.paymentForm ? this.paymentForm.get('firstName') : null; }
  get lastName()          { return this.paymentForm ? this.paymentForm.get('lastName') : null; }
  get cardNumber()        { return this.paymentForm ? this.paymentForm.get('cardNumber') : null; }
  get expiration()        { return this.paymentForm ? this.paymentForm.get('expiration') : null; }
  get ccv()               { return this.paymentForm ? this.paymentForm.get('ccv') : null; }
  get paymentSelection()  { return this.paymentForm ? this.paymentForm.get('paymentSelection') : null; }

  select(method: string) {
    this.paymentInformation.isPayPalToken = (method === 'paypal');
    this.paymentInformation.isAffirmToken = (method === 'affirm');
    this.paymentForm.get('paymentSelection').setValue(method);
    this.paymentInformation.isCash = this.setCashPayment(method);

    if (this.paymentInformation.isPayPalToken) {
      this.bookingData.updatePayPalTotal();
    }
    this.billingAddressSelected.emit();
    this.changePaymentMethod.emit(method);
  }

  setCashPayment(method: string): boolean {
    return method === this.cashPaymentOption;
  }

  setCVVValidators() {
    if (this.paymentInformation.cardType === 'amex') {
      this.ccv.setValidators([
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4),
        Validators.pattern('^[0-9]*$')
      ]);
      this.ccvMaxLength = 4;
    } else {
      this.ccv.setValidators([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(3),
        Validators.pattern('^[0-9]*$')
      ]);
      this.ccvMaxLength = 3;
    }
    this.ccv.updateValueAndValidity();
  }

  formatCreditCard() {
    const control = this.cardNumber;
    let number = control.value.replace(/ |\D/g, '');
    const type = CreditCardHelper.getCreditCardType(number);
    if (number && type.code.length) {
      this.paymentInformation.cardType = type.code;
      this.setCVVValidators();
      const newNumber = [];
      type.mask.forEach((value) => {
        if (number.length > value[0]) {
          newNumber.push(number.slice(value[0], value[1]));
        }
      });
      if (number.length === type.valid_length[0] && control.valid) {
        this.expirationDateField.nativeElement.focus();
      }
      number = newNumber.join(' ');
    } else if (!type.code.length) {
      this.paymentInformation.cardType = '';
    }
    this.paymentInformation.cardNumber = number;
    control.setValue(number);
  }

  formatDate(event) {
    const backspace = event.inputType === 'deleteContentBackward';
    const control = this.expiration;
    const date = control.value.replace(/\/|\D/g, '');
    let formattedDate = date;

    if (date.length === 1 && !backspace && parseInt(date, 10) > 1) {
      formattedDate = `0${date}/`;
    } else if (date.length === 2 && backspace) {
      formattedDate = date.substr(0, 1);
    } else if (date.length >= 2) {
      const month = date.substr(0, 2);
      const year = date.substr(2, 2);
      this.paymentInformation.expirationMonth = month;
      this.paymentInformation.expirationYear = `20${year}`;
      this.paymentInformation.expiration = `${month}/${year}`;
      formattedDate = `${month}/${year}`;
      if (date.length === 4 && control.valid) { this.cvvField.nativeElement.focus(); }
    }

    this.paymentInformation.formatExpirationDate(formattedDate);
    control.setValue(formattedDate);
  }

  checkCvv() {
    const control = this.ccv;
    const number = control.value.replace(/\D/g, '');
    this.paymentInformation.ccv = number;
    control.setValue(number);
    if (this.ccv.valid && this.addressForm && this.addressForm.showStreet) {
      this.addressForm.addressStreet.inputField.nativeElement.focus();
    }
  }

  retrievePaymentMethods() {
    this.checkEnabledPaymentMethods();
    if (this.enabledPaymentMethods.length && !this.reverify) {
      this.selectedCard = this.enabledPaymentMethods.find((card: CreditCard) => {
        if (this.preferBusinessCard) { return card.is_business === '1'; }
        return card.preferred === '1';
      });
      // In the event that there is no "preferred" card, we'll just set the active card to
      // the first one.
      if (!this.selectedCard) {
        this.selectedCard =  this.enabledPaymentMethods[0];
      }
      this.toggleForm(false);
      this.paymentInformation.applyCardValues(this.selectedCard);
    } else {
      this.reverify = false;
      if (this.featureFlagsService.isFeatureEnabled(featureFlags.PAYMENT_METHODS)) {
        this.paymentForm.patchValue({ savePayment: true });
      }
    }

    this.ready = true;
  }

  toggleForm(show) {
    this.showNewPaymentMethod = show;
    this.paymentInformation.resetCard();
    if (this.showNewPaymentMethod) {
      this.paymentInformation.isWorldpayToken = false;
      this.paymentInformation.isPayPalToken = false;
      this.enablePaymentFormRequiredFields();
    } else {
      this.paymentInformation.applyCardValues(this.selectedCard);
      this.paymentInformation.isWorldpayToken = true;
      this.paymentInformation.isPayPalToken = false;
      this.disablePaymentFormRequiredFields();
    }
  }

  enablePaymentFormRequiredFields() {
    this.firstName.enable();
    this.lastName.enable();
    this.cardNumber.enable();
    this.ccv.enable();
    this.expiration.enable();
  }

  disablePaymentFormRequiredFields() {
    this.firstName.disable();
    this.lastName.disable();
    this.cardNumber.disable();
    this.ccv.disable();
    this.expiration.disable();
  }

  showPaymentMethods() {
    const modalRef = this.modalService.open(StoredPaymentSelectionComponent, { size: 'lg' });
    modalRef.componentInstance.paymentMethods = this.paymentMethods;
    modalRef.componentInstance.isBusinessTravel = this.preferBusinessCard;
    modalRef.componentInstance.allowEdit = true;
    modalRef.result.then(
      (selectedCard) => {
        if (selectedCard && !selectedCard.noCardsAvailable) {
          this.selectedCard = selectedCard;
          this.paymentInformation.applyCardValues(this.selectedCard);
          this.billingAddressSelected.emit();
        } else if (selectedCard?.noCardsAvailable){
          this.showNewPaymentMethod = true;
          this.selectedCard = null;
          this.toggleForm(true);
        }
      },
      (error) => {
        this.paymentInformation.applyCardValues(this.selectedCard);
      });
  }

  showCvvModal() {
    const modalRef = this.modalService.open(CvvModalComponent, { size: 'lg' });
    modalRef.componentInstance.cardType = this.paymentInformation.cardType;
  }

  // Call back to update toal after Credit is applied
  updateTotal() {
    this.bookingData.updateTotal();
  }

  isValid() {
    if (this.bookingData?.paymentInformation?.isAffirmToken) {
      return !!(this.bookingData.paymentInformation.affirmToken)
    }

    if (this.addressForm) {
      return this.paymentInformation.isCash ||
        (this.paymentForm.valid && this.addressForm.isValid()) || this.selectedCard ||
        (this.paymentInformation.isPayPalToken && this.paymentInformation.authorizationId);
    }

    return this.paymentInformation.isCash || (this.paymentForm.valid) || this.selectedCard ||
      (this.paymentInformation.isPayPalToken && this.paymentInformation.authorizationId);
  }

  checkEnabledPaymentMethods() {
    for (const i in this.paymentMethods) {
      this.paymentMethods[i].disabled = this.isForeignCurrencyBooking() &&
        CreditCardHelper.NO_FOREIGN_CURRENCY_CARDS.indexOf(this.paymentMethods[i].payment_method_id) !== -1;

      this.paymentMethods[i].isExpired = CreditCardHelper.isExpired(this.paymentMethods[i].expiration_date);
    }
    this.enabledPaymentMethods = this.paymentMethods.filter((card) => !card.disabled);
  }

  getCardNumberValidators() {
    const validators = [Validators.required, CreditCardHelper.acceptedCard(this.availablesCreditCards)];
    if (this.isForeignCurrencyBooking()) {
      validators.push(CreditCardHelper.isForeignCurrencyCompatible());
    }
    return validators;
  }

  getAllowedCreditCardsIcons() {
    if (this.availablesCreditCards?.length) {
      this.allowedCreditsCardsIcons = this.availablesCreditCards;
    } else if (this.cashOptionAvailable) {
      this.select(this.cashPaymentOption);
    }
  }

  isForeignCurrencyBooking() {
    return this.currency && this.currency !== 'USD';
  }

  checkNames() {
    const guestName = this.personalDetails.firstName ?
      this.personalDetails.firstName.concat(this.personalDetails.lastName.toString()) : '';
    const holderName = this.paymentInformation.firstName.concat(this.paymentInformation.lastName.toString());
    return guestName && guestName !== holderName;
  }

  mapCreditCardParams(): PaymentMethod {
    const paymentData = this.paymentForm.value;
    const addressData = this.addressForm.addressForm.value || {};
    return {
      cc_type: CreditCardHelper.getCreditCardType(paymentData.cardNumber).code,
      cc_number: paymentData.cardNumber.replace(/\s/g, ''),
      ccv: paymentData.ccv,
      expiration_month: paymentData.expiration.split('/')[0],
      expiration_year: `20${paymentData.expiration.split('/')[1]}`,
      first_name: paymentData.firstName,
      last_name: paymentData.lastName,
      address: addressData.street,
      zip: addressData.zipCode,
      city: addressData.city,
      state: addressData.state,
      country: addressData.country,
      isWorldpayToken: true,
      is_business: paymentData.isBusiness
    };
  }

  addressSelected(address) {
    this.billingAddressSelected.emit(address);
  }
  get userHasCredit() {
    if (!this.creditInformation) return this.showApplyCredits;
    return this.creditInformation.userCredit > 0 && this.showApplyCredits;
  }
  formatValueAffirm() {
    return this.affirmService.formatTotalForAffirm(this.bookingData.total());
  }
  updateAffirmMessage(el) {
    this.observerMessage = new MutationObserver(() => this.affirmService.affirmUiRefresh());
    const config = { attributes: true, childList: false, characterData: false }; // only lisen the data attribute change
    this.observerMessage.observe(el, config);

  }
  canEditCard(card: CreditCard) {
    return card.is_business === '1' ? this.userData?.idMembers === Number(card.idMembers) : true;
  }
  ngOnDestroy(): void {
    this.observerMessage?.disconnect();
    this.messageSubscription?.unsubscribe();
  }
}
