import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoutesService {

  private currentUrl;

  constructor(router: Router) {
    router.events.pipe(
        filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.previousUrl.next(this.currentUrl);
      this.currentUrl = event.url;
    });
  }

  private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  public previousUrl$: Observable<string> = this.previousUrl.asObservable();

}
