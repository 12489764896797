import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    NgZone,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { AuthApiActions } from 'app/auth/store';
import * as fromAuth from 'app/auth/store/reducers';
import { MFAHelper } from 'app/profile/helpers/mfa.helper';
import { CreditActions, CreditHistoryActions, MemberLoyaltyActions, PaymentMethodsActions, ProfileUserActions } from 'app/profile/store/actions';
import { GetMemberInterests } from 'app/profile/store/actions/member-interests.actions';
import * as fromProfile from 'app/profile/store/reducers';
import { GeneralHelper } from 'app/shared/helpers/general.helper';
import { PhoneNumberHelper } from 'app/shared/helpers/phone-number.helper';
import { SanitizerHelper } from 'app/shared/helpers/sanitizer.helper';
import { Country } from 'app/shared/models/country.model';
import { featureFlags } from 'app/shared/models/featureFlags';
import { FeatureFlagsService } from 'app/shared/services/featureFlags/featureFlags.service';
import { GamificationService } from 'app/shared/services/gamification/gamification.service';
import { MemberService } from 'app/shared/services/member/member.service';
import { SeoService } from 'app/shared/services/seo/seo.service';
import { CountryActions } from 'app/shared/store/actions';
import { getStatistics } from 'app/shared/store/actions/gamification.actions';
import * as fromShared from 'app/shared/store/reducers';
import { CustomValidators } from 'app/shared/validators/custom-validators';
import * as fromRoot from 'reducers';
import { combineLatest, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { EmploymentTypes } from "app/auth/models/user";
import { EXEMPTED_SSO_RETIREE_AIRLINES } from "app/shared/models/airlines";

@Component({
  selector: 'profile',
  templateUrl: './profile.html',
  styleUrls: ['./profile.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProfilePage implements OnInit, OnDestroy, AfterViewChecked {

  @ViewChild('nav', { static: false }) private tabs: NgbNav;
  selectedTab: string;
  currentOrientation = 'vertical';
  section = 'profile';

  loading = true;
  profileForm: UntypedFormGroup;
  mailchimpPreferencesForm: UntypedFormGroup;
  fieldGroups;
  profileStructure;
  addressInfo;
  currentJustify = 'justified';
  paymentMethods: any;
  hideTabs = false;
  hideCommunicationPreferences = true;
  isMobile = false;
  showPassport = false;
  routes = {
    menu: '/profile/menu',
    edit: '/profile/edit',
    address: '/profile/address',
    employment: '/profile/employment',
    passport: '/profile/passport',
    travelers: '/profile/travelers',
    payment_methods: '/profile/payment_methods',
    login_and_security: '/profile/login_and_security',
    hotel_credits: '/profile/hotel_credits',
    communication_preferences: '/profile/communication_preferences',
    suite_dreams_sweepstakes: "profile/suite_dreams_sweepstakes"

  };
  ccParams;
  icParams;
  userTools$ = this.store.select(fromAuth.getAvailableTools).pipe(take(1));
  userCredits$ = this.store.select(fromProfile.getUserCredits);
  userCreditHistory$ = this.store.select(fromProfile.getUserCreditHistory);
  memberLoyaltySummary$ = this.store.select(fromProfile.getMemberLoyalty);
  isPartner$ = this.store.pipe(select(fromAuth.getPartner));
  availableTools$ = this.store.pipe(select(fromAuth.getAvailableTools));
  memberSavings$ = this.memberService.getMemberSavings();
  partner = false;
  showEligibleTravelers = false;
  pageInitializated = false;
  configFlags$ = this.store.pipe(select(fromShared.getConfigFlag));
  featureFlags$ = this.store.pipe(select(fromShared.getFeatureFlags));
  featureFlags = featureFlags;
  userStatistics$ = this.store.pipe(select(fromAuth.getUserStatistics));
  userStatistics: any;
  allInterests$ = this.store.pipe(select(fromProfile.getAllInterests));
  allInterests = [];
  memberInterestsSuccess$ = this.store.pipe(select(fromProfile.getMemberInterestsSuccess));
  memberInterestsPending$ = this.store.pipe(select(fromProfile.getMemberInterestsPending));
  private ngUnsubscribe = new Subject<void>();
  public isInvitedTraveler: boolean;
  userAccount$ = this.store.pipe(select(fromAuth.selectAuthStatusState));
  userData;
  requestMFA = false;
  mfaRequest$ = this.store.pipe(select(fromAuth.mfaRequest));
  getPaymentsMethods$ = this.store.pipe(select(fromProfile.getPaymentMethods));
  isFraudUser$ = this.store.pipe(select(fromAuth.getFraudStatus));
  isFraudUser = false;
  profileUser$ = this.store.pipe(select(fromProfile.getUserProfile));
  profileUser;
  profileConfig$ = this.store.pipe(select(fromProfile.getProfileConfig));
  profileConfig;
  countries$ = this.store.pipe(select(fromShared.getCountries));
  countriesLoaded$ = this.store.pipe(select(fromShared.getCountriesLoaded));
  countries:Country[];
  airlines$ = this.store.pipe(select(fromShared.getAirlines));
  loginEnabled = true;
  blockDomains = [];
  getGamificationStatistics$ = this.store.pipe(select(fromShared.getGamificationStatistics));
  activeCampaigns$ = this.store.select<any>(fromShared.getActiveCampaignsGamification);
  gamificationSectionEnable = false;
  loadingCredits = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private generalHelper: GeneralHelper,
    private memberService: MemberService,
    private ngZone: NgZone,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store<fromRoot.State>,
    private seoService: SeoService,
    private phoneNumberHelper: PhoneNumberHelper,
    private cdRef: ChangeDetectorRef,
    private mfaHelper: MFAHelper,
    private featureFlagsService: FeatureFlagsService,
    private sanitizerHelper: SanitizerHelper,
    private gamificationService: GamificationService
  ) { }

  ngOnInit() {
    this.seoService.setTitle('Profile');
    this.prepareUrlParams();
    this.listenSuccess();
    combineLatest([this.isPartner$, this.availableTools$]).pipe(take(1)).subscribe((results) => {
      this.isInvitedTraveler = results[1].includes('ticketing_invited');
      this.partner = results[0] && !this.isInvitedTraveler;
    });
    this.isMobile = window.innerWidth < 768;
    this.activatedRoute.params.subscribe((params) => {
      if (params.tab) {
        this.selectedTab = params.tab;
      }
    });

    combineLatest([this.userAccount$, this.mfaRequest$, this.isFraudUser$, this.featureFlags$, this.airlines$])
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe(([userAccount, mfaRequest, isFraudUser, features, airlines ]) => {
        if (userAccount.user) {
          this.userData = userAccount.user;
          this.blockDomains = GeneralHelper.getBlockDomains(airlines, userAccount.user.member.airline_code);
          this.requestMFA = this.mfaHelper.requestMFA(userAccount.user, features, mfaRequest);
          this.isFraudUser = isFraudUser;
          this.loginEnabled = this.featureFlagsService.isFeatureEnabled(this.featureFlags.LOGIN);
          this.store.dispatch(getStatistics())
        }
      });

    this.userStatistics$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((statistics: any) => {
      if (statistics) {
        this.userStatistics = statistics;
      }
    });

    combineLatest([this.activeCampaigns$, this.getGamificationStatistics$]).pipe(takeUntil(this.ngUnsubscribe)).subscribe(([activeCampaigns, statistics]) => {
      const campaign = this.gamificationService.getParticipatingCampaign(activeCampaigns);
      this.gamificationSectionEnable = this.showGamificationSection(campaign, statistics);
    });

    this.countriesLoaded$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((loaded: boolean) => {
      if (!loaded) {
        this.store.dispatch(new CountryActions.LoadCountries());
      }
    });

    this.memberLoyaltySummary$.pipe(take(1)).subscribe((summary: any) => {
      if (!summary && this.profileUser) {
        this.store.dispatch(new MemberLoyaltyActions.LoadLoyalty(this.profileUser.currency));
      }
    });

    combineLatest([this.profileUser$, this.profileConfig$,this.countries$]).pipe(takeUntil(this.ngUnsubscribe)).subscribe(([profileUser,profileConfig,countries]) => {
      this.countries=countries;
      if (!profileUser) {
        this.store.dispatch(new ProfileUserActions.LoadUserProfile());
      } else if (!profileConfig) {
          this.store.dispatch(new ProfileUserActions.LoadConfigProfile());
      } else {
        this.profileUser = profileUser;
        this.profileConfig = profileConfig;
        this.initPage();
      }
    });

    this.store.dispatch(new CreditActions.LoadCredits());
    this.store.dispatch(new CreditHistoryActions.LoadCredits());
  }

  initPage() {
    if (!this.pageInitializated) {
      this.store.select<any>(fromProfile.getProfileStructure).pipe(takeUntil(this.ngUnsubscribe)).subscribe((structure) => {
        if (structure) {
          this.profileStructure = structure;
          this.showPassport = this.isPassportEnabled();
          this.getPaymentMethods();
          this.hasEligibleTravelers();
          this.createForm();
          if (!this.hideCommunicationPreferences) {
            this.getAllInterests();
            this.prepareMailchimpPreferencesForm();
          }
          this.pageInitializated = true;
          this.loading = false;
        } else {
          this.store.dispatch(new ProfileUserActions.LoadUserProfileStructure());
        }
      });
    } else {
      this.createForm();
    }
  }

  private showGamificationSection(campaign, statistics) {
    if (campaign?.finished && !this.gamificationService.goalIsMet(statistics)) return false;
    if ((campaign?.finished || this.gamificationService.goalIsMet(statistics)) && !campaign?.user_participating) return false;
    return campaign?.redeemable;
  }

  isPassportEnabled() {
    if (!this.profileConfig) return false;
    return (this.profileConfig.passportFirstName && this.profileConfig.passportFirstName.show) ||
      (this.profileConfig.passportMiddleName && this.profileConfig.passportMiddleName.show) ||
      (this.profileConfig.passportLastName && this.profileConfig.passportLastName.show) ||
      (this.profileConfig.passportNationality && this.profileConfig.passportNationality.show) ||
      (this.profileConfig.passportNumber && this.profileConfig.passportNumber.show) ||
      (this.profileConfig.passportNumberDomestic && this.profileConfig.passportNumberDomestic.show) ||
      (this.profileConfig.passportExpirationDate && this.profileConfig.passportExpirationDate.show) ||
      (this.profileConfig.passportIssuanceCountry && this.profileConfig.passportIssuanceCountry.show) ||
      (this.profileConfig.passportIssuanceDate && this.profileConfig.passportIssuanceDate.show) ||
      (this.profileConfig.passportCountryBirth && this.profileConfig.passportCountryBirth.show);
  }

  ngAfterViewChecked(): void {
    if (this.tabs && this.selectedTab !== 'menu' ) {
      this.tabs.select(this.selectedTab);
    }
    if (this.selectedTab === 'menu') {
      this.hideTabs = false;
    }
    this.cdRef.detectChanges();
  }

  onTabChange($event) {
    if (this.routes[$event.target.parentElement.id]) {
      let redirect = this.ccParams ? `${this.routes[$event.target.parentElement.id]}?cc=${this.ccParams}` : this.routes[$event.target.parentElement.id];
      if (this.icParams) {
        redirect = `${redirect}&ic=${this.icParams}`;
      }
      this.selectedTab = $event.target.parentElement.id;
      this.hideTabs = true;
      return this.router.navigateByUrl(redirect);
    }
  }

  createForm() {
    const group = [];
    let formElements = {};
    for (const section of this.profileStructure) {
      for (const row of section.rows) {
        for (const item of row.items) {
          // Remove mobilePhone from the FlightAPI when we are sure we don't want to ask it anymore
          if (this.profileConfig[item.key] && item.key !== 'mobilePhone') {
            if (item.type === 'tel') {
              formElements[item.key] = this.getPhoneElement(item.key);
            } else if (item.type === 'name') {
              const nameItem = this.getFormElement(item.key, item.type);
              if (!nameItem[0].value) { nameItem[0].disabled = false; }
              if (item.key === 'firstName' || item.key === 'lastName') { nameItem[0].disabled = !!nameItem[0].value; }
              if (item.key === 'firstName' || item.key === 'lastName' || item.key === 'middleName') {
                nameItem[0].value = this.sanitizerHelper.decodeValue(nameItem[0].value) }
              formElements[item.key] = nameItem;
            } else {
              formElements[item.key] = this.getFormElement(item.key, item.type);
            }
          }
        }
      }
      group.push(formElements);
      formElements = {};
    }
    this.fieldGroups = { employee: this.formBuilder.group(group[0]) };
    if (group[1]) {
      this.fieldGroups['address'] = this.formBuilder.group(group[1]);
    }
    if (group[2]) {
      this.fieldGroups['passport'] = this.formBuilder.group(group[2]);
    }
    if (group[3]) {
      this.fieldGroups['employment'] = this.formBuilder.group(group[3]);
    }

    if (this.fieldGroups['employee'] && this.fieldGroups['employee'].get('knownTravelerNumber')) {
      this.fieldGroups['employee'].get('knownTravelerNumber')
        .setValidators([CustomValidators.text, CustomValidators.textWithoutBlankSpaces]);
    }
    if(this.fieldGroups['employee'] && this.fieldGroups['employee'].get('personalEmail')) {
      this.fieldGroups['employee'].get('personalEmail').setValidators(CustomValidators.checkBlockDomain(this.blockDomains))
    }

    this.profileForm = this.formBuilder.group(this.fieldGroups);
    this.addressInfo = this.profileForm.get('address');
  }

  getFormElement(key, type) {
    const elements = [];
    const attributes = {
      value: this.profileUser[key] ? this.profileUser[key] : null,
      disabled: !this.profileConfig[key].enabled
    };
    const validators = [];
    elements.push(attributes);
    let formType = (type === 'name') ? 'nameField' : type;
    formType = (key === 'passportFirstName' || key === 'passportMiddleName' || key === 'passportLastName') ? 'passportName' : formType;
    formType = (key === 'passportNumber' || key === 'passportNumberDomestic') ? 'textAndHyphen' : formType;
    if (this.profileConfig[key].required) {
      validators.push(Validators.required);
    }
    if (CustomValidators[formType]) {
      validators.push(CustomValidators[formType]);
    }
    if (validators) {
      elements.push(Validators.compose(validators));
    }
    return elements;
  }

  getPhoneElement(key): UntypedFormGroup {
    const phone = this.profileUser[key].split('|');
    const foundCountry = (Number(phone[0]) === 1) ?
    this.countries?.find(country => `${country.phone}${country.area_code}` === `${phone[0]}${phone[1]}`) :
    this.countries?.find(country => country.phone === parseInt(phone[0], 10));
    const country = foundCountry ? foundCountry : this.countries[0];

    const phoneRequired = this.profileConfig[key].required;
    const phoneEnabled = this.profileConfig[key].enabled;

    const phoneGroup = this.phoneNumberHelper.createPhoneFormGroup(phoneRequired, phoneRequired);

    phoneGroup.get('country').reset({ value: country, disabled: !phoneEnabled });
    phoneGroup.get('number').reset({ value: (phone[1] || '') + (phone[2] || ''), disabled: !phoneEnabled });

    return phoneGroup;
  }

  getAllInterests() {
    this.allInterests$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: any) => {
      if (response) {
        this.allInterests = response;
        const email = this.userData.member.email;
        this.store.dispatch(new GetMemberInterests({ email }));
      }
    });
  }

  prepareMailchimpPreferencesForm() {
    this.memberInterestsSuccess$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((memberInterests: any) => {
      const group = {};

      if (this.allInterests && Array.isArray(this.allInterests)) {
        for (let i = this.allInterests.length - 1; i >= 0; i--) {
          const interests = this.allInterests[i].interests ? this.allInterests[i].interests : [];

          if (interests && Array.isArray(interests)) {
            interests.forEach((interest) => {
              if (Object.keys(memberInterests).includes(interest.id)) {
                group[interest.name] = new UntypedFormControl(memberInterests[interest.id]);
              }
            });
          }
        }
        this.mailchimpPreferencesForm = new UntypedFormGroup(group);
      }
    });
  }

  hasEligibleTravelers() {
    this.store.select<any>(fromProfile.getProfileSettings).pipe(takeUntil(this.ngUnsubscribe)).subscribe((settings) => {
      const sections = settings.profile_sections;
      if (sections) {
        const hasElegibleTravelers = sections.find(section => section.id === 'travelers');
        if (hasElegibleTravelers) {
          this.showEligibleTravelers = true;
        }
      }
    });
  }

  getPaymentMethods() {
    this.store.dispatch(new PaymentMethodsActions.GetAll(false));
  }

  onRefreshPaymentMethods() {
    this.getPaymentMethods();
  }

  onShowTabs() {
    this.selectedTab = 'menu';
  }

  onRefreshCredits() {
    this.loadingCredits = true;
    setTimeout(() => {
      this.loadingCredits = false;
      this.store.dispatch(new CreditActions.LoadCredits());
      this.store.dispatch(new CreditHistoryActions.LoadCredits());
    }, 2000);
  }

  onUpdateProfile(data, createForm = true) {
    this.generalHelper.scrollToElement('header');
    if (data) {
      if (data.currency && this.profileUser?.currency !== data.currency) {
        this.store.dispatch(new CreditActions.LoadCredits());
        this.store.dispatch(new MemberLoyaltyActions.LoadLoyalty({ currency : data.currency }));
      }
      this.store.dispatch(new ProfileUserActions.UpdateUserProfile(data));
      this.store.dispatch(new AuthApiActions.UpdateUserAccountInfo(data));
      if (createForm) {
        this.createForm();
      }
    }
  }

  showLoginAndSecurity(): boolean {
    return this.featureFlagsService.isFeatureEnabled(this.featureFlags.LOGIN_AND_SECURITY) || this.isRetiredEmployee();
  }

  isRetiredEmployee(): boolean {
    if (EXEMPTED_SSO_RETIREE_AIRLINES.includes(this.userData?.member.airline_code)) {
      return this.userData?.account?.employmentType === EmploymentTypes.RETIREE;
    }
    return false;
  }

  logout() {
    return this.router.navigate(['/logout']);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private prepareUrlParams() {
    this.ccParams = this.activatedRoute.snapshot.queryParams['cc'] ? this.activatedRoute.snapshot.queryParams['cc'] : '';
    this.icParams = this.activatedRoute.snapshot.queryParams['ic'] ? this.activatedRoute.snapshot.queryParams['ic'] : '';
  }

  private listenSuccess() {
    this.getPaymentsMethods$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
      if (response) {
        this.paymentMethods = response;
      }
  });
  }
}
