import { Component, Input, Output, EventEmitter, Inject, PLATFORM_ID, OnChanges } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'full-screen-modal',
  templateUrl: './full-screen-modal.html',
  styleUrls: ['./full-screen-modal.scss']
})
export class FullScreenModal implements OnChanges {

  @Input() showModal = false;
  @Input() forceHideNav = false;
  @Input() scrollBody = true;
  @Input() displayCloseButon = true;
  @Input() customHeader = false;
  @Input() displayFooter = true;
  @Output() modalClosed = new EventEmitter();
  @Output() keepResult = new EventEmitter();

  icons = { faTimes };

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnChanges() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.showModal) {
        document.body.classList.add('full-screen-modal-open');
      } else {
        document.body.classList.remove('full-screen-modal-open');
        const nav = document.querySelector('.navbar');
        if (nav) { nav.classList.remove('modal-open'); }
      }
      if (this.showModal && this.forceHideNav) {
        document.querySelector('.navbar')?.classList.add('modal-open');
      }
    }
  }
  close() {
    this.showModal = false;
    this.modalClosed.emit();
  }
  preserveFocus(e) {
    if (!e.target.value && e.type === 'click' && (e.target.className === 'full-screen-modal-body' || e.target.className === 'full-screen-modal-header')) {
      this.keepResult.emit();
    }
  }

}
