import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'hotel-pricing',
  templateUrl: './hotel-pricing.component.html',
  styleUrls: ['./hotel-pricing.component.scss']
})
export class HotelPricingComponent {
  @Input() displayRate: string;
  @Input() totalRate: string;
  @Input() isPrimaryRate = false;
  @Input() tooltip: boolean = false;
  @Input() boldRate: boolean = false;
}
