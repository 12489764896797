import { LogoutComponentActions, AuthApiActions } from '../actions';

export interface State {
  error: string | null;
  pending: boolean;
}

export const initialState: State = {
  error: null,
  pending: false
};

export function reducer(
  state = initialState,
  action: AuthApiActions.AuthApiActionsUnion | LogoutComponentActions.LogoutComponentActionsUnion
): State {
  switch (action.type) {
    case LogoutComponentActions.LogoutComponentActionTypes.Logout:
      return {
        ...state,
        error: null,
        pending: true
      };
    case LogoutComponentActions.LogoutComponentActionTypes.Oauth2Logout:
      return {
        ...state,
        error: null,
        pending: true
      };
    case AuthApiActions.AuthApiActionTypes.LogoutSuccess:
      return {
        ...state,
        error: null,
        pending: false
      };
    case AuthApiActions.AuthApiActionTypes.LogoutFailure:
      return {
        ...state,
        error: action.payload.error,
        pending: false
      };
    default: {
      return state;
    }
  }
}

export const getError = (state: State) => state.error;
export const getPending = (state: State) => state.pending;
