import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CurrencyHelper } from 'app/shared/helpers/currency.helper';
import { CreditInformation } from 'app/shared/models/credit-information';
import { CreditsService } from 'app/shared/services/credits/credits.service';
import { Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'credit-component',
  styleUrls: ['./credit.component.scss'],
  templateUrl: './credit.html'
})
export class CreditComponent implements OnInit, OnDestroy {
  @Input() creditInformation: CreditInformation;
  @Input() totalPayment: number;
  @Input() currency: any;
  @Input() isPayLater: boolean = false;
  @Input() bookingData: any;
  @Output() goBackClick = new EventEmitter();
  @Output() updateTotal = new EventEmitter();
  @Output() toggleStealthCredits = new EventEmitter();
  creditForm: UntypedFormGroup;
  ready = false;
  private ngUnsubscribe = new Subject<void>();
  private creditSub: Subscription;

  constructor(
    private currencyPipe: CurrencyPipe,
    private formBuilder: UntypedFormBuilder,
    private creditsService: CreditsService,
    private currencyHelper: CurrencyHelper
  ) {}

  ngOnInit() {
    this.creditForm = this.formBuilder.group({
      applyCredit: [this.creditInformation.applyCredit]
    });

    this.creditSub = this.creditsService.getAvailableCredit().pipe(take(1)).subscribe((data) => {
      this.creditInformation.userCreditUsd = data.credit;
      this.creditInformation.userCredit = data.display_amount;
      this.creditInformation.remainingCredit = data.display_amount;
      this.ready = true;
    });

    this.creditForm.get('applyCredit').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((applyCredit) => {
      this.creditInformation.applyCredit = applyCredit;
      this.toggleStealthCredits.emit(applyCredit);
      this.updateCredit();
    });
  }

  get remainingCredit() {
    if (this.bookingData.room) {
      const remainingCredit = this.bookingData.room.total < this.creditInformation.userCredit ?
        this.bookingData.room.total :
        this.creditInformation.userCredit;
      return this.currencyHelper.toSelectedCurrencySymbol(
        remainingCredit,
        this.currency
      );
    }
  }

  get savePerNight(): string {
    const amount = this.bookingData && this.bookingData.savePerNight;
    return this.currencyHelper.toSelectedCurrencySymbol(amount, this.bookingData.bookingCurrency, '1.2-2');
  }

  get saveFees(): string {
    if (this.bookingData && this.bookingData.showSaveFees) {
      const saveFees = this.bookingData.room.total <= this.creditInformation.userCredit ?
      this.bookingData.room.taxes_and_fees :
     this.creditInformation.userCredit - this.bookingData.room.subtotal ;
      return this.currencyHelper.toSelectedCurrencySymbol(saveFees, this.bookingData.bookingCurrency, '1.2-2');
    }
    return '';
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.creditSub.unsubscribe();
  }

  updateCredit() {
    this.creditInformation.setCredit(this.totalPayment);
    this.updateTotal.emit();
  }
}
