<div class='card-header'>
  {{ 'mobile.checkout.traveler.title' | translate }} {{index + 1}}
  <fa-icon *ngIf="index > 0"
    [icon]="icons.faTimesCircle" (click)="onRemoveTraveler(index)" [attr.data-cy]="'removeTraveler'+(index+1)" class="fas fa-times-circle float-end me-4 mt-1 gray-medium-color clickable gtm-remove-traveler"></fa-icon>
</div>
<div class='card-body pb-0'>
  <form [formGroup]="travelerForm">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="firstName">{{ 'mobile.checkout.traveler.first_name' | translate }}</label>
          <input type="text" class="form-control" [(ngModel)]="traveler.firstName" formControlName="firstName" data-cy="travelerFirstName">
          <div class='error-message'
            *ngIf="firstName.errors !== null && (firstName.touched)"
            [innerHtml]="formErrorsHelper.errorMessage(firstName, ('mobile.checkout.traveler.first_name' | translate))">
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="lastName">{{ 'mobile.checkout.traveler.last_name' | translate }}</label>
          <input type="text" class="form-control" [(ngModel)]="traveler.lastName" formControlName="lastName" data-cy="travelerLastName">
          <div class='error-message'
            *ngIf="lastName.errors !== null && (lastName.touched)"
            [innerHtml]="formErrorsHelper.errorMessage(lastName, ('mobile.checkout.traveler.last_name' | translate))">
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="dateOfBirth">{{ 'mobile.checkout.traveler.birthday' | translate }}</label>
          <datepicker-range
            navigation="select"
            emitFormat="YYYY-MM-DD"
            [displayMonths]="1"
            [startDate]="startDate"
            [inputName]="'text-start ps-4 dateOfBirth' + (index + 1)"
            [singleDate]="true"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [showCalendarIcon]="true"
            [placeholderStart]="'mobile.checkout.traveler.birthday' | translate"
            (datesSelected)="onDatesSelected($event)">
          </datepicker-range>
          <div class='error-message'
            *ngIf="dateOfBirth.errors !== null && (dateOfBirth.touched)"
            [innerHtml]="formErrorsHelper.errorMessage(dateOfBirth, ('mobile.checkout.traveler.date_Of_Birth' | translate))">
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="email">{{ 'mobile.checkout.traveler.email' | translate }}</label>
          <input type="email" class="form-control" [(ngModel)]="traveler.email" formControlName="email" data-cy="travelerEmail">
          <div class='error-message'
            *ngIf="email.errors !== null && (email.touched)"
            [innerHtml]="formErrorsHelper.errorMessage(email, ('mobile.checkout.traveler.email' | translate))">
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
