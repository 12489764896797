import { Injectable } from '@angular/core';
import { MapMarker } from 'app/shared/models/map-marker';

export interface MapPoint {
  latitude: number;
  longitude: number;
}

@Injectable({
  providedIn: 'root'
})
export abstract class MapService {

  abstract initialize(): Promise<any>;
  abstract buildMap(latitude: number, longitude: number, radius?: number): void;
  abstract addMarker(map: any, marker: MapMarker, onClick: any, popupHtml?: any): any;
  abstract removeMarkers(map: any, markers: any[]): void;
  abstract getRadius(map: any): number;
  abstract setRadius(map: any, radius: number, animate?: boolean, lat?: number, lng?: number): void;
  abstract setCenter(map: any, latitude: number, longitude: number, animate: boolean, radius?: number): void;
  abstract getCenter(map: any): MapPoint;
  abstract extendBounds(map: any, markers: MapMarker[]): void;
  abstract setZoomEnabled(map: any, enabled: boolean): void;
  abstract setZoomLevel(map: any, level: number): void;
  abstract showScale(map: any): void;
  abstract drawPolygons(map: any, polygons: any[]): any;
  abstract removePolygons(map: any): void;
  abstract onLocationChanged(map: any, fn: any): void;
  abstract onLocationChangedSubject?(map: any, fn: any): void;
  abstract buildCluster(map: any, number: number, mapMarkers: any[], clickCallback, getMarkersCallback, sortBy, clusterRadius: number);
  abstract removeLayer();
  abstract drawMarkersWithoutClustering(map: any, mapMarkers: any, getMarkerCallback);
  abstract setInteractive(map: any, isInteractive: boolean, options?: any);
  abstract updateActiveMarker?(marker: HTMLElement);
}
