import { environment } from '@env/environment';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(
    private http: HttpClient
  ) { }

  getProfileUser(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/profile.json`, {});
  }

  getProfileConfig(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/profile/config.json`, {});
  }

  getProfileStructure(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/profile/brand_profile_structure.json`, {});
  }

  getProfileBusinessToggle(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/profile/show_business_toggle.json`, {});
  }

  updateProfile(params): Observable<any> {
    return this.http.put(`${environment.apiUrl}/profile.json`, params);
  }

  updatePassword(params): Observable<any> {
    const newParams = {
      previous_password: params['previousPassword'],
      new_password: params['newPassword'],
      confirmed_password: params['confirmedPassword'],
      confirm_logout: params['confirmLogout']
    };
    return this.http.put(`${environment.apiUrl}/api/v1/members/change_password.json`, newParams, {});
  }

  getProfileSettings(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/helpers/profile_settings.json`, {});
  }

  getBookableCurrencies() {
    return this.http.get(`${environment.apiUrl}/api/v1/currency_config.json`, { headers: { cacheable: 'true' } });
  }

  deleteProfile(params): Observable<any> {
    return this.http.put(`${environment.apiUrl}/api/v1/members/delete_profile.json`, params);
  }

  unlinkEmailVerify(params): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/v1/unlink_email/verify`, params);
  }

  unlinkEmailRequest(params): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/v1/unlink_email/request`, params);
  }


}
