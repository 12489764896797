import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';
import { faApple, faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { NgbNav, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Socials } from 'app/profile/models/third-party';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';
import { Oauth2Helper } from 'app/shared/helpers/oauth2.helper';
import { SamlHelper } from 'app/shared/helpers/saml.helper';
import { ConfigFlags } from 'app/shared/models/configFlags';
import { featureFlags } from 'app/shared/models/featureFlags';
import { AuthService } from 'app/shared/services/auth/auth.service';
import { FeatureFlagsService } from 'app/shared/services/featureFlags/featureFlags.service';
import * as fromShared from 'app/shared/store/reducers';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


interface LoginForm {
  airline: FormControl<string>;
  employee_number: FormControl<string>;
  password: FormControl<string>;
  remember_me: FormControl<boolean>;
  recaptcha?: FormControl<string>;
}
@Component({
  selector: 'login-form',
  templateUrl: './login-form.html',
  styleUrls: ['./login-form.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginFormComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('nav', { static: false }) private tabs: NgbNav;
  @Input()
  set pending(isPending: boolean) {
    if (isPending) {
      this.loginForm.disable();
    } else {
      this.loginForm.enable();
    }
    this._pending = isPending;
  }
  @Input() airlineDefault = '';
  @Input() employeeNumberDefault = '';
  @Input() emailDefault = '';
  @Input() message: any | null;
  @Input() error$: any | null;
  @Input() inModal: boolean = false;
  @Input() airlines:any = [];
  @Input() displayLoginWithConnection: boolean;
  @Input() platformChosen: string;
  @Input() configFlags: ConfigFlags;
  @Input() isWhiteLabel: boolean = false;
  @Output() submitted = new EventEmitter();
  @Output() closeAlert = new EventEmitter();
  @Output() thirdPartiesLogin = new EventEmitter();
  @Output() loginAndConnectAccount = new EventEmitter();
  @Output() loginSaml = new EventEmitter();
  @Output() onChangeTabEvent = new EventEmitter();
  selectedTab: string;
  formType:string = 'email';
  prevUsername:string = '';
  loginForm: FormGroup<LoginForm> = new FormGroup<LoginForm>({
    airline: new FormControl(''),
    employee_number: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    remember_me: new FormControl(true)
  });
  currentTab = 'airlineLogin';
  recaptchaKey = environment.recaptchaPublicKey;
  maxLoginAttempts = 10;
  requireRecaptcha = false;
  socials = Socials;
  icons = { faEnvelope, faFacebookSquare, faApple };
  showFacebook: boolean = true;
  showApple: boolean = true;
  showGoogle: boolean = true;
  loginSubmitted: boolean = false;
  alwaysShowRecaptcha: boolean = false;

  private appConfig$ = this.store.pipe(select(fromShared.getConfigFlag));
  private _pending = false;
  private ngUnsubscribe = new Subject<void>();
  public logInHelpInfoAirlines = environment.logInHelpInfoAirlines;
  public logInHelpInfoFriends = environment.logInHelpInfoFriends;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    public formErrorsHelper: FormErrorsHelper,
    public translate: TranslateService,
    public samlHelper: SamlHelper,
    private oauth2Helper: Oauth2Helper,
    private store: Store,
    private featureFlagsService: FeatureFlagsService
  ) {}

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => {
      this.selectedTab = ((params && params.tab === 'invite') ||
        this.airlineDefault.toString() === AuthService.INVITED_MEMBERS_AIRLINE_CODE) ?
        'nonAirlineLogin' :
        'airlineLogin';
    });
    this.setDefaults();
    this.loadAppConfig();
    this.showOrHideRecaptcha();
    if (this.error$) {
      this.error$.pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe((error) => {
        if (error && error.error['error_code'] === 'AirlineRequired') {
          this.formType = 'employee';
        }

        if (error && error.error['login_attempts']) {
          this.showOrHideRecaptcha(error.error.login_attempts);
        }

        this.loginSubmitted = false;
      });
    }
  }

  showOrHideRecaptcha(attempts = 1): void {
    if (attempts >= this.maxLoginAttempts || this.alwaysShowRecaptcha) {
      this.loginForm.addControl('recaptcha', new FormControl('', Validators.required));
      this.requireRecaptcha = true;
    } else {
      this.loginForm.removeControl('recaptcha');
      this.requireRecaptcha = false;
    }
  }

  setDefaults() {
    this.airlineDefault = this.airlineDefault.toString() === AuthService.INVITED_MEMBERS_AIRLINE_CODE ?
      '' :
      this.airlineDefault.toString();
    this.emailDefault.toString();
    this.employeeNumberDefault.toString();
    this.loginForm.get('employee_number').setValue(this.employeeNumberDefault);
    if (this.isWhiteLabel) {
      this.loginForm.get('airline').setValue(this.airlineDefault);
    }
  }

  ngAfterViewChecked(): void {
    if (this.tabs) {
      this.tabs.select(this.selectedTab);
    }
    this.cdRef.detectChanges();
  }

  onTabChange(changeEvent: NgbNavChangeEvent): void {
    this.onChangeTabEvent.emit({ tab: changeEvent.nextId });
    this.loginForm.reset();
    if (changeEvent.nextId === 'nonAirlineLogin' && this.displayLoginWithConnection) { this.displayLoginWithConnection = false; }
    this.resetErrors();
    if (!this.inModal) {
      const routes = {
        airlineLogin: '/login',
        nonAirlineLogin: '/login/invite'
      };
      let redirectUrl = routes[changeEvent.nextId];
      const queryParams = {} as any;
      for (const params in this.route.snapshot.queryParams) {
        if (Object.prototype.hasOwnProperty.call(this.route.snapshot.queryParams, params)) {
          const rawParams = this.route.snapshot.queryParams[params];
          queryParams[params] = rawParams === decodeURIComponent(rawParams) ? queryParams[params] = encodeURIComponent(rawParams) : rawParams;
        }
      }
      this.router.navigate([redirectUrl], { queryParams });
    } else {
      this.selectedTab = changeEvent.nextId;
    }
    this.formType = changeEvent.nextId === 'airlineLogin' ? 'employee' : 'email';
    this.setValidations(this.formType);
  }

  thirdPartyAuth(platform) {
    this.message = null;
    this.thirdPartiesLogin.emit({ platform, remember: this.rememberMe });
  }

  loginStarts(formSubmitted) {
    this.message = null;
    this.setValidations(formSubmitted);
    this.setAirlineCode(formSubmitted);
  }

  loginAndVinculate(formSubmitted = 'employee', platform) {
    this.loginStarts(formSubmitted);
    if (this.loginForm.valid) {
      this.loginAndConnectAccount.emit({ platform, credentials: this.loginForm.value, isSaml: false });
      this.resetForm(formSubmitted);
      return;
    }
    Object.keys(this.loginForm.controls).forEach((field) => {
      const control = this.loginForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  login(formSubmitted = 'employee') {
    this.loginStarts(formSubmitted);
    if (this.loginForm.valid) {
      this.prevUsername = this.loginForm.get('employee_number').value;
      this.submitted.emit(this.loginForm.value);
      this.resetForm(formSubmitted);
      this.loginSubmitted = true;
      return;
    }
    Object.keys(this.loginForm.controls).forEach((field) => {
      const control = this.loginForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });

  }

  resetForm(formSubmitted) {
    this.loginForm.get('password').reset();
    if (formSubmitted === 'email') {
      this.loginForm.get('airline').reset();
    }
  }

  setAirlineCode(formSubmitted) {
    if (this.loginForm.get('airline').value) {
      return;
    }
    const airlineCode = formSubmitted === 'invited' ? AuthService.INVITED_MEMBERS_AIRLINE_CODE : null;
    this.loginForm.controls['airline'].setValue(airlineCode);
  }

  setValidations(formSubmitted) {
    this.loginForm.get('airline').setValidators(Validators.required);
    if (formSubmitted === 'email') {
      this.loginForm.get('airline').setValidators([]);
    }
  }

  getDomain(email) {
    return email.split('@')[1];
  }

  getAirlineFromDomain(domain) {
    // If there are more than 1 airlines with the email domain then we let the webapp figure out what to do
    const airlines = this.airlines.filter(airline => airline.email_domains.includes(domain));
    return airlines.length === 1 ? airlines[0].code : null;
  }

  formToggle() {
    this.formType = this.formType === 'email' ? 'employee' : 'email';
    this.loginForm.get('airline').reset();
    this.airlineDefault = '';
    this.setValidations(this.formType);
  }

  get pending()         { return this._pending; }
  get airline()         { return this.loginForm.get('airline'); }
  get employee_number() { return this.loginForm.get('employee_number'); }
  get password()        { return this.loginForm.get('password'); }
  get recaptcha()       { return this.loginForm.get('recaptcha'); }
  get rememberMe ()     { return this.loginForm.get('remember_me').value ? '1' : '0'; }

  closeAlertEvent() {
    this.closeAlert.emit();
  }

  resetErrors() {
    Object.keys(this.loginForm.controls).forEach((field) => {
      const control = this.loginForm.get(field);
      control.markAsUntouched();
    });
  }

  setAirline(airline) {
    this.airlineDefault = airline.name ? airline.name.toUpperCase() : '';
    if (this.samlHelper.inSamlLogin(airline.code, this.configFlags) && !airline.added) {
      this._pending = true;
      if (this.displayLoginWithConnection && this.platformChosen) {
        this.loginAndConnectAccount.emit({ airline, platform: this.platformChosen, credentials: null, isSaml: true });
      } else {
        this.loginSaml.emit(airline);
      }
      return;
    }
    if (this.oauth2Helper.inOauth2Login(airline.code, this.configFlags) && !airline.added) {
      this.oauth2Helper.login(airline.code);
      return;
    }
  }

  parseMessage(message) {
    if (typeof message.message === 'string' || message.message instanceof String) {
      return message.message.replace('%{email}', this.prevUsername);
    }
  }

  private loadAppConfig() {
    this.appConfig$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((appConfig) => {
      const isThirdPartyLoginEnabled = this.featureFlagsService.isFeatureEnabled(featureFlags.THIRD_PARTY_LOGIN);
      this.alwaysShowRecaptcha = appConfig?.strict_login_recaptcha === '1';
      this.showApple = appConfig?.login_apple_enabled === '1' && isThirdPartyLoginEnabled;
      this.showFacebook = appConfig?.login_facebook_enabled === '1' && isThirdPartyLoginEnabled;
      this.showGoogle = appConfig?.login_google_enabled === '1' && isThirdPartyLoginEnabled;
    })
  }

  get ulStyle() {
    return this.isWhiteLabel ? { display: 'none' } : {};
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
