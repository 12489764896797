<section class="services-section flex-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6 col-sm-12 center-on-small-only">
        <div class="row">
          <div class="col-md-12">
            <h2 class="h1">Flights</h2>
            <h5 class="grey-text">Available only to partner Airlines</h5>
            <div class="py-3">It is now easier to charter, lease or rent a plane because there are more companies entering the aircraft leasing business. Not a partner? Sign petition to get your airline listed!</div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <img src="http://interliner.id90travel.com/wp-content/uploads/2016/08/united-airlines.png" class="img-fluid d-block pt-4"/>
            <div class="fst-italic py-2">“It is now easier to charter, lease or rent a plane because there are more companies entering the aircraft leasing business.”</div>
            <img src="http://materializecss.com/images/yuna.jpg" class="rounded-circle me-2"/>
            <em>John Doe, <span class="grey-text">CEO United Airlines</span></em>
            <button class="btn btn-md btn-primary mt-5 ms-0 d-block" routerLink="/create-account">Create Account</button>
          </div>
        </div>
      </div>
      <div class="col-md-5 ms-auto d-none d-md-block">
        <img src="angular_assets/images/homepage/events.png" class="img-fluid" />
      </div>
    </div>
  </div>
</section>
<hr>
<section class="services-section flex-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-5 me-auto d-none d-md-block">
        <img src="angular_assets/images/homepage/events.png" class="img-fluid" />
      </div>
      <div class="col-md-6 col-sm-12 center-on-small-only">
        <div class="row">
          <div class="col-md-12">
            <h2 class="h1">Flights</h2>
            <h5 class="grey-text">Available only to partner Airlines</h5>
            <div class="py-3">It is now easier to charter, lease or rent a plane because there are more companies entering the aircraft leasing business. Not a partner? Sign petition to get your airline listed!</div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <img src="http://interliner.id90travel.com/wp-content/uploads/2016/08/united-airlines.png" class="img-fluid d-block pt-4"/>
            <div class="fst-italic py-2">“It is now easier to charter, lease or rent a plane because there are more companies entering the aircraft leasing business.”</div>
            <img src="http://materializecss.com/images/yuna.jpg" class="rounded-circle me-2"/>
            <em>John Doe, <span class="grey-text">CEO United Airlines</span></em>
            <button class="btn btn-md btn-primary mt-5 ms-0 d-block" routerLink="/create-account">Create Account</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<hr>
<section class="services-section flex-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6 col-sm-12 center-on-small-only">
        <div class="row">
          <div class="col-md-12">
            <h2 class="h1">Flights</h2>
            <h5 class="grey-text">Available only to partner Airlines</h5>
            <div class="py-3">It is now easier to charter, lease or rent a plane because there are more companies entering the aircraft leasing business. Not a partner? Sign petition to get your airline listed!</div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <img src="http://interliner.id90travel.com/wp-content/uploads/2016/08/united-airlines.png" class="img-fluid d-block pt-4"/>
            <div class="fst-italic py-2">“It is now easier to charter, lease or rent a plane because there are more companies entering the aircraft leasing business.”</div>
            <img src="http://materializecss.com/images/yuna.jpg" class="rounded-circle me-2"/>
            <em>John Doe, <span class="grey-text">CEO United Airlines</span></em>
            <button class="btn btn-md btn-primary mt-5 ms-0 d-block" routerLink="/create-account">Create Account</button>
          </div>
        </div>
      </div>
      <div class="col-md-5 ms-auto d-none d-md-block">
        <img src="angular_assets/images/homepage/events.png" class="img-fluid" />
      </div>
    </div>
  </div>
</section>
<hr>
<section class="services-section flex-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-5 me-auto d-none d-md-block">
        <img src="angular_assets/images/homepage/events.png" class="img-fluid" />
      </div>
      <div class="col-md-6 col-sm-12 center-on-small-only">
        <div class="row">
          <div class="col-md-12">
            <h2 class="h1">Flights</h2>
            <h5 class="grey-text">Available only to partner Airlines</h5>
            <div class="py-3">It is now easier to charter, lease or rent a plane because there are more companies entering the aircraft leasing business. Not a partner? Sign petition to get your airline listed!</div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <img src="http://interliner.id90travel.com/wp-content/uploads/2016/08/united-airlines.png" class="img-fluid d-block pt-4"/>
            <div class="fst-italic py-2">“It is now easier to charter, lease or rent a plane because there are more companies entering the aircraft leasing business.”</div>
            <img src="http://materializecss.com/images/yuna.jpg" class="rounded-circle me-2"/>
            <em>John Doe, <span class="grey-text">CEO United Airlines</span></em>
            <button class="btn btn-md btn-primary mt-5 ms-0 d-block" routerLink="/create-account">Create Account</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<hr>
<section class="mobile-watchlist blue-gradient flex-center">
  <div class="container">
    <div class="row white-text">
      <div class="col-md-12">
        <h3>Hotel Watchlist</h3>
        <div>Available on our app</div>
        <p>It is now easier to charter, lease or rent a plane because there are more companies entering the aircraft leasing business. Also, a new generation of small jets is being produced as we speak, the so-called air taxis seating between.</p>
      </div>
    </div>
  </div>
</section>
