import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AuthApiActions, ForgotPasswordActions } from 'app/auth/store/actions';
import * as fromAuth from 'app/auth/store/reducers';
import { MfaActions } from 'app/profile/models/mfa';
import { TwoStepSecurityModal } from 'app/shared/containers/two-step-security/two-step-security-modal';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';
import { ProfileService } from 'app/shared/services/profile/profile.service';
import { CustomValidators } from 'app/shared/validators/custom-validators';
import * as fromRoot from 'reducers';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'change-password',
  templateUrl: 'change-password.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit , OnDestroy {
  @Input() modalTitle: string = 'mobile.profile.change_password';
  @Input() requestMFA = false;
  @Input() userData;
  @Input() passwordExpired = false;
  requestLinkSuccess$ = this.store.pipe(select(fromAuth.getForgotPasswordLinkSuccess));
  requestLinkError$ = this.store.pipe(select(fromAuth.getForgotPasswordLinkError));
  changePasswordForm: UntypedFormGroup;
  error: string = '';
  loading: boolean = false;
  successSendLink: boolean = false;
  alertType: 'success' | 'danger';
  private ngUnsubscribe = new Subject<void>();


  constructor(
    private formBuilder: UntypedFormBuilder,
    private profileService: ProfileService,
    public activeModal: NgbActiveModal,
    public formErrorsHelper: FormErrorsHelper,
    private store: Store<fromRoot.State>,
    private modalService: NgbModal,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.store.dispatch(new ForgotPasswordActions.Reset());
    this.changePasswordForm = this.formBuilder.group({
      previousPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, CustomValidators.password]],
      confirmedPassword: ['', [Validators.required]],
      confirmLogout: false
    });
    this.listenForLinkError();
    this.listenForLinkSuccess();
  }

  onChangePasswordClick(): void {
    if (this.isValid()) {
      this.loading = true;
      this.error = '';
      const passwords = this.changePasswordForm.value;

      if (this.requestMFA) {
        const twoStepSecurityModal = this.modalService.open(TwoStepSecurityModal, { backdrop : 'static', keyboard : false, size: 'lg' });
        twoStepSecurityModal.componentInstance.userData = this.userData;
        twoStepSecurityModal.componentInstance.action = MfaActions.MFA_PROFILE_UPDATE;
        twoStepSecurityModal.result.then(
          (success) => {
            if (success) { this.changePasswordAction(passwords); }
          },
          (e) => {
            this.loading = false;
            this.alertType = 'danger';
            this.error = this.translateService.instant('errors.mfa_required');
          }
        );
      } else {
        this.changePasswordAction(passwords);
      }
    } else {
      Object.keys(this.changePasswordForm.controls).forEach((field) => {
        const control = this.changePasswordForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  changePasswordAction(passwords) {
    this.profileService.updatePassword(passwords).pipe(take(1)).subscribe(
      () => {
        this.loading = false;
        this.store.dispatch(new AuthApiActions.UpdatePassword());
        this.activeModal.close(passwords);
      },
      (error) => {
        this.alertType = 'danger';
        this.error = error.error.error_description;
        this.loading = false;
      }
    );
  }

  isValid() {
    return this.changePasswordForm.valid;
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  closeAlert() {
    this.error = '';
  }

  getLink() {
    const credentials = {
      airlineCode : this.userData.account.airlineCode,
      medium: 'link',
      memberEmail: this.userData.account.employeeNumber,
      organizationId: this.userData.account.organizationId
    } as any;
    this.store.dispatch(new ForgotPasswordActions.RequestLink( credentials ));

  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.store.dispatch(new ForgotPasswordActions.Reset());
  }

  private listenForLinkSuccess() {
    this.requestLinkSuccess$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
      if (response) {
        this.alertType = 'success';
        return this.error = this.translateService.instant('password_reset.password_expired_send_link_success');
      }
    });
  }

  private listenForLinkError() {
    this.requestLinkError$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((error) => {
      if (error) {
        this.alertType = 'danger';
        this.error = error?.error?.error_description;
      }
    });
  }
  get previousPassword()   { return this.changePasswordForm.get('previousPassword'); }
  get previousPasswordError() {
    return this.formErrorsHelper.errorMessage(
      this.previousPassword as UntypedFormControl,
      'Password'
    );
  }
  get newPassword()   { return this.changePasswordForm.get('newPassword'); }
  get newPasswordError()   {
    return this.formErrorsHelper.errorMessage(
      this.newPassword as UntypedFormControl,
      'New Password'
    );
  }
  get confirmedPassword()   { return this.changePasswordForm.get('confirmedPassword'); }
  get confirmedPasswordError()   {
    return this.formErrorsHelper.errorMessage(
      this.confirmedPassword as UntypedFormControl,
      'Re-Type New Password'
    );
  }
}
