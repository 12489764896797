<section id="watchlist" class="py-5">
  <div class="container">
    <div class="button-tabs-left">
      <ul ngbNav #nav="ngbNav" class="nav nav-justified nav-tabs" (navChange)="tabChanged($event)">
        <li [ngbNavItem]="'flights'" *ngIf="hasFlightTools">
          <a ngbNavLink>
            {{'profile.reservations.flights.title' | translate}}
          </a>
          <ng-template ngbNavContent>
            <div (window:scroll)="infiniteScroll($event)">
              <div class="profile-title primary-content">{{ 'watchlist.watch_list' | translate }}</div>
              <div class="content-tab">
                <div class="row">
                  <ng-container *ngIf="watchlist.flights.result.length > 0; else noFlightResults">
                    <flight-watchlist-card class="col-sm-12 col-md-6 col-lg-6 col-xl-4" *ngFor="let flight of watchlist.flights.result" [item]="flight" (deleteClicked)="onFlightWatchlistDelete($event)">
                    </flight-watchlist-card>
                  </ng-container>
                </div>
                <div class="position-relative">
                  <loading-indicator *ngIf="watchlist.flights.loading" [fullScreen]='false'></loading-indicator>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="'hotels'">
          <a ngbNavLink>
            {{'profile.reservations.hotels.title' | translate}}
          </a>
          <ng-template ngbNavContent>
            <div (window:scroll)="infiniteScroll($event)">
              <div class="profile-title primary-content">{{ 'watchlist.watch_list' | translate }}</div>
              <div class="content-tab">
                <div class="row">
                  <ng-container *ngIf="watchlist.hotels.result.length > 0; else noHotelResults">
                    <hotel-watchlist-card class="col-sm-12 col-md-6 col-lg-6 col-xl-4"
                      *ngFor="let hotel of watchlist.hotels.result"
                      [item]="hotel"
                      [badges]="rooms"
                      (bookNowClicked)="onHotelWatchlistBooked($event)"
                      (deleteClicked)="onHotelWatchlistDelete($event)">
                    </hotel-watchlist-card>
                  </ng-container>
                </div>
                <div class="position-relative">
                  <loading-indicator *ngIf="watchlist.hotels.loading" [fullScreen]='false'></loading-indicator>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav"></div>
    </div>
  </div>
</section>
<ng-template #noHotelResults>
  <div class="col-md-4 offset-md-4 col-sm-12 text-center mt-5">
    <img src="angular_assets/images/watchlist/no-hotel-watchlist.png" class="img-fluid py-3 no-trips-img" />
    <div class="primary-content">{{'mobile.watchlist.no_hotel_watchlist' | translate}}</div>
    <div class="py-3">{{'mobile.watchlist.add_hotels' | translate}}</div>
    <button type="button" class="btn btn-primary" routerLink="/search/hotels">Search Hotels</button>
  </div>
</ng-template>
<ng-template #noFlightResults>
  <div class="col-md-4 col-sm-12 offset-md-4 text-center mt-5">
    <img src="angular_assets/images/watchlist/no-flight-watchlist.png" class="img-fluid py-3 no-trips-img" />
    <div class="primary-content">{{'mobile.watchlist.no_flight_watchlist' | translate}}</div>
    <div class="py-3">{{'mobile.watchlist.add_flights' | translate}}</div>
    <button type="button" class="btn btn-primary" routerLink="/flights">Search Flights</button>
  </div>
</ng-template>
