import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {MemberService} from 'app/shared/services/member/member.service';
import {MemberLoyaltyActions} from 'app/profile/store/actions';

@Injectable()
export class MemberLoyaltyEffects {

  loadLoyalty$ = createEffect(()=>
    this.actions$.pipe(
      ofType<MemberLoyaltyActions.LoadLoyalty>(MemberLoyaltyActions.MemberLoyaltyActionTypes.LoadLoyalty),
      switchMap((currency) =>  {
        return this.memberService.getMemberLoyalty(currency.payload).pipe(
          map(data => new MemberLoyaltyActions.LoadLoyaltySuccess(data)),
          catchError(error => of(new MemberLoyaltyActions.LoadLoyaltyFailure(error)))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private memberService: MemberService,
  ) {}

}
