import { Injectable } from '@angular/core';
import { SlugifyHelper } from './slugify.helper';
import * as moment from 'moment';
import { CarTrawlerHelper } from './car-trawler.helper';

@Injectable({
  providedIn: 'root'
})
export class HotelHelper {
  private readonly airportTransferUrl = 'https://cars.cartrawler.com/id90travel/en-us/transfers/book?clientID=';

  constructor(
    private slugifyHelper: SlugifyHelper,
    private cartrawler: CarTrawlerHelper
  ) { }

  displayRate(hotel): number {
    return hotel.display_rate;
  }

  companyImage(rate: any): string {
    return `angular_assets/images/icons/${this.slugifyHelper.slugify(rate.name)}.png`;
  }

  retailRate(hotel): number {
    return hotel.retail_rate && hotel.retail_rate > hotel.display_rate
      ? Math.ceil(hotel.retail_rate)
      : null;
  }

  totalRate(hotel) {
    return !!hotel.total ? Math.round(hotel.total).toFixed() : null;
  }

  percentOff(displayRate, retailRate): number {
    if (!retailRate || retailRate < 0.01) return null;
    const result = (Math.ceil((1 - (displayRate / retailRate)) * 100) / 100) * -1;
    return Math.abs(result) < 0.05 ? null : result;
  }

  decodedVendorPropertyID(id) {
    if (!id) return id;
    const key = id.slice(0, 3);
    const number = id.slice(3, id.length);
    switch (key) {
      case '011':
        return `EAN:PKG:${number}`;
      case '012':
        return `EAN:RMP:${number}`;
      case '013':
        return `EAN:RET:${number}`;
      case '021':
        return `PLN:PKG:${number}`;
      case '022':
        return `PLN:RMP:${number}`;
      case '023':
        return `PLN:RET:${number}`;
      case '025':
        return `PLN:AGD:${number}`;
      case '027':
        return `PLN:BKG:${number}`;
      case '031':
        return `BON:PKG:${number}`;
      case '041':
        return `EPS:PKG:${number}`;
      case '042':
        return `EPS:RMP:${number}`;
      case '043':
        return `EPS:RET:${number}`;
      case '061':
        return `HBD:PKG:${number}`;
      case '071':
        return `GAR:PKG:${number}`;
      case '073':
        return `GAR:GAR:${number}`;
      case '081':
        return `HDO:PKG:${number}`;
      case '161':
        return `PCN:PKG:${number}`;
      case '162':
        return `PCN:RMP:${number}`;
      case '163':
        return `PCN:RET:${number}`;
      case '165':
        return `PCN:AGD:${number}`;
      case '167':
        return `PCN:BKG:${number}`;
      case '171':
        return `PPS:PKG:${number}`;
      case '181':
        return `RTL:PKG:${number}`;
      case '111':
        return `SMY:PKG:${number}`;
      case '121':
        return `HTD:PKG:${number}`;
      case '191':
        return `WBD:PKG:${number}`;
      default:
        return id;
    }
  }

  stripItineraryHeaderID(data) {
    if (!data) return;

    return data.slice(8, data.length);
  }

  stripProviderCode(data) {
    const decodedItinerary = this.decodedVendorPropertyID(data);
    const itinerary = decodedItinerary.split(':')
    return itinerary[itinerary.length - 1];
  }

  encodeVendorPropertyID(identifier: string): string {
    const provider = identifier.slice(0, 3);
    const rateType = identifier.slice(4, 7);
    const id = identifier.slice(8, identifier.length);
    let encodedId = '';
    switch (provider) {
      case 'EAN':
        encodedId += '01';
        break;
      case 'PLN':
        encodedId += '02';
        break;
      case 'BON':
        encodedId += '03';
        break;
      case 'EPS':
        encodedId += '04';
        break;
      case 'HBD':
        encodedId += '06';
        break;
      case 'GAR':
        encodedId += '07';
        break;
      case 'HDO':
        encodedId += '08';
        break;
      case 'PCN':
        encodedId += '16';
        break;
      case 'PPS':
        encodedId += '17';
        break;
      case 'RTL':
        encodedId += '18';
        break;
      case 'SMY':
        encodedId += '11';
        break;
      case 'HTD':
        encodedId += '12';
        break;
      case 'WBD':
        encodedId += '19';
        break;
      default:
        encodedId += provider;
    }
    switch (rateType) {
      case 'PKG':
        encodedId += '1';
        break;
      case 'RMP':
        encodedId += '2';
        break;
      case 'RET':
        encodedId += '3';
        break;
      case 'AGD':
        encodedId += '5';
        break;
      case 'BKG':
        encodedId += '7';
        break;
      default:
        encodedId += rateType;
    }
    encodedId += id;
    return encodedId;
  }

  hotelName(code) {
    switch (code) {
      case 'EAN':
      case 'EPS':
        return 'Expedia';
      case 'PPS':
      case 'PCN':
      case 'PLN':
        return 'Priceline';
      case 'BKG':
        return 'Booking.com';
      case 'AGD':
        return 'Agoda';
      default:
        return null;
    }
  }

  getSupplierName(supplierId) {
    if (!supplierId) return supplierId;
    const key = supplierId.slice(0, 2);
    switch (key) {
      case '01':
        return 'EAN';
      case '02':
        return 'PLN';
      case '03':
        return 'BON';
      case '04':
        return 'EPS';
      case '06':
        return 'HBD';
      case '07':
        return 'GAR';
      case '08':
        return 'HDO';
      case '11':
        return 'SMY';
      case '12':
        return 'HTD';
      case '16':
        return 'PCN';
      case '17':
        return 'PPS';
      case '18':
        return 'RTL';
      case '19':
        return 'WBD';
      default:
        return key;
    }
  }

  getHotelUrl(hotel) {
    const destinationSlug = this.slugifyHelper.slugify(hotel.city || hotel.location.city || 'location');
    const hotelNameSlug = this.slugifyHelper.slugify(hotel.name || 'hotel');
    return `/hotels/details/${destinationSlug}/${hotelNameSlug}/`;
  }

  checkDates(checkin: moment.Moment, checkout: moment.Moment) {
    const today = moment().startOf('day');
    const yersterday = moment().startOf('day').subtract(1, 'days');
    const res = {
      checkin,
      checkout,
      modified: false,
    };

    if (checkin && checkin.isBefore(yersterday)) {
      const diff = checkout ? Math.abs(checkout.diff(checkin, 'days')) || 1 : 1;
      res.checkin = today.clone().add(3, 'days');
      res.checkout = today.clone().add(3 + diff, 'days');
      res.modified = true;
    }

    return res;
  }

  generateAirportTransferUrl(checkinDate: string, checkoutDate: string, latitude: string, longitude: string, place: string) {
    const clientId = this.cartrawler.getClientId(place);
    return `${this.airportTransferUrl}${clientId}&pickupDateTime=${checkinDate}&oneway=false&returnDateTime=${checkoutDate}&passengersAges=30&rtLat=${latitude}&rtLng=${longitude}`;
  }

  stripPropertyId(id) {
    if (!id) return id;
    let key = id.substr(0, 8).toUpperCase();
    let number = id.substr(8, id.length);
    switch (key) {
      case 'EAN:PKG:':
      case 'EAN:RMP:':
      case 'EAN:RET:':
      case 'EPS:PKG:':
      case 'EPS:RMP:':
      case 'EPS:RET:':
      case 'HBD:PKG:':
      case 'GAR:PKG:':
      case 'GAR:GAR:':
      case 'HDO:PKG:':
      case 'PLN:PKG:':
      case 'PLN:RMP:':
      case 'PLN:RET:':
      case 'PLN:AGD:':
      case 'PLN:BKG:':
      case 'PCN:PKG:':
      case 'PCN:RMP:':
      case 'PCN:RET:':
      case 'PCN:AGD:':
      case 'PCN:BKG:':
      case 'PPS:PKG:':
      case 'RTL:PKG:':
      case 'SMY:PKG:':
      case 'WBD:PKG:':
      case 'BON:PKG:':
        return number;
      default:
        key = id.substr(0, 3);
        number = id.substr(3, id.length);
        switch (key) {
          case '011':
          case '012':
          case '013':
          case '021':
          case '022':
          case '023':
          case '025':
          case '027':
          case '031':
          case '041':
          case '042':
          case '043':
          case '061':
          case '071':
          case '073':
          case '081':
          case '161':
          case '162':
          case '163':
          case '165':
          case '167':
          case '171':
          case '111':
          case '131':
          case '181':
          case '191':
            return number;
          default:
            return id;
        }
    }
  }
}
