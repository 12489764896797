import { AirportsDict } from 'app/shared/models/airport';
import { AirportActions } from '../actions';

export interface AirportsState {
  error: any | null;
  pending: boolean;
  airportsData: AirportsDict;
}

export const initialState: AirportsState = {
  error: null,
  pending: false,
  airportsData: {},
};

export function reducer(
  state = initialState,
  action:
    | AirportActions.AirportActionsUnion
): AirportsState {
  switch (action.type) {
    case AirportActions.AirportActionTypes.LoadAirports:
      return {
        ...state,
        pending: true
      };
    case AirportActions.AirportActionTypes.LoadAirportsSuccess:
      return {
        ...state,
        pending: false,
        airportsData: action.payload.airports,
        error: initialState.error
      };
    case AirportActions.AirportActionTypes.LoadAirportsFailure:
      return {
        ...state,
        pending: false,
        error: action.payload.error
      };
    case AirportActions.AirportActionTypes.ClearAirports:
      return {
        ...initialState
      };
    default: {
      return state;
    }
  }
}

export const getError = (state: AirportsState) => state.error;
export const getPending = (state: AirportsState) => state.pending;
export const getAirports = (state: AirportsState) => state.airportsData;
