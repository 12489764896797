import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { BaseWatchlistCardComponent } from '../watchlist-card/base-watchlist-card.component';
import * as moment from 'moment';
import { faTag, faCheck, faPercentage, faExclamationCircle, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { Store, select } from '@ngrx/store';
import * as fromAuth from 'app/auth/store/reducers';
import * as fromRoot from 'reducers';
import { take } from 'rxjs/operators';
import { User } from "app/auth/models/user";
import { HotelHelper } from 'app/shared/helpers/hotel.helper';
import { AnalyticsService } from "app/shared/services/analytics/analytics.service";
import { CurrencyHelper } from 'app/shared/helpers/currency.helper';

@Component({
  selector: 'hotel-watchlist-card',
  templateUrl: './hotel-watchlist-card.html',
  styleUrls: ['./hotel-watchlist-card.scss'],
  encapsulation: ViewEncapsulation.None
})

export class HotelWatchlistCardComponent extends BaseWatchlistCardComponent implements OnChanges {
  icons = { faTag, faCheck, faPercentage, faExclamationCircle, faCaretDown, faCaretUp };
  loggedIn$ = this.store.pipe(select(fromAuth.getUser));
  user: User;

  @Input() set badges(value) {
    if (value && Array.isArray(value) && value.length > 0) {
      this.setRoom(value);
    }
  }

  public totalCost: string;
  public room;
  public promotions: any[] = [];
  public isDiscount: boolean = true;
  public displayDiscount: boolean = true;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item) {
      this.totalCost = this.currencyHelper.toSelectedCurrencySymbol(this.item.room_total_cost, this.item.room_display_currency, '1.0-0');
    }
    if (changes.badges && changes.badges && Array.isArray(changes.badges.currentValue) && changes.badges.currentValue.length > 0)
      {
        this.setRoom(changes.badges.currentValue);
      }
  }

  constructor(
    private store: Store<fromRoot.State>,
    private hotelHelper: HotelHelper,
    private analyticsService: AnalyticsService,
    private currencyHelper: CurrencyHelper
  ) {
    super();
    this.loggedIn$.pipe(take(1)).subscribe((user) => {
      this.user = user;
    });
  }

  get dates(): string {
    if (this.item && this.item.checkin && this.item.checkout) {
      const from = moment(this.item.checkin).format('MMM DD');
      const to = moment(this.item.checkout).format('MMM DD');
      return `${from} - ${to}`;
    }
    return '';
  }

  get percentSaving(): number {
    let percent = 0;
    if (this.item && this.item.room_previous_rate && this.item.room_latest_rate) {
      const previous = Number(this.item.room_previous_rate.toFixed(2));
      const latest = Number(this.item.room_latest_rate.toFixed(2));

      if (latest > previous) {
        percent = (latest - previous) / previous;
        this.isDiscount = false;
      } else {
        percent = 1 - (latest / previous);
        this.isDiscount = true;
      }

    }
    if (percent > 0 && percent < 0.01) percent = 0.01; // round small percentages to 1 to avoid showing rounded 0%
    return Number((Math.abs(percent)).toFixed(2));
  }

  setRoom(value: any[]) {
    const item = this.item && value.find(x => x && x.room_id === this.item.room_id);
    if (item) {
      this.room = item;
      this.setupPromotions();
    }
  }

  private setupPromotions() {
    let filterPromotions = [];
    if (this.room && this.room.promotions && this.room.promotions.length > 0) {
      filterPromotions = [...this.room.promotions];

      if (filterPromotions.some(promo => promo.key === 'hbd_exclusive_deal')) {
        filterPromotions = filterPromotions.filter(promo => promo.key !== 'super-saver');
      }
    }
    this.promotions = filterPromotions;
  }

  onBookNowClicked() {
    this.logHotelsWatchlistEvent('view_rooms');
    super.onBookNowClicked();
  }

  logHotelsWatchlistEvent(type: string){
    const days = moment(this.item.checkout).diff(moment(this.item.checkin), 'days');
    const event = {
      line_of_business: 'Hotels',
      item_name: `Hotels ${type} watchlist`,
      user_action: type,
      user_id: this.user.account.id || this.user.account.idMembers,
      is_invited: this.user.account.airlineCode === '100',
      item_list_id: this.item.id,
      items: [{
        item_id: this.item.id90,
        item_name: this.item.hotel_name,
        item_brand: this.item.chain ?? '',
        item_category: 'Hotel',
        item_category_2: this.item.hotel_star_rating,
        item_category_3: this.item.accommodation_type ?? '',
        item_list_id: this.item.id,
        item_variant: this.hotelHelper.getSupplierName(this.item.room_id),
        quantity: days * this.item.rooms,
        price: this.item.room_latest_rate
      }]
    }
    this.analyticsService.logEvent(`${type}_watchlist`, event);
  }
}
