import { Component, Input } from '@angular/core';
import { faEye, faEyeSlash, faCheck, faX, } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'password-input',
  styleUrls: ['./password-input.scss'],
  templateUrl: './password-input.html',

})

export class PasswordInputComponent {

  constructor() {}
  icons = { faEye, faEyeSlash, faCheck, faX };
  type = 'password';
  @Input() control;
  @Input() form;
  @Input() id;
  @Input() label;
  @Input() placeholder = '';
  @Input() forgotPasswordLink;
  @Input() forgotPasswordLinkParams = '';
  @Input() pattern;
  @Input() showForgotPassword;
  @Input() autocomplete;
  @Input() showTooltip = true;
  rules = {
    rules1: false,
    rules2: false,
    rules3: false,
    rules4: false,
    rules5: false
  };

  showPassword() {
    this.type = 'text';
  }

  hidePassword() {
    this.type = 'password';
  }

  checkRules(val: string) {
    if (val.length === 0) { 
      return this.rules = {
        rules1: false,
        rules2: false,
        rules3: false,
        rules4: false,
        rules5: false
      };
    }
    this.rules.rules1 =  /^.{8,}$/.test(val);
    this.rules.rules2 = /[A-Z]+/.test(val);
    this.rules.rules3 =  /[a-z]+/.test(val);
    this.rules.rules4 = /.*\d.*/g.test(val);
    this.rules.rules5 = /.*[!@#$%^&*()\-_=+[\]{};:'",.<>?\\|/].*/.test(val);

  }

  getIcon(isValid: boolean) {
    return isValid ? this.icons.faCheck : this.icons.faX;
  }
}
