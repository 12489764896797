import { Action } from '@ngrx/store';
import { SelectedFlight } from 'app/flights/models/selected-flight.interface';

export enum SelectedFlightsActionTypes {
  SelectFlight = '[Selected Flights] Select Flight',
  SelectFlightSuccess = '[Selected Flights] Select Flight Success',
  SelectFlightFailure = '[Selected Flights] Select Flight Failure',
  ResetFlights = '[Selected Flights] Reset Flights'
}

export class SelectFlight implements Action {
  readonly type = SelectedFlightsActionTypes.SelectFlight;

  constructor(public payload: {
    modifying: boolean,
    params: any,
    searchId: string,
    segmentId: string,
    toolKey: string,
    selectFlight: SelectedFlight,
  }) { }
}

export class SelectFlightSuccess implements Action {
  readonly type = SelectedFlightsActionTypes.SelectFlightSuccess;

  constructor(public payload: { params: SelectedFlight, redirect: any, searchId: string }) { }
}

export class SelectFlightFailure implements Action {
  readonly type = SelectedFlightsActionTypes.SelectFlightFailure;

  constructor(public payload: { error: any }) { }
}

export class ResetFlights implements Action {
  readonly type = SelectedFlightsActionTypes.ResetFlights;

  constructor() { }
}

export type SelectedFlightsActionsUnion = SelectFlight
  | SelectFlightSuccess
  | SelectFlightFailure
  | ResetFlights;
