import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthenticationService } from 'app/auth/services/authentication.service';
import { AuthApiActions, MultifactorAuthenticationActions } from 'app/auth/store/actions';
import { MfaMediums } from 'app/profile/models/mfa';
import { Modal } from 'app/shared/models/modal.model';
import { ModalActions } from 'app/shared/store/actions';
import * as fromRoot from 'reducers';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  template: '<loading-indicator></loading-indicator>'
})

export class ConfirmEmailComponent implements OnInit, OnDestroy {

  token: string;
  memberId: string;
  ngUnsubscribe$ = new Subject<void>();

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<fromRoot.State>
  ) { }

  ngOnInit() {
    this.token = this.activatedRoute.snapshot.queryParams['token'] || '';
    this.activatedRoute.params.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((params) => {
      this.memberId = params.id || '';
      this.confirmEmail();
    });
  }

  confirmEmail() {
    this.authService.confirmEmail(this.memberId, this.token).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(
    (response:any) => {
      if (response) {
        const params = {
          mfaEmail: 1,
          medium: MfaMediums.EMAIL
        };
        localStorage.setItem('dontAskAgain', 'true');
        this.store.dispatch(new MultifactorAuthenticationActions.InsertNewIP());
        this.store.dispatch(new AuthApiActions.UpdateUserAccountInfo(params));
        this.store.dispatch(new AuthApiActions.FirstTimeAccessSuccess());
        this.store.dispatch(new AuthApiActions.ConfirmEmail({}));
        const data: Modal = {
          id: 'confirmedEmail',
          imageSrc: 'angular_assets/images/common/account-confirmed.png',
          imageCls: 'register-success-img',
          body: response.result,
          modalOptions: { size: 'md' }
        };
        this.store.dispatch(new ModalActions.Show({ data }));
      }
      return this.router.navigate(['/']);
    },
    (error) => {
      const data: Modal = {
        bodyTranslationKey: 'members.confirm_email.invalid_token',
        modalOptions: { size: 'md' }
      };
      this.store.dispatch(new ModalActions.Show({ data }));
      return this.router.navigate(['/']);
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
