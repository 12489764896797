import { Action } from '@ngrx/store';

export enum FlightWatchlistActionTypes {
  Add = '[Flight Watchlist] Add',
  Load = '[Flight Watchlist] Load',
  Remove = '[Flight Watchlist] Remove'
}

export class Add implements Action {
  readonly type = FlightWatchlistActionTypes.Add;

  constructor(public payload: { watchlist: any, toolKey: string }) { }
}

export class Load implements Action {
  readonly type = FlightWatchlistActionTypes.Load;

  constructor() {}
}

export class Remove implements Action {
  readonly type = FlightWatchlistActionTypes.Remove;

  constructor(public payload: { watchlist: any }) {}
}

export type FlightWatchlistActionsUnion = Add
  | Load
  | Remove;
