import { Component, Input } from '@angular/core';

@Component({
  selector: 'promo-card',
  styleUrls: ['./promo-card.scss'],
  templateUrl: 'promo-card.html'
})

export class PromoCardComponent {
  @Input() data;
}
