import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({ selector: 'cvv-modal', templateUrl: 'cvv-modal.html' })

export class CvvModalComponent {

  @Input() cardType: string;

  constructor(private activeModal: NgbActiveModal) {}

  dismiss() {
    this.activeModal.dismiss(null);
  }
}
