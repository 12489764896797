import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';
import { GeneralHelper } from 'app/shared/helpers/general.helper';
import { Country } from 'app/shared/models/country.model';
import { CustomValidators } from 'app/shared/validators/custom-validators';
import { AsYouType, CountryCode, getExampleNumber } from 'libphonenumber-js';
import examplesMobileJson from 'libphonenumber-js/examples.mobile.json';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'phone-input',
  templateUrl: 'phone-input.component.html',
  styleUrls: ['phone-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhoneInputComponent implements OnDestroy, AfterViewInit, OnChanges {
  @Input() countries: Country[] = [];
  @Input() labelKey: string;
  @Input() formGroupName: string = 'homePhone';
  @Input() formGroup: UntypedFormGroup | null;
  @Input() countryEnabled: boolean = true;
  @Input() numberEnabled: boolean = true;
  @Input() phoneForceError: boolean = false;
  @Input() hideNumber: boolean = false;
  @Input() showHelpText = false;
  @Input() strictPhoneValidation = false;
  @Output() onKeyUp = new EventEmitter();

  selectedCountry: Country;
  ngUnsubscribe = new Subject<void>();
  countrySubscription: Subscription;

  constructor(
    public formErrorsHelper: FormErrorsHelper,
    private changeDetectorRef: ChangeDetectorRef,
    private generalHelper: GeneralHelper
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.formGroup) {
      if (this.countrySubscription) {
        if (this.formGroup.value.country && this.formGroup.value.number) {
          this.doFormatNumber();
        }
        this.countrySubscription.unsubscribe();
      }
      this.countrySubscription = this.formGroup.get('country').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
        this.doFormatNumber();
      });
    }
  }

  doFormatNumber() {
    this.formatNumber(this.formGroup.value.number);
    this.selectedCountry = this.formGroup.value.country;
    if (this.strictPhoneValidation && this.formGroup.get('number') && this.selectedCountry) {
      this.formGroup.get('number').addValidators(CustomValidators.strictValidation());
    }
    this.formGroup.get('number').markAsTouched({ onlySelf: true });
    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewInit() {
    this.formatNumber(this.formGroup.value.number, this.hideNumber);
    this.changeDetectorRef.detectChanges();
  }

  searchCountry(term: string, item: any): boolean {
    return (item.name.toLowerCase().indexOf(term.toLowerCase()) >= 0) || (`${item.phone}`.indexOf(term) >= 0);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  get getPlaceHolderByCountry() {
    if (!this.selectedCountry || !this.selectedCountry.code) return '';
    const asYouType = new AsYouType(this.selectedCountry.code as CountryCode);
    const phoneNumber = getExampleNumber(this.selectedCountry.code as CountryCode, examplesMobileJson);
    asYouType.input(`${phoneNumber.nationalNumber}`);
    return asYouType.getTemplate();
  }

  keyUpController($event) {
    const codeAreaMinLength = 5;

    if (!this.formGroup?.value?.number || this.formGroup.value.number.length < codeAreaMinLength) {
      return;
    };
    this.onKeyUp.emit($event);
    this.formatNumber(this.formGroup.value.number.trim());
  }

  formatNumber(number, hide: boolean = false) {
    if (!number) return;
    if (this.formGroup.get('number').value && this.formGroup.get('country').value) {
      const phoneNumber = this.formattedPhoneNumber(number, this.formGroup.get('country').value.code);
      this.formGroup.get('number').setValue(!hide ? phoneNumber : this.generalHelper.hideString(phoneNumber));
    }
  }

  formattedPhoneNumber(phoneNumber, countryCode) {
    if (phoneNumber && countryCode) {
      return new AsYouType(countryCode).input(phoneNumber);
    }
  }
}
