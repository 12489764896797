import { GeocodingService } from '../geocoding.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LocationiqGeocodingService implements GeocodingService {

  private readonly BASE_URL = 'https://api.locationiq.com/v1';

  constructor(
    private http: HttpClient
  ) {}

  getLocations(term: string, locationType: string): Observable<any[]> {
    if (term === '') {
      return of([]);
    }

    if (locationType === 'hotels' || locationType === 'address' || locationType === 'airport') {
      return this.http.get<any[]>(
        `${this.BASE_URL}/autocomplete.php?key=${environment.locationiqApiKey}&q=${term}`,
        {
          headers: new HttpHeaders({ 'No-Auth': 'true', 'no-version': 'true' })
        }
      );
    }

    if (locationType === 'cars') {
      return this.http.get<any>(`${environment.apiUrl}/api/v1/car_locations.json`, { params: { query: term } }).pipe(
        map((response) => {
          return response.data;
        })
      );
    }
  }

  getLocationCoords(place: any): Observable<any> {
    return Observable.create((observer) => {
      observer.next({
        display_text: place.display_name,
        lat: place.lat,
        lng: place.lon
      });
      observer.complete();
    });
  }

  getLocationDetails(place: any): Observable<any> {
    return Observable.create((observer) => {
      observer.next({
        display_text: place.display_name,
        lat: place.lat,
        lng: place.lon
      });
      observer.complete();
    });
  }

  clearSessionToken() {}
}
