<ng-container *ngIf="!pendingTemplate && !eligibleTravelerTestTemplate" [ngTemplateOutlet]="defaultTemplate"></ng-container>
<ng-container *ngIf="!pendingTemplate && eligibleTravelerTestTemplate" [ngTemplateOutlet]="testTemplate"></ng-container>

<ng-template #defaultTemplate>
  <section class="create-account">
    <div *ngIf="showLandingInvitePage" class="container">
      <div class="row justify-content-center">
        <div class="col">
          <div class="invited-banner text-center">
            <img class="img-fluid iaf-header" src="https://marketing.id90travel.com/email/iaf/iaf_header.png"/>
          </div>
          <div class="secondary-content mt-3 justify-content-center landing-text"
            [innerHTML]="remoteContent.landingDescription"></div>
          <div class="mt-4 text-center">
            <button class="btn btn-primary gtm-invite" (click)="clickAcceptInvitation()">{{ 'invite.invite_accept_button' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!showLandingInvitePage" class="container-fluid">
      <div class="row">
        <div class="col-xl-4 col-lg-5 col-md-6 col-sm-8 col-xs-12 offset-lg-1 offset-md-1 offset-sm-2 pt-3 pt-md-5">
          <div class="row pb-4">
            <div class="col-sm-12">
              <h3 [innerHtml]="primaryContent"></h3>
              <div class="tertiary-content pt-2" [innerHTML]="'membership_modal.have_an_account' | translate"></div>
              <div class="tertiary-content pt-2">
                {{ "membership.create.current_airline_employee" | translate }}
                <a class="d-inline" [routerLink]="['/create-account']" [queryParams]="{employee: 'true'}">
                  {{ "membership.create.register_here" | translate }}
                </a>
              </div>
            </div>
          </div>
          <ngb-alert type="danger" *ngIf="errorMessage" (closed)="errorMessage = ''">
            <div [innerHtml]="errorMessage"></div>
          </ngb-alert>
          <loading-indicator *ngIf="loading"></loading-indicator>
          <form *ngIf="!loading" [formGroup]="createAccountForm" (ngSubmit)="createAccount()">
            <div class="form-group form-floating">
              <input type="text" id="employee_number" class="form-control with-background" formControlName="employee_number" placeholder=" " required autocomplete="email" maxlength="80" data-cy="employee_number" />
              <label for="employee_number" data-error="error">{{ "membership.create.email_label" | translate }}</label>
              <div
                class="error-message"
                *ngIf="employeeNumber.errors !== null && employeeNumber.touched"
                [innerHtml]="formErrorsHelper.errorMessage(employeeNumber, ('membership.create.email_label' | translate))">
              </div>
            </div>
            <div class="form-group" *ngIf="countries && phoneReady">
              <phone-input
                labelKey="profile.info.homePhone"
                formGroupName="homePhone"
                [countries]="countries"
                [formGroup]="createAccountForm.get('homePhone')"
                [countryEnabled]="true"
                [numberEnabled]="true"
                [phoneForceError]="phoneError"
                [showHelpText]="true"
                (onKeyUp)="checkHomePhone()"
              ></phone-input>
            </div>
            <div class="form-group">
              <password-input [control]="'password'" [id]="'password'" [form]="createAccountForm"
                [label]="'membership.create.create_password_label' | translate" autocomplete="password" [showTooltip]="true">
              </password-input>
              <div class='error-message' *ngIf="password.errors !== null && (password.touched)"
              [innerHtml]="formErrorsHelper.errorMessage(password, ('membership.create.create_password_label' | translate))">
              </div>
            </div>
            <div class="form-group">
              <password-input [control]="'password_confirm'" [id]="'password_confirm'" [form]="createAccountForm"
                [label]="'membership.create.create_confirm_password' | translate" autocomplete="off" [showTooltip]="true">
              </password-input>
              <div class='error-message' *ngIf="password_confirm.errors !== null && (password_confirm.touched)"
              [innerHtml]="formErrorsHelper.errorMessage(password_confirm, ('membership.create.create_confirm_password' | translate))">
              </div>
            </div>
            <div class="form-group form-floating"  *ngIf="promoCode">
              <input type="text" id="promo_code" class="form-control with-background" formControlName="promo_code" placeholder=" " (change)="promoCodeIsValid($event)">
              <label for="promoCode" data-error="error">{{ "membership.create.promo_code" | translate }}</label>
              <div
                class="error-message"
                *ngIf="promoCode && (promo_code.touched && promo_code.errors !== null)"
                [innerHtml]="formErrorsHelper.errorMessage(promoCode, ('membership.create.invalid_promo_code' | translate))">
              </div>
            </div>
            <div class="row pb-3">
              <div class="col-md-6 col-sm-6 text-end">
                <button type="submit" class="btn btn-primary w-100" data-cy="submit">
                  {{ "action_terms.create_account" | translate }}
                </button>
              </div>
            </div>
            <div>
              <label class="tertiary-content">
                {{ 'membership.create.acknowledge_terms_and_conditions' | translate }}
                <a href="/navigation/terms_and_conditions" target="_blank">{{ 'membership.create.terms_and_condition' | translate }}</a>.
              </label>
            </div>
            <div class="form-check">
              <input type="checkbox" class="form-check-input" id="email_opt_out" name="email_opt_out"
                formControlName="email_opt_out">
              <label class="tertiary-content" for="email_opt_out">{{ 'membership_modal.email_opt_out' | translate }}</label>
            </div>
          </form>
        </div>
        <div class="col-xl-5 col-lg-5 col-md-5 offset-xl-2 offset-lg-1 position-relative d-none d-md-block customer-benefits fadeIn animated">
          <div class="card shadow mt-5">
            <div class="card-body">
              <div class="secondary-content" [innerHtml]="secondaryContent"></div>
            </div>
          </div>
          <eligible-traveler-summary *ngIf="travelerCode; else itinerarySummary" [eligibleInfo]="eligibleTravelerInfo"></eligible-traveler-summary>
        </div>
        <ng-template #itinerarySummary>
          <co-traveler-itinerary-summary
            [referrer]="referrerName"
            [itinerary]="itinerary"
            [storyText]="remoteContent.messageText"
          ></co-traveler-itinerary-summary>
        </ng-template>
      </div>
    </div>
  </section>
</ng-template>

<ng-template #testTemplate>
  <section class="create-account default-background pt-3">
    <div class="container">
      <div class="card shadow-none">
        <div class="card-body">
          <div class="row justify-content-center h-100">
            <div class="col-5 col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-6 pt-3 avatar-container">
              <avatar-circle class="me-3" [noavatar]="employeeAvatar.avatar ? employeeAvatar.avatar : null" [type]="'create-account'"></avatar-circle>
              <div> {{ employeeAvatar.fullName }} </div>
              <span class="tertiary-content"> {{ employeeAvatar.airlineName }} </span>
            </div>
            <div class="col-xl-9 col-lg-8 col-md-6 col-sm-10 col-xs-12 pt-3 eligible-traveler-top">
              <h4> {{ "profile.eligible_travelers.create_account.title" | translate }}</h4>
              <p class="tertiary-content custom-content"
                 [innerHTML]=" 'profile.eligible_travelers.create_account.paragraph' | translate:{ employeeName:employeeAvatar.fullName, airlineName: employeeAvatar.airlineName.toLowerCase() }">
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center h-100 pt-3">

        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
          <ngb-alert type="danger" *ngIf="errorMessage" (closed)="errorMessage = ''">
            <div [innerHtml]="errorMessage"></div>
          </ngb-alert>
          <loading-indicator *ngIf="loading" [fullScreen]="false"></loading-indicator>
          <form *ngIf="!loading" [formGroup]="createAccountForm" (ngSubmit)="createAccount()">
            <div class="card shadow-none mb-2">
              <div class="card-body">
                <h4 [innerHtml]="primaryContent"></h4>
                <div class="form-group form-floating">
                  <input type="text" id="employee_number" class="form-control with-background" formControlName="employee_number" placeholder=" " required autocomplete="email" maxlength="80" data-cy="employee_number" />
                  <label for="employee_number" data-error="error">{{ "membership.create.email_label" | translate }}</label>
                  <div
                    class="error-message"
                    *ngIf="employeeNumber.errors !== null && employeeNumber.touched"
                    [innerHtml]="formErrorsHelper.errorMessage(employeeNumber, ('membership.create.email_label' | translate))">
                  </div>
                </div>
                <div class="form-group">
                  <password-input [control]="'password'" [id]="'password'" [form]="createAccountForm"
                    [label]="'membership.create.create_password_label' | translate" autocomplete="password" [showTooltip]="true">
                  </password-input>
                  <div class='error-message' *ngIf="password.errors !== null && (password.touched)"
                  [innerHtml]="formErrorsHelper.errorMessage(password, ('membership.create.create_password_label' | translate))">
                  </div>
                </div>
                <div class="form-group">
                  <password-input [control]="'password_confirm'" [id]="'password_confirm'" [form]="createAccountForm"
                    [label]="'membership.create.create_confirm_password' | translate" autocomplete="off" [showTooltip]="true">
                  </password-input>
                  <div class='error-message' *ngIf="password_confirm.errors !== null && (password_confirm.touched)"
                  [innerHtml]="formErrorsHelper.errorMessage(password_confirm, ('membership.create.create_confirm_password' | translate))">
                  </div>
                </div>
                <div class="form-group form-floating"  *ngIf="promoCode">
                  <input type="text" id="promo_code" class="form-control with-background" formControlName="promo_code" placeholder=" " (change)="promoCodeIsValid($event)"/>
                  <label for="promoCode" data-error="error">{{ "membership.create.promo_code" | translate }}</label>
                  <div
                    class="error-message"
                    *ngIf="promoCode && (promo_code.touched && promo_code.errors !== null)"
                    [innerHtml]="formErrorsHelper.errorMessage(promoCode, ('membership.create.invalid_promo_code' | translate))">
                  </div>
                </div>
              </div>
            </div>

            <div class="row align-items-end create-button">
              <div class="col-lg-6 col-md-6 col-sm-6">
                <button type="submit" class="btn btn-primary w-100 gtm-create-account" data-cy="submit">{{ "action_terms.create_account" | translate }}</button>
              </div>
            </div>
            <div class="text-end">
              <label class="tertiary-content">
                {{ 'membership.create.acknowledge_terms_and_conditions' | translate }}
                <a href="/navigation/terms_and_conditions" target="_blank">{{ 'membership.create.terms_and_condition' | translate }}</a>.
              </label>
            </div>
            <div class="form-check text-end">
              <input type="checkbox" class="form-check-input" id="email_opt_out" name="email_opt_out"
                formControlName="email_opt_out">
              <label class="tertiary-content" for="email_opt_out">{{ 'membership_modal.email_opt_out' | translate }}</label>
            </div>
          </form>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <div class="row pb-4">
            <div class="col-sm-12">
              <div class="tertiary-content custom-content pt-2" [innerHTML]="'membership_modal.have_an_account' | translate"></div>
              <div class="tertiary-content custom-content pt-2">
                {{ "membership.create.current_airline_employee" | translate }}
                <a class="d-inline" [routerLink]="['/create-account']" [queryParams]="{employee: 'true'}">
                  {{ "membership.create.register_here" | translate }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-template>
