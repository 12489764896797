import { Component, Input } from '@angular/core';
import { UserCreditHistoryItem } from 'app/profile/models/user-credit-history.interface';

@Component({
  selector: 'hotel-credit-history',
  templateUrl: './hotel-credit-history.html',
  styleUrls: ['./hotel-credit-history.scss']
})
export class HotelCreditHistoryComponent {
  visibleUserCreditHistory: UserCreditHistoryItem[] = [];
  totalPages = 0;

  private _userCreditHistory: UserCreditHistoryItem[] = [];
  private _page: number = 1;

  @Input() pageSize: number = 6;
  @Input() title: string;
  @Input() enablePagination: boolean = true;

  @Input()
  get userCreditHistory(): UserCreditHistoryItem[] {
    return this._userCreditHistory;
  }
  set userCreditHistory(val: UserCreditHistoryItem[]) {
    this._userCreditHistory = val;
    this.totalPages = Math.ceil(val.length / this.pageSize);
    this.setVisibleUserCreditHistory();
  }

  get page(): number {
    return this._page;
  }
  set page(val: number) {
    this._page = val;
    this.setVisibleUserCreditHistory();
  }

  private get offset(): number {
    return this.pageSize * (this.page - 1);
  }

  private setVisibleUserCreditHistory() {
    this.visibleUserCreditHistory = this._userCreditHistory.slice(this.offset, this.offset + this.pageSize);
  }

}
