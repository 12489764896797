import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { UnlinkEmailActions } from '../actions';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ProfileService } from 'app/shared/services/profile/profile.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

@Injectable()
export class UnlinkEmailEffects {

  unlinkEmail$ = createEffect(()=>
    this.actions$.pipe(
      ofType<UnlinkEmailActions.UnlinkRequest>(UnlinkEmailActions.UnlinkEmailActionTypes.UnlinkRequest),
      switchMap((params) =>
        this.profileService.unlinkEmailRequest(params.payload).pipe(
          map(res => new UnlinkEmailActions.UnlinkRequestSuccess(res)),
          catchError(error => of(new UnlinkEmailActions.UnlinkRequestError(error)))
        )
      )
    )
  );

  verifyCode$ = createEffect(()=>
    this.actions$.pipe(
      ofType<UnlinkEmailActions.UnlinkEmailVerifyCode>(UnlinkEmailActions.UnlinkEmailActionTypes.UnlinkEmailVerifyCode),
      switchMap((params) =>
        this.profileService.unlinkEmailVerify(params.payload).pipe(
          map(res => {
            return new UnlinkEmailActions.UnlinkEmailVerifyCodeSuccess(res)
          }),
          catchError(error => of(new UnlinkEmailActions.UnlinkEmailVerifyCodeError(error)))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private profileService: ProfileService,
    private cookieService: CookieService,
    private router: Router
  ) {}
}
