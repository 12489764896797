import { Action } from '@ngrx/store';

export enum CreditsActionTypes {
  LoadCredits = '[Profile] Load Credits',
  LoadCreditsSuccess = '[Profile] Load Credits Success',
  LoadCreditsFailure = '[Profile] Load Credits Failure',
  UpdateCredits = '[Profile] Update Credits'
}

export class LoadCredits implements Action {
  readonly type = CreditsActionTypes.LoadCredits;
}

export class LoadCreditsSuccess implements Action {
  readonly type = CreditsActionTypes.LoadCreditsSuccess;

  constructor(public payload: any) {}
}

export class LoadCreditsFailure implements Action {
  readonly type = CreditsActionTypes.LoadCreditsFailure;

  constructor(public payload: { error: any }) {}
}

export class UpdateCredits implements Action {
  readonly type = CreditsActionTypes.UpdateCredits;

  constructor(public payload: any) {}
}

export type CreditActionsUnion = LoadCredits
  | LoadCreditsSuccess
  | LoadCreditsFailure
  | UpdateCredits;
