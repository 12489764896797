import { HotelResultsCacheActions } from 'app/hotels/store/actions';
import { Hotel } from 'app/hotels/models/hotel';
import * as moment from 'moment';
import { HotelFilters } from 'app/hotels/models/hotel-filters';

interface FiltersCacheState extends HotelFilters {
  searchId: string;
}

export interface State {
  results: {
    searchId: string;
    hotels: Hotel[];
    meta: any;
    viewRadius: any;
    defaultRadius: number;
    timestamp: Date;
  };
  scrollIndex: number;
  filters: FiltersCacheState;
  resultsView: string;
}

export const initialState: State = {
  results: {
    searchId: null,
    hotels: [],
    meta: null,
    viewRadius: null,
    defaultRadius: null,
    timestamp: null
  },
  scrollIndex: 0,
  filters: null,
  resultsView: null
};

export function reducer(
  state = initialState,
  action: HotelResultsCacheActions.HotelResultsCacheActionUnion
) {
  switch (action.type) {
    case HotelResultsCacheActions.HotelResultsCacheActionTypes.SaveHotels:
      return {
        ...state,
        results: {
          searchId: action.payload.searchId,
          hotels: action.payload.meta.page > 1 ? state.results.hotels.concat(action.payload.hotels) : action.payload.hotels,
          meta: action.payload.meta,
          viewRadius: action.payload.viewRadius,
          defaultRadius: action.payload.defaultRadius,
          timestamp: moment().toDate(),
        },
        scrollIndex: initialState.scrollIndex
      };
    case HotelResultsCacheActions.HotelResultsCacheActionTypes.ClearHotels:
      return {
        ...state,
        results: initialState.results,
        scrollIndex: initialState.scrollIndex
      };
    case HotelResultsCacheActions.HotelResultsCacheActionTypes.SaveScrollIndex:
      return {
        ...state,
        scrollIndex: action.payload.scrollIndex
      };
    case HotelResultsCacheActions.HotelResultsCacheActionTypes.SaveFilters:
      return {
        ...state,
        filters: action.payload
      };
    case HotelResultsCacheActions.HotelResultsCacheActionTypes.ResetSearchId:
      return {
        ...state,
        filters: {
          ...state.filters,
          searchId: null
        }
      };
    case HotelResultsCacheActions.HotelResultsCacheActionTypes.SaveResultsView:
      return {
        ...state,
        resultsView: action.payload.view
      };
    default:
      return state;
  }
}

export const getHotelsCacheResults = (state: State) => state.results;
export const getHotelsCacheScrollIndex = (state: State) => state.scrollIndex;
export const getHotelsCacheFilters = (state: State) => state.filters;
export const getHotelsResultsView = (state: State) => state.resultsView;
