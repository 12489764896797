<register-form
  (submitted)="createAccount($event)"
  (showMessage)="saveMessageEvent($event)"
  [errorMessage]="error$ | async"
  (clearErrorMessage)="clearErrorMessage()"
  [pending]="pending$ | async"
  [pendingLogin]="pendingLogin$ | async"
  [airlines]="airlines$ | async"
  [countries]="countries$ | async"
  [inviteCode]="inviteCode"
  [uniqueCode]="uniqueCode"
  [inviteCodeType]="inviteCodeType"
  [inviteCodeSource]="inviteCodeSource"
  [shareCode]="shareCode"
  [travelerCode]="travelerCode"
  [utmMedium]="utmMedium"
  [utmSource]="utmSource"
  [utmCampaign]="utmCampaign"
  [airlines]="airlines$ | async"
>
</register-form>
<footer-component></footer-component>
