import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { EligibleTravelerStatus, InvitedTravelerStatus } from 'app/profile/models/eligibles-travelers';

@Component({
  selector: 'eligible-traveler-card',
  templateUrl: './eligible-traveler-card.html',
  styleUrls: ['./eligible-traveler-card.scss'],
})
export class EligibleTravelerCardComponent {
  @Input() canInvite;
  @Input() traveler;
  @Output() onEditOpen = new EventEmitter();

  icons = { faClock };

  readonly ELIGIBLE_TRAVELER_STATUS = EligibleTravelerStatus;
  readonly INVITED_TRAVELER_STATUS = InvitedTravelerStatus;

  constructor(private translate: TranslateService) {}

  onEditClick(travelerId: string, tab: number) {
    this.onEditOpen.emit({ tab, travelerId });
  }

  public getTravelerStatus(travelerStatus) {
    switch (travelerStatus) {
      case 'Active':
        return this.translate.instant('profile.eligible_travelers.active_status');
      case 'Inactive':
        return this.translate.instant('profile.eligible_travelers.inactive_status');
      default:
        return travelerStatus;
    }
  }
}
