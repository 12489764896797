import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TripInsuranceHelper {

  readonly restrictedStates = [
    'PR'
  ];

  getDefaultPlan(): number {
    return 3;
  }

  isValidTraveler(travelerInfo): boolean {
    return !!travelerInfo.firstName && !!travelerInfo.lastName &&
      !!travelerInfo.birthDate && !!travelerInfo.personalEmail;
  }
  isRestrictedCountry(country): boolean {
    return ['US', 'CA'].indexOf(country) < 0;
  }

  isRestrictedState(state): boolean {
    return state === undefined || this.restrictedStates.indexOf(state) >= 0;
  }
}
