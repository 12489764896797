import { Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { environment } from '@env/environment';
import { faApple, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as fromAuth from 'app/auth/store/reducers';
import { MFAHelper } from 'app/profile/helpers/mfa.helper';
import { MfaActions } from 'app/profile/models/mfa';
import { Socials } from 'app/profile/models/third-party';
import { TwoStepSecurityModal } from 'app/shared/containers/two-step-security/two-step-security-modal';
import { DynamicScriptLoaderService } from 'app/shared/services/dynamic-script-load/dynamic-script-load.service';
import * as fromShared from 'app/shared/store/reducers';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as fromRoot from '/reducers';

@Component({
  selector: 'social-buttons-component',
  templateUrl: './social-buttons.component.html',
  styleUrls: ['./social-buttons.component.scss']
})

export class SocialButtonsComponent implements OnInit, OnDestroy {
  @Input() fromProfile = false;
  @Input() requestMFA = false;
  @Input() showFacebook: boolean = true;
  @Input() showApple: boolean = true;
  @Input() showGoogle: boolean = true;
  @Output() clickEventAction = new EventEmitter();
  mfaError = false;
  googleId = environment.googleContactsClientID;
  noGoggleSession = false;
  socials = Socials;
  userInfo: any;
  googleClient: any;

  userInfo$ = this.store.pipe(select(fromAuth.getUser));
  featureFlags$ = this.store.pipe(select(fromShared.getFeatureFlags));

  private ngUnsubscribe = new Subject<void>();
  icons = { faFacebookF, faApple };

  constructor(
    private dynamicScriptLoader: DynamicScriptLoaderService,
    private mfaHelper: MFAHelper,
    private modalService: NgbModal,
    private ngZone: NgZone,
    private store: Store<fromRoot.State>,
    public translate: TranslateService
  ) { }

  ngOnInit() {
    this.loadScripts();
    if (this.fromProfile) { this.loadInfo(); }
  }

  handlerGoogleSignIn(response) {
    this.ngZone.run(() => {
      if (response) {
        this.clickEventAction.emit(response.access_token);
      }
    });
  }

  private loadScripts() {
    this.dynamicScriptLoader.load('apisGoogleGSIClient').then((r) => {
    }).catch(error => console.log(error, 'error'));
  }

  clickAction(social: Socials) {
    if (this.requestMFA) {
      return this.openTwoStepSecurityModal(MfaActions.MFA_PROFILE_UPDATE, social);
    }
    else {
      this.clickEventAction.emit(social);
    }
  }


  loadInfo() {
    combineLatest([this.featureFlags$, this.userInfo$]).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(([features, user]) => {
      if (features && user) {
        this.userInfo = user;
      }
    });
  }

  openTab() {
    if (this.requestMFA) {
      return this.openTwoStepSecurityModal(MfaActions.MFA_PROFILE_UPDATE, Socials.GOOGLE);
    }
    return this.openGoogleProcess();
  }

  openGoogleProcess() {
    this.noGoggleSession = false;
    if (!this.googleClient) {
      this.googleClient = window.google.accounts.oauth2.initTokenClient({
        client_id: this.googleId,
        callback: this.handlerGoogleSignIn.bind(this),
        scope: 'openid profile',
        }).requestAccessToken();
        return;
    }
    this.googleClient.requestAccessToken();
  }

  closeGoogleAlert() {
    this.noGoggleSession = false;
  }

  closeMfaError() {
    this.mfaError = false;
  }

  openTwoStepSecurityModal(action: string = '', social: Socials) {
    const twoStepSecurityModal = this.modalService.open(TwoStepSecurityModal, { size: 'lg' });
    twoStepSecurityModal.componentInstance.userData = this.userInfo;
    twoStepSecurityModal.componentInstance.action = action;
    twoStepSecurityModal.componentInstance.fromLogin = false;
    twoStepSecurityModal.result.then(
      (success) => {
        if (success) {
          if (social === Socials.GOOGLE ) { return this.openGoogleProcess(); }
          return this.clickEventAction.emit(social);
        }
      },
      () => {
          this.mfaError = true;
      }
    )
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
