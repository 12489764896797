import { of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})

export class SessionService {
  private accessToken: string;
  private rememberMe: Object;
  private user: Object;
  private account: Object;

  constructor(private cookieService: CookieService) { }

  logout() {
    this.clearState();
    return of({ response: true });
  }

  clearState() {
    this.removeAllCookies();
    this.user = null;
  }

  removeAllCookies() {
    this.cookieService.deleteAll('/',null,true,'Lax');
  }

  extendRememberMeExpiration() {
    let rememberMe = this.cookieService.get('remember_me');
    rememberMe = rememberMe ? JSON.parse(rememberMe) : null;
    this.setRememberMe(rememberMe);
  }

  getAccessToken() {
    return this.accessToken;
  }

  getRememberMe() {
    return this.rememberMe;
  }

  getUser() {
    return this.user;
  }

  getAccount() {
    return this.account;
  }

  getSmartLookSessionId() {
    return this.cookieService.get('SL_C_23361dd035530_SID');
  }

  getCurrency() {
    return this.cookieService.get('currency');
  }

  loadFromCookie() {
    this.accessToken = this.cookieService.get('access_token');
    const rememberMe = this.cookieService.get('remember_me');
    this.rememberMe = rememberMe ? JSON.parse(rememberMe) : null;
  }

  setAccessToken(token) {
    this.accessToken = token;
    this.cookieService.set('access_token', token, this.cookieExpiration(), '/', '', environment.secureCookies);
  }

  setRememberMe(tokenValues) {
    this.rememberMe = tokenValues;
    this.cookieService.set(
      'remember_me',
      JSON.stringify(tokenValues),
      this.cookieExpiration(
        new Date(Date.now() + 365 * 24 * 60 * 60 * 1000)
      ),
      '/',
      '',
      environment.secureCookies
    );
  }

  setRememberMeValues(airline, user, token) {
    if (!airline || !user || !token) {
      return;
    }

    this.setRememberMe({
      airline,
      user,
      token
    });
  }

  setState(state) {
    this.setAccessToken(state.access_token);
    if (state.member) {
      this.setRememberMeValues(
        state.member.airline_code,
        state.member.employee_number,
        state.remember_me_token
      );
      this.user = state.member;
      if (state.account) {
        this.account = state.account;
      }
    }
  }

  setPartialState(state) {
    this.user = state.member;
    if (state.account) {
      this.account = state.account;
    }
  }

  setCurrency(currency: string) {
    this.cookieService.set('currency', currency, this.cookieExpiration(), '/', '', environment.secureCookies);
  }

  setInviteCode(inviteCode: string) {
    this.cookieService.set('invite_code', inviteCode, this.cookieExpiration(), '/', '', environment.secureCookies);
  }

  setUniqueInviteCode(uniqueCode: string) {
    this.cookieService.set('unique_code', uniqueCode, this.cookieExpiration(), '/', '', environment.secureCookies);
  }

  setInviteCodeType(inviteCodeType: string) {
    this.cookieService.set('invite_code_type', inviteCodeType, this.cookieExpiration(), '/', '', environment.secureCookies);
  }

  setInviteCodeSource(inviteCodeSource: string) {
    this.cookieService.set('invite_code_source', inviteCodeSource, this.cookieExpiration(), '/', '', environment.secureCookies);
  }

  setNewFlightListingCookie() {
    this.cookieService.set('new_lis', 'true', this.cookieExpiration(), '/', '', environment.secureCookies);
  }

  getNewFlightListingCookie() {
    return this.cookieService.get('new_lis');
  }

  removeNewFlightListingCookie() {
    return this.cookieService.delete('new_lis');
  }

  getInviteCode() {
    return this.cookieService.get('invite_code');
  }

  getUniqueInviteCode() {
    return this.cookieService.get('unique_code');
  }

  getInviteCodeType() {
    return this.cookieService.get('invite_code_type');
  }

  getInviteCodeSource() {
    return this.cookieService.get('invite_code_source');
  }

  removeInviteCode() {
    this.cookieService.delete('invite_code');
  }

  setShareCode(shareCode: string) {
    this.cookieService.set('share_code', shareCode, this.cookieExpiration(), '/', '', environment.secureCookies);
  }

  getShareCode() {
    return this.cookieService.get('share_code');
  }

  removeShareCode() {
    this.cookieService.delete('share_code');
  }

  getLanguage() {
    return this.cookieService.get('id90_language');
  }

  setLanguage(lang: string) {
    this.cookieService.set('id90_language', lang, this.cookieExpiration(), '/', '', environment.secureCookies);
  }

  private cookieExpiration(expiration?) {
    if (expiration) { return expiration; }
    const now = new Date();
    now.setMinutes(now.getMinutes() + 30); // timestamp
    return new Date(now);
  }

  setTravelerCode(travelerCode: string) {
    this.cookieService.set('traveler_code', travelerCode, this.cookieExpiration(), '/', '', environment.secureCookies);
  }

  getTravelerCode() {
    return this.cookieService.get('traveler_code');
  }

  removeTravelerCode() {
    this.cookieService.delete('traveler_code');
  }

  setPromoCode(promoCode: string) {
    this.cookieService.set('promo_code', promoCode, this.cookieExpiration(), '/', '', environment.secureCookies);
  }

  getPromoCode() {
    return this.cookieService.get('promo_code');
  }

  removePromoCode() {
    this.cookieService.delete('promo_code');
  }
}
