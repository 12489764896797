import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { select, Store } from '@ngrx/store';
import * as LaunchDarkly from 'launchdarkly-js-client-sdk';
import { from, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import * as fromRoot from 'reducers';
import * as fromAuth from 'app/auth/store';
import { DeviceDetectorService } from 'ngx-device-detector';
import { User } from 'app/auth/models/user';

@Injectable({
  providedIn: 'root'
})
export class LaunchdarklyService {
  client: LaunchDarkly.LDClient;
  anonymousContext: LaunchDarkly.LDSingleKindContext = {
    kind: 'user',
    anonymous: true
  };
  private user$ = this.store.pipe(select(fromAuth.getUser));

  constructor(
    private store: Store<fromRoot.State>,
    private deviceService: DeviceDetectorService
  ) {}

    public buildContext(user: User): LaunchDarkly.LDMultiKindContext {
    const context: LaunchDarkly.LDMultiKindContext = {
      kind: 'multi',
      user: {
        key: `user-key-${user.member.member_id}`,
      },
      airline: {
        key: user.member.airline_code,
      },
      device: {
        key: this.deviceService.userAgent
      },
      platform: {
        key: this.deviceService.isDesktop() ? 'WEBSITE' : 'MOBILE_WEB'
      }
    };

    return context;
  }

  public initialize(user?: User): Observable<LaunchDarkly.LDSingleKindContext | LaunchDarkly.LDMultiKindContext> {
    const context = user ? this.buildContext(user) : this.anonymousContext;

    this.client = LaunchDarkly.initialize(environment.launchDarkly.clientId, context);
    this.client.on('error', (e) => {
      console.error('[LAUNCHDARKLY ERROR]', e);
    });

    return of(context);
  }

  public close() {
    return from(this.client.close());
  }

  public identify(user?: User): Observable<LaunchDarkly.LDFlagSet> {
    const context = user ? this.buildContext(user) : this.anonymousContext;

    return from(this.client.waitUntilReady()).pipe(
      switchMap(() => this.client.identify(context))
    );
  }

  public getFlag(flagKey: string, defaultValue: LaunchDarkly.LDFlagValue = false): Observable<LaunchDarkly.LDFlagValue> {
    return from(this.client.waitUntilReady()).pipe(
      map(() => this.client.variation(flagKey, defaultValue))
    );
  }

  public getDefaultContext(user?: User): Observable<LaunchDarkly.LDContext> {
    if (user) {
      return of(this.buildContext(user));
    } else {
      return this.user$.pipe(
        take(1),
        map((fetchedUser) => this.buildContext(fetchedUser))
      );
    }
  }
}
