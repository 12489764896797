import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import * as moment from 'moment';
import { filter } from 'rxjs/operators';
import { PlatformLocation } from '@angular/common';
import { environment } from '@env/environment';
@Injectable({
  providedIn: 'root'
})
export class GeneralHelper {
  private navigationEndHistory: NavigationEnd[] = [];
  private navigationHistoryMaxLength = 16;
  private readonly AIRLINE_TRUE = 'airline_true';
  private readonly DEV = 'development';
  private readonly BETA = 'beta';
  private readonly BASIC_TOOLS = ['id90'];

  constructor(
    private location: PlatformLocation,
    private router: Router
  ) {
    // browser back button
    this.location.onPopState((e) => {
      const index = e.state ? this.navigationEndHistory.reduce(
        (carry, navigationEnd, navigationEndIndex) =>
          navigationEnd.id <= e.state.navigationId ? Math.max(navigationEndIndex, carry) : carry,
        -1
      ) : -1;
      if (index >= 0) {
        this.navigationEndHistory.splice(index);
      } else {
        this.navigationEndHistory = [];
      }
    });

    // track router navigation
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((e: NavigationEnd) => {
      this.navigationEndHistory.push(e);
      if (this.navigationEndHistory.length > this.navigationHistoryMaxLength) {
        this.navigationEndHistory.shift();
      }
    });
  }

  TIMINGFUNC_MAP = {
    linear: t => t,
    'ease-in': t => t * t,
    'ease-out': t => t * (2 - t),
    'ease-in-out': t => (t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t)
  };

  cancelScrollTop = false;

  scrollToElement($element: string, block: ScrollLogicalPosition = 'start') {
    const el = document.querySelector($element);
    if (el) {
      el.scrollIntoView({ block, behavior: 'smooth', inline: 'nearest' });
    }
  }

  scrollToHeader() {
    this.scrollTopSmooth(window.scrollY, 300, 'ease-in-out');
  }

  touchForms(forms) {
    let valid = true;
    forms.forEach((form: UntypedFormGroup) => {
      form.markAllAsTouched();

      if (!form.valid) {
        valid = false;
        Object.keys(form.controls).forEach((field) => {
          const control = form.get(field);
          control.markAsTouched({ onlySelf: true });
        });
      }
    });

    if (!valid) {
      this.scrollToElement('.ng-invalid', 'center');
    }
  }

  getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/android/i.test(userAgent)) {
      return 'Android';
    }
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return 'iOS';
    }
    return false;
  }

  static isInternetExplorer() {
    const userAgent = navigator.userAgent || navigator.vendor;
    return /msie\s|trident\//i.test(userAgent);
  }

  // This is a more browser-friendly way to handle scrolls. It is also more
  // efficient than a setTimeout method. We can't use scroll with an object
  // because some browsers do not support this yet.
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/scroll#Browser_Compatibility
  scrollTopSmooth(initY: number, duration = 300, timingName = 'linear') {
    const timingFunc = this.TIMINGFUNC_MAP[timingName];
    let start = null;
    const step = (timestamp: number) => {
      if (!this.cancelScrollTop) {
        start = start || timestamp;
        const progress = timestamp - start;
        const time = Math.min(1, ((timestamp - start) / duration));

        window.scrollTo(0, initY - (timingFunc(time) * initY));
        if (progress < duration) {
          window.requestAnimationFrame(step);
        }
      }
    };

    window.requestAnimationFrame(step);
  }

  transformToHours(time: string): string {
    const timeArr = time.split(':');

    if (timeArr.length < 2) {
      return time;
    }

    return `${timeArr[0]}h ${timeArr[1]}m`;
  }

  private navigationEndInUris(navigationEnd: NavigationEnd, uris: string[]) {
    return uris.some(uri => navigationEnd.url.startsWith(uri));
  }

  locationBack(defaultNavigationUrl = ['/'], defaultNavigationParams: any = {}, excludedUris: string[] = ['/login']) {
    let step = 1;
    // Last NavigationEnd is the current URL
    // If previous location is login then we just navigate to the default page
    for (
      let i = this.navigationEndHistory.length - 2;
      i >= 0 && this.navigationEndInUris(this.navigationEndHistory[i], excludedUris);
      i--
    ) {
      step++;
    }
    if (this.navigationEndHistory.length - 1 >= step) {
      // we remove one extra navigation because when we navigate back it will be added again to the array
      this.navigationEndHistory.splice(this.navigationEndHistory.length - (step + 1));
      window.history.go(-step);
    } else {
      this.router.navigate(defaultNavigationUrl, defaultNavigationParams);
    }
  }

  // Compare an Input year with a date value year
  compareTwoYears(date, actualYear) {
    const reservationYear = moment(date).year();
    return actualYear === reservationYear;
  }

  // Format a Date and display or not the year
  formatDateOnYear(value, verification) {
    return verification ? moment(value).format('ddd, MMM D') : moment(value).format('ll');
  }

  private isDev() {
    return (environment.env === this.DEV || environment.env === this.BETA);
  }

  isAirlineForceEmail(email: string) {
    if (!this.isDev()) return false;
    return email.includes(this.AIRLINE_TRUE);
  }

  hasBasicsTools(tools) {
    return this.BASIC_TOOLS.some(t => tools.includes(t));
  }

  isThirdPartError(error) {
    if (!error || !error.error) return false;
    const isThirdPart = error.url.includes('third_parties');
    return isThirdPart && error.error.error_code === 'InvalidLogin';
  }

  static isMobileDevice() {
    return window.screen.width <= 990;
  }
  static getStreamingStatus(isFeatureOn, isInitLoading, isStreamingCompleted) {
    const STREAMING_STATUS = {
      DONE: 'DONE',
      INPROGRESS: 'INPROGRESS',
      NONE: 'NONE'
    };

    if (!isFeatureOn) { return STREAMING_STATUS.NONE; }
    if (!isInitLoading && isStreamingCompleted) { return STREAMING_STATUS.INPROGRESS; }
    return STREAMING_STATUS.DONE;
  }

  hideEmailInformation(email: string) {
    const emailString = email.split('@')[0];
    const host = email.split('@')[1];
    return `${this.hideString(emailString)}@${this.hideString(host)}`;
  }

  hideString(word: string) {
    const repeatedWord = '*'.repeat(word.length - 3);
    return word[0] + word[1] + word[2] + repeatedWord + word.slice(-1);
  }

  utf8ToBase64(str: string): string {
    const utf8Bytes = new TextEncoder().encode(str);
    let binaryString = "";
    for (let i = 0; i < utf8Bytes.byteLength; i++) {
      binaryString += String.fromCharCode(utf8Bytes[i]);
    }
    return btoa(binaryString);
  }

  static isFullRefund(estimateRefund, total) {
    return Math.abs(estimateRefund - total) < 0.01;
  }
  static isPaylater(hotel) {
    return hotel.payment_option === 'HotelCollect';
  }

  static getBlockDomains(airlines, userAirlineCode): any[] {
    return userAirlineCode
      ? this.getBlockedDomainsByAirline(airlines, userAirlineCode)
      : this.getAllBlockedDomains(airlines);
  }

  static getBlockedDomainsByAirline(airlines, userAirlineCode): string[] {
    if (!airlines || !userAirlineCode) { return [] }
    const userAirlineInfo = airlines.find((airline) => airline.code.toUpperCase() === userAirlineCode.toUpperCase());
    return userAirlineInfo.blocked_domains;
  }

  static getAllBlockedDomains(airlines: any[]): string[] {
    const airlinesWithBlocked = airlines.filter(airline => airline.blocked_domains && airline.blocked_domains.length);
    const aux = airlinesWithBlocked.map(airline => airline.blocked_domains);
    return [].concat.apply([], aux);
  }
}
