import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Subject } from 'rxjs';
declare const gapi;

@Injectable({
  providedIn: 'root'
})
export class GmailContactsService {
  client_id = environment.googleContactsClientID;
  scope = 'https://www.googleapis.com/auth/contacts.readonly'
  apiKey = environment.googleContactsApikey;
  client: any;
  contacts = new Subject<[]>();

  constructor(public http: HttpClient) { }

  googleContacts() {
    this.client = window.google?.accounts.oauth2.initTokenClient({
      client_id: this.client_id,
      scope: this.scope,
      callback: (tokenResponse) => {
        this.handleAuthorization(tokenResponse);
      },
    });
    this.client?.requestAccessToken();
    return this.contacts.asObservable();
  }

  handleAuthorization = (tokenResponse) => {
    const headers = new HttpHeaders({ 'No-Auth': 'true', 'no-version': 'true' });
    const path = 'https://people.googleapis.com/v1/people/me/connections?personFields=names,emailAddresses&pageSize=500';
    const url = `${path}&access_token=${tokenResponse.access_token}`;

    return this.http.get<any>(url, { headers }).subscribe(
      (response) => {
        if (response.connections && response.connections.length > 0) {
          this.contacts.next(response.connections);
        } else {
          this.contacts.next([]);
        }
      }
    );
  }

  emailToName(email) {
    let fullName = email;
    fullName = email.substring(0, email.indexOf('@'));
    const formatName = fullName.match(/([a-z|A-Z]+)[\.|_]([a-z|A-Z]+)/);
    if (formatName && formatName.length > 1) {
      fullName = `${formatName[1]} ${formatName[2]}`;
    }
    return fullName;
  }
}
