import { Action } from '@ngrx/store';

export enum MailchimpParametersActionTypes {
  Load = '[Mailchimp Parameters] Load',
  Clear = '[Mailchimp Parameters] Clear'
}

export class Load implements Action {
  readonly type = MailchimpParametersActionTypes.Load;

  constructor(public payload: any) {}
}

export class Clear implements Action {
  readonly type = MailchimpParametersActionTypes.Clear;

  constructor() {}
}

export type MailchimpParametersActionsUnion = Load
  | Clear;
