<div class="card push-up shadow-lg">
  <div class="top-section p-3">
    <div class="row">
      <div class="col-12 py-3 text-center">
        <h4>Access Our Exclusive Rates</h4>
        <p>{{ 'lodging.hotels.view_rates_login_create_account' | translate }}</p>
        <button class="btn btn-primary w-100 fw-normal mb-3" type="button"
          (click)="loginRedirect()">
          {{ 'action_terms.login' | translate }}
        </button>
        <button class="btn btn-outline-primary w-100 fw-normal mb-3" type="button" (click)="createAccountRedirect()">
          {{ 'action_terms.create_account' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
