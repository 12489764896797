<div class="row close-container">
    <div (click)="closeModal()" class="close-icon">
        <fa-icon [icon]="icons.faXmark" aria-hidden="true"></fa-icon>
    </div>
</div>
<div class="container">
    <div class="row">
        <div class="all-inclusive-container me-4">
            <fa-icon [icon]="icons.faMagnifyingGlass" aria-hidden="true"></fa-icon>
            <input type="text"
                class="all-inclusive-destination"
                placeholder="{{'lodging.hotels.all_inclusive_search_placeholder' | translate}}"
                (input)="destinationChanged($event.target.value)"/>
        </div>
    </div>

    <div class="row">
        <h3 class="primary-content my-4">{{'lodging.hotels.all_inclusive_search_title' | translate}}</h3>
    </div>

    <div class="row mt-0">
        <div class="control-container">
            <div (click)="previousPage()">
                <fa-icon [icon]="icons.faCaretLeft" aria-hidden="true" class="caret"></fa-icon>
            </div>
        </div>
        <div class="col">
            <div class="row">
                <div class="col">
                    <loading-indicator [fullScreen]="false" *ngIf="loading"></loading-indicator>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 col-md-12 mb-3 mt-0" *ngFor="let item of source[currentIndexPage]" (click)="destinationSelected(item)">
                    <div class="image-container">
                        <div class="image-destination">
                            <img style="height: 135px;" [src]="item.destination_image_home ? item.destination_image_home : defaultImage" />
                        </div>
                        <div class="title-destination">
                            <p class="m-1">{{item.destination_name ? item.destination_name: item.name}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="control-container">
            <div (click)="nextPage()">
                <fa-icon [icon]="icons.faCaretRight" aria-hidden="true" class="caret"></fa-icon>
            </div>
        </div>
    </div>

    <div class="row page-selector-container">
        <div *ngFor="let item of source;let i = index" (click)="updatePageSelected(i)" class="page-icon-container">
            <fa-icon aria-hidden="true"
                class="index"
                [icon]="icons.faCircle"
                [ngClass]="{'index-selected': i === currentIndexPage}">
            </fa-icon>
        </div>
    </div>
</div>
