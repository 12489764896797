import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { AuthService } from 'app/shared/services/auth/auth.service';
import { select, Store } from '@ngrx/store';
import * as fromShared from 'app/shared/store/reducers';
import * as fromRoot from 'reducers';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MemberService {
  private airlines$ = this.store.pipe(select(fromShared.getAirlines));

  constructor(private http: HttpClient,
              private store: Store<fromRoot.State>) {}

  getMemberPromotions(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/members/promotions.json`, {});
  }

  getMemberStats(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/user_statistics.json`);
  }
  getMemberSavings(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/members/savings.json`);
  }

  getMemberLoyalty(params): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/member_loyalty/index`, { params : { userCurrency:params.currency } });
  }

  getMemberType(user, airlines) {
    let type = 'Non-Partner';
   if (airlines?.length) {
    type = airlines.find(airline => airline.code === user.member.airline_code.toLowerCase()).partner ? 'Partner' : 'Non-Partner';
    if (user.member.airline_code === AuthService.INVITED_MEMBERS_AIRLINE_CODE) {
      if (user?.tools?.includes('ticketing_invited')) {
        type = 'ET';
      }
      else {
        type = 'IAF';
      }
    }
    if (user?.cobus_authorization) {
      type = 'Cobus';
    }
    if (user?.call_center_admin) {
      type = 'Call-Center';
    }
  }
    return type;
  }
}
