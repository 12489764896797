import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicScriptLoaderService } from 'app/shared/services/dynamic-script-load/dynamic-script-load.service';
declare var window;

@Injectable({
  providedIn: 'root'
})
export class BreamerHelper {

  constructor(
    private router: Router,
    private scriptLoaderService: DynamicScriptLoaderService
  ) { }

  load(user) {
    window.beamer_config.product_id = 'SZNCTSJp6544'; // DO NOT CHANGE : This is your product code on Beamer
    /* Optional : Id, class (or list of both) of the HTML element to use as a button */
    window.beamer_config.selector = '.beamerClass';
    window.beamer_config.right = 10;
    window.beamer_config.top = 10;
    window.beamer_config.user_email = user.member.email; /* Optional : Input your user email for better statistics */
    window.beamer_config.filter = user.member.airline_code;
    window.beamer_config.user_id = user.member.member_id;
    window.beamer_config.callback = () => {
      setTimeout(() => this.addCssClass(true), 2500);
    };
    this.scriptLoaderService.load('beamer').catch(error => console.log(error, 'error'));
  }

  destroy() {
    this.removeCssClass();
    window?.Beamer?.destroy();
  }

  addCssClass(showCloseButton = false) {
    const isBeamerDisplayed = document.getElementsByClassName('beamerAnnouncementBarTopActive');
    if (isBeamerDisplayed?.length > 0) {
      if (showCloseButton) {
        const buttonCloseBeamerBar = document.getElementsByClassName('beamerAnnouncementBarClose');
        buttonCloseBeamerBar[0].addEventListener('click', this.removeCssClass);
      }
      const beamerDiv = document.getElementById('beamerAnnouncementBar');
      beamerDiv.addEventListener('click', this.removeCssClass);
      const nav = document.getElementById('mainNavBar');
      if (this.router.url.includes('/hotels/search')
        || this.router.url.includes('/hotels/checkout/')
        || this.router.url.includes('cars/search')
        || (this.router.url.includes('flights/ticketing/searches') &&
          this.router.url.includes('new'))) {
        nav && nav.classList.add('navBarWithBeamerWith0');
        return;
      }
      if (nav && nav.classList.contains('navBarWithBeamerWith0')) {
        nav.classList.remove('navBarWithBeamerWith0');
      }
      if (nav && !nav.classList.contains('navBarWithBeamer')) {
        nav.classList.add('navBarWithBeamer');
      }
    }
  }

  removeCssClass() {
    const hotelDetailsHeader = <HTMLElement>document.getElementsByClassName('hotel-details-header')[0];
    const nav = document.getElementById('mainNavBar');
    document.getElementsByClassName('animated')[0].classList.remove('beamerAnnouncementBarTopActive');
    document.getElementsByTagName('html')[0].classList.remove('beamerAnnouncementBarTopActive');

  
    if (nav) {
      nav.classList.remove('navBarWithBeamerWith0');
      nav.classList.remove('navBarWithBeamer');
    }
    if (hotelDetailsHeader) {
      hotelDetailsHeader.classList.remove('navBarWithBeamer');
      hotelDetailsHeader.classList.remove('navBarWithBeamerWith0');
    }
  }

  toggleTopBar(action: 'hide' | 'show') {
    const beamerTopBar = document.getElementById('beamerAnnouncementBar');
    if (!beamerTopBar) { return null; }

    if (action === 'hide') {
      this.removeCssClass();
      beamerTopBar.hidden = true;
      return;
    };
    if (action === 'show') {
      beamerTopBar.hidden = false;
      document.getElementsByClassName('animated')[0].classList.add('beamerAnnouncementBarTopActive');
      document.getElementsByTagName('html')[0].classList.add('beamerAnnouncementBarTopActive');
      this.addCssClass();
      return;
    }
  

  }  
}
