import { HotelWatchlistActions, WatchlistApiActions } from '../actions';

export interface State {
  hotelWatchlists: any[] | null;
  pending: any[];
  error: any | null;
}

export const initialState: State = {
  hotelWatchlists: [],
  pending: [],
  error: null
};

export function reducer(
  state = initialState,
  action: WatchlistApiActions.WatchlistApiActionsUnion | HotelWatchlistActions.HotelWatchlistActionsUnion
) {
  switch (action.type) {
    case WatchlistApiActions.WatchlistApiActionTypes.GetHotelWatchlistSuccess:
      return {
        hotelWatchlists: action.payload,
        pending: [],
        error: null
      };
    case WatchlistApiActions.WatchlistApiActionTypes.GetHotelWatchlistFailure:
      return {
        hotelWatchlists: state.hotelWatchlists,
        pending: [],
        error: action.payload.error
      };
    case HotelWatchlistActions.HotelWatchlistActionTypes.Get:
      return {
        ...state,
        data: {},
      };

    case WatchlistApiActions.WatchlistApiActionTypes.AddHotelWatchlistSuccess:
      if (!state.hotelWatchlists.find(watcher => watcher.hotel_id === action.payload.watchlist.hotel_id)) {
        return {
          hotelWatchlists: [
            ...state.hotelWatchlists,
            {
              hotel_id: action.payload.watchlist.hotel_id,
              watchlistId: action.payload.watchlist.id,
              id90: action.payload.watchlist.id90,
            }
          ],
          pending: state.pending.filter(watchlist => watchlist.hotel_id !== action.payload.watchlist.hotel_id),
          error: null
        };
      }

      return {
        hotelWatchlists: state.hotelWatchlists,
        pending: [],
        error: null
      };
    case WatchlistApiActions.WatchlistApiActionTypes.AddHotelWatchlistFailure:
      return {
        hotelWatchlists: state.hotelWatchlists,
        pending: [],
        error: action.payload.error
      };
    case HotelWatchlistActions.HotelWatchlistActionTypes.Add:
      return {
        hotelWatchlists: state.hotelWatchlists,
        pending: [...state.pending, action.payload.watchlist],
        error: null
      };
    case HotelWatchlistActions.HotelWatchlistActionTypes.Remove:
      return {
        hotelWatchlists: state.hotelWatchlists,
        pending: [...state.pending, action.payload.watchlist],
        error: null
      };
    case WatchlistApiActions.WatchlistApiActionTypes.RemoveHotelWatchlistSuccess:
      return {
        hotelWatchlists: state.hotelWatchlists.filter(watchlist => watchlist.hotel_id !== action.payload.watchlist.hotel_id),
        pending: [],
        error: null
      };
    case WatchlistApiActions.WatchlistApiActionTypes.RemoveHotelWatchlistFailure:
      return {
        hotelWatchlists: state.hotelWatchlists,
        pending: [],
        error: action.payload.error
      };
    default:
      return state;
  }
}

export const getHotelWatchlists = (state: State) => state.hotelWatchlists;
export const getWatchlistPending = (state: State) => state.pending;
export const getWatchlistError = (state: State) => state.error;
