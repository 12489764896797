<form (submit)="select()" action="javascript:void(0)">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Select an Employee</h4>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label for="employeeNumber">Please select an employee number for {{ user?.member?.airline_code }}</label>
      <div class="input-group">
        <input required [disabled]="loading" id="employeeNumber" class="form-control" name="dp" [(ngModel)]="employee" (keyup)="clearErrors()"  autocomplete="off" >
      </div>
      <div *ngIf='error' class="alert alert-danger" role="alert"> {{ error }} </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" [disabled]="loading" class="btn btn-outline-light text-dark border float-end" (click)="select()" data-cy="selectUserButton">
      Select <fa-icon *ngIf="loading" [icon]="icons.faSpinner" class="rotation-animation fa-spin"></fa-icon>
    </button>
  </div>
</form>
