import { Component, Input } from '@angular/core';
import { UsersWithOutImages } from 'app/shared/components/avatar-circle/avatar-circle.component';

export interface EligibleTravelerInfoSummary {
  employeeFirstName: string;
  employeeLastName: string;
  employeeFullName?: string;
  employeeAirlineName?: string;
  employeeAvatar?: UsersWithOutImages;
}

@Component({
  selector: 'eligible-traveler-summary',
  templateUrl: './eligible-traveler-summary.html',
  styleUrls: ['./eligible-traveler-summary.scss']
})

export class EligibleTravelerSummaryComponent {
  @Input() eligibleInfo: EligibleTravelerInfoSummary;
  constructor() {}
}
