 <div class="d-flex" [ngClass]="{'flex-column': design === 'column', 'in-modal': design === 'in-modal', 'align-items-center': align === 'center', 'align-items-end': align === 'right'}">
  <div>
    <input
      (click)="switch()"
      class="tgl tgl-light"
      [ngClass]="{'checked' : value, 'size-sm':  size === 'small' }"
      [id]="id"
      type="checkbox"
      [value]="value"
      [disabled]="disabled"
      [attr.checked]="value"
      [attr.aria-label]="label" />
    <label class="tgl-btn mb-0" [for]="id" [ngClass]="{'disabled' : disabled }"></label>
  </div>
  <div class="label" [ngClass]="{'disabled label-disabled' : disabled }">
    {{ label }}
  </div>
 </div>
