import { LanguageActions } from '../actions';

export interface State {
  error: string;
  pending: boolean;
  languages: any[];
}

export const initialState: any = {
  error: null,
  pending: false,
  languages: []
};

export function reducer(
  state = initialState,
  action:
    | LanguageActions.LanguageActionsUnion
) {
  switch (action.type) {
    case LanguageActions.LanguageActionTypes.LoadLanguages:
      return {
        ...state,
        pending: true
      };
    case LanguageActions.LanguageActionTypes.LoadLanguagesSuccess:
      return {
        ...state,
        pending: false,
        languages: action.payload.languages,
        error: initialState.error
      };
    case LanguageActions.LanguageActionTypes.LoadLanguagesFailure:
      return {
        ...state,
        pending: false,
        error: action.payload.error
      };
    default: {
      return state;
    }
  }
}

export const getError = (state: any) => state.error;
export const getPending = (state: any) => state.pending;
export const getLanguages = (state: any) => state.languages;
