import { Injectable } from '@angular/core';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  constructor (
    private remoteConfig: AngularFireRemoteConfig
  ) {}

  fetchAndActivate(throwError: boolean = false) {
    if (!this.remoteConfig) { return; }
    return new Promise((resolve, reject) => {
      this.remoteConfig.fetchAndActivate()
        .then(() => {
          this.remoteConfig.getAll().then((all: any) => {
            let localRemote = {};
            for (const value in all) {
              localRemote = {
                ...localRemote,
                [value]: this.parseValue(all[value]._value)
              };
            }
            localStorage.setItem('remote_fetch', JSON.stringify(localRemote));
            resolve({ success: true });
          });
        })
        .catch((err) => {
          console.error(err);
          reject({ success: true });
          if (throwError) {
            throw new Error(err);
          }
        });
    });
  }

  parseValue(value) {
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  }
}
