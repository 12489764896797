import { PLATFORM_ID, Inject, Injectable } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '@env/environment.staging';

export const scriptStore = [
  { name: 'apisGoogleGSIClient', src: 'https://accounts.google.com/gsi/client', async: true, defer: true },
  { name: 'mapkit', src: 'https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js' },
  { name: 'beamer', src: 'https://app.getbeamer.com/js/beamer-embed.js' },
  { name: 'facebook', src: 'https://connect.facebook.net/en_US/sdk.js', async: true, defer: true, crossorigin:'anonymous' },
  { name: 'leaflet', src: 'https://unpkg.com/leaflet@1.9.4/dist/leaflet.js' },
  { name: 'leaflet-markercluster', src: 'https://unpkg.com/leaflet.markercluster@1.5.3/dist/leaflet.markercluster-src.js' },
  { name: 'leaflet-mapkitmutant', src: 'https://unpkg.com/leaflet.mapkitmutant@0.3.2/Leaflet.MapkitMutant.js' },
  { name: 'hbspt', src: '//js.hsforms.net/forms/embed/v2.js'},
  { name: 'storyblok', src: `https://app.storyblok.com/f/storyblok-v2-latest.js?t=${environment.storyblok.apiKey}`, async: true},
  { name: 'trustArc', src: '//consent.trustarc.com/notice?domain=id90travel.com&c=teconsent&js=nj&noticeType=bb&gtm=1', async: true}
];

declare var document: any;

@Injectable({
  providedIn: 'root'
})
export class DynamicScriptLoaderService {

  private scripts: any = {};

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    scriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src,
        async: script.async,
        defer: script.defer,
        crossorigin: script.crossorigin
      };
    });
  }

  load(...scripts: string[]): Promise<any[]> {
    const promises: any[] = [];
    scripts.forEach(script => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string): Promise<any> {
    if (!isPlatformBrowser(this.platformId)) {
      return Promise.reject(new Error(`Will not load ${name} server side`));
    }

    return new Promise((resolve, reject) => {
      if (!this.scripts[name].loaded || name === 'apisGoogleGSIClient') {
        const script = document.createElement('script');
        if (this.scripts[name].async) {
          script.async = true;
        }
        if (this.scripts[name].defer) {
          script.defer = true;
        }
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        if (script.readyState) { // IE
          script.onreadystatechange = () => {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({ script: name, loaded: true, status: 'Loaded' });
            }
          };
        } else {
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({ script: name, loaded: true, status: 'Loaded' });
          };
        }
        script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
        document.getElementsByTagName('head')[0].appendChild(script);
      } else {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      }
    });
  }
}
