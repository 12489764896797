<ngb-alert *ngIf="success" type="success" (closed)="closeAlert()">{{ success | translate }}</ngb-alert>
<ngb-alert *ngIf="error" type="danger" (closed)="closeAlert()">{{ error }}</ngb-alert>
<div class="d-sm-none back-button">
  <button type="button" class="btn btn-link" (click)="showTabs.emit()"><fa-icon [icon]="icons.faChevronLeft" aria-hidden="true"></fa-icon> {{'back' | translate}} </button>
</div>
<div class="profile-title primary-content">
  {{ "communication_preferences.title" | translate }}
</div>
<div class="card shadow-none mb-4">
  <div class="card-body communication_preferences">
    <div class="container" [formGroup]="mailchimpPreferencesForm">
      <div class="row mb-2">
        <div class="col">
          <div class="primary-content">{{ "communication_preferences.email_subscriptions" | translate }}</div>
          <div class="tertiary-content">{{ "communication_preferences.paragraph" | translate }}</div>
          <div class="tertiary-content">{{ "communication_preferences.paragraph_2" | translate }}</div>
        </div>
      </div>
      <ng-container *ngIf="!pending">
        <div class="mt-3" *ngFor="let allInterest of allInterests">
          <div class="d-flex bd-highlight mb-2">
            <div class="bd-highlight primary-content me-2">{{ allInterest.title }}</div>
            <div class="flex-grow-1 bd-highlight section-title-divider d-none d-md-block"></div>
          </div>
          <div class="row mt-2" *ngFor="let interest of allInterest.interests">
            <ng-container *ngIf="allInterest.type === 'checkboxes'">
              <div class="col">
                <div class="secondary-content text-capitalize"> {{ interest.name }} </div>
              </div>
              <div class="col text-end">
                <toggle [design]="'column'" [align]="'right'" [formControl]="mailchimpPreferencesForm.controls[interest.name]" id="{{ interest.id }}" (toggleChanged)="setStatus($event, interest.id, 'toggle')"></toggle>
              </div>
            </ng-container>
            <ng-container *ngIf="allInterest.type === 'radio'">
              <div class="col">
                <div class="secondary-content"> {{ interest.name }} </div>
              </div>
              <div class="col text-end">
                <input
                class="form-check-input"
                type="radio"
                id="{{ interest.id }}"
                name="radioButton"
                [checked]="mailchimpPreferencesForm.controls[interest.name].value"
                (click)="setStatus($event, interest.id, 'radio')">
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
