import { Action } from '@ngrx/store';
import { CurrentLocation } from 'app/shared/models/current-location';

export enum CurrentLocationActionTypes {
  Load        = '[Current Location] Load',
  LoadSuccess = '[Current Location] Load Success',
  LoadFailure = '[Current Location] Load Failure'
}

export class Load implements Action {
  readonly type = CurrentLocationActionTypes.Load;
}

export class LoadSuccess implements Action {
  readonly type = CurrentLocationActionTypes.LoadSuccess;

  constructor(public payload: { currentLocation: CurrentLocation }) {}
}

export class LoadFailure implements Action {
  readonly type = CurrentLocationActionTypes.LoadFailure;

  constructor(public payload: { error: any }) {}
}

export type CurrentLocationActionsUnion = Load
  | LoadSuccess
  | LoadFailure;
