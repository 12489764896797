<just-for-you-card
  [image]="image"
  [dates]="dates"
  [title]="title"
  [destination]="city"
  [type]="type"
  [defaultImage]="defaultImage"
  [isNeedARoom]="true"
  (click)="onClicked()"
></just-for-you-card>
