<div [class]="mode" [ngClass]="{'main-container': displayComponent, 'vh-100': forceHeigth}" data-cy="loaderIndicator">
	<ng-container 
		[ngTemplateOutlet]="spinner"
		[ngTemplateOutletContext]="{ forceCenter: displayComponent }">
	</ng-container>
	<div class="component-container" [ngClass]="{'w-100': isInternetExplorer}">
		<ng-content *ngIf="displayComponent"></ng-content>
	</div>
</div>

<ng-template #spinner let-forceCenter="forceCenter">
	<div [ngClass]="{'loading-indicator': !forceCenter, 'loading-indicator-with-component': forceCenter, 'mt-3': (isInternetExplorer && displayComponent) }"></div>
	<div *ngIf="message && fullScreen && !multiLineMessage" [ngClass]="{'loading-message': !forceCenter, 'loading-message-with-component': forceCenter}">
		{{ message }}
	</div>
	<div *ngIf="message && fullScreen && multiLineMessage" [ngClass]="{'loading-message': !forceCenter, 'loading-message-with-component': forceCenter}">
		<p [ngStyle]="{'font-size': messageOptions.fontSize, 'font-weight': messageOptions.fontWeight, 'color': messageOptions.fontColor}" *ngFor="let line of message;" class="m-0 p-0">
			{{line}}
		</p>
	</div>
</ng-template>

