import { ActionReducerMap, createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromModals from './modal.reducer';
import * as fromAirlines from './airlines.reducer';
import * as fromAllInclusive from './all-inclusive.reducer';
import * as fromCountry from './country.reducer';
import * as fromHotelsRecentSearches from './hotels-recent-searches.reducer';
import * as fromHotelsRecentViews from './hotels-recent-views.reducer';
import * as fromCarsRecentSearches from './cars-recent-searches.reducer';
import * as fromRecommendedDestinations from './recommended-destinations.reducer';
import * as fromAirportsRecentDeparturesSearches from './airports-recent-departures-searches.reducer'
import * as fromAirportsRecentArrivalsSearches from './airports-recent-arrivals-searches.reducer'
import * as fromTrendingDestinations from './trending-destinations.reducer';
import * as fromCurrentLocation from './current-location.reducer';
import * as fromAbandonedHotel from './abandoned-hotel.reducer';
import * as fromUTMParameters from './utm-parameters.reducer';
import * as fromRoot from 'reducers';
import * as fromFlightWatchlist from './flight-watchlist.reducer';
import * as fromAirports from './airports.reducer';
import * as fromMailchimpParameters from './mailchimp-parameters.reducer';
import * as fromLanguages from './language.reducer';
import * as fromFlags from './flags.reducer';
import * as fromUnlinkEmail from './unlink-email.reducer';
import * as fromGamification from './gamification.reducer';
import * as fromDemandCalendar from './demand-calendar.reducer';
import * as fromWalkthroughs from './walkthroughs.reducer';

export interface SharedState {
  abandonedHotel: fromAbandonedHotel.State;
  allInclusive: fromAllInclusive.State;
  airports: fromAirports.AirportsState;
  airportRecentDepartureSearches: fromAirportsRecentDeparturesSearches.State;
  airportRecentArrivalSearches: fromAirportsRecentArrivalsSearches.State;
  airlines: any;
  carsRecentSearches: fromCarsRecentSearches.State;
  countries: fromCountry.State;
  currentLocation: fromCurrentLocation.State;
  hotelsRecentSearches: fromHotelsRecentSearches.State;
  hotelsRecentViews: fromHotelsRecentViews.State;
  languages: fromLanguages.State;
  mailchimpParameters: fromMailchimpParameters.State;
  modals: fromModals.ModalState;
  recommendedDestinations: fromRecommendedDestinations.State;
  trendingDestinations: fromTrendingDestinations.State;
  utmParameters: fromUTMParameters.State;
  watchlist: fromFlightWatchlist.State;
  flags: fromFlags.State;
  unlinkEmail: fromUnlinkEmail.State;
  gamification: fromGamification.State;
  demandCalendar: fromDemandCalendar.State;
  walkthroughs: fromWalkthroughs.State
}

export interface State extends fromRoot.State {
  shared: SharedState;
}

export const reducers: ActionReducerMap<SharedState> = {
  abandonedHotel: fromAbandonedHotel.reducer,
  modals: fromModals.reducer,
  hotelsRecentSearches: fromHotelsRecentSearches.reducer,
  carsRecentSearches: fromCarsRecentSearches.reducer,
  airportRecentDepartureSearches: fromAirportsRecentDeparturesSearches.reducer,
  airportRecentArrivalSearches: fromAirportsRecentArrivalsSearches.reducer,
  countries:fromCountry.reducer,
  trendingDestinations: fromTrendingDestinations.reducer,
  airlines: fromAirlines.reducer,
  allInclusive: fromAllInclusive.reducer,
  currentLocation: fromCurrentLocation.reducer,
  hotelsRecentViews: fromHotelsRecentViews.reducer,
  mailchimpParameters: fromMailchimpParameters.reducer,
  recommendedDestinations: fromRecommendedDestinations.reducer,
  languages: fromLanguages.reducer,
  utmParameters: fromUTMParameters.reducer,
  watchlist: fromFlightWatchlist.reducer,
  airports: fromAirports.reducer,
  flags: fromFlags.reducer,
  unlinkEmail: fromUnlinkEmail.reducer,
  gamification: fromGamification.reducer,
  demandCalendar: fromDemandCalendar.reducer,
  walkthroughs: fromWalkthroughs.reducer,
};

export const selectSharedState                           = createFeatureSelector<SharedState>('shared');
export const selectModalState                            = createSelector(selectSharedState, (state: SharedState) => state.modals);
export const selectAirlinesState                         = createSelector(selectSharedState, (state: SharedState) => state.airlines);
export const selectAllInclusiveState                     = createSelector(selectSharedState, (state: SharedState) => state.allInclusive);
export const selectCountriesState                        = createSelector(selectSharedState, (state: SharedState) => state.countries);
export const selectHotelsRecentSearchesState             = createSelector(selectSharedState, (state: SharedState) => state.hotelsRecentSearches);
export const selectHotelsRecentViewsState                = createSelector(selectSharedState, (state: SharedState) => state.hotelsRecentViews);
export const selectRecommendedDestinationsState          = createSelector(selectSharedState, (state: SharedState) => state.recommendedDestinations);
export const selectCarsRecentSearchesState               = createSelector(selectSharedState, (state: SharedState) => state.carsRecentSearches);
export const selectTrendingDestinationsState             = createSelector(selectSharedState, (state: SharedState) => state.trendingDestinations);
export const selectCurrentLocationState                  = createSelector(selectSharedState, (state: SharedState) => state.currentLocation);
export const selectAbandonedHotelState                   = createSelector(selectSharedState, (state: SharedState) => state.abandonedHotel);
export const selectUTMState                              = createSelector(selectSharedState, (state: SharedState) => state.utmParameters);
export const selectAirportsRecentDeparturesSearchesState = createSelector(selectSharedState, state => state.airportRecentDepartureSearches);
export const selectAirportsRecentArrivalsSearchesState   = createSelector(selectSharedState, state => state.airportRecentArrivalSearches);
export const selectFlightsWatchlistState                 = createSelector(selectSharedState, (state: SharedState) => state.watchlist);
export const selectAirportsState                         = createSelector(selectSharedState, (state: SharedState) => state.airports);
export const mailchimpParametersState                    = createSelector(selectSharedState, (state: SharedState) => state.mailchimpParameters);
export const selectLanguageState                         = createSelector(selectSharedState, (state: SharedState) => state.languages);

export const getData      = createSelector(selectModalState, fromModals.getModalData);
export const getAirlines  = createSelector(selectAirlinesState, state => state.airlinesData);
export const getAirlinesPricingInCRS  = createSelector(selectAirlinesState, state => state.airlinesPricingInCRS);

export const getHotelsRecentSearches        = createSelector(selectHotelsRecentSearchesState, fromHotelsRecentSearches.getDestinations);
export const getHotelsRecentSearchesPending = createSelector(selectHotelsRecentSearchesState, fromHotelsRecentSearches.getPending);
export const getHotelsRecentSearchesLoaded  = createSelector(selectHotelsRecentSearchesState, fromHotelsRecentSearches.getLoaded);

export const getHotelsRecentViews         = createSelector(selectHotelsRecentViewsState, fromHotelsRecentViews.getDestinations);
export const getHotelsRecentViewsPending  = createSelector(selectHotelsRecentViewsState, fromHotelsRecentViews.getPending);
export const getHotelsRecentViewsLoaded   = createSelector(selectHotelsRecentViewsState, fromHotelsRecentViews.getLoaded);

export const getRecommendedDestinations         = createSelector(selectRecommendedDestinationsState, fromRecommendedDestinations.getDestinations);
export const getRecommendedDestinationsPending  = createSelector(selectRecommendedDestinationsState, fromRecommendedDestinations.getPending);
export const getRecommendedDestinationsLoaded   = createSelector(selectRecommendedDestinationsState, fromRecommendedDestinations.getLoaded);

export const getAllInclusiveDestinations         = createSelector(selectAllInclusiveState, fromAllInclusive.getDestinations);
export const getAllInclusiveDestinationsPending  = createSelector(selectAllInclusiveState, fromAllInclusive.getPending);
export const getAllInclusiveDestinationsLoaded   = createSelector(selectAllInclusiveState, fromAllInclusive.getLoaded);

export const getCarsRecentSearches        = createSelector(selectCarsRecentSearchesState, fromCarsRecentSearches.getDestinations);
export const getCarsRecentSearchesPending = createSelector(selectCarsRecentSearchesState, fromCarsRecentSearches.getPending);
export const getCarsRecentSearchesLoaded  = createSelector(selectCarsRecentSearchesState, fromCarsRecentSearches.getLoaded);

export const getCountries        = createSelector(selectCountriesState, fromCountry.getCountries);
export const getCountriesPending = createSelector(selectCountriesState, fromCountry.getPending);
export const getCountriesLoaded  = createSelector(selectCountriesState, fromCountry.getLoaded);
export const getCountriesError  = createSelector(selectCountriesState, fromCountry.getError);

export const getAirportsRecentDeparturesSearches        = createSelector(selectAirportsRecentDeparturesSearchesState, fromAirportsRecentDeparturesSearches.getDestinations);
export const getAirportsRecentDeparturesSearchesPending = createSelector(selectAirportsRecentDeparturesSearchesState, fromAirportsRecentDeparturesSearches.getPending);
export const getAirportsRecentDeparturesSearchesLoaded  = createSelector(selectAirportsRecentDeparturesSearchesState, fromAirportsRecentDeparturesSearches.getLoaded);

export const getAirportsRecentArrivalsSearches        = createSelector(selectAirportsRecentArrivalsSearchesState, fromAirportsRecentArrivalsSearches.getDestinations);
export const getAirportsRecentArrivalsSearchesPending = createSelector(selectAirportsRecentArrivalsSearchesState, fromAirportsRecentArrivalsSearches.getPending);
export const getAirportsRecentArrivalsSearchesLoaded  = createSelector(selectAirportsRecentArrivalsSearchesState, fromAirportsRecentArrivalsSearches.getLoaded);

export const getTrendingDestinations        = createSelector(selectTrendingDestinationsState, fromTrendingDestinations.getDestinations);
export const getTrendingDestinationsPending = createSelector(selectTrendingDestinationsState, fromTrendingDestinations.getPending);
export const getTrendingDestinationsLoaded  = createSelector(selectTrendingDestinationsState, fromTrendingDestinations.getLoaded);

export const getCurrentLocation = createSelector(selectCurrentLocationState, fromCurrentLocation.getCurrentLocation);
export const getCurrentLocationError = createSelector(selectCurrentLocationState, fromCurrentLocation.getError);

export const getAbandonedHotel        = createSelector(selectAbandonedHotelState, fromAbandonedHotel.getAbandonedHotel);
export const getAbandonedHotelPending = createSelector(selectAbandonedHotelState, fromAbandonedHotel.getPending);
export const getAbandonedHotelLoaded  = createSelector(selectAbandonedHotelState, fromAbandonedHotel.getLoaded);

export const getUTMParameters = createSelector(selectUTMState, fromUTMParameters.getUtmParameters);

export const getFlightWatchlist = createSelector(selectFlightsWatchlistState, fromFlightWatchlist.getFlightWatchlists);
export const getFlightWatchlistLoaded = createSelector(selectFlightsWatchlistState, fromFlightWatchlist.getWatchlistLoaded);
export const getFlightWatchlistPending = createSelector(selectFlightsWatchlistState, fromFlightWatchlist.getWatchlistPending);
export const getFlightWatchlistError = createSelector(selectFlightsWatchlistState, fromFlightWatchlist.getWatchlistError);

export const getAirports = createSelector(selectAirportsState, fromAirports.getAirports);
export const getAirportsPending = createSelector(selectAirportsState, fromAirports.getPending);
export const getAirportByCode = (airportCode: string) => createSelector(getAirports, (airports) => airports[airportCode]);

export const getMailchimpCampaign = createSelector(mailchimpParametersState, fromMailchimpParameters.getMailchimpCampaign);

export const getLanguages = createSelector(selectLanguageState, fromLanguages.getLanguages);

export const getConfigDate = createSelector(selectSharedState, (state: SharedState) => state.flags.configDate);
export const loadingConfigFlag = createSelector(selectSharedState, (state: SharedState) => state.flags.loadingConfig);
export const getConfigFlag = createSelector(selectSharedState, (state: SharedState) => state.flags.config);

export const getFeatureFlags = createSelector(selectSharedState, (state: SharedState) => state.flags.feature);
export const getFeatureFlagsLoading = createSelector(selectSharedState, (state: SharedState) => state.flags.loadingFeature);
export const getAirlineFlags = createSelector(selectSharedState, (state: SharedState) => state.flags.airlineFlags);

export const getUnlinkEmail = createSelector(selectSharedState, (state: SharedState) => state.unlinkEmail.success );
export const getUnlinkEmailError = createSelector(selectSharedState, (state: SharedState) => state.unlinkEmail.error );
export const getUnlinkEmailPending = createSelector(selectSharedState, (state: SharedState) => state.unlinkEmail.pending );

export const getUnlinkEmailVerifyCodeSuccess = createSelector(selectSharedState, (state: SharedState) => state.unlinkEmail.verifySuccess );
export const getUnlinkEmailVerifyCodeError = createSelector(selectSharedState, (state: SharedState) => state.unlinkEmail.verifyError );
export const getUnlinkEmailVerifyCodePending = createSelector(selectSharedState, (state: SharedState) => state.unlinkEmail.verifyPending );

export const getActiveCampaignsGamification= createSelector(selectSharedState, (state: SharedState) => state.gamification.activeCampaigns);
export const showGamificationParticipationModal = createSelector(selectSharedState, (state: SharedState) => state.gamification.showParticipationModal);
export const getGamificationStatistics  = createSelector(selectSharedState, (state: SharedState) => state.gamification.statistics);

export const showAnimation = createSelector(selectSharedState, (state: SharedState) => state.gamification.showAnimation);

export const getDemandCalendar = createSelector(selectSharedState, (state: SharedState) => state.demandCalendar.calendar);

export const getWalktroughs = createSelector(selectSharedState, (state: SharedState) => state.walkthroughs);
