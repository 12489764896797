<div class="card shadow-none mb-3">
  <div class="card-body">
    <div class="row align-items-center mb-3">
      <div class="col-md-9">
        <h5 class="mb-0" data-cy="travelerName">{{ traveler.firstName }} {{ traveler.lastName }}</h5>
      </div>
    </div>
    <div class="row align-items-center mb-2">
      <div class="col col-md-6">
        <h6 class="card-subtitle secondary-content">{{ traveler.relationshipDescription }}</h6>
        <div class="tertiary-content">{{ getTravelerStatus(traveler.travelerStatus) }}</div>
      </div>
    </div>
  </div>
  <div class="card-footer bg-white">
    <div class="row align-items-center mb-2">
      <div class="col color-light">{{ 'profile.travelers.edit_traveler' | translate }}</div>
    </div>
    <div class="row align-items-center">
      <div class="col-12">
        <div class="tertiary-content">
          <a class="btn-link" (click)="onEditClick(traveler.id, 1)">
            {{ 'profile.travelers.profile_information' | translate }}
          </a>
        </div>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-12">
        <div class="tertiary-content">
          <a class="btn-link" (click)="onEditClick(traveler.id, 2)">
            {{ 'profile.travelers.passport_information' | translate }}
          </a>
        </div>
      </div>
    </div>
    <div
      class="row align-items-center"
      *ngIf="canInvite && traveler.eligibleForTravelerProgram && traveler.travelerStatus === ELIGIBLE_TRAVELER_STATUS.ACTIVE"
    >
      <div class="col-12">
        <div class="tertiary-content">
          <a class="btn-link" (click)="onEditClick(traveler.id, 3)">
            {{ 'profile.travelers.flight_booking_access' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
