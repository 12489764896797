import { MemberInterestsState } from 'app/profile/models/member-interests.interface';
import { MemberInterestsActions } from '../actions';

export const initialState: MemberInterestsState = {
  error: null,
  pending: false,
  interests: {},
  addPending: false,
  addError: null,
  addSuccess: false
};

export function reducer(
  state = initialState,
  action:
    | MemberInterestsActions.MemberInterestsActionsUnion
): MemberInterestsState {
  switch (action.type) {
    case MemberInterestsActions.MemberInterestsActionTypes.GetMemberInterests:
      return {
        ...state,
        pending: true
      };
    case MemberInterestsActions.MemberInterestsActionTypes.GetMemberInterestsSuccess:
      return {
        ...state,
        pending: false,
        interests: action.payload.interests,
        error: initialState.error
      };
    case MemberInterestsActions.MemberInterestsActionTypes.GetMemberInterestsFailure:
      return {
        ...state,
        pending: false,
        error: action.payload.error
      };
    case MemberInterestsActions.MemberInterestsActionTypes.SetMemberStatus:
      return {
        ...state,
        addPending: true
      };
    case MemberInterestsActions.MemberInterestsActionTypes.SetMemberStatusSuccess:
      return {
        ...state,
        addPending: false,
        addError: initialState.error,
        addSuccess: true,
        interests: action.payload.response
      };
    case MemberInterestsActions.MemberInterestsActionTypes.SetMemberStatusFailure:
      return {
        ...state,
        addPending: false,
        addError: action.payload.error,
        addSuccess: false
      };
    default: {
      return state;
    }
  }
}

export const getMemberInterestsPending = (state: MemberInterestsState) => state.pending;
export const getMemberInterestsSuccess = (state: MemberInterestsState) => state.interests;

export const setMemberStatusError = (state: MemberInterestsState) => state.addError;
export const setMemberStatusPending = (state: MemberInterestsState) => state.addPending;
export const setMemberStatusSuccess = (state: MemberInterestsState): boolean => state.addSuccess;
