import {
  Component,
  Input,
  Output,
  OnInit,
  OnChanges,
  ViewChildren,
  QueryList,
  EventEmitter,
  DoCheck
} from '@angular/core';
import { InsuranceInformation } from 'app/shared/models/insurance-information';
import { Traveler } from 'app/shared/models/traveler';
import * as moment from 'moment';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TravelerComponent } from 'app/shared/components/traveler/traveler.component';
import { HttpErrorResponse } from '@angular/common/http';
import { CurrencyHelper } from 'app/shared/helpers/currency.helper';
import { TripInsuranceHelper } from '../../helpers/trip-insurance.helper';
import { datesConfig } from 'configs/dates';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'insurance',
  templateUrl: 'insurance.html',
  styleUrls: ['insurance.scss']
})

export class InsuranceComponent implements OnInit, OnChanges, DoCheck {
  @ViewChildren('trv') travelers: QueryList<TravelerComponent>;
  @Input() insuranceInformation: InsuranceInformation;
  @Input() checkin = moment().format('MMM DD, YYYY');
  @Input() checkout = moment().format('MMM DD, YYYY');
  @Input() user: any;
  @Input() plan: any;
  @Input() currency: any;
  @Input() billingAddress: any;
  @Input() isPayPalToken: any;
  @Input() showInsuranceTotal = true;
  @Input() showToggle = true;
  @Input() initWithTraveler = false;
  @Input() hotelInsuranceDisabled = false;
  @Input() isCashPayment = false;
  @Input() passengers = [];
  @Input() avoidLoadPlan = false;
  @Output() updateTotal = new EventEmitter();
  @Output() updateInsurance = new EventEmitter();
  error: boolean;

  hasChangedInsurance: boolean;
  insuranceForm: UntypedFormGroup;
  ready = false;
  insuranceMaxDaysPeriod = 90;
  maxDate = moment().add(this.insuranceMaxDaysPeriod, 'days');
  checkoutMaxDate = this.maxDate;
  checkoutMinDate = moment().add(1, 'days');
  insuranceSelected = false; // Keep track of the last status
  icons = { faPlusCircle };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private currencyHelper: CurrencyHelper,
    private tripInsuranceHelper: TripInsuranceHelper
  ) { }

  ngOnInit() {
    this.insuranceForm = this.formBuilder.group({
      insuranceSelection: [''],
      fromDate: [moment(this.checkin, datesConfig.momentFormats).format('MMM DD, YYYY')],
      toDate: [moment(this.checkout, datesConfig.momentFormats).format('MMM DD, YYYY')]
    });
    this.setCoverageDates(this.insuranceForm.get('fromDate').value, this.insuranceForm.get('toDate').value);

    if (this.initWithTraveler && !this.insuranceInformation.travelers.length && !this.passengers.length) {
      this.addTraveler(this.user);
    } else {
      this.clearTravelersWithInsuranceUnselected();
    }
  }

  ngDoCheck() {
    if (this.insuranceDisabled && !this.insuranceForm.get('insuranceSelection').value) {
      this.insuranceForm.get('insuranceSelection').setValue(false);
      this.insuranceForm.get('insuranceSelection').setValidators([]);
      this.hasChangedInsurance = false;
    }
    if (this.insuranceDisabled) {
      this.insuranceForm.get('insuranceSelection').setValue(false);
    }
  }

  ngOnChanges(changes: any) {
    if (changes.plan && this.plan && !this.avoidLoadPlan) {
      if (this.plan instanceof HttpErrorResponse) {
        this.error = true;
      } else {
        this.insuranceInformation.pricePerTraveler = this.plan.display_price;
      }
      this.ready = true;
    }

    if (changes.isCashPayment) {
      this.checkPayWithCash();
    }
  }

  checkPayWithCash() {
    if (this.isCashPayment) {
      this.insuranceInformation.purchaseInsurance = false;
      this.updateTotal.emit();
    } else {
      this.insuranceInformation.purchaseInsurance = this.insuranceSelected;
      this.updateTotal.emit();
    }
  }

  onCheckinDatesSelected(dates) {
    this.insuranceForm.get('fromDate').setValue(dates.startDate);
    this.insuranceForm.get('toDate').setValue(dates.endDate);
    this.setCoverageDates(dates.startDate, dates.endDate);
  }

  handleMandatory(event) {
    this.insuranceForm.get('insuranceSelection').setValue(event.insuranceChoised);
    if (event.insuranceChoised) {
      this.toggleForm({ target: { checked: true } });
    } else {
      this.toggleForm({ target: { checked: false } });
    }
  }

  toggleForm(e) {
    this.insuranceInformation.purchaseInsurance = e.target.checked;
    this.insuranceSelected = e.target.checked;
    this.updateInsurance.emit(this.insuranceSelected);
    this.clearTravelersWithInsuranceUnselected();
    if (e.target.checked && !this.insuranceInformation.travelers.length) {
      this.passengers.length ? this.addPassengers() : this.addTraveler(this.user);
    } else {
      this.updateTotal.emit();
    }
  }

  addTraveler(user = {}) {
    const traveler = new Traveler();
    traveler.firstName = user['firstName'] || '';
    traveler.lastName = user['lastName'] || '';
    traveler.email = user['personalEmail'] || '';
    traveler.dateOfBirth = user['birthDate'] || moment().startOf('year').subtract(18, 'years').format('YYYY-MM-DD');
    traveler.passengerId = user['passengerId'] || '';
    traveler.passengerHash = user['passengerHash'] || '';
    this.insuranceInformation.travelers.push(traveler);
    this.updateTotal.emit();
  }

  addPassengers() {
    this.passengers.forEach((passenger) => {
      this.addTraveler(passenger);
    });
  }

  removeTraveler(index) {
    this.insuranceInformation.travelers.splice(index, 1);
    this.updateTotal.emit();
  }

  isValid() {
    let travelersValid = true;
    if (this.travelers) {
      travelersValid = !this.getTravelerForms().some(traveler => {
        traveler.markAllAsTouched();
        return !traveler.valid
      });
    }
    return this.insuranceForm.touched ? travelersValid && this.insuranceForm.valid : true;
  }

  get insuranceDisabled() {
    if (!this.billingAddress || !this.billingAddress.country || this.isPayPalToken) {
      return false;
    }
    return this.tripInsuranceHelper.isRestrictedCountry(this.billingAddress.country);
  }

  get showDefaultInsurance() {
    return this.showToggle;
  }

  get pricePerTraveler() {
    return this.currencyHelper ? this.currencyHelper.toSelectedCurrencySymbol(
      this.insuranceInformation.pricePerTraveler,
      this.currency,
      '1.0-0'
    ) : '';
  }

  get insuranceTotal() {
    return this.currencyHelper ? this.currencyHelper.toSelectedCurrencySymbol(
      this.insuranceInformation.total(),
      this.currency,
      '1.0-0'
    ) : '';
  }

  addressUpdated() {
    this.insuranceInformation.purchaseInsurance = this.insuranceForm.value.insuranceSelection && !this.insuranceDisabled;
    this.updateTotal.emit();
  }

  setCoverageDates(fromDate: string = null, toDate: string = null) {
    if (fromDate) {
      this.insuranceInformation.fromDate = moment(fromDate, datesConfig.momentFormats).format('YYYY-MM-DD');
    }

    if (toDate) {
      this.insuranceInformation.toDate = moment(toDate, datesConfig.momentFormats).format('YYYY-MM-DD');
    }
  }

  getTravelerForms(): UntypedFormGroup[] {
    const travelersForms = [];
    this.travelers.forEach(traveler => travelersForms.push(traveler.getForm()));
    return travelersForms;
  }

  clearTravelersWithInsuranceUnselected() {
    if (!this.insuranceSelected) {
      this.insuranceInformation.clearTravelers();
    }
  }

  formValid(e) {
    this.insuranceInformation.travelers[e?.index].formValid = e.valid;
  }

}
