import { Action } from '@ngrx/store';

export enum AirportActionTypes {
  LoadAirports = '[Flights] Load Airports',
  LoadAirportsSuccess = '[Flights] Load Airports Success',
  LoadAirportsFailure = '[Flights] Load Airports Failure',
  ClearAirports = '[Flights] Clear Airports'
}

export class LoadAirports implements Action {
  readonly type = AirportActionTypes.LoadAirports;
  constructor(public payload?: { airlineCode?: string }) {}
}

export class LoadAirportsSuccess implements Action {
  readonly type = AirportActionTypes.LoadAirportsSuccess;

  constructor(public payload: { airports: any }) {}
}

export class LoadAirportsFailure implements Action {
  readonly type = AirportActionTypes.LoadAirportsFailure;

  constructor(public payload: { error: any }) {}
}

export class ClearAirports implements Action {
  readonly type = AirportActionTypes.ClearAirports;
}

export type AirportActionsUnion = LoadAirports
  | LoadAirportsSuccess
  | LoadAirportsFailure
  | ClearAirports;
