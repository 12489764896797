<div class="modal-content">
  <div class="modal-header text-end">
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModalCallBack()">

    </button>
  </div>
  <div class="modal-body">
    <div id="game" #game></div>
  </div>
</div>
