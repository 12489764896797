import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ZendeskService {

  constructor(private http: HttpClient) { }

  createTicket(ticket) {
    const headers = new HttpHeaders({
      enctype: 'multipart/form-data',
      'No-Auth': 'true',
      'X-API-CALL': 'true'
    });
    return this.http.post(`${environment.apiUrl}/api/v1/zendesk.json`, ticket, { headers });
  }
}
