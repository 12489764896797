import { DemandCalendar } from "app/hotels/models/demand-calendar";
import { DemandCalendarActions } from "app/hotels/store/actions";

export interface State {
  error: any | null;
  pending: boolean;
  calendar: DemandCalendar[];
}

export const initialState: State = {
  error: null,
  pending: false,
  calendar: [],
};

export function reducer(
  state = initialState,
  action: DemandCalendarActions.DemandCalendarActionsUnion
): State {
  switch (action.type) {
    case DemandCalendarActions.DemandCalendarActionTypes.ClearDemandCalendar:
      return {
        ...state,
        pending: true,
        error: initialState.error,
        calendar: [],
      };
    case DemandCalendarActions.DemandCalendarActionTypes.GetDemandCalendar:
      return {
        ...state,
        pending: true,
      };
    case DemandCalendarActions.DemandCalendarActionTypes
      .GetDemandCalendarSuccess:
      return {
        ...state,
        pending: false,
        error: initialState.error,
        calendar: action.payload.calendar,
      };
    case DemandCalendarActions.DemandCalendarActionTypes.GetDemandCalendarError:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    default: {
      return state;
    }
  }
}

export const getError = (state: State) => state.error;
export const getPending = (state: State) => state.pending;
export const getCalendar = (state: State) => state.calendar;
