import { Injectable } from '@angular/core';
import { ConfigFlags } from 'app/shared/models/configFlags';

@Injectable({ providedIn: 'root' })
export class InviteTravelerHelper {

  constructor() {
  }

  canInvite(configFlags: ConfigFlags, featuresFlags: any) {
    return configFlags && configFlags.invite_eligible_travelers === '1'
      && featuresFlags && featuresFlags.includes('invite_eligible_traveler');
  }
}
