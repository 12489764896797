import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { AirlineActions } from '../actions';
import { map, catchError, switchMap, debounceTime } from 'rxjs/operators';
import { of } from 'rxjs';
import { AirlinesService } from 'app/shared/services/airlines/airlines.service';

@Injectable()
export class AirlineEffects {

  loadAirlines$ = createEffect(()=>
    this.actions$.pipe(
      ofType<AirlineActions.LoadAirlines>(AirlineActions.AirlineActionTypes.LoadAirlines),
      debounceTime(100),
      switchMap(() =>  {
        return this.airlinesService.getAirlines().pipe(
            map(airlines => new AirlineActions.LoadAirlinesSuccess({ airlines })),
            catchError(error => of(new AirlineActions.LoadAirlinesFailure(error)))
          );
      })
    )
  );

  loadAirlinesPricingInCRS$ = createEffect(()=>
    this.actions$.pipe(
      ofType<AirlineActions.LoadAirlinesPricingInCRS>(AirlineActions.AirlineActionTypes.LoadAirlinesPricingInCRS),
      switchMap((action) =>  {
        return this.airlinesService.getAirlinesPricingInCRS(action?.payload?.employeeAirlineCode).pipe(
            map(airlinesPricingInCRS => new AirlineActions.LoadAirlinesPricingInCRSSuccess({ airlinesPricingInCRS })),
            catchError(error => of(new AirlineActions.LoadAirlinesPricingInCRSFailure(error)))
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private airlinesService: AirlinesService,
  ) {}
}
