import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AmazonS3Service {
  constructor(private http: HttpClient) { }

  getPresignedUrl(bucket, fileName, size) {
    const params = new HttpParams()
      .set('name', fileName)
      .set('size', size)
      .set('bucket', bucket);
    return this.http.get<any[]>(`${environment.apiUrl}/api/v1/s3/request_for_presigned_url.json`, { params });
  }

  uploadImagePresigned(file: File, presignedUrl) {

    const formData: FormData = new FormData();

    Object.keys(presignedUrl.url_fields).forEach((key) => {
      formData.append(key, presignedUrl.url_fields[key]);
    });

    // File must be the last one!
    formData.append('file', file);

    const headers = new HttpHeaders({
      enctype: 'multipart/form-data',
      'No-Auth': 'true',
      'no-version': 'true'
    });

    // Need this because amazon returns 201 with an xml
    const responseType = 'text' as 'text';

    return this.http.post(presignedUrl.url, formData, { headers, responseType });
  }
}
