import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { AbandonHotelService } from 'app/shared/services/abandon-hotel/abandon-hotel.service';
import { AbandonedHotelActions, AbandonedHotelApiActions } from '../actions';
import { isEmpty } from 'lodash';

@Injectable()
export class AbandonedHotelEffects {

  load$ = createEffect(()=>
    this.actions$.pipe(
      ofType<AbandonedHotelActions.Load>(AbandonedHotelActions.AbandonedHotelActionTypes.Load),
      switchMap(() =>
        this.abandonedHotelService.getCardContent().pipe(
          map(res => new AbandonedHotelApiActions.LoadSuccess(isEmpty(res) ? null : res)),
          catchError(error => of(new AbandonedHotelApiActions.LoadFailure(error)))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private abandonedHotelService: AbandonHotelService
  ) {}
}
