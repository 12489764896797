import { Action } from '@ngrx/store';
import { CarsRecentSearch } from 'app/shared/models/cars-recent-search';

export enum CarsRecentSearchesActionTypes {
  Load = '[Car Recent Searches] Load',
  SaveSearch = '[Car Recent Searches] Save Search'
}

export class Load implements Action {
  readonly type = CarsRecentSearchesActionTypes.Load;

  constructor() {}
}

export class SaveSearch implements Action {
  readonly type = CarsRecentSearchesActionTypes.SaveSearch;

  constructor(public payload: CarsRecentSearch) {}
}

export type CarsRecentSearchesActionsUnion = Load
  | SaveSearch;
