<div class="search-hero-section">
  <ngb-carousel *ngIf="items.length > 0" #ngbCarousel (swipeleft)="ngbCarousel.next()" (swiperight)="ngbCarousel.prev()">
    <ng-template *ngFor="let item of items" ngbSlide>
      <div class="picsum-img-wrapper">
        <div class="gradient-filter"></div>
        <picture>
          <source media="(max-width:768px)" [srcset]="item.backgroundImage.url + '/m/0x500'">
          <img [src]="item.backgroundImage.url + '/m/'" [alt]="item.backgroundImage.alt">
        </picture>
      </div>
      <div class="carousel-caption">
        <ng-container *ngFor="let content of item.content">
          <div *ngIf="content.cType === 'text'" [ngClass]="content.cssClass" [innerHTML]="content.text"></div>
          <ng-container *ngIf="content.cType === 'button'">
            <button class="btn px-md-4 me-2 d-none d-md-block" [ngClass]="content.style" (click)="buttonClick(content, item.contentInteractionData)">
              <span>
                {{ content.label }}
              </span>
            </button>
          </ng-container>
        </ng-container>
        <div *ngIf="item.button" class="mobile-button-wrapper d-md-none">
          <button class="btn px-md-4 me-2 w-100" [ngClass]="item.button.style" (click)="buttonClick(item.button, item.contentInteractionData)">
            <span>
              {{ item.button.label }}
            </span>
          </button>
        </div>
      </div>
    </ng-template>
  </ngb-carousel>
</div>
