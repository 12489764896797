import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MailchimpService {
  constructor (private http: HttpClient) {}

  getAllInterests(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/ups_email_subscriptions/all-interests`);
  }

  getMemberInterests(email): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/ups_email_subscriptions/interests?email=${encodeURIComponent(email)}`);
  }

  setMemberStatus(data: string, isToken: boolean): Observable<any> {
    const params = {
      data,
      isToken
    };
    return this.http.patch(`${environment.apiUrl}/api/v1/ups_email_subscriptions/email-interests`, params);
  }
}
