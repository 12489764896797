import { HotelFilterActions } from 'app/hotels/store/actions';

export interface State {
  dirty: boolean;
}

export const initialState: State = {
  dirty: false
};

export function reducer(
  state = initialState,
  action: HotelFilterActions.HotelFilterActionsUnion
) {
  switch (action.type) {
    case HotelFilterActions.HotelFilterActionTypes.MarkState:
      return action.payload;
    case HotelFilterActions.HotelFilterActionTypes.Clear:
      return initialState;
    default:
      return state;
  }
}

export const getFilterState = (state: State) => state;
