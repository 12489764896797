import { Action } from '@ngrx/store';

export enum HotelReviewsActionTypes {
  GetReviews = '[Hotel Reviews] Get Hotel Reviews',
  GetReviewsSuccess = '[Hotel Reviews] Get Hotel Reviews Success',
  GetReviewsFailure = '[Hotel Reviews] Get Hotel Reviews Failure'
}

export class GetReviews implements Action {
  readonly type = HotelReviewsActionTypes.GetReviews;

  constructor(public payload: any) { }
}

export class GetReviewsSuccess implements Action {
  readonly type = HotelReviewsActionTypes.GetReviewsSuccess;

  constructor(public payload: any) { }
}

export class GetReviewsFailure implements Action {
  readonly type = HotelReviewsActionTypes.GetReviewsFailure;

  constructor() { }
}

export type HotelReviewsActionsUnion = GetReviews | GetReviewsSuccess | GetReviewsFailure;
