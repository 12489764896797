export interface EligiblesTravelers {
  id: string;
  firstName: string;
  lastName: string;
  relationshipDescription: string;
  travelerStatus: string;
  invitedTravelerStatus: InvitedTravelerStatus;
  email: string;
  email_invitation: string;
  eligibleForTravelerProgram: boolean;
  bookableEligibleTravelers?: { eligibleTravelerId: string; status: BookableEligibleTravelerStatus }[];
}

export enum InvitedTravelerStatus {
  PENDING = 'pending',
  DELETED = 'deleted',
  CONFIRMED = 'confirmed',
}

export enum EligibleTravelerStatus {
  PENDING_APPROVAL = 'Pending Approval',
  PENDING_REMOVAL = 'Pending Removal',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export enum BookableEligibleTravelerStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED,',
}
