import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { RoutesService } from 'app/shared/services/routes/routes.service';
import { SeoService } from 'app/shared/services/seo/seo.service';
import { SessionService } from 'app/shared/services/session/session.service';
import { CountryActions } from 'app/shared/store/actions';
import * as fromShared from 'app/shared/store/reducers';
import { combineLatest, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { LoginPageActions, RegisterPageActions } from '../store/actions';
import * as fromAuth from '../store/reducers';

@Component({
  selector: 'register',
  templateUrl: './register.html'
})
export class RegisterPage implements OnInit, OnDestroy {
  pending$ = this.store.pipe(select(fromAuth.getRegisterPagePending));
  error$ = this.store.pipe(select(fromAuth.getRegisterPageError));
  pendingLogin$ = this.store.pipe(select(fromAuth.getLoginPagePending));
  airlines$ = this.store.pipe(select(fromShared.getAirlines));
  utmParameters$ = this.store.pipe(select(fromShared.getUTMParameters));
  countries$ = this.store.pipe(select(fromShared.getCountries));
  ngUnsubscribe$ = new Subject<void>();
  shareCode: string;
  inviteCode: string;
  uniqueCode: string;
  inviteCodeType: string;
  inviteCodeSource: string;
  travelerCode: string;
  utmSource = '';
  utmMedium = '';
  utmCampaign = '';
  phoneError = false;
  isWhiteLabelEnabled = false;
  loggedIn = false;
  private readonly SHARE_HOTEL_CODE = '7';
  private previousUrlFromRegisterInvite;
  loggedIn$ = this.store.pipe(select(fromAuth.getLoggedIn));
  sessionTimeOut$ = this.store.pipe(select(fromAuth.getSessionTimeOut));

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<fromAuth.State>,
    private seoService: SeoService,
    private sessionService: SessionService,
    private router: Router,
    private routesService: RoutesService
  ) {}

  ngOnInit() {
    combineLatest([this.loggedIn$, this.sessionTimeOut$]).pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(([loggedIn, sessionTimeOut]) => {
        if (loggedIn && !sessionTimeOut) {
          this.loggedIn = true;
        }
      });
    if (this.loggedIn) {
      const redirect = this.activatedRoute.snapshot.queryParams['redirect'] ?
        decodeURIComponent(this.activatedRoute.snapshot.queryParams['redirect']) : '/';
      return this.router.navigateByUrl(redirect);
    }
    this.store.dispatch(new CountryActions.LoadCountries());
    this.setUtmParameters();
    this.seoService.setTitle('Create Account');
    this.routesService.previousUrl$.pipe(take(1)).subscribe((url) => {
      this.previousUrlFromRegisterInvite = url === '/membership/new';
    });
    if (localStorage.getItem('invite_code')) {
      this.sessionService.setInviteCode(localStorage.getItem('invite_code'));
    }
    this.shareCode = this.sessionService.getShareCode();
    this.inviteCode = this.sessionService.getInviteCode();
    this.uniqueCode = this.sessionService.getUniqueInviteCode();
    this.inviteCodeType = this.sessionService.getInviteCodeType();
    this.inviteCodeSource = this.sessionService.getInviteCodeSource();
    this.travelerCode = this.sessionService.getTravelerCode();
    if (this.inviteCodeType === this.SHARE_HOTEL_CODE && !this.previousUrlFromRegisterInvite) {
      return this.router.navigate(['/membership/new']);
    }
  }

  setUtmParameters() {
    this.utmParameters$.pipe(take(1)).subscribe((data) => {
      this.utmSource = this.activatedRoute.snapshot.queryParams['utm_source'] || data.utmSource;
      this.utmMedium = this.activatedRoute.snapshot.queryParams['utm_medium'] || data.utmMedium;
      this.utmCampaign = this.activatedRoute.snapshot.queryParams['utm_campaign'] || data.utmCampaign;
    });
  }

  createAccount(credentials) {
    this.store.dispatch(new RegisterPageActions.Register({ credentials }));
  }

  saveMessageEvent(message) {
    this.store.dispatch(new LoginPageActions.LoginShowMessage({ message }));
  }

  clearErrorMessage() {
    this.store.dispatch(new RegisterPageActions.RegisterClearError());
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
