<div>
  <div *ngFor="let button of buttons">
    <div class="row mb-3 p-3 w-100">
      <div class="col-3 text-start" style="height: 48px;">
        <img [src]="button.image" class="image-icon" />
      </div>
      <div class="col-4 text-start d-flex justify-content-center align-items-end text-start button-title">
        <h4 class="text-center d-xl-flex justify-content-xl-center align-items-xl-end">{{button.display}}</h4>
      </div>
      <div class="col-5 align-self-center text-start">
        <button *ngIf="!button.external" (click)="handleButtonClick(button)" class="btn btn-primary btn-lg text-center border rounded-pill w-100 button-list" [class]="button.class" type="button"> {{ 'home_page.continue' | translate }} </button>
        <a *ngIf="button.external" class="w-100 btn btn-primary btn-lg text-center border rounded-pill button-list" [href]="button.url"> {{ 'home_page.open' | translate }} </a>
      </div>
    </div>
  </div>
</div>
