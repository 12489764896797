import { Action } from '@ngrx/store';
import { AbandonedHotel } from 'app/shared/models/abandoned-hotel';

export enum AbandonedHotelApiActionTypes {
  LoadSuccess = '[Abandoned Hotel/API] Load Success',
  LoadFailure = '[Abandoned Hotel/API] Load Failure',
}

export class LoadSuccess implements Action {
  readonly type = AbandonedHotelApiActionTypes.LoadSuccess;

  constructor(public payload: AbandonedHotel) {}
}

export class LoadFailure implements Action {
  readonly type = AbandonedHotelApiActionTypes.LoadFailure;

  constructor(public payload: { error: any }) {}
}

export type AbandonedHotelApiActionsUnion = LoadSuccess
  | LoadFailure;
