import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as fromAuth from 'app/auth/store/reducers';
import { BreamerHelper } from 'app/shared/helpers/breamer.helper';
import { GeneralHelper } from 'app/shared/helpers/general.helper';
import { featureFlags } from 'app/shared/models/featureFlags';
import * as fromShared from 'app/shared/store/reducers';
import * as fromRoot from 'reducers';
import { combineLatest, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.html',
  styleUrls: ['app-layout.scss']
})

export class AppLayoutComponent implements OnInit, OnDestroy {
  showNavbar = false;
  loggedIn$ = this.store.pipe(select(fromAuth.getLoggedIn));
  user$ = this.store.pipe(select(fromAuth.getUser));
  featureFlag$ = this.store.pipe(select(fromShared.getFeatureFlags));
  scrollableHeader = false;
  showAnnouncement = true;
  showBeamer = false;
  beamerAbaileblePaths = [
    '/flights',
    '/search/hotels',
    '/search/cars',
    '/search/all-inclusive'
  ];

  private ngUnsubscribe = new Subject<void>();

  constructor(
    private generalHelper: GeneralHelper,
    private store: Store<fromRoot.State>,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private breamerHelper: BreamerHelper,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit() {
    combineLatest([this.loggedIn$, this.featureFlag$]).pipe(takeUntil(this.ngUnsubscribe)).subscribe(([userLoggedIn, flags]) => {
      if (userLoggedIn && isPlatformBrowser(this.platformId)) {
        this.showNavbar = true;
        if (flags && flags.includes(featureFlags.BEAMER)) {
          this.showBeamer = true;
        }
      }
    });

    // Loads user data for beamer && Set datalayer user if the user is already logged in
    this.user$.pipe(take(1)).subscribe((user) => {
      if (this.showBeamer) {
        this.breamerHelper.load(user);
      }
    });
    this.setAbTestGroups();

    this.router.events.pipe(
      takeUntil(this.ngUnsubscribe),
      filter((event) => this.showBeamer)
      ).subscribe((event: NavigationEnd) => {
        if (event.urlAfterRedirects) {
          this.checkBeamerBarDisplay(event.urlAfterRedirects);
        }
    });

    this.checkBeamerBarDisplay(location?.pathname);
  }

  checkBeamerBarDisplay(path: string) {
    if (!this.beamerAbaileblePaths.includes(path)) {
      this.breamerHelper.toggleTopBar('hide');
    } else {
      setTimeout(() => {
       this.breamerHelper.toggleTopBar('show');
        this.breamerHelper.addCssClass();
      }, 2500);
      ;
    }
  }

  setAbTestGroups() {
    // this.featureFlag$.pipe(take(1)).subscribe((flags) => {
    //   const abTestGroups = flags && flags.length > 0 ? flags.filter(feature => feature.startsWith('abtest_')) : [];
    //   if (abTestGroups && abTestGroups.length > 0) {
    //     this.dataLayerHelper.pushAbTestGroups(this.dataLayerHelper.DESCRIPTORS.AB_TEST_GROUPS, abTestGroups);
    //   }
    // });
  }

  onActivate(event: Event) {
    this.generalHelper.scrollToHeader();
    const childRoute = this.activatedRoute.snapshot.firstChild;
    if (childRoute) {
      const data = childRoute.firstChild && childRoute.firstChild.data
        ? childRoute.firstChild.data
        : childRoute.data ? childRoute.data : null;
      this.scrollableHeader = data && typeof data.scrollableHeader !== 'undefined' ? data.scrollableHeader : false;
      this.showAnnouncement = data && typeof data.showAnnouncement !== 'undefined' ? data.showAnnouncement : true;
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
