import { CurrentLocationActions } from 'app/shared/store/actions';
import { CurrentLocation } from 'app/shared/models/current-location';

export interface State {
  currentLocation: CurrentLocation | null;
  error: any | null;
  pending: boolean;
  loaded: boolean;
}

export const initialState: State = {
  currentLocation: null,
  pending: false,
  error: null,
  loaded: false
};

export function reducer(
  state = initialState,
  action: CurrentLocationActions.CurrentLocationActionsUnion
): State {
  switch (action.type) {
    case CurrentLocationActions.CurrentLocationActionTypes.Load:
      return {
        ...state,
        currentLocation: null,
        error: null,
        pending: true
      };
    case CurrentLocationActions.CurrentLocationActionTypes.LoadSuccess:
      return {
        currentLocation: action.payload.currentLocation,
        error: null,
        pending: false,
        loaded: true
      };
    case CurrentLocationActions.CurrentLocationActionTypes.LoadFailure:
      return {
        currentLocation: null,
        error: action.payload.error,
        pending: false,
        loaded: false
      };
    default:
      return state;
  }
}

export const getCurrentLocation = (state: State) => state.currentLocation;
export const getPending = (state: State) => state.pending;
export const getError   = (state: State) => state.error;
