<loading-indicator [mode]="mode" *ngIf="!currency"></loading-indicator>
<div *ngIf="currency">
  <div class="col-12 px-2 pt-2 w-100 bg-white"  [ngClass]="{'borderfix': !isMobileView}">
    <div class="row d-flex justify-content-center p-2">
      <div [ngClass]="isMobileView  ? 'col-3' : 'col-4 pb-2 col-lg-4'">
        <div class="d-flex justify-content-center align-items-center logo ">
          <fa-icon [icon]="icons.faTrophy" class="fa-2x" style="color:#454447;"></fa-icon>
        </div>
      </div>
      <div [ngClass]="isMobileView ? 'col-3 col-lg-4 my-auto text-center' : 'col-8 pb-2 my-auto text-center'">
        <p style="font-weight: bold; margin: 0;">{{ userCredits | currencyConvert:currency }}</p>
        <p style="margin: 0;">{{ 'credits_available' | translate }}</p>
      </div>
      <div *ngIf="!isMobileView" class="row w-100 d-flex justify-content-center">
        <div class="col-12 my-auto text-center py-2">
          <hr class="divisor pb-2">
          <span class="description py-2" data-cy="member-reward">
            {{ 'member_rewards.away_from_next_level' | translate: {nextTierGapLtv: nextTierGapLtv | currencyConvert:currency, nextTierReward: nextTierReward | currencyConvert:currency } }}
          </span>
        </div>
      </div>

      <div *ngIf="isMobileView" class="col-6 d-flex align-items-center ">
        <member-progress-bar *ngIf="isMobileView" [nextTierReward]="nextTierReward" [nextLevel]="nextLevel"
          [nextTierLtv]="nextTierLtv" [nextTierGapLtv]="nextTierGapLtv" [currentLevel]="currentLevel"
          [currentTierLtv]="currentTierLtv" [showAwayFromNextLevel]="showAwayFromNextLevel" [showLevels]="showLevels"
          [size]='size' [nextTierCurrentLtv]="nextTierCurrentLtv" [currency]='currency'></member-progress-bar>
      </div>
    </div>
    <div>
      <member-progress-bar *ngIf="!isMobileView" class="d-flex align-items-center flex-column padding pb-1"
        [nextTierReward]="nextTierReward" [nextLevel]="nextLevel" [nextTierLtv]="nextTierLtv"
        [nextTierGapLtv]="nextTierGapLtv" [currentLevel]="currentLevel" [currentTierLtv]="currentTierLtv"
        [nextTierCurrentLtv]="nextTierCurrentLtv" [showAwayFromNextLevel]="showAwayFromNextLevel" [showLevels]="showLevels" [size]='size' [currency]='currency'></member-progress-bar>
    </div>
    <div *ngIf="!isMobileView" class="d-flex flex-row-reverse py-2">
      <a class="link" [routerLink]="['/profile/hotel_credits']">{{ 'more_info' | translate }}</a>
    </div>
  </div>


</div>
