import { HotelDetailsActions } from '../actions';
import { HotelDetails } from 'app/hotels/models/hotel-details';

export interface State {
  hotelDetail: HotelDetails;
  loadingDetail: boolean;
  amenities: any[]
}

export const initialState: State = {
  hotelDetail: null,
  loadingDetail: false,
  amenities: null,
};

export function reducer(
  state = initialState,
  action: HotelDetailsActions.HotelDetailsActionsUnion
) {
  switch (action.type) {
    case HotelDetailsActions.HotelDetailsActionTypes.Get:
      return {
        ...state,
        hotelDetail: null,
        loadingDetail: true
      };
    case HotelDetailsActions.HotelDetailsActionTypes.GetSuccess:
      return {
        ...state,
        hotelDetail: action.payload,
        loadingDetail: false
      };
    case HotelDetailsActions.HotelDetailsActionTypes.GetFailure:
      return {
        ...state,
        loadingDetail: false
      };
    case HotelDetailsActions.HotelDetailsActionTypes.SetAmenity:
      return {
        ...state,
        amenities: action.payload,
      };
    default:
      return state;
  }
}

export const getHotelDetail = (state: State) => state.hotelDetail;
export const getAmenities = (state: State) => state.amenities;
export const getLoadingDetail = (state: State) => state.loadingDetail;
