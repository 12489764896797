import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AirlinesService {
  constructor (private http: HttpClient) { }

  getAirlines() {
    return this.http.get<any[]>(`${environment.apiUrl}/airlines`, {});
  }

  getAirlinesForTravel(reason) {
    return this.http.get<any[]>(
      `${environment.apiUrl}/flights/listing/searches/airlines_for_travel_reason`,
      { params: { reason_for_travel: reason } }
    );
  }

  getWhiteLabelAirlineCode(airlines, airlineCode = null) {
    if (airlineCode) {
      return airlines.find(airline => airline.code.toUpperCase() === airlineCode && airline.white_label?.url)?.code;
    }
    return airlines.find(airline => airline.white_label?.url === window.location.origin)?.code;
  }

  getAirlineData(airlines, airlineCode) {
    return airlines.find(airline => airline.code.toUpperCase() === airlineCode && airline.white_label?.url);
  }

  isWhiteLabelON(airlines) {
    const whitelabelCode = airlines.find(airline => airline.white_label?.url === window.location.origin)?.code;
    return airlines.find(airline => airline.code === whitelabelCode)?.white_label.enabled;
  }

  getAirlinesPricingInCRS(employeeAirlineCode) {
    return this.http.get<any[]>(`${environment.apiUrl}/general/airlines-pricing-in-crs`,
    { params: { employeeAirlineCode: employeeAirlineCode } });
  }
}
