import { User, USER_INITIAL_STATE } from 'app/auth/models/user';
import { AuthApiActions } from '../actions';

export interface State {
  user: User | null;
  loggedIn: boolean;
  customerSupport: {
    userSelectedByOperator:boolean;
    error: string;
    pending: boolean;
  };
  invitedTravelerInfo: {
    airlinesAvailable: any,
    invitedTravelerSelected: {
      id: string;
      eligibleTravelerId: string;
      airlineCode: string;
    }
  };
  sessionTimeOut: boolean;
}

export const initialState: State = {
  user: USER_INITIAL_STATE,
  loggedIn: false,
  customerSupport:{
    userSelectedByOperator: false,
    error: '',
    pending: false
  },
  invitedTravelerInfo: {
    airlinesAvailable: null,
    invitedTravelerSelected: {
      id: null,
      eligibleTravelerId: null,
      airlineCode: null
    }
  },
  sessionTimeOut: true
};

export function reducer(
  state = initialState,
  action: AuthApiActions.AuthApiActionsUnion
) {
  switch (action.type) {
    case AuthApiActions.AuthApiActionTypes.LoginSuccess:
      return {
        ...state,
        loggedIn: true,
        user: { ...action.payload.user },
        customerSupport: {
          ...state.customerSupport,
          pending: false
        },
        sessionTimeOut: false,
      };
    case AuthApiActions.AuthApiActionTypes.LogoutSuccess:
      return {
        ...state,
        loggedIn: false,
        user: initialState.user,
        customerSupport: initialState.customerSupport,
        sessionTimeOut: true
      };
    case AuthApiActions.AuthApiActionTypes.SessionTimeOut:
      return {
        ...state,
        sessionTimeOut: true
      };
    case AuthApiActions.AuthApiActionTypes.AvatarUploadSuccess:
      const updateUser = {
        ...state.user,
        member: {
          ...state.user.member,
          profile_image_url: action.payload.avatarUrl,
        }
      };
      return {
        ...state,
        user: updateUser
      };
    case AuthApiActions.AuthApiActionTypes.LoginMarketingEventSuccess:
      return {
        ...state,
        loggedIn: true,
        sessionTimeOut: false,
        marketingUser: true
      };
    case AuthApiActions.AuthApiActionTypes.LogoutMarketingEventSuccess:
      return {
        ...state,
        loggedIn: false,
        sessionTimeOut: true,
        marketingUser: false
      };
    case AuthApiActions.AuthApiActionTypes.ConfirmEmail:
      const confirmedUser = {
        ...state.user,
        member: {
          ...state.user.member,
          confirmed: true,
        }
      };
      return {
        ...state,
        user: confirmedUser
      };
    case AuthApiActions.AuthApiActionTypes.UpdateUserLanguage:
      const languagesUser = {
        ...state.user,
        member: {
          ...state.user.member,
          lang: action.payload.language
        }
      };
      return {
        ...state,
        user: languagesUser
      };
    case AuthApiActions.AuthApiActionTypes.LoadStatisticsSuccess:
      const statisticsUser = {
        ...state.user,
        statistics: action.payload.statistics
      };
      return {
        ...state,
        user: statisticsUser
      };
    case AuthApiActions.AuthApiActionTypes.FirstTimeLoginSuccess:
      const firstTimeLogin = {
        ...state.user,
        first_time_login: false,
        first_time_login_session: true
      };
      return {
        ...state,
        user: firstTimeLogin
      };
    case AuthApiActions.AuthApiActionTypes.FirstTimeAccessSuccess:
      const firstTimeAccess = {
        ...state.user,
        first_time_access: true
      };
      return {
        ...state,
        user: firstTimeAccess
      };
    case AuthApiActions.AuthApiActionTypes.FirstTimeAccessRestore:
      const firstTimeRestore = {
        ...state.user,
        first_time_access: false
      };
      return {
        ...state,
        user: firstTimeRestore
      };
    case AuthApiActions.AuthApiActionTypes.LoadUserConfigSuccess:
      const loadUserConfig = {
        ...state.user,
        config_loaded: true
      };
      return {
        ...state,
        user: loadUserConfig
      };
    case AuthApiActions.AuthApiActionTypes.SelectEmployee:
      return {
        ...state,
        customerSupport: {
          userSelectedByOperator: false,
          error: '',
          pending: true
        }
      };
    case AuthApiActions.AuthApiActionTypes.SelectUser:
      return {
        ...state
      };
    case AuthApiActions.AuthApiActionTypes.SelectUserSuccess:
      return {
        ...state,
        customerSupport: {
          ...state.customerSupport,
          userSelectedByOperator: true,
        }
      };
    case AuthApiActions.AuthApiActionTypes.SelectUserFailure:
      return {
        ...state,
        customerSupport: {
          userSelectedByOperator: false,
          error: action.payload.error,
          pending: false
        }
      };
    case AuthApiActions.AuthApiActionTypes.UnselectUser:
      return {
        ...state,
        customerSupport: {
          userSelectedByOperator: false,
          error: '',
          pending: false
        }
      };
    case AuthApiActions.AuthApiActionTypes.UpdateUserInfo:
      return {
        ...state,
        user: action.payload.user
      };
    case AuthApiActions.AuthApiActionTypes.UpdateUserAccountInfo:
      const updateAccount = {
        ...state.user,
        account: {
          ...state.user.account,
          ...action.payload,
        }
      };
      return {
        ...state,
        user: updateAccount
      };
    case AuthApiActions.AuthApiActionTypes.UpdateMemberChangePasswordDate:
      const updateMemberPasswordDate = {
        ...state.user,
        member: {
          ...state.user.member,
          password_updated_at: action.payload.newChangePasswordDate,
        }
      };
      return {
        ...state,
        user: updateMemberPasswordDate
      };
    case AuthApiActions.AuthApiActionTypes.UpdatePassword:
      const updatedUserPassword = {
        ...state.user,
        member: {
          ...state.user.member,
          password_updated_at: Date.now(),
        }
      };
      return {
        ...state,
        user: updatedUserPassword
      };
    case AuthApiActions.AuthApiActionTypes.LoadInvitedTravelerInfo:
      return {
        ...state
      };
    case AuthApiActions.AuthApiActionTypes.LoadInvitedTravelerInfoSuccess:
      return {
        ...state,
        invitedTravelerInfo: {
          airlinesAvailable: action.payload.airlines.available_airlines,
          invitedTravelerSelected: {
            id: action.payload.airlines.available_airlines[0].invitedTravelerId,
            eligibleTravelerId: action.payload.airlines.available_airlines[0].eligibleId,
            airlineCode: action.payload.airlines.available_airlines[0].airline_code
          },
        }
      };
    case AuthApiActions.AuthApiActionTypes.UpdateInvitedTravelerId:
      return {
        ...state,
        invitedTravelerInfo: {
          ...state.invitedTravelerInfo.airlinesAvailable,
          invitedTravelerSelected: {
            id: action.payload.id,
            eligibleTravelerId: action.payload.eligibleTravelerId,
            airlineCode: action.payload.airlineCode
          },
        }
      };
    case AuthApiActions.AuthApiActionTypes.ClearRedirectUserURL:
      const user = {
        ...state.user,
        member: {
          ...state.user.member,
          redirect: '/hotels',
        }
      };
      return {
        ...state,
        user
      };
    case AuthApiActions.AuthApiActionTypes.LoadUserToolsSuccess:
      let tools = [...state.user.tools];
      for (const tool in action.payload.tools) {
        if (action.payload.tools[tool] && !tools.includes(tool)) {
          tools.push(tool);
        } else if (!!!action.payload.tools[tool]) {
          tools = tools.filter((toolS) => toolS !== tool);
        }
      }
      return {
        ...state,
        user: {
          ...state.user,
          tools: tools,
        },
      };
    default:
      return state;
  }
}

export const getUser = (state: State) => state.user;
export const isRestricted = (state: State) => state.user.account.restricted;
export const isUserLoggedIn = (state: State) => state.loggedIn;
export const isSessionTimeOut = (state: State) => state.sessionTimeOut;
export const getCustomerSupport = (state: State) => state.customerSupport;
export const getInvitedTravelerInfo = (state: State) => state.invitedTravelerInfo;
export const getUserLanguage = (state: State) => state.user.member.lang;
export const getAirlineCode = (state: State) => state.user.member.airline_code;
