import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Modal } from 'app/shared/models/modal.model';
import * as fromAuth from 'app/auth/store/reducers';
import * as fromRoot from 'reducers';
import { ModalActions } from 'app/shared/store/actions';
import { map, tap } from 'rxjs/operators';
import { featureFlags } from 'app/shared/models/featureFlags';
import { FeatureFlagsService } from 'app/shared/services/featureFlags/featureFlags.service';

@Injectable({
  providedIn: 'root'
})
export class LoggedUserGuard  {
  memberId = '';
  sessionTimeOut = false;
  constructor(
    public router: Router,
    private store: Store<fromRoot.State>,
    protected translateService: TranslateService,
    private featureFlagsService: FeatureFlagsService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    return this.store.select<any>(fromAuth.selectAuthStatusState).pipe(
      map((status) => {
        this.memberId = status.user.member.member_id;
        this.sessionTimeOut = status.sessionTimeOut;
        return !this.sessionTimeOut;
      }),
      tap(() => {
        if (!this.memberId || this.sessionTimeOut) {
          if (this.featureFlagsService.isFeatureEnabled(featureFlags.LOGIN)) {
            const translationKey = !this.memberId ? 'flash_messages.user_not_logged_in' : 'token_expired_popup';
            this.openLoginErrorModal(translationKey);
          }
          this.router.navigate(['/login'], { queryParams: { redirect: encodeURIComponent(state.url) } });
          return of(false);
        }
        return of(true);
      })
    );
  }

  openLoginErrorModal(translationKey) {
    const data: Modal = {
      title: 'flash_messages.sign_in',
      bodyTranslationKey: translationKey,
      modalOptions: { size: 'lg' }
    };

    this.store.dispatch(new ModalActions.Show({ data }));
  }
}
