import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginPageActions } from '../../../store/actions';
import * as fromEventRegistration from '../../../store/reducers';

@Component({
  selector: 'event-login',
  templateUrl: './event-login.html'
})

export class EventLoginPage implements OnInit, OnDestroy {
  error$ = this.store.pipe(select(fromEventRegistration.getMarketingLoginError));
  loggedIn$ = this.store.pipe(select(fromEventRegistration.getMarketingLoggedIn));
  errorMessage;

  private ngUnsubscribe = new Subject<void>();

  constructor(
    private store: Store<fromEventRegistration.State>,
    private router: Router
  ) {}

  ngOnInit() {
    this.loggedIn$.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((loggedIn) => {
      if (loggedIn) {
        return this.router.navigate(['event-registration/airline']);
      }
    });
  }

  login(credentials) {
    this.store.dispatch(new LoginPageActions.MarketingLogin({ credentials }));
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
