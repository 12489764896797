import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { EMPTY, Observable, of } from 'rxjs';
import { GeocoderAddress, GeocoderLocation } from './geocoding.service';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AutocompleteService {

  sessionToken;

  constructor (
    private http: HttpClient) {
  }

  getGeocode(queryText) {
    return this.http.get<any>(`${environment.apiUrl}/api/v1/autocomplete/geocode?`, {
      params: {
        text: queryText
      }
    });
  }

  getAiHotels(queryText) {
    return this.http.get<any[]>(`${environment.apiUrl}/api/v1/autocomplete/es/ai`, {
      params: {
        text: queryText
      }
    });
  }

  getAutocompleteResults(queryText, token, memberId, type?) {
    const headers = new HttpHeaders({
      memberId,
      'No-Auth': 'true'
    });
    return this.http.get<any>(`${environment.apiUrl}/api/v1/autocomplete`, {
      headers,
      params: {
        text: queryText,
        token: token ? token : '',
        type: type && type === 'address' ? type : ''
      }
    });
  }

  getGooglePlaceDetails(placeId, token, memberId) {
    const headers = new HttpHeaders({
      memberId,
      'No-Auth': 'true'
    });
    return this.http.get<any>(`${environment.apiUrl}/api/v1/place/details`, {
      headers,
      params: {
        placeId,
        token
      }
    });
  }

  getArcgisPlaceDetails(placeId, memberId) {
    const headers = new HttpHeaders({
      memberId,
      'No-Auth': 'true'
    });
    return this.http.get<any>(`${environment.apiUrl}/api/v1/place/details/arcgis`, {
      headers,
      params: {
        placeId,
        token: ''
      }
    });
  }

  getLocations(input: string, type: string, memberId: string): Observable<GeocoderLocation[]> {
    if (input === '') {
      return of([]);
    }

    if (type === 'hotels' || type === 'airport') {
      return this.getGeoLocations(input, memberId);
    }

    if (type === 'address') {
      return this.getGeoLocations(input, memberId, type);
    }

    if (type === 'cars') {
      return this.http.get<any>(`${environment.apiUrl}/api/v1/car_locations.json`, { params: { query: input } }).pipe(
        map((resp) => {
          const places = [];
          resp.data.forEach((place) => {
            places.push({ ...place, displayName: place.name });
          });
          return places;
        })
      );
    }
  }

  getGeoLocations(input: string, memberId: string, type?): Observable<GeocoderLocation[]> {
    return this.getAutocompleteResults(input, this.sessionToken, memberId, type).pipe(mergeMap((predictions) => {
      const places = [];
      // if token is present then the result is returned from google
      if (predictions.length === 1 && predictions[0].token) {
        this.sessionToken = predictions[0].token;
        if (predictions[0].result && predictions[0].result.length > 0) {
          predictions[0].result.forEach((place) => {
            places.push({ ...place, displayName: place.description });
          });
        }
      } else {
        return of(predictions);
      }
      return of(places);
    }));
  }

  getLocationDetails(placeId: string, memberId: string, isArcgis?): Observable<GeocoderAddress> {
    if (isArcgis) {
      return this.getArcgisPlaceDetails(placeId, memberId).pipe(mergeMap((result) => {
        this.clearSessionToken();
        if (result && result.length > 0) {
          return of(result[0].address);
        }
        return EMPTY;
      }));
    }
    return this.getGooglePlaceDetails(placeId, this.sessionToken, memberId).pipe(mergeMap((result) => {
      this.clearSessionToken();
      if (result && result.length > 0) return of(result[0].address);
      return EMPTY;
    }));
  }

  getLocationCoords(placeId: string, memberId: string, isArcgis?) {

    if (isArcgis) {
      return this.getArcgisPlaceDetails(placeId, memberId).pipe(mergeMap((result) => {
        this.clearSessionToken();
        if (result && result.length > 0) {
          return of({
            lat: result[0].lat,
            lng: result[0].lng,
            displayText: result[0].resultType == 'hotel' ? result[0].name : result[0].formattedAddress,
            locationResolution: result[0].resolution,
            locationType: result[0].resultType
          });
        }
        return EMPTY;
      }));
    }
    return this.getGooglePlaceDetails(placeId, this.sessionToken, memberId).pipe(mergeMap((result) => {
      this.clearSessionToken();
      if (result && result.length > 0) {
        return of({
          lat: result[0].lat,
          lng: result[0].lng,
          displayText: result[0].resultType == 'hotel' ? result[0].name : result[0].formattedAddress,
          locationResolution: result[0].resolution,
          locationType: result[0].resultType,
          countryCode: result[0].address && result[0].address.country ? result[0].address.country : null
        });
      }
      return EMPTY;
    }));
  }

  clearSessionToken() {
    this.sessionToken = null;
  }
}
