import { ShareItineraryActions } from "../actions";

export interface State {
  pending: boolean;
  error: any;
  success: boolean;
}

export const initialState: State = {
  pending: false,
  error: null,
  success: false
};

export function reducer(
  state = initialState,
  action: ShareItineraryActions.ShareItineraryActionsUnion
) {
  switch (action.type) {
    case ShareItineraryActions.ShareItineraryActionTypes.Send:
      return {
        ...state,
        pending: true
      };
    case ShareItineraryActions.ShareItineraryActionTypes.SendSuccess:
      return {
        ...state,
        pending: false,
        response: action.response,
        success: true
      };
    case ShareItineraryActions.ShareItineraryActionTypes.SendFailure:
      return {
        ...state,
        pending: false,
        error: action.error
      };
    case ShareItineraryActions.ShareItineraryActionTypes.ClearState:
      return initialState;
    default:
      return state;
  }
}

export const getSendSuccess = (state: State) => state.success;
export const getSendError = (state: State) => state.error;
export const getSendPending = (state: State) => state.pending;