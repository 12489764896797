import { Injectable } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CurrencyHelper {
  private sharedUsdSymbol = ['CAD', 'AUD', 'NZD', 'MXN', 'SGD', 'HKD', 'TTD', 'BBD', 'BZD', 'BMD', 'KYD', 'XCD', 'JMD',
    'ARS', 'CLP', 'UYU', 'COP', 'DOP', 'FJD'];

  constructor(
    private currencyPipe: CurrencyPipe
  ) {}

  public toSelectedCurrencySymbol(
    value: any,
    currencyCode: string, digits: string = '1.2-2', changeSharedDollarSymbol: boolean = true, changeUsd: boolean = false): any {
    const display = ((changeSharedDollarSymbol && this.idSharedUsdSymbol(currencyCode))
    || currencyCode === 'MXN') ? 'symbol' : 'symbol-narrow';
    const valueFormatted = this.currencyPipe.transform(value, currencyCode, display, digits);

    return (currencyCode === 'USD' && changeSharedDollarSymbol && changeUsd) ? `US${valueFormatted}` : valueFormatted;
  }

  private idSharedUsdSymbol(currencyCode: string): boolean {
    return this.sharedUsdSymbol.indexOf(currencyCode) > -1;
  }
}
