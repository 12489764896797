import * as moment from 'moment';
import { MultifactorAuthenticationActions } from '../actions';

export interface State {
  sendCodePending: boolean;
  sendCodeSuccess: boolean;
  sendCodeError: any;
  verifyCodePending: boolean;
  verifyCodeSuccess: boolean;
  verifyCodeError: any;
  enrollPending: boolean;
  enrollSuccess: boolean;
  enrollError: any;
  dismissModalPending: boolean;
  dismissModalSuccess: boolean;
  dismissModalError: any;
  insertNewIPPending: boolean;
  insertNewIPSuccess: boolean;
  insertNewIPError: any;
  mfaRequest: boolean;
  attempts: number;
  lastAttemptDate: string;
}

export const initialState: State = {
  sendCodePending: false,
  sendCodeSuccess: false,
  sendCodeError: null,
  verifyCodePending: false,
  verifyCodeSuccess: false,
  verifyCodeError: null,
  enrollPending: false,
  enrollSuccess: false,
  enrollError: null,
  dismissModalPending: false,
  dismissModalSuccess: false,
  dismissModalError: null,
  insertNewIPPending: false,
  insertNewIPSuccess: false,
  insertNewIPError: null,
  mfaRequest: true,
  attempts: 0,
  lastAttemptDate: null
};

export function reducer(
  state = initialState,
  action: MultifactorAuthenticationActions.MultifactorAuthentificationActionsUnion
) {
  switch (action.type) {
    case MultifactorAuthenticationActions.MfaActionTypes.ResetSendCode:
      return {
        ...state,
        sendCodeSuccess: false,
        sendCodePending: false,
        sendCodeError: null,
      };
    case MultifactorAuthenticationActions.MfaActionTypes.SendCodeByEmail:
      return {
        ...state,
        sendCodePending: true
      };
    case MultifactorAuthenticationActions.MfaActionTypes.SendCodeBySMS:
      return {
        ...state,
        sendCodePending: true
      };
    case MultifactorAuthenticationActions.MfaActionTypes.SendCodeSuccess:
      return {
        ...state,
        sendCodeSuccess: true,
        sendCodePending: false,
        sendCodeError: null,
        attempts: state.attempts + 1,
        lastAttemptDate: (state.attempts + 1) === 3 ? moment().toString() : null
      };
    case MultifactorAuthenticationActions.MfaActionTypes.SendCodeFailure:
      return {
        ...state,
        sendCodePending: false,
        sendCodeError: action.payload.error
      };
    case MultifactorAuthenticationActions.MfaActionTypes.VerifyCode:
      return {
        ...state,
        verifyCodePending: true
      };
    case MultifactorAuthenticationActions.MfaActionTypes.VerifyCodeSuccess:
      return {
        ...state,
        verifyCodeSuccess: true,
        verifyCodePending: false,
        verifyCodeError: null,
        mfaRequest: false,
        attempts: initialState.attempts,
        lastAttemptDate: initialState.lastAttemptDate
      };
    case MultifactorAuthenticationActions.MfaActionTypes.VerifyCodeFailure:
      return {
        ...state,
        verifyCodePending: false,
        verifyCodeError: action.payload.error,
        verifyCodeSuccess: false
      };
    case MultifactorAuthenticationActions.MfaActionTypes.Enroll:
      return {
        ...state,
        enrollPending: true
      };
    case MultifactorAuthenticationActions.MfaActionTypes.EnrollSuccess:
      return {
        ...state,
        enrollSuccess: true,
        enrollPending: false,
        enrollError: null,
        mfaRequest: false,
        lastAttemptDate: initialState.lastAttemptDate
      };
    case MultifactorAuthenticationActions.MfaActionTypes.EnrollFailure:
      return {
        ...state,
        enrollPending: false,
        enrollError: action.payload.error,
        enrollSuccess: false
      };
    case MultifactorAuthenticationActions.MfaActionTypes.DismissModal:
      return {
        ...state,
        dismissModalPending: true
      };
    case MultifactorAuthenticationActions.MfaActionTypes.DismissModalSuccess:
      return {
        ...state,
        dismissModalSuccess: true,
        dismissModalPending: false,
        dismissModalError: null
      };
    case MultifactorAuthenticationActions.MfaActionTypes.DismissModalFailure:
      return {
        ...state,
        dismissModalPending: false,
        dismissModalError: action.payload.error,
        dismissModalSuccess: false
      };
    case MultifactorAuthenticationActions.MfaActionTypes.InsertNewIP:
      return {
        ...state,
        insertNewIPPending: true
      };
    case MultifactorAuthenticationActions.MfaActionTypes.InsertNewIPSuccess:
      return {
        ...state,
        insertNewIPSuccess: true,
        insertNewIPPending: false,
        insertNewIPError: null
      };
    case MultifactorAuthenticationActions.MfaActionTypes.InsertNewIPFailure:
      return {
        ...state,
        insertNewIPPending: false,
        insertNewIPError: action.payload.error,
        insertNewIPSuccess: false
      };
    case MultifactorAuthenticationActions.MfaActionTypes.Reset:
      return initialState;
    case MultifactorAuthenticationActions.MfaActionTypes.ResetAttempts:
      return {
        ...state,
        attempts: 0
      };
    default: {
      return state;
    }
  }
}

export const sendCodePending = (state: State) => state.sendCodePending;
export const sendCodeSuccess = (state: State) => state.sendCodeSuccess;
export const sendCodeFailure = (state: State) => state.sendCodeError;
export const verifyCodePending = (state: State) => state.verifyCodePending;
export const verifyCodeSuccess = (state: State) => state.verifyCodeSuccess;
export const verifyCodeFailure = (state: State) => state.verifyCodeError;
export const enrollPending = (state: State) => state.enrollPending;
export const enrollSuccess = (state: State) => state.enrollSuccess;
export const enrollFailure = (state: State) => state.enrollError;
export const dismissModalPending = (state: State) => state.dismissModalPending;
export const dismissModalSuccess = (state: State) => state.dismissModalSuccess;
export const dismissModalFailure = (state: State) => state.dismissModalError;
export const insertNewIPPending = (state: State) => state.insertNewIPPending;
export const insertNewIPSuccess = (state: State) => state.insertNewIPSuccess;
export const insertNewIPFailure = (state: State) => state.insertNewIPError;
export const mfaRequest = (state: State) => state.mfaRequest;
export const attempts = (state: State) => state.attempts;
export const lastAttemptDate = (state: State) => state.lastAttemptDate;
