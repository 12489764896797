export interface User {
  access_token: string;
  account: {
    address: string;
    airlineCode: string;
    aptUnit: string;
    billDate: string | null;
    birthDate: string | null;
    city: string;
    country: string;
    deleted: string;
    departmentName: string;
    employeeNumber: string;
    employmentStatus: string;
    employmentType: string;
    facebook: string | null;
    firstName: string;
    fromAffiliate: number;
    gender: string;
    hireDate: string;
    homePhone: string;
    iafDisabled?: number;
    id: string;
    id90InvitesUsed: number;
    id90Status: string;
    idMembers: number;
    inviteCode: string;
    invitedByUserId: string | null;
    jobTitleDesc: string | null;
    lastName: string;
    linkedin: string | null;
    membershipType: string;
    mfaEmail?: boolean;
    middleName: string;
    mobilePhone: string;
    organizationId: number;
    passportCountryBirth: string;
    passportExpirationDate: string;
    passportFirstName: string;
    passportIssuanceCountry: string;
    passportIssuanceDate: string;
    passportLastName: string;
    passportMiddleName: string;
    passportNationality: string;
    passportNumber: string;
    passportNumberDomestic: string;
    personalEmail: string;
    personalId: string;
    promoCode: string;
    restricted?: boolean;
    rebillAvoid: string;
    rebillDate: string | null;
    seniorityDate: string;
    signupDate: string;
    state: string;
    travelStatus: string;
    trebecaInvitesRemaining: number;
    trebecaInvitesUsed: number;
    twitter: string | null;
    workEmail: string;
    worksInAirline: string;
    ytdSavings: number;
    zip: number;
  };
  allowed_to_invite: boolean;
  call_center_admin: string;
  first_time_access: boolean;
  first_time_login: boolean;
  first_time_login_session: boolean;
  generic_user: boolean;
  is_partner: boolean;
  member: {
    affiliation: string;
    airline_code: string;
    app_downloaded: boolean;
    confirmed: boolean;
    cruise_discount_airline: boolean;
    currency: string;
    email: string;
    employee_number: string;
    first_name: string;
    id: string;
    id90_user_id: number;
    last_name: string;
    member_id: number;
    organization_id: string;
    profile_image_url: string;
    redirect: string;
    lang: string;
    password_updated_at: string;
    mfa_skipped: string;
  };
  is_new_device: boolean;
  pin: string;
  remember_me_token: string;
  tools: string[];
  state: string;
  config_loaded: boolean;
  statistics: {
    allowed_to_invite: boolean;
    total_hotel_bookings: number;
    total_invited_users: number;
    total_invites_with_bookings: number;
    total_pending_invites: number;
    total_potential_rewards: number;
    total_registered_invites: number;
    total_rewards_earned: number;
  };
  third_part_accounts: ThirdPartAccounts[];
  cobus_authorization?: string;
  is_admin?: boolean;
  redirect?: string;
  showNewAccountModal?: string;
  total_spent: number;
}

export interface ThirdPartAccounts {
  id: number;
  member_id: string;
  account_id: string;
  platform: string;
}

export const USER_INITIAL_STATE = {
  access_token: '',
  account: {
    address: '',
    airlineCode: '',
    aptUnit: '',
    billDate: null,
    birthDate: null,
    city: '',
    country: '',
    deleted: '',
    departmentName: '',
    employeeNumber: '',
    employmentStatus: '',
    employmentType: '',
    facebook: null,
    firstName: '',
    fromAffiliate: 0,
    gender: '',
    hireDate: '',
    homePhone: '',
    id: '',
    id90InvitesUsed: 0,
    id90Status: '',
    idMembers: null,
    inviteCode: '',
    invitedByUserId: null,
    jobTitleDesc: null,
    lastName: '',
    linkedin: null,
    membershipType: '',
    middleName: '',
    mobilePhone: '',
    organizationId: null,
    passportCountryBirth: '',
    passportExpirationDate: '',
    passportFirstName: '',
    passportIssuanceCountry: '',
    passportIssuanceDate: '',
    passportLastName: '',
    passportMiddleName: '',
    passportNationality: '',
    passportNumber: '',
    passportNumberDomestic: '',
    personalEmail: '',
    personalId: '',
    promoCode: '',
    rebillAvoid: '',
    rebillDate: null,
    seniorityDate: '',
    signupDate: '',
    state: '',
    travelStatus: '',
    trebecaInvitesRemaining: 0,
    trebecaInvitesUsed: 0,
    twitter: null,
    workEmail: '',
    worksInAirline: '',
    ytdSavings: 0,
    zip: null,
  },
  allowed_to_invite: true,
  call_center_admin: '',
  first_time_access: false,
  first_time_login: false,
  first_time_login_session: false,
  generic_user: false,
  is_partner: false,
  member: {
    affiliation: '',
    airline_code: '',
    app_downloaded: false,
    confirmed: false,
    cruise_discount_airline: false,
    currency: 'USD',
    email: '',
    employee_number: '',
    first_name: '',
    id: '',
    id90_user_id: 0,
    last_name: '',
    member_id: 0,
    organization_id: '',
    profile_image_url: '',
    redirect: '',
    lang: '',
    password_updated_at: '',
    mfa_skipped: null
  },
  is_new_device: false,
  pin: '',
  remember_me_token: '',
  tools: [],
  state: '',
  config_loaded: false,
  statistics: {
    allowed_to_invite: true,
    total_hotel_bookings: 0,
    total_invited_users: 0,
    total_invites_with_bookings: 0,
    total_pending_invites: 0,
    total_potential_rewards: 0,
    total_registered_invites: 0,
    total_rewards_earned: 0
  },
  third_part_accounts: [],
  total_spent: 0
};

export enum EmploymentTypes {
  RETIREE = 'R',
}
