<!-- Mobile DatePicker to Open Modal -->
<div *ngIf="showModalForMobile" class="d-md-none">
  <div class="datepicker-container rounded" [ngClass]="{'datepicker-single-input': singleInput, 'datepicker-multi-input': !singleInput, 'narrow': narrowWidth, 'datepicker-double': !singleDate}">
    <div class="checking-container" [ngClass]="{'input-contains-icon': showCalendarIcon}">
      <label *ngIf="startLabel.length">{{ startLabel }}</label>
      <div #checkinContainer class="datepicker-checkin position-relative datepicker-{{placement}} d-flex" [class.pseudoFocus]="fromSelectionInProgress">
        <fa-icon [icon]="icons.faCalendarAlt" *ngIf="showCalendarIcon && !showCalendarIconRight"  class=" date-input-icon input-icon" (click)="showFullScreenModal = true"></fa-icon>
        <div class="flex-grow-1" [ngClass]="{ 'form-floating': displayLabel }">
          <input
            #fromInputEl
            data-cy="from"
            id="fromInputMobile"
            class="{{inputName}} checkin form-control"
            [ngClass]="{'ng-invalid ng-touched ng-dirty': isError, 'no-padding' : removePadding}"
            name="{{inputName}}"
            [value]="fromDisplay"
            (click)="showFullScreenModal = true"
            readonly
          >
          <label for="fromInputMobile" *ngIf="displayLabel">
            {{ placeholderStart }}
          </label>
        </div>
        <fa-icon [icon]="icons.faCalendarAlt" *ngIf="showCalendarIcon && showCalendarIconRight" class="date-input-icon input-icon" (click)="showFullScreenModal = true"></fa-icon>
      </div>
    </div><!-- /.checking-container -->
    <div *ngIf="showSeparator">
      <label *ngIf="startLabel.length || endLabel.length">&nbsp;</label>
      <fa-icon *ngIf="!singleDate" [icon]="icons.faArrowRight" class="datepicker-separator"></fa-icon>
    </div>
    <div *ngIf="!singleDate" class="checkout-container" [ngClass]="{'input-contains-icon': showCalendarIcon}">
      <label *ngIf="endLabel.length">{{ endLabel }}</label>
      <div class="datepicker-checkout d-flex" [class.pseudoFocus]="toSelectionInProgress" [ngClass]="{ 'form-floating': displayLabel }">
        <fa-icon [icon]="icons.faCalendarAlt" *ngIf="showCalendarIcon" class="date-input-icon input-icon" (click)="showFullScreenModal = true"></fa-icon>
        <div [ngClass]="{ 'form-floating': displayLabel }">
          <input
            #toInputEl
            id="toInputMobile"
            class="{{'checkout-date-' + inputName}} checkout form-control ps-0"
            [ngClass]="{'ng-invalid ng-touched ng-dirty': isError,'no-padding' : removePadding}"
            [value]="toDisplay"
            (click)="showFullScreenModal = true"
            readonly
          >
          <label for="toInputMobile" *ngIf="displayLabel" class="ps-0">
            {{ placeholderEnd }}
          </label>
        </div>
      </div>
    </div><!-- /.checkout-container -->
  </div>

  <!-- Mobile Modal DatePicker -->
  <full-screen-modal [showModal]="showFullScreenModal" [forceHideNav]="true" (modalClosed)="modalClosed()">
    <section body class="body-container">
      <div class="datepicker-container" [ngClass]="{'datepicker-single-input': singleInput, 'datepicker-multi-input': !singleInput, 'narrow': narrowWidth, 'datepicker-double': !singleDate}">
        <div class="checking-container" [ngClass]="{'input-contains-icon': showCalendarIcon}">
          <label *ngIf="startLabel.length">{{ startLabel }}</label>
          <div #checkinContainer class="datepicker-checkin position-relative datepicker-{{placement}}" [class.pseudoFocus]="fromSelectionInProgress">
            <div class="d-flex align-items-center">
              <fa-icon [icon]="icons.faCalendarAlt" *ngIf="showCalendarIcon && !showCalendarIconRight" class="date-input-icon input-icon"></fa-icon>
              <input
                #fromInputEl
                class="{{inputName}} checkin form-control"
                [ngClass]="{'ng-invalid ng-touched ng-dirty': isError}"
                [placeholder]="placeholderStart"
                name="{{inputName}}"
                [value]="fromDisplay"
                readonly
              >
              <fa-icon [icon]="icons.faCalendarAlt" *ngIf="showCalendarIcon && showCalendarIconRight" class="date-input-icon input-icon"></fa-icon>
            </div>
          </div>
        </div><!-- /.checking-container -->
        <div *ngIf="showSeparator">
          <label *ngIf="startLabel.length || endLabel.length">&nbsp;</label>
          <fa-icon *ngIf="!singleDate" [icon]="icons.faArrowRight" class="datepicker-separator"></fa-icon>
        </div>
        <div *ngIf="!singleDate" class="checkout-container" [ngClass]="{'input-contains-icon': showCalendarIcon}">
          <label *ngIf="endLabel.length">{{ endLabel }}</label>
          <div class="datepicker-checkout" [class.pseudoFocus]="toSelectionInProgress">
            <div class="d-flex align-items-center">
              <fa-icon [icon]="icons.faCalendarAlt" *ngIf="showCalendarIcon"
                class="fa fa-calendar-alt date-input-icon input-icon"></fa-icon>
              <input #toInputEl class="{{'checkout-date-' + inputName}} form-control checkout"
                [ngClass]="{'ng-invalid ng-touched ng-dirty': isError}" [placeholder]="placeholderEnd" [value]="toDisplay"
                readonly>
            </div>
          </div>
        </div><!-- /.checkout-container -->
      </div>
      <br>
      <ngb-datepicker
        *ngIf="showFullScreenModal"
        #datepicker
        class="w-100"
        [dayTemplate]="dayTemplate"
        [displayMonths]="displayMonths"
        [minDate]="minNgbDate"
        [maxDate]="maxNgbDate"
        [navigation]="navigation"
        [startDate]="fromDate"
        [outsideDays]="outsideDays"
        format="MM/dd/yyyy">
      </ngb-datepicker>
      <section class="footer-container">
        <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
      </section>
    </section>
    <section footer *ngIf="showFullScreenModal" class="p-3">
        <button class="btn btn-sm btn-primary w-100 text-uppercase fw-normal apply-button" type="button" (click)="apply()">
          {{ 'mobile.calendar.apply' | translate }}
        </button>
    </section>
  </full-screen-modal>
</div>

<!-- Desktop DatePicker Toggle -->
<div class="datepicker-container rounded"
  [ngClass]="{'d-none d-md-flex': showModalForMobile, 'datepicker-single-input': singleInput, 'datepicker-multi-input': !singleInput, 'narrow': narrowWidth, 'datepicker-double': !singleDate}">
  <div class="checking-container" [ngClass]="{'input-contains-icon': showCalendarIcon}">
    <label *ngIf="startLabel.length">{{ startLabel }}</label>
    <div #checkinContainer class="position-relative datepicker-checkin datepicker-{{placement}} d-flex" [class.pseudoFocus]="fromSelectionInProgress">
      <fa-icon [icon]="icons.faCalendarAlt" *ngIf="showCalendarIcon && !showCalendarIconRight" class="date-input-icon input-icon" (click)="toggleDatePicker()"></fa-icon>
      <div class="flex-grow-1" [ngClass]="{ 'form-floating': displayLabel }">
        <input
          #fromInputEl
          id="fromInput"
          class="{{inputName}} checkin form-control"
          [ngClass]="{'ng-invalid ng-touched ng-dirty': isError ,'no-padding' : removePadding}"
          [firstDayOfWeek]="7"
          name="{{inputName}}"
          [value]="fromDisplay"
          (click)="toggleDatePicker()"
          (input)="onDateInput($event)"
          [readonly]="!disabledModifyDate"
          ngbDatepicker #datepicker="ngbDatepicker"
          [footerTemplate]="footerTemplate"
          [dayTemplate]="dayTemplate"
          [displayMonths]="displayMonths"
          [minDate]="minNgbDate"
          [maxDate]="maxNgbDate"
          [navigation]="navigation"
          [placement]="placement"
          [outsideDays]="outsideDays"
          [autoClose]="autoClose"
          [datepickerClass]="datepickerClass"
          (closed)="onClosed()"
          format="MM/dd/yyyy"
          >
        <label *ngIf="displayLabel"
          for="fromInput">
          {{ placeholderStart }}
        </label>
      </div>
      <fa-icon [icon]="icons.faCalendarAlt" *ngIf="showCalendarIcon && showCalendarIconRight" class="fa fa-calendar-alt date-input-icon input-icon ps-0" (click)="toggleDatePicker()"></fa-icon>
    </div>
  </div><!-- /.checking-container -->
  <div *ngIf="showSeparator">
    <label *ngIf="startLabel.length || endLabel.length">&nbsp;</label>
    <fa-icon *ngIf="!singleDate" [icon]="icons.faArrowRight" class="datepicker-separator"></fa-icon>
  </div>
  <div *ngIf="!singleDate" class="checkout-container" [ngClass]="{'input-contains-icon': showCalendarIcon}">
    <label *ngIf="endLabel.length">{{ endLabel }}</label>
    <div class="datepicker-checkout d-flex" [class.pseudoFocus]="toSelectionInProgress">
      <fa-icon [icon]="icons.faCalendarAlt" *ngIf="showCalendarIcon" class="fa fa-calendar-alt date-input-icon input-icon" (click)="toggleDatePicker('to')"></fa-icon>
      <div class="flex-grow-1" [ngClass]="{ 'form-floating': displayLabel }">
        <input
          #toInputEl
          id="toInput"
          class="{{'checkout-date-' + inputName}} checkout form-control"
          [ngClass]="{'ng-invalid ng-touched ng-dirty': isError, 'no-padding' : removePadding}"
          [value]="toDisplay"
          (click)="toggleDatePicker('to')"
          readonly
        >
        <label
          *ngIf="displayLabel"
          for="toInput">
          {{ placeholderEnd }}
        </label>
      </div>
    </div>
  </div><!-- /.checkout-container -->
</div>

<ng-template #dayTemplate let-date="date" let-data="data" let-focused="focused" let-disabled="disabled">
    <div class="custom-day-container" (click)="onDateSelection($event, date)" (mouseenter)="hoveredDate = date"
      (mouseleave)="hoveredDate = null" [class.range]="isRange(date)" [class.start-date]="isFrom(date)"
      [class.single-date]="isSingleDate(date)" [class.end-date]="isTo(date)" [class.text-muted]="disabled">
      <div class="custom-day">
        <span class="today-tag">{{ 'lodging.hotels.demand_calendar.today' | translate }}</span>
        <span>
          {{ date.day }}
        </span>
        <div class="circle" *ngIf="demandCalendar.length && showDemandCalendar" [ngClass]="date | demandCalendar: demandCalendar"></div>
      </div>
    </div>
</ng-template>

<ng-template #footerTemplate>
  <div class="datepicker-footer">
    <div class="datepicker-popup-footer" *ngIf="demandCalendar.length && showDemandCalendar">
      <div class="demand-clarification-container">
        <div class="demand-badges">
          <span class="badge cheaper">{{ 'lodging.hotels.demand_calendar.demand.cheaper' | translate }}</span>
          <span class="badge average">{{ 'lodging.hotels.demand_calendar.demand.average' | translate }}</span>
          <span class="badge higher">{{ 'lodging.hotels.demand_calendar.demand.higher' | translate }}</span>
        </div>
        <div class="text-muted">{{'lodging.hotels.demand_calendar.demand.based_on_most_frequent_searches' | translate }}
        </div>
      </div>
    </div>
    <div class="footer-buttons" *ngIf="!showFullScreenModal && (displayMonths > 1 || showApplyButton)" [class.justify-content-between]="enableClearAction">
      <button *ngIf="enableClearAction" (click)="reset()" type="button" class="btn primary-color float-start">{{
        'mobile.calendar.reset' | translate }}</button>
      <button (click)="apply()" type="button" class="btn btn-outline-primary float-end">{{ 'mobile.calendar.apply' |
        translate }}</button>
    </div>
  </div>
</ng-template>
