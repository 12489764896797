<div *ngIf="!showloading">
  <!-- DESKTOP VERSION -->
  <li *ngIf="!forMobile && selectedCurrency && show" display="dynamic" class="nav-item currency-selector">
    <a class="nav-link black-text gtm-nav" href="javascript:void(0)" [ngbPopover]="popContent" placement="bottom"
      popoverClass="currency-selector">
      <span class="currency-name">{{ selectedCurrency | uppercase }}  {{ getCurrencySym(selectedCurrency) }}</span>
    </a>
    <ng-template #popContent>
      <ul class="ps-0">
        <ng-container *ngFor="let currency of ALL_CURRENCYS">
          <li *ngIf="currency !== selectedCurrency" (click)="selectCurrency(currency)">
            <span> {{ currency }} {{ getCurrencySym(currency) }}
            </span>
          </li>
        </ng-container>
      </ul>
    </ng-template>
  </li>
  <!-- MOBILE VERSION -->
  <div *ngIf="forMobile" class="currency-selector-mobile">
    <ul class="navbar-nav collapsible collapsible-accordion">
      <li class="nav-item">
        <a class="nav-link black-text" href="javascript:void(0)" (click)="isCollapsed = !isCollapsed">
          {{ 'mobile.profile.currency' | translate }} <span class="float-end p-1"> {{ selectedCurrency }}</span>
        </a>
      </li>
      <div [ngbCollapse]="isCollapsed" class="container-mobile">
        <ng-container *ngFor="let currency of ALL_CURRENCYS">
          <li (click)="selectCurrency(currency)" class="nav-item float-start p-1" *ngIf="currency !== selectedCurrency">
            <a class="nav-link black-text" href="javascript:void(0)">
              {{ currency }}
            </a>
          </li>
        </ng-container>
      </div>
    </ul>
  </div>
</div>
<div *ngIf="showloading && !forMobile">
  <!-- DESKTOP VERSION -->
  <li>
    <a>
      <div class="container">
        <div class="row d-flex">
          <div class="col justify-content-center">
            <fa-icon [icon]="icons.faSpinner" class="float-end fa-spin p-1 black-text" ></fa-icon>
          </div>
        </div>
      </div>
    </a>
  </li>
</div>
<div *ngIf="showloading && forMobile">
    <!-- MOBILE VERSION -->
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link black-text" href="javascript:void(0)" (click)="isCollapsed = !isCollapsed" [attr.disabled]="true">
        {{ 'mobile.profile.currency' | translate }}  <fa-icon [icon]="icons.faSpinner" class=" float-end fa-spin p-1" ></fa-icon>
      </a>
    </li>
  </ul>
</div>
