import { Component, Input, OnChanges, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons';
import { select, Store } from '@ngrx/store';
import { ThirdPartyActions } from 'app/auth/store/actions';
import * as fromAuth from 'app/auth/store/reducers';
import { ThirdPartiesFactory } from 'app/auth/strategies/third-parties.factory';
import { Socials } from 'app/profile/models/third-party';
import { ProfileHelper } from 'app/shared/helpers/profile.helper';
import { featureFlags } from 'app/shared/models/featureFlags';
import { Modal } from 'app/shared/models/modal.model';
import { FeatureFlagsService } from 'app/shared/services/featureFlags/featureFlags.service';
import { ModalActions } from 'app/shared/store/actions';
import * as fromShared from 'app/shared/store/reducers';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'link-social-account',
  templateUrl: './link-social-account.html',
  styleUrls: ['./link-social-account.scss'],
  encapsulation: ViewEncapsulation.None
})

export class LinkSocialAccountComponent implements OnInit, OnChanges, OnDestroy {
  @Input() profileForm;
  @Input() requestMFA = false;
  @Input() userStatistics;
  @Input() userInfo;
  featureFlags$ = this.store.pipe(select(fromShared.getFeatureFlags));
  featureFlagsLoading$ = this.store.pipe(select(fromShared.getFeatureFlagsLoading));
  thirdPartyPending$ = this.store.pipe(select(fromAuth.getThirdPartyPending));
  loading = false;
  accountLinked = false;
  progressBarValue = 0;
  completedStyle = 'warning';
  profileSteps = {
    firstName: { translation: 'profile.social_account.account_linked.first_last_name', completed: false },
    homePhone: { translation: 'profile.social_account.account_linked.phone', completed: false },
    address: { translation: 'profile.social_account.account_linked.address', completed: false },
    invite_friend: { translation: 'profile.social_account.account_linked.invite_friend', completed: false }
  };
  private ngUnsubscribe = new Subject<void>();
  socials = Socials;
  icons = { faCheckCircle, faCircle };
  showFacebook: boolean = true;
  showApple: boolean = true;
  showGoogle: boolean = true;
  private appConfig$ = this.store.pipe(select(fromShared.getConfigFlag));


  constructor(
    private store: Store<fromAuth.State>,
    private profileHelper: ProfileHelper,
    private thirdPartiesFactory: ThirdPartiesFactory,
    private featureFlagsService: FeatureFlagsService
  ) {}

  ngOnInit() {
    combineLatest([this.thirdPartyPending$, this.featureFlagsLoading$]).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((val) => {
      this.loading = val[0] || val[1];
    });
    this.loadProgressAccount();
    this.loadAppConfig();
  }

  ngOnChanges(changes) {
    if (changes.profileForm && changes.profileForm.currentValue) {
      this.loadProgressAccount();
    }
  }

  loadProgressAccount() {
    this.featureFlags$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((flags) => {
      if (flags) {
        if (flags.includes(featureFlags.FACEBOOK) || flags.includes(featureFlags.GOOGLE)) {
          this.accountLinked = true;
          this.loading = false;
          this.updateProgressBar();
        }
      }
    });
  }

  updateProgressBar() {
    Object.values(this.profileSteps).map(profileStep => profileStep.completed = false);
    const member = this.profileHelper.getMemberFromForm(this.profileForm);
    if (member.firstName && member.lastName) {
      this.profileSteps.firstName.completed = true;
    }
    if (member.homePhone) {
      this.profileSteps.homePhone.completed = true;
    }
    if (member.address)  {
      this.profileSteps.address.completed = true;
    }
    if (this.userStatistics && this.userStatistics.total_invited_users > 0) {
      this.profileSteps.invite_friend.completed = true;
    }
    const completed = Object.keys(this.profileSteps).filter((key, value) => {
      return this.profileSteps[key].completed;
    }).length;
    this.progressBarValue = ((completed  + 1) * 100) / (Object.keys(this.profileSteps).length + 1);
    this.completedStyle = this.progressBarValue === 100 ? 'success' : 'warning';
  }

  async connectAccount(platform) {
    if (platform !== Socials.FACEBOOK && platform !== Socials.APPLE ) {
      return this.store.dispatch(new ThirdPartyActions.ConnectThirdParty({
        platform: Socials.GOOGLE,
        showModalError: false,
        authToken: platform,
        userInfo: this.userInfo
      }));
    }
    try {
      const platformProvider = this.thirdPartiesFactory.getProvider(platform);
      const service = this.thirdPartiesFactory.getService(platform);
      const user = await service.signIn(platformProvider);
      const token = this.thirdPartiesFactory.getToken(user, platform);
      this.store.dispatch(new ThirdPartyActions.ConnectThirdParty({
        platform,
        authToken: token,
        userInfo: this.userInfo,
        showModalError: true
      }));
    } catch (e) {
      this.showMessage(e.error);
    }
  }

  showMessage(error: any) {
    const data: Modal = {
      title: 'errors.error',
      bodyTranslationKey: error && error.error_description ? error.error_description : 'errors.try_again_later',
      modalOptions: { size: 'md' }
    };
    this.store.dispatch(new ModalActions.Show({ data }));
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private loadAppConfig() {
    this.appConfig$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((appConfig) => {
      if (!appConfig) return
      const isThirdPartyLoginEnabled = this.featureFlagsService.isFeatureEnabled(featureFlags.THIRD_PARTY_LOGIN);
      this.showApple = appConfig?.login_apple_enabled === '1' && isThirdPartyLoginEnabled;
      this.showFacebook = appConfig?.login_facebook_enabled === '1' && isThirdPartyLoginEnabled;
      this.showGoogle = appConfig?.login_google_enabled === '1' && isThirdPartyLoginEnabled;
    });
  }
}
