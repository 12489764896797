<div *ngIf="loading" class="row justify-content-center mt-4">
  <loading-indicator [fullScreen]="false"></loading-indicator>
</div>
<div class="card" *ngIf="!loading">
  <button type="button" class="btn-close dismiss-button" aria-label="Close" (click)="dismiss()">

  </button>
  <img class="img-fluid destination-image" [offset]="100" [defaultImage]="defaultImage" [lazyLoad]="hotelImage" />
  <ngb-alert *ngIf="this.error.length > 0" type="danger" (closed)="closeAlert()">{{ error }}</ngb-alert>
  <div class="card-body">
    <ng-container *ngIf="this.success.length > 0">
      <div class="primary-content text-center">
        <fa-icon [icon]="icons.faCheckCircle" class="success-icon"></fa-icon>
        <h2> {{ success | translate }} </h2>
      </div>
    </ng-container>
    <ng-container *ngIf="this.success === ''">
      <div class="primary-content text-center"> {{ 'reviews.title' | translate }} </div>
      <div class="secondary-content text-center mt-2"> {{ itineraryData.hotel_name }} </div>
      <div class="tertiary-content text-center"> {{ itineraryData.hotel_address }}</div>
      <div class="text-center mb-2"> {{ startDate }} - {{ endDate }} </div>
      <form [formGroup]="hotelReviewForm">
        <div class="row thumbs-section mt-1 mb-1">
          <div class="col-6 text-center thumbs-feedback" (click)="setThumbUp()">
            <fa-icon [icon]="icons.faThumbsUp" *ngIf="!thumbUp" class="thumbs-icon"></fa-icon>
            <fa-icon [icon]="icons.faThumbsUp" *ngIf="thumbUp" class="thumbs-icon selected"></fa-icon>
            <div>{{ 'reviews.liked' | translate}}</div>
          </div>
          <div class="col-6 text-center thumbs-feedback" (click)="setThumbDown()">
            <fa-icon [icon]="icons.faThumbsDown" *ngIf="!thumbDown" class="thumbs-icon"></fa-icon>
            <fa-icon [icon]="icons.faThumbsDown" *ngIf="thumbDown" class="thumbs-icon selected"></fa-icon>
            <div>{{ 'reviews.disliked' | translate}}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <!-- <label for="description"></label> -->
              <textarea formControlName="reviewDescription" maxlength={{this.maxLength}} id="reviewDescription" class="form-control" rows="4"
                placeholder="{{ 'reviews.text' | translate }}"></textarea>
                <div class="char-counter">{{this.counterChar}}/{{this.maxLength}}</div>
              <div class='error-message' *ngIf="reviewDescription.errors !== null && (reviewDescription.touched)"
                [innerHtml]="formErrorsHelper.errorMessage(reviewDescription, ('flights.requests.comments' | translate))">
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center" *ngIf="!disableForm">
            <button type="button" *ngIf="!loading" class="btn btn-md btn-primary btn-lg gtm-submit-hotel-view"
              (click)="sendReview()">
              {{ 'reviews.submit' | translate }}
            </button>
          </div>
        </div>
      </form>
    </ng-container>
  </div>
</div>
