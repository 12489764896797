import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { User } from 'app/auth/models/user';
import * as fromAuth from 'app/auth/store/reducers';
import * as fromRoot from 'reducers';
import { map, tap, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class Id90UserGuard  {
  user: User;

  constructor(
    public router: Router,
    private store: Store<fromRoot.State>
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.select<any>(fromAuth.getUser).pipe(
      take(1),
      map((user) => {
        this.user = user;
        if (user.member.member_id && !this.isId90User(this.user.tools)) {
          return false;
        }

        return true;
      }),
      tap(() => {
        if (!this.isId90User(this.user.tools)) {
          const snapshot = this.router.routerState.snapshot.url;
          this.router.navigate(['/login'], { queryParams: { redirect: encodeURIComponent(snapshot) } });
        }
      })
    );
  }

  isId90User(tools) {
    return tools && tools.includes('id90');
  }
}
