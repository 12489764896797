import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export abstract class GeocodingService {
  abstract getLocations(term: string, type: string): Observable<GeocoderLocation[]>;
  abstract getLocationCoords(place: any): Observable<any>;
  abstract getLocationDetails(place: any): Observable<GeocoderAddress>;
  abstract clearSessionToken(): void;
}

export interface GeocoderAddress {
  city: string;
  country: string;
  state: string;
  street: string;
  zip: string;
}

export interface GeocoderLocation {
  displayName: string;
  magicKey?: string;
  type?: string;
}
