import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { CurrentLocationActions } from 'app/shared/store/actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { LocationsService } from 'app/shared/services/locations/locations.service';

@Injectable()
export class CurrentLocationEffects {

  load$ = createEffect(()=>
    this.actions$.pipe(
      ofType<CurrentLocationActions.Load>(CurrentLocationActions.CurrentLocationActionTypes.Load),
      switchMap(() =>
        this.locationService.getBrowserLocation().pipe(
          map(position => new CurrentLocationActions.LoadSuccess({ currentLocation: position })),
          catchError(error => of(new CurrentLocationActions.LoadFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private locationService: LocationsService
  ) {}
}
