import { Action } from '@ngrx/store';
import { HotelsRecentView } from 'app/shared/models/hotels-recent-view';

export enum HotelsRecentViewsApiActionTypes {
  LoadSuccess = '[Hotels Recent Views/API] Load Success',
  LoadFailure = '[Hotels Recent Views/API] Load Failure',
}

export class LoadSuccess implements Action {
  readonly type = HotelsRecentViewsApiActionTypes.LoadSuccess;

  constructor(public payload: { destinations: HotelsRecentView[] }) {}
}

export class LoadFailure implements Action {
  readonly type = HotelsRecentViewsApiActionTypes.LoadFailure;

  constructor(public payload: { error: any }) {}
}

export type HotelsRecentViewsApiActionsUnion = LoadSuccess
  | LoadFailure;
