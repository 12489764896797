import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Id90UserGuard } from 'app/auth/store/guards/id90user.guard';
import { LoggedUserGuard } from 'app/auth/store/guards/logged-user.guard';
import { VerifiedUserGuard } from 'app/auth/store/guards/verified-user.guard';
import { ProfilePage } from './containers/profile/profile.page';

const routes: Routes = [
  {
    path: '',
    component: ProfilePage,
    pathMatch: 'full',
    canActivate: [LoggedUserGuard, VerifiedUserGuard],
    data: { title: 'Profile' }
  },
  {
    path: ':tab/:section',
    component: ProfilePage,
    pathMatch: 'full',
    canActivate: [LoggedUserGuard, VerifiedUserGuard, Id90UserGuard],
    data: { title: 'Profile', snapshotParams: true }
  },
  {
    path: ':tab',
    component: ProfilePage,
    pathMatch: 'full',
    canActivate: [LoggedUserGuard, VerifiedUserGuard, Id90UserGuard],
    data: { title: 'Profile', snapshotParams: true }
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProfileRoutingModule {}
