import { Component, OnInit } from '@angular/core';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';
import * as fromAirlines from 'app/shared/store/reducers';
import { skipWhile } from 'rxjs/operators';
import { AirlinesService } from 'app/shared/services/airlines/airlines.service';
import { select, Store } from '@ngrx/store';

@Component({
  selector: 'contact-us',
  templateUrl: './contact-us.html',
  styleUrls: ['./contact-us.scss']
})
export class ContactUsPage implements OnInit {
  loading = true;
  isWhiteLabel = false;
  airlines$ = this.store.pipe(select(fromAirlines.getAirlines));

  constructor(
    public formErrorsHelper: FormErrorsHelper,
    private airlinesService: AirlinesService,
    private store: Store
  ) { }

  ngOnInit() {
    this.airlines$.pipe(skipWhile(airlines => !airlines)).subscribe((airlines) => {
      this.loading = false;
      this.isWhiteLabel = !!this.airlinesService.getWhiteLabelAirlineCode(airlines);
    });
  }
}
