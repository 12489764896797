<div class="card clickable-card my-2" id="gtm-{{type}}" (click)="onClicked()">
  <img class="destination-image" id="gtm-{{type}}" [lazyLoad]="image" [offset]="10" [defaultImage]="defaultImage"/>
  <div class="card-body" id="gtm-{{type}}">
    <div class="primary-content gtm-{{type}}" [ngClass]="{'primary-color': isAbandonedHotel || isNeedARoom}">{{title}}</div>
    <div *ngIf="destination" class="secondary-content" id="gtm-{{type}}">{{destination}}</div>
    <div *ngIf="dates" class="tertiary-content mb-1" id="gtm-{{type}}">{{dates.checkin}} - {{dates.checkout}}</div>
    <div *ngIf="tertiaryContent" class="tertiary-content" id="gtm-{{type}}">{{tertiaryContent}}</div>
    <div *ngIf="isAbandonedHotel">
      <hotel-pricing [displayRate]="hotelNightRate" [totalRate]="hotelTotalCost" [boldRate]="true" [tooltip]="false" />
    </div>
  </div>
</div>
