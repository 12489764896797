import { Component, Input, OnInit } from '@angular/core';
import { TrendingDestinationsService } from 'app/shared/services/trending-destinations/trending-destinations.service';
import { flatten, reverse, sortBy, map } from 'lodash';
import { take, withLatestFrom } from 'rxjs/operators';
import { faMagnifyingGlass, faCircle, faCaretRight, faCaretLeft, faXmark } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SlugifyHelper } from 'app/shared/helpers/slugify.helper';
import { select, Store } from '@ngrx/store';
import * as fromShared from 'app/shared/store/reducers';
import * as fromRoot from 'reducers';
import { AllInclusiveActions } from 'app/shared/store/actions';

@Component({
  selector: 'all-inclusive-search',
  templateUrl: './all-inclusive.component.html',
  styleUrls: ['./all-inclusive.component.scss']
})
export class AllInclusiveComponent implements OnInit {
  @Input() defaultImage = '/angular_assets/images/common/default-city.jpg';

  allInclusiveDestinations$ = this.store.pipe(select(fromShared.getAllInclusiveDestinations));
  source: any[] = [[]];
  items: any[] = [];
  icons = { faMagnifyingGlass, faCircle, faCaretRight, faCaretLeft, faXmark };
  currentIndexPage = 0;
  loading = true;

  constructor(
    private trendingDestinationsService: TrendingDestinationsService,
    private activeModal: NgbActiveModal,
    private slugifyHelper: SlugifyHelper,
    private store: Store<fromRoot.State>,
    ) { }

  ngOnInit(): void {


    this.trendingDestinationsService.getAllInclusiveLocations().pipe(
      take(1), withLatestFrom(this.allInclusiveDestinations$)
      ).subscribe(([data, allInclusive]) => {
      
      this.loading = false;

      const mainItems = allInclusive.map(item => item.destinations).reduce((array, subArray) => {
        return array.concat(subArray);
      }, [])

      const secondaryItems = reverse(sortBy(flatten(data.map((d) =>(d.destinations))), ai => ai.count)); 
      
      this.items = this.setupImages(mainItems, secondaryItems);
      
      this.setupPages(this.items);
    });
  }


  setupImages(images: any[], destinations: any[]): any[] {
    const itemsWithImages = [];
    const itemsWithoutImages = [];

    for (const destination of destinations) {
      const aux = images.find(element => {
        return this.slugifyHelper.slugify(element.destination_name).toLowerCase() === this.slugifyHelper.slugify(destination.name).toLowerCase()
      });
      if (aux) {
        itemsWithImages.push({
          ...destination,
          destination_image_home: aux.destination_image_home
        });
      } else {
        itemsWithoutImages.push(destination)
      }
    }

    return [...itemsWithImages, ...itemsWithoutImages];
  }

  setupPages(items: any[]): void {
    const aux = [];
      const pageNumber = Number.parseInt((items.length / 12).toFixed(0));
    for (let i = 0; i <= pageNumber; i++) {
      const page = items.slice(i * 12, (i + 1) * 12);
      aux.push(page)
    }
    this.source = aux.length ? aux : [[]];
  }

  updatePageSelected(index: number): void {
    this.currentIndexPage = index;
  }

  nextPage(): void {
    if (this.currentIndexPage < (this.source.length - 1)) {
      this.currentIndexPage++;
    } else {
      this.currentIndexPage = 0;
    }
  }

  previousPage(): void {
    if (this.currentIndexPage !== 0) {
      this.currentIndexPage--;
    } else {
      this.currentIndexPage = 0;
    }
  }

  destinationChanged(value): void {
    this.currentIndexPage = 0;
    const keys = value.split(' ').filter(v => v.trim() !== '').map(item => this.slugifyHelper.slugify(item).toLowerCase());
    const itemsFiltered = this.items.filter(item =>
      keys.every(key => {
        const name = this.slugifyHelper.slugify(item.destination_name ? item.destination_name : item.name);
        return name.toLowerCase().includes(key)})
    );
    this.setupPages(itemsFiltered);
  }

  closeModal(): void {
    this.activeModal.dismiss();
  }

  destinationSelected(item): void {
    this.activeModal.close(item);
  }
}
