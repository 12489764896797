import { Action } from '@ngrx/store';
import { HotelWatchlist } from 'app/profile/models/hotel-watchlist';

export enum HotelWatchlistActionTypes {
  Get = '[Hotel Watchlist] Get',
  Add = '[Hotel Watchlist] Add',
  Remove = '[Hotel Watchlist] Remove'
}

export class Get implements Action {
  readonly type = HotelWatchlistActionTypes.Get;

  constructor() {}
}

export class Add implements Action {
  readonly type = HotelWatchlistActionTypes.Add;

  constructor(public payload: { watchlist: HotelWatchlist}) {}
}
export class Remove implements Action {
  readonly type = HotelWatchlistActionTypes.Remove;

  constructor(public payload: { watchlist: any }) {}
}

export type HotelWatchlistActionsUnion = Get | Add
  | Remove;
