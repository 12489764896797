import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { DemandCalendarRequest } from 'app/hotels/models/demand-calendar';
import { DemandCalendarActions } from 'app/hotels/store/actions';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DemandCalendarService {

  constructor (
    private http: HttpClient,
    private store: Store
  ) {}

  get(latitude: number, longitude: number) {
    return this.http.get(`${environment.apiUrl}/api/pricing-insights/demand-calendar?lat=${latitude}&lng=${longitude}`);
  }

  getDemandCalendar({latitude, longitude}: DemandCalendarRequest) {
    this.store.dispatch(new DemandCalendarActions.GetDemandCalendar({latitude, longitude}));
  }

  clearDemandCalendar() {
    this.store.dispatch(new DemandCalendarActions.ClearDemandCalendar());
  }
}
