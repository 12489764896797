import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ConfigFlags } from '../models/configFlags';

@Injectable()

export class SamlHelper {
  samlUrl:string = '/sso/new?desktop_request=true&airline=';
  browser = null;

  constructor () {}

  inSamlLogin(airline, config: ConfigFlags) {
    const samlAirlines = config?.saml_available_airlines?.split(' ') ?? [];
    return samlAirlines.includes(airline.toUpperCase());
  }

  login(airline) {
    window.location.href =  environment.apiUrl + this.samlUrl + airline.toUpperCase();
  }
}
