import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }

  transform(value: any, type: string = 'url') {
    let sanitized;
    switch (type) {
      case 'url':
        sanitized = this.sanitizer.bypassSecurityTrustResourceUrl(value);
        break;

      case 'html':
        sanitized = this.sanitizer.bypassSecurityTrustHtml(value);
        break;

      default:
        sanitized = value;
        break;
    }
    return sanitized;
  }
}
