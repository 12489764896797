import { Action } from '@ngrx/store';

export enum CountryActionTypes {
  LoadCountries = '[Countries] Load Countries',
  LoadCountriesSuccess = '[Countries] Load Countries Success',
  LoadCountriesFailure = '[Countries] Load Countries Failure',
}

export class LoadCountries implements Action {
  readonly type = CountryActionTypes.LoadCountries;
}

export class LoadCountriesSuccess implements Action {
  readonly type = CountryActionTypes.LoadCountriesSuccess;

  constructor(public payload: { countries: any }) {}
}

export class LoadCountriesFailure implements Action {

  readonly type = CountryActionTypes.LoadCountriesFailure;

  constructor(public payload: { error: any }) {}
}

export type CountryActionsUnion = LoadCountries
  | LoadCountriesSuccess
  | LoadCountriesFailure;
