import { Component, Input, ViewChild, ElementRef, OnInit } from '@angular/core';
import { NeedARoomWidgetService } from 'app/shared/services/need-a-room-widget/need-a-room-widget.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'need-a-room-widget',
  styleUrls: ['./need-a-room-widget.scss'],
  templateUrl: './need-a-room-widget.html'
})

export class NeedARoomWidgetComponent implements OnInit {

  @Input() destination;
  @Input() checkinDate;
  @Input() checkoutDate;
  @Input() user;
  @ViewChild('needARoom', { static: false }) widget: ElementRef;

  constructor(
    private needARoomWidgetService: NeedARoomWidgetService
  ) {}

  ngOnInit() {
    const widgetParams = {
      destination: this.destination,
      checkin_date: this.checkinDate,
      checkout_date: this.checkoutDate,
      first_name: this.user.member.first_name,
      last_name: this.user.member.last_name
    };
    this.needARoomWidgetService.getContent(widgetParams).pipe(take(1)).subscribe((results) => {
      const hasHotels = results.includes('class="hotels"');
      if (!hasHotels) {
        this.widget.nativeElement.innerHTML = '';
        return;
      }
      this.widget.nativeElement.innerHTML = results;
    });
  }
}
