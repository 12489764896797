export interface GamificationCampaignModel {
  id: number;
  name: string;
  description: string;
  audience: string;
  config: GamificationCampaignConfig[];
  require_participation: boolean;
  finished: boolean;
  redeemable: boolean;
  free_entry: boolean;
  user_participating: boolean | null;
}

export interface GamificationCampaignConfig {
  image: string;
  containerHTML?: string;
  animation: string;
  destination: any;
  cssStyle: string;
  animateConfig: {
    style: any;
    width: string;
    height: string;
    animate: {
      duration: string;
      keyframe: any[];
      style: any;
    }
  } | any;
  mode: 'css' | 'angularAnimate';
}

export interface GamificationTransactionModel {
  values: GamificationHotelValues;
  section: GamificationTransactionSection;
  type: GamificationTransactionType;
  campaign_id: number;
  itinerary_id?: any;
}

export enum GamificationTransactionType {
  Booking = 'booking',
  GoalMet = 'goal_met',
  FreeEntry = 'free_entry'
}

export enum GamificationTransactionSection {
  Cars = 'cars',
  Flights = 'flights',
  Hotels = 'hotels'
}

export interface GamificationHotelValues {
  nights: number;
  start_date: string;
  end_date: string;
  refundable: boolean;
}
