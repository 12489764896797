<ng-container *ngIf="!isHeader">
  <loading-indicator *ngIf="loading" [fullScreen]="false"></loading-indicator>
  <ng-container *ngIf="!loading">
    <div class="position-relative text-center avatar-height">
      <fa-icon *ngIf="shouldDisplayRemove" [icon]="icons.faTimesCircle" (click)="removePhoto()" class="avatar-delete-button gtm-remove-profile-picture clickable"></fa-icon>
      <avatar-circle [type]="'profile'" [avatar]="avatarImage || null" [noavatar]="noAvatarImage || null"></avatar-circle>
    </div>
    <div class="text-center mt-1">
      <input class="d-none" #avatarInput type="file" accept=".png, .jpg, .jpeg" (change)="processAvatar($event)">
      <a href="javascript:void(0)" class="primary-color gtm-upload-profile-picture" (click)="uploadButton()">{{ 'profile.avatar.upload_photo' | translate }}</a>
      <div class="allow-size">{{ 'profile.avatar.format_allowed' | translate }}</div>
    </div>
  </ng-container>
</ng-container>
<ng-container *ngIf="isHeader">
  <avatar-circle [type]="'header'" [avatar]="avatarImage || null" [noavatar]="noAvatarImage || null"></avatar-circle>
</ng-container>
