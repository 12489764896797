import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ForgotPasswordActions } from 'app/auth/store';
import * as fromAuth from 'app/auth/store/reducers';
import * as fromShared from 'app/shared/store/reducers';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfigFlags } from '../models/configFlags';


@Injectable({
  providedIn: 'root'
})
export class AttemptsHelper {

  private appConfig$: Observable<ConfigFlags> = this.store.pipe(select(fromShared.getConfigFlag));
  private resetPwAttempts$: Observable<number> = this.store.pipe(select(fromAuth.getResetPasswordAttempts));
  blockGuestAttemptsAppConfigEnabled: boolean = false;
  blockGuestMaxAttempts: number = 0;
  minutesToAttemptAgain: number = 0;
  private ngUnsubscribe = new Subject<void>();

  constructor(
    private store: Store<fromAuth.State>
  ) {
    this.loadAppConfig();
  }

  private loadAppConfig() {
    this.appConfig$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((appConfig : ConfigFlags) => {
      if (!appConfig) return
      this.configureBlockAttempts(appConfig);
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
    });
  }

  private configureBlockAttempts(appConfig: ConfigFlags) {
    const blockGuestConfig = appConfig?.block_guest_attempts?.split('|') || ['0'];
    if (blockGuestConfig.length > 1){
      this.blockGuestMaxAttempts = +blockGuestConfig[0];
      this.minutesToAttemptAgain = +blockGuestConfig[1];
      this.blockGuestAttemptsAppConfigEnabled = this.blockGuestMaxAttempts > 0 && this.minutesToAttemptAgain > 0;
    } else {
      this.blockGuestAttemptsAppConfigEnabled = false;
    }
  }

  private generateBlockCookie() {
    localStorage.setItem('block_guest_attempts',moment().add(this.minutesToAttemptAgain,'minutes').toISOString());
    setInterval(()=> this.refreshBlockAttempts(), this.minutesToAttemptAgain * 60000);
  }

  private refreshBlockAttempts() {
    localStorage.removeItem('block_guest_attempts');
    this.store.dispatch(new ForgotPasswordActions.ResetAttempts());
  }

  public isBlockedDueToAttempts() {
    return localStorage.getItem('block_guest_attempts') &&
    localStorage.getItem('block_guest_attempts') !== null &&
    moment().isBefore(moment(localStorage.getItem('block_guest_attempts')));
  }

  public checkAmountOfAttempts() {
    this.resetPwAttempts$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((attempts : number) => {
      if (attempts >= this.blockGuestMaxAttempts){
        this.generateBlockCookie();
        this.store.dispatch(new ForgotPasswordActions.ResetAttempts());
      }
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
    });
  }
}
