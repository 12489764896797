import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { GamificationStatistics } from 'app/profile/models/gamification-statistics.interface';
import { GamificationComponent } from 'app/shared/components/gamification/gamification.component';
import { ParticipationModalComponent } from 'app/shared/components/gamification/participation-modal.component';
import { GamificationCampaignModel, GamificationTransactionSection, GamificationTransactionType } from 'app/shared/models/gamification.model';
import { AnalyticsService } from 'app/shared/services/analytics/analytics.service';
import { GamificationService } from 'app/shared/services/gamification/gamification.service';
import { GamificationActions, ModalActions } from 'app/shared/store/actions';
import { take } from 'rxjs/operators';
import { TitleCasePipe } from '@angular/common';
import { Modal } from 'app/shared/models/modal.model';
import { TranslateService } from '@ngx-translate/core';
import { CreditActions } from 'app/profile/store/actions';

@Component({
  selector: 'gamification-profile-section',
  templateUrl: './gamification-profile-section.component.html',
  styleUrls: ['./gamification-profile-section.component.scss']
})
export class GamificationProfileSectionComponent implements OnInit, OnChanges {
  @Input() activeCampaigns: GamificationCampaignModel[];
  @Input() airlines = null;
  @Input() userAccount: any = null;
  @Input() statistics: GamificationStatistics;
  @Output() onCloseGame = new EventEmitter;
  airlineName: string;
  airlineCode: string;
  isFreePlayAvailable = false;
  activeCampaign: GamificationCampaignModel;
  goalIsMet = false;

  constructor(
    private modalService: NgbModal,
    private gamificationService: GamificationService,
    private store: Store,
    private titleCasePipe: TitleCasePipe,
    private analyticsService: AnalyticsService,
    private translateService: TranslateService
    ) { }

  ngOnInit() {
    if (this.airlines?.length) {
      const airline = this.airlines.find(airline => airline.code.toLowerCase() === this.userAccount.user.member.airline_code.toLowerCase());
      this.airlineName = this.titleCasePipe.transform(airline?.display_name.split('(')[0]);
      this.airlineCode = airline?.code.toUpperCase();
    }
    this.getCampaignInfo();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activeCampaign?.currentValue?.user_participating) {
      this.store.dispatch(GamificationActions.checkFreeEntryAvailable({ campaignId: this.activeCampaign.id }));
    }
  }

  clickHandler() {
    if (this.gamificationService.canSendTransaction(this.activeCampaigns, this.statistics)) {
      return this.sendGamificationTransaction();
    }
  }

  participate() {
     if (!this.gamificationService.isUserParticipating(this.activeCampaigns)) {
      const participationModal = this.modalService.open(ParticipationModalComponent);
      participationModal.componentInstance.campaign = this.activeCampaign;
       participationModal.componentInstance.statistics = this.statistics;
       participationModal.result.then((resp) => {
        if (resp) {
          this.getCampaignInfo();
        }
      }, () => {
        this.getCampaignInfo();
      });
      return;
    }
  }

  private getCampaignInfo() {
    this.activeCampaign = this.gamificationService.getParticipatingCampaign(this.activeCampaigns);
    this.isFreePlayAvailable = this.gamificationService.hasFreeEntry(this.activeCampaigns);
    this.goalIsMet = this.gamificationService.goalIsMet(this.statistics);
  }

  private showGamificationModal() {
    const gamificationModalRef = this.modalService.open(GamificationComponent, {
      backdropClass: 'w-100 h-100 dark-backdrop',
      backdrop: 'static',
      keyboard: false
    });
    gamificationModalRef.componentInstance.userId = this.userAccount.user.account.idMembers;
    return gamificationModalRef.result.then((result) => {
      if (result) {
        this.onCloseGame.emit();
      }
    });
  }

  private sendGamificationTransaction() {
    const transaction = {
      values: {
        refundable: false,
        nights: 0,
        start_date: new Date().toISOString(),
        end_date: new Date().toISOString(),
      },
      section: GamificationTransactionSection.Hotels,
      type: GamificationTransactionType.FreeEntry,
      campaign_id: this.activeCampaign.id
    };
    return this.gamificationService.sendTransaction(transaction).pipe(take(1)).subscribe((resp: any) => {
      if (resp?.success) {
        this.analyticsService.logContent({
          content_type: `Send Transaction - Type ${transaction.type}`,
          content_id: this.activeCampaign.id
        });
        this.store.dispatch(GamificationActions.loadActiveCampaigns());
        this.store.dispatch(GamificationActions.getStatistics());
        this.isFreePlayAvailable = false;
        this.showGamificationModal();
      } else {
        this.showGamificationErrorModal(resp);
      }
    }, (resp) => {
      this.showGamificationErrorModal(resp);
    });
  }
  private showGamificationErrorModal(resp: any = null) {
    this.analyticsService.logContent({
      content_type: `Send Transaction - ERROR`,
      content_id: this.activeCampaign.id
    });
    const modalInfo = { title: 'errors.error', body: resp?.response?.message || this.translateService.instant('errors.internal_server_error_hotel_checkout') };
    const data: Modal = modalInfo;
    this.store.dispatch(new ModalActions.Show({ data }));
  }
}
