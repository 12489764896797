<ng-container *ngIf="!showConfirmation; else confirmationContent">
  <form *ngIf="campaign" [formGroup]="participationForm" (ngSubmit)="participate()">
    <div class="modal-header">
      <div class="header-content">
        <img class="airline-logo-header " src="angular_assets/images/flights/partners/{{ airlineCode }}_header_logo.png" (error)="'angular_assets/images/color-logo.png'">
        <h4 class="modal-title" id="modal-basic-title">{{ 'gamification.hotel_booking_challenge.title' | translate }}</h4>
      </div>
      <button type="button" class="btn-close" aria-label="Close" (click)="close()">

      </button>
    </div>
    <div class="modal-body">
      <div
        class="secondary-content">{{ 'gamification.hotel_booking_challenge.description' | translate: { number_nights: statistics?.metadata?.target } }}</div>
      <div class="mt-3 text-center">
        <img src="angular_assets/images/hotels/hotel_gamification.svg" width="60%">
      </div>
    </div>
    <div class="modal-footer">
      <div class="form-group form-check text-center">
        <input type="checkbox" class="form-check-input" id="accept_terms" name="accept_terms"
               formControlName="accept_terms">
        <label class="form-check-label tertiary-content" for="accept_terms">
          <div>
            <a routerLink="/navigation/gamification" target="_blank">{{'gamification.accept_terms' | translate}}</a>
          </div>
        </label>
      </div>
      <div>
        <button type="submit" [disabled]="!participationForm.valid" class="btn btn-primary"
                data-cy="participateGamification">
          {{ 'gamification.participate' | translate }}
        </button>
      </div>
      <div>
        <button type="button" class="btn btn-link" (click)="abstain()" data-cy="participateGamification">
          {{ 'gamification.abstain' | translate }}
        </button>
      </div>
    </div>
  </form>
</ng-container>
<ng-template #confirmationContent>
  <div class="modal-header">
    <div class="header-content">
      <img class="airline-logo-header " src="angular_assets/images/flights/partners/{{ airlineCode }}_header_logo.png" (error)="'angular_assets/images/color-logo.png'">
      <h4 class="modal-title">{{ 'gamification.confirmation.title' | translate }}</h4>
    </div>
    <button type="button" class="btn-close" aria-label="Close" (click)="close()">

    </button>
  </div>
  <div class="modal-body">
    {{ 'gamification.confirmation.text' | translate: { airline: airlineName } }}
  </div>
</ng-template>
