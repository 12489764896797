import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { isPlatformBrowser } from '@angular/common';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LocationsService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private translate: TranslateService
  ) {}

  getBrowserLocation(): Observable<any> {
    return Observable.create((observer) => {
      if (isPlatformBrowser(this.platformId)) {
        navigator.geolocation.watchPosition((pos: GeolocationPosition) => {
          if (!pos) {
            observer.complete();
          }
          this.translate.get('lodging.search_form.current_location').pipe(take(1)).subscribe((name) => {
            observer.next({
              name,
              latitude: pos.coords.latitude,
              longitude: pos.coords.longitude
            });
            observer.complete();
          });
        });
      }
    });
  }
}
