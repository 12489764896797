export class Traveler {
  firstName = '';
  lastName = '';
  dateOfBirth = '';
  email = '';
  passengerId = '';
  passengerHash = '';
  formValid = true;

  constructor() { }

  toJson() {
    return {
      first_name: this.firstName,
      last_name: this.lastName,
      email: this.email,
      birth_date: this.dateOfBirth,
      passenger_id: this.passengerId,
      passenger_hash: this.passengerHash
    };
  }

  toJsonInsurance() {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      dateOfBirth: this.dateOfBirth,
      passengerId: this.passengerId,
      passengerHash: this.passengerHash,
    };
  }

  isValid() {
    return this.firstName !== '' &&
           this.lastName !== '' &&
           this.dateOfBirth !== '' &&
           this.email !== '' &&
           this.formValid;
  }
}
