import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { MultifactorAuthenticationService } from 'app/auth/services/multifactor-authentication-service/multifactor-authentication.service';
import { AuthApiActions, MultifactorAuthenticationActions } from 'app/auth/store';
import { Store } from '@ngrx/store';

@Injectable()
export class MultiFactorAuthenticationEffects {

  sendCodeByEmail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<MultifactorAuthenticationActions.SendCodeByEmail>(MultifactorAuthenticationActions.MfaActionTypes.SendCodeByEmail),
      switchMap((action) => {
        return this.mfaService.sendEmailCode(action.payload.params).pipe(
          map(response =>  new MultifactorAuthenticationActions.SendCodeSuccess({ response })),
          catchError(error => of(new MultifactorAuthenticationActions.SendCodeFailure(error)))
        );
      }));
  });

  sendCodeBySMS$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<MultifactorAuthenticationActions.SendCodeBySMS>(MultifactorAuthenticationActions.MfaActionTypes.SendCodeBySMS),
      switchMap((action) => {
        return this.mfaService.sendSMSCode(action.payload.params).pipe(
          map(response =>  new MultifactorAuthenticationActions.SendCodeSuccess({ response })),
          catchError(error => of(new MultifactorAuthenticationActions.SendCodeFailure(error)))
        );
      }));
  });

  verifyCode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<MultifactorAuthenticationActions.VerifyCode>(MultifactorAuthenticationActions.MfaActionTypes.VerifyCode),
      switchMap((action) => {
        return this.mfaService.verifyCode(action.payload.params).pipe(
          map(response => new MultifactorAuthenticationActions.VerifyCodeSuccess()),
          catchError(error => of(new MultifactorAuthenticationActions.VerifyCodeFailure(error)))
        );
      }));
  });

  enroll$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<MultifactorAuthenticationActions.Enroll>(MultifactorAuthenticationActions.MfaActionTypes.Enroll),
      switchMap((action) => {
        return this.mfaService.enroll(action.payload.params).pipe(
          map(() => {
            this.store.dispatch(new MultifactorAuthenticationActions.InsertNewIP());
            this.store.dispatch(new AuthApiActions.UpdateUserAccountInfo(action.payload.params));
            return new MultifactorAuthenticationActions.EnrollSuccess();
          }),
          catchError(error => of(new MultifactorAuthenticationActions.EnrollFailure(error)))
        );
      }));
  });

  dismissModal$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<MultifactorAuthenticationActions.DismissModal>(MultifactorAuthenticationActions.MfaActionTypes.DismissModal),
      switchMap((action) => {
        return this.mfaService.dismissVerifyModal().pipe(
          map(() => {
            return new MultifactorAuthenticationActions.DismissModalSuccess();
          }),
          catchError(error => of(new MultifactorAuthenticationActions.DismissModalFailure(error)))
        );
      }));
  });

  insertNewIP$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<MultifactorAuthenticationActions.InsertNewIP>(MultifactorAuthenticationActions.MfaActionTypes.InsertNewIP),
      switchMap((action) => {
        return this.mfaService.insertNewIpAddress().pipe(
          map(() => {
            return new MultifactorAuthenticationActions.InsertNewIPSuccess();
          }),
          catchError(error => of(new MultifactorAuthenticationActions.InsertNewIPFailure(error)))
        );
      }));
  });

  constructor(
    private actions$: Actions,
    private mfaService: MultifactorAuthenticationService,
    private store: Store
  ) { }
}
