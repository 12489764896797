import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, Store, select } from '@ngrx/store';
import { User } from "app/auth/models/user";
import * as fromAuth from 'app/auth/store/reducers';
import { HotelRoomsActions, HotelWatchlistActions } from 'app/hotels/store/actions';
import { FlightHelper } from 'app/shared/helpers/flight.helper';
import { HotelHelper } from 'app/shared/helpers/hotel.helper';
import { SlugifyHelper } from 'app/shared/helpers/slugify.helper';
import { AnalyticsService } from "app/shared/services/analytics/analytics.service";
import { ConfirmationDialogService } from 'app/shared/services/confirmation-dialog/confirmation-dialog.service';
import { SeoService } from 'app/shared/services/seo/seo.service';
import { WatchlistService } from 'app/shared/services/watchlist/watchlist.service';
import { FlightWatchlistActions } from 'app/shared/store/actions';
import * as fromShared from 'app/shared/store/reducers';
import * as moment from 'moment';
import * as fromRoot from 'reducers';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'watchlist',
  templateUrl: './watchlist.html',
  styleUrls: ['./watchlist.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WatchlistPage implements OnInit, OnDestroy {
  allFlightsWatchlist: any = {};
  currentJustify = 'justified';
  watchlist: any = {
    flights: {
      result: [],
      sum: 0,
      loading: false,
      emptyResponse: false
    },
    hotels: {
      result: [],
      sum: 1,
      loading: false,
      emptyResponse: false
    }
  };
  scrollDistanceToInfiniteScroll = 250;
  type = 'flights';
  itemsToLoad = 8;
  loggedIn$ = this.store.pipe(select(fromAuth.getUser));
  flightWatchlist$ = this.store.pipe(select(fromShared.getFlightWatchlist));
  private ngUnsubscribe = new Subject<void>();
  hasFlightTools = false;
  rooms: any[] = [];
  user: User;
  hotelWatchlistEventSent = false;

  constructor(
    private watchlistService: WatchlistService,
    private slugifyHelper: SlugifyHelper,
    private router: Router,
    private hotelHelper: HotelHelper,
    private store: Store<fromRoot.State>,
    private seoService: SeoService,
    private actionsSubject: ActionsSubject,
    private confirmationDialogService: ConfirmationDialogService,
    private flightHelper: FlightHelper,
    private analyticsService: AnalyticsService,
  ) {}

  ngOnInit() {
    this.seoService.setTitle('Watchlist');
    this.loggedIn$.pipe(
      take(1)
    ).subscribe((user) => {
      this.hasFlightTools = this.flightHelper.hasAnyFlightsTools(user);
      this.user = user;
      if (this.hasFlightTools) {
        this.store.dispatch(new FlightWatchlistActions.Load());
        this.logWatchlistEvent('Flights', 'view');
        this.loadFlightWatchlist();
      } else {
        this.type = 'hotels';
        this.logWatchlistEvent('Hotels', 'view');
        this.hotelWatchlistEventSent = true;
      }
    });

    this.actionsSubject.pipe(
      takeUntil(this.ngUnsubscribe),
      ofType(HotelRoomsActions.HotelRoomsActionTypes.GetRoomSuccess))
      .subscribe((response: any) => {
        if (response) {
          this.rooms.push(response.payload.room);
        }
      });

    this.loadHotelWatchlist(this.watchlist['hotels'].sum);
  }

  infiniteScroll(ev) {
    if (
      window.innerHeight +
        window.scrollY +
        this.scrollDistanceToInfiniteScroll >=
        document.body.offsetHeight ||
      window.innerHeight +
        document.documentElement.scrollTop +
        this.scrollDistanceToInfiniteScroll >=
        document.body.offsetHeight
    ) {
      if (
        !this.watchlist[this.type].emptyResponse &&
        !this.watchlist[this.type].loading
      ) {
        this.loadMoreItems();
      }
    }
  }

  loadMoreItems() {
    if (this.type === 'flights') {
      if (this.watchlist[this.type].sum < this.allFlightsWatchlist.length) {
        this.watchlist[this.type].loading = true;
        setTimeout(
          () => {
            this.watchlist[this.type].loading = false;
            this.watchlist[this.type].sum += this.itemsToLoad;
            const start = this.watchlist[this.type].sum;
            const end = this.watchlist[this.type].sum + this.itemsToLoad;
            this.addItems(
              start,
              end,
              this.allFlightsWatchlist,
              this.watchlist[this.type].result
            );
          },
          500
        );
      }
    } else if (this.type === 'hotels') {
      this.watchlist[this.type].sum += 1;
      this.loadHotelWatchlist(this.watchlist[this.type].sum);
    }
  }

  tabChanged(tab) {
    window.scrollTo(0, 0);
    this.type = tab.nextId;
    if (this.type === 'hotels' && !this.hotelWatchlistEventSent) {
      this.logWatchlistEvent('Hotels', 'view');
      this.hotelWatchlistEventSent = true;
    }
  }

  loadFlightWatchlist() {
    this.watchlist.flights.loading = true;
    this.flightWatchlist$.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((response) => {
      this.allFlightsWatchlist = response;
      this.watchlist.flights.result = [];
      this.watchlist.flights.sum = 0;
      this.addItems(0, this.itemsToLoad, response, this.watchlist.flights.result);
      this.watchlist.flights.loading = false;
    });
  }

  loadHotelWatchlist(itemsToStart) {
    const params = [];
    params['page_number'] = itemsToStart;
    params['page_size'] = this.itemsToLoad;
    this.watchlist['hotels'].loading = true;
    this.watchlistService.getHotelWatchers(params).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((response) => {
      this.addItems(0, response['data'].length, response['data'], this.watchlist['hotels'].result);
      if (response['data'].length === 0) {
        this.watchlist['hotels'].emptyResponse = true;
      } else {
        this.loadHotelInformation();
      }
      this.watchlist['hotels'].loading = false;
    });
  }

  addItems(startIndex, endIndex, fromArray, toArray) {
    if (endIndex !== 0) {
      for (let i = startIndex; i < endIndex; ++i) {
        if (fromArray[i]) {
          toArray.push(fromArray[i]);
        }
      }
    }
  }

  onHotelWatchlistBooked(item: any) {
    const city = this.slugifyHelper.slugify(item.hotel_city);
    const name = this.slugifyHelper.slugify(item.hotel_name);
    const hotelId = item.room_id.split('~')[0];

    return this.router.navigate([`/hotels/details/${city}/${name}/`,
      {
        hotelId,
        id90: item.id90,
        checkin_date: item.checkin,
        checkout_date: item.checkout,
        destination: item.hotel_address,
        businessTrip: false,
        bt_token: null,
        'guests[]': item.guests.join('|'),
        hotelName: name,
        id: hotelId,
        room_id: item.room_id,
        rooms: item.rooms,
        star_rating: item.hotel_star_rating,
        hotel_service_id: item.hotel_displayable_id,
        keyword: '',
        sortCriteria: 'recommended',
        sortOrder: 'desc'
      }
    ]);
  }

  onHotelWatchlistDelete(item: any) {
    this.confirmationDialogService.confirm({
      title: 'profile.watchlist.delete_confirm',
      titleParams: { hotel_name: item.hotel_name },
      btnOkText: 'profile.watchlist.delete_confirm_yes',
      btnCancelText: 'profile.watchlist.delete_confirm_no'
    }).then((confirmed) => {
      if (confirmed) {
        const watcher = {
          hotel_id: this.hotelHelper.encodeVendorPropertyID(item.hotel_id),
          watchlistId: item.id
        };
        this.store.dispatch(
          new HotelWatchlistActions.Remove({ watchlist: watcher })
        );
        this.removeHotel(watcher.hotel_id);
        this.logWatchlistEvent('Hotels', 'delete', item);
      }
    }).catch(() => {});
  }

  removeHotel(hotelId) {
    const decodedHotelId = this.hotelHelper.decodedVendorPropertyID(hotelId);
    this.watchlist.hotels.result = this.watchlist.hotels.result.filter(
      item => item.hotel_id !== decodedHotelId
    );
  }

  onFlightWatchlistDelete(item: any) {
    this.confirmationDialogService.confirm({
      title: 'profile.watchlist.delete_confirm',
      titleParams: { hotel_name: `${item.watching_data.transportingCarrier}-${item.watching_data.flightNumber}` },
      btnOkText: 'profile.watchlist.delete_confirm_yes',
      btnCancelText: 'profile.watchlist.delete_confirm_no'
    }).then((confirmed) => {
      if (confirmed) {
        this.logWatchlistEvent('Flights', 'delete');
        this.store.dispatch(
          new FlightWatchlistActions.Remove({ watchlist: item.api_id })
        );
      }
    }).catch(() => {});
  }

  private loadHotelInformation() {
    const items = this.watchlist?.hotels?.result;
    if (items && items.length) {
      items.forEach((item: any, index: number) => {
        setTimeout(() => {
          this.store.dispatch(new HotelRoomsActions.GetRoom({
            id: item.hotel_displayable_id,
            businessTrip: false,
            checkin: item.checkin,
            checkout: item.checkout,
            currency: item.room_display_currency,
            destination: item.hotel_address,
            'guests[]': '2',
            hotelName: item.hotel_name,
            rid: item.room_id,
            rooms: item.rooms,
            star_rating: item.hotel_star_rating
          }));
        },         index * 500);
      });
    }
  }

  logWatchlistEvent(line_of_business: string, type: string, hotel_item?: any){
    let event = {
      line_of_business: line_of_business,
      item_name: `${line_of_business} ${type} watchlist`,
      user_action: type,
      user_id: this.user?.account?.id ?? this.user?.account?.idMembers ?? '',
      is_invited: this.user?.account?.airlineCode === '100' ?? ''
    };

    if (hotel_item) {
      const days = moment(hotel_item.checkout).diff(moment(hotel_item.checkin), 'days');
      let item_event = {
        item_id: hotel_item.id90,
        item_name: hotel_item.hotel_name,
        item_brand: hotel_item.chain ?? '',
        item_category: 'Hotel',
        item_category_2: hotel_item.hotel_star_rating,
        item_category_3: hotel_item.accommodation_type ?? '',
        item_list_id: hotel_item.id,
        item_variant: this.hotelHelper.getSupplierName(hotel_item.room_id),
        quantity: days * hotel_item.rooms,
        price: hotel_item.room_latest_rate
      }
      event['items'] = [item_event];
    }

    let event_name = `${type.toLowerCase()}_watchlist`;
    this.analyticsService.logEvent(event_name, event);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
