import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { ProfileService } from 'app/shared/services/profile/profile.service';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';
import { GeneralHelper } from 'app/shared/helpers/general.helper';
import { take } from 'rxjs/operators';
import { ProfileHelper } from 'app/shared/helpers/profile.helper';
import { faChevronLeft, faCalendar, faCircleNotch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'passport',
  templateUrl: './passport.html'
})

export class PassportComponent implements OnInit {

  @Input() profileForm;
  @Input() profileConfig;
  @Input() countries;
  @Output() showTabs = new EventEmitter();
  @Output() updateProfile = new EventEmitter();

  minDate = {};
  maxDate = {};
  maxDatePassport = {};
  startPassportIssuanceDate = '';
  startPassportExpirationDate = '';
  loading = false;
  success = null;
  error = null;
  minExpirationDate: {};
  icons = { faCalendar, faChevronLeft, faCircleNotch };

  constructor(
    private profileService: ProfileService,
    private generalHelper: GeneralHelper,
    public formErrorsHelper: FormErrorsHelper,
    private profileHelper: ProfileHelper
  ) {}

  ngOnInit() {
    this.loadInfo();
  }

  loadInfo() {
    const now = moment();
    const todayMonth = now.month() + 1; // Current month number but we need to add one for moment.
    const todayDay = now.date();
    const todayYear = now.year();
    this.minDate = { year: 1900, month: 1, day: 1 };
    this.maxDate = { year: todayYear, month: todayMonth, day: todayDay };
    this.maxDatePassport = { year: todayYear + 26, month: todayMonth, day: todayDay };
    this.minExpirationDate = { year: todayYear, month: todayMonth, day: todayDay };
    this.startPassportIssuanceDate = this.profileForm.value.passport.passportIssuanceDate || '';
    this.startPassportExpirationDate = this.profileForm.value.passport.passportExpirationDate || '';
  }

  onEditPassport() {
    if (this.isValid()) {
      this.loading = true;
      this.success = null;
      this.error = null;

      const params = this.profileForm.controls['passport'].value;

      this.profileService.updateProfile(params).pipe(take(1)).subscribe(
        () => {
          this.loading = false;
          this.success = 'profile.info.successful_update';
          this.updateProfile.emit(params);
        },
        (error) => {
          this.error = error.error.error_description;
          this.loading = false;
          this.generalHelper.scrollToElement('header');
        }
      );
    } else {
      Object.keys(this.profileForm.controls['passport'].controls).forEach((field) => {
        const control = this.profileForm.controls['passport'].get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  onInsurranceDateSelected(e) {
    const date = `${e.year}/${e.month}/${e.day}`;
    const dateSelected = moment(date).format('YYYY-MM-DD');
    this.profileForm.controls['passport'].controls['passportIssuanceDate'].setValue(dateSelected);
    this.startPassportIssuanceDate = dateSelected;
  }

  formatStartIssuanceDate() {
    return this.profileHelper.formatStartDate(this.startPassportIssuanceDate);
  }

  onExpirationDateSelected(e) {
    const date = `${e.year}/${e.month}/${e.day}`;
    const dateSelected = moment(date).format('YYYY-MM-DD');
    this.profileForm.controls['passport'].controls['passportExpirationDate'].setValue(dateSelected);
    this.startPassportExpirationDate = dateSelected;
  }

  formatStartExpirationDate() {
    return this.profileHelper.formatStartDate(this.startPassportExpirationDate);
  }

  isValid() {
    return this.profileForm.controls['passport'].valid;
  }

  closeAlert() {
    this.error = null;
    this.success = null;
  }

  backToTabs() {
    this.showTabs.emit();
  }

  get passportFirstName()  { return this.profileForm.controls['passport'].get('passportFirstName'); }
  get passportMiddleName()  { return this.profileForm.controls['passport'].get('passportMiddleName'); }
  get passportLastName() { return this.profileForm.controls['passport'].get('passportLastName'); }
  get passportNationality() { return this.profileForm.controls['passport'].get('passportNationality'); }
  get passportNumber() { return this.profileForm.controls['passport'].get('passportNumber'); }
  get passportNumberDomestic() { return this.profileForm.controls['passport'].get('passportNumberDomestic'); }
  get passportIssuanceCountry() { return this.profileForm.controls['passport'].get('passportIssuanceCountry'); }
  get passportCountryBirth() { return this.profileForm.controls['passport'].get('passportCountryBirth'); }

}
