import { Component, Input, OnInit, OnDestroy, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GmailContactsService } from 'app/shared/services/gmail-contacts/gmail-contacts.service';
import { GmailContactsModalComponent } from './gmail-contacts-modal.component';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gmail-contacts',
  templateUrl: './gmail-contacts.html'
})

export class GmailContactsComponent implements OnInit, OnDestroy {
  @Input() newGmailIcon = true;
  @Input() modalTitle = 'invite.select_contacts';
  @Input() buttonText = 'Import Contacts';
  @Input() buttonActionText = 'mobile.invite_friends.send_invites';
  @Input() buttonActionTypeText = 'mobile.invite_friends.send_one_invite';
  @Input() buttonAddActionText = 'mobile.invite_friends.send_n_invites_1';
  @Input() buttonActionTypeTextN = 'mobile.invite_friends.send_n_invites_2';
  @Input() rewardPrice: number;
  @Output() emailsSelected: any = new EventEmitter();
  @ViewChild('launchModal', { static: false }) launchModal: ElementRef; // Little hack to launch modal clicking a btn
  loading = true;
  gmailContacts = [];
  private gcSubscription;

  constructor(
    private modalService: NgbModal,
    private gc: GmailContactsService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.loading = false;
  }

  showGmailContactsModal() {
    const modalRef = this.modalService.open(GmailContactsModalComponent, { size: 'lg' });
    modalRef.componentInstance.contacts = this.gmailContacts;
    this.gmailContacts = []; // Prepare for next service call.
    modalRef.componentInstance.rewardPrice = this.rewardPrice;
    modalRef.componentInstance.modalTitle =  this.translateService.instant(this.modalTitle);
    modalRef.componentInstance.buttonActionText = this.translateService.instant(this.buttonActionText);
    modalRef.componentInstance.buttonActionTypeText = this.translateService.instant(this.buttonActionTypeText);
    modalRef.componentInstance.buttonAddActionText = this.translateService.instant(this.buttonAddActionText);
    modalRef.componentInstance.buttonActionTypeTextN = this.translateService.instant(this.buttonActionTypeTextN);
    modalRef.result.then(
      (contactsSelected) => {
        if (contactsSelected) {
          this.emailsSelected.emit(contactsSelected);
        }
      },
      () => {} // Avoid close reason log an error in the console
    );
  }

  googleContacts() {
    this.gcSubscription = this.gc.googleContacts().pipe(take(1)).subscribe((gContacts) => {
      this.getEmailsFromGmailContacts(gContacts);

      const launchGmailModalButton: HTMLElement = this.launchModal.nativeElement as HTMLElement;
      launchGmailModalButton.click();
    });
  }

  getEmailsFromGmailContacts(gContacts) {
    gContacts.forEach((contact) => {
      if (contact.emailAddresses) {
        const email = contact.emailAddresses[0].value;
        this.gmailContacts.push({
          email,
          name: contact.names && contact.names[0] ? contact.names[0].displayName : this.gc.emailToName(email),
        });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.gcSubscription) {
      this.gcSubscription.unsubscribe();
    }
  }
}
