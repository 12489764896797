import { FacebookLoginProvider, GoogleInitOptions, GoogleLoginProvider, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { environment } from '@env/environment';

export function getAuthServiceConfigs() {
  const fbLoginOptions = {
    auth_type: 'rerequest',
    scope: 'email'
  };

  const googleLoginOptions = {
    oneTapEnabled: false,
  } as GoogleInitOptions;

  const config: SocialAuthServiceConfig = {
    providers: [
      {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider(environment.oauth.googleApp, googleLoginOptions)
      },
      {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider(environment.oauth.facebookApp, fbLoginOptions)
      }
    ],
    onError: (err) => {
      console.error(err);
    }
  } as SocialAuthServiceConfig

  return config;
}
