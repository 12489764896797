import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { TrendingDestinationsActions, TrendingDestinationsApiActions } from '../actions';
import { switchMap, concatMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { TrendingDestinationsService } from 'app/shared/services/trending-destinations/trending-destinations.service';
import { Store, select } from '@ngrx/store';
import * as fromRoot from 'reducers';
import * as fromCurrentLocation from 'app/shared/store/reducers';

@Injectable()
export class TrendingDestinationsEffects {
  currentLocation$ = this.store.pipe(select(fromCurrentLocation.getCurrentLocation));

  load$ = createEffect(()=>
    this.actions$.pipe(
      ofType<TrendingDestinationsActions.Load>(TrendingDestinationsActions.TrendingDestinationsActionTypes.Load),
      concatMap(action => of(action).pipe(withLatestFrom(this.currentLocation$))),
      switchMap(([res, currentLocation]) => {
        return this.trendingDestinationsService.getTrendingDestinations('hotels', currentLocation ?? {}).pipe(
          map(res => new TrendingDestinationsApiActions.LoadSuccess({ destinations: res })),
          catchError(error => of(new TrendingDestinationsApiActions.LoadFailure(error)))
        )
      })
    )
  );

  constructor(
    private actions$: Actions,
    private trendingDestinationsService: TrendingDestinationsService,
    private store: Store<fromRoot.State>
  ) {}
}
