<ngb-alert *ngIf="success" type="success" (closed)="closeAlert()">{{ success | translate }}</ngb-alert>
<ngb-alert *ngIf="error" type="danger" (closed)="closeAlert()">{{ error }}</ngb-alert>
<ngb-alert *ngIf="noGoggleSession" type="info" (closed)="closeAlert()">{{ 'home_page.third_parties_connect_modal.google_no_session' | translate }}</ngb-alert>
<div class="d-sm-none back-button">
  <button type="button" class="btn btn-link" (click)="backToTabs()">
    <fa-icon [icon]="icons.faChevronLeft" aria-hidden="true"></fa-icon> {{'back' | translate}} </button>
</div>
<div class="profile-title primary-content">{{'profile.login_and_security.title' | translate}}</div>
<section class="mb-4">
  <div class="card shadow-none">
    <div class="card-body">
      <loading-indicator *ngIf="isPending" [fullScreen]="false"></loading-indicator>
      <div class="container" *ngIf="!isPending">
        <div *ngIf="mfaActive" class="row">
          <div class="col col-md-9">
            <div class="secondary-content">{{'profile.login_and_security.two_step_security.title' | translate}}</div>
            <div *ngIf="userInfo.account.mfaEmail === 0" class="tertiary-content">{{ 'profile.login_and_security.two_step_security.not_enabled' | translate }}</div>
          </div>
          <div class="col col-md-3 text-end">
            <button *ngIf="userInfo.account.mfaEmail !== 1" (click)="openTwoStepSecurityModal()" class="btn btn-link">{{'profile.login_and_security.update' | translate}}</button>
            <span *ngIf="userInfo.account.mfaEmail === 1" class="badge bg-success-subtle text-success">{{'profile.login_and_security.two_step_security.enabled' | translate}}</span>
          </div>
        </div>
        <div *ngIf="mfaActive" class="row">
          <div class="col">
            <div *ngIf="userInfo.account.mfaEmail === 1" class="tertiary-content" [innerHTML]="mfaAdditionalInfo | translate"></div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <div class="secondary-content">{{'profile.login_and_security.password' | translate}}</div>
            <div class="tertiary-content">{{ lastPasswordUpdate }}</div>
          </div>
          <div class="col text-end">
            <button (click)="updatePassword()"
                class="btn btn-link" data-cy="password">{{'profile.login_and_security.update' | translate}}</button>
          </div>
        </div>
        <div class="row mt-4" *ngIf="phoneAvailable">
          <div class="col">
            <div class="secondary-content">{{'profile.login_and_security.phone_number' | translate}}</div>>
            <div *ngIf="isPhoneNumberConfirmed" class="tertiary-content">{{'profile.login_and_security.confirmed' | translate}}</div>
            <div *ngIf="!isPhoneNumberConfirmed" class="tertiary-content">{{'profile.login_and_security.not_confirmed' | translate}}</div>
          </div>
          <div class="col text-end">
            <button *ngIf="!isPhoneNumberConfirmed" (click)="confirmPhoneNumber()"
                class="btn btn-link gtm-confirm-phone">{{'profile.login_and_security.confirm' | translate}}</button>>
          </div>
        </div>
        <div class="row mt-4" *ngIf="showFacebook">
          <div class="col">
            <div class="secondary-content">{{'profile.login_and_security.login' | translate: { platform: socials.FACEBOOK } }}</div>
            <div *ngIf="isFacebookConnected" class="tertiary-content">{{'profile.login_and_security.connected' | translate}}</div>
            <div *ngIf="!isFacebookConnected" class="tertiary-content">{{'profile.login_and_security.not_connected' | translate}}</div>
          </div>
          <div class="col text-end">
            <button *ngIf="!isFacebookConnected" (click)="connectThirdPartyAccount(socials.FACEBOOK)"
                class="btn btn-link gtm-facebook-connect">{{'profile.login_and_security.connect' | translate}}</button>
            <button *ngIf="isFacebookConnected" (click)="disconnectThirdPartyAccount(socials.FACEBOOK)"
                class="btn btn-link gtm-facebook-connect">{{'profile.login_and_security.disconnect' | translate}}</button>
          </div>
        </div>
        <div class="row mt-4" *ngIf="showGoogle">
          <div class="col">
            <div class="secondary-content">{{'profile.login_and_security.login' | translate: { platform: socials.GOOGLE } }}</div>
            <div *ngIf="isGoogleConnected" class="tertiary-content">{{'profile.login_and_security.connected' | translate}}</div>
            <div *ngIf="!isGoogleConnected" class="tertiary-content">{{'profile.login_and_security.not_connected' | translate}}</div>
          </div>
          <div class="col text-end">
            <button *ngIf="!isGoogleConnected" (click)="connectThirdPartyAccount(socials.GOOGLE)"
                class="btn btn-link gtm-google-connect" data-cy="google-connect">{{'profile.login_and_security.connect' | translate}}</button>
            <button *ngIf="isGoogleConnected" (click)="disconnectThirdPartyAccount(socials.GOOGLE)"
                class="btn btn-link gtm-google-connect">{{'profile.login_and_security.disconnect' | translate}}</button>
          </div>
        </div>
        <div class="row mt-4" *ngIf="showApple">
          <div class="col">
            <div class="secondary-content">{{'profile.login_and_security.login' | translate: { platform: socials.APPLE } }}
            </div>
            <div *ngIf="isAppleConnected" class="tertiary-content">{{'profile.login_and_security.connected' | translate}}</div>
            <div *ngIf="!isAppleConnected" class="tertiary-content">{{'profile.login_and_security.not_connected' | translate}}
            </div>
          </div>
          <div class="col text-end">
            <button *ngIf="!isAppleConnected" (click)="connectThirdPartyAccount(socials.APPLE)"
              class="btn btn-link gtm-apple-connect">{{'profile.login_and_security.connect' | translate}}</button>
            <button *ngIf="isAppleConnected" (click)="disconnectThirdPartyAccount(socials.APPLE)"
              class="btn btn-link gtm-apple-connect">{{'profile.login_and_security.disconnect' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div *ngIf="userInfo && !userInfo.is_partner && !isEligibleTraveler()" class="row mb-4">
  <div class="col text-end">
    <button class="btn btn-link btn-sm text-danger" (click)="deleteAccount()">
      <fa-icon [icon]="icons.faTrashAlt"></fa-icon> {{ 'profile.info.delete_account.link' | translate }}
    </button>
  </div>
</div>
