import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  IterableDiffer,
  IterableDiffers,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { environment } from '@env/environment';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { mapFactory } from 'app/map.factory';
import { GeneralHelper } from 'app/shared/helpers/general.helper';
import { MapMarker } from 'app/shared/models/map-marker';
import { DynamicScriptLoaderService } from 'app/shared/services/dynamic-script-load/dynamic-script-load.service';
import { MapLayerService } from 'app/shared/services/map/map-layer.service';
import { MapService } from 'app/shared/services/map/map.service';
import { isEqual } from 'lodash';

declare const L: any;

@Component({
  selector: 'map',
  templateUrl: 'map.html',
  styleUrls: ['./map.scss'],
  providers: [
    [
      {
        provide: MapService,
        useFactory: mapFactory,
        deps: [HttpClient, DynamicScriptLoaderService, MapLayerService]
      }
    ]
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapComponent implements OnInit, OnChanges, OnDestroy {
  @Input() centerLat: string;
  @Input() centerLng: string;
  @Input() radius: number;
  @Input() markers: MapMarker[];
  @Input() polygons: any[] = [];
  @Input() showSearchButton = true;
  @Input() isSearchingArea = false;
  @Input() mapHeight: number;
  @Input() zoomLevel: number;
  @Input() enableZoom = true;
  @Input() showScale = false;
  @Input() showCluster = false;
  @Input() isInteractive = true;
  @Input() sortBy = 'recommended';
  @Input() trackZoomEvents: boolean = false;
  @Input() set hotelSelected(value) {
    value && this.updateItemSelected(value);
  };

  @Output() redoSearch = new EventEmitter();
  @Output() locationChanges = new EventEmitter();
  @Output() markersOnMap = new EventEmitter();
  @Output() itemSelected = new EventEmitter();
  @Output() mapItemPressed = new EventEmitter();

  icons = { faSpinner };

  private map: any;
  private selectedMarker: any;
  private mapMarkers: any[] = [];
  private highlightLookup: any[] = [];
  private iterableDiffer: IterableDiffer<any> | null;

  constructor(
    private mapService: MapService,
    private differs: IterableDiffers
  ) {}

  ngOnInit() {
    this.mapService.initialize().then(() => {
      let centerLat = 0;
      let centerLng = 0;
      if (this.centerLat && this.centerLng) {
        centerLat = parseFloat(this.centerLat);
        centerLng = parseFloat(this.centerLng);
      } else if (this.markers && this.markers.length > 0) {
        centerLat = this.markers[0].latitude;
        centerLng = this.markers[0].longitude;
      }

      this.map = this.mapService.buildMap(centerLat, centerLng, this.radius);

      this.mapService.setInteractive(this.map, this.isInteractive);

      if (this.showScale) {
        this.mapService.showScale(this.map);
      }
      this.mapService.setZoomEnabled(this.map, this.enableZoom);

      this.drawMarkers();
      this.mapService.drawPolygons(this.map, this.polygons);

      this.iterableDiffer = this.differs.find([]).create(null);
      if (this.centerLat && this.centerLng) {
        if (!this.radius && this.zoomLevel) {
          this.mapService.setZoomLevel(this.map, this.zoomLevel);
        }
      } else if (Array.isArray(this.markers) && this.markers.length > 0) {
        this.extendMap();
      }
      this.map.getContainer().focus = (e)=> e?.preventDefault();

      this.mapService.onLocationChangedSubject(this.map, (data) => {
        this.centerLat = data.latitude.toString();
        this.centerLng = data.longitude.toString();
        this.radius = data.radius;
        this.locationChanges.emit(data);
      });
      if (this.trackZoomEvents) {
        setTimeout(() => this.setTrackingZoomEvents(), 2500);
      }
    }).catch(error => console.log(error, 'error'));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.markers && this.map && this.markers.length > 0 || this.map && (changes.sortBy || changes.polygons)) {
        this.removeMarkers();
        this.drawMarkers();
        this.emitMarkersOnMap();
    }

    if (
      this.map && (changes.centerLat || changes.centerLng || changes.radius) &&
      this.centerLat && this.centerLng && this.radius &&
      this.centerLat !== '0' && this.centerLng !== '0' && this.radius !== 0
    ) {
      const animate = typeof(changes.centerLat) === 'undefined' && typeof(changes.centerLng) === 'undefined';
      this.centerMap(parseFloat(this.centerLat), parseFloat(this.centerLng), animate, this.radius);
    }

    if (changes.polygons && this.map && !isEqual(changes.polygons.currentValue, changes.polygons.previousValue)) {
      this.mapService.removePolygons(this.map);
      this.mapService.drawPolygons(this.map, changes.polygons.currentValue);
      // center map if no polygons present
      if (!changes.polygons.currentValue.length) {
        this.centerMap(parseFloat(this.centerLat), parseFloat(this.centerLng), true, this.radius);
      }
    }

    if (changes.markers && this.map && this.markers.length > 0)  {
      this.markers.forEach((m) => this.highlightToggle(m.info, m.highlight));
    }
  }

  ngOnDestroy() {
    this.mapService.removeLayer();
  }

  private updateItemSelected(hotelSelected): void {
    this.centerMap(parseFloat(hotelSelected.location.latitude), parseFloat(hotelSelected.location.longitude), false, this.radius);
    const id = hotelSelected.id;
    const markerIconElement = this.highlightLookup[id] && this.highlightLookup[id].mapMarker.getIconElement();
    if (markerIconElement) {
      setTimeout(() => this.mapService.updateActiveMarker(markerIconElement), 600);
    }
  }

  setTrackingZoomEvents() {
    if (this.map) {
      this.map.on('zoom', (event) => {
        if (this.trackZoomEvents) {
          this.mapItemPressed.emit(true);
        }
     });
    }
  }

  highlightToggle(id: string, highlight: boolean) {
    if (this.highlightLookup[id] && this.highlightLookup[id].marker) {
      const markerIconElement = this.highlightLookup[id].mapMarker.getIconElement();
      if (markerIconElement) {
        if (highlight) {
          this.mapService.updateActiveMarker(markerIconElement);
        } else {
          markerIconElement.classList.remove('marker-active');
        }
      }
    }
  }

  removeMarkers() {
    this.mapService.removeMarkers(this.map, this.mapMarkers);
    this.mapMarkers = [];
  }

  drawMarkers() {
    this.mapMarkers = [];
    this.markers.forEach((marker: MapMarker) => {
      const mapMarker = this.mapService.addMarker(this.map, marker, this.onMarkerClick);
      this.mapMarkers.push(mapMarker);
      this.highlightLookup[marker.info] = { marker, mapMarker };
    });

    const destinationCenter = this.highlightLookup['destination_center'];
    if (destinationCenter) {
      destinationCenter.mapMarker.getIconElement()?.classList?.add('destination-center');
    }
    if (this.showCluster && (
      this.markers.length > environment.hotelResultsMapClusteringMin || (
        GeneralHelper.isMobileDevice() &&
        this.markers.length > environment.hotelResultsMapClusteringMinMobile
      )
    )) {
      this.mapService.buildCluster(this.map, 15, this.mapMarkers, this.onMarkerClick, this.emitMarkersOnMap, this.sortBy, this.calculateClusterRadius(this.mapMarkers.length));
    } else {
      // we only emit markers when we cluster
      this.mapService.drawMarkersWithoutClustering(this.map, this.mapMarkers, this.showCluster ? this.emitMarkersOnMap : null);
    }
  }

  private calculateClusterRadius(markersLength: number): number {
    let baseRadius = 30;
    let radiusIncrement = 5;
    let limitIncrement = 200; // 225

    // Calculate the radius based on the markers length
    const calculatedRadius = baseRadius + Math.floor(markersLength / limitIncrement) * radiusIncrement;
    
    // Ensure the calculated radius is not less than the base radius
    return Math.max(baseRadius, calculatedRadius);
  }

  private onMarkerClick = (ev) => {
    const marker = ev.sourceTarget.getIconElement() ? ev.sourceTarget.getIconElement() : ev.sourceTarget;
    const mapData = ev.sourceTarget.getData();
    this.mapService.updateActiveMarker(marker);
    this.itemSelected.emit(mapData.hotel);
  }

  private emitMarkersOnMap = () => {
    const mapMarkers = this.map.getMarkersOnMap();
    this.highlightLookup = [];
    mapMarkers.forEach((mapMarker) => {
      const marker = mapMarker.getData();
      this.highlightLookup[marker.info] = { marker, mapMarker } ;
    });
    this.mapMarkers = mapMarkers;
    this.markersOnMap.emit(mapMarkers);
  }

  centerMap(lat: number, lng: number, animate = false, radius = null) {
    if (!lat || !lng) {
      return;
    }
    this.mapService.setCenter(this.map, lat, lng, animate, radius);
  }

  extendMap() {
    if (this.mapMarkers.length > 0) {
      this.mapService.extendBounds(this.map, this.markers);
    }
  }

  onRedoSearch() {
    const location = this.mapService.getCenter(this.map);
    this.redoSearch.emit({ ...location, radius: this.radius });
  }

}
