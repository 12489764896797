import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'just-for-you-card',
  styleUrls: ['./just-for-you-card.scss'],
  templateUrl: './just-for-you-card.html'
})

export class JustForYouCardComponent {
  @Input() data;
  @Input() title;
  @Input() image;
  @Input() dates;
  @Input() tertiaryContent;
  @Input() destination;
  @Input() type;
  @Input() defaultImage;

  @Input() isNeedARoom: boolean = false;
  @Input() isAbandonedHotel: boolean = false;
  @Input() hotelTotalCost: string;
  @Input () hotelNightRate: string;

  @Output() click = new EventEmitter();

  onClicked() {
    this.click.emit();
  }
}
