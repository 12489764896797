import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { faComment, faEnvelopeOpenText, faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import { ResetPasswordOptions } from 'app/auth/models/reset-password-options';
import { Subject } from 'rxjs';

@Component({
  selector: 'send-code-options',
  templateUrl: './send-code-options.component.html',
  styleUrls: ['./send-code-options.component.scss']
})

export class SendCodeOptionsComponent implements OnDestroy {
  icons = { faComment, faMobileAlt, faEnvelopeOpenText };
  @Input() enabledResetOptions: ResetPasswordOptions;
  @Output() sendResetLink = new EventEmitter();
  @Output() sendResetCode = new EventEmitter();

  private ngUnsubscribe = new Subject<void>();

  constructor() { }

  selectMethodAction(method) {
    method === 'code' || method === 'sms' ? this.sendResetCode.emit(method) : this.sendResetLink.emit();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
