import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as fromAuth from 'app/auth/store/reducers';
import * as fromRoot from 'reducers';
import { map, tap, filter, skipWhile } from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';
import { TwoStepSecurityModal } from 'app/shared/containers/two-step-security/two-step-security-modal';
import { MfaActions } from 'app/profile/models/mfa';
import { MultifactorAuthenticationActions } from 'app/auth/store/actions';
import { AuthApiActions, LogoutComponentActions } from 'app/auth/store';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { MFAHelper } from 'app/profile/helpers/mfa.helper';
import * as fromShared from 'app/shared/store/reducers';

@Injectable({
  providedIn: 'root'
})

export class MFAGuard  {
  user: any;
  mfaModalOptions: NgbModalOptions = {
    backdrop : 'static',
    keyboard : false,
    size: 'lg'
  };

  configFlags$ = this.store.pipe(select(fromShared.getConfigFlag));
  userAccount$ = this.store.pipe(select(fromAuth.selectAuthStatusState));
  featureFlag$ = this.store.pipe(select(fromShared.getFeatureFlags));
  features = null;

  constructor(
    public router: Router,
    private store: Store<fromRoot.State>,
    private modalService: NgbModal,
    private mfaHelper: MFAHelper
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return combineLatest([this.featureFlag$, this.userAccount$]).pipe(
      skipWhile(([features, account]) => {
        if (!account.loggedIn) return false;
        return !features;
      }), // Wait until flags finished to load
      map(([features, account]) => {
        this.user = account.user;
        this.features = features;
        return true;
      }),
      tap(() => {
        if (this.mfaHelper.isMfaSkipped(this.user)) {
          return of(true);
        }
        if (this.mfaHelper.isFirstAccessAfterValidateAccount(this.user)) {
          this.store.dispatch(new AuthApiActions.FirstTimeAccessRestore());
          return of(true);
        }
        this.openMFAModal();
        return of(true);
      })
    );
  }

  openMFAModal() {
    if (this.features?.length && this.mfaHelper.showMFAModal(this.user, this.features)) {
      const twoStepSecurityModal = this.modalService.open(TwoStepSecurityModal, this.mfaModalOptions);
      twoStepSecurityModal.componentInstance.userData = this.user;
      twoStepSecurityModal.componentInstance.fromLogin = true;
      // twoStepSecurityModal.componentInstance.action = this.user.is_new_device ? MfaActions.MFA_NEW_DEVICE : MfaActions.MFA_ENROLLMENT;
      twoStepSecurityModal.componentInstance.action = MfaActions.MFA_ENROLLMENT;
      twoStepSecurityModal.result.then(
        (success) => {
          if (success && this.user.is_new_device) {
            this.user.member.redirect = this.user.member.redirect || '/hotels';
            this.user.is_new_device = false;
            this.store.dispatch(new MultifactorAuthenticationActions.InsertNewIP());
            this.store.dispatch(new AuthApiActions.UpdateUserInfo({ user: this.user }));
            return this.router.navigateByUrl(this.user.member.redirect);
          }
        },
        () => {
          if (this.user.is_new_device) {
            return this.store.dispatch(new LogoutComponentActions.Logout());
          }
        }
      );
    }
  }
}
