<div class="row">
  <div class="col-12 pb-4">
    <button type="button" class="sync btn btn-outline-warning float-end btn-sm " [ngClass]="showForm ? '' : 'active'"
      (click)="dash()">
      Pending:{{ (registrations?.pending).length }} - Failed:{{ (registrations?.failed).length }} -
      Completed:{{ (registrations?.completed).length }}
      <fa-icon *ngIf="syncInProgress" [icon]="icons.faSpinner" class="rotation-animation fa-spin"></fa-icon>
    </button>
  </div>
</div>
<div class="row">
  <div class="col-12 text-center">
    <h2>{{ (airline$ | async) }}</h2>
    <div class="secondary-content">Campaign: {{ campaign$ | async }}</div>
  </div>
</div>
<div class="row" *ngIf="showForm">
  <div class="col-12">
    <event-registration-form *ngIf="(airline$ | async) && (campaign$ | async)"
      [airline]="airline$ | async"
      [utmCampaign]="campaign$ | async"
      [errorMessage]="error$ | async"
      [pending]="pending$ | async"
      [success]="success"
      (submitted)="createAccount($event)" (logout)="logout()"
      (clear)="clearStatus()">
    </event-registration-form>
  </div>
</div>
<div class="row" *ngIf="!showForm">
  <div class="col-md-6 col-xs-12 offset-md-3 py-5">
    <div class="row">
      <div class="col-12">
        <button class="btn btn-sm btn-outline-primary float-end" [disabled]="registrations.pending.length || registrations.failed.length" (click)="logout()"> {{ 'admin.select_employee.button.cancel' | translate }} </button>
        <button class="btn btn-sm btn-outline-primary float-end" *ngIf="registrations.pending.length" (click)="sync()" [disabled]="syncInProgress"><fa-icon [icon]="icons.faSync"></fa-icon>Sync</button>
      </div>
    </div>
    <div *ngIf="registrations.pending.length">
      <div class="row">
        <div class="col-12">
          <h6>Pending</h6>
        </div>
      </div>
      <div class="list-group">
        <div class="list-group-item" *ngFor="let registration of registrations.pending">
          {{ registration.email }}
          <a href="javascript:void(0)"  class="trash float-end" (click)="remove(registration)">
            <fa-icon [icon]="icons.faTimes" class="red"></fa-icon>
          </a>
        </div>
      </div>
    </div>

    <div *ngIf="registrations.failed.length">
      <br>
      <div class="row">
        <div class="col-12">
          <h6>Failed</h6>
        </div>
      </div>
      <div class="list-group">
        <div class="list-group-item" *ngFor="let registration of registrations.failed">
          <a href="javascript:void(0)" class="trash float-end" (click)="remove(registration.auth)">
            <fa-icon [icon]="icons.faTimes" class="red"></fa-icon>
          </a>
          {{ registration.auth.email }}
          <p class="red">
            {{ registration.error }}
          </p>
          <a href="javascript:void(0)" class="float-end" (click)="retry(registration.auth)">
            <fa-icon [icon]="icons.faSync"></fa-icon>
          </a>
        </div>
      </div>
    </div>

    <div *ngIf="registrations.completed.length">
      <br>
      <div class="row">
        <div class="col-12">
          <h6>Completed</h6>
        </div>
      </div>
      <div class="list-group">
        <div class="list-group-item green" *ngFor="let registration of registrations.completed">
          {{ registration.email }}
          <fa-icon [icon]="icons.faCheck" class="float-end"></fa-icon>
        </div>
      </div>
    </div>
  </div>
</div>
