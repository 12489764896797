import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MfaActions } from 'app/profile/models/mfa';
import { PaymentMethodsActions } from 'app/profile/store/actions';
import { Reset } from 'app/profile/store/actions/payment-methods.actions';
import * as fromProfile from 'app/profile/store/reducers';
import { TwoStepSecurityModal } from 'app/shared/containers/two-step-security/two-step-security-modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'delete-payment-method',
  templateUrl: 'delete-payment-method.html'
})

export class DeletePaymentMethodComponent  implements OnInit, OnDestroy {
  @Input() requestMFA = false;
  @Input() userData;
  @Input() token;
  @Input() isPopup: boolean = true;
  @Output() deletedCard = new EventEmitter();
  @Output() cancelPressed = new EventEmitter();

  error = '';
  loading = false;
  updatedPressed: boolean = false;
  cardActionSuccess$ = this.store.select(fromProfile.getPaymentMethodsSuccess);
  cardActionError$ = this.store.select(fromProfile.getPaymentMethodsError);
  private ngUnsubscribe = new Subject<void>();

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private store: Store
  ) {  }

  ngOnInit(): void {
    if (this.isPopup) {
      this.store.dispatch(new Reset());
    }
    this.listenForError();
    this.listenForSuccess();
  }

  onDeletedClick(token): void {
    if (this.requestMFA) {
      const twoStepSecurityModal = this.modalService.open(TwoStepSecurityModal, { backdrop : 'static', keyboard : false, size: 'lg' });
      twoStepSecurityModal.componentInstance.userData = this.userData;
      twoStepSecurityModal.componentInstance.action = MfaActions.MFA_PROFILE_UPDATE;
      twoStepSecurityModal.result.then(
        (success) => {
          if (success) { this.deleteAction(token); }
        },
        () => {
          this.error = this.translateService.instant('errors.mfa_required');
        }
      );
    } else {
      this.deleteAction(token);
    }
  }

  deleteAction(token) {
    this.loading = true;
    this.error = '';
    this.updatedPressed = true;
    this.store.dispatch(new PaymentMethodsActions.Delete(token))
  }

  dismiss() {
    if (this.isPopup) {
      this.activeModal.dismiss();
    } else {
      this.cancelPressed.emit();
    }
  }
  private listenForSuccess() {
    this.cardActionSuccess$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((resp) => {
      if (resp) {
        if (this.isPopup) {
          this.activeModal.close(true);
        } else if (this.updatedPressed) {
          this.deletedCard.emit(true);
        }
      }
    });
  }
  private listenForError() {
    this.cardActionError$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((error) => {
      if (error) {
        this.error = error.error.error_description;
        this.loading = false;
      }
    })
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
