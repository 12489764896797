import { AfterViewInit, Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GamificationService } from 'app/shared/services/gamification/gamification.service';

@Component({
  selector: 'gamification-game',
  templateUrl: './gamification.component.html',
  styleUrls: ['gamification.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})

export class GamificationComponent implements AfterViewInit {
  @ViewChild('game', { static: false }) private elementRef: ElementRef;
  @Input() userId: string;
  @Output() submitted = new EventEmitter();

  constructor(private gamificationService: GamificationService,
              private ngZone: NgZone,
              public activeModal: NgbActiveModal) { }

  ngAfterViewInit(): void {
  }

  closeModalCallBack() {
    this.ngZone.run(()=> {
      this.activeModal.close('close');
      this.submitted.emit();
    });
  }
}
