import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  EligiblesTravelers,
  EligibleTravelerStatus,
  InvitedTravelerStatus,
} from 'app/profile/models/eligibles-travelers';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';

@Component({
  selector: 'eligible-traveler-flight-access',
  templateUrl: './eligible-traveler-flight-access.html',
  styleUrls: ['./eligible-traveler-flight-access.scss'],
})
export class EligibleTravelerFlightAccessComponent implements OnInit {
  @Input() canInvite;
  @Input() eligibleTraveler: EligiblesTravelers;
  @Input() eligibleTravelers: EligiblesTravelers[];
  @Input() flightAccessForm: UntypedFormGroup;

  readonly ELIGIBLE_TRAVELER_STATUS = EligibleTravelerStatus;
  readonly INVITED_TRAVELER_STATUS = InvitedTravelerStatus;

  constructor(public formErrorsHelper: FormErrorsHelper) {}

  eligibleTravelerDisplayName: string;
  eligibleTravelerEmail: string;

  ngOnInit() {
    this.setEligibleTravelerDisplayName();
  }

  setEligibleTravelerDisplayName() {
    this.eligibleTravelerDisplayName = `${this.eligibleTraveler.firstName} ${this.eligibleTraveler.lastName}`;
    this.eligibleTravelerEmail = `${this.eligibleTraveler.email_invitation}`;

  }

  get flightAccessEmail() {
    return this.flightAccessForm.get('email');
  }
}
