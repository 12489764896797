import { Component, Input } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserCreditHistory } from 'app/profile/models/user-credit-history.interface';
import { UserCredits } from 'app/profile/models/user-credits.interface';

@Component({
  selector: 'hotel-credits-history-mobile',
  templateUrl: './hotel-credits-history-mobile.component.html',
  styleUrl: './hotel-credits-history-mobile.component.scss'
})
export class HotelCreditsHistoryMobileComponent {
  @Input() userCreditHistory: UserCreditHistory[];
  @Input() userCredits: UserCredits;
  @Input() isUsd: boolean = true;

  icons = { faSpinner };

  constructor(private activeModal: NgbActiveModal) {}

  closeModal() {
    this.activeModal.dismiss();
  }
}
