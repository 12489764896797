import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { PaymentMethodsActions } from 'app/profile/store/actions';
import { CreditCard } from 'app/shared/models/credit-card';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import * as fromAuth from 'app/auth/store/reducers';
import * as fromProfile from 'app/profile/store/reducers';

@Component({
  selector: 'stored-payment-selection',
  templateUrl: './stored-payment-selection.html',
  styleUrls: ['./stored-payment-selection.scss']
})

export class StoredPaymentSelectionComponent implements OnInit, OnDestroy {
  @Input() paymentMethods: CreditCard[];
  @Input() allowEdit: boolean = false;
  @Input() isBusinessTravel: boolean = false;

  icons = { faCheckCircle };
  userData;
  cardToEdit: CreditCard;
  deleteCreditCard: boolean = false;
  loadingNewCards: boolean = false;
  userAccount$ = this.store.pipe(select(fromAuth.selectAuthStatusState));
  getCards$ = this.store.select((fromProfile.getPaymentMethods));
  addCardSuccess$ = this.store.select(fromProfile.getPaymentMethodsSuccess);
  addCardError$ = this.store.select(fromProfile.getPaymentMethodsError);

  private ngUnsubscribe = new Subject<void>();

  constructor(
    private activeModal: NgbActiveModal,
    private store: Store,
  ) {}

  ngOnInit(): void {
    if (this.allowEdit) {
      this.setupUserData();
      this.paymentMethods = this.setupCreditCardInfo(this.paymentMethods);
      this.listenForPaymentFailure();
      this.listenForPaymentSuccess();
      this.listenForPaymentUpdates();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  setupUserData() {
    this.userAccount$.pipe(take(1)).subscribe((userAccount) => this.userData = userAccount.user);
  }

  setupCreditCardInfo(items: CreditCard[]) {
    return items.map(card => {
      return {
        ...card,
        isPrimary: (card.preferred === '1' || items.length === 1),
        isEditable: card.is_business === '1' ? this.userData?.member?.id90_user_id === Number(card.idMembers) : true
      }
    });
  }

  listenForPaymentFailure(){
    this.addCardError$.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((error: any) => {
        this.loadingNewCards = false;
      });
  }

  listenForPaymentSuccess(){
    this.addCardSuccess$.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((response: any) => {
        if (response) {
          this.loadingNewCards = false;
        }
      });
  }

  listenForPaymentUpdates() {
    this.getCards$.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((response: any) => {
        if (response) {
          this.paymentMethods = this.setupCreditCardInfo(response);
        }
        if (response && !response.length) {
          this.activeModal.close({ noCardsAvailable: true });
        }
      });
  }

  onEditOpen(card) {
    this.cardToEdit = card;
    this.deleteCreditCard = false;
  }

  onPaymentMethodDeleted(card) {
    this.deleteCreditCard = true;
    this.cardToEdit = card;
  }

  selectCard(card: CreditCard) {
    this.activeModal.close(card);
  }

  editCardCancelPressed(event) {
    this.cardToEdit = null;
    this.deleteCreditCard = false;
  }

  updatedCard(event) {
    this.loadingNewCards = true;
    this.cardToEdit = null;
    this.getPaymentMethods();

  }

  getPaymentMethods() {
    this.store.dispatch(new PaymentMethodsActions.GetAll(this.isBusinessTravel));
  }

  dismiss() {
    this.activeModal.close();
  }
}
