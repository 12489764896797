import { Component, Input } from '@angular/core';
import { faPlaneDeparture } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'payment-card',
  templateUrl: './payment-card.html',
  styleUrls: ['./payment-card.scss']
})

export class PaymentCardComponent {
  @Input() card;
  @Input() isEditableMode = false;
  icons = { faPlaneDeparture };
}
