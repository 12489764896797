<div class="card my-3 flight-watched-card">
  <div class="row mt-3">
    <div class="col-3 d-flex align-items-center justify-content-center">
      <div class="airline-image">
        <img [src]="flight.image">
      </div>
    </div>
    <div class="col">
      <div class="secondary-content">{{ flight.date }}</div>
      <span class="primary-content">
        {{ flight.flightNumber }}
        <span class="secondary-content"> - {{ flight.watchingData.bookingClassDescription }}</span>
      </span>
    </div>
  </div>
  <div class="card-body">
    <div class="row flight-data" [ngStyle]="{'background': 'linear-gradient(rgba(20,20,20,.5),rgba(20,20,20,.5)), url(' + flight.destinationImage + ')'}">
      <div class="col-6 d-flex align-items-center justify-content-left">
        <div class="departure-container">
          <div class="airport-time">{{ flight.departureName }}</div>
          <div class="airport-code">{{ flight.departureCode }}</div>
          <div class="airport-time">{{ flight.departureTime }}</div>
        </div>
      </div>
      <div class="col-6 d-flex align-items-center justify-content-end">
        <div class="arrival-container">
          <div class="airport-time">{{ flight.arrivalName }}</div>
          <div class="airport-code">{{ flight.arrivalCode }}</div>
          <div class="airport-time">{{ flight.arrivalTime }}</div>
        </div>
      </div>
    </div>
    <watcher-status [flightStatus]="flight.watchingData" [loading]="watcherLoading[item.api_id]"></watcher-status>
  </div>
  <div class="card-footer bg-transparent">
    <button *ngIf="!watcherLoading[item.api_id]" class="btn btn-primary w-100" (click)="onUpdateFlightStatusClicked()">
      {{'watchlist.notifications.flight.update' | translate }}
    </button>
    <div class="btn btn-primary w-100" *ngIf="watcherLoading[item.api_id]">{{'profile.reservations.flights.loading' | translate }}</div>
    <button class="btn btn-link w-100" (click)="onDeleteClicked()">{{ 'watchlist.delete' | translate }}</button>
  </div>
</div>