import { Injectable } from '@angular/core';
import { User } from 'app/auth/models/user';
import menuJson from 'configs/menu.json';
import { FlightHelper } from 'app/shared/helpers/flight.helper';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class MenuService {
  private user: User;
  private profileUser: any;

  constructor(private router: Router) {

  }

  getMenu(view = 'desktop', options = null, user: User, profileUser: any): any {
    this.user = user;
    this.profileUser = profileUser;

    return (menuJson || []).filter((item) => {
      if (!user.member.id) {
        return item.optionsView[view].enabled && item.showLoggedOut;
      }

      if (user.generic_user && !item.allowedGeneric) {
        return false;
      }

      return item.optionsView[view].enabled
        && item.showLoggedIn
        && this.checkFraud(item, options)
        && this.checkVerifiedMember(item, options)
        && this.checkCruiseDiscountAirline(item, options)
        && this.showFlightUserItem(item, options)
        && this.checkInviteAllowed(item, options)
        && this.checkAirlineBlogUri(item, options)
        && this.checkCobusAuthorization(item)
        && this.checkVirtualGiftCard(item, options);
    }).map((item) => {
      item.isActive = this.isMenuItemActive(item.feature);
      if (item.checkAirlineBlogUri && options.airline_blog_uri) {
        item.url = options.airline_blog_uri;
      }

      if (item.feature === 'activities') {
        const urlParams = {};
        urlParams['customerID'] = this.getViatorCustomerId();
        item.urlParams = urlParams;
      }

      return item;
    });
  }

  isMenuItemActive(feature: string): boolean {
    const url = this.router.url?.replace(/-/g, '_') || '';
    return url.includes(feature);
  }

  showFlightUserItem(item, options) {
    if (item.checkFlightUser) {
      return options.enableFlights && FlightHelper.canViewFlights(this.user);
    }
    return true;
  }

  checkCruiseDiscountAirline(item, options) {
    if (item.checkCruiseDiscountAirline && options.cruiseDiscountAirline) {
      item.url = item.urlDiscount;
    }

    return true;
  }

  checkVerifiedMember(item, options) {
    return item.checkVerifiedMember ? options.verifiedMember : true;
  }

  checkAirlineBlogUri(item, options) {
    return item.checkAirlineBlogUri ? options.airline_blog_uri : true;
  }

  checkInviteAllowed(item, options) {
    let res = true;
    // check if item is the invite a friend link and check whether the airline allows it or not
    if (typeof (item.checkInviteAllowed) !== 'undefined' && item.checkInviteAllowed) {
      res = options.inviteAllowed;
      if (res && typeof (this.user.allowed_to_invite) !== 'undefined') {
        res = this.user.allowed_to_invite;
      }
    }
    return res;
  }

  checkCobusAuthorization(item) {
    return !(item.checkCobusAuthorization && this.user.cobus_authorization);
  }

  checkVirtualGiftCard(item, options) {
    return item.checkVirtualGiftCard ? options.virtual_gift_card_enabled : true;
  }

  checkFraud(item, options) {
    return item.checkFraudUser ? !options.fraud_user : true;
  }

  getViatorCustomerId() {
    if (!this.profileUser) return;
    const airlineId = this.profileUser.organizationId;
    const memberId = this.profileUser.idMembers;
    const employeeId = this.profileUser.id;
    return `${airlineId}-${memberId}-${employeeId}`
  }
}
