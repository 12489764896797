<register-invited-form
  (submitted)="createAccount($event)"
  [errorMessage]="error$ | async"
  [pending]="pending$ | async"
  [pendingLogin]="pendingLogin$ | async"
  [airlines]="airlines$ | async"
  [countries]="countries$ | async"
  [inviteCode]="inviteCode"
  [uniqueCode]="uniqueCode"
  [inviteCodeType]="inviteCodeType"
  [inviteCodeSource]="inviteCodeSource"
  [itinerary]="itinerary"
  [defaultEmail]="defaultEmail"
  [shareCode]="shareCode"
  [travelerCode]="travelerCode"
  [eligibleTravelerInfo]="eligibleTravelerInfo"
  [referrerName]="referrerName"
  [utmMedium]="utmMedium"
  [utmSource]="utmSource"
  [utmCampaign]="utmCampaign"
  [pendingTemplate]="pendingTemplate"
  [promoCode]="promoCode"
>
</register-invited-form>
