import { HttpParams } from '@angular/common/http';
import * as moment from 'moment';

export class CarSearch {
  age: string = '';
  country: string = '';
  dropoffDate: string = '';
  dropoffLocation: string = '';
  dropoffLocationId: string = '';
  dropoffTime: string = '';
  pickupDate: string = '';
  pickupLocation: string = '';
  pickupLocationId: string = '';
  pickupTime: string = '';
  currencyCode: string = 'USD';

  doors: string[] = [];
  equipment: string[] = [];
  pageSize: number = 25;
  pageNumber: number = 1;
  passengers: string[] = [];
  size: string[] = [];
  sort: string = 'price';
  vendors: string[] = [];

  toHttpParams(): HttpParams {
    const params: HttpParams = new HttpParams()
      .set('age', this.age)
      .set('country', this.country)
      .set('pick_up_datetime', moment(`${this.pickupDate} ${this.pickupTime}`, 'MMM DD, YYYY HH:mmA').format('YYYY-MM-DDTHH:mm:ss'))
      .set('drop_off_datetime', moment(`${this.dropoffDate} ${this.dropoffTime}`, 'MMM DD, YYYY HH:mmA').format('YYYY-MM-DDTHH:mm:ss'))
      .set('pick_up_location', this.pickupLocation)
      .set('pick_up_location_id', this.pickupLocationId)
      .set('drop_off_location', this.dropoffLocation)
      .set('drop_off_location_id', this.dropoffLocationId)
      .set('sort', this.sort)
      .set('currency_code', this.currencyCode);

    if (this.doors.length > 0) {
      for (const door of this.doors) {
        params.append('doors[]', door.toString());
      }
    }
    if (this.equipment.length > 0) {
      for (const equip of this.equipment) {
        params.append('equipment[]', equip.toString());
      }
    }
    if (this.passengers.length > 0) {
      for (const passenger of this.passengers) {
        params.append('passengers[]', passenger.toString());
      }
    }
    if (this.size.length > 0) {
      for (const s of this.size) {
        params.append('size[]', s.toString());
      }
    }
    if (this.vendors.length > 0) {
      for (const vendor of this.vendors) {
        params.append('vendors[]', vendor.toString());
      }
    }

    return params;
  }
}
