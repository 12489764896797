import { environment } from '@env/environment';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecommendedDestinationsService {
  constructor (
    private http: HttpClient
  ) {}

  get(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/hotels/top_destinations.json`, {});
  }
}
