<section class="py-5" *ngIf="failStoryBlok && !loading">
  <div class="container">
    <div class="p-3 terms-section">
      <div [innerHTML]="content | translate" *ngIf="!isPdf"></div>
      <div *ngIf="isPdf && urlSafe">
        <iframe [src]="urlSafe" frameborder="0"></iframe>
    </div>
    </div>
  </div>
</section>
<div>
  <dynamic-page *ngIf="folder && !loading" [slugName]="page" [folder]="folder" (notFound)="noContent()" class="row d-flex justify-content-center "></dynamic-page>
</div>
<div>
  <loading-indicator  *ngIf="loading"></loading-indicator>
</div>
