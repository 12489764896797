import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { DestinationImagesService } from 'app/shared/services/destination-images/destination-images.service';
import { TranslateService } from '@ngx-translate/core';
import { HotelSearch } from 'app/shared/models/hotel-search';
import { filter, take } from 'rxjs/operators';
import { getAirportByCode, getAirportsPending } from 'app/shared/store/reducers';
import { Store } from '@ngrx/store';

@Component({
  selector: 'need-a-room-card',
  templateUrl: './need-a-room-card.html'
})

export class NeedARoomCardComponent implements OnInit {
  city;
  coords;
  dates;
  hotelSearch;
  image;
  title;
  type = 'need-a-room';
  @Input() data;
  @Output() clicked = new EventEmitter();
  defaultImage = '/angular_assets/images/common/default-city.jpg';

  constructor(
    private destinationImagesService: DestinationImagesService,
    private store: Store,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.hotelSearch = new HotelSearch();
    this.store.select(getAirportsPending).pipe(take(1), filter(pending => !pending)).subscribe(pending => {
      this.store.select(getAirportByCode(this.data.airport_code)).pipe(take(1)).subscribe(airport => {
        this.coords = airport?.coordinates;
        this.hotelSearch.latitude = this.coords?.latitude;
        this.hotelSearch.longitude = this.coords?.longitude;
        this.retrieveImages();
      });
    });
    this.city = this.data.city;
    this.title = `${this.translateService.instant('lodging.hotels.need_hotel_destination')} ${this.city}?`;
    const checkinDate = moment.utc(this.data.estimated_checkin);
    const checkoutDate = moment.utc(this.data.estimated_checkout);
    this.hotelSearch.destination = this.data.city;
    this.hotelSearch.checkin = checkinDate.format('MMM DD, YYYY');
    this.hotelSearch.checkout = checkoutDate.format('MMM DD, YYYY');
    this.hotelSearch['guests[]'] = '1';
    this.dates = {
      checkin: checkinDate.format('MMM D'),
      checkout: checkoutDate.format('MMM D')
    };
  }

  retrieveImages() {
    this.destinationImagesService.images(this.coords).pipe(take(1)).subscribe(
      (data) => {
        data.forEach((imageData) => {
          if (imageData != null) {
            this.image = `https:${imageData.image}`;
          }
        });
      });
  }

  onClicked() {
    this.clicked.emit({ path: 'hotels/search', data: this.hotelSearch });
  }
}
