import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';
import { GeocodingService, GeocoderAddress, GeocoderLocation } from '../geocoding.service';

declare const google: any;

@Injectable({
  providedIn: 'root'
})
export class GoogleGeocodingService implements GeocodingService {
  autocompleteService;
  placesService;
  geocoder;
  sessionToken;

  constructor(
    private http: HttpClient
  ) { }

  getLocations(input: string, type: string): Observable<GeocoderLocation[]> {
    if (input === '') {
      return of([]);
    }

    if (type === 'hotels' || type === 'address' || type === 'airport') {
      return this.getGeoLocations(input);
    }

    if (type === 'cars') {
      return this.http.get<any>(`${environment.apiUrl}/api/v1/car_locations.json`, { params: { query: input } }).pipe(
        map((resp) => {
          const places = [];
          resp.data.forEach((place) => {
            places.push({ ...place, displayName: place.name });
          });
          return places;
        })
      );
    }
  }

  getGeoLocations(input: string): Observable<GeocoderLocation[]> {
    return Observable.create((observer) => {
      // NOTE: We are essentially removing IP bias for autocomplete by biasing to
      // lat/lng 0,0 with a radius of 1 meter--essentially meaning no bias is applied.
      if (!this.sessionToken) {
        this.sessionToken = new google.maps.places.AutocompleteSessionToken();
      }
      this.autocompleteService = new google.maps.places.AutocompleteService();
      this.placesService = new google.maps.places.PlacesService(document.createElement('div'));
      this.geocoder = new google.maps.Geocoder;
      this.autocompleteService.getPlacePredictions(
        {
          input,
          types: [],
          location: new google.maps.LatLng({ lat: 0, lng: 0 }),
          radius: 1,
          sessionToken: this.sessionToken
        },
        (predictions) => {
          const places = [];
          if (predictions) {
            predictions.forEach((place) => {
              places.push({ ...place, displayName: place.description });
            });
          }
          observer.next({ results: places });
          observer.complete();
        }
      );
    }).pipe(
      map((response) => {
        return response['results'];
      })
    );
  }

  getLocationDetails(place: any): Observable<GeocoderAddress> {
    return Observable.create((observer) => {
      this.placesService.getDetails({ placeId: place.place_id }, (result) => {
        const address = {} as GeocoderAddress;
        result.address_components.forEach((comp) => {
          if (comp.types.indexOf('street_number') >= 0) {
            address.street = comp.long_name;
          }

          if (comp.types.indexOf('route') >= 0) {
            address.street += ` ${comp.long_name}`;
          }

          if ((comp.types.indexOf('locality') >= 0 || comp.types.indexOf('postal_town') >= 0)) {
            address.city = comp.long_name;
          }

          if (comp.types.indexOf('postal_code') >= 0) {
            address.zip = comp.long_name;
          }

          if (comp.types.indexOf('country') >= 0) {
            address.country = comp.short_name;
          }

          if (comp.types.indexOf('administrative_area_level_1') >= 0) {
            address.state = comp.short_name;
          }
        });

        observer.next(address);
        observer.complete();
      });
    });
  }

  getLocationCoords(place: any) {
    return Observable.create((observer) => {
      this.placesService.getDetails(
        {
          placeId: place.place_id,
          fields: ['geometry.location', 'formatted_address'],
          sessionToken: this.sessionToken
        },
        (result, status) => {
          if (status === 'OK' && result) {
            observer.next({
              lat: result.geometry.location.lat(),
              lng: result.geometry.location.lng(),
              displayText: result.formatted_address
            });
            observer.complete();
          }
          this.clearSessionToken();
        }
      );
    });
  }

  clearSessionToken() {
    this.sessionToken = null;
  }
}
