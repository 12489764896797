import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from 'app/shared/services/profile/profile.service';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import { ProfileHelper } from 'app/shared/helpers/profile.helper';
import { faChevronLeft, faCalendar, faCircleNotch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'employment-information',
  templateUrl: './employment-information.html'
})

export class EmploymentInformationComponent implements OnInit {
  @Input() profileForm;
  @Input() profileConfig;
  @Output() showTabs = new EventEmitter();
  @Output() updateProfile = new EventEmitter();

  employmentTypeOptions = [];
  employmentStatusOptions = [];
  travelStatusOptions = [];
  minDate = {};
  maxDate = {};
  startHireDate = '';
  startSeniorityDate = '';
  loading = false;
  success = null;
  error = null;
  icons = { faChevronLeft, faCalendar, faCircleNotch };

  constructor(
    public translateService: TranslateService,
    private profileService: ProfileService,
    public formErrorsHelper: FormErrorsHelper,
    private profileHelper: ProfileHelper
  ) {}

  ngOnInit() {
    this.loadInfo();
    this.setSelectOptions();
  }

  loadInfo() {
    this.minDate = { year: 1900, month: 1, day: 1 };
    this.maxDate = { year: moment().year(), month: moment().month(), day: moment().day() };
    this.startHireDate = this.profileForm.get('employment').get('hireDate').value || '';
    this.startSeniorityDate = this.profileForm.get('employment').get('seniorityDate').value || '';
  }

  setSelectOptions() {
    const empTypeOptions$ = this.translateService.get('profile.info.emp_type_options');
    const empType$ = this.translateService.get('profile.info.employmentType');
    forkJoin([empTypeOptions$, empType$]).pipe(take(1)).subscribe((response) => {
      this.employmentTypeOptions.push({ value: '', label: response[1] });
      for (const option of response[0]) {
        this.employmentTypeOptions.push({ value: option[1], label: option[0] });
      }
    });

    const empStatusOptions$ = this.translateService.get('profile.info.emp_status_options');
    const empStatusLabel$ = this.translateService.get('profile.info.select');
    forkJoin([empStatusOptions$, empStatusLabel$]).pipe(take(1)).subscribe((response) => {
      this.employmentStatusOptions.push({ value: '', label: response[1] });
      for (const option of response[0]) {
        this.employmentStatusOptions.push({ value: option[1], label: option[0] });
      }
    });

    this.translateService.get('profile.info.travel_status_options').pipe(take(1)).subscribe((travelStatusOptions) => {
      for (const option of travelStatusOptions) {
        this.travelStatusOptions.push({ value: option[1], label: option[0] });
      }
    });
  }

  onEditEmployment(): void {
    if (this.isValid()) {
      this.loading = true;
      this.success = null;
      this.error = null;

      const params = this.profileForm.controls['employment'].value;

      this.profileService.updateProfile(params).pipe(take(1)).subscribe(
        () => {
          this.loading = false;
          this.success = 'profile.info.successful_update';
          this.updateProfile.emit(params);
        },
        (error) => {
          this.error = error.error.error_description;
          this.loading = false;
        }
      );
    } else {
      Object.keys(this.profileForm.controls['employment'].controls).forEach((field) => {
        const control = this.profileForm.controls['employment'].get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  isValid() {
    return this.profileForm.controls['employment'].valid;
  }

  onHireDateSelected(e) {
    const date = `${e.year}/${e.month}/${e.day}`;
    const dateSelected = moment(date).format('YYYY-MM-DD');
    this.profileForm.controls['employment'].controls['hireDate'].setValue(dateSelected);
    this.startHireDate = dateSelected;
  }

  formatStartHireDate() {
    return this.profileHelper.formatStartDate(this.startHireDate);
  }

  onSeniorityDateSelected(e) {
    const date = `${e.year}/${e.month}/${e.day}`;
    const dateSelected = moment(date).format('YYYY-MM-DD');
    this.profileForm.controls['employment'].controls['seniorityDate'].setValue(dateSelected);
    this.startSeniorityDate = dateSelected;
  }

  formatStartSeniorityDate() {
    return this.profileHelper.formatStartDate(this.startSeniorityDate);
  }

  closeAlert() {
    this.error = null;
    this.success = null;
  }

  backToTabs() {
    this.showTabs.emit();
  }

  get employmentType()  { return this.profileForm.controls['employment'].get('employmentType'); }
  get employmentStatus()  { return this.profileForm.controls['employment'].get('employmentStatus'); }
  get travelStatus() { return this.profileForm.controls['employment'].get('travelStatus'); }
}
