import { Component, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'all-inclusive',
  templateUrl: './all-inclusive-section.html',
  styleUrls: ['./all-inclusive-section.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AllInclusiveSectionComponent implements OnChanges {
  @Input() allInclusiveDestinations = [];
  destinations: any[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if (changes.allInclusiveDestinations && changes.allInclusiveDestinations.currentValue) {
      this.generateDestinations();
    }
  }

  generateDestinations() {
    this.allInclusiveDestinations.forEach((country) => {
      for (const destination of country.destinations) {
        this.destinations.push(destination);
      }
    });
  }
}
