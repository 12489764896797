<section id="profile" class="py-5 default-background">
  <loading-indicator *ngIf="loading"></loading-indicator>
  <div class="container" *ngIf="!loading">
      <div *ngIf="!hideTabs" class="profile-title primary-content d-block d-md-none">{{'profile.info.title' | translate}}</div>
      <div id="profile-tabs" [class]="hideTabs ? 'hide-tabs' : 'show-tabs'">
        <ul ngbNav #nav="ngbNav" class="nav-tabs justify-content-center" (click)="onTabChange($event)" [orientation]="currentOrientation" >
          <li [ngbNavItem]="'edit'" id="edit" *featureFlag="featureFlags.EDIT_PROFILE">
            <a ngbNavLink>
              {{'profile.info.edit' | translate}}
            </a>
            <ng-template ngbNavContent>
              <edit-profile
                *ngIf="profileConfig"
                [countries]="countries$ | async"
                [profileConfig]="profileConfig$ | async "
                [profileForm]="profileForm"
                [memberSavings]="memberSavings$ | async"
                [memberLoyalty]="memberLoyaltySummary$ | async"
                [userCredits]="userCredits$ | async"
                [userStatistics]="userStatistics"
                [isInvitedTraveler]="isInvitedTraveler"
                [isFraudUser]="isFraudUser"
                [requestMFA]="requestMFA"
                [loginEnabled]="loginEnabled"
                [userData]="userData"
                (showTabs)="onShowTabs()"
                (updateProfile)="onUpdateProfile($event)">
              </edit-profile>
            </ng-template>
          </li>
          <li [ngbNavItem]="'address'" id="address"  *featureFlag="featureFlags.ADDRESS">
            <a ngbNavLink data-cy="mailing-address">
              {{'nav.contact.mailing_address' | translate}}
            </a>
            <ng-template ngbNavContent *ngIf="addressInfo">
              <div class="row">
                <div class="col-12">
                  <profile-address
                    *ngIf="profileConfig"
                    [profileConfig]="profileConfig$ | async"
                    [profileForm]="profileForm"
                    [countries]="countries$ | async"
                    (updateProfile)="onUpdateProfile($event)"
                    (showTabs)="onShowTabs()">
                  </profile-address>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="'employment'" id="employment" *ngIf="partner">
            <a ngbNavLink>
              {{'profile.info.employment' | translate}}
            </a>
            <ng-template ngbNavContent>
              <div class="row">
                <div class="col-12">
                  <employment-information (updateProfile)="onUpdateProfile($event)" [profileConfig]="profileConfig$ | async" [profileForm]="profileForm" *ngIf="profileConfig" (showTabs)="onShowTabs()"></employment-information>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="'passport'" id="passport" *ngIf="showPassport && partner" data-cy="passport">
            <a ngbNavLink>
              {{'profile.info.passport' | translate}}
            </a>
            <ng-template ngbNavContent>
              <div class="row">
                <div class="col-12">
                  <passport (updateProfile)="onUpdateProfile($event)" [profileConfig]="profileConfig$ | async" [profileForm]="profileForm" [countries]="countries$ | async" *ngIf="profileConfig" (showTabs)="onShowTabs()"></passport>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="'communication_preferences'" id="communication_preferences" *ngIf="!hideCommunicationPreferences">
            <a ngbNavLink>
              {{'communication_preferences.title' | translate}}
            </a>
            <ng-template ngbNavContent>
              <div class="row">
                <div class="col-12">
                  <communication-preferences
                    [allInterests]="allInterests"
                    [profileUser]="profileUser"
                    [pending]="memberInterestsPending$ | async"
                    [mailchimpPreferencesForm]="mailchimpPreferencesForm"
                    (showTabs)="onShowTabs()">
                  </communication-preferences>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="'travelers'" id="travelers" *ngIf="showEligibleTravelers && partner">
            <a ngbNavLink  data-cy="eligiblesProfileTab">
              {{'mobile.flights.eligible_travelers' | translate}}
            </a>
            <ng-template ngbNavContent>
              <div class="row">
                <div class="col-12">
                  <eligible-travelers (showTabs)="onShowTabs()" [configFlags]="configFlags$ | async" [featureFlags]="featureFlags$ | async"></eligible-travelers>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="'payment_methods'"  id="payment_methods" *featureFlag="featureFlags.PAYMENT_METHODS">
            <a ngbNavLink data-cy="payment-methods">
              {{'profile.info.payment_methods' | translate}}
            </a>
            <ng-template ngbNavContent>
              <div class="row">
                <div class="col-12">
                  <payment-methods
                    [paymentMethods]="paymentMethods"
                    [user]="profileUser"
                    [userTools]="userTools$ | async"
                    [requestMFA]="requestMFA"
                    [userData]="userData"
                    (refreshPaymentMethods)="onRefreshPaymentMethods()"
                    (showTabs)="onShowTabs()">
                  </payment-methods>
                </div>
              </div>
            </ng-template>
          </li>
          <ng-container *ngIf="showLoginAndSecurity()">
            <li [ngbNavItem]="'login_and_security'" id="login_and_security" *ngIf="!isFraudUser">
              <a ngbNavLink data-cy="login-and-security">
                {{'profile.info.login_and_security' | translate}}
              </a>
              <ng-template ngbNavContent>
                <div class="row">
                  <div class="col-12">
                    <login-and-security
                      [requestMFA]="requestMFA"
                      [profileForm]="profileForm"
                      (showTabs)="onShowTabs()"
                      (logout)="logout()">
                    </login-and-security>
                  </div>
                </div>
              </ng-template>
            </li>
          </ng-container>
          <ng-container *featureFlag="featureFlags.MEMBER_REWARDS">
            <li [ngbNavItem]="'hotel_credits'"  id="hotel_credits" *ngIf="!isFraudUser">
              <a ngbNavLink>
                {{ 'profile.info.sections.credits' | translate }} |
                <span class="secondary-color" *ngIf="!loadingCredits">
                  {{ (userCredits$ | async).displayAmount | currency:(userCredits$ | async).currency:'symbol-narrow':'1.2-2' }}
                </span>
                <div class="spinner-border text-primary" role="status" *ngIf="loadingCredits">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </a>
              <ng-template ngbNavContent>
                <div class="row">
                  <div class="col-12">
                    <hotel-credits
                      *ngIf="profileConfig"
                      [userCredits]="userCredits$ | async"
                      [userCreditHistory]="(userCreditHistory$ | async) || []"
                      (updateProfile)="onUpdateProfile($event)"
                      (showTabs)="onShowTabs()">
                    </hotel-credits>
                  </div>
                </div>
              </ng-template>
            </li>
          </ng-container>
          <li [ngbNavItem]="'suite_dreams_sweepstakes'"  id="suite_dreams_sweepstakes" *ngIf="!isFraudUser && gamificationSectionEnable">
            <a ngbNavLink>
              {{ 'profile.info.sections.suite_dreams_sweepstakes' | translate }}
            </a>
            <ng-template ngbNavContent>
              <div class="row">
                <div class="col-12">
                  <div class="profile-title primary-content">
                    {{ "gamification.my_activity" | translate }}
                </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <gamification-profile-section
                    [activeCampaigns]="activeCampaigns$ | async"
                    [airlines]="airlines$ | async"
                    [userAccount]="userAccount$ | async"
                    [statistics]="getGamificationStatistics$ | async"
                    (onCloseGame)="onRefreshCredits()"
                  >
                  </gamification-profile-section>
                </div>
              </div>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
      </div>
      <div class="clear-both"></div>
  </div>
</section>
