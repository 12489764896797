import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { HotelsRecentViewsActions, HotelsRecentViewsApiActions } from '../actions';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { RecentViewsService } from 'app/shared/services/recent-views/recent-views.service';

@Injectable()
export class HotelsRecentViewsEffects {

  load$ = createEffect(()=>
    this.actions$.pipe(
      ofType<HotelsRecentViewsActions.Load>(HotelsRecentViewsActions.HotelsRecentViewsActionTypes.Load),
      switchMap(() =>
        this.recentViewsService.hotels().pipe(
          map(res => new HotelsRecentViewsApiActions.LoadSuccess({ destinations: res.data })),
          catchError(error => of(new HotelsRecentViewsApiActions.LoadFailure(error)))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private recentViewsService: RecentViewsService
  ) {}
}
