import { FlightWatchlist } from 'app/profile/models/flight-watchlist';
import { FlightWatchlistActions, FlightWatchlistApiActions } from '../actions';

export interface State {
  flightWatchlists: FlightWatchlist[] | null;
  pending: any[];
  error: any | null;
  loaded: boolean;
}

export const initialState: State = {
  flightWatchlists: [],
  pending: [],
  error: null,
  loaded: false
};

export function reducer(
  state = initialState,
  action: FlightWatchlistApiActions.FlightWatchlistApiActionsUnion
    | FlightWatchlistActions.FlightWatchlistActionsUnion
) {
  switch (action.type) {
    case FlightWatchlistActions.FlightWatchlistActionTypes.Add:
      return {
        ...state,
        flightWatchlists: state.flightWatchlists,
        pending: [...state.pending, action.payload.watchlist],
        error: null
      };
    case FlightWatchlistActions.FlightWatchlistActionTypes.Remove:
      const removed = state.flightWatchlists.filter(watchlist => watchlist.api_id === action.payload.watchlist);
      return {
        ...state,
        flightWatchlists: state.flightWatchlists,
        pending: [...state.pending, removed[0]],
        error: null
      };
    case FlightWatchlistApiActions.FlightWatchlistApiActionTypes.RemoveFlightWatchlistSuccess:
      return {
        ...state,
        flightWatchlists: state.flightWatchlists.filter(watchlist => watchlist.api_id !== action.payload.watchlist),
        pending: [],
        error: null
      };
    case FlightWatchlistApiActions.FlightWatchlistApiActionTypes.RemoveFlightWatchlistFailure:
      return {
        ...state,
        flightWatchlists: state.flightWatchlists,
        pending: [],
        error: action.payload.error
      };
    case FlightWatchlistApiActions.FlightWatchlistApiActionTypes.LoadFlightWatchlistSuccess:
      return {
        flightWatchlists: action.payload.watchlist,
        pending: [],
        error: null,
        loaded: true
      };
    default:
      return state;
  }
}

export const getFlightWatchlists = (state: State) => state.flightWatchlists;
export const getWatchlistPending = (state: State) => state.pending;
export const getWatchlistError = (state: State) => state.error;
export const getWatchlistLoaded = (state: State) => state.loaded;
