import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';
import { CustomValidators } from 'app/shared/validators/custom-validators';
import { PhoneNumberHelper } from 'app/shared/helpers/phone-number.helper';
import { ProfileHelper } from 'app/shared/helpers/profile.helper';
import { PhoneNumberMapper } from 'app/profile/mappers/phone-number.mapper';
import { AirlinesService } from 'app/shared/services/airlines/airlines.service';
import  rules  from 'configs/rules.json';
import * as moment from 'moment';

@Component({
  selector: 'register-form',
  templateUrl: './register-form.html',
  styleUrls: ['./register-form.scss']
})
export class RegisterFormComponent implements OnInit, OnChanges {
  @Output() clearErrorMessage = new EventEmitter();
  @Output() submitted = new EventEmitter();
  @Output() showMessage = new EventEmitter();
  @Input() errorMessage: string | null;
  @Input() pendingLogin;
  @Input() pending;
  @Input() inviteCode = '';
  @Input() uniqueCode = '';
  @Input() travelerCode = '';
  @Input() inviteCodeType = '';
  @Input() inviteCodeSource = '';
  @Input() utmSource = '';
  @Input() utmMedium = '';
  @Input() utmCampaign = '';
  @Input() airlines;
  @Input() shareCode;
  @Input() countries;

  private selectedAirline = null;
  validEmailDomains = ['id90travel.com'];
  airlineEmailDomains = this.validEmailDomains;
  createAccountForm: UntypedFormGroup;
  phoneError = false;
  airlineRules;
  isWhiteLabel = false;
  minDate;
  maxDate;
  startDate;

  constructor(
    public formErrorsHelper: FormErrorsHelper,
    public translate: TranslateService,
    public router: Router,
    private phoneNumberHelper: PhoneNumberHelper,
    private profileHelper: ProfileHelper,
    private airlinesService: AirlinesService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.fetchRulesAndInitializeForm();
  }

  private fetchRulesAndInitializeForm(): void {
    const airlineCode = this.airlinesService.getWhiteLabelAirlineCode(this.airlines) || 'default';
    this.airlineRules = rules['create_account'][airlineCode] || rules['create_account']['default'];
    this.isWhiteLabel = this.airlinesService.isWhiteLabelON(this.airlines);
    this.initializeForm();
  }

  private initializeForm(): void {
    const controlsConfig = this.getFormControlsConfig();
    this.createAccountForm = this.formBuilder.group(controlsConfig);
    this.configureWhiteLabelSettings();
    this.addConditionalControls();
    this.setFormStateBasedOnLoginStatus();
  }

  private getFormControlsConfig(): { [key: string]: any } {
    const controlsConfig = this.airlineRules.form.fields.reduce((acc, field) => {
      if (field.name === 'homePhone') return acc; // Skip homePhone to handle it separately

      const validators = this.getFieldValidators(field);
      acc[field.name] = new UntypedFormControl('', validators);
      return acc;
    }, {});
    const defaultControls = this.setDefaultControls();
    return {
      ...controlsConfig,
      ...defaultControls
    };
  }

  private setDefaultControls(): { [key: string]: any } {
    return {
      employee_number: ['', [Validators.required, CustomValidators.textWithoutBlankSpaces]],
      airline_code: ['', Validators.required],
      organization_id: ['', Validators.required],
      accept_terms_of_service: [true],
      email_opt_out: [false],
      share_code: [''],
      invite_code: [this.inviteCode],
      traveler_code: [this.travelerCode],
      unique_code: [this.uniqueCode],
      invite_code_type: [this.inviteCodeType],
      invite_code_source: [this.inviteCodeSource],
      utm_source: [this.utmSource],
      utm_medium: [this.utmMedium],
      utm_campaign: [this.utmCampaign]
    };
  }

  configureWhiteLabelSettings() {
    if (!this.isWhiteLabel) return;
    const airlineData = this.airlinesService.getAirlineData(this.airlines, this.airlinesService.getWhiteLabelAirlineCode(this.airlines));
    if (!airlineData) return;
    this.createAccountForm.patchValue({
      airline_code: airlineData.code,
      organization_id: airlineData.id,
    });
    return this.airlineSelected(airlineData);
  }

  setFormStateBasedOnLoginStatus(): void {
    if (this.pendingLogin) {
      this.createAccountForm.disable();
    } else {
      this.createAccountForm.enable();
    }
  }

  private getFieldValidators(field: any) {
    const validators = [];
    if (field.required) validators.push(Validators.required);
    if (field.type === 'email') {
      validators.push(Validators.email, CustomValidators.emailDomain(this.airlineEmailDomains));
    }
    if (field.type === 'password') validators.push(CustomValidators.password);
    return validators;
  }

  private addConditionalControls(): void {
    if (this.shouldAddPhoneControl()) {
      this.createPhoneFormGroup();
    }
    if (this.createAccountForm.get('birth_date')) {
      this.loadBirthDate();
    }
  }

  private shouldAddPhoneControl(): boolean {
    return this.createAccountForm && this.countries && this.airlineRules.form.fields.some(field => field.name === 'homePhone');
  }

  createPhoneFormGroup(): void {
    if (!this.countries) return;
    const phoneControl = this.phoneNumberHelper.createPhoneFormGroup(false, false);
    phoneControl.setValue({
      country: { code: 'US', code3: 'US', currency: "USD", name: "United States of America", phone: 1 },
      area: '',
      number: ''
    });
    this.createAccountForm.addControl('homePhone', phoneControl);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.countries && this.shouldAddPhoneControl()) {
      this.createPhoneFormGroup();
    }
  }

  createAccount(): void {
    if (!this.createAccountForm.controls['employee_number'].valid && !this.displayEmployeeNumber) {
      this.createAccountForm.controls['employee_number'].setValue(this.createAccountForm.get('email').value);
    }
    if (!this.createAccountForm.valid) {
      this.markAllAsTouched();
      return;
    }

    const formValue = this.prepareSubmissionValue();
    this.submitted.emit(formValue);
  }

  private markAllAsTouched(): void {
    Object.values(this.createAccountForm.controls).forEach(control => {
      control.markAsTouched();
    });
  }

  private prepareSubmissionValue(): any {
    const submissionValue = { ...this.createAccountForm.value };
    if (submissionValue.homePhone) {
      submissionValue.homePhone = this.getPhoneFormatted(submissionValue.homePhone);
    }
    return submissionValue;
  }

  airlineSelected(airline) {
    if (airline.partner) {
      return this.router.navigate([`/access-account/${airline.code}`]);
    }

    this.selectedAirline = airline;
    this.airlineEmailDomains = airline.email_domains ?
      this.validEmailDomains.concat(airline.email_domains) :
      this.validEmailDomains;
    this.checkEmailDomain();
  }

  checkEmailDomain() {
    if (this.isWhiteLabel && this.validEmailDomains.length === 1) {
      this.createAccountForm.controls['email'].setValidators([Validators.email]);
    } else {
      this.createAccountForm.controls['email'].setValidators([
        Validators.email, CustomValidators.emailDomain(this.airlineEmailDomains)
      ]);
    }
  }

  closeAlertEvent() {
    this.clearErrorMessage.emit();
  }

  getFieldControl(fieldName: string) {
    return this.createAccountForm?.get(fieldName);
  }

  get displayEmployeeNumber() {
    let result = true;
    if (this.selectedAirline) {
      if (typeof (this.selectedAirline.employee_number_required) !== 'undefined') {
        result = this.selectedAirline.employee_number_required;
      }
    }
    return result;
  }

  setEmployeeNumber() {
    if (!this.displayEmployeeNumber) {
      this.createAccountForm.controls['employee_number'].setValue(this.createAccountForm.get('email').value);
    }
  }

  checkHomePhone() {
    this.phoneError = false;
    if (!this.createAccountForm.get('homePhone').valid) {
      this.phoneError = true;
    }
  }

  getPhoneFormatted(rawPhone) {
    const country = rawPhone.country?.code || 'US';
    const number = rawPhone?.number || '';
    if (number.length === 0) { return '||' };
    const objPhone = this.profileHelper.formatPhoneService(country, number);
    return new PhoneNumberMapper().fromObject().toString(objPhone);
  }

  redirectToLogin() {
    return this.router.navigate(['/login']);
  }

  loadBirthDate() {
    this.minDate = { year: 1900, month: 1, day: 1 };
    this.maxDate = { year: moment().year(), month: moment().month(), day: moment().day() };
    this.startDate = this.createAccountForm.get('birth_date').value || '';
  }

  formatStartDate() {
    if (typeof(this.startDate) !== 'string' && typeof(this.startDate) !== 'undefined') {
      this.startDate = `${this.startDate.year}-${this.startDate.month}-${this.startDate.day}`;
    }
    return this.profileHelper.formatStartDate(this.startDate);
  }

  onDatesSelected(e) {
    const date = `${e.year}/${e.month}/${e.day}`;
    const dateSelected = moment(date).format('YYYY-MM-DD');
    this.createAccountForm.get('birth_date').setValue(dateSelected);
    this.startDate = dateSelected;
  }
}
