import { Injectable } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { CustomValidators } from '../validators/custom-validators';
import { GeneralHelper } from './general.helper';
import { CountryCode, isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';

@Injectable({
  providedIn: 'root'
})
export class PhoneNumberHelper {

  constructor(
    private formBuilder: UntypedFormBuilder,
    private generalHelper: GeneralHelper
  ) {}

  getCountryCode(phoneNumber: String) {
    if (phoneNumber) {
      return phoneNumber.split('|')[0];
    }

    return '';
  }

  getLocalPhoneNumber(phoneNumber: String) {
    if (phoneNumber && phoneNumber.indexOf('|') > -1) {
      const countryCodeIndex = phoneNumber.indexOf('|');
      const localPhoneNumber = phoneNumber.substr(countryCodeIndex + 1);
      return localPhoneNumber.replace(/\|/g, '');
    }
  }

  getInternationalPhoneNumber(phoneNumber: String) {
    if (phoneNumber && phoneNumber.indexOf('|') > -1) {
      return `+${phoneNumber.split('|')[0]}${phoneNumber.split('|')[1]}${(phoneNumber.split('|')[2])}`;
    }
  }

  formatPhoneNumberOnlyNumbers(phoneNumber: string) {
    return phoneNumber.replace(/[|() -]/g, '');
  }

  formatPhoneNumberToString(phoneNumber: string, hide = false) {
    if (!phoneNumber) return null;
    const phone = `+${phoneNumber.split('|')[0]} (${phoneNumber.split('|')[1]}) ${(phoneNumber.split('|')[2])}`;
    if (!hide) {
      return phone;
    }
    return this.generalHelper.hideString(phone);
  }

  createPhoneFormGroup(numberRequired: boolean = true, countryRequired: boolean = true): UntypedFormGroup {
    const formGroup =  this.formBuilder.group({
      country: [{ value: '' }, countryRequired ? Validators.required : null],
      area: [{ value: '' }],
      number: [{ value: '' }, numberRequired ? Validators.required : null],
    });
    formGroup.setValidators([CustomValidators.validPhone, CustomValidators.phoneElement]);
    return formGroup;
  }


  phoneObject(phoneNumber) {
  try {
    const phoneCountryCode = this.getCountryCode(phoneNumber);
    const localNumber = this.getLocalPhoneNumber(phoneNumber);
    return {
      countryCode: phoneCountryCode,
      number: parsePhoneNumber(`+${phoneCountryCode}${localNumber}`)
    };
  } catch (error) {
    return false
  }
  }

  isValid(phone: any, strictValidation?: any): boolean {
      return !strictValidation ? this.basicValidation(phone) : this.strictValidation(phone, strictValidation.countryCode);
  }

  private strictValidation(phone, country: CountryCode) {
      return isValidPhoneNumber(phone, country);
  }

  private basicValidation(phone) {
    return (phone !== null && typeof phone === 'object' && phone.country && phone.area && phone.number) ||
    (typeof phone === 'string' && phone.split('|').length === 3 && phone.split('|').indexOf('') === -1);
  }
}
