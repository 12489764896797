import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SimpleModalComponent } from '../simple-modal/simple-modal.component';

@Component({
  selector: 'simple-modal-link',
  templateUrl: 'simple-modal-link.component.html'
})
export class SimpleModalLinkComponent {
  @Input() linkText: string;
  @Input() title: string;
  @Input() content: string;
  @Input() config: any = {};
  @Input() inLine: boolean;
  constructor(private modalService: NgbModal) {}

  openSimpleModal() {
    const modalRef = this.modalService.open(SimpleModalComponent, this.config);
    modalRef.componentInstance.title = this.title;
    modalRef.componentInstance.content = this.content;
  }
}
