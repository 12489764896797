import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faComment, faEnvelopeOpenText, faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import { select, Store } from '@ngrx/store';
import * as fromAuth from 'app/auth/store/reducers';
import { AttemptsHelper } from 'app/shared/helpers/attempts.helper';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';
import { AirlineActions } from 'app/shared/store/actions';
import * as fromAirlines from 'app/shared/store/reducers';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'reset-password-form',
  templateUrl: './reset-password-request-form.html',
  styleUrls: ['./reset-password-request-form.scss']
})

export class ResetPasswordRequestFormComponent implements OnInit, OnDestroy {
  loading = true;
  airlineLogin = true;
  nonAirlineCode = '100';
  resetPasswordRequestForm: UntypedFormGroup;
  msgError = '';
  airlines$: any = this.store.pipe(select(fromAirlines.getAirlines));
  verifyOptionsSuccess$ = this.store.pipe(select(fromAuth.getVerifyOptionsResultSuccess));
  airlines;
  params;
  icons = { faComment, faMobileAlt, faEnvelopeOpenText };
  userNotFound = false;
  @Input() defaultAirlineCode;
  @Input() isWhiteLabel: boolean = false;
  @Output() verifyAccountAction = new EventEmitter();

  private ngUnsubscribe = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    public formErrorsHelper: FormErrorsHelper,
    private store: Store<fromAuth.State>,
    public attemptsHelper: AttemptsHelper
  ) { }

  ngOnInit() {
    this.params = this.activatedRoute.snapshot.params;
    if (this.params.type && this.params.type === 'friend') {
      this.airlineLogin = false;
    }
    this.createForm();
    this.airlines$.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((airlines) => {
      if (!airlines.length) {
        this.store.dispatch(new AirlineActions.LoadAirlines());
      } else {
        this.airlines = airlines;
        this.setDefaults();
        this.loading = false;
      }
    });
    this.listenForVerifyOptionsSuccess();
  }

  createForm() {
    if (this.airlineLogin) {
      this.resetPasswordRequestForm = this.formBuilder.group({
        airlineCode: ['', Validators.required],
        airline: ['', Validators.required],
        organizationId: ['', Validators.required],
        memberEmail: ['', Validators.required]
      });
    } else {
      this.resetPasswordRequestForm = this.formBuilder.group({
        memberEmail: ['', Validators.required]
      });
    }

    this.resetPasswordRequestForm.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(() => this.isValid());
  }

  setDefaults() {
    if (this.isWhiteLabel && this.defaultAirlineCode) {
      const whiteAirlineData = this.airlines.find(airline => airline.code === this.defaultAirlineCode);
      this.resetPasswordRequestForm.get('airlineCode').setValue(this.defaultAirlineCode.toString());
      this.resetPasswordRequestForm.get('airline').setValue(whiteAirlineData.display_name.toString());
      this.resetPasswordRequestForm.get('organizationId').setValue(whiteAirlineData.id.toString());
    }
  }

  verifyAccount() {
    if (!this.isValid()) {
      Object.keys(this.resetPasswordRequestForm.controls).forEach((field) => {
        const control = this.resetPasswordRequestForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
      return false;
    }
    this.loading = true;
    this.verifyAccountAction.emit({ credentials: this.getCredentials() });
    this.resetForm();
  }

  getCredentials() {
    if (this.airlineLogin) {
      return this.resetPasswordRequestForm.value;
    }
    const invitedAirline = this.airlines.find(airline => airline.code === this.nonAirlineCode);
    return {
      airline: invitedAirline.display_name,
      airlineCode: this.nonAirlineCode,
      memberEmail: this.resetPasswordRequestForm.value.memberEmail,
      organizationId: invitedAirline.id
    };
  }

  isValid() {
    return this.resetPasswordRequestForm.valid;
  }

  resetForm() {
    this.resetPasswordRequestForm.get('memberEmail').reset();
  }

  closeAlert() {
    this.msgError = '';
  }

  get memberEmail() {
    if (this.resetPasswordRequestForm) {
      return this.resetPasswordRequestForm.get('memberEmail');
    }
  }

  listenForVerifyOptionsSuccess() {
    this.verifyOptionsSuccess$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
      if (response && !response.user_exist) { this.userNotFound = true; }
    });
  }

  resetUser() {
    this.userNotFound = false;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
