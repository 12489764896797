import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ResetPasswordService } from 'app/auth/services/reset-password/reset-password.service';
import { LoginPageActions } from 'app/auth/store/actions';
import * as fromAuth from 'app/auth/store/reducers';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';
import { CustomValidators } from 'app/shared/validators/custom-validators';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'forgot-password-reset',
  templateUrl: './reset.html'
})

export class ForgotPasswordResetPage implements OnInit, OnDestroy {
  loading = true;
  inactive = {};
  credentials = {
    password: '',
    password_confirmation: '',
    h: ''
  };
  forgotPasswordResetForm: UntypedFormGroup;
  msgError = false;
  private ngUnsubscribe = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private store: Store<fromAuth.State>,
    public resetPasswordService: ResetPasswordService,
    public formErrorsHelper: FormErrorsHelper
  ) { }

  ngOnInit() {
    this.buildForm();
    this.activatedRoute.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => {
      this.resetPasswordService.validate(params.h).pipe(take(1)).subscribe(
        () => {
          this.credentials.h = params.h;
          this.loading = false;
        },
        () => {
          this.loading = false;
          this.translate.get('password_reset.errors.failure').pipe(take(1)).subscribe((f) => {
            const message = {
              type: 'danger',
              message: f
            };
            this.store.dispatch(new LoginPageActions.LoginShowMessage({ message }));
          });
          return this.router.navigate(['/login']);
        }
      );
    });
  }

  buildForm() {
    this.forgotPasswordResetForm = this.formBuilder.group({
      newPassword: ['', Validators.compose([Validators.required, CustomValidators.password])],
      newPasswordConfirm: ['', Validators.compose([Validators.required, CustomValidators.duplicatePassword])]
    });

    this.forgotPasswordResetForm.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(() => {
      this.isValid();
    });
  }

  submit() {
    if (!this.isValid()) {
      Object.keys(this.forgotPasswordResetForm.controls).forEach((field) => {
        const control = this.forgotPasswordResetForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
      return false;
    }

    this.credentials.password = this.forgotPasswordResetForm.value.newPassword;
    this.credentials.password_confirmation = this.forgotPasswordResetForm.value.newPasswordConfirm;

    this.resetPasswordService.update(this.credentials).pipe(take(1)).subscribe(
      () => {
        this.translate.get('mobile.password_reset.success').pipe(take(1)).subscribe((s) => {
          const message = {
            type: 'success',
            message: s
          };
          this.store.dispatch(new LoginPageActions.LoginShowMessage({ message }));
        });
        return this.router.navigate(['/login']);
      },
      () => {
        this.msgError = true;
        this.forgotPasswordResetForm.reset();
      }
    );
  }

  isValid() {
    return this.forgotPasswordResetForm.valid;
  }

  closeAlert() {
    this.msgError = false;
  }

  get newPassword()  {
    return this.forgotPasswordResetForm.get('newPassword');
  }

  get newPasswordConfirm()  {
    return this.forgotPasswordResetForm.get('newPasswordConfirm');
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
