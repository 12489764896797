import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { LanguageActions } from '../actions';
import { map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { UserPreferencesService } from 'app/shared/services/user-preferences/user-preferences.service';

@Injectable()
export class LanguageEffects {

  loadLanguages$ = createEffect(()=>
    this.actions$.pipe(
      ofType<LanguageActions.LoadLanguages>(LanguageActions.LanguageActionTypes.LoadLanguages),
      switchMap(() => {
        return this.userPreferencesService.getLanguages().pipe(
          map(languages => new LanguageActions.LoadLanguagesSuccess({ languages })),
          catchError(error => of(new LanguageActions.LoadLanguagesFailure(error)))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private userPreferencesService: UserPreferencesService,
  ) { }
}
