import { Action } from '@ngrx/store';

export enum EventRegistrationActionTypes {
  OfflineMemberRegister = '[Event Registration] Offline Member Register',
  MemberRegisterSync = '[Event Registration] Member Register Sync',
  MemberRegisterSuccess = '[Event Registration] Member Register Success',
  MemberRegisterFailure = '[Event Registration] Member Register Failure',
  MemberRegisterSendLater = '[Event Registration] Member Register send later',
  MemberRegisterSyncSuccess = '[Event Registration] Member Register Sync Success',
  MemberRegisterSyncFailure = '[Event Registration] Member Register Sync Failure',
  MemberRegisterRetry = '[Event Registration] Member Register Retry',
  MemberRegisterRemove = '[Event Registration] Member Register Remove',
  MemberRegisterClear = '[Event Registration] Member Register Clear',
  AirlineSet = '[Event Registration] Airline Set'
}

export class OfflineMemberRegister implements Action {
  readonly type = EventRegistrationActionTypes.OfflineMemberRegister;

  constructor(public payload: { credentials: any }) {}
}

export class MemberRegisterSync implements Action {
  readonly type = EventRegistrationActionTypes.MemberRegisterSync;

  constructor(public payload: { credentials: any }) { }
}

export class MemberRegisterSuccess implements Action {
  readonly type = EventRegistrationActionTypes.MemberRegisterSuccess;

  constructor(public payload: { auth: any }) {}
}

export class MemberRegisterFailure implements Action {
  readonly type = EventRegistrationActionTypes.MemberRegisterFailure;

  constructor(public payload: { error: any }) {}
}

export class MemberRegisterSyncSuccess implements Action {
  readonly type = EventRegistrationActionTypes.MemberRegisterSyncSuccess;

  constructor(public payload: { auth: any}) { }
}

export class MemberRegisterSyncFailure implements Action {
  readonly type = EventRegistrationActionTypes.MemberRegisterSyncFailure;

  constructor(public payload: { error: any, auth:any }) { }
}

export class MemberRegisterSendLater implements Action {
  readonly type = EventRegistrationActionTypes.MemberRegisterSendLater;

  constructor(public payload: { auth: any }) {}
}

export class MemberRegisterRetry implements Action {
  readonly type = EventRegistrationActionTypes.MemberRegisterRetry;

  constructor(public payload: { auth: any }) {}
}

export class MemberRegisterRemove implements Action {
  readonly type = EventRegistrationActionTypes.MemberRegisterRemove;

  constructor(public payload: { auth: any }) {}
}

export class MemberRegisterClear implements Action {
  readonly type = EventRegistrationActionTypes.MemberRegisterClear;
}

export class AirlineSet implements Action {
  readonly type = EventRegistrationActionTypes.AirlineSet;

  constructor(public payload: {airline: any, campaign: any}) {}
}

export type EventRegistrationActionsUnion = OfflineMemberRegister
  | MemberRegisterSuccess
  | MemberRegisterFailure
  | AirlineSet
  | MemberRegisterSendLater
  | MemberRegisterSync
  | MemberRegisterSyncSuccess
  | MemberRegisterSyncFailure
  | MemberRegisterRetry
  | MemberRegisterRemove
  | MemberRegisterClear;
