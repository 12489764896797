import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { CreditActions } from '../actions';
import { map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { CreditsService } from 'app/shared/services/credits/credits.service';

@Injectable()
export class CreditEffects {

  loadCredits$ = createEffect(()=>
    this.actions$.pipe(
      ofType<CreditActions.LoadCredits>(CreditActions.CreditsActionTypes.LoadCredits),
      switchMap(() =>  {
        return this.creditsService.getAvailableCredit().pipe(
          map(data => new CreditActions.LoadCreditsSuccess(data)),
          catchError(error => of(new CreditActions.LoadCreditsFailure(error)))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private creditsService: CreditsService,
  ) {}
}
