

  <form [formGroup]="childAgeForm">
    <div class="col-12 px-0 form-floating">
      <select
        id="age"
        formControlName="age"
        class="form-control tab-select form-select with-background"
        [value]="valueSelected"
        (change)="onChildAgeChanged($event.target.value)">
          <option [value]="'0'">{{ 'lodging.search_form.select_age' | translate }}</option>
          <option *ngFor="let g of childAges;" [value]="g.key">{{ g.value }}</option>
      </select>
      <label for="guests">
        {{ 'lodging.search_form.child_age' | translate }}: {{ childNumber }}
      </label>
    </div>
  </form>
