import { Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { getUser } from 'app/auth/store';
import { featureFlags } from 'app/shared/models/featureFlags';
import { AirlinesService } from 'app/shared/services/airlines/airlines.service';
import { FlagsActions } from 'app/shared/store/actions';
import { getAirlineFlags, getAirlines } from 'app/shared/store/reducers';
import { isEqual } from 'lodash';
import { Subject } from 'rxjs';
import { distinctUntilChanged, distinctUntilKeyChanged, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagsService implements OnDestroy {

  private airlineFlags$ = this.store.pipe(select(getAirlineFlags));
  private airlineFlags;
  private airlines$ = this.store.pipe(select(getAirlines));
  private airlines;
  private user$ = this.store.pipe(select(getUser));
  private user;

  private ngUnsubscribe = new Subject<void>();

  private readonly OPUSER_FEATURES = [
    featureFlags.HEADER,
    featureFlags.PROFILE_MENU,
    featureFlags.EDIT_PROFILE,
    featureFlags.TRIPS
  ];

  constructor(
    private airlinesService: AirlinesService,
    private store: Store,
  ) {
    this.loadAirlineFlags();
    this.loadAirlines();
    this.loadUser();
  }

  private loadAirlineFlags() {
    this.airlineFlags$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(airlineFlags =>
      this.airlineFlags = airlineFlags
    );
  }

  private loadAirlines() {
    this.airlines$.pipe(
      distinctUntilChanged(isEqual),
      takeUntil(this.ngUnsubscribe),
    ).subscribe(airlines => {
      this.airlines = airlines;
      if (this.user) {
        this.loadFlags();
      }
    });
  }

  private loadUser() {
    this.user$.pipe(
      distinctUntilKeyChanged('access_token'),
      takeUntil(this.ngUnsubscribe),
    ).subscribe(user => {
      this.user = user;
      if (this.airlines?.length) {
        this.loadFlags();
      }
    });
  }

  loadFlags() {
    if (this.airlines?.length) {
      let airlineCode = this.user?.member?.airline_code;
      if (!airlineCode) {
        airlineCode = this.airlinesService.getWhiteLabelAirlineCode(this.airlines) || '';
      }
      this.store.dispatch(FlagsActions.loadAirlineFlags({ code: airlineCode }));
    }
  }

  isFeatureEnabled(key: string) {
    let enabled = true;
    if (this.airlineFlags?.items.length > 0) {
      enabled = this.enableOpUserFeatures(this.user.call_center_admin, key) ||
        !!this.airlineFlags.items.find(flag => flag.status === 1 && flag.name === key);
    }
    return enabled;
  }

  private enableOpUserFeatures(isOpUser, featureFlag) {
    return isOpUser && this.OPUSER_FEATURES.includes(featureFlag);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
