import { Action } from '@ngrx/store';
import { Hotel } from 'app/hotels/models/hotel';

export enum HotelResultsCacheActionTypes {
  SaveHotels = '[Hotel Results Cache] Save Hotels',
  ClearHotels = '[Hotel Results Cache] Clear Hotels',
  SaveScrollIndex = '[Hotel Results Cache] Save Scroll Index',
  SaveFilters = '[Hotel Results Cache] Save Filters',
  ResetSearchId = '[Hotel Results Cache] Reset Search Id',
  SaveResultsView = '[Hotel Results Cache] Save Results View',
}

export class SaveHotels implements Action {
  readonly type = HotelResultsCacheActionTypes.SaveHotels;

  constructor(public payload: { hotels: Hotel[] | null, meta: any, viewRadius: any | null, defaultRadius: number, searchId: string }) {}
}

export class ClearHotels implements Action {
  readonly type = HotelResultsCacheActionTypes.ClearHotels;
}

export class SaveScrollIndex implements Action {
  readonly type = HotelResultsCacheActionTypes.SaveScrollIndex;

  constructor(public payload: { scrollIndex: number }) {}
}

export class SaveFilters implements Action {
  readonly type = HotelResultsCacheActionTypes.SaveFilters;

  constructor(public payload: any) {}
}

export class ResetSearchId implements Action {
  readonly type = HotelResultsCacheActionTypes.ResetSearchId;
}

export class SaveResultsView implements Action {
  readonly type = HotelResultsCacheActionTypes.SaveResultsView;

  constructor(public payload: { view: string }) {}
}

export type HotelResultsCacheActionUnion =
  SaveHotels | ClearHotels | SaveScrollIndex | SaveFilters | ResetSearchId | SaveResultsView;
