<header id="header" class="{{ headerClass }} header-component" [ngClass]="{'has-app-banner': isSmartBannerVisible}" *ngIf="logged">
  <div [class]="navClass">
    <smart-app-banner (isBannerVisible)="setSmartBannerVisibility($event)"></smart-app-banner>
    <app-announcement-bar
      *ngIf="showAlertIE && showAnnouncement"
      code="IE11"
      [seeNotShowAgain]="true"
      [userID]="user?.member.id90_user_id">
      <p class="m-0 p-0" body>
        <strong>{{ 'announcement_bar.ie11_announcement_title' | translate }}</strong>
        {{ 'announcement_bar.ie11_announcement_message' | translate }}
      </p>
    </app-announcement-bar>

    <nav class="navbar navbar-expand-lg navbar-light align-items-center py-2" [ngClass]="{'navbar-with-banner': isSmartBannerVisible}" id="mainNavBar">
      <button *featureFlag="featureFlags.HEADER" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-target="#mobile-menu" aria-controls="mobile-menu"
        aria-expanded="false" aria-label="Toggle navigation" (click)="isCollapsed = !isCollapsed" >
        <span class="navbar-toggler-icon"></span>
      </button>
      <a class="navbar-brand me-0 min-logo-width" [routerLink]="homeUrl">
        <img [src]="logo" class="d-inline-block align-top img-fluid id90-logo" alt="ID90 Travel" />
      </a>
      <div *featureFlag="featureFlags.HEADER" class="collapse navbar-collapse" id="mobile-menu" [ngbCollapse]="!isCollapsed">
        <language-selector-component
          *featureFlag="featureFlags.LANGUAGE"
          [languages]="languages$ | async"
          [userLanguage]="userLanguage$ | async"
          [forMobile]="true"
          (languageSelected)="onLanguageSelected($event)">
        </language-selector-component>
        <hr>
        <currency-selector
        [user]="profileUser$ | async"
        [configFlags]="configFlags$ | async"
        [forMobile]="true">
        </currency-selector>
        <hr>
        <ul class="navbar-nav collapsible collapsible-accordion mt-2" *featureFlag="featureFlags.DOWNLOAD_APP">
          <li class="nav-item">
            <a href="javascript:void(0)" (click)="handleOpenInApp()" class="nav-link">{{ 'mobile.checkout.open_in_app' | translate }}
              <toggle [value]="openInAppActive" design='column' id='openInApp' class="float-end"></toggle>
            </a>
          </li>
        </ul>
        <hr>
        <ul class="navbar-nav collapsible collapsible-accordion" (click)="mobileLinkClicked()">
          <ng-container *ngFor="let link of mobileLinks">
            <li class="nav-item" *featureFlag="link.feature">
              <a *ngIf="!link.external && !link.event" routerLink="{{ link.url | translate }}" class="{{ link.optionsView.mobile.class }} gtm-nav">{{ link.text | translate }} <fa-icon [icon]="getIcon(link.optionsView.mobile.icon)" aria-hidden="true" class="float-end"></fa-icon></a>
              <a *ngIf="link.external"
                 target="{{ link.target || '_self' }}"
                 href= "{{ needTranslation(link.feature) ? (link.url | translate) : link.url }}"
                 (click)="trackAndNavigate($event, link)"
                 class="{{ link.optionsView.mobile.class }}
                 gtm-nav">{{ link.text | translate }}
                <fa-icon [icon]="getIcon(link.optionsView.mobile.icon)" aria-hidden="true" class="float-end"></fa-icon>
              </a>
              <hr *ngIf="link.optionsView.mobile.showHr">
            </li>
          </ng-container>
        </ul>
      </div>
      <div *featureFlag="featureFlags.HEADER" id="desktop-menu" class="collapse navbar-collapse">
        <ul class="navbar-nav me-auto">
          <ng-container *ngFor="let link of desktopLinks">
            <ng-container *featureFlag="link.feature">
              <li class="nav-item" *ngIf="link.url && link.optionsView.desktop.section === 1">
                <a *ngIf="!link.external" [ngClass]="{'active': link.isActive}" class="{{ link.optionsView.desktop.class }} gtm-nav" routerLink="{{ link.url | translate}}" >
                  <span class="clearfix d-none d-sm-inline-block" [attr.data-cy]="link.optionsView.desktop.dataCy">
                    {{ link.text | translate }}
                  </span>
                </a>
                <a *ngIf="link.external" target="{{ link.target || '_self' }}" class="{{ link.optionsView.desktop.class }} gtm-nav"
                   href="{{ link.url | translate: link.urlParams }}" (click)="trackAndNavigate($event, link)">
                  <span class="clearfix d-none d-sm-inline-block">
                    {{ link.text | translate }}
                  </span>
                </a>
              </li>
            </ng-container>
          </ng-container>
        </ul>
        <ul class="navbar-nav ms-auto">
          <language-selector-component
            *featureFlag="featureFlags.LANGUAGE"
            [languages]="languages$ | async"
            [userLanguage]="userLanguage$ | async"
            (languageSelected)="onLanguageSelected($event)"
            class="me-3">
          </language-selector-component>
          <currency-selector
          [user]="profileUser$ | async"
          [profileConfig]="profileConfig$ | async"
          [configFlags]="configFlags$ | async"
          [forMobile]="false">
          </currency-selector>
          <ng-container *ngFor="let link of desktopLinks">
            <ng-container *featureFlag="link.feature">
              <li class="nav-item" *ngIf="link.url  && link.optionsView.desktop.section === 2">
                <a *ngIf="!link.external"  class="{{ link.optionsView.desktop.class }} gtm-nav" routerLink="{{ link.url | translate}}">
                <span class="clearfix d-none d-sm-inline-block">
                  {{ link.text | translate}}
                </span>
                </a>
                <a *ngIf="link.external" target="{{ link.target || '_self' }}" class="{{ link.optionsView.desktop.class }} gtm-nav" href="{{ link.url }}">
                <span class="clearfix d-none d-sm-inline-block" [attr.data-cy]="link.optionsView.desktop.dataCy">
                  {{ link.text | translate}}
                </span>
                </a>
              </li>
            </ng-container>
          </ng-container>
          <ng-container *featureFlag="featureFlags.PROFILE_MENU">
            <li  display="dynamic" class="nav-item" data-cy="userMenu">
              <a class="nav-link black-text gtm-nav" href="javascript:void(0)" [ngbPopover]="popContent"
                 placement="bottom-right" popoverClass="profile-selector">
                <avatar [isHeader]="true"></avatar>
              </a>
              <ng-template #popContent>
                <ul class="ps-0">
                  <li class="p-0" *ngIf="customerSupportAgent">
                    <div class="list-group-item list-group-item-danger p-1 text-center">Airline Administrator</div>
                    <a href="/" class="text-center d-block" (click)="changeUser()">
                      <span class="clearfix d-none d-sm-inline-block">Change Employee</span>
                    </a>
                    <div class="list-group-item list-group-item-secondary text-center" data-cy="selectedUser">Employee: <b>{{ user?.member?.employee_number }}</b></div>
                  </li>
                  <ng-container *ngFor="let link of desktopLinks">
                    <ng-container *featureFlag="link.feature">
                      <li *ngIf="link.url && link.optionsView.desktop.section === 3" data-cy="userMenuItem">
                        <a *ngIf="!link.external" class="{{ link.optionsView.desktop.class }} gtm-nav" routerLink="{{ link.url | translate}}" [attr.data-cy]="link.optionsView.desktop.dataCy">
                          <span >{{ link.text | translate }}</span>
                        </a>
                        <a *ngIf="link.external" target="{{ link.target || '_self' }}" class="{{ link.optionsView.desktop.class }} gtm-nav" (click)="openBeamer(link.feature, link.url)">
                          <span [attr.data-cy]="link.optionsView.desktop.dataCy">{{ link.text | translate }}</span>
                        </a>
                      </li>
                    </ng-container>
                  </ng-container>
                </ul>
              </ng-template>
            </li>
          </ng-container>
        </ul>
      </div>
    </nav>
  </div>
</header>
