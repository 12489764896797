import {Component} from '@angular/core';
import {MenuService} from 'app/shared/services/menu/menu.service';
import {faCircleChevronRight} from '@fortawesome/free-solid-svg-icons';
import {AnalyticsService} from 'app/shared/services/analytics/analytics.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
             selector: 'activities-banner',
             templateUrl: './activities-banner.component.html',
             styleUrls: ['./activities-banner.component.scss']
           })
export class ActivitiesBannerComponent {

  public icons = {faCircleChevronRight};

  constructor(
    private menuService: MenuService,
    private analyticsService: AnalyticsService,
    private translateService: TranslateService,
  ) {
  }

  getCustomerId() {
    return this.menuService.getViatorCustomerId();
  }

  navigate() {
    const url = this.translateService.instant('web.nav.url.activities', { customerID: this.getCustomerId() });
    this.analyticsService.logEvent('viator_banner_click', { destination_url: url });
    window.open(url, '_blank');
  }
}
