import { Injectable } from '@angular/core';
import { PhoneNumberHelper } from './phone-number.helper';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseMissingModal, Mode } from '../models/missing-modal';
import { CustomValidators } from '../validators/custom-validators';

@Injectable({ providedIn: 'root' })

export class MissingInfoHelper {

    constructor(
        private phoneNumberHelper: PhoneNumberHelper,
        private translate: TranslateService
    ) { }

    checkMissingInformation(phone: any, email: string, blockedDomains: string[]): any {
        const dontAskAgain = localStorage.getItem('dontAskAgain');
        const userDomain = email.split('@')[1];

        const validPhone = this.phoneNumberHelper.isValid(phone);
        const validEmail = blockedDomains.find((blockedDomain) => {
            return blockedDomain === userDomain
        });
        return {
            phone: dontAskAgain === 'true' ?  true : validPhone,
            email: validEmail  === undefined
        }
    }

    buildMissingInfoModal(mode: Mode = Mode.customerSupportMsg, blockedDomains = [], email: string = null ): BaseMissingModal | null {
        const typesOfModal = {
            customerSupportMsg: {
                title: {
                    text: this.translate.instant('ask_for_email_modal.title'),
                },
                body: {
                    html: `<p>${this.translate.instant('ask_for_email_modal.customer_support_message')}</p>`
                },
                close: {
                    icon: `<span class="clickable" aria-hidden="true">${this.translate.instant('sign_out')}</span>`,
                    show: true,
                    logout: true
                },
                dontAskAgain: {
                    show: true,
                    text: this.translate.instant('mfa.add_phone_number.dont_ask_again')
                }
            } as BaseMissingModal,
            email: {
                title: {
                    text: this.translate.instant('ask_for_email_modal.title'),
                },
                body: {
                    html: `<p>${this.translate.instant('ask_for_email_modal.body')}</p>`
                },
                close: {
                    icon: `<span class="clickable" aria-hidden="true">${this.translate.instant('sign_out')}</span>`,
                    show: true,
                    logout: true
                },
                dontAskAgain: {
                    show: true,
                    text: this.translate.instant('mfa.add_phone_number.dont_ask_again')
                },
                formFields: {
                    email: {
                        show: true,
                        validators: [Validators.required, CustomValidators.email, CustomValidators.checkBlockDomain(blockedDomains)]
                    }
                }
            } as BaseMissingModal,
            phone: {
                title: {
                    text: this.translate.instant('mfa.add_phone_number.title'),
                },
                body: {
                    html: ` <p class="secondary-content pb-3"> ${this.translate.instant('mfa.add_phone_number.text_1')} </p>
                            <p class="tertiary-content pb-3"> ${this.translate.instant('mfa.add_phone_number.text_2')} </p>
                            `
                },
                close: {
                    icon: '',
                    show: true,
                },
                formFields: {
                    phone: {
                        show: true
                    }
                }
            } as BaseMissingModal
        };

        if (mode === 'customerSupportMsg') {
            return typesOfModal.customerSupportMsg;
        }

        if ( mode === 'email' && email ) {
          const sameEmailValidator = CustomValidators.sameEmail(email) as ValidatorFn;
          typesOfModal.email.formFields.email.validators.push(sameEmailValidator);
        }

        return {
            ...typesOfModal[mode],
        }
    }

    buildFormGroup(modalconfig: BaseMissingModal): FormGroup {
        const formGroup = new FormGroup({});
        if (!modalconfig) { return formGroup };
        for (const name in modalconfig?.formFields) {
            if (Object.prototype.hasOwnProperty.call(modalconfig.formFields, name)) {
                if (name === 'phone' && modalconfig.formFields['phone'].show === true) {
                    const phoneControl = this.phoneNumberHelper.createPhoneFormGroup(true, true);
                    phoneControl.get('country')?.setValue({
                        code: 'US',
                        code3: 'US',
                        currency: "USD",
                        name: "United States of America",
                        phone: 1
                    });
                    phoneControl.get('area')?.setValue('');
                    phoneControl.get('number')?.setValue('');
                    formGroup.addControl('phoneNumber', phoneControl)
                    continue;
                }
                const control = new FormControl(modalconfig.formFields[name].value, {
                    validators: modalconfig.formFields[name].validators
                })
                formGroup.addControl(name, control);
            }
        }
        return formGroup
    }

}
