<div class="modal-header">
  <div class="profile-title primary-content">
    {{ modalTitle | translate }}
  </div>
  <button type="button"  [class]="passwordExpired ? 'btn-close sign-out-button' : 'btn-close'" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">{{ passwordExpired ? ('sign_out' | translate) : '' }}</span>
  </button>
</div>
<div class="modal-body">
  <div>
    <ngb-alert *ngIf="passwordExpired && this.error.length === 0" type="danger" (closed)="closeAlert()">{{ 'errors.password_old' | translate }}</ngb-alert>
    <ngb-alert *ngIf="this.error.length > 0" [type]="alertType" (closed)="closeAlert()">{{ error }}</ngb-alert>
    <form [formGroup]="changePasswordForm">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <password-input required [pattern]='' [control]="'previousPassword'" [showTooltip]="false" [id]="'previous_password'" [form]="changePasswordForm" [placeholder]=""
              [label]="'mobile.password_reset.current_password_label' | translate" [showForgotPassword]="false" autocomplete="previous_password" >
            </password-input>
            <div
              class="error-message"
              *ngIf="previousPassword.errors !== null && previousPassword.touched"
              [innerHtml]="previousPasswordError">
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <password-input required [pattern]='' [control]="'newPassword'" [id]="'new_password'" [form]="changePasswordForm" [placeholder]=""
              [label]="'mobile.password_reset.new_password_label' | translate" [showForgotPassword]="false" autocomplete="new_password">
            </password-input>
            <div
              class="error-message"
              *ngIf="newPassword.errors !== null && newPassword.touched"
              [innerHtml]="newPasswordError">
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <password-input required [pattern]='' [control]="'confirmedPassword'" [id]="'confirmed_password'" [form]="changePasswordForm" [placeholder]=""
              [label]="'mobile.password_reset.new_password_confirm_label' | translate" [showForgotPassword]="false" autocomplete="confirmed_password" [showTooltip]="false">
            </password-input>
            <div
              class="error-message"
              *ngIf="confirmedPassword.errors !== null && confirmedPassword.touched"
              [innerHtml]="confirmedPasswordError">
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <toggle [size]="'large'" formControlName="confirmLogout" class="toggle-selector"
              [label]="'mobile.password_reset.logout' | translate"></toggle>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group float-end">
            <a class="gtm-forgot-pass text-end clickable" (click)="getLink()">{{ 'forgot_password?' | translate }}</a>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="modal-footer">
  <button type="button" *ngIf="!loading" class="btn btn-md btn-primary btn-lg" (click)="onChangePasswordClick()" data-cy="update-password">
    {{ "profile.info.update" | translate }}
  </button>
</div>
