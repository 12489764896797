import { TripsActions, TripsApiActions } from '../actions';
import { TripsDetails } from '../../models/trips-details';
import { environment } from '@env/environment';
import { uniqBy } from 'lodash';

export interface State {
  futureHotels: TripsDetails;
  pastHotels: TripsDetails;
  futureFlights: TripsDetails;
  pastFlights: TripsDetails;
  futureCars: TripsDetails;
  pastCars: TripsDetails;
  hotelsInsurance: TripsDetails;
  flightsInsurance: TripsDetails;
  sharedHotels: TripsDetails;
  pastSharedHotels: TripsDetails;
}

export const initialState: State = {
  futureHotels: {
    lastPage: false,
    loaded: false,
    pageLoaded: 0,
    pending: false,
    reservations: []
  },
  pastHotels: {
    lastPage: false,
    loaded: false,
    pageLoaded: 0,
    pending: false,
    reservations: []
  },
  futureFlights: {
    lastPage: false,
    loaded: false,
    pageLoaded: 0,
    pending: false,
    reservations: []
  },
  pastFlights: {
    lastPage: false,
    loaded: false,
    pageLoaded: 0,
    pending: false,
    reservations: []
  },
  futureCars: {
    lastPage: false,
    loaded: false,
    pageLoaded: 0,
    pending: false,
    reservations: []
  },
  pastCars: {
    lastPage: false,
    loaded: false,
    pageLoaded: 0,
    pending: false,
    reservations: []
  },
  hotelsInsurance: {
    lastPage: true,
    loaded: false,
    pageLoaded: 0,
    pending: false,
    reservations: []
  },
  flightsInsurance: {
    lastPage: true,
    loaded: false,
    pageLoaded: 0,
    pending: false,
    reservations: []
  },
  sharedHotels: {
    lastPage: true,
    loaded: false,
    pageLoaded: 0,
    pending: false,
    reservations: []
  },
  pastSharedHotels: {
    lastPage: true,
    loaded: false,
    pageLoaded: 0,
    pending: false,
    reservations: []
  }
};

export function reducer(
  state = initialState,
  action: TripsApiActions.TripsApiActionsUnion | TripsActions.TripsActionsUnion
) {
  switch (action.type) {
    case TripsActions.TripsActionTypes.LoadFutureCars:
      return {
        ...state,
        futureCars: {
          ...state.futureCars,
          pending: true
        }
      };
    case TripsActions.TripsActionTypes.LoadPastCars:
      return {
        ...state,
        pastCars: {
          ...state.pastCars,
          pending: true
        }
      };
    case TripsActions.TripsActionTypes.LoadFutureFlights:
      return {
        ...state,
        futureFlights: {
          ...state.futureFlights,
          pending: true
        }
      };
    case TripsActions.TripsActionTypes.LoadPastFlights:
      return {
        ...state,
        pastFlights: {
          ...state.pastFlights,
          pending: true
        }
      };
    case TripsActions.TripsActionTypes.LoadFutureFlightsByPNR:
      return {
        ...state,
        pastFlights: {
          ...state.futureFlights,
          pending: true
        }
      };
    case TripsActions.TripsActionTypes.LoadPastFlightsByPNR:
      return {
        ...state,
        pastFlights: {
          ...state.pastFlights,
          pending: true
        }
      };
    case TripsActions.TripsActionTypes.LoadFutureHotels:
      return {
        ...state,
        futureHotels: {
          ...state.futureHotels,
          pending: true
        }
      };
    case TripsActions.TripsActionTypes.LoadPastHotels:
      return {
        ...state,
        pastHotels: {
          ...state.pastHotels,
          pending: true
        }
      };
    case TripsActions.TripsActionTypes.LoadHotelsInsurance:
      return {
        ...state,
        hotelsInsurance: {
          ...state.hotelsInsurance,
          pending: true
        }
      };
    case TripsActions.TripsActionTypes.LoadFlightsInsurance:
      return {
        ...state,
        flightsInsurance: {
          ...state.flightsInsurance,
          pending: true
        }
      };
    case TripsActions.TripsActionTypes.LoadSharedItineraries:
      return {
        ...state,
        sharedHotels: {
          ...state.sharedHotels,
          pending: true
        }
      };
    case TripsActions.TripsActionTypes.LoadSharedItineraries:
      return {
        ...state,
        sharedHotels: {
          ...state.sharedHotels,
          pending: true
        }
      };
    case TripsApiActions.TripsApiActionTypes.LoadFutureCarTripsSuccess:
      const futureCars = action.payload.reservations ? action.payload.reservations.data : [];
      return {
        ...state,
        futureCars: {
          lastPage: futureCars.length < environment.tripsPageSize,
          loaded: true,
          pageLoaded: action.payload.reservations ? action.payload.reservations.meta.page_number : state.futureCars.pageLoaded,
          pending: false,
          reservations: uniqBy([...state.futureCars.reservations, ...futureCars], c => c.id)
        }
      };
    case TripsApiActions.TripsApiActionTypes.LoadPastCarTripsSuccess:
      const pastCars = action.payload.history_reservations ? action.payload.history_reservations.data : [];
      return {
        ...state,
        pastCars: {
          lastPage: pastCars.length < environment.tripsPageSize,
          loaded: true,
          pageLoaded: action.payload.history_reservations ? action.payload.history_reservations.meta.page_number : state.pastCars.pageLoaded,
          pending: false,
          reservations: [...state.pastCars.reservations, ...pastCars]
        }
      };
    case TripsApiActions.TripsApiActionTypes.LoadHotelsInsuranceTripsSuccess:
      const hotelsInsurance = action.payload.hotels_reservations ? action.payload.hotels_reservations.data : [];
      return {
        ...state,
        hotelsInsurance: {
          lastPage: hotelsInsurance.length < environment.tripsPageSize,
          loaded: true,
          pageLoaded: action.payload.hotels_reservations ? action.payload.hotels_reservations.meta.page_number : (state.hotelsInsurance.pageLoaded === 0 ? 1 : state.hotelsInsurance.pageLoaded),
          pending: false,
          reservations: [...state.hotelsInsurance.reservations, ...hotelsInsurance]
        }
      };
    case TripsApiActions.TripsApiActionTypes.LoadFlightsInsuranceTripsSuccess:
      const flightsInsurance = action.payload.flights_reservations ? action.payload.flights_reservations.data : [];
      return {
        ...state,
        flightsInsurance: {
          lastPage: flightsInsurance.length < environment.tripsPageSize,
          loaded: true,
          pageLoaded: action.payload.flights_reservations ? action.payload.flights_reservations.meta.page_number : (state.flightsInsurance.pageLoaded === 0 ? 1 : state.flightsInsurance.pageLoaded),
          pending: false,
          reservations: [...state.flightsInsurance.reservations, ...flightsInsurance]
        }
      };
    case TripsApiActions.TripsApiActionTypes.LoadFutureHotelTripsSuccess:
      const futureHotels = action.payload.reservations ? action.payload.reservations.data : [];
      return {
        ...state,
        futureHotels: {
          lastPage: futureHotels.length < environment.tripsPageSize,
          loaded: true,
          pageLoaded: action.payload.reservations ? action.payload.reservations.meta.page_number : state.futureHotels.pageLoaded,
          pending: false,
          reservations: [...state.futureHotels.reservations, ...futureHotels]
        }
      };
    case TripsApiActions.TripsApiActionTypes.LoadPastHotelTripsSuccess:
      const pastHotels = action.payload.history_reservations ? action.payload.history_reservations.data : [];
      return {
        ...state,
        pastHotels: {
          lastPage: pastHotels.length < environment.tripsPageSize,
          loaded: true,
          pageLoaded: action.payload.history_reservations ? action.payload.history_reservations.meta.page_number : state.pastHotels.pageLoaded,
          pending: false,
          reservations: [...state.pastHotels.reservations, ...pastHotels]
        }
      };

    case TripsApiActions.TripsApiActionTypes.LoadFutureFlightTripsSuccess:
      const futureFlights = action.payload.reservations ?
        action.payload.reservations : {};
      const maxFutureFlights = futureFlights.reduce((x, y) => Math.max(x, y.reservations.length), 0);
      const reservationsFutureFlights = (state.futureFlights.pageLoaded === 0 && futureFlights.length > 0) ?
        [...futureFlights] : [...state.futureFlights.reservations, ...futureFlights];
      // flights future reservations endpoint retrieves all the trips. so we check if the length is a multiple of the pagesize
      return {
        ...state,
        futureFlights: {
          lastPage: (maxFutureFlights % environment.tripsPageSize !== 0) || maxFutureFlights === 0,
          loaded: true,
          pageLoaded: state.futureFlights.pageLoaded + 1,
          pending: false,
          reservations: reservationsFutureFlights
        }
      };

    case TripsApiActions.TripsApiActionTypes.LoadFutureFlightTripsByPNRSuccess:
      const futureFlightss = action.payload.reservations ?
        action.payload.reservations : {};
      return {
        ...state,
        futureFlights: {
          lastPage: true,
          loaded: true,
          pageLoaded: 1,
          pending: false,
          reservations: [...futureFlightss]
        }
      };

    case TripsApiActions.TripsApiActionTypes.LoadPastFlightTripsSuccess:
      const pastFlights = action.payload.history_reservations ?
        action.payload.history_reservations : {};
      const maxPastFlights = pastFlights.reduce((x, y) => Math.max(x, y.reservations.length), 0);
      return {
        ...state,
        pastFlights: {
          lastPage: maxPastFlights <= 0,
          loaded: true,
          pageLoaded: state.pastFlights.pageLoaded + 1,
          pending: false,
          reservations: [...state.pastFlights.reservations, ...pastFlights]
        }
      };

    case TripsApiActions.TripsApiActionTypes.LoadPastFlightTripsByPNRSuccess:
      const pastFlightss = action.payload.history_reservations ?
        action.payload.history_reservations : {};
      return {
        ...state,
        pastFlights: {
          lastPage: true,
          loaded: true,
          pageLoaded: 1,
          pending: false,
          reservations: [...pastFlightss]
        }
      };

    case TripsApiActions.TripsApiActionTypes.LoadSharedItinerariesSuccess:
      const sharedHotels = action.payload.reservations ? action.payload.reservations.data : [];
      const pastSharedHotels = action.payload.history_reservations ? action.payload.history_reservations.data : [];
      return {
        ...state,
        sharedHotels: {
          lastPage: true,
          loaded: true,
          pageLoaded: 1,
          pending: false,
          reservations: sharedHotels
        },
        pastSharedHotels: {
          lastPage: true,
          loaded: true,
          pageLoaded: 1,
          pending: false,
          reservations: pastSharedHotels
        }
      };
    case TripsActions.TripsActionTypes.ClearLoaded:
      return {
        ...state,
        futureCars: initialState.futureCars,
        futureFlights: initialState.futureFlights,
        futureHotels: initialState.futureHotels,
        pastCars: initialState.pastCars,
        pastFlights: initialState.pastFlights,
        pastHotels: initialState.pastHotels,
        sharedHotels: initialState.sharedHotels,
        pastSharedHotels: initialState.pastSharedHotels,
        hotelsInsurance: initialState.hotelsInsurance,
        flightsInsurance: initialState.flightsInsurance
      };
    case TripsActions.TripsActionTypes.ClearPastLoaded:
      return {
        ...state,
        pastCars: initialState.pastCars,
        pastFlights: initialState.pastFlights,
        pastHotels: initialState.pastHotels
      };
    case TripsActions.TripsActionTypes.ClearFutureLoaded:
      return {
        ...state,
        futureCars: initialState.futureCars,
        futureFlights: initialState.futureFlights,
        futureHotels: initialState.futureHotels,
        hotelsInsurance: initialState.hotelsInsurance,
        flightsInsurance: initialState.flightsInsurance
      };
    case TripsActions.TripsActionTypes.SaveFutureCar:
      return {
        ...state,
        futureCars: {
          ...state.futureCars,
          reservations: [...state.futureCars.reservations, action.payload]
        }
      };
    case TripsActions.TripsActionTypes.SaveFutureHotel:
      return {
        ...state,
        futureHotels: {
          ...state.futureHotels,
          reservations: [...state.futureHotels.reservations, action.payload]
        }
      };
    case TripsActions.TripsActionTypes.ClearFlights:
      return {
        ...state,
        futureFlights: initialState.futureFlights,
        pastFlights: initialState.pastFlights
      };
    case TripsActions.TripsActionTypes.ClearCars:
      return {
        ...state,
        futureCars: initialState.futureCars,
        pastCars: initialState.pastCars
      };
    case TripsActions.TripsActionTypes.ClearFutureHotels:
      return {
        ...state,
        futureHotels: initialState.futureHotels
      };
    case TripsActions.TripsActionTypes.ClearInsurancesLoaded:
      return {
        ...state,
        hotelsInsurance: initialState.hotelsInsurance,
        flightsInsurance: initialState.flightsInsurance
      };
    case TripsActions.TripsActionTypes.CleanHotelTrips:
      return {
        ...state,
        futureHotels: {
          lastPage: false,
          loaded: false,
          pageLoaded: 0,
          pending: false,
          reservations: []
        },
        pastHotels: {
          lastPage: false,
          loaded: false,
          pageLoaded: 0,
          pending: false,
          reservations: []
        },
      };
    case TripsActions.TripsActionTypes.CleanCarTrips:
      return {
        ...state,
        futureCars: {
          lastPage: false,
          loaded: false,
          pageLoaded: 0,
          pending: false,
          reservations: []
        },
        pastCars: {
          lastPage: false,
          loaded: false,
          pageLoaded: 0,
          pending: false,
          reservations: []
        },
      };
    case TripsActions.TripsActionTypes.CleanFlightTrips:
      return {
        ...state,
        futureFlights: {
          lastPage: false,
          loaded: false,
          pageLoaded: 0,
          pending: false,
          reservations: []
        },
        pastFlights: {
          lastPage: false,
          loaded: false,
          pageLoaded: 0,
          pending: false,
          reservations: []
        },
      };
    case TripsActions.TripsActionTypes.CleanInsuranceTrips:
      return {
        ...state,
        hotelsInsurance: {
          lastPage: true,
          loaded: false,
          pageLoaded: 0,
          pending: false,
          reservations: []
        },
        flightsInsurance: {
          lastPage: true,
          loaded: false,
          pageLoaded: 0,
          pending: false,
          reservations: []
        },
      };
    default:
      return state;
  }
}

export const getFutureHotels = (state: State): TripsDetails => state.futureHotels;
export const getPastHotels = (state: State): TripsDetails => state.pastHotels;
export const getFutureFlights = (state: State): TripsDetails => state.futureFlights;
export const getPastFlights = (state: State): TripsDetails => state.pastFlights;
export const getFutureCars = (state: State): TripsDetails => state.futureCars;
export const getPastCars = (state: State): TripsDetails => state.pastCars;
export const getHotelsInsurance = (state: State): TripsDetails => state.hotelsInsurance;
export const getFlightsInsurance = (state: State): TripsDetails => state.flightsInsurance;
export const getPendingFutureCars = (state: State): boolean => state.futureCars.pending;
export const getPendingFutureFlights = (state: State): boolean => state.futureFlights.pending;
export const getPendingFutureHotels = (state: State): boolean => state.futureHotels.pending;
export const getPendingHotelsInsurance = (state: State): boolean => state.hotelsInsurance.pending;
export const getPendingPastCars = (state: State): boolean => state.pastCars.pending;
export const getPendingPastFlights = (state: State): boolean => state.pastFlights.pending;
export const getPendingPastHotels = (state: State): boolean => state.pastHotels.pending;
export const getPendingFlightsInsurance = (state: State): boolean => state.flightsInsurance.pending;
export const getFutureHotelsLoaded = (state: State): boolean => state.futureHotels.loaded;
export const getFutureFlightsLoaded = (state: State): boolean => state.futureFlights.loaded;
export const getFutureCarsLoaded = (state: State): boolean => state.futureCars.loaded;
export const getHotelsInsuranceLoaded = (state: State): boolean => state.hotelsInsurance.loaded;
export const getPastHotelsLoaded = (state: State): boolean => state.pastHotels.loaded;
export const getPastFlightsLoaded = (state: State): boolean => state.pastFlights.loaded;
export const getPastCarsLoaded = (state: State): boolean => state.pastCars.loaded;
export const getFlightsInsuranceLoaded = (state: State): boolean => state.flightsInsurance.loaded;
export const getSharedHotels = (state: State): TripsDetails => state.sharedHotels;
export const getPastSharedHotels = (state: State): TripsDetails => state.pastSharedHotels;
