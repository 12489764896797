import { Component, Input, SimpleChanges, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'cars-recent-searches',
  templateUrl: './cars-recent-searches-section.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CarsRecentSearchesSectionComponent implements OnChanges {
  @Input() recentSearches = [];
  recentCarsSearches: any[] = [];
  defaultImage = 'https://marketing.id90travel.com/web/assets/generic_car_2.jpg';

  ngOnChanges(changes: SimpleChanges) {
    if (changes.recentSearches && changes.recentSearches.currentValue) {
      this.generateCarsRecentSearches();
    }
  }

  generateCarsRecentSearches() {
    this.recentCarsSearches = [];

    const now = moment();
    this.recentSearches.forEach((cars) => {
      if (
        moment(cars.pick_up_datetime) >= now &&
        moment(cars.drop_off_datetime) >= now &&
        typeof cars.pick_up_location !== 'object'
      ) {

        const destination = Object.assign({}, cars) as any;
        destination.name = this.formatDestinationName(cars.pick_up_location);
        destination.image = this.defaultImage;
        destination.dates = [
          this.formatDate(cars.pick_up_datetime),
          this.formatDate(cars.drop_off_datetime)
        ];

        this.recentCarsSearches.push(destination);
      }
    });
  }

  formatDate(date: string) {
    return moment.utc(date).format('MMM D');
  }

  formatDestinationName(destination: string) {
    return destination.split(',')[0];
  }
}
