import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as fromEventRegistration from 'app/auth/store/reducers';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MarketingUserGuard  {

  constructor(
    public router: Router,
    private store: Store<fromEventRegistration.State>
  ) { }

  canActivate() {
    return this.store.select<any>(fromEventRegistration.getLoggedIn).pipe(
      map((loggedIn) => {
        return loggedIn;
      }),
      tap((confirmed) => {
        if (!confirmed) {
          this.router.navigate(['/event-registration']);
        }
        return of(true);
      })
    );
  }
}
