import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import * as fromAuth from 'app/auth/store/reducers';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';
import { AnalyticsService } from 'app/shared/services/analytics/analytics.service';
import { GamificationService } from 'app/shared/services/gamification/gamification.service';
import { GamificationActions } from 'app/shared/store/actions';
import { participateCampaign } from 'app/shared/store/actions/gamification.actions';
import * as fromShared from 'app/shared/store/reducers';
import { combineLatest, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-participation-modal',
  templateUrl: './participation-modal.component.html',
  styleUrls: ['./participation-modal.component.scss']
})
export class ParticipationModalComponent implements OnInit, OnDestroy {
  @Input() campaign = null;
  @Input() statistics = null;
  showConfirmation = false;
  participationForm = new UntypedFormGroup({
    accept_terms: new UntypedFormControl(false, [Validators.pattern('true')])
  });
  user$ = this.store.pipe(select(fromAuth.getUser));
  airlineName;
  airlineCode: string;
  airlines$ = this.store.pipe(select(fromShared.getAirlines));
  private ngUnsubscribe = new Subject<void>();

  constructor(
    protected formErrorsHelper: FormErrorsHelper,
    private activeModal: NgbActiveModal,
    private gamificationService: GamificationService,
    private store: Store,
    private analyticsService: AnalyticsService
  ) { }

  ngOnInit() {
    combineLatest([this.user$, this.airlines$])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([user, airlines]) => {
        const airline = airlines.find((airline) => airline.code.toUpperCase() === user?.member?.airline_code?.toUpperCase());
        this.airlineName = airline?.display_name.split('(')[0];
        this.airlineCode = airline?.code.toUpperCase();
      }
    );
  }

  participate() {
    if (this.participationForm.valid) {
      this.sendParticipation(true);
    } else {
      Object.keys(this.participationForm.controls).forEach((field) => {
        const control = this.participationForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  close() {
    this.analyticsService.logContent({
      content_type: 'Close Modal - Section Participation Modal',
      content_id: this.campaign.id
    });
    this.store.dispatch(GamificationActions.closeParticipationModal());
    this.activeModal.dismiss(this.showConfirmation);
  }

  sendParticipation(participate) {
    this.gamificationService.participateCampaign(this.campaign.id, participate).pipe(take(1)).subscribe((response) => {
      this.showConfirmation = participate;
      this.store.dispatch(participateCampaign({ idCampaign: this.campaign.id, participate }));
      if (!participate) {
        this.close();
      }
    }, (error) => {
      this.close();
    });
  }

  abstain() {
    this.sendParticipation(false);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
