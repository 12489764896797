<ngb-alert *ngIf="success" type="success" (closed)="closeAlert()">{{ success }}</ngb-alert>
<ngb-alert *ngIf="error" type="danger" (closed)="closeAlert()">{{ error }}</ngb-alert>
<div class="d-sm-none back-button">
  <button type="button" class="btn btn-link" (click)="backToTabs()">
    <fa-icon [icon]="icons.faChevronLeft" aria-hidden="true"></fa-icon> {{'back' | translate}}
  </button>
</div>
<div class="row">
  <div class="col-12">
    <div class="profile-title primary-content float-start">{{'mobile.flights.eligible_travelers' | translate}}</div>
    <div *ngIf="!loading && canInviteEligibleTravelers" class="shadow-none card wrapper">
      <div class="card-body">
        <div class="content">
          <p class="banner-title">{{'profile.eligible_travelers.banner.title' | translate}}</p>
          <p class="banner-text">{{'profile.eligible_travelers.banner.text' | translate}}</p>
          <a target="_blank" href="https://www.youtube.com/watch?v=7Zrjo6PjXYQ&feature=youtu.be&ab_channel=ID90Travel" class="stretched-link banner-link">{{'profile.eligible_travelers.banner.link' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
  <div class="col xs-3 text-end" *ngIf="!loadingBasicGroup">
    <button *ngIf="enableModify && eligibleTravelers.length && !showBasicGroup" class="btn btn-link" (click)="onAddTravelerOpen()" data-cy="addTraveler">
      {{'profile.travelers.add_traveler' | translate }}
    </button>
  </div>
</div>
<loading-indicator [fullScreen]="false" *ngIf="loading"></loading-indicator>
<div *ngIf="!loading">
  <ng-container>
    <div *ngIf="eligibleTravelers?.length; else noResults">
      <div class="row">
        <div class="col-md-6 col-xs-12" *ngFor="let traveler of eligibleTravelers;">
          <eligible-traveler-card [canInvite]="canInvite" [traveler]="traveler" (onEditOpen)="onEditOpen($event)"></eligible-traveler-card>
        </div>
      </div>
      <div class="col xs-12 text-end" *ngIf="enableModify">
        <button class="btn btn-md btn-primary ms-2 submmit-button" (click)="onSubmit()"
                title="{{'profile.travelers.add_traveler' | translate }}">
          {{'profile.travelers.submit' | translate }}
        </button>
      </div>
    </div>
  </ng-container>
  <ng-template #noResults>
    <div class="row text-center">
      <div class="col-12">
        <ngb-alert [dismissible]="false">
          <strong>{{ 'profile.travelers.no_travelers' | translate }}</strong>
        </ngb-alert>
      </div>
      <div class="col xs-12 text-center"  *ngIf="!loadingBasicGroup">
        <button *ngIf="enableModify && !showBasicGroup" class="btn btn-md btn-primary" (click)="onAddTravelerOpen()"
                title="{{'profile.travelers.add_traveler' | translate }}" data-cy="addTraveler">
          {{'profile.travelers.add_traveler' | translate }}
        </button>
      </div>
    </div>
  </ng-template>
</div>
