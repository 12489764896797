import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'passes-modal',
  templateUrl: 'passes-modal.html'
})

export class PassesModalComponent {
  remainingFtp: any[] = [];
  remainingRetireePasses: any = {};
  entitlementsPrefix = 'flights.entitlements.';

  constructor(private activeModal: NgbActiveModal) {}

  dismiss() {
    this.activeModal.dismiss();
  }

  entitlementTextKey(entitlement) {
    let res = '';
    if (entitlement && entitlement !== '') {
      res = this.entitlementsPrefix + entitlement;
    }
    return res;
  }

}
