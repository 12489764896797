import { Action } from '@ngrx/store';

export enum AccountValidationActionTypes {
  Validate = '[Account Validation] Validate',
  ValidateSuccess = '[Account Validation] Validate Success',
  ValidateError = '[Account Validation] Validate Error',
  ValidateUser = '[Account Validation] Validate User',
  ValidateUserSuccess = '[Account Validation] Validate User Success',
  ValidateUserError = '[Account Validation] Validate User Error',
  ClearState = '[Account Validation] Clear State'
}

export class Validate implements Action {
  readonly type = AccountValidationActionTypes.Validate;

  constructor(public payload: { credentials: any }) { }
}

export class ValidateSuccess implements Action {
  readonly type = AccountValidationActionTypes.ValidateSuccess;

  constructor() { }
}

export class ValidateError implements Action {
  readonly type = AccountValidationActionTypes.ValidateError;

  constructor(public payload: { error: any }) { }
}

export class ValidateUser implements Action {
  readonly type = AccountValidationActionTypes.ValidateUser;

  constructor(public payload: { credentials: any }) { }
}

export class ValidateUserSuccess implements Action {
  readonly type = AccountValidationActionTypes.ValidateUserSuccess;

  constructor(public payload: { response: any }) { }
}

export class ValidateUserError implements Action {
  readonly type = AccountValidationActionTypes.ValidateUserError;

  constructor(public payload: { error: any }) { }
}

export class ClearState implements Action {
  readonly type = AccountValidationActionTypes.ClearState;

  constructor() { }
}

export type AccountValidationActionsUnion = 
  Validate | 
  ValidateSuccess | 
  ValidateError | 
  ValidateUser |
  ValidateUserSuccess |
  ValidateUserError | 
  ClearState;