import { SettingsActions } from '../actions';
import { ProfileSettingsState } from 'app/profile/models/profile-settings.interface';

export const initialState: ProfileSettingsState = {
  error: null,
  pending: false,
  settingsData: {}
};

export function reducer(
  state = initialState,
  action:
    | SettingsActions.SettingsActionsUnion
): ProfileSettingsState {
  switch (action.type) {
    case SettingsActions.SettingsActionTypes.LoadSettings:
      return {
        ...state,
        pending: true
      };
    case SettingsActions.SettingsActionTypes.LoadSettingsSuccess:
      return {
        ...state,
        pending: false,
        settingsData: action.payload.settings,
        error: initialState.error
      };
    case SettingsActions.SettingsActionTypes.LoadSettingsFailure:
      return {
        ...state,
        pending: false,
        error: action.payload.error
      };
    default: {
      return state;
    }
  }
}

export const getError = (state: ProfileSettingsState) => state.error;
export const getPending = (state: ProfileSettingsState) => state.pending;
export const getSettings = (state: ProfileSettingsState) => state.settingsData;
