import { Output, EventEmitter, Component, Input, ViewChild, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { VerificationCodeFormComponent } from 'app/shared/components/verification-code/verification-code-form.component';
import { faComment, faMobileAlt, faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';
import { ResetPasswordOptions } from 'app/auth/models/reset-password-options';

@Component({
  selector: 'reset-password-code-form',
  templateUrl: './reset-password-code-form.html'
})

export class ResetPasswordCodeFormComponent implements OnInit {
  @ViewChild(VerificationCodeFormComponent, { static: false }) private verificationCodeForm: VerificationCodeFormComponent
  @Input() resendSuccess = false;
  @Input() error;
  @Input() medium;
  @Input() enabledResetOptions: ResetPasswordOptions;
  @Output() codeSubmitted = new EventEmitter();
  @Output() sendResetLink = new EventEmitter();
  @Output() sendPushCode = new EventEmitter()
  @Output() reSendCode = new EventEmitter();
  @Output() switchCodeEmit = new EventEmitter();
  icons = { faComment, faMobileAlt, faEnvelopeOpenText };

  constructor ( 
    public translate: TranslateService
  ) { }

  buttons = [];
  visiblebuttons = [];

  ngOnInit() {
    this.buttons = [
      {
        medium: 'sms',
        icon: 'faComment',
        onClick: this.switchCode.bind(this),
        text: this.translate.instant('forgot_password.send_sms'),
        disable: true,
        show: this.enabledResetOptions.sms
      },
      {
        medium: 'code',
        icon: 'faMobileAlt',
        onClick: this.switchCode.bind(this),
        text: this.translate.instant('forgot_password.send_code'),
        disable: true,
        show: this.enabledResetOptions.push_notification
      },
      {
        medium: 'email',
        icon: 'faEnvelopeOpenText',
        onClick: this.sendEmail.bind(this),
        text: this.translate.instant('forgot_password.send_email'),
        disable: true,
        show: this.enabledResetOptions.email
      }
    ];
    this.setButtons();
    this.resendSuccess = true;
  }

  sendEmail() {
    this.sendResetLink.emit();
    this.medium = 'email';
    this.resendSuccess = false;
    this.setButtons();
  }

  resetCode() {
    this.reSendCode.emit();
    this.resendSuccess = false;
    this.setDisableEnableButtons('disable');
  }

  switchCode(event) {
    this.resendSuccess = true;
    this.switchCodeEmit.emit(event);
    this.medium = event;
    this.setButtons();
    this.verificationCodeForm.codeForm.reset();
    this.setDisableEnableButtons('disable');
  }

  endTimerEvent() {
    this.setDisableEnableButtons('enable');
  }

  submitCode(event) {
    this.codeSubmitted.emit(event);
  }

  setButtons() {
    this.visiblebuttons = this.buttons.filter((button) => (button.medium !== this.medium) && button.show);
    this.setDisableEnableButtons('disable');
  }

  setDisableEnableButtons(action) {
    this.visiblebuttons = this.visiblebuttons.map((button) => {
      if (action === 'disable') {
        button.disable = button.medium === 'email' ? false : true;
      } else {
        button.disable = false;
      }

      return button;
    })
  }

  getVisibleButtonsSize() {
    return this.visiblebuttons.length;
  }

  getIconName(icon) {
    return this.icons[icon];
  }
}
