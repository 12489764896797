import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { HotelSearch } from 'app/shared/models/hotel-search';
import { SlugifyHelper } from 'app/shared/helpers/slugify.helper';

@Component({
  selector: 'destinations',
  templateUrl: './destinations.html',
  styleUrls: ['./destinations.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class DestinationsComponent implements OnInit {

  CARDS_PER_PAGE = 4;
  @Input() destinationsList = [];
  @Input() image = 'image';
  @Input() name = 'name';
  @Input() source = 'recentSearches';
  @Input() defaultImage = '/angular_assets/images/common/default-city.jpg';
  @Input() showImageSquare: boolean = false;
  @Input() size: 'sm' | 'xl' = 'sm';
  numberOfDestinations = 0;
  cardsDisplayed = this.CARDS_PER_PAGE;
  moreOptionsAvailable = true;

  constructor(
    private router: Router,
    private slugifyHelper: SlugifyHelper
  ) {}

  ngOnInit() {
    this.numberOfDestinations = this.destinationsList.length;
    this.moreOptionsAvailable = (this.numberOfDestinations - this.cardsDisplayed) > 0;
  }

  displayClass(index) {
    return index >= this.cardsDisplayed ? 'd-none' : '';
  }

  gtmCardClass() {
    return `gtm-${this.source}`;
  }

  showMoreCards() {
    this.cardsDisplayed += this.CARDS_PER_PAGE;
    this.moreOptionsAvailable = (this.numberOfDestinations - this.cardsDisplayed) > 0;
  }

  onDestinationSelected(dest) {
    let params = { destination: null };

    if (this.source === 'recentSearches') {
      params = this.getParamsForRecentSearches(dest);
    } else if (this.source === 'carsRecentSearches') {
      return this.router.navigate(['/cars/search', this.getParamsForCarsRecentSearches(dest)]);
    } else if (this.source === 'allInclusive') {
      params = this.getParamsForAllInclusive(dest);
    } else if (this.source === 'recommendedDestinations') {
      params = this.getParamsForRecommendedDestinations(dest);
    } else if (this.source === 'recentViews') {
      return this.onRecentViewsClicked(dest);
    }

    if (Object.keys(params).length > 0 && params.destination) {
      const destinationSlug = this.slugifyHelper.slugify(params.destination);
      this.router.navigate([`/hotels/search/${destinationSlug}`, params]);
    }
  }

  onRecentViewsClicked(hotel) {
    const hotelSearch = new HotelSearch();
    hotelSearch.checkin = moment.utc(hotel.checkin).format('YYYY-MM-DD');
    hotelSearch.checkout = moment.utc(hotel.checkout).format('YYYY-MM-DD');
    hotelSearch.longitude = hotel.longitude;
    hotelSearch.latitude = hotel.latitude;
    hotelSearch.destination = hotel.destination;
    hotelSearch.rooms = hotel.rooms;
    hotelSearch['guests[]'] = hotel.travelers;
    hotel['id'] = hotel.displayable_id;

    const slugifyHelper = new SlugifyHelper();
    const destinationSlug = slugifyHelper.slugify(hotelSearch.destination);
    const hotelNameSlug = slugifyHelper.slugify(hotel.name);

    this.router.navigate([`/hotels/details/${destinationSlug}/${hotelNameSlug}/`, {
      checkin_date: hotelSearch.checkin,
      checkout_date: hotelSearch.checkout,
      guests: `[${hotel.travelers}]`,
      hotel_service_id: hotel.displayable_id,
      id: hotel.id,
      rooms: hotelSearch.rooms
    }]);
  }

  getParamsForRecentSearches(dest) {
    return {
      destination: dest.destination,
      checkin: this.formatDate(dest.checkin),
      checkout: this.formatDate(dest.checkout),
      rooms: dest.rooms,
      longitude: dest.longitude,
      latitude: dest.latitude,
      'guests[]': dest.travelers.replace(/-/g, ',')
    };
  }

  getParamsForCarsRecentSearches(dest) {
    return {
      country: dest.country,
      dropoffDate: this.formatDate(dest.drop_off_datetime),
      dropoffLocation: dest.drop_off_location,
      dropoffLocationId: dest.drop_off_location_id,
      dropoffTime: '10:00',
      over25: dest.age >= 25,
      pickupLocation: dest.pick_up_location,
      pickupLocationId: dest.pick_up_location_id,
      pickupDate: this.formatDate(dest.pick_up_datetime),
      pickupTime: '10:00'
    };
  }

  getParamsForAllInclusive(dest) {

    const checkinDate = moment(new Date()).add(3, 'weeks');
    checkinDate.add(4 - checkinDate.isoWeekday(), 'days');

    return {
      destination: dest.destination_name,
      checkin: this.formatDate(checkinDate),
      checkout: this.formatDate(checkinDate.add(3, 'days')),
      rooms: 1,
      longitude: dest.properties[0].longitude,
      latitude: dest.properties[0].latitude,
      'guests[]': '2',
      roomsGuests: [{
        adults: 2, children: 0, ages: []
      }]
    };
  }

  getParamsForRecommendedDestinations(dest) {
    const checkinDate = moment(new Date()).add(3, 'weeks');
    checkinDate.add(4 - checkinDate.isoWeekday(), 'days');

    return {
      destination: dest.name,
      checkin: moment().add(2, 'day').format('MMM DD, YYYY'),
      checkout: moment().add(3, 'day').format('MMM DD, YYYY'),
      rooms: 1,
      longitude: dest.longitude,
      latitude: dest.latitude,
      'guests[]': '2',
      roomsGuests: [{
        adults: 2, children: 0, ages: []
      }]
    };
  }

  formatDate(date) {
    return moment.utc(date).format('MMM DD, YYYY');
  }
}
