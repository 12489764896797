import { Component, Input } from '@angular/core';
import { versions } from '@env/version';

@Component({
  selector: 'svg-icon',
  template: `
    <svg [style.color]="color">
      <use attr.xlink:href="/angular_assets/images/icons/symbol-defs.svg?v=${versions.revision ? versions.revision : ''}#{{icon}}"></use>
    </svg>
  `,
  styleUrls: ['./svg-icon.scss']
})

export class SvgIconComponent {
  @Input() icon: string;
  @Input() color: string;
}
