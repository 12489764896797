import { Action } from '@ngrx/store';
import {MemberLoyaltySummary} from 'app/profile/models/member-loyalty.interface';

export enum MemberLoyaltyActionTypes {
  LoadLoyalty = '[Member/Loyalty] Load Loyalty',
  LoadLoyaltySuccess = '[Member/Loyalty] Load Loyalty Success',
  LoadLoyaltyFailure = '[Member/Loyalty] Load Loyalty Failure'
}

export class LoadLoyalty implements Action {
  readonly type = MemberLoyaltyActionTypes.LoadLoyalty;
  constructor(public payload: { currency: any }) {}
}

export class LoadLoyaltySuccess implements Action {
  readonly type = MemberLoyaltyActionTypes.LoadLoyaltySuccess;

  constructor(public payload: { summary: MemberLoyaltySummary }) {}
}

export class LoadLoyaltyFailure implements Action {
  readonly type = MemberLoyaltyActionTypes.LoadLoyaltyFailure;

  constructor(public payload: { error: any }) {}
}

export type MemberLoyaltyActionsUnion = LoadLoyalty
  | LoadLoyaltySuccess
  | LoadLoyaltyFailure;
