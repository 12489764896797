import { Component, Input, Output, EventEmitter } from '@angular/core';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'counter-component',
  templateUrl: './counter.html',
  styleUrls: ['./counter.scss']
})
export class CounterComponent {
  @Input() step = 1;
  @Input() value: number;
  @Input() maxValue: number;
  @Input() minValue: number;
  @Output() valueUpdated = new EventEmitter();
  icons = { faMinusCircle, faPlusCircle };

  mutate(change: number): void {
    this.value = Math.min(Math.max(this.minValue, change), this.maxValue);
    this.valueUpdated.emit(this.value);
  }
}
