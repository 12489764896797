<section id="forgot-password-reset">
  <loading-indicator *ngIf="loading"></loading-indicator>
  <div class="container" *ngIf="!loading">
    <div class="row justify-content-center">
      <div class="col-md-6 col-sm-12">
        <h2 class="pb-4 text-center">{{ 'password_reset.title' | translate }}</h2>
        <ngb-alert *ngIf="msgError" type="danger" (closed)="closeAlert()">
          {{ 'password_reset.errors.failure' | translate }}
        </ngb-alert>
        <div class="card mb-5">
          <div class="card-body">
            <div class="row justify-content-center">
              <div class="col-10">
                <form [formGroup]="forgotPasswordResetForm" (ngSubmit)="submit()">
                  <div class="form-group">
                    <password-input [control]="'newPassword'" [id]="'newPassword'" [form]="forgotPasswordResetForm"
                      [placeholder]="'mobile.password_reset.new_password_label' | translate"
                      [label]="'mobile.password_reset.new_password_label' | translate" >
                    </password-input>
                    <div class='error-message' *ngIf="newPassword.errors !== null && (newPassword.touched)"
                    [innerHtml]="formErrorsHelper.errorMessage(newPassword, ('mobile.password_reset.new_password_label' | translate))">
                    </div>
                  </div>
                  <div class="form-group">
                    <password-input [control]="'newPasswordConfirm'" [id]="'newPasswordConfirm'" [form]="forgotPasswordResetForm" [showTooltip]="true"
                      [placeholder]="'mobile.password_reset.new_password_confirm_label' | translate"
                      [label]="'mobile.password_reset.new_password_confirm_label' | translate" >
                    </password-input>
                    <div class='error-message' *ngIf="newPasswordConfirm.errors !== null && (newPasswordConfirm.touched)"
                    [innerHtml]="formErrorsHelper.errorMessage(newPasswordConfirm, ('mobile.password_reset.new_password_confirm_label' | translate))">
                    </div>
                  </div>
                  <button type="submit" class="btn btn-primary w-100 my-2" *ngIf="!loading">
                    {{'mobile.password_reset.button' | translate}}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
