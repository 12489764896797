<ng-container *ngIf="!loading && !accountLinked && (showApple || showFacebook || showGoogle)">
  <div class="link-social-account" >
    <div class="primary-content mb-2">
      {{ 'profile.social_account.title' | translate }}
    </div>
    <div class="tertiary-content">
      {{ 'profile.social_account.body' | translate }}
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <social-buttons-component
          [showApple]="showApple"
          [showFacebook]="showFacebook"
          [showGoogle]="showGoogle"
          [fromProfile]=true
          [requestMFA]="requestMFA"
          (clickEventAction)="connectAccount($event)"></social-buttons-component>
      </div>
    </div>
  </div>
</ng-container>
<ng-container>
  <loading-indicator *ngIf="loading" [fullScreen]="false"></loading-indicator>
  <div class="link-social-account" *ngIf="!loading && accountLinked">
    <div class="d-flex bd-highlight">
      <div class="primary-content bd-highlight">{{ 'profile.social_account.account_linked.title' | translate }}</div>
      <div class="flex-grow-1 bd-highlight p-3">
        <div class="progress">
          <div class="progress-bar bg-{{ completedStyle }} w-{{ progressBarValue }}" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
      <div class="pt-2 bd-highlight">
        <!-- TODO: Bootstrap 5: Review badge implementation -->
        <span class="badge bg-secondary bg-success text-white badge-big">{{progressBarValue}}%</span>
      </div>
    </div>
    <ul class="checkmark">
      <li>
        <fa-icon [icon]="icons.faCheckCircle"></fa-icon>
        {{ 'profile.social_account.account_linked.connected' | translate }}
      </li>
      <li *ngFor="let profile of profileSteps | keyvalue;">
        <fa-icon [icon]="profileSteps[profile.key].completed ? icons.faCheckCircle : icons.faCircle "></fa-icon>
        {{ profileSteps[profile.key].translation | translate }}
      </li>
    </ul>
  </div>
</ng-container>
