import { Component, Input, OnInit, Output, ViewChild, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from 'app/shared/services/profile/profile.service';
import { SessionService } from 'app/shared/services/session/session.service';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';
import { ProfileHelper } from 'app/shared/helpers/profile.helper';
import { select, Store } from '@ngrx/store';
import * as fromAuth from '../../../auth/store';
import * as fromRoot from '../../../../reducers';
import { AuthService } from 'app/shared/services/auth/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UnlinkEmailModal } from 'app/shared/components/unlink-email-modal/unlink-email-modal';
import { Router } from '@angular/router';
import { TwoStepSecurityModal } from 'app/shared/containers/two-step-security/two-step-security-modal';
import { MfaActions } from 'app/profile/models/mfa';
import { featureFlags } from 'app/shared/models/featureFlags';
import { faChevronLeft, faEnvelope, faCalendar, faCircleNotch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'edit-profile',
  templateUrl: './edit-profile.html',
  styleUrls: ['./edit-profile.scss']
})
export class EditProfileComponent implements OnInit, OnChanges {

  @Input() profileForm;
  @Input() profileConfig;
  @Input() memberSavings;
  @Input() memberLoyalty;
  @Input() userCredits;
  @Input() countries;
  @Input() userStatistics;
  @Input() isInvitedTraveler;
  @Input() loginEnabled;
  @Input() isFraudUser;
  @Input() enableNameEdition = false;
  @Input() requestMFA = false;
  @Input() userData;
  @Output() refreshChangePassword = new EventEmitter();
  @Output() updateProfile = new EventEmitter();

  private readonly EMAIL_IN_USE_ERROR = 'The email has already been registered';

  selectedCountry: any;
  success = null;
  loading = false;
  error = null;
  ready = false;
  genderOptions = [];
  minDate;
  maxDate;
  startDate;
  currencyOptions = [];
  user$ = this.store.pipe(select(fromAuth.getUser));
  memberId: number;
  invitedUser = false;
  partner = false;
  isPartner$ = this.store.pipe(select<any, any>(fromAuth.getPartner));
  userInfo: any;
  phoneError = false;
  featureFlags = featureFlags;
  mfaForce = false;
  icons = { faChevronLeft, faEnvelope, faCalendar, faCircleNotch };

  @ViewChild('changePasswordComponent', { static: false }) changePasswordModal: ChangePasswordComponent;
  @Output() showTabs = new EventEmitter();

  constructor(
    protected translateService: TranslateService,
    public formErrorsHelper: FormErrorsHelper,
    private profileService: ProfileService,
    private sessionService: SessionService,
    private profileHelper: ProfileHelper,
    private store: Store<fromRoot.State>,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit() {
    this.user$.pipe(take(1)).subscribe((user) => {
      this.isPartner$.pipe(take(1)).subscribe((isPartner) => {
        this.partner = isPartner;
        this.memberId = user.member.member_id;
        this.userInfo = user;
        this.invitedUser = user.member.airline_code === AuthService.INVITED_MEMBERS_AIRLINE_CODE;
        this.loadInfo();
        this.setSelectOptions();
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.countries && this.profileForm) { this.ready = true; }
  }

  loadInfo() {
    this.minDate = { year: 1900, month: 1, day: 1 };
    this.maxDate = { year: moment().year(), month: moment().month(), day: moment().day() };
    this.startDate = this.profileForm.get('employee').get('birthDate').value || '';
  }

  setSelectOptions() {
    this.genderOptions = [];
    this.translateService.get('flights.booking.ticketing.gender.male').pipe(take(1)).subscribe((item) => {
      this.genderOptions.push({ value: 'M', label: item });
    });
    this.translateService.get('flights.booking.ticketing.gender.female').pipe(take(1)).subscribe((item) => {
      this.genderOptions.push({ value: 'F', label: item });
    });
    this.translateService.get('flights.booking.ticketing.gender.undisclosed').pipe(take(1)).subscribe((item) => {
      this.genderOptions.push({ value: 'U', label: item });
    });
    this.translateService.get('flights.booking.ticketing.gender.unspecified').pipe(take(1)).subscribe((item) => {
      this.genderOptions.push({ value: 'X', label: item });
    });

    this.profileService.getBookableCurrencies().pipe(take(1)).subscribe((value:any) => {
      this.currencyOptions = value.enabled_booking_currencies;
    });
  }

  private getEmptyPhoneWithCode(code) {
    const number = {
      country: '1',
      area: '',
      number: ''
    };
    if (!code || !code.phone) { return number; }
    number.country = code.phone.toString();
    return number;
  }

  onEditProfile(): void {
    Object.keys(this.profileForm.controls).forEach((field) => {
      const control = this.profileForm.get(field);
      control.markAsTouched({ onlySelf: true });
      control.markAsDirty({ onlySelf: true });
    });
    if (this.isValid()) {
      this.loading = true;
      this.success = null;
      this.error = null;

      const params = this.profileForm.get('employee').value;
      const homePhone = this.profileForm.get('employee').get('homePhone').value;
      if (homePhone) {
        if (homePhone.number) {
          params['homePhone'] = this.setPhoneData(homePhone);
        } else {
          params['homePhone'] = this.getEmptyPhoneWithCode(homePhone.country);
        }
      }
      const currency = this.profileForm.get('employee').get('currency').value;
      params['currency'] = currency || 'USD';

      const storeParams = Object.assign({}, params, this.formatPhoneNumbersForStore(params));
      params['isInvitedTraveler'] = this.isInvitedTraveler;

      if (this.requestMFA) {
        const twoStepSecurityModal = this.modalService.open(TwoStepSecurityModal, { backdrop : 'static', keyboard : false, size: 'lg' });
        twoStepSecurityModal.componentInstance.userData = this.userData;
        twoStepSecurityModal.componentInstance.action = MfaActions.MFA_PROFILE_UPDATE;
        twoStepSecurityModal.result.then(
          (success) => {
            if (success) { this.updateProfileAction(params, storeParams); }
          },
          () => {
            this.error = this.translateService.instant('errors.mfa_required');
            this.loading = false;
            this.updateProfile.emit(false);
          }
        );
      } else {
        this.updateProfileAction(params, storeParams);
      }
    } else {
      Object.keys(this.profileForm.get('employee').controls).forEach((field) => {
        const control = this.profileForm.get('employee').get(field);
        if (!this.profileForm.get('employee').get('homePhone').valid) {
          this.phoneError = true;
        }
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  checkHomePhone() {
    this.phoneError = !this.profileForm.get('employee').get('homePhone').valid;
  }

  private updateProfileAction(params, storeParams) {
    const savedHomePhone = params['homePhone'];
    const savedMobilePhone = params['mobilePhone'];

    this.profileService.updateProfile(params).pipe(take(1)).subscribe(
      () => {
        this.sessionService.setCurrency(params['currency']);
        this.loading = false;
        this.updateProfile.emit(storeParams);
        this.success = 'profile.info.successful_update';
        if (this.mfaForce) { this.openMfaForceEnrollModal(); }
      },
      (e) => {
        const error = e?.error?.error;
        this.error = error;
        this.loading = false;
        this.updateProfile.emit(false);
        if (this.EMAIL_IN_USE_ERROR === error) {
          const modalRef = this.modalService.open(UnlinkEmailModal, { size: 'lg', backdrop: 'static', keyboard: false });
          modalRef.componentInstance.email = this.personalEmail.value;
          modalRef.result.then((toast) => {
            if (toast) {
              this.error = '';
              this.success = 'profile.info.successful_email_sent';
            }
          }).catch(err => this.error = err);
        }
      }
    );
    if (savedHomePhone) { params['homePhone'] = savedHomePhone; }
    if (savedMobilePhone) { params['mobilePhone'] = savedMobilePhone; }
  }

  openMfaForceEnrollModal() {
    const twoStepSecurityModal = this.modalService.open(TwoStepSecurityModal, { backdrop : 'static', keyboard : false, size: 'lg' });
    twoStepSecurityModal.componentInstance.userData = this.userInfo;
    twoStepSecurityModal.componentInstance.action = MfaActions.MFA_PROFILE_UPDATE;
    twoStepSecurityModal.result.then(
      (success) => {
        if (success) { return this.router.navigate(['/profile/login_and_security']); }
      },
      () => {
        this.error = this.translateService.instant('errors.CODE_NOT_FOUND');
        this.loading = false;
      }
    );
  }

  formatPhoneNumbersForStore(params) {
    const homePhone = params['homePhone'] ? this.getPhoneStoreFormat(params['homePhone']) : '||';
    const mobilePhone = params['mobilePhone'] ? this.getPhoneStoreFormat(params['mobilePhone']) : '||';
    return { homePhone, mobilePhone };
  }

  getPhoneStoreFormat(phoneParam) {
    return `${phoneParam['country']}|${phoneParam['area']}|${phoneParam['number']}`;
  }

  setPhoneData(phoneParam) {
    const country = phoneParam['country'] ? phoneParam['country'].code : '';
    const number = phoneParam['number'] ? phoneParam['number'] : '';
    return this.profileHelper.formatPhoneService(country, number);
  }

  onDatesSelected(e) {
    const date = `${e.year}/${e.month}/${e.day}`;
    const dateSelected = moment(date).format('YYYY-MM-DD');
    this.profileForm.get('employee').get('birthDate').setValue(dateSelected);
    this.startDate = dateSelected;
  }

  backToTabs() {
    this.showTabs.emit();
  }

  isValid() {
    return this.profileForm.get('employee').valid;
  }

  closeAlert() {
    this.error = null;
    this.success = null;
  }

  formatStartDate() {
    if (typeof(this.startDate) !== 'string' && typeof(this.startDate) !== 'undefined') {
      this.startDate = `${this.startDate.year}-${this.startDate.month}-${this.startDate.day}`;
    }
    return this.profileHelper.formatStartDate(this.startDate);
  }

  searchCountry(term: string, item: any): boolean {
    return (item.name.toLowerCase().indexOf(term.toLowerCase()) >= 0) || (`${item.phone}`.indexOf(term) >= 0);
  }

  get firstName()  { return this.profileForm.get('employee').get('firstName'); }
  get middleName()  { return this.profileForm.get('employee').get('middleName'); }
  get lastName()  { return this.profileForm.get('employee').get('lastName'); }
  get birthDate() { return this.profileForm.get('employee').get('birthDate'); }
  get gender() { return this.profileForm.get('employee').get('gender'); }
  get personalEmail()  { return this.profileForm.get('employee').get('personalEmail'); }
  get homePhone()  { return this.profileForm.get('employee').get('homePhone'); }
  get employeeNumber()  { return this.profileForm.get('employee').get('employeeNumber'); }
  get knownTravelerNumber() { return this.profileForm.get('employee').get('knownTravelerNumber'); }
  get countryOfResidence() { return this.profileForm.get('employee').get('countryOfResidence'); }
  get displayKnownTravelerNumber() {
    return this.profileConfig.knownTravelerNumber &&
       this.profileConfig.knownTravelerNumber.show &&
       !this.isInvitedTraveler;
  }
}
