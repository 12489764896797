<section class="py-5" *ngIf="showSection">
  <div class="container">
    <h2>{{'mobile.home.just_for_you' | translate }}</h2>
    <div class="row for-you-cards">
      <ng-container *ngIf="showStaticCards">
        <div *ngIf="userCredits && userCredits.creditUSD" class="col-12 col-md-6 mb-3">
          <user-credits-card
            [userCredits]="userCredits">
          </user-credits-card>
        </div>
        <ng-container *featureFlag="featureFlags.INVITE">
          <div *ngIf="showInviteAFriendCard" class="col-12 col-md-6 mb-3">
            <invite-a-friend-card (clicked)="navigateTo($event)"></invite-a-friend-card>
          </div>
        </ng-container>
        <div *ngIf="promoData" class="col-12 col-md-6 mb-3">
          <promo-card [data]="promoData"></promo-card>
        </div>
        <div *ngIf="needARoomData" class="col-md-6 col-lg-4 col-xl-3 mb-3">
          <need-a-room-card [data]="needARoomData" (clicked)="navigateTo($event)"></need-a-room-card>
        </div>
        <div *ngIf="abandonedHotel" class="col-md-6 col-lg-4 col-xl-3 mb-3">
          <abandon-hotel-card [data]="abandonedHotel" (clicked)="navigateTo($event)"></abandon-hotel-card>
        </div>
      </ng-container>
    </div>
  </div>
</section>
