import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthApiActions, LoginPageActions, ThirdPartyActions } from 'app/auth/store';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { Modal } from 'app/shared/models/modal.model';
import { FlagsActions, ModalActions } from 'app/shared/store/actions';
import { of } from 'rxjs';
import { AuthService } from 'app/shared/services/auth/auth.service';
import { Store } from '@ngrx/store';
import * as fromShared from 'app/shared/store/reducers';
import { ProfileHelper } from 'app/shared/helpers/profile.helper';
import { ProfileUserActions } from '../../../profile/store/actions';
import { TranslateService } from '@ngx-translate/core';
import { featureFlags } from 'app/shared/models/featureFlags';
import { ProfileService } from 'app/shared/services/profile/profile.service';
import { CookieService } from 'ngx-cookie-service';
import { AnalyticsService } from 'app/shared/services/analytics/analytics.service';
import { MemberService } from 'app/shared/services/member/member.service';

@Injectable()
export class ThirdPartyEffects {

  loginThirdParty$ = createEffect(()=>
    this.actions$.pipe(
      ofType<LoginPageActions.LoginThirdParty>(LoginPageActions.LoginPageActionTypes.LoginThirdParty),
      map(action => action.payload),
      switchMap((data: any) => {
        return this.thirdPartyService.loginThirdPartyAccount(data.authToken, data.platform.toLowerCase(), data.remember).pipe(
          map((res) => {
            if (res.response.link_account) {
              const thirdPartyCookie = {
                platform: data.platform,
                token: data.authToken
              };
              this.cookieService.set('thirdPartyConnect', JSON.stringify(thirdPartyCookie));
            }
            const redirect = '/hotels';
            const accessToken = res.response.access_token;
            return new AuthApiActions.ReloadSSOUser({ redirect, accessToken, platform: data.platform.toLowerCase() });
          }),
          catchError((error) => {
            const errorDescriptionCb = (errorDescription) => {
              const errorMessage = error.error && error.error.error_description ? error.error.error_description : 'errors.internal_server_error';
              const message = {
                type: 'danger',
                message: this.translate.instant(errorMessage, { platform: data.platform })
              };
              this.analyticsService.logEvent('login_error', {
                method: data.platform.toLowerCase(),
                success: false,
                message: this.translate.instant(errorMessage, { platform: data.platform })
              });
              this.store.dispatch(new LoginPageActions.LoginShowMessage({ message }));
            };
            if (error && error.error && error.error.error_description) {
              errorDescriptionCb(error.error.error_description);
            } else {
              this.translate.get('login_page.invalid').pipe(take(1)).subscribe((errorDescription) => {
                errorDescriptionCb(errorDescription);
              });
            }
            return of(new AuthApiActions.LoginFailure({ error }));
          })
        );
      })
    )
  );

  connectThirdParty$ = createEffect(()=>
    this.actions$.pipe(
      ofType<ThirdPartyActions.ConnectThirdParty>(ThirdPartyActions.ThirdPartyActionTypes.ConnectThirdParty),
      map(action => action.payload),
      switchMap((data: any) => {
        const token = data.authToken;
        const platform = data.platform;
        const userInfo = data.userInfo;
        return this.thirdPartyService.connectThirdPartyAccount(token, platform.toLowerCase()).pipe(
          map((userAccess) => {
            const platform = featureFlags[data.platform.toUpperCase()];
            this.store.dispatch(FlagsActions.addFeatureFlags({ feature: platform }));
            const params = this.profileHelper.getParamsToUpdate(
              userInfo,
              userAccess.response.third_party_account
            );
            return new ThirdPartyActions.ConnectThirdPartySuccess({ params });
          }),
          catchError((e) => {
            const platform = data.platform.toUpperCase();
            this.analyticsService.logEvent('login_error', {
              method: data.platform.toLowerCase(),
              success: false,
              message: e.error
            });
            return of(new ThirdPartyActions.ConnectThirdPartyFailure({
              platform,
              error: e.error,
              showModalError: data.showModalError
            }));
          })
        );
      })
    )
  );

  connectThirdPartySuccess$ = createEffect(()=>
    this.actions$.pipe(
      ofType<ThirdPartyActions.ConnectThirdPartySuccess>(ThirdPartyActions.ThirdPartyActionTypes.ConnectThirdPartySuccess),
      map(action => action.payload.params),
      switchMap((params) => {
        return this.profileService.updateProfile(params).pipe(
          map(() => {
            if (params.image) {
              this.store.dispatch(new AuthApiActions.AvatarUploadSuccess({ avatarUrl: params.image }));
            }
            this.store.dispatch(new ProfileUserActions.UpdateUserProfile(params));
          }),
          catchError((e) => {
            return of(new ThirdPartyActions.ConnectThirdPartyFailure({
              error: e.error,
              showModalError: true,
              platform: 'Platform'
            }));
          })
        );
      })
    ),
    { dispatch: false }
  );

  connectThirdPartyFailure$ = createEffect(()=>
    this.actions$.pipe(
      ofType<ThirdPartyActions.ConnectThirdPartyFailure>(ThirdPartyActions.ThirdPartyActionTypes.ConnectThirdPartyFailure),
      map(action => action.payload),
      tap((response) => {
        if (response.showModalError) {
          const error = response.error;
          const data: Modal = {
            title: 'errors.error',
            body: error ? this.translate.instant(error.error_description, { platform: response.platform }) : this.translate.instant('errors.try_again_later'),
            modalOptions: { size: 'md' }
          };
          this.store.dispatch(new ModalActions.Show({ data }));
        }
      })
    ),
    { dispatch: false }
  );

  disConnectThirdParty$ = createEffect(()=>
    this.actions$.pipe(
      ofType<ThirdPartyActions.DisconnectThirdParty>(ThirdPartyActions.ThirdPartyActionTypes.DisconnectThirdParty),
      map(action => action.payload),
      switchMap((data: any) => {
        const platform = data.platform;
        return this.thirdPartyService.disconnectThirdPartyAccount(platform.toLowerCase()).pipe(
          map((userAccess) => {
            const platform = featureFlags[data.platform.toUpperCase()];
            this.store.dispatch(FlagsActions.removeFeatureFlags({ feature: platform }));
            return new ThirdPartyActions.DisconnectThirdPartySuccess();
          }),
          catchError((e) => {
            return of(new ThirdPartyActions.DisconnectThirdPartyFailure({ error: e.error.error_description, showModalError: true, platform: 'Platform' }));
          })
        );
      })
    )
  );

  disConnectThirdPartyFailure$ = createEffect(()=>
    this.actions$.pipe(
      ofType<ThirdPartyActions.DisconnectThirdPartyFailure>(ThirdPartyActions.ThirdPartyActionTypes.DisconnectThirdPartyFailure),
      map(action => action.payload),
      tap((response) => {
        if (response.showModalError) {
          const error = response.error;
          const data: Modal = {
            title: 'errors.error',
            body: error ? this.translate.instant(error.error_description, { platform: response.platform }) : this.translate.instant('errors.try_again_later'),
            modalOptions: { size: 'md' }
          };
          this.store.dispatch(new ModalActions.Show({ data }));
        }
      })
    ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private thirdPartyService: AuthService,
    private store: Store<fromShared.State>,
    private profileHelper: ProfileHelper,
    private translate: TranslateService,
    private profileService: ProfileService,
    private cookieService: CookieService,
    private analyticsService: AnalyticsService,
    private memberService: MemberService
  ) {}
}
