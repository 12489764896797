import { Action } from '@ngrx/store';

export enum StealthCreditsActionsType {
  Toggle = '[Hotel Stealth Credits] Toggle Credits',
  Reset = '[Hotel Stealth Credits] Reset',
  Apply = '[Hotel Stealth Credits] Apply Credits',
  Remove = '[Hotel Stealth Credits] Remove Credits'
}

export class Toggle implements Action {
  readonly type = StealthCreditsActionsType.Toggle;
}
export class Reset implements Action {
  readonly type = StealthCreditsActionsType.Reset;
}
export class Apply implements Action {
  readonly type = StealthCreditsActionsType.Apply;
}
export class Remove implements Action {
  readonly type = StealthCreditsActionsType.Remove;
}

export type StealthCreditsActionsUnion = Toggle | Reset | Apply | Remove;
