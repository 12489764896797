import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HotelRoomsService {
  constructor (
    private http: HttpClient
  ) {}

  getRooms(params) {
    return this.http.get<any>(`${environment.apiUrl}/api/v1/rooms.json`, {
      params: this.getURLSearchParams(params)
    });
  }

  getRoom(params) {
    return this.http.get<any>(
      `${environment.apiUrl}/api/v1/rooms/${params.id}.json`,
      { params: this.getURLSearchParams(params) });
  }

  getURLSearchParams(data): HttpParams {
    let params = new HttpParams()
      .set('id', data.id)
      .set('star_rating', data.star_rating)
      .set('checkin', data.checkin)
      .set('checkout', data.checkout)
      .set('rooms', data.rooms)
      .set('destination', data.destination)
      .set('currency', data.currency);

    if (data.businessTrip) {
      params = params.set('trip_reason', 'business');
      params = params.set('bt_token', data.bt_token);
    }

    if (data.rid) {
      params = params.set('rid', data.rid);
    }
    for (const roomGuests of data['guests[]'].split('|')) {
      params = params.append('guests[]', roomGuests);
    }

    if (data.features) {
      params = params.append('features', data.features);
    }

    if (data.accommodationTypes) {
      params = params.append('accommodation_types[]', data.accommodationTypes);
    }

    return params;
  }
}
