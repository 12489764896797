import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { TranslateService } from "@ngx-translate/core";
import { GeneralHelper } from 'app/shared/helpers/general.helper';
import { CarsRecentSearch } from 'app/shared/models/cars-recent-search';
import { featureFlags } from 'app/shared/models/featureFlags';
import { HotelsRecentSearch } from 'app/shared/models/hotels-recent-search';
import { TrendingDestination } from 'app/shared/models/trending-destination';
import { FeatureFlagsService } from 'app/shared/services/featureFlags/featureFlags.service';
import { GamificationActions } from 'app/shared/store/actions';
import * as fromShared from 'app/shared/store/reducers';
import * as fromRoot from 'reducers';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AllInclusiveComponent } from './all-inclusive/all-inclusive.component';

@Component({
  selector: 'search-component',
  templateUrl: './search-component.html',
  styleUrls: ['./search-component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('nav', { static: false }) private tabs: NgbNav;
  @Input() recentHotelSearches: HotelsRecentSearch[];
  @Input() recentHotelSearchesPending: boolean;
  @Input() trendingHotelDestinations: TrendingDestination[];
  @Input() trendingHotelDestinationsPending: boolean;
  @Input() recentCarSearches: CarsRecentSearch[];
  @Input() recentCarSearchesPending: boolean;
  @Input() selectedTab: string;
  @Input() isFraudUser = false;
  @Input() displayInLine: boolean;
  @Input() displayTabs = true;
  @Output() searchTabChanged = new EventEmitter();
  @Output() hotelSearchInitiated = new EventEmitter();
  featureFlags$ = this.store.pipe(select(fromShared.getFeatureFlags));
  searchHeroBannerEnabled = false;
  showHotelForm = true;
  showCarsForm = false;
  isMobileView = GeneralHelper.isMobileDevice();
  featureFlags = featureFlags;
  whiteLabelCruisesUrl = null;
  destination;
  private ngUnsubscribe = new Subject<void>();
  public allInclusiveAbTest = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private store: Store<fromRoot.State>,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private featureFlagsService: FeatureFlagsService
  ) { }

  ngOnInit() {
    this.store.dispatch(GamificationActions.showGamificationAnimation());
    const carsData$ = this.store.select(fromShared.getCarsRecentSearchesLoaded);
    this.searchHeroBannerEnabled = this.featureFlagsService.isFeatureEnabled(featureFlags.HOTEL_HERO_SECTION);
    carsData$.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((cars) => {
      if (cars) {
        this.showCarsForm = true;
      }
    });

    this.featureFlags$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((featureflags: any) => {
      this.allInclusiveAbTest = featureflags?.includes('abtest_all_inclusive_search.popup')
        ? 1
        : featureflags?.includes('abtest_all_inclusive_search.default')
          ? -1
          : 0;
    });
    this.whiteLabelCruisesUrl = this.translateService.instant('web.nav').url?.cruises;
    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => {
      this.detectCurrentTab(params.tab);
      if (this.selectedTab === 'all-inclusive-tab') {
        this.handleAllInclusiveTab();
      }
    });
  }

  ngOnChanges() {
    if (this.tabs) {
      this.tabs.select(this.selectedTab);
    }
    this.cdRef.detectChanges();
  }

  detectCurrentTab(tab) {
    switch (tab) {
      case 'cars':
        this.selectedTab = 'cars-tab';
        break;
      case 'cruises':
        this.selectedTab = 'cruises-tab';
        break;
      case 'all-inclusive':
        this.selectedTab = 'all-inclusive-tab';
        break;
      default:
        this.selectedTab = 'hotels-tab';
        break;
    }
  }

  ngAfterViewInit(): void {
    if (this.tabs) {
      this.tabs.select(this.selectedTab);
    }
    this.cdRef.detectChanges();
  }

  tabChanged(tab) {
    const routes = {
      'hotels-tab': '/hotels',
      'cars-tab': '/cars',
      'cruises-tab': '/cruises',
      'all-inclusive-tab': '/search/all-inclusive',
    };
    this.searchTabChanged.emit(tab.nextId);
    return this.router.navigateByUrl(routes[tab.nextId]);
  }

  async handleAllInclusiveTab() {
    if (this.allInclusiveAbTest === 1) {
      this.modalService.dismissAll();
      const modal = await this.modalService.open(AllInclusiveComponent, {
        size: 'lg',
        backdrop: 'static',
        keyboard: false
      });

      modal.result.then(
        (result) => {
          this.destination = result;
          this.modalService.dismissAll();
        },
        (reason) => {
          this.modalService.dismissAll();
        }
      );
    }
  }

  ngOnDestroy() {
    this.modalService.activeInstances.complete();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
