import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import * as fromAuth from 'app/auth/store/reducers';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { tap, take } from 'rxjs/operators';
import { SessionService } from 'app/shared/services/session/session.service';

@Injectable({
  providedIn: 'root'
})
export class InvitedUserGuard  {

  constructor(
    public router: Router,
    private sessionService: SessionService,
    private store: Store<fromAuth.State>
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    if (route.queryParamMap.get('employee')) {
      return of(true);
    }
    const inviteCode = this.sessionService.getInviteCode();
    if (inviteCode) {
      return this.store.select<any>(fromAuth.getRegisterPageInviteParams).pipe(
        take(1),
        tap((inviteParams) => {
          if (inviteParams) {
            this.router.navigate(['/membership/new'], { queryParams: inviteParams });
          } else {
            this.sessionService.removeInviteCode();
          }
          return of(true);
        })
      );
    }
    return of(true);
  }
}
