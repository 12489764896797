<ngb-alert class="m-0" *ngIf="showMessage" id="announcementContainer"  #announcementContainer (closed)="onClose()">
  <div class="d-sm-flex" [ngClass]="{'justify-content-between': seeNotShowAgain, 'justify-content-center': !seeNotShowAgain }">
    <div class="d-sm-flex">
      <div>
        <ng-content select="[body]"></ng-content>
      </div>
    </div>

    <div class="d-sm-flex flex-shrink-1 dont-show-again" *ngIf="seeNotShowAgain" id="checkOption">
      <div class="form-check">
        <input
          id="dontShowAgainCheckbox"
          type="checkbox"
          class="form-check-input ng-untouched ng-pristine ng-valid"
          [(ngModel)]="dontShowAgain"
        />
        <label class="form-check-label" for="dontShowAgainCheckbox">
          {{ 'announcement_bar.dont_show_again' | translate }}
        </label>
      </div>
    </div>
  </div>
</ngb-alert>
