import { Action } from '@ngrx/store';
import { AirportsRecentSearch } from 'app/shared/models/airports-recent-search';

export enum AirportsRecentDeparturesSearchesActionTypes {
    Load = '[Airport Recent Departures Searches] Load',
    SaveSearch = '[Airport Recent Departures Searches] Save Search'
  }
  
  export class Load implements Action {
    readonly type = AirportsRecentDeparturesSearchesActionTypes.Load;
  
    constructor() {}
  }
  
  export class SaveSearch implements Action {
    readonly type = AirportsRecentDeparturesSearchesActionTypes.SaveSearch;
  
    constructor(public payload: AirportsRecentSearch) {}
  }
  
  export type AirportsRecentDeparturesSearchesActionsUnion = Load
    | SaveSearch;