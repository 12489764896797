import { Component, OnInit } from '@angular/core';
import { AirlinesService } from '../../services/airlines/airlines.service';
import { take } from "rxjs/operators";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'flex-airline',
  templateUrl: './flex-airline.html',
  styleUrls: ['./flex-airline.scss']
})

export class FlexAirlinePage implements OnInit {

  airlineName;
  airlineCode;
  fromLink = false;

  constructor(
    private airlinesService: AirlinesService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.activatedRoute.params.pipe(take(1)).subscribe((params) => {
      this.airlineCode = params.airlineCode;
      if (this.airlineCode) {
        this.airlinesService.getAirlines().pipe(take(1)).subscribe((airlines) => {
          const selectedAirline = airlines.find(
            airline => airline.code.toLowerCase() === this.airlineCode.toLowerCase()
          );
          this.airlineName = selectedAirline.display_name.toLowerCase().replace(` (${selectedAirline.code})`, '');
        });
      } else {
        this.fromLink = true;
      }
    });
  }
}
