import { Action } from '@ngrx/store';

export enum FlightWatchlistApiActionTypes {
  AddFlightWatchlistSuccess = '[Watchlist/API] Add Flight Watchlist Success',
  AddFlightWatchlistFailure = '[Watchlist/API] Add Flight Watchlist Failure',
  LoadFlightWatchlist = '[Watchlist/API] Load Flight Watchlist',
  LoadFlightWatchlistSuccess = '[Watchlist/API] Load Flight Watchlist Success',
  LoadFlightWatchlistFailure = '[Watchlist/API] Load Flight WatchlistsFailure',
  RemoveFlightWatchlistSuccess = '[Watchlist/API] Remove Flight Watchlist Success',
  RemoveFlightWatchlistFailure = '[Watchlist/API] Remove Flight Watchlist Failure'
}

export class AddFlightWatchlistSuccess implements Action {
  readonly type = FlightWatchlistApiActionTypes.AddFlightWatchlistSuccess;

  constructor(public payload: { watchlist: any }) { }
}

export class AddFlightWatchlistFailure implements Action {
  readonly type = FlightWatchlistApiActionTypes.AddFlightWatchlistFailure;

  constructor(public payload: { error: any }) { }
}

export class LoadFlightWatchlists implements Action {
  readonly type = FlightWatchlistApiActionTypes.LoadFlightWatchlist;

  constructor() {}
}

export class LoadFlightWatchlistsSuccess implements Action {
  readonly type = FlightWatchlistApiActionTypes.LoadFlightWatchlistSuccess;

  constructor(public payload: { watchlist: any }) {}
}

export class LoadFlightWatchlistsFailure implements Action {
  readonly type = FlightWatchlistApiActionTypes.LoadFlightWatchlistFailure;

  constructor(public payload: { error: any }) {}
}

export class RemoveFlightWatchlistSuccess implements Action {
  readonly type = FlightWatchlistApiActionTypes.RemoveFlightWatchlistSuccess;

  constructor(public payload: { watchlist: any }) {}
}

export class RemoveFlightWatchlistFailure implements Action {
  readonly type = FlightWatchlistApiActionTypes.RemoveFlightWatchlistFailure;

  constructor(public payload: { error: any }) {}
}

export type FlightWatchlistApiActionsUnion = AddFlightWatchlistSuccess
  | AddFlightWatchlistFailure
  | LoadFlightWatchlists
  | LoadFlightWatchlistsSuccess
  | LoadFlightWatchlistsFailure
  | RemoveFlightWatchlistSuccess
  | RemoveFlightWatchlistFailure;
