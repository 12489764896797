import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { exhaustMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ReservationsService } from '../../../shared/services/reservations/reservations.service';
import { TripsActions, TripsApiActions } from '../actions';

@Injectable()
export class TripsEffects {

  loadFutureCars$ = createEffect(()=>
    this.actions$.pipe(
      ofType<TripsActions.LoadFutureCars>(TripsActions.TripsActionTypes.LoadFutureCars),
      map(action => action.payload.page),
      exhaustMap(page =>
        this.reservationsService.getCarsReservations('future', page).pipe(
          map(res => new TripsApiActions.LoadFutureCarTripsSuccess(res)),
          catchError(error => of(new TripsApiActions.LoadFutureCarTripsFailure({ error })))
        )
      )
    )
  );

  loadPastCars$ = createEffect(()=>
    this.actions$.pipe(
      ofType<TripsActions.LoadPastCars>(TripsActions.TripsActionTypes.LoadPastCars),
      map(action => action.payload.page),
      exhaustMap(page =>
        this.reservationsService.getCarsReservations('history', page).pipe(
          map(res => new TripsApiActions.LoadPastCarTripsSuccess(res)),
          catchError(error => of(new TripsApiActions.LoadPastCarTripsFailure({ error })))
        )
      )
    )
  );

  loadFutureFlights$ = createEffect(()=>
    this.actions$.pipe(
      ofType<TripsActions.LoadFutureFlights>(TripsActions.TripsActionTypes.LoadFutureFlights),
      map(action => action.payload),
      exhaustMap(payload =>
        this.reservationsService.getFlightReservations('future', payload.page, '', payload.invitedTravelerId, payload.eligibleTravelersEnabled).pipe(
          map(res => new TripsApiActions.LoadFutureFlightTripsSuccess(res)),
          catchError(error => of(new TripsApiActions.LoadFutureFlightTripsFailure({ error })))
        )
      )
    )
  );

  loadPastFlights$ = createEffect(()=>
    this.actions$.pipe(
      ofType<TripsActions.LoadPastFlights>(TripsActions.TripsActionTypes.LoadPastFlights),
      map(action => action.payload),
      exhaustMap(payload =>
        this.reservationsService.getFlightReservations('history', payload.page, '', payload.invitedTravelerId, payload.eligibleTravelersEnabled).pipe(
          map(res => new TripsApiActions.LoadPastFlightTripsSuccess(res)),
          catchError(error => of(new TripsApiActions.LoadPastFlightTripsFailure({ error })))
        )
      )
    )
  );


  loadFutureFlightsByPNR$ = createEffect(()=>
    this.actions$.pipe(
      ofType<TripsActions.LoadFutureFlightsByPNR>(TripsActions.TripsActionTypes.LoadFutureFlightsByPNR),
      map(action => action.payload),
      exhaustMap(payload =>
        this.reservationsService.getFlightReservations('future', payload.page, payload.pnr, payload.invitedTravelerId, payload.eligibleTravelersEnabled).pipe(
          map(res => new TripsApiActions.LoadFutureFlightTripsByPNRSuccess(res)),
          catchError(error => of(new TripsApiActions.LoadFutureFlightTripsFailure({ error })))
        )
      )
    )
  );

  loadPastFlightsByPNR$ = createEffect(()=>
    this.actions$.pipe(
      ofType<TripsActions.LoadPastFlightsByPNR>(TripsActions.TripsActionTypes.LoadPastFlightsByPNR),
      map(action => action.payload),
      exhaustMap(payload =>
        this.reservationsService.getFlightReservations('history', payload.page, payload.pnr, payload.invitedTravelerId, payload.eligibleTravelersEnabled).pipe(
          map(res => new TripsApiActions.LoadPastFlightTripsByPNRSuccess(res)),
          catchError(error => of(new TripsApiActions.LoadPastFlightTripsFailure({ error })))
        )
      )
    )
  );

  loadFutureHotels$ = createEffect(()=>
    this.actions$.pipe(
      ofType<TripsActions.LoadFutureHotels>(TripsActions.TripsActionTypes.LoadFutureHotels),
      map(action => action.payload.page),
      exhaustMap(page =>
        this.reservationsService.getHotelReservations('future', page).pipe(
          map(res => new TripsApiActions.LoadFutureHotelTripsSuccess(res)),
          catchError(error => of(new TripsApiActions.LoadFutureHotelTripsFailure({ error })))
        )
      )
    )
  );

  loadPastHotels$ = createEffect(()=>
    this.actions$.pipe(
      ofType<TripsActions.LoadPastHotels>(TripsActions.TripsActionTypes.LoadPastHotels),
      map(action => action.payload.page),
      exhaustMap(page =>
        this.reservationsService.getHotelReservations('history', page).pipe(
          map(res => new TripsApiActions.LoadPastHotelTripsSuccess(res)),
          catchError(error => of(new TripsApiActions.LoadPastHotelTripsFailure({ error })))
        )
      )
    )
  );

  loadHotelsInsurance$ = createEffect(()=>
    this.actions$.pipe(
      ofType<TripsActions.LoadHotelsInsurance>(TripsActions.TripsActionTypes.LoadHotelsInsurance),
      map(action => action.payload.page),
      exhaustMap(page =>
        this.reservationsService.getInsuranceReservations('hotels', page).pipe(
          map(res => new TripsApiActions.LoadHotelsInsuranceTripsSuccess(res)),
          catchError(error => of(new TripsApiActions.LoadHotelsInsuranceTripsFailure({ error })))
        )
      )
    )
  );

  loadFlightsInsurance$ = createEffect(()=>
    this.actions$.pipe(
      ofType<TripsActions.LoadFlightsInsurance>(TripsActions.TripsActionTypes.LoadFlightsInsurance),
      map(action => action.payload.page),
      exhaustMap(page =>
        this.reservationsService.getInsuranceReservations('flights', page).pipe(
          map(res => new TripsApiActions.LoadFlightsInsuranceTripsSuccess(res)),
          catchError(error => of(new TripsApiActions.LoadFlightsInsuranceTripsFailure({ error })))
        )
      )
    )
  );

  loadSharedHotels$ = createEffect(()=>
    this.actions$.pipe(
      ofType<TripsActions.LoadSharedItineraries>(TripsActions.TripsActionTypes.LoadSharedItineraries),
      exhaustMap(() =>
        this.reservationsService.getSharedHotelReservations().pipe(
          map(res => new TripsApiActions.LoadSharedItinerariesSuccess(res)),
          catchError(error => of(new TripsApiActions.LoadSharedItinerariesFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private reservationsService: ReservationsService
  ) {}
}
