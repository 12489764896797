import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HotelsRecentSearch } from 'app/shared/models/hotels-recent-search';
import { CarsRecentSearch } from 'app/shared/models/cars-recent-search';
import { AirportsRecentSearch } from 'app/shared/models/airports-recent-search';

@Injectable({
  providedIn: 'root'
})
export class RecentSearchesService {
  constructor (
    private http: HttpClient
  ) {}

  hotels(): Observable<RecentSearchResponse> {
    return this.http.get<RecentSearchResponse>(`${environment.apiUrl}/api/v1/recent_hotel_searches.json`, { params: { enable: '' } });
  }

  cars(): Observable<CarsRecentSearchResponse> {
    return this.http.get<CarsRecentSearchResponse>(`${environment.apiUrl}/api/v1/recent_car_searches.json`, { params: { enable: '' } });
  }

  airports(params): Observable<AirportsRecentSearchResponse> {
    return this.http.get<AirportsRecentSearchResponse>(`${environment.apiUrl}/api/v1/recent_flight_search_destinations.json`, { params: { type: params } });
  }
}

// TODO: This endpoint should return an array of recent searches so this is not needed.
interface RecentSearchResponse {
  data: HotelsRecentSearch[];
}

interface CarsRecentSearchResponse {
  data: CarsRecentSearch[];
}

interface AirportsRecentSearchResponse {
  data: AirportsRecentSearch[];
}