import { Action } from '@ngrx/store';

export enum AllInclusiveActionTypes {
  Load = '[All Inclusive] Load'
}

export class Load implements Action {
  readonly type = AllInclusiveActionTypes.Load;
}

export type AllInclusiveActionsUnion = Load;
