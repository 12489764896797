<div>
  <fraud-alert
    *ngIf="isFraudUser"
    [errorMessage]="'fraud_user.please_contact_us'"
    [errorCode]="'572'"
  >
  </fraud-alert>

  <ng-container
    [ngTemplateOutlet]="!isFraudUser && showSearchHeroBanner && selectedTab === 'hotels-tab' ? searchWithBanner : searchWithoutBanner"
  >
  </ng-container>

  <ng-template #searchWithBanner>
    <section class="container">
      <div class="row my-4">
        <div class="col-12 shadow p-3 bg-white rounded">
          <search-component
            [isFraudUser]="isFraudUser"
            [selectedTab]="selectedTab"
            [recentHotelSearches]="hotelsRecentSearches$ | async"
            [recentHotelSearchesPending]="hotelsRecentSearchesPending$ | async"
            [recentCarSearches]="carsRecentSearches$ | async"
            [recentCarSearchesPending]="carsRecentSearchesPending$ | async"
            [trendingHotelDestinations]="trendingDestinations$ | async"
            [trendingHotelDestinationsPending]="trendingDestinationsPending$ | async"
            [displayTabs]="false"
            [displayInLine]="true"
            (searchTabChanged)="onSearchTabChanged($event)"
            (hotelSearchInitiated)="onHotelSearchInitiated($event)"
          >
          </search-component>
        </div>
      </div>
    </section>

    <section class="w-100">
      <app-search-hero></app-search-hero>
    </section>
  </ng-template>

  <ng-template #searchWithoutBanner>
    <section class="container-fluid search-component-home">
      <div
        [ngStyle]="{'background-image': 'url(' + backgroundImage + ')'}"
        class="background-form-container">
      </div>
      <div class="container position-relative pt-5">
        <div class="row">
          <div class="col-lg-12 col-xl-10 offset-xl-1">
            <h1 class="pb-4 fw-light d-none d-md-block main-heading">
              {{ 'home_page.begin_adventure__id90_es_ru' | translate }}
            </h1>
            <div class="row d-flex">
              <div
                *featureFlag="featureFlags.MEMBER_REWARDS"
                class="col-md-12 col-lg-3 px-0 bg-white borderfix"
              >
                <member-rewards-home
                  class="bg-white"
                  [isMobileView]="isMobileView"
                >
                </member-rewards-home>
              </div>
              <div
                [class]="!memberRewardsAvailable ? 'col-12' : 'col-12 col-md-12 col-lg-9 col-sm-12 px-0'"
              >
                <search-component
                  [isFraudUser]="isFraudUser"
                  [selectedTab]="selectedTab"
                  [recentHotelSearches]="hotelsRecentSearches$ | async"
                  [recentHotelSearchesPending]="hotelsRecentSearchesPending$ | async"
                  [recentCarSearches]="carsRecentSearches$ | async"
                  [recentCarSearchesPending]="carsRecentSearchesPending$ | async"
                  [trendingHotelDestinations]="trendingDestinations$ | async"
                  [trendingHotelDestinationsPending]="trendingDestinationsPending$ | async"
                  (searchTabChanged)="onSearchTabChanged($event)"
                  (hotelSearchInitiated)="onHotelSearchInitiated($event)"
                >
                </search-component>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </ng-template>

  <section *ngIf="!isFraudUser">
    <ng-container *featureFlag="featureFlags.HOTELS_RECENT_SEARCHES">
      <hotels-recent-searches
        *ngIf="selectedTab === 'hotels-tab'"
        [recentSearches]="hotelsRecentSearches$ | async"
      ></hotels-recent-searches>
    </ng-container>
    <ng-container *featureFlag="featureFlags.CARS_RECENT_SEARCHES">
      <cars-recent-searches
        *ngIf="selectedTab === 'cars-tab'"
        [recentSearches]="carsRecentSearches$ | async"
      ></cars-recent-searches>
    </ng-container>
    <ng-container *featureFlag="featureFlags.CRUISES">
      <cruise-merchandise
        [type]="'family'"
        *ngIf="selectedTab === 'cruises-tab'"
      ></cruise-merchandise>
      <cruise-merchandise
        [type]="'destination'"
        *ngIf="selectedTab === 'cruises-tab'"
      ></cruise-merchandise>
      <!-- <cruise-merchandise [type]="'luxury'" *ngIf="selectedTab === 'cruises-tab'"></cruise-merchandise> -->
    </ng-container>
    <ng-container *featureFlag="featureFlags.JUST_FOR_YOU">
      <just-for-you
        [userStatistics]="userStatistics"
        [userCredits]="userCredits"
        [abandonedHotel]="abandonedHotel$ | async"
      ></just-for-you>
    </ng-container>
    <ng-container *featureFlag="featureFlags.HOTELS_RECENT_VIEW">
      <recent-views
        *ngIf="selectedTab === 'hotels-tab'"
        [recentHotelViews]="hotelsRecentViews$ | async"
      ></recent-views>
    </ng-container>
    <ng-container *featureFlag="featureFlags.RECOMMENDED_DESTINATIONS">
      <recommended-destinations
        *ngIf="selectedTab === 'hotels-tab'"
        [recommendedDestinations]="recommendedDestinations$ | async"
      ></recommended-destinations>
    </ng-container>
    <ng-container *featureFlag="featureFlags.ALL_INCLUSIVE">
      <all-inclusive
        *ngIf="selectedTab === 'hotels-tab' || selectedTab === 'all-inclusive-tab'"
        [allInclusiveDestinations]="allInclusiveDestinations$ | async"
      ></all-inclusive>
    </ng-container>
  </section>
</div>
