import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import * as fromShared from 'app/shared/store/reducers';
import { ModalActions } from 'app/shared/store/actions';

@Component({
  selector: 'simple-modal',
  templateUrl: 'simple-modal.component.html',
  styleUrls: ['simple-modal.component.scss']
})
export class SimpleModalComponent {
  @Input() contentTranslationKey: string;
  @Input() contentTranslationVars: string;
  @Input() content: string;
  @Input() imageCls: string;
  @Input() imageSrc: string;
  @Input() linkText: string;
  @Input() subtitle: string;
  @Input() titleTranslationKey: string;
  @Input() subtitleTranslationKey: string;
  @Input() imageTitleSrc: string;
  @Input() title: string;
  @Input() secondaryButton: string;
  @Input() secondaryButtonLink: string;

  constructor(
    public activeModal: NgbActiveModal,
    private store: Store<fromShared.State>
  ) {}

  closeModals() {
    this.activeModal.close('Close click');
    this.store.dispatch(new ModalActions.Hide());
  }
}
