import { FormControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CreditCardHelper } from 'app/shared/helpers/credit-card.helper';
import { CountryCode, isPossibleNumber } from 'libphonenumber-js';
import { Traveler } from '../models/traveler';
import * as moment from 'moment';
import { PhoneNumberHelper } from '../helpers/phone-number.helper';

export class CustomValidators {
  static email(c: UntypedFormControl): any {
    if (c.value === '' || c.value === null) {
      return;
    }
    const EMAIL_REGEXP = /^[a-zA-Z0-9_-]+(?:\.[a-zA-Z0-9+_-]+)*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (c.value.indexOf(' ') > -1) {
      return { emailContainsSpaces: true };
    }
    if (c.value.indexOf('@') === -1) {
      return { invalidEmailFormat: true };
    }
    if (!EMAIL_REGEXP.test(c.value)) {
      return { valid: false };
    }
    return null;
  }

  /**
   * avoid russian cyrillic chars
   */
  static specialChars(c: UntypedFormControl) {
    if (c.value === '' || c.value === null) {
      return;
    }
    const REGEXP = /[а-яА-ЯЁё]/;

    if (REGEXP.test(c.value)) {
      return { valid: false };
    }
    return null;
  }

  static number(c: UntypedFormControl) {
    const NUM_REGEXP = /^[0-9]*$/;
    return NUM_REGEXP.test(c.value) ? null : { number: { valid: false } };
  }

  static text(c: UntypedFormControl) {
    const TEXT_REGEXP = /^[A-Za-z0-9 .]*$/;
    return TEXT_REGEXP.test(c.value) ? null : { textAndNumbers: { valid: false } };
  }

  static textName(c: UntypedFormControl) {
    const TEXT_REGEXP = /^[a-zA-Z '\-]{0,20}$/;
    return TEXT_REGEXP.test(c.value) ? null : { text: { valid: false } };
  }

  static nameField(c: UntypedFormControl) {
    const TEXT_REGEXP = /^[a-zA-Z '\-.]*$/;
    return TEXT_REGEXP.test(c.value) ? null : { text: { valid: false } };
  }

  static textWithoutBlankSpaces(c: UntypedFormControl) {
    const HAS_BLANK_SPACES = /\s/g;
    return !HAS_BLANK_SPACES.test(c.value) ? null : { textHasBlankSpaces: { valid: false } };
  }

  static textNotOnlyBlankSpaces(c: UntypedFormControl) {
    const NON_BLANK_SPACES = /\S/;
    return NON_BLANK_SPACES.test(c.value || '') ? null : { textIsOnlyBlankSpaces: { valid: false } };
  }

  static requiredTrue(c: UntypedFormControl) {
    return c.value ? null : { requiredTrue: { valid: false } };
  }

  static passportName(c: UntypedFormControl) {
    const TEXT_REGEXP = /^[A-Za-z ]*$/;
    return TEXT_REGEXP.test(c.value) ? null : { text: { valid: false } };
  }

  static textAndHyphen(c: UntypedFormControl) {
    const TEXT_REGEXP = /^[A-Za-z0-9\-]*$/;
    return TEXT_REGEXP.test(c.value) ? null : { textAndHyphen: { valid: false } };
  }

  static minDigitsLength(length: number): any {
    return (control: UntypedFormControl) => {
      if (!control.value) { return null; }
      return control.value.length >= length ? null : { minDigitsLength: { valid: false }, length };
    }
  }

  /**
   * get required group of checkboxes
   * @param group
   */
  static checkboxRequired(group: UntypedFormGroup) {
    let valid = false;
    for (const name in group.controls) {
      const val = group.controls[name].value;
      if (val) {
        valid = true;
        break;
      }
    }
    if (valid) {
      return null;
    }
    return {
      checkboxRequired: true
    };
  }

  static validateCcv(c: UntypedFormControl): any {
    if (!c.value) return null;
    const cc = CreditCardHelper.getCreditCardType(c.root.value.cardNumber).code;
    let ccvLength = 3;
    if (cc.toLowerCase() === 'amex') {
      ccvLength = 4;
    }

    return c.value.length === ccvLength ? null : { ccvLength: false };
  }

  static validateExpDate(c: UntypedFormControl): any {
    if (typeof c.value !== 'undefined' && c.value.length >= 5) {
      let [month, year] = c.value.split(/[\s\/]+/, 2);
      let prefix;

      if ((year != null ? year.length : void 0) === 2 && /^\d+$/.test(year)) {
        prefix = new Date().getFullYear();
        prefix = prefix.toString().slice(0, 2);
        year = prefix + year;
      }
      month = parseInt(month, 10).toString();
      year = parseInt(year, 10).toString();

      if (
        /^\d+$/.test(month) &&
        /^\d+$/.test(year) &&
        (month >= 1 && month <= 12)
      ) {
        let currentTime;
        let expiry;
        expiry = new Date(year, month);
        currentTime = new Date();
        expiry.setMonth(expiry.getMonth() - 1);
        expiry.setMonth(expiry.getMonth() + 1, 1);

        if (expiry > currentTime) {
          return null;
        }

        return { expDate: false };
      }
    }

    return { expDateFormat: false };
  }

  static duplicatePassword(input: UntypedFormControl) {
    if (!input.root || !input.root.value) {
      return null;
    }

    const exactMatch = input.root.value.newPassword === input.value;
    return exactMatch ? null : { mismatchedPassword: true };
  }

  static checkPasswordConfirmed(input: UntypedFormControl) {
    if (!input.root || !input.root.value) {
      return null;
    }

    const exactMatch = input.root.value.password === input.value;
    return exactMatch ? null : { mismatchedPassword: true };
  }

  static duplicatePasswordConfirmed(input: UntypedFormControl) {
    if (!input.root || !input.root.value) {
      return null;
    }

    const exactMatch = input.root.value.new_password === input.value;
    return exactMatch ? null : { mismatchedPassword: true };
  }

  static confirmPasswordMatch(input : UntypedFormControl) {
    if (input.root || !input.root.value) {
      return null;
    }
    const exactMatch = input.root.value.password === input.value;
    return exactMatch ? null : { mismatchedPassword: true };
  }

  static emailDomain(domains) {
    return (c: UntypedFormControl) => {
      if (domains && domains.length > 0) {
        let error = { invalidDomain: true };
        for (const domain of domains) {
          if (c.value.indexOf(domain) > -1) {
            error = null;
          }
        }
        return error;
      }
    };
  }

  static hotelCreditCardType(input: UntypedFormControl) {
    return CreditCardHelper.isValidHotelCCType(input.value)
      ? null
      : { invalidCardType: true };
  }

  static phoneElement(group: UntypedFormGroup): { [key: string]: any } | null {
    const groupParts = ['country', 'area', 'number'];
    return groupParts.reduce(
      (carry: any, part: string) => {
        if (group.controls[part] && group.controls[part].errors) {
          return carry ? { ...carry, ...group.controls[part].errors } : group.controls[part].errors;
        }
        return carry;
      },
      null
    );
  }

  static validPhone(control: UntypedFormGroup): any {
    const { number, country } = control.value;
    if (number && country && country.code !== '') {
      return isPossibleNumber(number, country.code) ? null : { phoneInput: { valid: false } };
    }
  }

  static alphanumeric(control: UntypedFormGroup): any {
    const REGEXP = /^[A-Z0-9]*$/;
    const fieldValue = control.value;
    return REGEXP.test(fieldValue) ? null : { alphanumeric: { valid: false } };
  }

  static address(control: UntypedFormGroup): any {
    const REGEXP = /^[a-zA-Z0-9 .,/-]*$/;
    const fieldValue = control.value;
    return REGEXP.test(fieldValue) ? null : { address: { valid: false } };
  }

  static validateNonDuplicatedTravelers(travelers: Traveler[]) {
    return (control : UntypedFormControl) => {
      const watchControls = ['firstName', 'lastName', 'dateOfBirth'];
      const controls = [];
      watchControls.forEach(controlName => {
        controls[controlName] = control.root.get(controlName);
      });

      const travelerFullName = `${controls['firstName'].value} ${controls['lastName'].value}`;
      let isDuplicated = false;
      travelers.forEach((traveler) => {
        if (`${traveler.firstName.toLowerCase()} ${traveler.lastName.toLowerCase()}` === travelerFullName.toLowerCase()  && traveler.dateOfBirth === controls['dateOfBirth'].value)
          isDuplicated = true;
      });

      watchControls.forEach(controlName => {
        if (isDuplicated) {
          control.root.get(controlName).setErrors({ duplicateTraveler: true });
        } else if (control.root.get(controlName).value.length === 0) {
          control.root.get(controlName).setErrors({ required: true });
        } else {
          control.root.get(controlName).setErrors(null);
        }
      });

      if (isDuplicated) {
        return { duplicateTraveler: true };
      } else if(control.value.length === 0) {
        return { required: true }
      }

      return null;
    };
  }

  static validDate(format) {
    return (control: UntypedFormControl) => {
      return moment(control.value, format, true).isValid() ? null : { dateFormat: true, correctFormat: format };
    };
  }

  static password(c: UntypedFormControl) {
    if (c.value === '' || c.value === null) { return; }
    const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/;
    if (!PASSWORD_REGEX.test(c.value)) { return { password: { valid: false } }; }
    return null;
  }

  static checkBlockDomain(blockDomains: string[]) {
    return (control: UntypedFormControl) => {
      const controlValue = !control.value ? '' : control.value;
      const domain = controlValue.split('@')[1];
      if (!domain) { return null };
      const isBlockDomain = blockDomains.find((blockDomain) => domain.toLowerCase() === blockDomain.toLocaleLowerCase());
      return isBlockDomain ? { blockDomain: true , isBlockDomain } : null;
    }
  }

  static sameEmail(currentEmail: string) {
    return (control: FormControl) => {
      return control.value === currentEmail ? { sameEmail: true } : false;
    }
  }
  static strictValidation() {
    return (control: FormControl) => {
      if (!control.value || typeof control.value === 'object') {
        return null;
      }
      const countryCode = control?.parent?.get('country')?.value ? control?.parent?.get('country')?.value.code  : 'US';
      const phoneHelper = new PhoneNumberHelper(null, null);
      const isPhoneValid = phoneHelper.isValid(control.value , {
        countryCode 
      });
      return !isPhoneValid ? { phoneInput: { valid: false } } : null;
      }
  }

  static customBirthDateValidator() {
    return (control: UntypedFormControl) => {
      if (control.value === '') return null;
      return moment(control.value, 'YYYY-MM-DD', true).isValid() ? null : { dateFormat: true, correctFormat: 'MM/DD/YYYY' };
    };
  }
}
