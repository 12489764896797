import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { AccountValidationActions } from 'app/auth/store';
import * as fromAuth from 'app/auth/store/reducers';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';
import { CustomValidators } from 'app/shared/validators/custom-validators';
import * as moment from 'moment';
import * as fromRoot from 'reducers';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'account-validation',
  templateUrl: './account-validation.html'
})

export class AccountValidationPage implements OnInit, OnDestroy {
  personalDataForm;
  airline;
  organizationId;
  username;
  startHireDate;
  error;
  private ngUnsubscribe = new Subject<void>();
  pending$ = this.store.pipe(select(fromAuth.getAccountValidationPending));
  error$ = this.store.pipe(select(fromAuth.getAccountValidationError));
  success$ = this.store.pipe(select(fromAuth.getAccountValidationSuccess));
  readonly MAX_ATTEMPTS = 3;
  isBlocked: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<fromRoot.State>,
    private formBuilder: UntypedFormBuilder,
    public formErrorsHelper: FormErrorsHelper,
    private router: Router
  ) { }

  ngOnInit() {
    this.isBlocked = false;
    this.store.dispatch(new AccountValidationActions.ClearState());
    const params = this.activatedRoute.snapshot.params;
    this.airline = params.airline;
    this.organizationId = params.organizationId;
    this.username = params.username;
    this.personalDataForm = this.formBuilder.group({
      firstName: new UntypedFormControl('', [Validators.required]),
      lastName: new UntypedFormControl('', [Validators.required]),
      dateOfHire: new UntypedFormControl('', [Validators.required, CustomValidators.validDate('MM-DD-YYYY')])
    });
    this.listenForError();
  }

  validateAccount() {
    this.error = null;
    if (this.personalDataForm.valid) {
      const values = this.personalDataForm.value;
      const credentials = {
        firstName: values.firstName,
        lastName: values.lastName,
        hireDate: moment(values.dateOfHire, 'MM-DD-YYYY').format('YYYY-MM-DD'),
        employeeNumber: this.username,
        airlineCode: this.airline,
        organizationId: this.organizationId
      };
      this.store.dispatch(new AccountValidationActions.Validate({ credentials }));
    } else {
      Object.keys(this.personalDataForm.controls).forEach((field) => {
        const control = this.personalDataForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  cancel() {
    return this.router.navigate(['/login']);
  }

  get firstName() { return this.personalDataForm.get('firstName'); }
  get lastName() { return this.personalDataForm.get('lastName'); }
  get dateOfHire() { return this.personalDataForm.get('dateOfHire'); }

  listenForError() {
    this.error$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((error) => {
      if (error) {
        this.error = error.error.error_description;
        if (error.error.attempts >= this.MAX_ATTEMPTS || error.error.error_code === 'InvalidLogin') {
          this.isBlocked = true;
        }
      }
    });
  }

  closeAlert() {
    this.error = '';
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
