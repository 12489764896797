import { Injectable } from '@angular/core';
import { AffirmInformation } from 'app/shared/models/affirm.model';
import { environment } from '@env/environment';
import * as Sentry from '@sentry/angular';

@Injectable({
  providedIn: 'root'
})
export class AffirmService {

  constructor() { }

  checkAndStartAffirmScript() {
    if (window && !window.document.getElementById('affirm')) {
      window['_affirm_config']['public_api_key'] = environment.affirmKey;
      window['_affirm_config']['script'] = environment.affirmLibraryURL;
      const affirmScript = window.document.createElement('script');
      affirmScript.id = 'affirm';
      affirmScript.src = environment.affirmLibraryURL;
      window.document.head.prepend(affirmScript);
    }
  }

  createModal(affirmInformation: AffirmInformation, callback: any) {
    window.affirm.checkout(affirmInformation);
    this.modalCallback(callback);
  }

  initializeAffirm(publicKey) {
    window.affirm.apiKey.setPublicApiKey(publicKey);
  }

  checkPublicApiKey() {
    if (window.affirm.apiKey && typeof window.affirm.apiKey.checkPublicApiKey === 'function') {
    return window.affirm?.apiKey?.checkPublicApiKey();
    }
    return false;
  }

  affirmUiRefresh() {
    if(window.affirm && this.checkPublicApiKey()) {
      return window.affirm.ui.refresh();
    }
  }
  private modalCallback(callback) {
    window.affirm.checkout.open({
      onFail(error) {
        Sentry.captureException(error);
      },
      onSuccess(checkout) { callback({}, checkout.checkout_token); }
    });

  }
  formatTotalForAffirm(amount) {
   if(!amount) { return null}
   let formatamount = amount;
   if (typeof amount === 'string' || amount instanceof String) {
     return formatamount.toString().replace('.', '');
   }
   formatamount = amount.toFixed(2);
   const asDecimal = formatamount % 1 === 0 ? false : true;
   return asDecimal ? formatamount.toString().replace('.', '') : `${amount}00`;
  }
  canAffirmBeEnabled(flag, user, total?) {
    if (!flag || !user) { return false; }
    return total ? flag
    && total > 0
    && user.currency === 'USD'
    && (user.country === 'US' || user.country === 'CA')
    : flag
    && user.currency === 'USD'
    && (user.country === 'US' || user.country === 'CA');
  }
}
