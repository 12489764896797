import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AirportsDict } from 'app/shared/models/airport';

@Injectable({
  providedIn: 'root'
})
export class AirportsService {

  constructor (
    private http: HttpClient,
  ) {}

  getAirports(airlineCode?: string) {
    let params = new HttpParams();
    if (airlineCode) {
      params = params.append('airlineCode', airlineCode);
    }
    return this.http.get<string[]>(`${environment.apiUrl}/airports`, { params, headers: { cacheable: 'false' } }).pipe(
      map(airports => this.parseAirports(airports)),
      catchError((error: any) => throwError(error))
    );
  }

  getNearbyAirports(userCoords) {
    return this.http.get<string[]>(
      `${environment.apiUrl}/airports/location`,
      {
        params: {
          latitude: userCoords.latitude,
          longitude: userCoords.longitude
        }
      }
    );
  }

  parseAirports(airports): AirportsDict {
    return airports.reduce((acc, cur) => {
      const tmp = cur.split(' - ');
      acc[tmp[0]] = {
        code: tmp[0],
        name: tmp[1],
        city: tmp[2].split(' (')[0],
        cityFull: tmp[2],
        coordinates: {
          latitude: parseFloat(tmp[3]),
          longitude: parseFloat(tmp[4]),
        },
      };
      return acc;
    }, {});
  }

}
