import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { faAngleDown, faCaretDown, faCircleInfo, faTimes } from '@fortawesome/free-solid-svg-icons';
import { GeneralHelper } from 'app/shared/helpers/general.helper';
import { find, isEqual } from 'lodash';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  host: {
    '(document:click)': 'onClick($event)',
  },
  selector: 'custom-select',
  templateUrl: 'custom-select.component.html',
  styleUrls: ['custom-select.component.scss']
})

export class CustomSelectComponent implements OnInit, OnDestroy, OnChanges {
  @Input() id: string;
  @Input() label: string;
  @Input() items: any;
  @Input() inputFormControl: UntypedFormControl;
  @Input() multiSelect: boolean = true;
  @Input() showCount: boolean = true;
  @Input() gtmClass: String = '';
  @Input() showMoreMode: boolean = false;
  @Input() showMin: number = 5;
  @Input() resetScroll: boolean = false; // scroll to the top after selecting an item
  @Input() enableResetButton: boolean = false;
  // Custom-select updated styles for New Flight Results
  @Input() updatedStyles: boolean = false;
  @Output() updateFilters = new EventEmitter();
  @ViewChild('pillComponent', { static: false }) pillComponent: ElementRef;
  @ViewChild('itemList', { static: false }) itemList: ElementRef;

  showSelect = false;
  selectedItems = [];
  selectedValue = null;
  isInternetExplorer = GeneralHelper.isInternetExplorer();
  icons = { faTimes, faAngleDown, faCaretDown, faCircleInfo };

  private ngUnsubscribe = new Subject<void>();

  get resetButtonEnabled() {
    if (this.enableResetButton) {
      if (this.multiSelect) {
        return this.inputFormControl.value && this.inputFormControl.value.length > 0;
      }
      return this.inputFormControl.value !== '1';
    }
    return false;
  }

  ngOnInit() {
    // Form value may change from the component's parent
    if (this.inputFormControl.value) {
      this.setSelectedValue(this.inputFormControl.value);
    }
    this.inputFormControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe), filter(val => !!val)).subscribe((val) => {
      this.setSelectedValue(val);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.inputFormControl && changes.inputFormControl.currentValue.value) {
      // This is used in the full screen modal, new car-filters are created every time you open the modal
      // so we check if the formControl already had a value
      this.setSelectedValue(changes.inputFormControl.currentValue.value);
    }

    // Have Input options changed?
    if (!changes.items?.firstChange && changes.items && !isEqual(changes.items.previousValue, changes.items.currentValue)) {
      if (this.multiSelect) {
        // check if selectedItems exist in items
        this.selectedItems = this.selectedItems.filter(selectedValue => this.items.find(item => item.value === selectedValue));

      } else {
        // check if selectedValue exists in items
        const selectedItem = this.findItemByLabel(this.selectedValue);
        this.selectedValue = selectedItem ? selectedItem.label : null;
      }

      this.setFormValue();
    }
  }

  // Triggered by input changes
  updateValue(event) {
    const previousSelectedCount = this.selectedItems.length;

    if (this.multiSelect) {
      if (event.target.checked) {
        this.selectedItems.push(event.target.value);
      } else {
        this.selectedItems.splice(this.selectedItems.indexOf(event.target.value), 1);
      }
    } else {
      this.showSelect = false;
      const item = this.findItemByValue(event.target.value);
      this.selectedValue = item.label ? item.label : null;
    }

    if (this.resetScroll && previousSelectedCount !== this.selectedItems.length && this.itemList) {
      // Scroll to the top after selecting a neighborhood (hotels filter)
      // Newly selected neighborhoods are moved to the top of the list
      setTimeout(() => this.itemList.nativeElement.scrollTop = 0, 100);
    }

    this.setFormValue(true);
  }

  setSelectedValue(val) {
    this.selectedItems = val && Array.isArray(val) ? val.map(i => i) : [];

    const selectedItem = this.findItemByValue(val);
    this.selectedValue = selectedItem ? selectedItem.label : null;
  }

  findItemByValue(val) {
    return val ? find(this.items, (item) => {
      return item.value === val;
    }) : null;
  }

  findItemByLabel(val) {
    return val ? find(this.items, (item) => {
      return item.label === val;
    }) : null;
  }

  // Updates the form value and emits through updateFilters
  setFormValue(emitContent: boolean = false) {
    if (this.multiSelect) {
      this.inputFormControl.setValue(this.selectedItems.map(i => i));
    } else {
      const selectedItem = this.findItemByLabel(this.selectedValue);
      this.inputFormControl.setValue(selectedItem ? selectedItem.value : null);
    }

    if (emitContent) {
      const value = this.inputFormControl.value;
      const filterName = this.label ? this.label.toLowerCase().replace(' ', '-') : '';
      this.updateFilters.emit({ filterName, value });
    }
  }

  onClick(event) {
    if (!this.pillComponent) { return; }
    if (this.pillComponent.nativeElement === event.target || this.pillComponent.nativeElement.contains(event.target)) {
      this.showSelect = !this.showSelect;
    } else {
      this.showSelect = false;
    }
  }

  showMore() {
    this.showMin += 5;
  }

  get selectedCount() {
    if (this.multiSelect && this.showCount && this.selectedItems.length) {
      return this.selectedItems.length.toString();
    }
    return '';
  }

  get selected() {
    return this.selectedItems.length;
  }

  itemSelected(value) {
    if (this.multiSelect) {
      return this.selectedItems.indexOf(value) !== -1;
    }

    return this.inputFormControl.value === value;
  }

  get showOrHiddenIE() {
    if (!this.isInternetExplorer) return true;
    return this.showSelect || this.showMoreMode;
  }

  onResetClick() {
    if (this.enableResetButton) {
      if (this.multiSelect) {
        this.inputFormControl.reset([]);
      } else {
        this.inputFormControl.reset('1');
      }
      this.selectedItems = [];
      this.updateFilters.emit();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
