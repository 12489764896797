import { Action } from '@ngrx/store';

export enum WatchlistApiActionTypes {
  GetHotelWatchlistSuccess = '[Watchlist/API] Get Hotel Watchlist Success',
  GetHotelWatchlistFailure = '[Watchlist/API] Get Hotel Watchlist Failure',
  AddHotelWatchlistSuccess = '[Watchlist/API] Add Hotel Watchlist Success',
  AddHotelWatchlistFailure = '[Watchlist/API] Add Hotel Watchlist Failure',
  RemoveHotelWatchlistSuccess = '[Watchlist/API] Remove Hotel Watchlist Success',
  RemoveHotelWatchlistFailure = '[Watchlist/API] Remove Hotel Watchlist Failure'
}

export class GetHotelWatchlistSuccess implements Action {
  readonly type = WatchlistApiActionTypes.GetHotelWatchlistSuccess;

  constructor(public payload: any) {}
}

export class GetHotelWatchlistFailure implements Action {
  readonly type = WatchlistApiActionTypes.GetHotelWatchlistFailure;

  constructor(public payload: { error: any }) {}
}

export class AddHotelWatchlistSuccess implements Action {
  readonly type = WatchlistApiActionTypes.AddHotelWatchlistSuccess;

  constructor(public payload: { watchlist: any }) {}
}

export class AddHotelWatchlistFailure implements Action {
  readonly type = WatchlistApiActionTypes.AddHotelWatchlistFailure;

  constructor(public payload: { error: any }) {}
}

export class RemoveHotelWatchlistSuccess implements Action {
  readonly type = WatchlistApiActionTypes.RemoveHotelWatchlistSuccess;

  constructor(public payload: { watchlist: any }) {}
}

export class RemoveHotelWatchlistFailure implements Action {
  readonly type = WatchlistApiActionTypes.RemoveHotelWatchlistFailure;

  constructor(public payload: { error: any }) {}
}

export type WatchlistApiActionsUnion = GetHotelWatchlistSuccess
  | GetHotelWatchlistFailure
  | AddHotelWatchlistSuccess
  | AddHotelWatchlistFailure
  | RemoveHotelWatchlistSuccess
  | RemoveHotelWatchlistFailure;
