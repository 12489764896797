import { Component, OnInit } from '@angular/core';
import { faFacebookF, faInstagram, faLinkedinIn, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faEnvelopeOpen, faPhone } from '@fortawesome/free-solid-svg-icons';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as fromAuth from 'app/auth/store/reducers';
import { featureFlags } from 'app/shared/models/featureFlags';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { DynamicScriptLoaderService } from 'app/shared/services/dynamic-script-load/dynamic-script-load.service';

@Component({
  selector: 'footer-component',
  templateUrl: './footer.html',
  styleUrls: ['./footer.scss']
})

export class FooterComponent implements OnInit {
  loggedIn$ = this.store.pipe(select(fromAuth.getLoggedIn));
  ngUnsubscribe$ = new Subject<void>();
  currentYear = moment().year();
  featureFlags = featureFlags;
  icons = { faEnvelope, faEnvelopeOpen, faPhone, faFacebookF, faLinkedinIn, faInstagram, faYoutube, faTiktok };

  constructor(
    private store: Store,
    private translate: TranslateService,
    private dynamicScriptLoader: DynamicScriptLoaderService
  ) { }

  async ngOnInit() {
    // Uncomment this when the TrustArc implementation is finished
    // try {
    //   await this.dynamicScriptLoader.load('trustArc');
    // } catch (error) {
    //   console.log(error, 'error')
    // }
  }

  openBeamer(key: string) {
    const url = this.translate.instant(key);

    if (url === 'web.nav.url.whats_new' || url === "#") {
      return window['Beamer']?.show();
    }
    window.location.href = url;
  }
}
