import { Action } from '@ngrx/store';
import { LoadFlightParams, SearchFlightParams } from 'app/flights/models/searches.interface';
import { Legs } from 'app/flights/models/legs.interface';
import { SearchParams } from 'app/flights/models/search-params.interface';

export enum SearchFormActionTypes {
  LoadSearchForm = '[Flights] Load Search Form',
  LoadSearchFormSuccess = '[Flights] Search Form Success',
  LoadSearchFormFailure = '[Flights] Search Form Failure',
  LoadSearchFormRedirect = '[Flights] Search Form Redirect',
  SearchFlights = '[Flights] Search Flights',
  SearchFlightsSuccess = '[Flights] Search Flights Success',
  LoadFlights = '[Flights] Load Flights',
  LoadFlightsSuccess = '[Flights] Load Flights Success',
  LoadFlightsFailure = '[Flights] Load Flights Failure',
  UpdateSearchFlights = '[Flights] Update Search Flights',
  UpdateTypeOfTrip = '[Flights] Update Type Of Trip',
  UpdateFlightLegs = '[Flights] Update Flight Legs',
  ClearSearchFlights = '[Flights] Clear Search Flights',
  SetFirstSearchFlag = '[Flights] Set First Search Flag'
}

export class LoadSearchForm implements Action {
  readonly type = SearchFormActionTypes.LoadSearchForm;

  constructor(public payload: { options: any }) {}
}

export class LoadSearchFormSuccess implements Action {
  readonly type = SearchFormActionTypes.LoadSearchFormSuccess;

  constructor(public payload: { data: any, toolKey: string }) {}
}

export class LoadSearchFormFailure implements Action {
  readonly type = SearchFormActionTypes.LoadSearchFormFailure;

  constructor(public payload: { error: any }) {}
}

export class LoadSearchFormRedirect implements Action {
  readonly type = SearchFormActionTypes.LoadSearchFormRedirect;

  constructor(public payload: { url: any }) {}
}

export class SearchFlights implements Action {
  readonly type = SearchFormActionTypes.SearchFlights;

  constructor(public payload: { searchParams: SearchFlightParams }) {}
}

export class SearchFlightsSuccess implements Action {
  readonly type = SearchFormActionTypes.SearchFlightsSuccess;

  constructor(public payload: { searchId: string }) {}
}

export class LoadFlights implements Action {
  readonly type = SearchFormActionTypes.LoadFlights;

  constructor(public payload: { params: LoadFlightParams }) {}
}

export class LoadFlightsSuccess implements Action {
  readonly type = SearchFormActionTypes.LoadFlightsSuccess;

  constructor(public payload: { data: any }) {}
}

export class LoadFlightsFailure implements Action {
  readonly type = SearchFormActionTypes.LoadFlightsFailure;

  constructor(public payload: { error: any }) {}
}

export class UpdateSearchFlights implements Action {
  readonly type = SearchFormActionTypes.UpdateSearchFlights;

  constructor(public payload: { searchParams: SearchFlightParams }) {}
}

export class UpdateTypeOfTrip implements Action {
  readonly type = SearchFormActionTypes.UpdateTypeOfTrip;

  constructor(public payload: { type: string }) {}
}

export class UpdateFlightLegs implements Action {
  readonly type = SearchFormActionTypes.UpdateFlightLegs;

  constructor(public payload: { legs: Legs[]}) {}
}

export class ClearSearchFlights implements Action {
  readonly type = SearchFormActionTypes.ClearSearchFlights;

  constructor() {}
}

export class SetFirstSearchFlag implements Action {
  readonly type = SearchFormActionTypes.SetFirstSearchFlag;

  constructor() {}
}

export type SearchFormActionsUnion = LoadSearchForm
  | LoadSearchFormSuccess
  | LoadSearchFormFailure
  | LoadSearchFormRedirect
  | SearchFlights
  | UpdateSearchFlights
  | UpdateTypeOfTrip
  | UpdateFlightLegs
  | SearchFlightsSuccess
  | ClearSearchFlights
  | SetFirstSearchFlag;
