import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HelpersService {

  constructor(
    private http: HttpClient
  ) {}

  getAvailableConfigFlags(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v2/ffs_app_config_V2.json`);
  }

  getAvailableFeatureFlags(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/features`);
  }

  setUserFeatureFlag(feature) {
    return this.http.post(`${environment.apiUrl}/api/v1/features`, { feature });
  }

  deleteUserFeatureFlag(platform) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { feature: platform },
    };
    return this.http.delete(`${environment.apiUrl}/api/v1/features`, options);
  }

  getAirlineFeatureFlags(airlineCode): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v2/ffs_airlines_features?airlineCode=${airlineCode}`);
  }
}
