<div class="invite-a-friend-card card clickable-card my-2" id="gtm-iaf" (click)="onClicked()">
	<div class="row card-body" id="gtm-iaf">
		<div class="col-4 text-center d-none d-md-block">
			<img class="img-fluid" id="gtm-iaf" src="angular_assets/images/common/invite-a-friend-characters.png" />
		</div>
		<div class="col-12 col-md-8">
			<div class="primary-content" id="gtm-iaf">{{ storyblokTitle }}</div>
			<div class="tertiary-content mb-2 pt-2" id="gtm-iaf">{{ storyblokText }}</div>
			<a class="btn btn-link float-end" id="gtm-iaf" routerLink="{{path}}" role="button">{{ 'invite.menu_title' | translate}}</a>
		</div>
	</div>
</div>
