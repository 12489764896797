import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import { select, Store } from '@ngrx/store';
import * as fromAuth from 'app/auth/store/reducers';
import { CreditActions, MemberLoyaltyActions, ProfileUserActions } from 'app/profile/store/actions';
import * as fromProfile from 'app/profile/store/reducers';
import * as fromRoot from 'reducers';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'member-rewards-home',
  templateUrl: './member-rewards-home.component.html',
  styleUrls: ['./member-rewards-home.component.scss']
})
export class MemberRewardsHomeComponent implements OnInit, OnDestroy {
  @Input() isMobileView: boolean;
  @Input() mode = 'fill-parent';

  nextTierReward = 0;
  nextLevel = 0;
  nextTierLtv = 0;
  nextTierGapLtv = 0;
  currentLevel = 0;
  currentTierLtv = 0;
  size = 'small';
  showAwayFromNextLevel = false;
  showLevels = false;
  userCredits = 0;
  nextTierCurrentLtv = 0;
  user : any;
  currency : string;
  icons = { faTrophy };

  private ngUnsubscribe = new Subject<void>();
  private memberLoyaltySummary$ = this.store.select(fromProfile.getMemberLoyalty);
  private userCredits$ = this.store.select(fromProfile.getUserCredits);
  private customerSupport$ = this.store.pipe(select(fromAuth.getCustomerSupport));
  private getUserInfo$ = this.store.select(fromProfile.getUserProfile);

  constructor(
    private store: Store<fromRoot.State>
  ) { }

  ngOnInit() {
    this.getUser();
    this.showAwayFromNextLevel = this.isMobileView;
    this.showLevels = !this.isMobileView;
    this.size = this.isMobileView ? 'small' : 'big';
  }
  getUser() {
    this.getUserInfo$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
      if (!user) { this.store.dispatch(new ProfileUserActions.LoadUserProfile()); }
      this.user = user;
      this.currency = user?.currency ? user.currency : 'USD';
      this.getMemberLoyalty();
      this.getUserCredit();
      this.getCustomerSupport();
      this.store.dispatch(new MemberLoyaltyActions.LoadLoyalty({ currency : this.currency }));
      this.store.dispatch(new CreditActions.LoadCredits());
    });
  }

  getUserCredit() {
    this.userCredits$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((userCredtis: any) => {
      if (userCredtis) {
        this.userCredits = userCredtis.displayAmount;
      }
    });
  }
  getCustomerSupport() {
    this.customerSupport$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((customerSupport) => {
      if (!customerSupport.pending && customerSupport.error.length === 0 && customerSupport.userSelectedByOperator) {
        this.store.dispatch(new CreditActions.LoadCredits());
        this.store.dispatch(new MemberLoyaltyActions.LoadLoyalty(this.user.currency));
      }
    });
  }

  getMemberLoyalty() {
    this.memberLoyaltySummary$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((summary: any) => {
      if (summary) {
        this.currentLevel = summary.current_level;
        this.currentTierLtv = summary.current_tier_ltv;
        this.nextLevel = summary.next_level;
        this.nextTierReward = summary.next_tier_reward;
        this.nextTierLtv = summary.next_tier_ltv;
        this.nextTierGapLtv = summary.next_tier_gap_ltv;
        this.nextTierCurrentLtv = summary.next_tier_current_ltv;
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
