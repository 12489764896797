import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as fromAuth from 'app/auth/store/reducers';
import { DemandCalendarService } from 'app/hotels/services/demand-calendar/demand-calendar.service';
import * as fromProfile from 'app/profile/store/reducers';
import { DatePickerHelper } from 'app/shared/helpers/date-picker.helper';
import { DestinationHelper } from 'app/shared/helpers/destination.helper';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';
import { SmartlookHelper } from 'app/shared/helpers/smartlook.helper';
import { HotelSearch } from 'app/shared/models/hotel-search';
import { HotelsRecentSearch } from 'app/shared/models/hotels-recent-search';
import { DateConfig, DestinationsConfig } from 'app/shared/models/rules';
import { TrendingDestination } from 'app/shared/models/trending-destination';
import * as fromShared from 'app/shared/store/reducers';
import { datesConfig } from 'configs/dates';
import * as moment from 'moment';
import * as fromRoot from 'reducers';
import { Subject } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { MultipleGuestsSectionComponent } from './multiple-guests/multiple-guests-section.component';

@Component({
  host: {
    '(document:click)': 'onDocumentClick()',
  },
  selector: 'hotel-search',
  templateUrl: './hotel-search.html',
  styleUrls: ['./hotel-search.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HotelSearchComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('locationsAutocomplete', { static: false }) locationsAutocomplete;
  @ViewChild('guestOptionsList', { static: false }) guestOptionsList: ElementRef;
  @ViewChild('guestSelect', { static: false }) guestSelect: ElementRef;

  @Input() hotelSearch = new HotelSearch();
  @Input() showLocationSearch = true;
  @Input() recentSearches: HotelsRecentSearch[];
  @Input() recentSearchesPending: boolean;
  @Input() trendingDestinations: TrendingDestination[];
  @Input() trendingDestinationsPending: boolean;
  @Input() allInclusive: boolean = false;
  @Input() clearInputAndKeepResult: boolean = false;
  @Input() displayInLine: boolean;
  @Input() removePadding: boolean = false;
  @Input() allInclusivePopupSearch?: boolean;
  @Input() set destination(value: any) {
    if (value) {
      this.selectedDestination = value.name;
      this.locationSelected(value, true);
    }
  }
  @Input() showDemandCalendar = true;
  @Output() searchInitiated = new EventEmitter();
  @Output() destinetionFocused = new EventEmitter();

  wasLocationSelected = false;
  openDatePicker = false;
  hotelSearchForm: UntypedFormGroup;
  guestsOptions = [
    { value: '1', label: 'Single Guest' },
    { value: '2', label: 'Two Guests' },
    { value: '2+', label: 'Multiple Guests' }
  ];
  rooms = [
    { adults: 2, children: 0, ages: [] }
  ];
  isSearching: boolean = false;
  guestValue: any;
  memoGuestValue: any;
  loading = false;
  destinationConfig : DestinationsConfig  | null = null;
  datePickerConfig : DateConfig | null = null;
  selectedDestination;

  @ViewChild('multipleGuestsComponent', { static: false }) multipleGuestsComponent: MultipleGuestsSectionComponent;

  demandCalendar$ = this.store.pipe(select(fromShared.getDemandCalendar));
  showBusinessToggle$ = this.store.select(fromProfile.getBusinessToggle);
  user$ = this.store.pipe(select(fromAuth.getUser));
  locationType: string;
  locationResolution: any;
  locationCountryCode: string;
  componentId = `_${Math.random().toString(36).substr(2, 9)}`;
  showMultipleGuest: boolean;
  multipleGuestIsOpen: boolean;
  modalReference: NgbModalRef;
  multipleGuestsErrors = [];
  formSubmitted: boolean;
  private ngUnsubscribe = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    public formErrorsHelper: FormErrorsHelper,
    public modalService: NgbModal,
    private store: Store<fromRoot.State>,
    private cd: ChangeDetectorRef,
    private router: Router,
    private translate: TranslateService,
    private datePickerHelper: DatePickerHelper,
    private destinationHelper: DestinationHelper,
    private demandCalendarService: DemandCalendarService,
    private smartlookHelper: SmartlookHelper
  ) { }

  ngOnInit() {
    this.loading = true;
    const defaultMinDay =  moment().add(-1, 'days');
    const defaultMaxDay =  moment().add(360, 'days');
    this.destinationConfig = {
      disabledChangeDestination : false
    }
    this.datePickerConfig = {
      minDate: moment(defaultMinDay),
      maxDate: moment(defaultMaxDay)
    }

    this.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
      if (user && user.account) {
        this.datePickerConfig = this.datePickerHelper.getDateConfig(
          user.account.airlineCode,
          'hotels',
          'search',
          this.hotelSearch.checkinIso,
          this.hotelSearch.checkoutIso,
          this.datePickerConfig
          );
          this.destinationConfig = this.destinationHelper.getDestinationConfig(user.account.airlineCode, 'hotels', 'search', this.destinationConfig);
      }
      this.loading = false;
    });
    const today = moment();
    const tomorrow = moment().add(1, 'days');
    this.guestValue = this.getGuestItemFromValue(this.hotelSearch['guests[]']);
    this.removePadding = this.isHomepage();
    this.hotelSearchForm = this.formBuilder.group({
      destination: [this.hotelSearch.destination || '', Validators.compose([Validators.required, this.destinationValidator()])],
      guests: [this.hotelSearch['guests[]'], Validators.compose([Validators.required])],
      latitude: [this.hotelSearch.latitude || '', {}],
      longitude: [this.hotelSearch.longitude || '', {}],
      rooms: [this.hotelSearch.rooms, {}],
      radius: [this.hotelSearch.radius || ''],
      dates: this.formBuilder.group(
        {
          checkin: [(moment(this.hotelSearch.checkin, datesConfig.momentFormats) || today)
            .format('MMM DD, YYYY'), Validators.required],
          checkout: [(moment(this.hotelSearch.checkout, datesConfig.momentFormats) || tomorrow)
            .format('MMM DD, YYYY'), Validators.required],
        },
        {
          validator: this.differentCheckinCheckout
        }
      ),
      businessTrip: [this.hotelSearch.businessTrip]
    });

    if (this.hotelSearch.businessTrip !== this.hotelSearchForm.value.businessTrip) {
      this.hotelSearchForm.get('businessTrip').setValue(this.hotelSearch.businessTrip);
    }

    if (this.hotelSearch.destination) {
      this.wasLocationSelected = true;
    }

    if (this.showDemandCalendar && this.hotelSearch.destination && this.hotelSearch.latitude && this.hotelSearch.longitude) {
      this.getDemandCalendar();
    } else {
      this.demandCalendarService.clearDemandCalendar();
    }

    this.listenToDestinationChanges();
  }

  ngAfterViewInit() {
    this.parseGuests();
  }

  differentCheckinCheckout(ac: AbstractControl) {
    const checkin = ac.get('checkin').value;
    const checkout = ac.get('checkout').value;

    if (checkin === checkout) {
      return { customError: 'errors.checkin_checkout_matching' };
    }
    const diff = moment(checkout, datesConfig.momentFormats).diff(moment(checkin, datesConfig.momentFormats), 'days');

    if (diff > 28) {
      return { customError: 'errors.length_of_stay_long' };
    }

    return null;
  }

  onDatesSelected(dates) {
    this.dates.get('checkin').setValue(dates.startDate);
    this.dates.get('checkout').setValue(dates.endDate);
    this.dates.markAsTouched();
    this.cd.detectChanges();
  }

  getGuestItemFromValue(value: string) {
    return this.guestsOptions.find(x => x.value === value);
  }

  onMultipleGuestPress() {
    this.multipleGuestIsOpen = !this.multipleGuestIsOpen;
    if (!this.multipleGuestIsOpen) {
      this.guestSelect.nativeElement.blur();
    }

  }

  onGuestsChanged(value) {
    if (value === '2+') {
      this.multipleGuestsComponent.openSection();
    } else {
      this.multipleGuestsComponent.closeSection();
      this.hotelSearchForm.get('rooms').setValue('1');
    }
  }

  handleGuestInLine(item, modalContent) {
    this.memoGuestValue = { ...this.guestValue };
    this.guestValue = item;

    if (item.value === '2+') {
      this.openModal(modalContent);
    } else {
      this.showMultipleGuest = false;
      this.hotelSearchForm.get('rooms').setValue('1');
    }
    this.hotelSearchForm.get('guests').setValue(item.value);
    this.multipleGuestIsOpen = false;
  }

  onModalClose() {
    this.guestValue = { ...this.memoGuestValue };
    this.handleGuestInLine(this.guestValue, null);
    this.modalReference.close();
    this.modalService.dismissAll();
  }

  onGuestsUpdated(event) {
    this.hotelSearchForm.get('rooms').setValue(event.numberOfRooms);
    this.rooms = event.rooms;
    if (this.formSubmitted) {
      this.isValidChildAges();
    }
  }

  destinationValidator(): ValidatorFn {
    return () => {
      if (!this.wasLocationSelected) {
        return { customError: 'Please select a valid destination from the list' };
      }
      return undefined;
    };
  }

  searchFromPopup() {
    if (this.hotelSearchForm.valid && this.locationSearchCheck()) {
      this.search();
    } else {
      this.showMultipleGuest = false;
      this.modalService.dismissAll();
    }
  }

  search() {
    this.location.markAsTouched();
    this.dates.markAsTouched();
    const isValidChildAges = this.isValidChildAges();
    this.formSubmitted = true;
    if (this.hotelSearchForm.valid && this.locationSearchCheck() && isValidChildAges) {
      const params = this.hotelSearchForm.value;
      params.checkin = this.dates.get('checkin').value;
      params.checkout = this.dates.get('checkout').value;
      params.roomsList = this.rooms;
      params['guests[]'] = this.getGuestsParams(params);
      params.destination = typeof (params.destination) === 'string' ? params.destination : params.destination.displayName;
      params.businessTrip = params.businessTrip ? params.businessTrip : false;
      if (this.allInclusive) { params.accommodationType = 'all-inclusive'; }
      if (this.locationType) { params.locationType = this.locationType; }
      if (this.locationResolution) { params.locationResolution = this.locationResolution; }
      if (this.locationCountryCode) { params.locationCountryCode = this.locationCountryCode; }
      this.showMultipleGuest = false;
      this.smartlookHelper.customEvent('Hotels - Search Start', {
        line_of_business: "Hotels",
        destination: params.destination,
        check_in: params.checkin,
        check_out: params.checkout,
        guests: this.hotelSearch.getNumberOfGuests()
      });
      this.searchInitiated.emit(params);
    }
  }

  locationSearchCheck() {
    if(this.showLocationSearch) {
      return !this.locationsAutocomplete.searching;
    }
    return true;
  }
  isValidChildAges(): boolean {
    this.multipleGuestsErrors = [];
    if (this.hotelSearchForm?.value?.guests === '2+') {
      for (let i = 0; i < this.rooms.length; i++) {
        if (this.rooms[i].children && this.rooms[i].ages.some(age => Number(age) === 0)) {
          this.multipleGuestsErrors.push(i);
        }
      }
    }
    return this.multipleGuestsErrors.length === 0;
  }

  locationChanging() {
    this.wasLocationSelected = false;
  }

  onSearchChanged(value) {
    this.isSearching = value;
    this.hotelSearchForm.markAsDirty();
  }

  getDemandCalendar() {
    this.demandCalendar$.pipe(take(1)).subscribe((calendar) => {
      if (!calendar.length) {
        this.demandCalendarService.getDemandCalendar({ latitude: this.hotelSearch.latitude, longitude: this.hotelSearch.longitude });
      }
    })
  }

  listenToDestinationChanges() {
    this.hotelSearchForm.get('destination').valueChanges.pipe(debounceTime(500), takeUntil(this.ngUnsubscribe)).subscribe((destination) => {
      if (destination?.name) {
        this.wasLocationSelected = true;
      } else if (!destination) {
        this.demandCalendarService.clearDemandCalendar();
      }
    });
  }

  locationSelected(location, forceDestinationName = false) {
    this.wasLocationSelected = true;

    const longitude = location.lng !== undefined ? location.lng : location.longitude;
    const latitude = location.lat !== undefined ? location.lat : location.latitude;
    const destination = location.name;

    if (this.allInclusive && !forceDestinationName && this.allInclusivePopupSearch) return;

    if (forceDestinationName) {
      this.hotelSearchForm.get('destination').setValue(destination);
    }

    this.hotelSearchForm.get('destination').updateValueAndValidity();
    this.hotelSearchForm.get('longitude').setValue(longitude);
    this.hotelSearchForm.get('latitude').setValue(latitude);


    if (latitude && longitude) {
      this.demandCalendarService.getDemandCalendar({latitude, longitude});
    }

    if (location.radius !== undefined) {
      this.hotelSearchForm.get('radius').setValue(location.radius);
    }
    if (location.locationResolution !== undefined) {
      this.locationResolution = location.locationResolution;
    }
    if (location.countryCode !== undefined) {
      this.locationCountryCode = location.countryCode;
    }
    this.locationType = location.locationType ? location.locationType : location.type;
    if (this.locationType === 'current') {
      this.locationResolution = 0.45;
      this.locationType = 'street_address';
    }
    if (location.type !== 'recent') { this.openDatePicker = true; } // Opens Date Picker for Mobile
    this.cd.detectChanges();
  }


  setRoomGuests(guests: string) {
    const rooms = [];
    guests.replace(/-/g, ',').split('|').forEach((room) => {
      const roomGuests = room.split(',').map(i => parseInt(i, 10));
      const adults = roomGuests.shift();
      rooms.push({ adults, children: roomGuests.length, ages: roomGuests });
    });
    this.hotelSearchForm.get('rooms').setValue(rooms.length);
    this.rooms = rooms;
  }

  getGuestsParams(params) {
    if (params.guests === '2+') {
      let rooms = '';
      const guests = params.roomsList;
      params.roomsList.forEach((room, index) => {
        let value = room.adults.toString();
        if (room.ages.length > 0) {
          room.ages.forEach((age) => {
            value += `,${age.toString()}`;
          });
        }
        rooms += value;
        if (guests[index + 1]) {
          rooms += '|';
        }
      });
      delete params.roomsList;
      return rooms;
    }

    delete params.roomsList;
    return params.guests;
  }

  parseGuests() {
    this.rooms = this.hotelSearch.roomsGuests;
    if (this.hotelSearch.getNumberOfGuests() > 2 || this.rooms.length > 1) {
      this.hotelSearchForm.get('guests').setValue('2+');
      this.guestValue = this.getGuestItemFromValue('2+');
      this.multipleGuestsComponent.openSection();
      this.cd.detectChanges();
    }
  }

  onDocumentClick() {
    this.cd.detectChanges();
  }

  openModal(content): void {
    this.showMultipleGuest = true;
    this.modalReference = this.modalService.open(content, { size: 'lg' });
  }
  private isHomepage(): boolean {
    if (this.router && this.router.url) {
      const currentLocation = this.router.url;
      if (currentLocation === '/' || currentLocation === '/search/hotels') {
        return true;
      }
    }
    return false;
  }

  get location() { return this.hotelSearchForm.controls['destination']; }
  get dates() { return this.hotelSearchForm.get('dates'); }

  public getGuestOptionLabel(guestConfig) {
    switch (guestConfig.value) {
      case '1':
        return this.translate.instant('lodging.hotels.guest_labels.single_guest');
      case '2':
        return this.translate.instant('lodging.hotels.guest_labels.two_guests');
      case '2+':
        return this.translate.instant('lodging.hotels.guest_labels.multiple_guests');
      default:
        return guestConfig.label;
    }
  }

  destinationClicked() {
    this.destinetionFocused.emit()
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
