import { Action } from "@ngrx/store";

export enum ShareItineraryActionTypes {
  Send = '[Share Itinerary] Send',
  SendSuccess = '[Share Itinerary] Send Success',
  SendFailure = '[Share Itinerarys] Send Failure',
  ClearState = '[Share Itinerarys] Clear State'
}

export class Send implements Action {
  readonly type = ShareItineraryActionTypes.Send;

  constructor(public payload: any) { }
}

export class SendSuccess implements Action {
  readonly type = ShareItineraryActionTypes.SendSuccess;

  constructor(public response: any) { }
}

export class SendFailure implements Action {
  readonly type = ShareItineraryActionTypes.SendFailure;

  constructor(public error: any) { }
}

export class ClearState implements Action {
  readonly type = ShareItineraryActionTypes.ClearState;

  constructor() { }
}

export type ShareItineraryActionsUnion = Send | SendSuccess | SendFailure | ClearState;