import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { LogoutComponentActions, AuthApiActions } from '../actions';
import { tap, exhaustMap, map, catchError } from 'rxjs/operators';
import { SessionService } from 'app/shared/services/session/session.service';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { FeatureFlagsService } from 'app/shared/services/featureFlags/featureFlags.service';
import { TranslateService } from '@ngx-translate/core';
import { SmartlookHelper } from 'app/shared/helpers/smartlook.helper';
import { AnalyticsService } from 'app/shared/services/analytics/analytics.service';

@Injectable()
export class LogoutEffects {

  logout$ = createEffect(()=>
    this.actions$.pipe(
      ofType<LogoutComponentActions.Logout>(LogoutComponentActions.LogoutComponentActionTypes.Logout),
      exhaustMap((res) =>
        this.authService.logout().pipe(
          map(response => {
            return new AuthApiActions.LogoutSuccess(res.payload)
          }),
          catchError(error => of(new AuthApiActions.LogoutFailure({ error })))
        )
      )
    )
  );

  logoutSuccess$ = createEffect(()=>
      this.actions$.pipe(
        ofType<AuthApiActions.LogoutSuccess>(AuthApiActions.AuthApiActionTypes.LogoutSuccess),
        map(action => action.payload),
        tap(async (payload) => {
          this.analyticsService.setUserPropertiesAndUserId(null, null)
          this.sessionService.logout();
          this.smartlookHelper.finishRecordingSession();
          if (payload?.logout_freeze || payload?.redirectUrl === '/') {
            return;
          }
          const navURL = await this.translate.get('nav.url').toPromise();
          const logoutRedirect = navURL?.logout;
          if (payload?.logout_url || logoutRedirect) {
            return window.location.href = payload?.logout_url || logoutRedirect;
          }
          if (payload?.redirectUrl) {
            return this.router.navigate(['/login'], { queryParams: { redirect: encodeURIComponent(payload.redirectUrl) }});
          }
          return this.router.navigate(['/']);
        })
      ),
    { dispatch: false }
  );

  marketingLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LogoutComponentActions.MarketingLogout>(LogoutComponentActions.LogoutComponentActionTypes.MarketingLogout),
      exhaustMap(() =>
        this.authService.marketingLogout().pipe(
          map(response => new AuthApiActions.LogoutMarketingEventSuccess(response)),
          catchError(error => of(new AuthApiActions.LogoutFailure({ error })))
        )
      )
    )
  );

  marketingLogoutSuccess$ = createEffect(()=>
    this.actions$.pipe(
      ofType(AuthApiActions.AuthApiActionTypes.LogoutMarketingEventSuccess),
      tap(() => {
        this.sessionService.logout();
        this.router.navigate(['/event-registration', { loggedOut: true }]);
      })
    ),
    { dispatch: false }
  );

  oauth2Logout$ = createEffect(()=>
    this.actions$.pipe(
      ofType<LogoutComponentActions.Oauth2Logout>(LogoutComponentActions.LogoutComponentActionTypes.Oauth2Logout),
      exhaustMap((action) =>
        this.authService.oauth2Logout(action?.payload?.airlineCode).pipe(
          map(response => new AuthApiActions.LogoutSuccess(response)),
          catchError(error => of(new AuthApiActions.LogoutFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private sessionService: SessionService,
    private router: Router,
    private authService: AuthenticationService,
    private analyticsService: AnalyticsService,
    private translate: TranslateService,
    private featureFlagsService: FeatureFlagsService,
    private smartlookHelper: SmartlookHelper
  ) {}
}
