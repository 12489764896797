import { GamificationActions } from '../actions';
import { createReducer, on, Action } from '@ngrx/store';
import { GamificationStatistics } from 'app/profile/models/gamification-statistics.interface';
import { GamificationCampaignModel } from 'app/shared/models/gamification.model';

export interface State {
  activeCampaigns: GamificationCampaignModel[];
  pending: boolean;
  error: any | null;
  showParticipationModal: boolean;
  statistics: GamificationStatistics;
  showAnimation: boolean;
}

export const initialState: State = {
  activeCampaigns: [],
  pending: false,
  error: null,
  showParticipationModal: true,
  statistics: null,
  showAnimation: true
};

const gamificationReducer = createReducer(
  initialState,
  on(GamificationActions.loadActiveCampaigns, state => ({ ...state, pending: true })),
  on(GamificationActions.loadActiveCampaignsSuccess, (state, { campaigns }) => ({ ...state, error: null, pending: false, activeCampaigns: [ ...campaigns ] })),
  on(GamificationActions.loadActiveCampaignsFailure, (state, { error }) => ({ ...state, activeCampaigns: initialState.activeCampaigns, error, pending: false })),
  on(GamificationActions.participateCampaign, state => ({ ...state, pending: true })),
  on(GamificationActions.participateCampaignSuccess, (state, { campaignId, participate }) => ({
    ...state,
    pending: true,
    activeCampaigns: state.activeCampaigns.map((campaign) => campaign.id === campaignId ? {...campaign, user_participating: participate } : campaign),
  })),
  on(GamificationActions.closeParticipationModal, state => ({ ...state, showParticipationModal: false })),
  on(GamificationActions.checkFreeEntryAvailable, state => ({ ...state, pending: true })),
  on(GamificationActions.checkFreeEntryAvailableSuccess, (state , { campaignId }) => ({
    ... state,
    campaigns: state.activeCampaigns.map((campaign) => campaign.id === campaignId ? {...campaign } : campaign),
  })),
  on(GamificationActions.checkFreeEntryAvailableFailure, (state , { error }) => ({ ... state })),
  on(GamificationActions.getStatistics, state => ({ ...state, pending: true })),
  on(GamificationActions.getStatisticsSuccess, (state, { statistics }) => ({... state, statistics, pending: false })),
  on(GamificationActions.getStatisticsFailure, (state , { error }) => ({... state, error })),
  on(GamificationActions.showGamificationAnimation, state => ({... state, showAnimation: true })),
  on(GamificationActions.hideGamificationAnimation, state => ({... state, showAnimation: false }))

);

export function reducer(state: State | undefined, action: Action) {
  return gamificationReducer(state, action);
}
