import { Component, Input, OnInit } from '@angular/core';
import { Autoplay } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types';

@Component({
  selector: 'companies-carousel-component',
  templateUrl: './companies-carousel.component.html',
  styleUrls: ['./companies-carousel.component.scss']
})

export class CompaniesCarouselComponent implements OnInit {
  @Input() companyLogosPaths = [];
  companyLogos = [];
  baseCompanyLogoPath = '/angular_assets/images/homepage/companies/';
  defaultCompanyLogosPaths = [
    `${this.baseCompanyLogoPath}Alaska.png`,
    `${this.baseCompanyLogoPath}Volaris.png`,
    `${this.baseCompanyLogoPath}UnitedAirlines.png`,
    `${this.baseCompanyLogoPath}Swissport.png`,
    `${this.baseCompanyLogoPath}Suncountry.png`,
    `${this.baseCompanyLogoPath}Spirit.png`,
    `${this.baseCompanyLogoPath}Norse.png`,
    `${this.baseCompanyLogoPath}jetBlue.png`,
    `${this.baseCompanyLogoPath}Hawaiian.png`,
    `${this.baseCompanyLogoPath}Frontier.png`,
    `${this.baseCompanyLogoPath}Czech.png`,
    `${this.baseCompanyLogoPath}Caribbean.png`
  ];
  sliderConfig: SwiperOptions = {
    modules: [Autoplay],
    autoplay: {
      delay: 1000,
      disableOnInteraction: false,
      pauseOnMouseEnter: false,
    },
    centeredSlides: true,
    loop: true,
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: 4,
      },
      1200: {
        slidesPerView: 5,
      },
    }
  };

  constructor() { }

  ngOnInit() {
    this.companyLogos = this.companyLogosPaths.length < 1 ? this.defaultCompanyLogosPaths : this.companyLogosPaths;
  }
}
