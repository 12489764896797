import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { User } from 'app/auth/models/user';
import * as fromAuth from 'app/auth/store/reducers';
import * as fromRoot from 'reducers';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cruise-merchandise',
  templateUrl: './cruise-merchandise.html',
  styleUrls: ['./cruise-merchandise.scss']
})

export class CruiseMerchandiseComponent implements OnInit, OnDestroy {
  user: User;
  storeUser$ = this.store.pipe(select(fromAuth.getUser));
  @Input() type;
  renderData;
  merchandiseData = {
    cruiseDestinations: {
      title: 'cruises.destinations.title',
      cards: [
        {
          img: 'https://marketing.id90travel.com/web/cruise/cruise_ships/DEST_Caribbean.jpg',
          params: 'field_cruisedeal_region_tid=63',
          primaryKey: 'cruises.destinations.primary1',
          secondaryKey: 'cruises.destinations.secondary1'
        },
        {
          img: 'https://marketing.id90travel.com/web/cruise/cruise_ships/DEST_Alaska.jpg',
          params: 'field_cruisedeal_region_tid=64',
          primaryKey: 'cruises.destinations.primary2',
          secondaryKey: 'cruises.destinations.secondary2'
        },
        {
          img: 'https://marketing.id90travel.com/web/cruise/cruise_ships/DEST_Mediterranean.jpg',
          params: 'field_cruisedeal_region_tid=68',
          primaryKey: 'cruises.destinations.primary3',
          secondaryKey: 'cruises.destinations.secondary3'
        },
        {
          img: 'https://marketing.id90travel.com/web/cruise/cruise_ships/DEST_Mexico.jpg',
          params: 'field_cruisedeal_region_tid=74',
          primaryKey: 'cruises.destinations.primary4',
          secondaryKey: 'cruises.destinations.secondary4'
        }
      ]
    },
    cruiseFamily: {
      title: 'cruises.family.title',
      subtitle: 'cruises.family.subtitle',
      cards: [
        {
          img: 'https://marketing.id90travel.com/web/cruise/cruise_ships/RCI_Symphony.jpg',
          params: 'field_cruisedeal_cruiseline_tid=18',
          primaryKey: 'cruises.family.primary1',
          secondaryKey: 'cruises.family.secondary1'
        },
        {
          img: 'https://marketing.id90travel.com/web/cruise/cruise_ships/NCL_Getaway.jpg',
          params: 'field_cruisedeal_cruiseline_tid=13',
          primaryKey: 'cruises.family.primary2',
          secondaryKey: 'cruises.family.secondary2'
        },
        {
          img: 'https://marketing.id90travel.com/web/cruise/cruise_ships/PCL_Majestic_Princess.jpg',
          params: 'field_cruisedeal_cruiseline_tid=16',
          primaryKey: 'cruises.family.primary3',
          secondaryKey: 'cruises.destinations.secondary3'
        },
        {
          img: 'https://marketing.id90travel.com/web/cruise/cruise_ships/CCL_Legend.jpg',
          params: 'field_cruisedeal_cruiseline_tid=4',
          primaryKey: 'cruises.family.primary4',
          secondaryKey: 'cruises.destinations.secondary4'
        }
      ]
    },
    cruiseLuxury: {
      title: 'cruises.luxury.title',
      cards: [
        {
          img: 'https://marketing.id90travel.com/web/cruise/cruise_ships/PGN_Paul_Gauguin.jpg',
          params: 'field_cruisedeal_cruiseline_tid=15',
          primaryKey: 'cruises.luxury.primary1',
          secondaryKey: 'cruises.luxury.secondary1'
        },
        {
          img: 'https://marketing.id90travel.com/web/cruise/cruise_ships/AZA_Pursuit.jpg',
          params: 'field_cruisedeal_cruiseline_tid=2',
          primaryKey: 'cruises.luxury.primary2',
          secondaryKey: 'cruises.luxury.secondary2'
        },
        {
          img: 'https://marketing.id90travel.com/web/cruise/cruise_ships/OCE_Regatta.jpg',
          params: 'field_cruisedeal_cruiseline_tid=14',
          primaryKey: 'cruises.luxury.primary3',
          secondaryKey: 'cruises.luxury.secondary3'
        },
        {
          img: 'https://marketing.id90travel.com/web/cruise/cruise_ships/SVR_Silver_Muse.jpg',
          params: 'field_cruisedeal_cruiseline_tid=21',
          primaryKey: 'cruises.luxury.primary4',
          secondaryKey: 'cruises.luxury.secondary4'
        }
      ]
    }
  };

  private ngUnsubscribe = new Subject<void>();

  constructor(private store: Store<fromRoot.State>) {}

  ngOnInit() {
    if (this.type === 'luxury') {
      this.renderData = this.merchandiseData.cruiseLuxury;
    } else if (this.type === 'family') {
      this.renderData = this.merchandiseData.cruiseFamily;
    } else {
      this.renderData = this.merchandiseData.cruiseDestinations;
    }
  }

  search(params) {
    this.storeUser$.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((user) => {
      this.user = user;
    });
    const cruiseDiscountAirline = this.user.member ? this.user.member.cruise_discount_airline : false;
    const url = cruiseDiscountAirline ? 'https://cruises.id90travel.com/cruise-deal-view?' : 'https://voyages.id90travel.com/cruise-deal-view?';

    window.open(`${url}${params}&pin=${this.user.pin}`, '_blank');
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
