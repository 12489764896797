import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromWatchlist from './watchlist.reducer';
import * as fromRoot from 'reducers';
import * as fromStealthCredits from './stealth-credits.reducer';
import * as fromHotelResultsCache from './hotel-results-cache.reducer';
import * as fromHotelDetails from './hotel-details.reducer';
import * as fromFilters from './filters.reducer';
import * as fromShareItinerary from './share-itinerary.reducer';
import * as fromHotelStream from './hotel-results-streaming.reducer';
import { state } from '@angular/animations';

export interface HotelState {
  watchlists: fromWatchlist.State;
  stealthCredits: fromStealthCredits.State;
  filters: fromFilters.State,
  resultsCache: fromHotelResultsCache.State;
  hotelDetail: fromHotelDetails.State;
  shareItinerary: fromShareItinerary.State;
  hotelStream : fromHotelStream.State;
}

export interface State extends fromRoot.State {
  hotels: HotelState;
}

export const reducers: ActionReducerMap<HotelState> = {
  watchlists: fromWatchlist.reducer,
  stealthCredits: fromStealthCredits.reducer,
  filters: fromFilters.reducer,
  resultsCache: fromHotelResultsCache.reducer,
  hotelDetail: fromHotelDetails.reducer,
  shareItinerary: fromShareItinerary.reducer,
  hotelStream : fromHotelStream.reducer
};

export const selectHotelsState               = createFeatureSelector<State, HotelState>('hotels');
export const selectWatchlistHotelsState      = createSelector(selectHotelsState, state => state.watchlists);
export const selectStealthCreditsState       = createSelector(selectHotelsState, state => state.stealthCredits);
export const selectFiltersState              = createSelector(selectHotelsState, state => state.filters);
export const selectHotelResultsCache         = createSelector(selectHotelsState, state => state.resultsCache);
export const selectHotelDetail               = createSelector(selectHotelsState, state => state.hotelDetail);
export const selectShareItinerary            = createSelector(selectHotelsState, state => state.shareItinerary);
export const selectHotelResultStream      = createSelector(selectHotelsState, state => state.hotelStream);

export const getHotelWatchlist   = createSelector(selectWatchlistHotelsState, fromWatchlist.getHotelWatchlists);
export const getWatchlistPending = createSelector(selectWatchlistHotelsState, fromWatchlist.getWatchlistPending);
export const getWatchlistError   = createSelector(selectWatchlistHotelsState, fromWatchlist.getWatchlistError);

export const initHotelStream = createSelector(selectHotelResultStream, fromHotelStream.getHotelStream);
export const getHotelsStreamSuccess = createSelector(selectHotelResultStream, fromHotelStream.getHotelsSuccess);
export const getHotelsStreamFailure =  createSelector(selectHotelResultStream, fromHotelStream.getHotelsStreamFailure);

export const getStealthCredits = createSelector(selectStealthCreditsState, fromStealthCredits.getStealthCredits);
export const getFiltersState = createSelector(selectFiltersState, fromFilters.getFilterState);

export const getHotelsCacheResults = createSelector(selectHotelResultsCache, fromHotelResultsCache.getHotelsCacheResults);
export const getHotelsCacheScrollIndex = createSelector(selectHotelResultsCache, fromHotelResultsCache.getHotelsCacheScrollIndex);
export const getHotelsCacheFilters = createSelector(selectHotelResultsCache, fromHotelResultsCache.getHotelsCacheFilters);
export const getHotelsResultsView = createSelector(selectHotelResultsCache, fromHotelResultsCache.getHotelsResultsView);

export const getHotelDetail = createSelector(selectHotelDetail, fromHotelDetails.getHotelDetail);
export const getAmenities = createSelector(selectHotelDetail, fromHotelDetails.getAmenities);
export const getLoadingDetail = createSelector(selectHotelDetail, fromHotelDetails.getLoadingDetail);

export const getShareItinerarySuccess = createSelector(selectShareItinerary, fromShareItinerary.getSendSuccess);
export const getShareItineraryError = createSelector(selectShareItinerary, fromShareItinerary.getSendError);
export const getShareItineraryPending = createSelector(selectShareItinerary, fromShareItinerary.getSendPending);
