import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { CurrencyHelper } from 'app/shared/helpers/currency.helper';
import * as moment from 'moment';

@Component({
  selector: 'abandon-hotel-card',
  templateUrl: './abandon-hotel-card.html'
})

export class AbandonHotelCardComponent implements OnInit {
  @Input() data;
  @Output() clicked = new EventEmitter();
  image;
  abandonHotelParams;
  dates;
  type = 'abandon-hotel';
  
  constructor(private currencyHelper: CurrencyHelper) {}

  ngOnInit() {
    this.image = this.data.hotel_image;
    this.dates = {
      checkin: moment.utc(this.data.checkin_date).format('MMM DD'),
      checkout: moment.utc(this.data.checkout_date).format('MMM DD')
    };
    this.abandonHotelParams = this.abandonHotelParamsToCheckOut(this.data);
  }

  formatPrice(price: number) {
    // TODO: Abandoned Hotels response should bring back currency used
    return this.currencyHelper.toSelectedCurrencySymbol(price, 'USD', '1.0-0');
  }

  abandonHotelParamsToCheckOut(paramsFromService) {
    const params: URLSearchParams = new URLSearchParams(paramsFromService.checkout_url);

    const guests: string = params.get('guests[]') &&
      params.get('guests[]')
        .replace(/%5B/g, '')
        .replace(/%5D/g, '')
        .replace('[', '')
        .replace(']', '');

    const parsedParams = {
      id: params.get('id'),
      hotelId: params.get('id'),
      hotel_service_id: params.get('id'),
      destination: paramsFromService.city,
      hotelName: paramsFromService.hotel_name,
      checkin_date: moment.utc(paramsFromService.checkin_date, ['YYYY-MM-DD', 'DD-MM-YYYY']).format('YYYY-MM-DD'),
      checkout_date: moment.utc(paramsFromService.checkout_date, ['YYYY-MM-DD', 'DD-MM-YYYY']).format('YYYY-MM-DD'),
      'guests[]': guests,
      room_id: decodeURI(params.get('room_id')),
      rooms: params.get('rooms'),
      hotelPrice: paramsFromService.hotel_price
    };
    return parsedParams;
  }

  onClicked() {
    this.clicked.emit({
      path: `hotels/checkout/${this.data.city}/${this.data.hotel_name}/reservations`,
      data: this.abandonHotelParams
    });
  }
}
