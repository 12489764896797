import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: 'confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {

  @Input() title: string;
  @Input() titleParams: any;
  @Input() subtitle: string;
  @Input() message: string;
  @Input() messageTxt: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() primaryBtn: string;

  constructor(private activeModal: NgbActiveModal) { }

  get primaryText() {
    return this.primaryBtn === 'OK' ? this.btnOkText : this.btnCancelText;
  }

  get secondaryText() {
    return this.primaryBtn === 'OK' ? this.btnCancelText : this.btnOkText;
  }

  public primary() {
    this.primaryBtn === 'OK' ? this.accept() : this.decline();
  }

  public secondary() {
    this.primaryBtn === 'OK' ? this.decline() : this.accept();
  }

  public decline() {
    this.activeModal.close(false);
  }

  public accept() {
    this.activeModal.close(true);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

}
