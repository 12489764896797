<div class="invite-a-friend-card card my-2">
  <div class="row card-body" style="flex: 0 0 auto;">
    <div *ngIf="showPicture" class="col-4 text-center d-none d-md-block">
      <img class="pe-2 img-fluid" src="angular_assets/images/common/teal-moneybag.png" />
    </div>
    <div class="col-12" [ngClass]="showPicture ? 'col-md-8' : 'col-md-12'">
      <div class="primary-content">{{'lodging.credits.current_balance' | translate }} {{userCredits.displayAmount | currency:userCredits.currency:'symbol-narrow':'1.2-2'}}<sup *ngIf="!isUsd">*</sup></div>
      <div class="tertiary-content" *ngIf="userCredits.expiration">{{ allCreditsExpire ? ('lodging.credits.credits_expires' | translate) : ('profile.credits.some_credits_expire' | translate) }}: {{ userCredits.expiration | amUtc | amDateFormat:'MMM DD, YYYY' }}</div>
      <div class="tertiary-content mb-2 pt-2">{{'lodging.credits.next_purchase' | translate }}</div>
      <div class="estimate-message mb-2 pt-4" *ngIf="!isUsd">
        {{ 'profile.credits.estimate_in_usd' | translate }}
      </div>
    </div>
  </div>
</div>
