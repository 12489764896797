import { Action } from '@ngrx/store';

export enum HotelRoomsActionTypes {
  GetRooms = '[Hotel Rooms] Get Rooms',
  GetRoomsSuccess = '[Hotel Rooms] Get Rooms Success',
  GetRoomsFailure = '[Hotel Rooms] Get Rooms Failure',
  SetRoomsCredits = '[Hotel Rooms] Set Rooms Credits',
  GetRoom = '[Hotel Rooms] Get Room',
  GetRoomSuccess = '[Hotel Rooms] Get Room Success',
  GetRoomFailure = '[Hotel Rooms] Get Room Failure'
}

export class GetRooms implements Action {
  readonly type = HotelRoomsActionTypes.GetRooms;
  constructor(public payload: any) { }
}

export class GetRoomsSuccess implements Action {
  readonly type = HotelRoomsActionTypes.GetRoomsSuccess;
  constructor(public payload: any) { }
}

export class GetRoomsFailure implements Action {
  readonly type = HotelRoomsActionTypes.GetRoomsFailure;
  constructor(public payload: any) { }
}

export class SetRoomsCredits implements Action {
  readonly type = HotelRoomsActionTypes.SetRoomsCredits;
  constructor(public payload: any) { }
}

export class GetRoom implements Action {
  readonly type = HotelRoomsActionTypes.GetRoom;
  constructor(public payload: any) { }
}

export class GetRoomSuccess implements Action {
  readonly type = HotelRoomsActionTypes.GetRoomSuccess;
  constructor(public payload: any) { }
}

export class GetRoomFailure implements Action {
  readonly type = HotelRoomsActionTypes.GetRoomFailure;
  constructor(public payload: any) { }
}

export type HotelFilterActionsUnion =
  GetRooms |
  GetRoomsSuccess |
  GetRoomsFailure |
  SetRoomsCredits |
  GetRoom |
  GetRoomSuccess |
  GetRoomFailure;
