<div class="container">
  <div class="primary-content my-4 d-flex justify-content-between">
    <span>{{ 'profile.credits.history_modal_title_xs' | translate }}</span>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
  </div>
  <img class="img-fluid px-4 mb-3" src="angular_assets/images/profile/credit_image.png" [alt]="'profile.credits.history_modal_title_xs' | translate">
  <div class="bg-primary text-white p-2 rounded text-center text-uppercase text-white" style="line-height: 1.2;">
    {{ 'profile.credits.credits_available' | translate }}<br/>
    <b style="font-size: 1.4em;">{{ userCredits.displayAmount | currency: userCredits.currency:'symbol-narrow':'1.2-2' }}</b>
  </div>
  <div class="row justify-content-center mt-1" *ngIf="!isUsd">
    <div class="col-md-auto estimate-usd-message">
      {{ 'profile.credits.estimate_in_usd' | translate }}
    </div>
  </div>
  <div class="row justify-content-center mt-1">
    <div class="col-md-auto estimate-usd-message">
      {{ 'profile.credits.historical_in_usd' | translate }}
    </div>
  </div>
  <div class="list-group credit-history-list">
    <div class="list-group-item" *ngFor="let credit of userCreditHistory">
      <fa-icon [icon]="icons.faSpinner" class="text-primary" aria-hidden="true"></fa-icon>
      <h5 [ngClass]="{ 'text-success': credit.amount > 0, 'text-danger': credit.amount < 0 }">
        {{ credit.amount > 0 ? '+' : '' }}{{ credit.amount | currencyConvert:'USD' }}
      </h5>
      <div class="d-flex flex-nowrap justify-content-between">
        <div class="text-nowrap">
          {{ 'profile.credits.transaction_date' | translate }}<br/>
          {{ 'profile.credits.expiration_date' | translate }}
        </div>
        <div class="text-nowrap">
          <b>{{ credit.time_updated ? (credit.time_updated | amUtc | amDateFormat:'MM/DD/YYYY') : 'N/A' }}</b><br/>
          <b>{{ credit.expiration_date ? (credit.expiration_date | amUtc | amDateFormat:'MM/DD/YYYY') : 'N/A' }}</b>
        </div>
      </div>
    </div>
  </div>
</div>
