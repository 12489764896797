import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { ContentInteractionData } from 'app/shared/models/analytics-data';
import { MemberService } from 'app/shared/services/member/member.service';
import { isString } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(
    private analytics: AngularFireAnalytics,
    private memberService: MemberService
  ) { }

  logFirebaseSessionEvent(data, airlines, eventType: 'login' | 'sign_up') {
    this.setUserPropertiesAndUserId(data.user.member.id, {
      account_id: data.user.member.id90_user_id,
      account_type: this.memberService.getMemberType(data.user, airlines),
      account_airline: data.user.member.airline_code,
      account_total_spend: data.user.total_spent || 0
    });
    this.logEvent(eventType, {
      transaction_id: data.user.member.id90_user_id,
      method: data.method || 'Email',
      success: true,
      message: 'Login Success'
    });
  }

  async setUserPropertiesAndUserId(id: string | null, properties: any) {
    try {
      await this.analytics.setUserId(id);
      await this.analytics.setUserProperties(properties);
    } catch (error) {
      console.log(error)
    }
  }

  logEvent(name: string, data: any) {
    data.platform = 'Web'
    this.analytics.logEvent(name, data).catch((error) => console.log(error));
  }

  logViewItem(data) {
    this.logEvent('view_item', data);
  }

  logSelectItem(data) {
    this.logEvent('select_item', data);
  }

  logBeginCheckout(data) {
    this.logEvent('begin_checkout', data);
  }

  logPurchase(data) {
    this.logEvent('purchase', data);
  }

  logViewItemList(data) {
    this.logEvent('view_item_list', data);
  }

  logRefund(data) {
    this.logEvent('refund', data);
  }

  logAddPaymentInfo(data) {
    this.logEvent('add_payment_info', data);
  }

  logContent(data) {
    this.logEvent('select_content', data);
  }

  logAddToCart(data) {
    this.logEvent('add_to_cart', data);
  }

  logFilterSelect(data) {
    this.logEvent('filter_select', data)
  }

  logAutomaticPageView(data, params) {
    let title = data.title;
    if (data.snapshotParams) {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          title += ` ${this.formatString(params[key])}`;
        }
      }
    }
    if (title) {
      this.logPageView(title);
    }
  }

  logPageView(pageTitle) {
    this.logEvent('page_view', {
      page_title: `${pageTitle} Page`
    });
  }

  formatValueForPurchase(value: number | string): number {
    return parseFloat(Number(value).toFixed(2));
  }

  logViewSearchResultsFlights(toolKey: string, formData: any) {
    const legs = formData.legs.reduce(
      (data: { origins: string[]; destinations: string[]; dates: string[]; }, leg) => {
        if ((!!leg.departure_location_name || !!leg.departure_location) && (!!leg.arrival_location_name || !!leg.arrival_location)) {
          data.origins.push(isString(leg.departure_location_name) ? leg.departure_location_name : leg.departure_location);
          data.destinations.push(isString(leg.arrival_location_name) ? leg.arrival_location_name : leg.arrival_location);
          data.dates.push(leg.departure_date);
        }
        return data;
      }, { origins: [], destinations: [], dates: [] });

    const passengers: number = formData.passengers.length;
    const typeOfTrip: string = formData.type_of_trip;

    const event = {
      line_of_business: 'Flights',
      tool: toolKey,
      origin: legs.origins,
      destination: legs.destinations,
      date: legs.dates[0],
      trip_type: typeOfTrip,
      passenger: passengers
    };

    this.logViewSearchResults(event);
  }

  private formatString(input) {
    return input.split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  logViewSearchResults(data) {
    this.logEvent('view_search_results', data);
  }

  logContentInteraction(contentInteractionData: ContentInteractionData) {
    this.logEvent('content_interaction', contentInteractionData);
  }
}
