import { HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import { pick } from 'lodash';
import { GeneralHelper } from '../helpers/general.helper';
import { datesConfig } from '../../../configs/dates';

export class HotelSearch {
  applyCredits : boolean = true;
  neighborhoods: string[] = [];
  airports: string[] = [];
  starRatings = '1';
  reviewRatings = '1';
  amenities: number[] = [];
  currency = 'USD';
  checkin: string = moment().format('ll');
  checkout: string = moment().add(1, 'day').format('ll');
  destination = '';
  'guests[]' = '2';
  keyword = '';
  rooms = '1';
  locale: string;
  longitude: number = 0;
  latitude: number = 0;
  memberId: number;
  organizationId: number;
  perPage = GeneralHelper.isInternetExplorer() ? 25 : 500;
  page = 1;
  id: string;
  radius: number = null;
  rid: string;
  userId: number;
  accommodationTypes: string[] = [];
  roomsGuests = [{ adults: 2, children: 0, ages: [] }];
  priceRange = { lower: null, upper: null };
  specials: string[] = [];
  businessTrip = false;
  btToken = '';
  adultsOnly = false;
  oldDesign: boolean = false;
  allInclusive = false;
  locationType: string = null;
  locationResolution: number = null;
  locationCountryCode: string = null;
  // Pagination
  currentPage: number = 1;

  _sort = 'recommended';
  _sortDisplay = 'recommended';
  _sortCriteria = 'Overall';
  _sortOrder = 'desc';
  _sortValue = '';
  sortByRecommended = {
    key: 'recommended',
    criteria: 'Overall',
    order: 'desc',
  };
  private readonly sortMapping = [
    {
      key: 'priceLowHigh',
      criteria: 'Price',
      order: 'asc',
    },
    {
      key: 'priceHighLow',
      criteria: 'Price',
      order: 'desc',
    },
    {
      key: 'rating',
      criteria: 'Stars',
      order: 'desc',
    },
    {
      key: 'reviewScore',
      criteria: 'REVIEW_RATINGS',
      order: 'desc',
    },
    {
      key: 'savings',
      criteria: 'Savings',
      order: 'desc',
    },
    {
      key: 'propertyName',
      criteria: 'Alpha',
      order: 'asc',
    },
    {
      key: 'distance',
      criteria: 'Distance',
      order: 'asc',
    },
    this.sortByRecommended
  ];

  get sort(): string {
    return this._sort;
  }

  get sortToDisplay(): string {
    return this._sortDisplay;
  }

  set sort(sortKey: string) {
    this._sort = sortKey;

    if (sortKey && sortKey.includes('distanceFromAirport')) {
      this._sortCriteria = 'airport';
      this._sortOrder = 'asc';
      this._sortValue = sortKey.split(':')[1];
    } else {
      let sortConfig = this.sortMapping.find(config => config.key === sortKey);
      if (!sortConfig) {
        sortConfig = this.sortByRecommended;
      }
      this._sort = sortConfig.key;
      this._sortCriteria = sortConfig.criteria;
      this._sortOrder = sortConfig.order;
    }
  }

  toHttpParams(): HttpParams {
    let params: HttpParams = new HttpParams()
      .set('checkin', this.checkin)
      .set('checkout', this.checkout)
      .set('destination', this.destination)
      .set('keyword', this.keyword)
      .set('rooms', this.rooms)
      .set('longitude', this.longitude.toString())
      .set('latitude', this.latitude.toString())
      .set('sort_criteria', this._sortCriteria)
      .set('sort_value', this._sortValue)
      .set('sort_order', this._sortOrder)
      .set('per_page', this.perPage.toString())
      .set('page', this.page.toString())
      .set('currency', this.currency)
      .set('price_low', this.priceRange.lower ? this.priceRange.lower : '')
      .set('price_high', this.priceRange.upper ? this.priceRange.upper : '')
      .set('trip_reason', this.businessTrip ? 'business' : '')
      .set('locale', this.locale)
      .set('member_id', this.memberId ? this.memberId.toString() : '')
      .set('user_id', this.userId ? this.userId.toString() : '')
      .set('organization_id', this.organizationId ? this.organizationId.toString() : '')
      .set('oldDesign', this.oldDesign ? 'true' : 'false')
      .set('applyCredits', this.applyCredits ? 'true' : 'false');

    if(this.locationType) {
      params = params.append('locationType', this.locationType);
    }
    if(this.locationResolution) {
      params = params.append('locationResolution', this.locationResolution.toString());
    }
    if(this.locationCountryCode) {
      params = params.append('locationCountryCode', this.locationCountryCode);
    }

    for (const neighborhood of this.neighborhoods) {
      params = params.append('neighborhoods[]', neighborhood.toString());
    }
    for (const airport of this.airports) {
      params = params.append('neighborhoods[]', airport.toString());
    }
    if (this.starRatings) {
      for (let optionValue = parseInt(this.starRatings, 10); optionValue <= 5; optionValue++) {
        params = params.append('star_ratings[]', `${optionValue}`);
      }
    }

    if (this.reviewRatings && this.reviewRatings !== '1') {
      for (let optionValue = parseInt(this.reviewRatings, 10); optionValue <= 10; optionValue++) {
        params = params.append('review_ratings[]', `${optionValue}`);
      }
    }
    for (const amenity of this.amenities) {
      params = params.append('amenities[]', amenity.toString());
    }
    for (const guests of this['guests[]'].split('|')) {
      params = params.append('guests[]', guests);
    }
    for (const accommodation of this.accommodationTypes) {
      params = params.append('accommodation_types[]', accommodation.toString());
    }
    for (const special of this.specials) {
      params = params.append('specials[]', special);
    }
    if (this.adultsOnly) {
      params = params.append('adults_only', this.adultsOnly.toString());
    }
    if (this.oldDesign) {
      params = params.append('oldDesign', this.oldDesign ? 'true' : 'false');
    }
    return params;
  }

  getNumberOfGuests() {
    let totalGuests = 0;
    for (let n = 0; n < this.roomsGuests.length; n++) {
      const adults = Number(this.roomsGuests[n].adults);
      const children = Number(this.roomsGuests[n].children);
      totalGuests += (adults + children);
    }
    return totalGuests;
  }

  getGuestsParam() {
    let rooms = '';
    const guests = this.roomsGuests;
    this.roomsGuests.forEach((room, index) => {
      let value = room.adults.toString();
      if (room.ages.length > 0) {
        room.ages.forEach((age) => {
          value += `,${age.toString()}`;
        });
      }
      rooms += value;
      if (guests[index + 1]) {
        rooms += '|';
      }
    });
    return rooms;
  }

  parseGuests() {
    this.roomsGuests = [];
    this['guests[]'].split('|').forEach((room) => {
      const roomGuests = room.split(',');
      this.roomsGuests.push({
        adults: parseInt(roomGuests[0], 10),
        children: roomGuests.slice(1).length,
        ages: roomGuests.slice(1).map(Number)
      });
    });
    this.rooms = this.roomsGuests.length.toString();
  }

  updateWithFilters(filters) {
    Object.assign(this, pick(filters, [
      'destination', 'checkin', 'checkout', 'amenities', 'guests[]', 'neighborhoods', 'airports', 'priceRangeLower', 'priceRangeUpper',
      'sortBy', 'sortByToDisplay', 'starRatings', 'reviewRatings', 'keyword', 'specials', 'propertyTypes', 'businessTrip',
      'latitude', 'longitude', 'radius', 'adultsOnly', 'page', 'oldDesign', 'allInclusive', 'applyCredits', 'currentPage'
    ]));
    this.applyCredits = filters.applyCredits ? filters.applyCredits : this.applyCredits;
    this.priceRange.lower = filters.priceRangeLower ? filters.priceRangeLower : null;
    this.priceRange.upper = filters.priceRangeUpper ? filters.priceRangeUpper : null;

    if (filters.currentPage) {
      this.currentPage = parseFloat(filters.currentPage);
    }
    if (filters.sortBy) {
      this.sort = filters.sortBy;
    }
    if (filters.sortByToDisplay) {
      this._sortDisplay = filters.sortByToDisplay;
    }
    if (filters.destination || filters.latitude) {
      this.latitude = filters.latitude ? parseFloat(filters.latitude) : 0;
    }
    if (filters.destination || filters.longitude) {
      this.longitude = filters.longitude ? parseFloat(filters.longitude) : 0;
    }

    if (filters.propertyTypes) {
      this.accommodationTypes = Array.isArray(filters.propertyTypes) ? filters.propertyTypes : [filters.propertyTypes];
    } else if (filters.accommodationType) {
      if (filters.accommodationType === 'all-inclusive') {
        this.accommodationTypes = ['1'];
      }
    }
    if (filters.oldDesign) {
      this.oldDesign = filters.oldDesign === 'true';
    }
    if (filters.destination) {
      this.destination = filters.destination.replace(/-/g, ' ');
    }
    if (filters.radius) {
      this.radius = parseFloat(filters.radius);
    }
    if (filters.amenities) {
      this.amenities = Array.isArray(filters.amenities) ? filters.amenities : [filters.amenities];
    }
    if (filters.neighborhoods) {
      this.neighborhoods = Array.isArray(filters.neighborhoods) ? filters.neighborhoods : [filters.neighborhoods];
    }
    if (filters.airports) {
      this.airports = Array.isArray(filters.airports) ? filters.airports : [filters.airports];
    }
    if (filters.specials) {
      this.specials = Array.isArray(filters.specials) ? filters.specials : [filters.specials];
    }
    if (filters.rooms) {
      this.rooms = filters.rooms;
    }
    if (filters.page) {
      this.page = parseFloat(filters.page);
    }
    if (filters.businessTrip) {
      this.businessTrip = typeof (filters.businessTrip) === 'boolean'
        ? filters.businessTrip
        : filters.businessTrip === 'true';
    }
    if (filters.adultsOnly) {
      this.adultsOnly = typeof (filters.adultsOnly) === 'boolean'
        ? filters.adultsOnly
        : filters.adultsOnly === 'true';
    }

    if (filters.locationType && filters.locationType !== 'null' && filters.locationType !== '') {
      this.locationType = filters.locationType;
    }

    if (filters.locationResolution && filters.locationResolution !== 'null') {
      this.locationResolution = filters.locationResolution;
    }

    if (filters.locationCountryCode && filters.locationCountryCode !== 'null' && filters.locationCountryCode !== '') {
      this.locationCountryCode = filters.locationCountryCode;
    }

    this.parseGuests();
    this.setDatesFromFilters({ checkin: filters.checkin, checkout: filters.checkout });
  }

  resetSearchByFilter(filters) {
    Object.assign(this, pick(filters, [
      'destination', 'guests[]', 'amenities', 'neighborhoods', 'airports', 'starRatings', 'reviewRatings', 'keyword', 'specials',
      'businessTrip', 'radius', 'page', 'oldDesign', 'sortByToDisplay', 'priceRangeLower', 'priceRangeUpper', 'applyCredits', 'currentPage'
    ]));

    this.currentPage = 1;
    this.radius = null;
    this.priceRange.lower = filters.priceRangeLower ? parseFloat(filters.priceRangeLower) : 0;
    this.priceRange.upper = filters.priceRangeUpper ? parseFloat(filters.priceRangeUpper) : null;
    this.sort = filters.sortBy ? filters.sortBy : 'recommended';
    this._sortDisplay = filters.sortByToDisplay ? filters.sortByToDisplay : 'recommended';
    this.latitude = filters.latitude ? parseFloat(filters.latitude) : 0;
    this.longitude = filters.longitude ? parseFloat(filters.longitude) : 0;
    this.adultsOnly = filters.adultsOnly ? filters.adultsOnly : false;
    this.oldDesign = filters.oldDesign ? (filters.oldDesign === 'true') : false;
    this.destination = filters.destination ? filters.destination.replace(/-/g, ' ') : '';
    this.amenities = filters.amenities ? filters.amenities : [];
    this.starRatings = filters.starRatings ? filters.starRatings : '1';
    this.reviewRatings = filters.reviewRatings ? filters.reviewRatings : '1';
    this.keyword = filters.keyword ? filters.keyword : '';
    this.specials = filters.specials ? filters.specials : [];
    this.businessTrip = filters.businessTrip ? filters.businessTrip : false;
    this.locationType =
      filters.locationType && filters.locationType !== 'null' && filters.locationType !== '' ?
        filters.locationType : null;
    this.locationCountryCode =
      filters.locationCountryCode && filters.locationCountryCode !== 'null' && filters.locationCountryCode !== '' ?
        filters.locationCountryCode : null;
    this.applyCredits = filters.applyCredits;
    this.locationResolution =
      filters.locationResolution && filters.locationResolution !== 'null' ?
        filters.locationResolution : null;
    this.neighborhoods = filters.neighborhoods
      ? Array.isArray(filters.neighborhoods) ? filters.neighborhoods : [filters.neighborhoods]
      : [];
    this.airports = filters.airports
      ? Array.isArray(filters.airports) ? filters.airports : [filters.airports]
      : [];
    this.amenities = filters.amenities
      ? Array.isArray(filters.amenities) ? filters.amenities : [filters.amenities]
      : [];
    this.specials = filters.specials
      ? Array.isArray(filters.specials) ? filters.specials : [filters.specials]
      : [];

    if (filters.propertyTypes || filters.accommodationType) {
      if (filters.propertyTypes) {
        this.accommodationTypes = Array.isArray(filters.propertyTypes) ? filters.propertyTypes : [filters.propertyTypes];
      } else if (filters.accommodationType) {
        if (filters.accommodationType === 'all-inclusive') {
          this.accommodationTypes = ['1'];
        }
      }
    } else {
      this.accommodationTypes = [];
    }
    this.parseGuests();
    this.setDatesFromFilters({ checkin: filters.checkin, checkout: filters.checkout });
  }

  private setDatesFromFilters(params: any) {
    this.setDateFromFilter('checkin', params.checkin);
    this.setDateFromFilter('checkout', params.checkout);
  }

  private setDateFromFilter(dateKey: 'checkin' | 'checkout', dateStr: string) {
    const date = dateStr && dateStr !== '' && typeof (dateStr) !== 'undefined' && !dateStr.startsWith('Invalid') ?
      moment(dateStr, datesConfig.momentFormats) : moment(this[dateKey], datesConfig.momentFormats);

    this[dateKey] = date.format('YYYY-MM-DD');
  }

  updateWithUserData(userData) {
    this.currency = userData && userData.currency ? userData.currency : this.currency;
    this.organizationId = userData ? userData.organizationId : this.organizationId;
    this.userId = userData ? userData.idMembers : this.userId; // Hotels DB ID
    this.memberId = userData.member ? userData.member.member_id : this.memberId; // Webapp DB ID
  }

  toFilters() {
    const filters = <any> pick(this, [
      'destination', 'checkin', 'checkout', 'amenities', 'guests[]', 'neighborhoods', 'airports', 'priceRangeLower', 'priceRangeUpper',
      'sortBy', 'sortByToDisplay', 'starRatings', 'reviewRatings', 'keyword', 'specials', 'propertyTypes', 'businessTrip',
      'latitude', 'longitude', 'radius', 'adultsOnly', 'page', 'oldDesign', 'locationType', 'locationResolution', 'locationCountryCode', 'applyCredits', 'currentPage'
    ]);

    filters['guests[]'] = this['guests[]'];
    filters['sortBy'] = this.sort;
    filters['currentPage'] = this.currentPage;
    filters['rooms'] = this.rooms;
    filters['priceRangeUpper'] = this.priceRange.upper && parseFloat(this.priceRange.upper);
    filters['priceRangeLower'] = this.priceRange.lower ? parseFloat(this.priceRange.lower) : 0;
    filters['latitude'] = this.latitude.toString();
    filters['longitude'] = this.longitude.toString();
    filters['propertyTypes'] = this.accommodationTypes;
    filters['oldDesign'] = this.oldDesign;
    filters['locationType'] = this.locationType;
    filters['locationCountryCode'] = this.locationCountryCode;
    filters['locationResolution'] = this.locationResolution;
    return filters;
  }

  get checkinIso() {
    return this.checkin ? moment(this.checkin, datesConfig.momentFormats).format('YYYY-MM-DD') : '';
  }

  get checkoutIso() {
    return this.checkout ? moment(this.checkout, datesConfig.momentFormats).format('YYYY-MM-DD') : '';
  }

  get checkinShort() {
    return this.checkin ? moment(this.checkin, datesConfig.momentFormats).format('MMM DD') : '';
  }

  get checkoutShort() {
    return this.checkout ? moment(this.checkout, datesConfig.momentFormats).format('MMM DD') : '';
  }

  get checkinShortWithDay() {
    return this.checkin ? moment(this.checkin, datesConfig.momentFormats).format('ddd, MMM DD') : '';
  }

  get checkoutShortWithDay() {
    return this.checkout ? moment(this.checkout, datesConfig.momentFormats).format('ddd, MMM DD') : '';
  }
}
