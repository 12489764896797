<ngb-alert *ngIf="error" type="danger" (closed)="closeAlert()" data-cy="two-step-error">{{ error }}</ngb-alert>
<div class="text-center code-sent-subtitle mb-3">{{ 'forgot_password.code_sent_subtitle' | translate: { expire_time: this.expireTime} }}</div>
<form [formGroup]="codeForm" (ngSubmit)="submit()">
  <div class="row text-center m-2">
    <div class="input-item col col-2">
      <input #code type="tel" size="1" maxlength="1" class="form-control code" (input)="focusNext(1)" formControlName="0" data-cy="code-input-0"  (keydown)="preventSpecialCharacters($event)" />
    </div>
    <div class="input-item col col-2">
      <input #code type="tel" size="1" maxlength="1" class="form-control code" (input)="focusNext(2)" (keyup.backspace)="focusPrev(0)" formControlName="1" data-cy="code-input-1"  (keydown)="preventSpecialCharacters($event)" />
    </div>
    <div class="input-item col col-2">
      <input #code type="tel" size="1" maxlength="1" class="form-control code" (input)="focusNext(3)" (keyup.backspace)="focusPrev(1)" formControlName="2" data-cy="code-input-2"  (keydown)="preventSpecialCharacters($event)" />
    </div>
    <div class="input-item col col-2">
      <input #code type="tel" size="1" maxlength="1" class="form-control code" (input)="focusNext(4)" (keyup.backspace)="focusPrev(2)" formControlName="3" data-cy="code-input-3"  (keydown)="preventSpecialCharacters($event)" />
    </div>
    <div class="input-item col col-2">
      <input #code type="tel" size="1" maxlength="1" class="form-control code" (input)="focusNext(5)" (keyup.backspace)="focusPrev(3)" formControlName="4" data-cy="code-input-4"  (keydown)="preventSpecialCharacters($event)" />
    </div>
    <div class="input-item col col-2">
      <input #code type="tel" size="1" maxlength="1" class="form-control code" (keyup.backspace)="focusPrev(4)" formControlName="5" data-cy="code-input-5"   (keydown)="preventSpecialCharacters($event)"/>
    </div>
  </div>
  <div class="mx-4 my-3">
    <button type="submit" class="btn btn-primary w-100" data-cy="code-submit">
      {{'password_reset.submit_button' | translate}}
    </button>
  </div>
</form>
<div *ngIf="showResendButton" class="text-center">
  <div *ngIf="resendSuccess && counter > 0">
    <div class="code-sent-subtitle mb-3">{{ 'forgot_password.code_resent' | translate }}</div>
  </div>
  <div class="code-sent-subtitle mb-3">{{ 'forgot_password.code_resend_disabled' | translate }}</div>
  <button [disabled]="resendSuccess && counter > 0" class="btn btn-outline-light text-dark border" (click)="resend()" data-cy="resend">{{ 'forgot_password.resend_code' | translate }}</button>
</div>
<div *ngIf="showVerifyInsteadButton" class="row">
  <div class="col-12 text-center">
    <button class="btn btn-link" [disabled]="resendSuccess && counter > 0" (click)="switchMethod()" data-cy="switch-method">
      {{ (enrollBySms ?
      'profile.login_and_security.two_step_security.verify_by_email_button' :
      'profile.login_and_security.two_step_security.verify_by_sms_button'
      ) | translate }}
    </button>
  </div>
</div>
<div *ngIf="isMaxAttempts" class="row">
  <div class="col-12 text-center" [innerHTML]="'profile.login_and_security.two_step_security.verify_code.content_6' | translate"></div>
</div>
