import { createAction, props } from '@ngrx/store';
import { GamificationCampaignModel } from 'app/shared/models/gamification.model';

export const loadActiveCampaigns = createAction(
  '[Gamification] Load Active Campaigns'
);

export const loadActiveCampaignsSuccess = createAction(
  '[Gamification] Load Active Campaigns Success',
  props<{ campaigns: GamificationCampaignModel[] }>()
);

export const loadActiveCampaignsFailure = createAction(
  '[Gamification] Load Active Campaigns Failure',
  props<{ error: any }>()
);

export const participateCampaign = createAction(
  '[Gamification] Participate Campaign',
  props<{ idCampaign: number, participate: boolean }>()
);

export const participateCampaignSuccess = createAction(
  '[Gamification] Participate Campaign Success',
  props<{ campaignId: number, participate: boolean }>()
);

export const participateCampaignFailure = createAction(
  '[Gamification] Participate Campaign Failure',
  props<{ error: any }>()
);

export const closeParticipationModal = createAction(
  '[Gamification] Close Participation Modal'
);

export const checkFreeEntryAvailable = createAction(
  '[Gamification] Check Free Entry Available',
  props<{ campaignId: number }>()
);

export const checkFreeEntryAvailableSuccess = createAction(
  '[Gamification] Check Free Entry Available Success',
  props<{ campaignId: number }>()
);

export const checkFreeEntryAvailableFailure = createAction(
  '[Gamification] Check Free Entry Available Failure',
  props<{ error: any }>()
);

export const getStatistics = createAction(
  '[Gamification] Get Statistics'
);

export const getStatisticsSuccess = createAction(
  '[Gamification] Get Statistics Success',
  props<{ statistics: any }>()
);

export const getStatisticsFailure = createAction(
  '[Gamification] Get Statistics Failure',
  props<{ error: any }>()
);

export const showGamificationAnimation = createAction(
  '[Gamification] Show Animation'
);

export const hideGamificationAnimation = createAction(
  '[Gamification] Hide Animation'
);


