<div class="light-layout">
  <header>
    <div class="container-fluid">
      <nav class="navbar navbar-expand-lg">
        <a class="navbar-brand" [routerLink]="homeUrl">
          <img [src]="logo" class="d-inline-block align-top img-fluid id90-logo" alt="ID90 Travel" />
        </a>
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <a class="support-number text-decoration-none" href="tel:+1-{{ 'nav.contact.support_number_nt' | translate }}">
              <fa-icon [icon]="icons.faPhone" class="me-2"></fa-icon> {{ 'nav.contact.support_number_nt' | translate }}
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </header>
  <main>
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </main>
</div>
