<div class="progress-container w-100 mx-0" [ngClass]="{'gradient': showGradient, 'small-size': isSmall, 'normal-size': isNormal, 'big-size': isBig}">
    <div class="progress" [ngStyle]="{width: percent}"></div>
    <div class="indicator" *ngIf="percent"></div>
  </div>

  <div *ngIf="showAwayFromNextLevel" class="away-from-next-level">
    <span [ngClass]="{'secondary-content': isBig, 'tertiary-content': isSmall || isNormal}">
      {{ 'member_rewards.away_from_next_level' | translate: {nextTierGapLtv: nextTierGapLtv | currencyConvert:currency, nextTierReward: nextTierReward | currencyConvert:currency } }}
    </span>
  </div>
  
  <div *ngIf="showLevels" class="levels">
    <span class="tertiary-content" [ngStyle]="{color: colorLevel}">
      {{ 'member_rewards.level' | translate }} {{ currentLevel }}
    </span>
    <span class="tertiary-content"  [ngStyle]="{color: colorLevel}">
      {{ 'member_rewards.level' | translate }} {{ nextLevel }}
    </span>
  </div> 