import moment from "moment";
import { DateConfig } from "../models/rules";
import  rules  from 'configs/rules.json';
import { Injectable } from "@angular/core";


@Injectable({
    providedIn: 'root'
})
export class DatePickerHelper {
    constructor() { }

    getDateConfig(airlineCode: string, lineOfBusiness: string, pathOfBusiness: string, minDate: any, maxDate: any, defaultConfig: DateConfig) : DateConfig {
        const airlineRules = rules[lineOfBusiness][pathOfBusiness][airlineCode];
        if (!airlineRules) { return defaultConfig }
        return {
            minDate: this.formatDateConfig(minDate, airlineRules.dateConfig.minDate),
            maxDate: this.formatDateConfig(maxDate, airlineRules.dateConfig.maxDate)
        };
    }

    private formatDateConfig(startDate: string, addiction: number) {
        return moment(startDate).add(addiction, 'days');
    }
}
