import { Component, Input } from '@angular/core';
import { MemberLoyaltySummary } from 'app/profile/models/member-loyalty.interface';

@Component({
  selector: 'member-savings-card',
  templateUrl: './member-savings-card.html'
})

export class MemberSavingsCardComponent  {
  @Input() memberSavings;
  @Input() memberLoyalty: MemberLoyaltySummary;

  constructor() {}
}
