<ng-container *ngIf="showTextFormat; then textFormat; else iconFormat"></ng-container>

<ng-template #textFormat>
  <span *ngIf="value" class="fw-bold">
    {{ value }} - {{ 'mobile.hotel_results.stars_hotel' | translate }}
  </span>
</ng-template>

<ng-template #iconFormat>
  <span class="star-rating" [ngClass]="{'star-normal': starSize === 'normal', 'star-small': starSize === 'small' }">
    <span class="back-stars">
      <fa-icon *ngFor="let s of numberOfStars" [icon]="icons.faStar" aria-hidden="true"></fa-icon>
      <span class="front-stars" [ngStyle]="{'width': percentWidth + '%'}">
        <fa-icon *ngFor="let s of numberOfStars" [icon]="icons.faStar" aria-hidden="true"></fa-icon>
      </span>
    </span>
  </span>
</ng-template>
