import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { FlightWatchlistActions, FlightWatchlistApiActions } from '../actions';
import { mergeMap, switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { WatchlistService } from 'app/shared/services/watchlist/watchlist.service';

@Injectable()
export class FlightWatchlistEffects {

  addFlights$ = createEffect(()=>
    this.actions$.pipe(
      ofType<FlightWatchlistActions.Add>(FlightWatchlistActions.FlightWatchlistActionTypes.Add),
      map((action) => {
        return action.payload;
      }),
      mergeMap((data: any) =>
        this.watchlistService.createFlightWatchlist(data.watchlist, data.toolKey).pipe(
          map(res => new FlightWatchlistActions.Load()),
          catchError(error => of(new FlightWatchlistApiActions.AddFlightWatchlistFailure({ error })))
        )
      )
    )
  );

  loadFlights$ = createEffect(()=>
    this.actions$.pipe(
      ofType<FlightWatchlistActions.Load>(FlightWatchlistActions.FlightWatchlistActionTypes.Load),
      switchMap(() =>
        this.watchlistService.getFlightWatchers().pipe(
          map(watchlists => new FlightWatchlistApiActions.LoadFlightWatchlistsSuccess({ watchlist: watchlists })),
          catchError(error => of(new FlightWatchlistApiActions.LoadFlightWatchlistsFailure({ error })))
        )
      )
    )
  );

  removeFlight$ = createEffect(()=>
    this.actions$.pipe(
      ofType<FlightWatchlistActions.Remove>(FlightWatchlistActions.FlightWatchlistActionTypes.Remove),
      map(action => action.payload.watchlist),
      mergeMap((watchlist: any) =>
        this.watchlistService.deleteFlightWatcher(watchlist).pipe(
          map(res => new FlightWatchlistApiActions.RemoveFlightWatchlistSuccess({ watchlist })),
          catchError(error => of(new FlightWatchlistApiActions.RemoveFlightWatchlistFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private watchlistService: WatchlistService
  ) {}
}
