import { Component, Input } from '@angular/core';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'hotel-pricing-total',
  templateUrl: './hotel-pricing-total.component.html',
  styleUrls: ['./hotel-pricing-total.component.scss']
})
export class HotelPricingTotalComponent {
  @Input() totalRate: string;
  
  /**
   * Tooltip defines if we want to show the infoIcon for the tooltip or text to declare taxes and fees included
   * @type {boolean}
   * @memberof HotelPricingTotalComponent
   */
  @Input() tooltip: boolean = true;
  
  /**
   * If the rate is primary we display it with a primary color
   * @type {boolean}
   * @memberof HotelPricingTotalComponent
   */
  @Input() isPrimaryRate: boolean = false;

  /**
   * We display the rate with a bold font weight
   * @type {boolean}
   * @memberof HotelPricingTotalComponent
   */
  @Input() boldRate: boolean = false;
  public icons = { faCircleInfo };

  preventTooltipRedirection(event) {
    event.preventDefault();
    event.stopPropagation();
    return;
  }
}
