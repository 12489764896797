import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'verification-code-form',
  templateUrl: './verification-code-form.html',
  styleUrls: ['./verification-code-form.scss']
})

export class VerificationCodeFormComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChildren('code') inputs: QueryList<ElementRef>;
  loading = false;
  codeForm: UntypedFormGroup;
  fieldGroups;
  params;
  counter = 30;
  cDisplay = '30';

  @Input() medium = null;
  @Input() expireTime = 30;
  @Input() resendSuccess = false;
  @Input() showVerifyInsteadButton = false;
  @Input() showResendButton = true;
  @Input() enrollBySms = false;
  @Input() error = '';
  @Input() isMaxAttempts = false;
  @Output() codeSubmitted = new EventEmitter();
  @Output() sendResetLink = new EventEmitter();
  @Output() reSendCode = new EventEmitter();
  @Output() switchMethodAction = new EventEmitter();
  @Output() endTimerEvent = new EventEmitter();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private ngZone: NgZone
  ) { }

  ngOnInit() {
    this.createForm();
    if (this.error) {
      this.codeForm.reset();
    }
    if (this.resendSuccess) {
      this.setTimer();
    }
    this.listenPaste();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.medium?.previousValue && changes?.medium?.currentValue !== changes?.medium?.previousValue) {
      this.setTimer();
      this.resendSuccess = true;
    }
  }

  createForm() {
    this.codeForm = this.formBuilder.group({
      0: ['', [Validators.required]],
      1: ['', [Validators.required]],
      2: ['', [Validators.required]],
      3: ['', [Validators.required]],
      4: ['', [Validators.required]],
      5: ['', [Validators.required]]
    });
  }

  focusNext(nextIndex: number) {
    const inputs = this.inputs.toArray();
    if (!this.codeForm.get((nextIndex - 1).toString()).invalid) {
      inputs[nextIndex].nativeElement.focus();
    }
  }

  submit() {
    this.error = '';
    if (!this.isValid()) {
      Object.keys(this.codeForm.controls).forEach((field) => {
        const control = this.codeForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
      this.error = this.translateService.instant('forgot_password.code_error');
      return false;
    }

    this.loading = true;
    this.codeSubmitted.emit(this.value());
  }

  resend() {
    this.error = '';
    this.reSendCode.emit();
    this.codeForm.reset();
    this.setTimer();
    this.resendSuccess = true;
  }

  public switchMethod() {
    this.error = '';
    this.codeForm.reset();
    this.switchMethodAction.emit();
    this.setTimer();
  }

  setTimer() {
    this.counter = 30;
    this.cDisplay = '30';
    const intervalId = setInterval(() => {
      this.counter = this.counter - 1;
      this.cDisplay = this.counter < 10 ? `0${this.counter}` : `${this.counter}`;
      if (this.counter === 0) {
        clearInterval(intervalId);
        this.resendSuccess = false;
        this.endTimerEvent.emit();
      }
    },                             1000);
  }

  isValid() {
    return this.codeForm.valid;
  }

  showError(message) {
    this.error = message;
  }

  closeAlert() {
    this.error = '';
  }

  value() {
    let code = '';
    Object.keys(this.codeForm.value).forEach((key) => {
      code += this.codeForm.value[key];
    });
    return code;
  }

  focusPrev(prevIndex: number) {
    const inputs = this.inputs.toArray();
    inputs[prevIndex].nativeElement.focus();
  }

  onPasteCode(event: ClipboardEvent) {
    this.ngZone.run(() => {
      const inputs = this.inputs.toArray();
      let clipboardData = event.clipboardData;
      let pastedText = clipboardData.getData('text');
      const regexOnlyNumbers = /^\d{6}$/;
      if (!regexOnlyNumbers.test(pastedText)) {
        pastedText = this.tryToConvertToNumber(pastedText);
      }
      const pastedSplitValues = pastedText.split('');
      if (pastedSplitValues.length != 6) { return; }
      for (let index = 0; index < inputs.length; index++) {
        this.codeForm.get(`${index}`).setValue(pastedSplitValues[index]);
      }
      inputs[5].nativeElement.focus();
      this.checkValidAndTouchControls(this.codeForm);
    });
  }

  resetForm() {
    this.codeForm.reset();
  }
  preventSpecialCharacters(event) {
    const allowedCharacters = ['0','1','2','3','4','5','6','7','8','9', 'backspace', 'meta', 'v', 'control'];
    const key = event.key || event.keyCode;
    const isValidCharacter =  allowedCharacters.find((char) => char.toLowerCase() === key?.toLowerCase());
    if (!isValidCharacter && key ) {
      event.preventDefault();
    }
  }

  private checkValidAndTouchControls(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
      control.updateValueAndValidity();
    });
  }

  private listenPaste() {
    return document.addEventListener('paste', this.onPasteCode.bind(this));
  }

  private removePaste() {
    return document.removeEventListener('paste', this.onPasteCode.bind(this));
  }

  private tryToConvertToNumber(pastedText: string): string {
    let convertedString = "";
    for (let index = 0; index < pastedText.length; index++) {
      const element = pastedText[index];
      if (this.isNumber(element)) {
        convertedString += element;
      }
    }
    return convertedString.slice(0,6);
  }

  private isNumber(number) {
    return Number(number) ? true : false;
  }

  ngOnDestroy(): void {
    this.removePaste();
  }
}
