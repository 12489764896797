import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import * as fromSettings from './settings.reducer';
import * as fromProfileUser from './profile-user.reducer';
import * as fromCredit from './credit.reducer';
import * as fromCreditHistory from './credit-history.reducer';
import * as fromMemberLoyalty from './member-loyalty.reducer';
import * as fromPaymentMethods from './payment-methods.reducer';
import * as fromAllInterests from './all-interests.reducer';
import * as fromMemberInterests from './member-interests.reducer';
import * as fromPromoCode from './promo-code.reducer';
import { ProfileSettingsState } from 'app/profile/models/profile-settings.interface';
import { ProfileUserState } from 'app/profile/models/profile-user.interface';
import { UserCredits } from 'app/profile/models/user-credits.interface';
import { UserCreditHistory } from 'app/profile/models/user-credit-history.interface';
import { MemberLoyalty } from 'app/profile/models/member-loyalty.interface';
import { AllInterestsState } from 'app/profile/models/all-interests.interface';
import { MemberInterestsState } from 'app/profile/models/member-interests.interface';
import * as fromProfile from 'app/profile/store/reducers';


export interface ProfileState {
  settings: ProfileSettingsState;
  user: ProfileUserState;
  credit: UserCredits;
  creditHistory: UserCreditHistory;
  memberLoyalty: MemberLoyalty;
  paymentMethods: fromProfile.State | any;
  allInterests: AllInterestsState;
  memberInterests: MemberInterestsState;
  promoCode : any;
}

export interface State extends fromRoot.State {
  profile: ProfileState;
}

export const reducers: ActionReducerMap<ProfileState> = {
  settings: fromSettings.reducer,
  user: fromProfileUser.reducer,
  credit: fromCredit.reducer,
  creditHistory: fromCreditHistory.reducer,
  memberLoyalty: fromMemberLoyalty.reducer,
  paymentMethods: fromPaymentMethods.reducer,
  allInterests: fromAllInterests.reducer,
  memberInterests: fromMemberInterests.reducer,
  promoCode: fromPromoCode.reducer,
};

export const selectProfileState = createFeatureSelector<State, ProfileState>('profile');
export const selectPaymentMethodsState = createSelector(selectProfileState, state => state.paymentMethods);
export const selectSettingsState = createSelector(selectProfileState, state => state.settings);
export const getProfileSettings = createSelector(selectSettingsState, fromSettings.getSettings);

export const getPaymentMethodsPending = createSelector(selectPaymentMethodsState, fromPaymentMethods.getPendingState);
export const getPaymentMethodsError = createSelector(selectPaymentMethodsState, fromPaymentMethods.getErrorState);
export const getPaymentMethodsSuccess = createSelector(selectPaymentMethodsState, fromPaymentMethods.getSuccessState);
export const getPaymentMethods = createSelector(selectPaymentMethodsState, fromPaymentMethods.getPaymentMethods);


export const selectCreditState = createSelector(selectProfileState, state => state.credit);
export const getUserCredits = createSelector(selectCreditState, fromCredit.getCredits);
export const selectCreditHistoryState = createSelector(selectProfileState, state => state.creditHistory);
export const getUserCreditHistory = createSelector(selectCreditHistoryState, fromCreditHistory.getCreditHistory);
export const getUserCreditHistoryPending = createSelector(selectCreditHistoryState, fromCreditHistory.getCreditHistoryPending);
export const getUserCreditHistoryLoaded = createSelector(selectCreditHistoryState, fromCreditHistory.getCreditHistoryLoaded);
export const getUserCreditHistoryError = createSelector(selectCreditHistoryState, fromCreditHistory.getCreditHistoryError);
export const selectMemberLoyaltyState = createSelector(selectProfileState, state => state.memberLoyalty);
export const getMemberLoyalty = createSelector(selectMemberLoyaltyState, fromMemberLoyalty.getMemberLoyalty);
export const getMemberLoyaltyPending = createSelector(selectMemberLoyaltyState, fromMemberLoyalty.getMemberLoyaltyPending);
export const getMemberLoyaltyLoaded = createSelector(selectMemberLoyaltyState, fromMemberLoyalty.getMemberLoyaltyLoaded);
export const getMemberLoyaltyError = createSelector(selectMemberLoyaltyState, fromMemberLoyalty.getMemberLoyaltyError);
export const selectEmployeeState = createSelector(selectProfileState, state => state.user);
export const getProfileConfig = createSelector(selectEmployeeState, fromProfileUser.getConfig);
export const getBusinessToggle = createSelector(selectEmployeeState, fromProfileUser.getBusinessToggle);
export const getProfilePending = createSelector(selectEmployeeState, fromProfileUser.getPending);
export const getUserProfile = createSelector(selectEmployeeState, fromProfileUser.getUserProfile);
export const getProfileStructure = createSelector(selectEmployeeState, fromProfileUser.getProfileStructure);

export const selectAllInterestsState = createSelector(selectProfileState, state => state.allInterests);
export const getAllInterests = createSelector(selectAllInterestsState, fromAllInterests.getSuccess);
export const selectMemberInterestsState = createSelector(selectProfileState, state => state.memberInterests);
export const getMemberInterestsPending = createSelector(selectMemberInterestsState, fromMemberInterests.getMemberInterestsPending);
export const getMemberInterestsSuccess = createSelector(selectMemberInterestsState, fromMemberInterests.getMemberInterestsSuccess);
export const getMemberStatusSuccess = createSelector(selectMemberInterestsState, fromMemberInterests.setMemberStatusSuccess);
export const selectPromoCode = createSelector(selectProfileState, state => state.promoCode);
export const promoCodeCheck = createSelector(selectPromoCode, fromPromoCode.checkPromoCode);
export const promoCodeCheckError = createSelector(selectPromoCode, fromPromoCode.checkPromoCodeError);
export const promoCodeCheckSucccess = createSelector(selectPromoCode, fromPromoCode.checkPromoCodeSuccess);
export const redeemPromoCode = createSelector(selectPromoCode, fromPromoCode.redeemPromoCode);
export const redeemPromoCodeError = createSelector(selectPromoCode, fromPromoCode.redeemPromoCodeError);
export const redeemPromoCodeSucccess = createSelector(selectPromoCode, fromPromoCode.redeemPromoCodeSuccess);
