<div class="cursor-pointer" (click)="navigate()">
  <div class="card activities-banner">
    <div class="card-body activities-banner-container">
      <div class="row">
        <div class="col-4 col-sm-3 align-content-center">
          <img alt="viator-logo" class="activities-logo"
               src="https://marketing.id90travel.com/assets/Viator_DMWV_RGB_Green.png">
        </div>
        <div class="col-sm-6 col-11 mt-2 activities-text">
          <p [innerHTML]="'banner.viator' | translate "></p>
        </div>
        <div class="offset-sm-2 align-content-center">
          <h3>
            <fa-icon [icon]="icons.faCircleChevronRight"></fa-icon>
          </h3>
        </div>
      </div>
    </div>
  </div>
</div>
