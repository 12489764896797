import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Store, select } from '@ngrx/store';
import { User } from 'app/auth/models/user';
import { Observable } from 'rxjs';
import * as fromRoot from '../../../reducers';
import * as fromAuth from '../../auth/store/reducers';
import smartlookClient from 'smartlook-client';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SmartlookHelper {

  private user$: Observable<User> = this.store.pipe(select(fromAuth.getUser));

  constructor(private store: Store<fromRoot.State>) { }

  public async startSmartlook() {
    try {
      if (environment.smartlookEnabled && !smartlookClient.initialized()) smartlookClient.init(environment.smartlookKey);
      smartlookClient.record({
        emails: true,
        forms: true,
        numbers: true,
      })
      smartlookClient.resume();
      this.identifyUser();
    } catch (e) { }
  }

  public async identifyUser() {
    return new Promise((resolve, reject) => {
      this.user$.pipe(take(1)).subscribe(async (user) => {
        if (user.account) {
          smartlookClient.identify(user.account.employeeNumber, {
            name: `${user.member.first_name} ${user.member.last_name}`,
            email: user.member.email,
            airline: user.account.airlineCode
          });
          resolve(true);
        }
      });
    });
  }

  public pauseRecording() {
    if (!environment.smartlookEnabled) { return; }
    smartlookClient.pause();
  }

  public resumeRecording() {
    if (!environment.smartlookEnabled) { return; }
    smartlookClient.resume();
  }

  public finishRecordingSession() {
    if (!environment.smartlookEnabled) { return; }
    smartlookClient.anonymize();
  }

  public customEvent(eventName: string, eventData?: any) {
    if (!environment.smartlookEnabled) { return; }

    if (eventData) {
      smartlookClient.track(eventName, eventData);
    } else {
      smartlookClient.track(eventName, {});
    }
  }

  public customRevenueEvent(eventType: string, amount: any) {
    if (!environment.smartlookEnabled) { return; }

    this.customEvent('Revenue Event', { type: eventType, revenue: amount });
  }

  public sessionId(): string {
    if (!environment.smartlookEnabled) { return ''; }

    return smartlookClient.sessionId;
  }

  public sessionUrl(): string {
    if (!environment.smartlookEnabled) { return ''; }

    return smartlookClient.playUrl;
  }

  public recordId(): string {
    if (!environment.smartlookEnabled) { return ''; }

    return smartlookClient.recordId;
  }
}
