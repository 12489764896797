import { Action } from '@ngrx/store';

export enum UTMParametersActionTypes {
  Load = '[UTM Parameters] Load',
  Clear = '[UTM Parameters] Clear'
}

export class Load implements Action {
  readonly type = UTMParametersActionTypes.Load;

  constructor(public payload: any) {}
}

export class Clear implements Action {
  readonly type = UTMParametersActionTypes.Clear;

  constructor() {}
}

export type UTMParametersActionsUnion = Load
| Clear;
