import { Injectable } from '@angular/core';
import amenitiesJson from 'configs/amenities.json';

@Injectable({
  providedIn: 'root'
})
export class AmenityService {

  constructor() {}

  getMappedAmenity(amenity: any): any {
    return (amenitiesJson || []).filter((a) => {
      if (amenity.name || amenity.description) {
        const matchingName = amenity.name ? amenity.name.toLowerCase() : amenity.description.toLowerCase();
        return a.names.find(name => name.toLowerCase() === matchingName);
      }

      return false;
    });
  }
}
