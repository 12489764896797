<div class="inline-datepicker">
  <button id="inline-datepicker-desktop" class="btn btn-link datepicker-trigger" (click)="openDatePicker()">
    <fa-icon [icon]="icons.faCalendar"></fa-icon>
    &nbsp;
    <span>{{ currentDate | amDateFormat : 'MMM DD, YYYY' }}</span>
    &nbsp;
    <fa-icon *ngIf="!datepickerOpen" [icon]="icons.faCaretDown"></fa-icon>
    <fa-icon *ngIf="datepickerOpen" [icon]="icons.faCaretUp"></fa-icon>
  </button>
  <datepicker-range
    [startDate]="currentDate"
    [singleDate]="true"
    [singleInput]="true"
    [displayMonths]="1"
    [enableClearAction]="true"
    [showApplyButton]="true"
    [autoClose]="'outside'"
    [maxDate]="maxDateMoment"
    [minDate]="minDateMoment"
    [showModalForMobile]="true"
    [emitFormat]="DATE_FORMAT"
    (datesSelected)="onDateSelected($event)"
    (datepickerClosed)="onDatepickerClosed()">
  </datepicker-range>
</div>
