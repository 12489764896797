<div class="personal-details position-relative" data-cy="form-personal-data">
  <loading-indicator [fullScreen]="false" *ngIf="loading"></loading-indicator>
  <div *ngIf="condensed && isValid()" class="mb-3">
    <div class="d-flex justify-content-between align-items-center">
      <div class='primary-content primary-color'>{{ userFirstName }} {{ userLastName }}</div>
      <div class="primary-color clickable" (click)="condensed=false"><fa-icon [icon]="icons.faEdit"></fa-icon></div>
    </div>
    <div class='tertiary-content'>{{ userDetails.personalEmail}}</div>
  </div>

  <form [formGroup]="personalDetailsForm" *ngIf="!loading && (!condensed || !isValid())">
    <ng-container *ngIf="formDesign === 'expanded'">
      <div class="row" *ngIf="fields.firstName || fields.lastName">
        <div class="col-12 col-md-6" *ngIf="fields.firstName">
          <div class="form-group form-floating">
            <input type="text" class="form-control with-background" formControlName="firstName" (keyup)="personalDetailsEvent()" autocomplete="given-name" data-cy="firstNameInput">
            <label for="first-name">{{ 'profile.info.firstName' | translate }}</label>
            <div class='error-message' *ngIf="firstName.errors !== null && (firstName.touched)" [innerHtml]="firstNameError">
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6" *ngIf="fields.lastName">
          <div class="form-group form-floating">
            <input type="text" class="form-control with-background"
                   formControlName="lastName"
                   (keyup)="personalDetailsEvent()" autocomplete="family-name" data-cy="lastNameInput">
            <label for="last-name">{{ 'profile.info.lastName' | translate }}</label>
            <div class='error-message' *ngIf="lastName.errors !== null && (lastName.touched)" [innerHtml]="lastNameError">
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="fields.email">
        <div class="col">
          <div class="form-group form-floating">
            <input type="email" class="form-control with-background" formControlName="email" (keyup)="personalDetailsEvent()"
              autocomplete="email" data-cy="email-input">
              <label for="email">{{ 'profile.info.email' | translate }}</label>
              <div class='error-message' *ngIf="email.errors !== null && (email.touched)" [innerHtml]="emailError">
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="fields.homePhone">
        <div class="col">
          <phone-input
            labelKey="profile.info.homePhone"
            formGroupName="homePhone"
            [countries]="countries"
            [formGroup]="homePhone"
            [phoneForceError]="homePhoneRequiredError"
            (onKeyUp)="personalDetailsEvent()"></phone-input>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="formDesign === 'inline'">
      <div *ngIf="fields.nationality" class="secondary-content mb-2">{{ 'profile.reservations.flights.details.mobile.passenger' | translate }} {{ passNumber }}</div>
      <div class="row">
        <div [ngClass]="requiresNationality ? 'col-12 col-md-4 pe-md-0' : 'col-12 col-md pe-md-0'" *ngIf="fields.firstName">
          <div class="form-group form-floating">
            <input
              type="text"
              class="secondary-content form-control with-background input-height"
              [value]="firstName.value"
              formControlName="firstName"
            />
            <label *ngIf="showLabels" for="first-name" class="lang-{{lang}}">{{ 'profile.info.firstName' | translate }}</label>
            <div class='error-message' *ngIf="firstName.errors !== null && (firstName.touched)" [innerHtml]="firstNameError"></div>
          </div>
        </div>
        <div [ngClass]="requiresNationality ? 'col-12 col-md-4 pe-md-0' : 'col-12 col-md pe-md-0'" *ngIf="fields.middleName">
          <div class="form-group form-floating">
            <input type="text" id="middle-name" class="form-control with-background input-height" formControlName="middleName" (keyup)="personalDetailsEvent()" autocomplete="family-name">
            <label *ngIf="showLabels" for="middle-name" class="middle-name lang-{{lang}}">{{
              'profile.info.middleName' | translate }}</label>
            <div class='error-message' *ngIf="middleName.errors !== null && (middleName.touched)" [innerHtml]="middleNameError">
            </div>
          </div>
        </div>
        <div [ngClass]="requiresNationality ? 'col-12 col-md-4' : 'col-12 col-md pe-md-0'" *ngIf="fields.lastName">
          <div class="form-group form-floating">
            <input
              type="text"
              class="secondary-content form-control with-background input-height"
              [value]="lastName.value"
              formControlName="lastName"
            />
            <label *ngIf="showLabels" for="last-name" class="lang-{{lang}}">{{ 'profile.info.lastName' | translate }}</label>
            <div class='error-message' *ngIf="lastName.errors !== null && (lastName.touched)" [innerHtml]="lastNameError"></div>
          </div>
        </div>
        <div [ngClass]="requiresNationality ? 'col-12 col-md-4 pe-md-0' : 'col-md-3'" *ngIf="fields.birthDate">
          <div class="form-group with-background mb-md-0 form-floating">
            <datepicker-range data-cy="birthDate" [displayMonths]="1" [startDate]="birthdayDate" [displayFormat]="'MM/DD/YYYY'"
              [inputName]="birthDate" [singleDate]="true" [showCalendarIcon]="true" [showCalendarIconRight]="true"
              navigation="select" [minDate]="minDate" [maxDate]="maxDate" emitFormat="YYYY-MM-DD" [displayLabel]="showLabels"
              [placeholderStart]="'profile.info.dob' | translate" [disabledModifyDate]="!dateOfBirthRestricted"
              (datesSelected)="onDatesSelected($event)">
            </datepicker-range>
          </div>
          <div class='error-message' *ngIf="birthDate.errors !== null && (birthDate.touched)"
               [innerHtml]="formErrorsHelper.errorMessage(birthDate, 'flights.booking.ticketing.dob' | translate) ">
          </div>
        </div>
        <div [ngClass]="requiresNationality ? 'col-12 col-md-4 pe-md-0' : 'col-12 col-md ps-lg-0 ps-md-1'" *ngIf="fields.gender">
          <div class="form-group form-floating">
            <select data-cy="gender" id="gender" class="form-control form-select flights-checkout-gender form-select with-background input-height" formControlName="gender" required (change)="personalDetailsEvent()">
              <option value="M">{{ 'flights.booking.ticketing.gender.male' | translate }}</option>
              <option value="F">{{ 'flights.booking.ticketing.gender.female' | translate }}</option>
              <option value="U">{{ 'flights.booking.ticketing.gender.undisclosed' | translate }}</option>
              <option value="X">{{ 'flights.booking.ticketing.gender.unspecified' | translate }}</option>
            </select>
            <label *ngIf="showLabels" for="gender" class="lang-{{lang}}">{{'flights.booking.ticketing.gender.title' | translate }}</label>
            <div class='error-message' *ngIf="gender.errors !== null && (gender.touched)"
              [innerHtml]="formErrorsHelper.errorMessage(gender, 'flights.booking.ticketing.gender.title' | translate) ">
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4" *ngIf="requiresNationality">
          <div class="form-group form-floating">
            <select class="form-control form-select flights-checkout-gender with-background input-height" placeholder=" " id="nationality" formControlName="nationality" (change)="personalDetailsEvent()">
              <option *ngFor="let country of countries" [value]="country.code">{{ country.name }}</option>
            </select>
            <label *ngIf="showLabels" for="nationality" class="lang-{{lang}}">{{ 'profile.info.nationality' |
              translate }}</label>
            <div class='error-message' *ngIf="nationality.errors !== null && (nationality.touched)"
              [innerHtml]="formErrorsHelper.errorMessage(nationality, ('profile.info.nationality' | translate))">
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="fields.ticketType && (!ticketTypeSelector || !ticketField)">
        <div class="col-12 col-md">
          <div class="form-group form-floating">
            <select id="ticketType" formControlName="ticketType" class="form-control form-select with-background" placeholder=" "
              data-cy="ticket-type" (change)="personalDetailsEvent()" >
              <option *ngFor="let type of ticketTypes" [value]="type[1]">{{type[0]}}</option>
            </select>
            <label for="ticketType">{{ 'mobile.flights.listings.ticket_type' | translate }}</label>
            <div class='error-message' *ngIf="ticketType.errors !== null && (ticketType.touched)"
              [innerHtml]="formErrorsHelper.errorMessage(ticketType, 'mobile.flights.listings.ticket_type' | translate) ">
            </div>
          </div>
        </div>
        <div class="col-12 col-md">
          <div class="form-group">
            <div class="position-relative form-floating">
              <input type="text" id="ticketNumber" class="form-control with-background" formControlName="ticketNumber" maxlength="14"
                data-cy="ticket-number" (keyup)="personalDetailsEvent()" >
              <label for="ticketNumber">{{ 'mobile.flights.listings.ticket_number' | translate }}</label>
              <fa-icon [icon]="icons.faInfoCircle" class="d-md-inline input-icon" placement="top" [ngbTooltip]="tooltipContent"></fa-icon>
            </div>
            <div class='error-message' *ngIf="ticketNumber.errors !== null && (ticketNumber.touched)"
              [innerHtml]="formErrorsHelper.errorMessage(ticketNumber, 'mobile.flights.listings.ticket_number' | translate) ">
            </div>
          </div>
        </div>
      </div>
    </ng-container>

  </form>
</div>

<ng-template #tooltipContent>
  <div [innerHtml]="'mobile.flights.listings.ticket_number_popover' | translate"></div>
</ng-template>
