import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'guest-login-box',
  templateUrl: './guest-login-box.html'
})

export class GuestLoginBoxComponent {
  
  constructor(private router: Router) { }

  loginRedirect() {
    const snapshot = this.router.routerState.snapshot.url;
    return this.router.navigate(['login'], { queryParams: { redirect: encodeURIComponent(snapshot) } });
  }

  createAccountRedirect() {
    return this.router.navigate(['create-account']);
  }
}
