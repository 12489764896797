<div class="cvv-information">
  <ng-container *ngIf="!cardType">
    <div class="columns d-flex flex-column flex-md-row text-center">
      <ng-container *ngTemplateOutlet="noamex"></ng-container>
      <ng-container *ngTemplateOutlet="amex"></ng-container>
    </div>
  </ng-container>
  <div class="text-center" *ngIf="cardType && cardType === 'amex'">
    <ng-container *ngTemplateOutlet="amex"></ng-container>
  </div>
  <div class="text-center" *ngIf="cardType &&  cardType !== 'amex'">
    <ng-container *ngTemplateOutlet="noamex"></ng-container>
  </div>
</div>

<ng-template #noamex>
  <div class="flex flex-column noamex">
    <img src="angular_assets/images/common/visa-vvc.png" alt="">
    <h4 class="mt-3">VISA, MASTERCARD & DISCOVER</h4>
    <div class="secondary-content pt-2 pt-md-0" [innerHtml]="'flights.booking.ccvinfo_non_amex' | translate"></div>
  </div>
</ng-template>

<ng-template #amex>
  <div class="flex flex-column amex">
    <img src="angular_assets/images/common/amex-vvc.png" alt="">
    <h4 class="mt-3">AMEX</h4>
    <div class="secondary-content pt-2 pt-md-0" [innerHtml]="'flights.booking.ccvinfo_amex' | translate"></div>
  </div>
</ng-template>

