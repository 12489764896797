import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, combineLatest } from 'rxjs';
import { map, skipWhile, tap } from 'rxjs/operators';
import * as fromAuth from 'app/auth/store/reducers';
import * as fromShared from 'app/shared/store/reducers';
import { Store, select } from '@ngrx/store';
import { MFAHelper } from 'app/profile/helpers/mfa.helper';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { MissingInfoHelper } from 'app/shared/helpers/missing-info.helper';
import { Mode } from 'app/shared/models/missing-modal';
import { MissingInfoModalComponent } from 'app/auth/components/missing-info-modal/missing-info-modal.component';
import { GeneralHelper } from 'app/shared/helpers/general.helper';
import { User } from 'app/auth/models/user';

@Injectable({
  providedIn: 'root'
})
export class MissingInfoGuard  {
  user$ = this.store.pipe(select(fromAuth.getUser));
  appConfig$ = this.store.pipe(select(fromShared.getConfigFlag));
  flags$ = this.store.pipe(select(fromShared.getFeatureFlags));
  airlines$: any = this.store.pipe(select(fromShared.getAirlines));
  mfaRequest$ = this.store.pipe(select(fromAuth.mfaRequest));
  mfaRequest;
  user;
  flags;
  airlines;
  ngbModalOpt: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'lg'
  };

  constructor(
    private mfaHelper: MFAHelper,
    private store: Store,
    private missingInfoHelper: MissingInfoHelper,
    private modalService: NgbModal,
    ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return combineLatest<[User, any, any, boolean]>([this.user$, this.flags$, this.airlines$, this.mfaRequest$]).pipe(
      skipWhile(([user, flags, airlines, mfaRequest]) => {
        if (!user?.member?.id) { return false; }
        return !flags;
      }),
      map(([user, flags, airlines, mfaRequest]) => {
        this.user = user;
        this.flags = flags;
        this.airlines = airlines;
        this.mfaRequest = mfaRequest
        return true;
      }),
      tap(async (resp) => {
        if (!this.isRequestMFAActive()) { return of(true); }
        const isSpecialUser =
          !this.user
          || this.user.generic_user
          || this.user.call_center_admin
          || this.user.account?.restricted
          || this.user.cobus_authorization;
        if (isSpecialUser) {
          return of(true);
        }
        const blockedDomains = GeneralHelper.getBlockDomains(this.airlines, this.user.account.airlineCode);
        const valid = this.missingInfoHelper.checkMissingInformation(this.user.account.homePhone, this.user.account.personalEmail, blockedDomains);
        if (valid.email && valid.phone) {
          return of(true);
        }
        if (this.isMfaModalActive()) { return of(true); }
        if (this.modalService.hasOpenModals()) { return of(true); }

        if (!valid.email && !valid.phone) {
          const modalConfig = this.missingInfoHelper.buildMissingInfoModal(Mode.customerSupportMsg, []);
          const missingInfoModal = this.modalService.open(MissingInfoModalComponent, this.ngbModalOpt);
          missingInfoModal.componentInstance.modalconfig = modalConfig;
          missingInfoModal.componentInstance.mode = Mode.customerSupportMsg;
          return await missingInfoModal.result.then(
            res => of(!!res),
            err => of(false),
          );
        }

        const mode = !valid.email ? Mode.email : Mode.phone
        const modalConfig = this.missingInfoHelper.buildMissingInfoModal(mode, blockedDomains, this.user.account.personalEmail);
        const missingInfoModal = this.modalService.open(MissingInfoModalComponent, this.ngbModalOpt);
        missingInfoModal.componentInstance.modalconfig = modalConfig;
        missingInfoModal.componentInstance.mode = mode;
        missingInfoModal.componentInstance.user = this.user;
        missingInfoModal.componentInstance.mfaRequest = this.mfaRequest;
        return await missingInfoModal.result.then(
          res => of(!!res),
          err => of(false),
        );
      })
    );
  }

  isRequestMFAActive() {
    return this.mfaHelper.requestMFA(this.user, this.flags, true);
  }

  isMfaModalActive() {
    return this.mfaHelper.showMFAModal(this.user, this.flags);
  }
}
