import { Action } from '@ngrx/store';

export enum RegisterPageActionTypes {
  Register = '[Register Page] Register',
  RegisterClearError = '[Register Page] Register Clear Error',
  SetInviteParams = '[Register Page] Set invite params',
  ClearInviteParams = '[Register Page] Clear Invite Params'
}

export class Register implements Action {
  readonly type = RegisterPageActionTypes.Register;

  constructor(public payload: { credentials: any }) {}
}

export class RegisterClearError implements Action {
  readonly type = RegisterPageActionTypes.RegisterClearError;

  constructor() { }
}

export class SetInviteParams implements Action {
  readonly type = RegisterPageActionTypes.SetInviteParams;

  constructor(public payload: { inviteParams: any }) {}
}

export class ClearInviteParams implements Action {
  readonly type = RegisterPageActionTypes.ClearInviteParams;

  constructor() { }
}

export type RegisterPageActionsUnion = Register | RegisterClearError | SetInviteParams | ClearInviteParams;
