import { Component, Input } from '@angular/core'
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'fraud-alert',
  templateUrl: 'fraud-alert.component.html',
  styleUrls: ['./fraud-alert.component.scss']
})
export class FraudAlertComponent {
  @Input() errorTitle: string = 'fraud_user.default_main_title';
  @Input() errorMessage: string = 'fraud_user.please_contact_us';
  @Input() errorCode: string = '';
  @Input() flightDesign: boolean = false;

  constructor(
    public translate: TranslateService
  ) {}

  get flightMessage() {
    return `${this.translate.instant('fraud_user.able_to_book_flights')}. ${this.translate.instant(this.errorMessage)}`;
  }
}
