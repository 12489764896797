import { environment } from '@env/environment';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { State } from '../../models/state.model';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  constructor(private http: HttpClient) {}

  get(code) {
    return this.http.get<State[]>(
      `${environment.apiUrl}/api/v1/states.json?country_code=${code}`,
      { headers: { cacheable: 'true' } }
    );
  }
}
