<ng-container *ngIf="!flightDesign">
  <div class="container fraud-container">
    <div class="row">
      <div class="col">
        <h2 class="text-center title-spacing"> {{ errorTitle | translate }} </h2>
      </div>
    </div>
    <div class="container message-container">
      <div class="row">
        <div class="col-12">
          {{ 'fraud_user.trouble_accessing' | translate }}
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12" [innerHtml]="errorMessage | translate"></div>
      </div>
      <div class="row mt-3">
        <div class="col-12 error-code">
          {{ 'fraud_user.error_code' | translate }}: {{ errorCode }}
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="flightDesign">
  <div class="card shadow-none mb-4">
    <div class="card-body">
      <h3 class="text-center"> {{ errorTitle | translate }} </h3>
      <div class="row">
        <div class="col-12 text-center">
          {{ 'fraud_user.trouble_accessing' | translate }}
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 text-center" [innerHtml]="flightMessage"></div>
      </div>
      <div class="row mt-2">
        <div class="col-12 text-center error-code-red">
          {{ 'fraud_user.error_code' | translate }}: {{ errorCode }}
        </div>
      </div>
    </div>
  </div>
</ng-container>
