import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'truncated-text-component',
  templateUrl: './truncated-text.html',
  styleUrls: ['./truncated-text.scss'],
  encapsulation: ViewEncapsulation.None
})

export class TruncatedTextComponent implements OnInit {
  @Input() text;
  @Input() maxLength = 100;
  @Input() microdataItemprop = '';
  @Input() buttonFloatingLeft: boolean = true;
  /* Enables or disables default margins for elements inside innerHTML. */
  @Input() innerMargins: boolean = true;
  truncatedText = '';
  displayText = '';
  showReadMore = false;
  showingMore = false;
  readText = 'Read more <fa-icon [icon]="icons.faAngleDown" class="ms-1"></fa-icon>';

  ngOnInit() {
    if (this.text.length <= this.maxLength) {
      this.truncatedText = this.text;
    } else {
      this.truncatedText = `${this.text.substr(0, this.maxLength)}...`;
      this.showReadMore = true;
    }

    this.displayText = this.truncatedText;
  }

  toggleShowMore() {
    if (this.showingMore) {
      this.displayText = this.truncatedText;
      this.readText = 'Read more <fa-icon [icon]="icons.faAngleDown" class="ms-1"></fa-icon>';
    } else {
      this.displayText = this.text;
      this.readText = 'Read less <fa-icon [icon]="icons.faAngleUp" class="ms-1"></fa-icon>';
    }
    this.showingMore = !this.showingMore;
  }
}
