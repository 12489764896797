<div class="container">
  <div class="row justify-content-center">
    <div class="col-md-8 col-sm-12 text-center py-4">
      <h1>{{ formTitle | translate }}</h1>
    </div>
    <div class="col-md-8 col-sm-12">
      <loading-indicator [fullScreen]="false" *ngIf="loading"></loading-indicator>
      <ngb-alert class="success-message" *ngIf="success" type="success" (close)="closeAlert()" [innerHTML]="'membership.request.success.content' | translate"></ngb-alert>
      <ngb-alert *ngIf="error" type="danger" (close)="closeAlert()" [innerHTML]="'errors.internal_server_error' | translate"></ngb-alert>
      <form *ngIf="!loading" [formGroup]="contactForm" (ngSubmit)="createTicket()" enctype="multipart/form-data">
        <div class="row pb-4">
          <div class="col-12 contact-info">
            <div class="secondary-content" [innerHTML]="formContent | translate"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group form-floating">
              <input type="text" formControlName="name" id="name" class="form-control validate">
              <label for="name">{{ 'contact.form.full_name' | translate }}</label>
              <div class='error-message' *ngIf="name.errors !== null && (name.touched)" [innerHtml]="formErrorsHelper.errorMessage(name, ('contact.form.full_name' | translate))">
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group form-floating">
              <input type="email" formControlName="email" id="email" class="form-control validate">
              <label for="email">{{ 'membership.create.personal_email' | translate }}</label>
              <div class='error-message' *ngIf="email.errors !== null && (email.touched)" [innerHtml]="formErrorsHelper.errorMessage(email, ('membership.create.personal_email' | translate))">
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <airlines-autocomplete-component
              [airlines]="airlines"
              [codeFieldName]="'airline'"
              [control]="contactForm.get('airline')"
              [form]="contactForm"
              [isSignIn]="true"
              [action]="'sign-up'">
            </airlines-autocomplete-component>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <input type="file"  id="identification" class="form-control validate" (change)="onFileChange('identification', $event)">
              <div class="tertiary-content">{{ 'membership.request.identification.info' | translate }}</div>
              <div class='error-message' *ngIf="identification.errors !== null && (identification.touched)" [innerHtml]="formErrorsHelper.errorMessage(identification, ('membership.request.identification.label' | translate))">
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <input type="file"  id="badge" class="form-control validate" (change)="onFileChange('badge', $event)">
              <div class="tertiary-content">{{ 'membership.request.badge.info' | translate }}</div>
              <div class='error-message' *ngIf="badge.errors !== null && (badge.touched)" [innerHtml]="formErrorsHelper.errorMessage(badge, ('membership.request.badge.label' | translate))">
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <ngx-recaptcha2 #captchaElem [siteKey]="recaptchaKey" [useGlobalDomain]="false" formControlName="recaptcha">
              </ngx-recaptcha2>
              <div class='error-message' *ngIf="recaptcha.errors !== null && (recaptcha.touched)"
                   [innerHtml]="formErrorsHelper.errorMessage(recaptcha, ('membership.create.captcha' | translate))">
              </div>
            </div>
          </div>
          <div class="col-12 mb-5">
            <button type="submit" class="btn btn-primary w-100 my-2">{{ 'contact.form.submit' | translate }}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
