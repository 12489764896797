import { AllInterestsActions } from '../actions';
import { AllInterestsState } from 'app/profile/models/all-interests.interface';

export const initialState: AllInterestsState = {
  error: null,
  pending: false,
  response: {}
};

export function reducer(
  state = initialState,
  action:
    | AllInterestsActions.AllInterestsActionsUnion
): AllInterestsState {
  switch (action.type) {
    case AllInterestsActions.AllInterestsActionTypes.LoadAllInterests:
      return {
        ...state,
        pending: true
      };
    case AllInterestsActions.AllInterestsActionTypes.LoadAllInterestsSuccess:
      return {
        ...state,
        pending: false,
        response: action.payload.response,
        error: initialState.error
      };
    case AllInterestsActions.AllInterestsActionTypes.LoadAllInterestsFailure:
      return {
        ...state,
        pending: false,
        error: action.payload.error
      };
    default: {
      return state;
    }
  }
}

export const getError = (state: AllInterestsState) => state.error;
export const getPending = (state: AllInterestsState) => state.pending;
export const getSuccess = (state: AllInterestsState) => state.response;
