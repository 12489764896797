<div class="card my-3 text-center trips clickable-card" (click)="viewTripClicked.emit(insurance)">
  <div class="card-body pb-3">
    <div class="primary-content hotel-name mt-2" data-cy="insuranceCardTitle">{{ destination }}</div>
    <div class="secondary-content mt-2">
      <div>{{ 'profile.reservations.insurance.header_title' | translate }} {{ insurance.id }}</div>
      <time datetime="'insurance.departureDate'">{{ insurance.departureDate | amDateFormat:'ddd, MMM D' }}</time> -
      <time datetime="'insurance.returnDate'">{{ insurance.returnDate | amDateFormat:'ddd, MMM D' }}</time>
      <div>{{ insurance.travelers.length }} {{ 'emails.confirmation.ticketing.travel' | translate }}</div>
    </div>
  </div>
</div>
