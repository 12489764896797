import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromTrips from './trips.reducer';
import * as fromRoot from '../../../../reducers';


export interface TripsState {
  trips: fromTrips.State;
}

export interface State extends fromRoot.State {
  trips: TripsState;
}

export const reducers: ActionReducerMap<TripsState> = {
  trips: fromTrips.reducer,
};

export const selectTripsContainerState = createFeatureSelector<State, TripsState>('trips');
export const selectTripsState = createSelector(selectTripsContainerState, state => state.trips);
export const getFutureHotels = createSelector(selectTripsState, fromTrips.getFutureHotels);
export const getPastHotels = createSelector(selectTripsState, fromTrips.getPastHotels);
export const getFutureFlights = createSelector(selectTripsState, fromTrips.getFutureFlights);
export const getPastFlights = createSelector(selectTripsState, fromTrips.getPastFlights);
export const getFutureCars = createSelector(selectTripsState, fromTrips.getFutureCars);
export const getPastCars = createSelector(selectTripsState, fromTrips.getPastCars);
export const getHotelsInsurance = createSelector(selectTripsState, fromTrips.getHotelsInsurance);
export const getFlightsInsurance = createSelector(selectTripsState, fromTrips.getFlightsInsurance);
export const getPendingFutureHotels = createSelector(selectTripsState, fromTrips.getPendingFutureHotels);
export const getPendingFutureCars = createSelector(selectTripsState, fromTrips.getPendingFutureCars);
export const getPendingFutureFlights = createSelector(selectTripsState, fromTrips.getPendingFutureFlights);
export const getPendingHotelsInsurance = createSelector(selectTripsState, fromTrips.getPendingHotelsInsurance);
export const getPendingPastHotels = createSelector(selectTripsState, fromTrips.getPendingPastHotels);
export const getPendingPastCars = createSelector(selectTripsState, fromTrips.getPendingPastCars);
export const getPendingPastFlights = createSelector(selectTripsState, fromTrips.getPendingPastFlights);
export const getPendingFlightsInsurance = createSelector(selectTripsState, fromTrips.getPendingFlightsInsurance);
export const getFutureHotelsLoaded = createSelector(selectTripsState, fromTrips.getFutureHotelsLoaded);
export const getFutureCarsLoaded = createSelector(selectTripsState, fromTrips.getFutureCarsLoaded);
export const getFutureFlightsLoaded = createSelector(selectTripsState, fromTrips.getFutureFlightsLoaded);
export const getHotelsInsuranceLoaded = createSelector(selectTripsState, fromTrips.getHotelsInsuranceLoaded);
export const getSharedHotels = createSelector(selectTripsState, fromTrips.getSharedHotels);
export const getPastSharedHotels = createSelector(selectTripsState, fromTrips.getPastSharedHotels);
