<section class="first-time-login">
  <fraud-alert *ngIf="isBlocked" [errorMessage]="'fraud_user.please_contact_us'" [errorCode]="'572'"></fraud-alert>
  <div *ngIf="!isBlocked" class="container-fluid">
    <div class="row">
      <div class="col-12 text-center" data-cy="account-validation">
        <h1>{{ 'membership.partner.account_validation' | translate }}</h1>
      </div>
      <div class="col-md-6 col-sm-8 col-xs-12 offset-md-3 offset-sm-2 pt-5">
        <ngb-alert *ngIf="error" type="danger" (closed)="closeAlert()">
          <div class="fw-bold" data-cy="generic-error">{{ 'membership.partner.generic_error_title' | translate }}</div>
          <p>{{ 'membership.partner.generic_error_p1' | translate }}</p>
          <p>{{ 'membership.partner.generic_error_p2' | translate }}</p>
        </ngb-alert>
        <loading-indicator [fullScreen]="false" *ngIf="pending$ | async"></loading-indicator>
        <form *ngIf="!(pending$ | async)" [formGroup]="personalDataForm" (ngSubmit)="validateAccount()">
          <div class="form-group">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <input placeholder="{{'profile.info.firstName' | translate}}" type="text" formControlName="firstName" id="firstName"
                    class="form-control validate with-background" required autocomplete="given-name" data-cy="first-name">
                  <div class='error-message' *ngIf="firstName.errors !== null && (firstName.touched)"
                    [innerHtml]="formErrorsHelper.errorMessage(firstName, 'profile.info.firstName' | translate)"></div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <input placeholder="{{'profile.info.lastName' | translate}}" type="text" formControlName="lastName" id="lastName"
                    class="form-control validate with-background" required autocomplete="family-name" data-cy="last-name">
                  <div class='error-message' *ngIf="lastName.errors !== null && (lastName.touched)"
                    [innerHtml]="formErrorsHelper.errorMessage(lastName, 'profile.info.lastName' | translate)"></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <input type="text" formControlName="dateOfHire" id="dateOfHire" class="form-control validate with-background"
                    placeholder="{{ 'profile.info.date_hire' | translate }} (mm-dd-yyyy)" data-cy="hire-date">
                    <div class='error-message' *ngIf="dateOfHire.errors !== null && (dateOfHire.touched)"
                      [innerHtml]="formErrorsHelper.errorMessage(dateOfHire, 'profile.info.date_hire' | translate)"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-6 text-center">
              <button type="button" class="btn btn-outline-primary w-100 my-2" (click)="cancel()">
                {{ 'profile.reservations.hotels.details.labels.cancel_btn' | translate }}
              </button>
            </div>
            <div class="col-md-6 col-sm-6 text-center">
              <button [disabled]="isBlocked" type="submit" class="btn btn-primary w-100 my-2" data-cy="submit" >
                {{ 'password_reset.submit_button' | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
