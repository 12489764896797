import { AuthApiActions, ThirdPartyActions } from '../actions';

export interface State {
  error: any;
  pending: boolean;
  success: boolean;
}

export const initialState: State = {
  error: null,
  pending: false,
  success: false
};

export function reducer(
  state = initialState,
  action: AuthApiActions.AuthApiActionsUnion | ThirdPartyActions.ThirdPartyActionsUnion
): State {
  switch (action.type) {
    case ThirdPartyActions.ThirdPartyActionTypes.ConnectThirdParty:
      return {
        ...state,
        error: null,
        pending: true,
        success: false
      };
    case ThirdPartyActions.ThirdPartyActionTypes.ConnectThirdPartySuccess:
      return {
        ...state,
        error: null,
        pending: false,
        success: true
      };
    case ThirdPartyActions.ThirdPartyActionTypes.ConnectThirdPartyFailure:
      return {
        ...state,
        error: action.payload.error,
        pending: false,
        success: false
      };
    case ThirdPartyActions.ThirdPartyActionTypes.DisconnectThirdParty:
      return {
        ...state,
        error: null,
        pending: true,
        success: false
      };
    case ThirdPartyActions.ThirdPartyActionTypes.DisconnectThirdPartySuccess:
      return {
        ...state,
        error: null,
        pending: false,
        success: true
      };
    case ThirdPartyActions.ThirdPartyActionTypes.DisconnectThirdPartyFailure:
      return {
        ...state,
        error: action.payload.error,
        pending: false,
        success: false
      };
    default: {
      return state;
    }
  }
}

export const getError = (state: State) => state.error;
export const getPending = (state: State) => state.pending;
export const getSuccess = (state: State) => state.success;
