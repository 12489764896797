<div class="stepper d-none d-lg-flex">
  <div
    *ngFor="let step of steps; let i = index"
    [ngClass]="{ active: i === currentStep, complete: i < currentStep }"
    (click)="onStepClick(i)"
    class="step"
  >
    <div class="step-content">
      <div class="step-circle"></div>
      <fa-icon class="step-check" [icon]="icons.faCheckCircle"></fa-icon>
      <span class="step-text">{{ step.text | translate : step.params }}</span>
    </div>
  </div>
</div>

<div class="row">
  <div class="col mobile-stepper d-lg-none">
    <ng-container *ngFor="let step of steps; let i = index; let isLast = last">
      <div [ngClass]="{ active: i === currentStep, complete: i < currentStep }" class="step" (click)="onStepClick(i)">
        <div class="step-text">{{ step.text | translate : step.params }}</div>
      </div>
      <div *ngIf="!isLast" class="step-separator">&gt;</div>
    </ng-container>
  </div>
</div>
