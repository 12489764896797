import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { AirportsRecentDeparturesSearchesActions, AirportsRecentDeparturesSearchesApiActions } from '../actions';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { RecentSearchesService } from '../../services/recent-searches/recent-searches.service';

@Injectable()
export class AirportsRecentDeparturesSearchesEffects {

  load$ = createEffect(()=>
    this.actions$.pipe(
      ofType<AirportsRecentDeparturesSearchesActions.Load>(AirportsRecentDeparturesSearchesActions.AirportsRecentDeparturesSearchesActionTypes.Load),
      switchMap(() =>
        this.recentSearchesService.airports('departure').pipe(
          map(res => new AirportsRecentDeparturesSearchesApiActions.LoadSuccess({ destinations: res.data })),
          catchError(error => of(new AirportsRecentDeparturesSearchesApiActions.LoadFailure(error)))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private recentSearchesService: RecentSearchesService
  ) {}
}
