<button
  *ngIf="eligibleTravelerWithStatus.invitedTravelerStatus !== INVITED_TRAVELER_STATUS.CONFIRMED && eligibleTravelerWithStatus.travelerStatus === ELIGIBLE_TRAVELER_STATUS.ACTIVE"
  class="btn gtm-invite btn-primary btn-md m-1"
  (click)="sendInvitation()"
>
  {{ (eligibleTravelerWithStatus.invitedTravelerStatus !== INVITED_TRAVELER_STATUS.PENDING ?
  'profile.eligible_travelers.invite_button' : 'profile.eligible_travelers.re_invite_button') | translate }}
</button>
<ng-container
  *ngIf="eligibleTravelerWithStatus.invitedTravelerStatus === INVITED_TRAVELER_STATUS.CONFIRMED && (eligibleTravelerWithStatus.travelerStatus === ELIGIBLE_TRAVELER_STATUS.ACTIVE || eligibleTravelerWithStatus.travelerStatus === ELIGIBLE_TRAVELER_STATUS.PENDING_REMOVAL)"
>
  <button class="btn gtm-invite btn-danger btn-md m-1" (click)="onRemoveAccessClick()">
    {{ 'profile.eligible_travelers.remove_access_button' | translate }}
  </button>
  <button *ngIf="areOtherEligibleTravelers" class="btn gtm-invite btn-primary btn-md m-1" (click)="giveAccess()">
    {{ 'profile.eligible_travelers.update_access_button' | translate }}
  </button>
</ng-container>
