import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EligibleTravelersService } from 'app/shared/services/eligible-travelers/eligible-travelers.service';
import { NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { forEach, merge, get } from 'lodash';
import * as moment from 'moment';
import * as fromRoot from 'reducers';
import * as fromShared from 'app/shared/store/reducers';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';
import { CustomValidators } from 'app/shared/validators/custom-validators';
import { map, take } from 'rxjs/operators';
import { concat, combineLatest } from 'rxjs';
import { ProfileHelper } from 'app/shared/helpers/profile.helper';
import { SessionService } from 'app/shared/services/session/session.service';
import { ConfirmationDialogService } from 'app/shared/services/confirmation-dialog/confirmation-dialog.service';
import { GeneralHelper } from 'app/shared/helpers/general.helper';
import { BookableEligibleTravelerStatus, EligiblesTravelers, EligibleTravelerStatus } from 'app/profile/models/eligibles-travelers';
import { Store } from '@ngrx/store';
import { faExclamationCircle, faCalendar } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'eligible-travler',
  templateUrl: 'eligible-traveler.html',
  styleUrls: ['./eligible-traveler.scss']
})
export class EligibleTravelerComponent implements OnInit {
  @Input() active = 1;
  @Input() travelerId;
  @Input() relations;
  @Input() externalConfigs;
  @Input() enableModify: boolean;
  @Input() showBasicGroup: boolean;
  @Input() eligibleTravelers: EligiblesTravelers[];
  @Input() canInvite: boolean;
  loading: boolean = false;
  eligibleForm: UntypedFormGroup;
  profileSub: any = {};
  relationships: any = {};
  studentOptions: any = [];
  formGroup: any = {};
  config: any = {};
  entity: any = {};
  minDate;
  maxDate;
  maxDatePassport;
  startDate;
  countries$ = this.store.select(fromShared.getCountries);
  countries;
  startPassportIssuanceDate;
  startPassportExpirationDate;
  profileStructure: any = {
    eligible: [
      { key: 'firstName', type: 'name' },
      { key: 'middleName', type: 'name' },
      { key: 'lastName', type: 'name' },
      { key: 'relationshipId', type: 'select' },
      { key: 'student', type: 'select' },
      { key: 'birthDate', type: 'date' },
      { key: 'gender', type: 'select' },
      { key: 'knownTravelerNumber', type: 'text' },
      { key: 'fqtvNumber', type: 'text' },
      { key: 'isBasicGroup', type: 'toggle' }
    ],
    passport: [
      { key: 'passportFirstName', type: 'passportName' },
      { key: 'passportMiddleName', type: 'passportName' },
      { key: 'passportLastName', type: 'passportName' },
      { key: 'passportNationality', type: 'country' },
      { key: 'passportNumber', type: 'textAndHyphen' },
      { key: 'passportNumberDomestic', type: 'textAndHyphen' },
      { key: 'passportIssuanceDate', type: 'date' },
      { key: 'passportExpirationDate', type: 'future_date' },
      { key: 'passportIssuanceCountry', type: 'country' },
      { key: 'passportCountryBirth', type: 'country' }
    ]
  };
  previousDay: moment.Moment;
  today: moment.Moment;
  nextDay: moment.Moment;
  previuosDayFormatted: NgbDateStruct;
  todayFormatted: NgbDateStruct;
  nextDayFormated: NgbDateStruct;
  studentOptions$ = this.translateService.get('profile.travelers.student_options').pipe(
    take(1),
    map((x) => {
      return Object.keys(x).map((y) => {
        return { key: y, value: x[y] };
      });
    })
  );
  isTravelerInformationValid = true;
  isTravelerPassportValid = true;
  icons = { faExclamationCircle, faCalendar };
  flightAccessForm: UntypedFormGroup;
  filteredEligibleTravelers: EligiblesTravelers[];
  eligibleTravelerWithStatus: EligiblesTravelers;

  readonly errors: {[key: string]: string} = {
    TOO_MANY_ELIGIBLE_TRAVELERS_WITH_RELATIONSHIP: this.translateService.instant("profile.travelers.to_many_relationships")
  };
  readonly ELIGIBLE_TRAVELER_STATUS = EligibleTravelerStatus;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private eligibleTravelersService: EligibleTravelersService,
    public activeModal: NgbActiveModal,
    public formErrorsHelper: FormErrorsHelper,
    private translateService: TranslateService,
    private profileHelper: ProfileHelper,
    private sessionService: SessionService,
    private confirmationDialogService: ConfirmationDialogService,
    private generalHelper: GeneralHelper,
    private store: Store<fromRoot.State>
  ) { }

  ngOnInit() {
    this.setLimitDates();
    this.getCountries();
    if (this.travelerId) {
      this.getEligibleInformation();
      return;
    }
    this.configEmpty();
    this.createForm();
    this.loading = false;
  }

  configEmpty() {
    this.config = this.externalConfigs;
    this.relationships = this.relations;
  }

  setLimitDates() {
    this.minDate = { year: 1900, month: 1, day: 1 };
    this.maxDate = { year: moment().year(), month: moment().month(), day: moment().day() };
    this.previousDay = moment().subtract(2, 'days');
    this.today = moment();
    this.nextDay = moment().add(1, 'days');
    this.previuosDayFormatted = this.formatMomentDate(this.previousDay);
    this.todayFormatted = this.formatMomentDate(this.today);
    this.nextDayFormated = this.formatMomentDate(this.nextDay);
    this.maxDatePassport = this.formatMomentDate(moment().add(26, 'years'));
  }

  formatMomentDate(moment: moment.Moment) {
    const getDate = { y: Number(moment.format('Y')), m: Number(moment.format('M')), d: Number(moment.format('D')) };
    const date : NgbDateStruct = { year: getDate.y, month: getDate.m, day: getDate.d };
    return date;
  }

  getEligibleInformation() {
    this.loading = true;
    this.profileSub = this.eligibleTravelersService.getTraveler(this.travelerId).pipe(take(1)).subscribe(
      (response) => {
        this.entity = response.traveler;
        this.config = response.config;
        this.relationships = response.relationships;
        this.createForm();
        this.setEligibleWithStatus();
        this.setFilteredEligibleTravelers();
        this.setFlightAccessForm();
        this.loading = false;
      },
      () => this.loading = false
    );
  }

  createForm() {
    forEach(this.profileStructure, (value, key) => {
      const formElements = {};
      forEach(value, (item) => {
        if (!this.config[item.key]) return;
        formElements[item.key] = this.getFormElement(item.key, item.type);
      });
      this.formGroup[key] = this.formBuilder.group(formElements);
    });
    this.eligibleForm = this.formBuilder.group(this.formGroup);
    const startDateControl = this.eligibleForm.controls.eligible.get('birthDate');
    this.startDate = startDateControl.value ? startDateControl.value : this.previousDay.format('YYYY-MM-DD');
    this.startPassportIssuanceDate = this.getPassportDate('passportIssuanceDate') || null;
    this.startPassportExpirationDate = this.getPassportDate('passportExpirationDate') || null;

    const knownTravelerNumberInput = this.eligibleForm.controls.eligible.get('knownTravelerNumber');
    if (knownTravelerNumberInput) {
      knownTravelerNumberInput.setValidators([CustomValidators.text, CustomValidators.textWithoutBlankSpaces]);
    }
  }

  getFormElement(key, type) {
    const elements = [];
    const attributes = { value: this.entity[key], disabled: !this.config[key].enabled };
    const validators = [];
    elements.push(attributes);
    const validatorType = (type === 'name') ? 'textName' : type;
    if (this.config[key].required) {
      validators.push(Validators.required);
    }
    if (CustomValidators[validatorType]) {
      validators.push(CustomValidators[validatorType]);
    }
    if (validators) {
      elements.push(Validators.compose(validators));
    }
    return elements;
  }

  getCountries() {
    combineLatest([this.countries$]).pipe(take(1)).subscribe(([countries]) => {
      this.countries = countries;
    });
  }

  onCreateEligibleTravelerClick() {
    if (this.isValid()) {
      this.loading = true;
      let eligibleInformation = merge(this.eligibleForm.value.eligible);
      eligibleInformation = merge(eligibleInformation, this.eligibleForm.value.passport);
      const user = this.sessionService.getAccount();
      eligibleInformation['employeeId'] = user['id'];
      this.createTraveler(eligibleInformation);
    } else {
      const eligibleForm = this.eligibleForm.get('eligible');
      const passportForm = this.eligibleForm.get('passport');
      this.generalHelper.touchForms([eligibleForm, passportForm]);
    }
  }

  onEditEligibleTravelerClick(): void {
    if (this.isValid()) {
      this.loading = true;
      let eligibleInformation = merge(this.entity, this.eligibleForm.value.eligible);
      eligibleInformation = merge(eligibleInformation, this.eligibleForm.value.passport);
      this.updateTraveler(eligibleInformation);
    } else {
      const eligibleForm = this.eligibleForm.get('eligible');
      const passportForm = this.eligibleForm.get('passport');
      this.isTravelerInformationValid = eligibleForm.valid;
      this.isTravelerPassportValid = passportForm.valid;
      this.generalHelper.touchForms([eligibleForm, passportForm]);
    }
  }

  async createTraveler(eligibleInformation) {
    try {
      const response = await this.eligibleTravelersService.newTraveler(eligibleInformation).toPromise();
      this.loading = false;
      this.activeModal.close({ success: response.notice });
    } catch (error) {
      let errorMsg = get(error, 'error.error', error.message);
      errorMsg = get(this.errors, errorMsg,error.message);
      this.loading = false;
      this.activeModal.close({ error: errorMsg });
    }
  }

  updateTraveler(eligibleInformation) {
    const sources = [];
    if (this.showBasicGroup && this.enableModify) {
      sources.push(this.updateTravelerGroup(eligibleInformation));
    }
    sources.unshift(this.eligibleTravelersService.updateTraveler(this.travelerId, eligibleInformation));

    concat(...sources)
      .subscribe(() => {
         this.loading = false;
         this.activeModal.close({ success: this.translateService.instant('profile.travelers.update_success') });
      },(error) => {
        this.loading = false;
        this.activeModal.close({ error: this.translateService.instant('profile.travelers.update_error')  });
      });
  }

  updateTravelerGroup(eligibleInformation) {
    let params = {
      isBasicGroup: eligibleInformation.isBasicGroup ?? false,
      enabledFly: 'N',
      travelerStatus: EligibleTravelerStatus.PENDING_APPROVAL
    };
    params = merge(eligibleInformation, params);
    return this.eligibleTravelersService.updateTravelerGroup(params);
  }

  async deleteTraveler() {
    const confirmationDialog = await this.confirmationDialogService.confirm({
      title: 'profile.travelers.remove_traveler_title',
      message: 'profile.travelers.remove_traveler_text',
      btnOkText: 'profile.travelers.remove_traveler_confirm',
      btnCancelText: 'profile.travelers.traveler_cancel'
    });

    if (confirmationDialog) {
      this.eligibleTravelersService.deleteTraveler(this.travelerId).pipe(take(1)).subscribe(
        () => {
          this.loading = false;
          this.activeModal.close({ success: this.translateService.instant('profile.travelers.remove_success') });
        },
        (error) => {
          this.loading = false;
          this.activeModal.close({ error: error.message });
        }
      );
    }
  }

  isValid() {
    return this.eligibleForm.valid;
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  onDatesSelected(e) {
    const date = `${e.year}/${e.month}/${e.day}`;
    const dateSelected = moment(date).format('YYYY-MM-DD');
    this.eligibleForm.controls.eligible.get('birthDate').setValue(dateSelected);
    this.startDate = dateSelected;
  }

  formatStartDate() {
    const date = this.startDate || moment().format('YYYY-MM-DD');
    this.eligibleForm.controls.eligible.get('birthDate').setValue(date);
    return this.profileHelper.formatStartDate(date);
  }

  onInsurranceDateSelected(e) {
    const date = `${e.year}/${e.month}/${e.day}`;
    const dateSelected = moment(date).format('YYYY-MM-DD');
    this.eligibleForm.controls.passport.get('passportIssuanceDate').setValue(dateSelected);
    this.startPassportIssuanceDate = dateSelected;
  }

  formatStartIssuanceDate() {
    const date = this.startPassportIssuanceDate || moment().format('YYYY-MM-DD');
    return this.profileHelper.formatStartDate(date);
  }

  onExpirationDateSelected(e) {
    const date = `${e.year}/${e.month}/${e.day}`;
    const dateSelected = moment(date).format('YYYY-MM-DD');
    this.eligibleForm.controls.passport.get('passportExpirationDate').setValue(dateSelected);
    this.startPassportExpirationDate = dateSelected;
  }

  formatStartExpirationDate() {
    const date = this.startPassportExpirationDate || moment().format('YYYY-MM-DD');
    return this.profileHelper.formatStartDate(date);
  }

  getPassportDate(fieldName) {
    if (this.eligibleForm.value.passport) {
      return this.eligibleForm.value.passport[fieldName];
    }
    if (this.entity) {
      return this.entity[fieldName];
    }
    return '';
  }

  toggle(event) {
    if (event) {
      event.toggle();
    }
  }

  validateForm() {
    this.isTravelerInformationValid = this.eligibleForm.get('eligible').valid;
    this.isTravelerPassportValid = this.eligibleForm.get('passport').valid;
  }

  closeModal(message) {
    this.activeModal.close(message);
  }

  setEligibleWithStatus() {
    this.eligibleTravelerWithStatus = this.eligibleTravelers.find(
      (eligibleTraveler) => eligibleTraveler.id === this.travelerId,
    );
  }

  setFilteredEligibleTravelers() {
    this.filteredEligibleTravelers = this.eligibleTravelers.filter(
      (eligibleTraveler) =>
        eligibleTraveler.id !== this.travelerId &&
        eligibleTraveler.travelerStatus === this.ELIGIBLE_TRAVELER_STATUS.ACTIVE,
    );
  }

  setFlightAccessForm() {
    const eligibleTravelersForm = this.formBuilder.array([]);
    this.flightAccessForm = this.formBuilder.group({
      email: this.formBuilder.control('', [CustomValidators['email'], Validators.required]),
      eligibleTravelers: eligibleTravelersForm,
    });
    this.filteredEligibleTravelers.forEach((eligibleTraveler) => {
      const enabled =
        this.eligibleTravelerWithStatus.bookableEligibleTravelers?.find(
          (bookableEligibleTraveler) => bookableEligibleTraveler.eligibleTravelerId === eligibleTraveler.id,
        )?.status === BookableEligibleTravelerStatus.ENABLED;
      const eligibleTravelerForm = this.formBuilder.group({
        id: [Number(eligibleTraveler.id)],
        enabled: [enabled],
      });
      eligibleTravelersForm.push(eligibleTravelerForm);
    });
  }

  get firstName() { return this.eligibleForm.controls.eligible.get('firstName'); }
  get middleName() { return this.eligibleForm.controls.eligible.get('middleName'); }
  get lastName() { return this.eligibleForm.controls.eligible.get('lastName'); }
  get relationshipId() { return this.eligibleForm.controls.eligible.get('relationshipId'); }
  get student() { return this.eligibleForm.controls.eligible.get('student'); }
  get birthDate() { return this.eligibleForm.controls.eligible.get('birthDate'); }
  get gender() { return this.eligibleForm.controls.eligible.get('gender'); }
  get knownTravelerNumber() { return this.eligibleForm.controls.eligible.get('knownTravelerNumber'); }
  get fqtvNumber() { return this.eligibleForm.controls.eligible.get('fqtvNumber'); }
  get passportFirstName() { return this.eligibleForm.controls.passport.get('passportFirstName'); }
  get passportMiddleName() { return this.eligibleForm.controls.passport.get('passportMiddleName'); }
  get passportLastName() { return this.eligibleForm.controls.passport.get('passportLastName'); }
  get passportNationality() { return this.eligibleForm.controls.passport.get('passportNationality'); }
  get passportNumber() { return this.eligibleForm.controls.passport.get('passportNumber'); }
  get passportNumberDomestic() { return this.eligibleForm.controls.passport.get('passportNumberDomestic'); }
  get passportIssuanceCountry() { return this.eligibleForm.controls.passport.get('passportIssuanceCountry'); }
  get passportCountryBirth() { return this.eligibleForm.controls.passport.get('passportCountryBirth'); }
  get isBasicGroup() { return this.eligibleForm.controls.eligible.get('isBasicGroup'); }
}
