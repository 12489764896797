<div class="secondary-content text-center mb-4">{{ 'forgot_password.select_opts_title' | translate }}</div>
<div class="row">
  <div class="col" *ngIf="enabledResetOptions.push_notification">
    <button type="button" class="btn btn-outline-primary gtm-send-code" (click)="selectMethodAction('code')" data-cy="code">
      <fa-icon [icon]="icons.faMobileAlt"></fa-icon>
      {{ 'forgot_password.send_code' | translate }}
    </button>
  </div>
  <div class="col" *ngIf="enabledResetOptions.sms">
    <button type="button" class="btn btn-outline-primary gtm-send-sms" (click)="selectMethodAction('sms')" data-cy="sms">
      <fa-icon [icon]="icons.faComment"></fa-icon>
      {{ 'forgot_password.send_sms' | translate }}
    </button>
  </div>
  <div class="col" *ngIf="enabledResetOptions.email">
    <button type="button" class="btn btn-outline-primary gtm-send-email-link" (click)="selectMethodAction('link')" data-cy="link">
      <fa-icon [icon]="icons.faEnvelopeOpenText"></fa-icon>
      {{ 'forgot_password.send_email' | translate }}
    </button>
  </div>
</div>
