import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AirlinesService } from 'app/shared/services/airlines/airlines.service';
import * as fromAirlines from 'app/shared/store/reducers';
import * as fromShared from 'app/shared/store/reducers';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'white-label-home',
  styleUrls: ['white-label-home.scss'],
  templateUrl: './white-label-home.html'
})

export class WhiteLabelHomePage implements OnInit, OnDestroy {
  airlineCode = '';
  logo = '';
  title = '';
  p1Content = '';
  p2Content = '';
  buttonText = '';
  linkUrl = '';
  airlineFlags$ = this.store.select<any>(fromShared.getAirlineFlags);
  ngUnsubscribe = new Subject<void>();
  backgroundUrl: string;
  loading = false;
  airlines$ = this.store.pipe(select(fromAirlines.getAirlines));
  isWhiteLabelEnabled = true;

  constructor(
    private store: Store<fromShared.State>,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private route: ActivatedRoute,
    private airlinesService: AirlinesService
  ) { }

  ngOnInit() {
    combineLatest([this.airlines$, this.route.queryParams, this.airlineFlags$]).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([airlines, params, flags]) => {
      if (!flags?.airlineCode) {
        return this.router.navigateByUrl('404');
      }
      this.isWhiteLabelEnabled = this.airlinesService.isWhiteLabelON(airlines);
      this.airlineCode = flags.airlineCode.toLowerCase();
      const linkExpired = this.activatedRoute.snapshot.queryParams.expired;
      this.backgroundUrl = `/angular_assets/images/white-label/landing/id90-${this.airlineCode}-landing.svg`;
      this.logo = `angular_assets/images/white-label/id90-${this.airlineCode}-logo.png`;
      this.title = linkExpired ? 'landing_page.expired.title' : 'landing_page.title';
      this.p1Content = 'landing_page.paragraph';
      this.p2Content = linkExpired ? 'landing_page.expired.paragraph' : 'landing_page.paragraph_2';
      this.buttonText = 'landing_page.visit_link';
      this.linkUrl = 'landing_page.link_url';
      this.loading = !!params.jwt;
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
