import { UnlinkEmailActions } from '../actions';

export interface State {
  error: string;
  pending: boolean;
  success: boolean;
  verifyError: string;
  verifyPending: boolean;
  verifySuccess: boolean;
}

export const initialState: any = {
  error: null,
  pending: false,
  success: false,
  verifyError: null,
  verifyPending: false,
  verifySuccess: false
};

export function reducer(
  state = initialState,
  action:
    | UnlinkEmailActions.UnlinkEmailActionUnion
) {
  switch (action.type) {
    case UnlinkEmailActions.UnlinkEmailActionTypes.UnlinkRequest:
      return {
        ...state,
        pending: true
      };
    case UnlinkEmailActions.UnlinkEmailActionTypes.UnlinkRequestSuccess:
      return {
        ...state,
        pending: false,
        success: true,
        error: initialState.error
      };
    case UnlinkEmailActions.UnlinkEmailActionTypes.UnlinkRequestError:
      return {
        ...state,
        verifyPending: false,
        verifyError: action.payload.error
      };
    case UnlinkEmailActions.UnlinkEmailActionTypes.UnlinkEmailVerifyCode:
      return {
        ...state,
        verifyPending: true
      };
    case UnlinkEmailActions.UnlinkEmailActionTypes.UnlinkEmailVerifyCodeSuccess:
      return {
        ...state,
        verifyPending: false,
        verifySuccess: true,
        verifyError: initialState.verifyError
      };
    case UnlinkEmailActions.UnlinkEmailActionTypes.UnlinkEmailVerifyCodeError:
      return {
        ...state,
        verifyPending: false,
        verifyError: action.payload.error
      };
    case UnlinkEmailActions.UnlinkEmailActionTypes.Clear:
      return {
        ...state,
        pending: initialState.pending,
        success: initialState.success,
        error: initialState.error,
        verifyError: initialState.verifyError,
        verifyPending: initialState.verifyPending,
        verifySuccess: initialState.verifySuccess
      }
    default: {
      return state;
    }
  }
}

export const getError = (state: any) => state.error;
export const getPending = (state: any) => state.pending;
export const getSuccess = (state: any) => state.success;
