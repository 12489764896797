import { Action } from '@ngrx/store';
import { AirportsRecentSearch } from 'app/shared/models/airports-recent-search';

export enum AirportsRecentArrivalsSearchesActionTypes {
    Load = '[Airport Recent Arrivals Searches] Load',
    SaveSearch = '[Airport Recent Arrivals Searches] Save Search'
  }
  
  export class Load implements Action {
    readonly type = AirportsRecentArrivalsSearchesActionTypes.Load;
  
    constructor() {}
  }
  
  export class SaveSearch implements Action {
    readonly type = AirportsRecentArrivalsSearchesActionTypes.SaveSearch;
  
    constructor(public payload: AirportsRecentSearch) {}
  }
  
  export type AirportsRecentArrivalsSearchesActionsUnion = Load
    | SaveSearch;