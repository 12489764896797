import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class UserToolsService {
  constructor(
    private http: HttpClient
  ) {}

  loadUserTools(tools: any) {
    return this.http.post<any[]>(`${environment.apiUrl}/general/user-tools`, {
      tools
    });
  }
}
