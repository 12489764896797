import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'contactFilter' })

export class ContactFilterPipe implements PipeTransform {
  transform(values: any, arg: any): any {
    if (!arg || arg.length < 3) return values;
    return values.filter(value => value.name.toLowerCase().indexOf(arg.toLowerCase()) > -1 ||
      value.email.toLowerCase().indexOf(arg.toLowerCase()) > -1);
  }
}
