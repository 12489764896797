import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { combineLatest } from 'rxjs';
import { ParticipationModalComponent } from 'app/shared/components/gamification/participation-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import * as fromShared from 'app/shared/store/reducers';
import * as fromAuth from 'app/auth/store/reducers';
import { map } from 'rxjs/operators';
import { GamificationCampaignModel } from 'app/shared/models/gamification.model';
import { GamificationService } from 'app/shared/services/gamification/gamification.service';
import { GamificationComponent } from '../components/gamification/gamification.component';
import { User } from 'app/auth/models/user';

@Injectable({
  providedIn: 'root',
})
export class GamificationGuard {

  activeCampaigns$ = this.store.select<any>(fromShared.getActiveCampaignsGamification);
  showParticipationModal$ = this.store.select<any>(fromShared.showGamificationParticipationModal);
  user$ = this.store.select<any>(fromAuth.getUser);
  private user: User;

  constructor(private modalService: NgbModal,
              private store: Store,
              private gamificationService: GamificationService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return combineLatest([this.activeCampaigns$, this.showParticipationModal$, this.user$]).pipe(
      map(async ([activeCampaigns, showParticipationModal, user]: [GamificationCampaignModel[], boolean, User]) => {
        if (this.modalService.hasOpenModals()) { return true; }
        this.user = user;
        const isSpecialUser =
          !this.user
          || this.user.generic_user
          || this.user.call_center_admin
          || this.user.account?.restricted
          || !!this.user.cobus_authorization;

        const activeCampaign = activeCampaigns?.find((c) => c.require_participation);

        if (isSpecialUser || !activeCampaign || (activeCampaign.finished && !activeCampaign.redeemable)) {
          return true;
        }

        const gamificationStatistics = await this.gamificationService.getStatistics().toPromise();
        const goalIsMet = this.gamificationService.goalIsMet(gamificationStatistics);

        if (this.showParticipationModal(activeCampaigns, showParticipationModal, goalIsMet, activeCampaign)) {
          const participationModal = this.modalService.open(ParticipationModalComponent);
          participationModal.componentInstance.campaign = this.gamificationService.getParticipatingCampaign(activeCampaigns);
          participationModal.componentInstance.statistics = gamificationStatistics;
        }

        if (this.showGoalMetModal(goalIsMet, activeCampaign)) {
          const userTransactions = await this.gamificationService.hasTransactions(activeCampaign?.id).toPromise();
          const hasDoneGoalMetTx = await this.gamificationService.checkGoalMetTransactionDone(activeCampaign.id).toPromise();
          if (userTransactions && !hasDoneGoalMetTx) await this.showGamificationModal();
        }
        return true;
      })
    );
  }

  private showParticipationModal(activeCampaigns, showParticipationModal, goalIsMet, activeCampaign) {
    return showParticipationModal &&
      this.gamificationService.isUserNotParticipating(activeCampaigns) &&
      !goalIsMet &&
      !activeCampaign.finished;
  }

  private showGoalMetModal(goalIsMet, activeCampaign) {
    return activeCampaign.user_participating && goalIsMet && activeCampaign.redeemable
  }

  private async showGamificationModal() {
    const gamificationModalRef = this.modalService.open(GamificationComponent, {
      backdropClass: 'w-100 h-100 dark-backdrop',
      backdrop: 'static',
      keyboard: false
    });
    gamificationModalRef.componentInstance.userId = this.user.account.idMembers;
  }
}
