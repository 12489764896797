<div class="card shadow-none mb-2 bg-success-subtle text-success" *ngIf="(memberSavings && memberSavings.savings_message) || (memberLoyalty && memberLoyalty.hotel_ltv)">
	<div class="card-body">
		<div class="row">
			<div *ngIf="memberSavings && memberSavings.savings_message" class="col-12 text-center" >
        <strong>{{ memberSavings.savings_message }}</strong>
			</div>
      <div *ngIf="memberLoyalty && memberLoyalty.hotel_ltv" class="col-12 text-center fw-light">
        {{ 'profile.member_ltv' | translate: { ltv: (memberLoyalty.hotel_ltv| currency:'USD':'symbol':'1.0-0') }  }}
      </div>
		</div>
	</div>
</div>
