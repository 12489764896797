<div *ngIf="title || text">
  <p class="primary-content">{{ title }}</p>
  <p *ngIf="subtitle" class="tertiary-content">{{ subtitle }}</p>
  <truncated-text-component
    [text]="text"
    [maxLength]="maxLength"
    [microdataItemprop]="microdataItemprop"
    [buttonFloatingLeft]="buttonFloatingLeft"
    [innerMargins]="innerMargins"
  ></truncated-text-component>
</div>
