import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { GeneralHelper } from 'app/shared/helpers/general.helper';
import { WindowEvents } from 'app/shared/services/window-events/window.events';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

declare const window: any;

@Component({
  selector: 'infinite-scroll',
  templateUrl: './infinite-scroll.html',
  styleUrls: ['./infinite-scroll.scss']
})
export class InfiniteScrollComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild(CdkVirtualScrollViewport, { static: false }) virtualScrollViewport: CdkVirtualScrollViewport;

  @Input() items: any[];
  @Input() scrollIndex;
  @Input() loading = true;
  @Input() loadingMore = false;
  @Input() hideListOnLoading = false;
  @Input() spinnerMode: 'full-screen-height' | 'basic-height' | 'fill-parent' = 'basic-height';
  @Input() showLoadMoreButton = false;
  @Input() virtualScroll = false;
  @Input() itemTemplate;
  @Input() itemTemplateContextFunction;
  @Input() itemUniqueIdFunction;
  @Input() itemHeight = 100;
  @Input() isLastPage = false;
  @Input() displayPagination = false;

  @Output() pageEndReach = new EventEmitter();
  @Output() loadMoreClick = new EventEmitter();

  windowResize$: Observable<Event> = this.windowEvents.observe('resize');
  cdkVirtual = false;
  displayAnimation = false;
  waitToScroll = true;
  scrolled = false;
  isMobile = GeneralHelper.isMobileDevice();
  icons = { faSpinner };

  setupWaitToScroll$ = new Subject<boolean>();

  private ngUnsubscribe = new Subject<void>();

  constructor(
    private windowEvents: WindowEvents,
    private cd: ChangeDetectorRef,
  ) { }

  get spinner(): string {
    return this.spinnerMode;
  }

  ngOnInit(): void {
    if (this.virtualScroll) {
      this.cdkVirtual = this.virtualScroll;
      this.windowResize$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
        // destroy and reconstruct the infinite scroll because stuff, fixed size stuff.. dont ask, look away.. RUN
        this.cdkVirtual = false;
        this.loading = true;
        setTimeout(
          () => {
            this.cdkVirtual = true;
            this.loading = false;
          },
          300
        );
      });
    }

    this.setupWaitToScroll$.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.waitToScroll = value;
        this.cd.detectChanges();
      });

    if (this.scrollIndex && this.scrollIndex > 0) {
      this.scrollToItem();
    } else {
      this.setupWaitToScroll$.next(false);
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.virtualScrollViewport && changes.loading && changes.loading.previousValue && !changes.loading.currentValue) {
      this.virtualScrollViewport.scrollToOffset(0);
    }

    if (this.scrollIndex && this.scrollIndex > 0 && changes.items && changes.items.currentValue.length !== 0 && !this.scrolled) {
      this.scrollToItem();
    }
  }

  onIndexChange(index) {
    // we start loading the next page before hitting the end of the page
    if (!this.loading && index >= this.items.length - 5) {
      this.onScrolled();
    }
  }

  onScrolled() {
    this.pageEndReach.emit({ endReached: true });
  }

  getItemTemplate(item) {
    if (typeof item.template !== 'undefined') {
      return item.template;
    }
    return this.itemTemplate;
  }

  getItemTemplateContext(i, item) {
    if (typeof this.itemTemplateContextFunction === 'function') {
      return this.itemTemplateContextFunction(i, item);
    }
    if (typeof item.templateContext !== 'undefined') {
      return item.templateContext;
    }
    return null;
  }

  trackByIdx = (index, item) => {
    if (typeof this.itemUniqueIdFunction === 'function') {
      return this.itemUniqueIdFunction(index, item);
    }
    return index;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  scrollToItem() {
    if (this.items && this.items.length === 0) return;
    this.scrolled = true;
    if (!this.virtualScroll) {
      let index = 0;
      if (window.screen.width <= 768) {
        index = this.scrollIndex;
      } else {
        index = this.scrollIndex > 2 ? this.scrollIndex - 1 : 0;
      }
      if (index) {
        this.setupWaitToScroll$.next(true);
      }

      setTimeout(() => {
        this.setupWaitToScroll$.next(false);
        if (index) {
          const element: HTMLElement = document.getElementById(index.toString());
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
          this.handleFideInAnimation();
        }
      },         300);
    } else {
      this.virtualScrollViewport.scrollToIndex(this.scrollIndex);
    }
  }

  handleFideInAnimation() {
    this.displayAnimation = true;
    setTimeout(() => {
      this.displayAnimation = false;
      this.cd.detectChanges();
    },         2000);
  }
}
