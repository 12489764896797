import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'app/auth/models/user';
import * as fromAuth from 'app/auth/store/reducers';
import { CheckVerifyUser } from 'app/auth/store/actions/auth-api.actions';
import * as fromRoot from 'reducers';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VerifiedUserGuard  {
  user: User;
  confirmed = false;
  isFromShareItinerary = false;
  constructor(
    public router: Router,
    private store: Store<fromRoot.State>,
    protected translateService: TranslateService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.select<any>(fromAuth.selectAuthStatusState).pipe(
      map((status) => {
        this.user = status.user;
        this.confirmed = status.user.member.confirmed;
        this.isFromShareItinerary = route.queryParamMap.get('fromShareItinerary') && this.user.member.airline_code === '100';
        if (this.isFromShareItinerary) { return true; }
        return this.confirmed;
      }),
      tap(() => {
        if (!this.confirmed && !this.isFromShareItinerary) {
          this.store.dispatch(new CheckVerifyUser({ accessToken: this.user.access_token, url: state.url }));
          return of(false);
        }
        return of(true);
      })
    );
  }
}
