import { Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import * as moment from 'moment';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';
import { PhoneNumberHelper } from 'app/shared/helpers/phone-number.helper';
import { Country } from 'app/shared/models/country.model';
import { CustomValidators } from 'app/shared/validators/custom-validators';
import { faEdit, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { SanitizerHelper } from 'app/shared/helpers/sanitizer.helper';

@Component({
  selector: 'personal-details-component',
  templateUrl: './personal-details.html',
  styleUrls: ['./personal-details.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PersonalDetailsComponent implements OnChanges {
  @Input() formDesign = 'expanded';
  @Input() countries: Country[];
  @Input() userDetails: any;
  @Input() showFields = [];
  @Input() disabledFields: string[] = [];
  @Input() showLabels = true;
  @Input() config: any;
  @Input() condensed = false;
  @Input() passNumber = 1;
  @Input() blockedDomains: string[] = [];
  @Input() dateOfBirthRestricted = true;

  @Output() personalDetailsUpdated = new EventEmitter();

  loading = true;
  birthdayDate: any;
  minDate = moment('19000101');
  maxDate = moment();
  ticketTypes: any = null;
  ticketTypeSelector: any = null;
  ticketField: any = null;
  lang = 'en';
  fields = {
    firstName: false,
    lastName: false,
    middleName: false,
    email: false,
    homePhone: false,
    gender: false,
    birthDate: false,
    ticketType: false,
    ticketNumber: false,
    nationality: false,
  };
  requiresNationality: boolean;
  icons = { faEdit, faInfoCircle };
  readonly ftpTypesForNationality = ['BU', 'CO'];

  personalDetailsValidations = {
    firstName: [Validators.required, CustomValidators.nameField],
    middleName: [CustomValidators.nameField],
    lastName: [Validators.required, CustomValidators.nameField],
    email:        [Validators.required, Validators.email],
    phoneCountry: Validators.required,
    birthDate:    Validators.required,
    phoneNumber:  [Validators.required, Validators.maxLength(15)],
    ticketType:   Validators.required,
    ticketNumber: [Validators.required, CustomValidators.minDigitsLength(13), CustomValidators.number],
    gender:       Validators.required,
    nationality: Validators.required,
  };

  personalDetailsForm = new UntypedFormGroup({});
  homePhoneRequiredError: boolean = false;

  constructor(
    public formErrorsHelper: FormErrorsHelper,
    protected translate: TranslateService,
    private phoneNumberHelper: PhoneNumberHelper,
    private sanitizerHelper: SanitizerHelper
  ) {}

  ngOnChanges() {
    this.lang = this.translate.currentLang;
    if (this.userDetails && this.countries) {
      this.setUserInformation();
      if (this.config) {
        this.ticketTypeSelector = this.config.ticketTypeSelectorDisabled ? this.config.ticketTypeSelectorDisabled : null;
        this.ticketField = this.config.ticketDisabled ? this.config.ticketDisabled : null;
      }
      this.loading = false;
    }
  }

  isValid(): boolean {
    if (!this.personalDetailsForm.valid) { this.condensed = false; }
    return this.personalDetailsForm.valid;
  }

  checkHomePhoneValid() {
    if (this.personalDetailsForm.controls['homePhone'].invalid) {
      this.homePhoneRequiredError = true;
      return false;
    }
    this.homePhoneRequiredError = false;
    return true;
  }

  enableFields() {
    this.showFields.map((enable) => {
      if (enable in this.fields) {
        this.fields[enable] = true;
      }
    });
  }

  disableFields() {
    this.disabledFields.forEach((field) => {
      const control = this.personalDetailsForm.get(field);
      if(control){
        control.disable();
      }
    });
  }

  buildForm() {
    this.personalDetailsForm = new UntypedFormGroup({
      firstName:    new UntypedFormControl('', this.fields.firstName ? this.personalDetailsValidations['firstName'] : null),
      lastName:     new UntypedFormControl('', this.fields.lastName ? this.personalDetailsValidations['lastName'] : null),
      middleName:   new UntypedFormControl('', this.fields.middleName ? this.personalDetailsValidations['middleName'] : null),
      email:        new UntypedFormControl(null, this.fields.email ? [...this.personalDetailsValidations['email'], CustomValidators.checkBlockDomain(this.blockedDomains)] : null),
      homePhone:    this.fields.homePhone ? this.phoneNumberHelper.createPhoneFormGroup() : new UntypedFormControl('', null),
      gender:       new UntypedFormControl('', this.fields.gender ? this.personalDetailsValidations['gender'] : null),
      birthDate:    new UntypedFormControl('', this.fields.birthDate && this.dateOfBirthRestricted ? this.personalDetailsValidations['birthDate'] : CustomValidators.customBirthDateValidator()),
      nationality:    new UntypedFormControl('', this.requiresNationality ? this.personalDetailsValidations['nationality'] : null),
      ticketType:   new UntypedFormControl({ value: '', disabled: this.config?.ticketTypeSelector || null }, this.fields.ticketType && !this.config.ticketTypeSelectorDisabled ?
        this.personalDetailsValidations['ticketType'] : null),
      ticketNumber: new UntypedFormControl(
        { value: '', disabled: this.config?.ticketField || null }, this.fields.ticketNumber && this.config.ticketRequired ? this.personalDetailsValidations['ticketNumber'] : null
      )
    });

    if (this.fields.homePhone) {
      const phoneGroup = this.personalDetailsForm.get('homePhone') as UntypedFormGroup;
      phoneGroup.get('country').setValidators(this.personalDetailsValidations['phoneCountry']);
      phoneGroup.get('number').setValidators(this.personalDetailsValidations['phoneNumber']);
    }
  }

  setUserInformation(): void {
    this.enableFields();
    this.requiresNationality = this.fields.nationality && this.ftpTypesForNationality.includes(this.userDetails.passengerType);
    this.buildForm();
    this.disableFields();
    if (this.fields.firstName && this.userDetails.firstName) {
      this.personalDetailsForm.get('firstName').setValue(this.sanitizerHelper.decodeValue(this.userDetails.firstName));
    }

    if (this.fields.lastName && this.userDetails.lastName) {
      this.personalDetailsForm.get('lastName').setValue(this.sanitizerHelper.decodeValue(this.userDetails.lastName));
    }

    if (this.fields.email && this.userDetails.personalEmail) {
      this.personalDetailsForm.get('email').setValue(this.userDetails.personalEmail);
    }

    if (this.fields.homePhone && this.userDetails.homePhone) {
      const phoneGroup = this.personalDetailsForm.get('homePhone') as UntypedFormGroup;
      const phone = this.userDetails.homePhone.split('|');
      const phoneNumber = phone[1] + ((phone[2]) ? phone[2] : '');
      const foundCountry = (Number(phone[0]) === 1) ?
        this.countries.find(country => `${country.phone}${country.area_code}` === `${phone[0]}${phone[1]}`) :
        this.countries.find(country => country.phone === parseInt(phone[0], 10));
      const country = foundCountry ? foundCountry : this.countries[0];
      phoneGroup.patchValue({
        country,
        number: phoneNumber,
      });
    }

    if (this.fields.birthDate && this.isBirthday(this.userDetails.birthDate)) {
      const birthdate = this.returnFormatedBirthdate();
      this.birthdayDate = moment(birthdate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      this.personalDetailsForm.get('birthDate').setValue(birthdate);
    }

    if (this.fields.gender && this.userDetails.gender) {
      this.personalDetailsForm.get('gender').setValue(this.userDetails.gender);
    }

    if (this.fields.middleName) {
      this.personalDetailsForm.get('middleName').setValue(this.userDetails.middleName);
    }

    if (this.fields.ticketNumber && (this.userDetails.ticketNumber || this.userDetails.ticketId)) {
      const ticketNumber = this.userDetails.ticketNumber || this.userDetails.ticketId;
      this.personalDetailsForm.get('ticketNumber').setValue(ticketNumber);
    }

    if (this.fields.ticketType) {
      this.setTicketTypes();
    }

    if (this.fields.nationality && this.userDetails.nationality) {
      this.personalDetailsForm.get('nationality').setValue(this.userDetails.nationality);
    }

    this.personalDetailsUpdated.emit();
  }

  returnFormatedBirthdate() {
    if (this.isBirthDateValid(this.userDetails.birthDate)) return this.parse(this.userDetails.birthDate);
    if (typeof this.userDetails.birthDate === 'string') return this.userDetails.birthDate;
    return null; // Only if the object that came from the flight API with out content
  }

  isBirthday(date) {
    if (!date) return false;
    if (typeof(date) === 'string') {
      return !!date.length;
    }
    return !!(date.year.length && date.month.length && date.day.length);
  }

  isBirthDateValid(date) {
    if (typeof(date) === 'string' || typeof(date) === 'undefined') {
      return false;
    }
    return !!(date.year.length && date.month.length && date.day.length);
  }

  parse(birthdayDate) {
    return `${birthdayDate.year}-${birthdayDate.month}-${birthdayDate.day}`;
  }

  searchCountry(term: string, item: any): boolean {
    return (item.name.toLowerCase().indexOf(term.toLowerCase()) >= 0) || (`${item.phone}`.indexOf(term) >= 0);
  }

  personalDetailsEvent() {
    this.checkHomePhoneValid();
    this.personalDetailsUpdated.emit(this.personalDetailsForm.value);
  }

  setTicketTypes() {
    this.translate.get('mobile.flights.listings.ticket_types').pipe(take(1)).subscribe((types) => {
      this.ticketTypes = types;

      if (this.config && this.config.ticketType && this.config.ticketType !== 'none') {
        this.personalDetailsForm.get('ticketType').setValue(this.config.ticketType);
      } else if (this.userDetails.ticketType) {
        this.personalDetailsForm.get('ticketType').setValue(this.userDetails.ticketType);
      }
    });
  }

  onDatesSelected(e) {
    const isValidDate = moment(e.startDate, 'YYYY-MM-DD', true).isValid();
    if (isValidDate) {
      this.birthdayDate = moment(e.startDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
    }
    this.personalDetailsForm.get('birthDate').setValue(e.startDate);
    this.personalDetailsUpdated.emit(this.personalDetailsForm.value);
  }

  get firstName() {
    return this.personalDetailsForm ? this.personalDetailsForm.get('firstName') : null;
  }
  get firstNameError() {
    return this.formErrorsHelper ? this.formErrorsHelper.errorMessage(this.firstName as UntypedFormControl, 'First Name') : '';
  }
  get middleName() {
    return this.personalDetailsForm ? this.personalDetailsForm.get('middleName') : null;
  }
  get middleNameError() {
    return this.formErrorsHelper ? this.formErrorsHelper.errorMessage(this.middleName as UntypedFormControl, 'Middle Name') : '';
  }
  get lastName() {
    return this.personalDetailsForm ? this.personalDetailsForm.get('lastName') : null;
  }
  get lastNameError() {
    return this.formErrorsHelper ? this.formErrorsHelper.errorMessage(this.lastName as UntypedFormControl, 'Last Name') : '';
  }
  get email() {
    return this.personalDetailsForm && this.fields.email ? this.personalDetailsForm.get('email') : null;
  }
  get emailError() {
    return this.formErrorsHelper && this.fields.email
      ? this.formErrorsHelper.errorMessage(this.email as UntypedFormControl, 'Email') : '';
  }
  get birthDate() {
    return this.personalDetailsForm && this.fields.birthDate ? this.personalDetailsForm.get('birthDate') : null;
  }
  get gender() {
    return this.personalDetailsForm && this.fields.gender ? this.personalDetailsForm.get('gender') : null;
  }
  get ticketNumber() {
    return this.personalDetailsForm && this.fields.ticketNumber ? this.personalDetailsForm.get('ticketNumber') : null;
  }
  get ticketType() {
    return this.personalDetailsForm && this.fields.ticketType ? this.personalDetailsForm.get('ticketType') : null;
  }
  get homePhone() {
    return this.personalDetailsForm && this.fields.homePhone ? this.personalDetailsForm.get('homePhone') : null;
  }
  get nationality() {
    return this.personalDetailsForm && this.fields.nationality ? this.personalDetailsForm.get('nationality') : null;
  }
  get userFirstName() {
    return this.userDetails.firstName ? this.sanitizerHelper.decodeValue(this.userDetails.firstName) : '';
  }
  get userLastName() {
    return this.userDetails.lastName ? this.sanitizerHelper.decodeValue(this.userDetails.lastName) : '';
  }
}
