import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HotelPricingNightTagComponent } from './components/hotel-pricing-night-tag/hotel-pricing-night-tag.component';
import { HotelPricingTotalComponent } from './components/hotel-pricing-total/hotel-pricing-total.component';
import { HotelPricingComponent } from './components/hotel-pricing/hotel-pricing.component';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    HotelPricingNightTagComponent,
    HotelPricingTotalComponent,
    HotelPricingComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FontAwesomeModule,
    NgbModule
  ],
  exports: [
    HotelPricingNightTagComponent,
    HotelPricingTotalComponent,
    HotelPricingComponent
  ]
})
export class HotelPricingModule { }
