import { AllInclusiveActions, AllInclusiveApiActions } from 'app/shared/store/actions';
import { AllInclusiveLocation } from 'app/shared/models/all-inclusive-location';

export interface State {
  destinations: AllInclusiveLocation[];
  pending: boolean;
  error: any | null;
  loaded: boolean;
}

export const initialState: State = {
  destinations: [],
  pending: false,
  error: null,
  loaded: false
};

export function reducer(
  state = initialState,
  action: AllInclusiveActions.AllInclusiveActionsUnion | AllInclusiveApiActions.AllInclusiveApiActionsUnion
) {
  switch (action.type) {
    case AllInclusiveActions.AllInclusiveActionTypes.Load:
      return {
        ...state,
        destinations: [],
        error: null,
        pending: true
      };
    case AllInclusiveApiActions.AllInclusiveApiActionTypes.LoadSuccess:
      return {
        destinations: action.payload,
        error: null,
        pending: false,
        loaded: true
      };
    case AllInclusiveApiActions.AllInclusiveApiActionTypes.LoadFailure:
      return {
        destinations: [],
        error: action.payload.error,
        pending: false,
        loaded: false
      };
    default:
      return state;
  }
}

export const getDestinations = (state: State) => state.destinations;
export const getPending = (state: State) => state.pending;
export const getLoaded = (state: State) => state.loaded;
