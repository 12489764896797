import { Action } from '@ngrx/store';

export enum ProfileUserActionTypes {
  LoadConfigProfile = '[Profile] Load Config',
  LoadConfigProfileSuccess = '[Profile] Load Config Success',
  LoadConfigProfileFailure = '[Profile] Load Config Failure',
  LoadUserProfile = '[Profile] Load User',
  LoadUserProfileSuccess = '[Profile] Load User Success',
  LoadUserProfileFailure = '[Profile] Load User Failure',
  LoadBusinessToggle = '[Profile] Load Business Toggle',
  LoadBusinessToggleSuccess = '[Profile] Load Business Toggle Success',
  LoadBusinessToggleFailure = '[Profile] Load Business Toggle Failure',
  LoadUserProfileStructure = '[Profile] Load Structure',
  LoadUserProfileStructureSuccess = '[Profile] Load Structure Success',
  LoadUserProfileStructureFailure  = '[Profile] Load Structure Failure',
  UpdateUserProfile = '[Profile] Update User'
}

export class LoadConfigProfile implements Action {
  readonly type = ProfileUserActionTypes.LoadConfigProfile;
}

export class LoadConfigProfileSuccess implements Action {
  readonly type = ProfileUserActionTypes.LoadConfigProfileSuccess;

  constructor(public payload: { config: any }) {}
}

export class LoadConfigProfileFailure implements Action {
  readonly type = ProfileUserActionTypes.LoadConfigProfileFailure;

  constructor(public payload: { error: any }) {}
}

export class LoadUserProfile implements Action {
  readonly type = ProfileUserActionTypes.LoadUserProfile;
}

export class LoadUserProfileSuccess implements Action {
  readonly type = ProfileUserActionTypes.LoadUserProfileSuccess;

  constructor(public payload: { info: any }) {}
}

export class LoadUserProfileFailure implements Action {
  readonly type = ProfileUserActionTypes.LoadUserProfileFailure;

  constructor(public payload: { error: any }) {}
}

export class LoadBusinessToggle implements Action {
  readonly type = ProfileUserActionTypes.LoadBusinessToggle;
}

export class LoadBusinessToggleSuccess implements Action {
  readonly type = ProfileUserActionTypes.LoadBusinessToggleSuccess;

  constructor(public payload: { showBusinessToggle: any }) {}
}

export class LoadBusinessToggleFailure implements Action {
  readonly type = ProfileUserActionTypes.LoadBusinessToggleFailure;

  constructor(public payload: { error: any }) {}
}

export class LoadUserProfileStructure implements Action {
  readonly type = ProfileUserActionTypes.LoadUserProfileStructure;
}

export class LoadUserProfileStructureSuccess implements Action {
  readonly type = ProfileUserActionTypes.LoadUserProfileStructureSuccess;

  constructor(public payload: { brandUserStructure: any }) {}
}

export class LoadUserProfileStructureFailure implements Action {
  readonly type = ProfileUserActionTypes.LoadUserProfileStructureFailure;

  constructor(public payload: { error: any }) {}
}

export class UpdateUserProfile implements Action {
  readonly type = ProfileUserActionTypes.UpdateUserProfile;

  constructor(public payload: any) {}
}

export type ProfileUserActionsUnion = LoadConfigProfile
  | LoadConfigProfileSuccess
  | LoadConfigProfileFailure
  | LoadUserProfile
  | LoadUserProfileSuccess
  | LoadUserProfileFailure
  | LoadUserProfileStructure
  | LoadUserProfileStructureSuccess
  | LoadUserProfileStructureFailure
  | LoadBusinessToggle
  | LoadBusinessToggleSuccess
  | LoadBusinessToggleFailure
  | UpdateUserProfile;
